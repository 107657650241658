import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ValidationErrors } from "pojo-validator";
import { ValidateCallback } from "pojo-validator-react";
import { ValidatedInput } from "pojo-validator-reactstrap";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonGroup, Card, CardBody, Col, Collapse, FormGroup, FormText, Input, Label, Row, InputGroup, InputGroupAddon, } from "reactstrap";
import { useToggleState } from "use-toggle-state";
import { QuestionAnswer } from "../../../../api/main/models/QuestionAnswer";
import { HtmlDisplay, HtmlEditor } from "../../../../shared/htmlEditor";
import { CardHeaderCollapse } from "../../../shared/cardHeaderCollapse/CardHeaderCollapse";
import { FormButtons } from "../../../shared/FormButtons";
import { InputSelectOrText } from "../../../shared/inputSelectOrText/InputSelectOrText";
import { responseWindowColors } from "./reponseWindowColors";

export interface QuestionAnswerLocationDiscriminationImageProps {
    model: QuestionAnswer | undefined,
    change: (changes: Partial<QuestionAnswer>) => void,
    remove: () => void,
    moveUp: () => void,
    canMoveUp: boolean,
    moveDown: () => void,
    canMoveDown: boolean,

    isOpenInitially?: boolean,

    validate: ValidateCallback,
    validationErrors: ValidationErrors,

    discriminationGroups: Array<string>,
}

/**
 * Response window for LocationDiscriminationImage questions.
 * @param props
 */
export const QuestionAnswerLocationDiscriminationImage = (props: QuestionAnswerLocationDiscriminationImageProps) => {
    const {
        model,
        change,
        remove,
        moveUp,
        canMoveUp,
        moveDown,
        canMoveDown,

        isOpenInitially = false,

        validate,
        validationErrors,

        discriminationGroups,
    } = props;

    const { t } = useTranslation();

    // Self management of if we are expanded or not.
    const [isOpen, toggleOpen] = useToggleState(isOpenInitially);

    const locationString = useMemo(() => {
        if (!model) {
            return '';
        }

        return `${Math.round(model.windowTop)},${Math.round(model.windowLeft)} to ${Math.round(model.windowBottom)},${Math.round(model.windowRight)}`;
    }, [model]);

    const discriminationGroupColor = useMemo(() =>
        responseWindowColors[(discriminationGroups.indexOf(model?.discriminationGroup ?? '') || 0) % responseWindowColors.length].fill
        , [model, discriminationGroups]);

    return (
        <Card>
            <CardHeaderCollapse isOpen={isOpen} toggle={toggleOpen}>
                <Row>
                    <Col xs="auto">
                        {locationString}
                    </Col>
                    <Col xs="auto" style={{ color: discriminationGroupColor }}>
                        {model?.discriminationGroup}
                    </Col>
                    <Col>
                        <div style={{ maxHeight: '2rem', overflowY: 'hidden', }}>
                            <HtmlDisplay html={model?.answerText ?? ''} />
                        </div>
                    </Col>
                </Row>
            </CardHeaderCollapse>

            <Collapse isOpen={isOpen}>
                <CardBody>
                    <FormButtons noPadding>
                        <ButtonGroup>
                            <Button color="primary" outline onClick={() => moveUp()} disabled={!canMoveUp}>
                                <FontAwesomeIcon icon="caret-up" />
                                <> </>
                                {t('questionAnswerLocationDiscriminationImage.up', 'Up')}
                            </Button>
                            <Button color="primary" outline onClick={() => moveDown()} disabled={!canMoveDown}>
                                <FontAwesomeIcon icon="caret-down" />
                                <> </>
                                {t('questionAnswerLocationDiscriminationImage.donn', 'Down')}
                            </Button>
                            <Button color="danger" outline onClick={() => remove()}>
                                <FontAwesomeIcon icon="trash-alt" />
                                <> </>
                                {t('questionAnswerLocationDiscriminationImage.delete', 'Delete window')}
                            </Button>
                        </ButtonGroup>
                    </FormButtons>

                    <Row>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="windowTop">{t('questionAnswerLocationDiscriminationImage.windowTop.label', 'Top')}</Label>

                                <Input type="number" value={model?.windowTop ?? ''} onChange={e => change({ windowTop: e.currentTarget.valueAsNumber })} />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="windowLeft">{t('questionAnswerLocationDiscriminationImage.windowLeft.label', 'Left')}</Label>

                                <Input type="number" value={model?.windowLeft ?? ''} onChange={e => change({ windowLeft: e.currentTarget.valueAsNumber })} />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="windowBottom">{t('questionAnswerLocationDiscriminationImage.windowBottom.label', 'Bottom')}</Label>

                                <Input type="number" value={model?.windowBottom ?? ''} onChange={e => change({ windowBottom: e.currentTarget.valueAsNumber })} />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="windowRight">{t('questionAnswerLocationDiscriminationImage.windowRight.label', 'Right')}</Label>

                                <Input type="number" value={model?.windowRight ?? ''} onChange={e => change({ windowRight: e.currentTarget.valueAsNumber })} />
                            </FormGroup>
                        </Col>
                    </Row>

                    <FormGroup>
                        <Label htmlFor="discriminationGroup">{t('questionAnswerLocationDiscriminationImage.discriminationGroup', 'Discrimination group')}</Label>
                        <InputSelectOrText value={model?.discriminationGroup} onChange={e => change({ discriminationGroup: e.currentTarget.value })}>
                            {
                                discriminationGroups.map(item => (
                                    <option key={item} value={item}
                                        style={{ color: responseWindowColors[(discriminationGroups.indexOf(item) || 0) % responseWindowColors.length].fill }}
                                    >
                                        {item}
                                    </option>
                                    ))
                            }
                        </InputSelectOrText>
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="answerText">{t('questionAnswerLocationDiscriminationImage.answerText', 'Window description')}</Label>
                        <HtmlEditor value={model?.answerText ?? ''} onChange={html => change({ answerText: html })} />
                    </FormGroup>

                    <Row>
                        {/*<Col>*/}
                        {/*    <FormGroup>*/}
                        {/*        <Label htmlFor="isCorrect">{t('questionAnswerLocationDiscriminationImage.isCorrect', 'Is this a correct answer?')}</Label>*/}

                        {/*        <TwoValueSwitch*/}
                        {/*            leftLabel={t('questionAnswerLocationDiscriminationImage.isCorrect.false', 'Incorrect')}*/}
                        {/*            rightLabel={t('questionAnswerLocationDiscriminationImage.isCorrect.true', 'Correct')}*/}
                        {/*            checked={model?.isCorrect ?? false} onChange={checked => {*/}
                        {/*                // Toggle the flag, and if we're using default scoring at the moment, also default the score.*/}
                        {/*                if (checked && model?.score === 0) {*/}
                        {/*                    change({ isCorrect: checked, score: 10 });*/}
                        {/*                } else if (!checked && model?.score === 10) {*/}
                        {/*                    change({ isCorrect: checked, score: 0 });*/}
                        {/*                } else {*/}
                        {/*                    change({ isCorrect: checked });*/}
                        {/*                }*/}
                        {/*            }}*/}
                        {/*            />*/}
                        {/*    </FormGroup>*/}
                        {/*</Col>*/}
                        <Col>
                            <FormGroup>
                                <Label htmlFor="score">{t('questionAnswerLocationDiscriminationImage.score.label', 'Score')}</Label>

                                <InputGroup>
                                    <ValidatedInput name="score" type="number" min={-100} max={100} value={model?.score ?? ''} onChange={e => change({ score: e.currentTarget.valueAsNumber })} onBlur={() => validate('score')} validationErrors={validationErrors['score']} />
                                    <InputGroupAddon addonType="append">
                                        {t('common.percentageSign', '%')}
                                    </InputGroupAddon>
                                </InputGroup>
                                <FormText>
                                    {t('questionAnswerMultipleChoiceVideo.score.help', 'Score will normally be between 0% (incorrect) and 100% (correct).')}
                                </FormText>
                            </FormGroup>
                        </Col>
                    </Row>
                </CardBody>
            </Collapse>
        </Card>
        );
};