import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { AssessmentFeedbackCreateInput } from '../generated/globalTypes';
import { createAssessmentFeedbackMutation, createAssessmentFeedbackMutationVariables } from '../generated/createAssessmentFeedbackMutation';
import { assessmentFeedbackFieldsFragment } from '../models/AssessmentFeedback';

/**
 * Get a callback to create a AssessmentFeedback in the store.
 */
export function useCreateAssessmentFeedbackCallback(): [(model: AssessmentFeedbackCreateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<createAssessmentFeedbackMutation, createAssessmentFeedbackMutationVariables>(
        gql`
            mutation createAssessmentFeedbackMutation ($model: AssessmentFeedbackCreateInput!) {
                createAssessmentFeedback(model: $model) {
                    ...assessmentFeedbackFields
                }
            }

            ${assessmentFeedbackFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (model: AssessmentFeedbackCreateInput) => {
        const result = await mutationAction({ variables: { model: model } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
