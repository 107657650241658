import { useTranslation } from "react-i18next";
import { UsersListBase } from "../UsersListBase";

/**
 * List of administrators.
 */
export const AdministratorsList = () => {
    const { t } = useTranslation();

    return (
        <UsersListBase
            title={t('administratorsList.title', 'Esitu staff')}
            filterByRoleGroups={['Esitu staff']}
            roleGroupColumnHidden={true}
            baseRoute={'/administration/administrators'}
            teamColumnHidden={true}
            allowImport={false}
        />
        );
};