import { RouteEntry } from "../../../shared/routes";
import { DistributorsList } from './DistributorsList';
import { IdentityRoles } from "../../../configure/security/IdentityRoles";
import { EditDistributor, CreateDistributor } from "./EditDistributor";
import { DistributorAdminsList } from "./DistributorAdminList";
import { CreateDistributorAdmin, EditDistributorAdmin } from "./Administration/CreateDistributorAdmin";
import { DistributorAssessmentsList } from "./DistributorAssessmentsList";
import { DistributorTrainingList } from "./DistributorTrainingList";
import { DistributorAdministrationHome } from "./Administration/DistributorAdministrationHome";
import { DistributorAdministrationDashboard } from "../../dashboard/administrationDashboard/DistributorAdministrationDashboard";
import { DistributorSubscriptionsList } from "./Administration/DistributorSubscriptionsList";
import { CreateDistributorSubscription, EditDistributorSubscription } from "./Administration/EditDistributorSubscription";
import { DistributorSubscriptionAssessmentsList } from "./Administration/DistributorSubscriptionAssessmentsList";
import { DistributorSubscriptionTrainingList } from "./Administration/DistributorSubscriptionTrainingList";

export const distributorRoutes: Array<RouteEntry> = [
    { path: '/administration/distributors', exact: true, component: DistributorsList, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/distributors/add', component: CreateDistributor, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/distributors/edit/:id', component: EditDistributor, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/distributors/details/:subscriptionId/assessments', exact: true, component: DistributorAssessmentsList, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/distributors/details/:subscriptionId/training', exact: true, component: DistributorTrainingList, authorize: true, requireRole: IdentityRoles.Administration },

    //manage for distributors is the distributors admin page
    { path: '/manage/distributor', exact: true, component: DistributorAdministrationHome, authorize: true, requireRole: IdentityRoles.DistributionAdministration },
    { path: '/manage/distributor/dashboard', exact: true, component: DistributorAdministrationDashboard, authorize: true, requireRole: IdentityRoles.DistributionAdministration },
    { path: '/manage/distributor/admins', exact: true, component: DistributorAdminsList, authorize: true, requireRole: IdentityRoles.DistributionAdministration },
    { path: '/manage/distributor/admins/add', component: CreateDistributorAdmin, authorize: true, requireRole: IdentityRoles.DistributionAdministration },
    { path: '/manage/distributor/admins/edit/:id', component: EditDistributorAdmin, authorize: true, requireRole: IdentityRoles.DistributionAdministration },
    { path: '/manage/distributor/subscriptions', exact: true, component: DistributorSubscriptionsList, authorize: true, requireRole: IdentityRoles.DistributionAdministration },
    { path: '/manage/distributor/subscriptions/add', component: CreateDistributorSubscription, authorize: true, requireRole: IdentityRoles.DistributionAdministration },
    { path: '/manage/distributor/subscriptions/edit/:id', component: EditDistributorSubscription, authorize: true, requireRole: IdentityRoles.DistributionAdministration},
    { path: '/manage/distributor/subscriptions/details/:subscriptionId/assessments', exact: true, component: DistributorSubscriptionAssessmentsList, authorize: true, requireRole: IdentityRoles.DistributionAdministration},
    { path: '/manage/distributor/subscriptions/details/:subscriptionId/training', exact: true, component: DistributorSubscriptionTrainingList, authorize: true, requireRole: IdentityRoles.DistributionAdministration },
];
