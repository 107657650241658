import { MouseEventHandler, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { NavItem, NavLink } from "reactstrap";
import { NavLinkTrackActive } from "../../shared/NavLinkTrackActive";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface AdministratorNavigationProps {
    id: string,
    onNavigate?: (link: string) => void,
    editLink?: string,
}

/**
 * Navigation under editing of a Administrator.
 */
export const AdministratorNavigation = (props: AdministratorNavigationProps) => {
    const { id, onNavigate, editLink } = props;
    const { t } = useTranslation();

    // If we have an onNavigate event, we should call that instead of leting the links handle themselves.
    const onNavLinkClick = useCallback<MouseEventHandler>((e) => {
        if (onNavigate) {
            e.preventDefault();
            const link = e.currentTarget.getAttribute("href") ?? '';
            onNavigate(link);
        }
    }, [onNavigate]);

    return (
        <>
            <NavItem>
                <NavLink tag={NavLinkTrackActive} to={editLink ?? `/administration/administrators/edit/${id}`} onClick={onNavLinkClick}>
                    <FontAwesomeIcon icon="users" className="nav-icon" />
                    {t('administratorNavigation.administrator', ' Esitu staff')}
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink tag={NavLinkTrackActive} to={`/administration/administrators/details/${id}/assessments`} onClick={onNavLinkClick}>
                    <FontAwesomeIcon icon="clipboard-check" className="nav-icon" />
                    {t('administratorNavigation.assessments', ' Assessments')}
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink tag={NavLinkTrackActive} to={`/administration/administrators/details/${id}/training`} onClick={onNavLinkClick}>
                    <FontAwesomeIcon icon="graduation-cap" className="nav-icon" />
                    {t('administratorNavigation.training', ' Training modules')}
                </NavLink>
            </NavItem>
        </>
    );
};
