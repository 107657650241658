import { ServiceResolver, ServiceProvider, IServiceCollectionBuilder } from "inject-typesafe";
import { AccountService } from "../api/account";
import { ApiFetch } from 'apifetch-json';

/**
 * Returns a configured service provider.
 */
export function createServiceProvider() {
    return new ServiceProvider((builder: IServiceCollectionBuilder<AppServices>) => ({
        accountService: builder.singleton(services => new AccountService()),
        apiFetch: builder.singleton(services => new ApiFetch()),
    }));
}

export interface AppServices {
    accountService: ServiceResolver<AccountService>,
    apiFetch: ServiceResolver<ApiFetch>,
}

