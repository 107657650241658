import { Question } from "../../../../../api/main/models/Question";
import { QuestionAnswer } from "../../../../../api/main/models/QuestionAnswer";
import { HtmlDisplay } from "../../../../../shared/htmlEditor";
import { SlidingScaleSlider } from "../../../../questions/edit/slidingScaleVideo/SlidingScaleSlider";
import { QuestionResponseEvent } from "../../../../../api/main/models/QuestionResponseEvent";
import { ModelArrayChanges } from "../../../../../shared/useChanges";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";

/**
 * Running of a SlidingScaleVideo question.
 * @param props
 */

export interface ShowQuestionnaireSlidingScaleProps {

    model: Question
    answers: Array<QuestionAnswer>

    // Selecting an answer.
    selectedAnswerId: string | undefined,
    selectSingleAnswer: (answer: QuestionAnswer | undefined, currentQuestion: Question) => void,

    questionResponseEventsManager: ModelArrayChanges<QuestionResponseEvent, string>,

    userId: string | undefined,
}

export const ShowQuestionnaireSlidingScale = (props: ShowQuestionnaireSlidingScaleProps) => {
    const {
        model,
        answers,

        questionResponseEventsManager,
        selectedAnswerId,
        selectSingleAnswer,

        userId,
    } = props;

    const { t } = useTranslation();

    const onSelectSingleAnswer = useCallback((answer: QuestionAnswer | undefined) => {
        selectSingleAnswer(answer, model);
    }, [selectSingleAnswer, model]);

    //pick the answer that is set as the default value and add it to the response manager
    useEffect(() => {

        if (userId) {

            const currentResponse = questionResponseEventsManager.model.filter(item => item.questionId === model.id && !item.archived);
            //check if we already have a response saved
            if (currentResponse.length === 0) {

                //same logic as in "<SlidingScaleSlider>" for deciding where to start the scale dot
                const min = 0;
                const max = answers.length < 2 ? 1 : answers.length - 1;

                const defaultValue = Math.floor((max - min) / 2);

                //set what was calculated to be the current response
                onSelectSingleAnswer(answers[defaultValue]);

            }
        }

    }, [userId, answers, model, onSelectSingleAnswer, questionResponseEventsManager]);


    return (
        <div className="show-questionnaire-sliding-scale">
            <HtmlDisplay html={t(model.questionText)} />

            <div className="mt-4">
                <SlidingScaleSlider
                    answers={answers}
                    selectedAnswerId={selectedAnswerId}
                    onSelectedAnswerChanged={answer => onSelectSingleAnswer(answer)}
                />
            </div>

        </div>
            
        );
}