import './uploadedVideoPreview.scss';

import { useTranslation } from 'react-i18next';
import { ConditionalFragment } from 'react-conditionalfragment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CSSProperties, ReactEventHandler } from 'react';

export interface UploadedVideoPreviewProps {
    src: string,
    poster?: string,

    className?: string,
    style?: CSSProperties,

    onTimeUpdate?: ReactEventHandler<HTMLVideoElement>,
}

/**
 * Preview of an video uploaded by a user, suitable for use on an Edit form for example and made to look similar to other controls that would show in that context.
 */
export const UploadedVideoPreview = (props: UploadedVideoPreviewProps) => {
    const {
        src,
        poster,
        className,
        style,
        onTimeUpdate,
    } = props;

    const { t } = useTranslation();

    return (
        <div className={`uploaded-video-preview ${className ?? ''}`} style={style}>
            <ConditionalFragment showIf={!!src}>
                <div className="embed-responsive embed-responsive-16by9 mb-4 video-container video-container-admin">
                    <video
                        src={src}
                        poster={poster}
                        playsInline={true}
                        controls
                        onTimeUpdate={onTimeUpdate}
                    >
                    </video>
                </div>
            </ConditionalFragment>
            <ConditionalFragment showIf={!src}>
                <div className="uploaded-video-preview-no-video">
                    <div className="uploaded-video-preview-no-video-icon">
                        <FontAwesomeIcon icon="video" />
                    </div>
                    <p>
                        {
                            t('uploadedVideoPreview.notVideoText', 'No video has been uploaded yet')
                        }
                    </p>
                </div>
            </ConditionalFragment>
        </div>
    );
};
