import { Dispatch, SetStateAction } from "react";
import { Button, ButtonGroup, ListGroupItem } from "reactstrap";
import { OverallRiskCategory, overallRiskService } from "../../../services/OverallRiskService";

export interface OverallRiskFilterListGroupItemProps {
    riskCategoryFilter: OverallRiskCategory | undefined,
    setRiskCategoryFilter: Dispatch<SetStateAction<OverallRiskCategory | undefined>>,
}

/**
 * ListGroupItem that allows filtering by the overall risk.
 * @param props
 */
export const OverallRiskFilterListGroupItem = (props: OverallRiskFilterListGroupItemProps) => {
    const {
        riskCategoryFilter, setRiskCategoryFilter,
    } = props;

    return (
        <ListGroupItem className="text-right">
            <ButtonGroup size="sm">
                <Button outline={!!riskCategoryFilter} onClick={() => setRiskCategoryFilter(undefined)}>
                    All
                </Button>
                <Button color="danger" outline={riskCategoryFilter !== OverallRiskCategory.High} onClick={() => setRiskCategoryFilter(OverallRiskCategory.High)}>
                    {overallRiskService.getRiskName(OverallRiskCategory.High)}
                </Button>
                <Button color="warning" outline={riskCategoryFilter !== OverallRiskCategory.Medium} onClick={() => setRiskCategoryFilter(OverallRiskCategory.Medium)}>
                    {overallRiskService.getRiskName(OverallRiskCategory.Medium)}
                </Button>
                <Button color="success" outline={riskCategoryFilter !== OverallRiskCategory.Low} onClick={() => setRiskCategoryFilter(OverallRiskCategory.Low)}>
                    {overallRiskService.getRiskName(OverallRiskCategory.Low)}
                </Button>
                <Button color="dark" outline={riskCategoryFilter !== OverallRiskCategory.NotAssessed} onClick={() => setRiskCategoryFilter(OverallRiskCategory.NotAssessed)}
                    style={{ backgroundColor: riskCategoryFilter !== OverallRiskCategory.NotAssessed? undefined: '#aeaeae' }}
                >
                    {overallRiskService.getRiskName(OverallRiskCategory.NotAssessed)}
                </Button>
            </ButtonGroup>
        </ListGroupItem>
        );
};