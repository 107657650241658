/**
 * Colour palette used by charts.
 * 
 * Note that we've tried to use a larger colour pallet before repeating the colours.
 */
export const chartColors = [
    // Brand specific chart colours.
    '#ec0b62', '#2f5597', '#00c4de',

    // General chart colours.
    '#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0',
    '#4caf50', '#3f51b5', '#FF9800',
    '#546E7A', '#d4526e', '#A5978B',
    '#4ecdc4', '#c7f464',
    '#f9a3a4', '#69d2e7',
    '#F86624', '#EA3546', '#C5D86D',
    '#D7263D', '#1B998B', '#2E294E',
    '#662E9B', '#f9ce1d',
    '#8D5B4C'
];
