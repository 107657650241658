import gql from "graphql-tag";
import { Guid } from "guid-string";
import moment from "moment";
import { assessmentItemFields } from "../generated/assessmentItemFields";

export const assessmentItemFieldsFragment = gql`
    fragment assessmentItemFields on AssessmentItem {
        id
        assessmentId
        displayOrder
        archived
        numberOfQuestionsToPresent
        counterbalanceGroup
    }
`;


export type AssessmentItem = Omit<assessmentItemFields, '__typename'>;

// Default values.
export const assessmentItemDefaultValues = (): Partial<AssessmentItem> => ({
    id: Guid.newGuid(),
    assessmentId: undefined,
    displayOrder: moment().unix(),
    archived: false,
    numberOfQuestionsToPresent: 1,
    counterbalanceGroup: '',
});