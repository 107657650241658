import * as React from "react";
import { useCompletedExternalLoginDetails, useCreateAccountForExternalLoginCallback } from "../../api/account";
import { ClaimTypes } from "../../api/account";
import { useChanges } from "../../shared/useChanges";
import { useValidatorCallback } from "pojo-validator-react";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { LoadingIndicator } from "../../components/shared/LoadingIndicator";
import { FormGroup, Label, Form, Spinner, Card, CardHeader, CardBody } from "reactstrap";
import { ValidatedInput } from "pojo-validator-reactstrap";
import { ButtonAsync } from "reactstrap-buttonasync";
import { ExternalLoginCallback as ExternalLoginCallbackModel } from "../../api/account/models/ExternalLoginCallback";
import { useTranslation } from "react-i18next";
import { RegisterProfileDetails, useRegisterProfileDetailsProps } from "../profiles/RegisterProfileDetails";
import { MainContainer } from "../shared/MainContainer";
import { Background } from "../shared/background/Background";

/**
 * Complete an the external login.
 */
export const ExternalLoginCallback = () => {
    const { t } = useTranslation();
    const { data: { isNewUser, loginProvider, returnUrl, claims }, isLoading, errors: loadErrors } = useCompletedExternalLoginDetails();
    const [createAccount, { isExecuting: isCreatingAccount, errors: createAccountErrors }] = useCreateAccountForExternalLoginCallback();

    const registerProfileDetailsProps = useRegisterProfileDetailsProps();

    const { model, change } = useChanges<ExternalLoginCallbackModel>({ email: '' });
    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        if (!model) {
            return;
        }

        const rules = {
            email: () => !model.email ? t('externalLoginCallback.emailRequired', 'Email is required') : '',
        };
        validation.checkRules(rules, fieldsToCheck);

        // Check the profile details as well (ensuring an error reflects in our error state).
        validation.singleCheck('profileDetails', !registerProfileDetailsProps.validate(fieldsToCheck), 'Profile details has some validation errors');
    }, [model]);

    // When the data returned by the external login changes, we want to use it to either default some changes or redirect away from the page.
    React.useEffect(() => {
        // If this is not a new user just redirect to their target page.
        if (!isNewUser) {
            // Redirect the whole page (not just the react app) as its likely the returnUrl is handled on the server.
            window.location.href = returnUrl || '/';
        } else {
            // Default the email in the model for new users (if the external login provided one).
            let emailClaim = claims.find(item => item.type === ClaimTypes.email);
            if (emailClaim && emailClaim.value) {
                change({ email: emailClaim.value });
            }
        }
    }, [isNewUser, returnUrl, claims, change]);

    // Render the UI.
    //

    // If we're not ready, show the loading indicator.
    if (isLoading || !isNewUser) {
        return (
            <>
                <AlertOnErrors simple errors={[loadErrors, createAccountErrors]} />
                <LoadingIndicator />
            </>
            );
    }

    // Render the external login registration view.
    return (
        <Background centerChildren="vertically">
            <MainContainer color="transparent">
                <Card color="dark">
                    <CardHeader>
                        <h1>{t('externalLoginCallback.registerAccountHeading', 'Register with your {{loginProvider}} account', { context: loginProvider, loginProvider: loginProvider })}</h1>
                    </CardHeader>
                    <CardBody>
                        <p className="text-mute">
                            {t('externalLOginCallback.registerHelpText', 'You\'ve successfully authenticated with <strong>{loginProvider}</strong>.  Please enter an email address for this site below and click the Register button to finish logging in.')}
                        </p>
            
                        <Form onSubmit={async e => {
                            e.preventDefault();
                            if (!validate()) {
                                return;
                            }

                            const result = await createAccount(model)
                            if (result) {
                                // Save the profile.
                                await registerProfileDetailsProps.registerProfile(result.userId);
                            }
                        }}>
                            <AlertOnErrors errors={[loadErrors, createAccountErrors]} />


                            <RegisterProfileDetails {...registerProfileDetailsProps} />

                            <FormGroup>
                                <Label htmlFor="email">{t('externalLoginCallback.email', 'Email')}</Label>
                                <ValidatedInput type="email" name="email" autoComplete="username" value={model.email} onChange={e => change({ email: e.currentTarget.value })} onBlur={e => validate('email')} validationErrors={validationErrors['email']} />
                            </FormGroup>
                            <ButtonAsync type="submit" color="primary" isExecuting={isCreatingAccount}
                                executingChildren={<><Spinner size="sm" /> {t('externalLoginCallback.registring', 'Registering...')}</>}>
                                {t('externalLoginCallback.register', 'Register')}
                            </ButtonAsync>
                        </Form>
                    </CardBody>
                </Card>
            </MainContainer>
        </Background>
    );
};

