import * as React from 'react';
import { AssessmentCreateInput, AssessmentUpdateInput } from '../generated/globalTypes';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useCreateAssessmentCallback } from './useCreateAssessmentCallback';
import { useUpdateAssessmentCallback } from './useUpdateAssessmentCallback';
import { Assessment } from '../models/Assessment';

/**
 * Get a callback to save a Assessment in the store using either a create or update.
 */
export function useSaveAssessmentCallback(options: SaveInStoreOptions<Assessment, string> = {}) {
    const [_create, createStatus] = useCreateAssessmentCallback();
    const create = React.useCallback((model: Partial<Assessment>) => _create(model as AssessmentCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateAssessmentCallback();
    const update = React.useCallback((id: string, changes: Partial<Assessment>) => _update(id, changes as AssessmentUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
