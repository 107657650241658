import { AssessmentType } from "../../../api/main/models/codeOnly/AssessmentType";
import { AssessmentsDashboardTabBase, AssessmentsDashboardTabBaseProps } from "./AssessmentsDashboardTabBase";

/**
 * Assessments tab on the dashboard.
 * @param props
 */
export const AssessmentsDashboardTab = (props: AssessmentsDashboardTabBaseProps) => {
    return (
        <AssessmentsDashboardTabBase
            assessmentType={AssessmentType.Assessment}
            {...props}
        />
    );
};