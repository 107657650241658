import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Button, FormGroup, FormText, Label, Input } from "reactstrap";
import { BlobReference } from "../../../../api/main/models/BlobReference";
import { Question } from "../../../../api/main/models/Question";
import { QuestionAnswer, questionAnswerDefaultValues } from "../../../../api/main/models/QuestionAnswer";
import { HtmlEditor } from "../../../../shared/htmlEditor";
import { ModelArrayChanges } from "../../../../shared/useChanges";
import { QuestionAnswerLocationResponseImage } from "./QuestionAnswerLocationResponseImage";
import { useDisplayOrder } from "../../../shared/useDisplayOrder/useDisplayOrder";
import { ValidationErrors } from "pojo-validator";
import { InteractiveImage } from "../../../shared/interactiveImage/InteractiveImage";
import { OverlayShape } from "../../../shared/interactiveImage/OverlayShape";
import { useToggleState } from "use-toggle-state";
import { ConditionalFragment } from "react-conditionalfragment";
import { textFromHtml } from "../../../../utilities/textFromHtml";
import { responseWindowColors } from "../locationDiscriminationImage/reponseWindowColors";


export interface QuestionPromptLocationResponseImageTabProps {
    model: Question | undefined,
    change: (changes: Partial<Question>) => void,

    imageBlob: BlobReference | undefined | null,

    answersManager: ModelArrayChanges<QuestionAnswer, string>,

    validateQuestionAnswer: (model: QuestionAnswer) => boolean,
    questionAnswerValidationErrors: (id: string) => ValidationErrors,
}

/**
 * Tab for maintaining the prompt and response windows for a LocationResponseImage question.
 * @param props
 */
export const QuestionPromptLocationResponseImageTab = (props: QuestionPromptLocationResponseImageTabProps) => {
    const {
        model,
        change,

        imageBlob,

        answersManager,

        validateQuestionAnswer,
        questionAnswerValidationErrors,
    } = props;



    const { t } = useTranslation();

    // Order the answers so they show in and can be managed by displayOrder.
    const [orderedAnswers, {
        canMoveUp: canMoveAnswerUp,
        moveUp: moveAnswerUp,
        canMoveDown: canMoveAnswerDown,
        moveDown: moveAnswerDown,
    }] = useDisplayOrder(answersManager);

    // Adding of an answer.
    const [isAddingAnswer, toggleAddAnswer] = useToggleState();
    const onAnswerAdded = useCallback((shapeProps: Partial<OverlayShape>): OverlayShape => {
        // Genreate a new answer.
        const newItem = {
            ...questionAnswerDefaultValues(),

            questionId: model?.id,
            windowLeft: shapeProps.x ?? 0,
            windowTop: shapeProps.y ?? 0,
            windowRight: (shapeProps.x ?? 0) + (shapeProps.width ?? 100),
            windowBottom: (shapeProps.y ?? 0) + (shapeProps.height ?? 100),
        };

        answersManager.addFor(newItem);

        // Turn off the click to add functionality.
        toggleAddAnswer();

        // Return information to the interactive image so the new item can be selected automatically.
        return {
            ...shapeProps,
            id: newItem.id,
        } as OverlayShape;
    }, [answersManager, model?.id, toggleAddAnswer]);

    // Transforming of an overlay using the visual shape editor.
    const onAnswerOverlayTransformed = useCallback((id: string, shapeProps: OverlayShape) => {
        answersManager.changeFor(id, {
            windowLeft: shapeProps.x ?? 0,
            windowTop: shapeProps.y ?? 0,
            windowRight: (shapeProps.x ?? 0) + (shapeProps.width ?? 100),
            windowBottom: (shapeProps.y ?? 0) + (shapeProps.height ?? 100),
        });
    }, [answersManager]);

    // Generate overlays for each answer.
    const overlays: Array<OverlayShape> = useMemo(() =>
        answersManager.model.map(item => ({
            id: item.id,
            label: textFromHtml(item.answerText),
            x: item.windowLeft,
            y: item.windowTop,
            width: item.windowRight - item.windowLeft,
            height: item.windowBottom - item.windowTop,

            opacity: 0.5,
            ...responseWindowColors[0],
        } as OverlayShape))
    , [answersManager.model]);

    return (
        <>
            <FormGroup>
                <Label htmlFor="questionText">{t('questionPromptLocationResponseImageTab.preQuestionText', 'Prompt before activity')}</Label>

                <HtmlEditor value={model?.preQuestionText} onChange={html => change({ preQuestionText: html })} />
            </FormGroup>

            <FormGroup>
                <Label htmlFor="questionText">{t('questionPromptLocationResponseImageTab.questionText', 'Prompt after activity')}</Label>

                <HtmlEditor value={model?.questionText} onChange={html => change({ questionText: html })} />
            </FormGroup>

            <FormGroup>
                <Label htmlFor="answers">{t('questionPromptLocationResponseImageTab.answers', 'Response window locations')}</Label>

                <InteractiveImage src={imageBlob?.url ?? ''}
                    overlays={overlays}
                    allowAdd={isAddingAnswer}
                    onAddOverlay={onAnswerAdded}
                    allowTransform={true}
                    onOverlayTransformed={onAnswerOverlayTransformed}
                />
                <FormText>
                    {t('questionPromptLocationResponseImageTab.virtualSizeText', 'All locations and sizes are configured based on the original size of the image and are automatically scaled by the software when used to match the size of the device being used.')}
                </FormText>

                <ConditionalFragment showIf={isAddingAnswer}>
                    <FormText color="primary">
                        {t('questionPromptLocationResponseImageTab.clickToAddText', 'Click on the image to add a new response window.')}
                    </FormText>
                </ConditionalFragment>

                <div className="mt-2">
                    {
                        orderedAnswers.map(item => (
                            <QuestionAnswerLocationResponseImage key={item.id}
                                model={item}
                                change={changes => answersManager.changeFor(item.id, changes)}
                                remove={() => answersManager.removeFor(item.id)}

                                isOpenInitially={!!answersManager.added.find(it => it.id === item.id)}

                                moveUp={() => moveAnswerUp(item.id)} canMoveUp={canMoveAnswerUp(item.id)}
                                moveDown={() => moveAnswerDown(item.id)} canMoveDown={canMoveAnswerDown(item.id)}

                                validate={() => validateQuestionAnswer(item)}
                                validationErrors={questionAnswerValidationErrors(item.id)}
                                />
                            ))
                    }
                </div>

                <Button color="primary" outline={!isAddingAnswer} onClick={() => toggleAddAnswer()}>
                    {t('questionPromptLocationResponseImageTab.addAnswer', 'Add response window')}
                </Button>
            </FormGroup>

            <FormGroup>
            <Label htmlFor="maximumClicks">{t('questionPromptLocationDiscriminationImageTab.maximumClicks', 'Maximum clicks before score deduction')}</Label>
                <Input name="maximumClicks" type="number" min={0} value={model?.maximumClicks ?? ''} onChange={e => change({ maximumClicks: e.currentTarget.valueAsNumber })} />
        </FormGroup>
        </>
        );
};