import { RouteEntry } from "../../../shared/routes";
import { LaunchTraining } from "./LaunchTraining";
import { StartTraining } from "./StartTraining";
import { TrainingResults } from "./TrainingResults";


export const assessmentSessionTrainingRoutes: Array<RouteEntry> = [
    { path: '/training/start/:assessmentId', exact: true, component: StartTraining, authorize: true, },
    { path: '/training/startFor/:userId/:assessmentId', exact: true, component: StartTraining, authorize: true, },
    { path: '/training/launch/:id', component: LaunchTraining, authorize: true, },
    { path: '/training/results/:id', component: TrainingResults, authorize: true, },
];
