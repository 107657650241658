import moment from "moment";
import { useCallback, useMemo } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";
import { Badge, Button, ButtonGroup, Col, Collapse, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText, Row } from "reactstrap";
import { useToggleStateArray } from "use-toggle-state";
import { Assessment } from "../../../api/main/models/Assessment";
import { AssessmentSession } from "../../../api/main/models/AssessmentSession";
import { AssessmentType } from "../../../api/main/models/codeOnly/AssessmentType";
import { DriverMetric } from "../../../api/main/models/DriverMetric";
import { UserAssessmentAssignment } from "../../../api/main/models/UserAssessmentAssignment";
import { UserDriverMetric } from "../../../api/main/models/UserDriverMetric";
import { RiskRules } from "../../../services/OverallRiskService";
import { HtmlDisplay } from "../../../shared/htmlEditor";
import { questionScoreFromNumber } from "../../assessmentSessions/launch/utilities/questionScoreFromNumber";
import { DueDateLabel } from "../../me/DueDateLabel";
import { useAverageScoreChartData } from "../chartData/useAverageScoreChartData";
import { useDriverMetricsChartData } from "../chartData/useDriverMetricsChartData";
import { AverageScoreRadarChart } from "../charts/AverageScoreRadarChart";
import { DriverMetricsRadarChart } from "../charts/DriverMetricsRadarChart";
import { DriverScoreProgressBarAsChart } from "../charts/DriverScoreProgressBarAsChart";
import { IndividualScoreProgressBar } from "../progress/IndividualScoreProgressBar";
import { DashboardSummary } from "../utilities/generateDashboardSummary";
import { getLatestSessions } from "../utilities/getLatestSessions";
import { useCachedDashboardSummaries } from "../utilities/useCachedDashboardSummaries";
import "./userProgressDashboardTab.scss";

export interface UserProgressDashboardTabProps {
    assessmentType: AssessmentType,

    assessments: Array<Assessment>,
    generateDashboardSummary: (
        sessionFilter: (session: AssessmentSession) => boolean,
        assignmentFilter: (assignment: UserAssessmentAssignment) => boolean,
        assessmentType: AssessmentType
    ) => DashboardSummary,

    assessmentSessions: Array<AssessmentSession>,
    expandedUserAssessmentAssignments: Array<UserAssessmentAssignment>,

    driverMetrics: Array<DriverMetric>,
    userDriverMetrics: Array<UserDriverMetric>,

    riskRules: RiskRules | undefined | null,
    isCurrentUser: boolean,
}

/**
 * Tab for the dasboard that shows under a user to show their assigned assessments/training by status.
 * @param props
 */
export const UserProgressDashboardTab = (props: UserProgressDashboardTabProps) => {
    const {
        assessments: allAssessments,
        assessmentType,

        generateDashboardSummary,

        assessmentSessions,
        expandedUserAssessmentAssignments,

        driverMetrics,
        userDriverMetrics,

        riskRules,
        isCurrentUser,
    } = props;

    const { t } = useTranslation();


    const assessments = useMemo(() => {
        return allAssessments.filter(item => item.assessmentType === assessmentType);
    }, [allAssessments, assessmentType]);

    // Method we use to filter sessions by assessment and user wherever we need it.
    const filterByAssessment = useCallback((assessment: { id: string }, item: { assessmentId: string | undefined | null }) => {
        return item.assessmentId === assessment.id;
    }, []);

    // Cache the dashboard summaries so each chart/display doesn't need to recaclulate them and they can be looked up easily by id.
    const getCachedDashboardSummary = useCachedDashboardSummaries(
        assessments,
        (group, assessmentType) => generateDashboardSummary(
            session => filterByAssessment(group, session),
            assignment => filterByAssessment(group, assignment),
            assessmentType
        )
    );

    // Get the latest session for each assessment.
    const { latestSessions, latestSessionsIncludingIncomplete } = useMemo(() => getLatestSessions(assessmentSessions ?? []), [assessmentSessions]);

    // Split the assessments up into groups based on the user's progress.
    const { notStarted, started, complete, markedSuspicious } = useMemo(() => {
        let notStarted: Array<Assessment> = [];
        let started: Array<Assessment> = [];
        let complete: Array<Assessment> = [];
        let markedSuspicious: Array<Assessment> = [];


        for (const assessment of assessments) {
            const assignment = expandedUserAssessmentAssignments.find(it => it.assessmentId === assessment.id);
            const session = latestSessionsIncludingIncomplete.find(it => it.assessmentId === assessment.id);

            // If we have no session, then check if its assigned or not.
            if (!session) {
                if (!assignment) {
                    // User has nothing to do with this assessment, just skip it.
                    continue;
                }

                // Add to the notStarted.
                notStarted.push(assessment);
            } else {
                // If its complete add it to the complete list.
                if (session.isFinished && !session.markedSuspicious) {
                    complete.push(assessment);
                } else if (session.isFinished && session.markedSuspicious) {
                    // If it is complete and marked suspicious we want to seperate these assessments.
                    markedSuspicious.push(assessment);
                } else {
                    // Otherwise add it to the started list.
                    started.push(assessment);
                }
            }
        };

        return {
            notStarted,
            started,
            complete,
            markedSuspicious,
        };
    }, [assessments, latestSessionsIncludingIncomplete, expandedUserAssessmentAssignments]);

    // Get data for the various charts.
    const averageScoreChartData = useAverageScoreChartData(assessments, getCachedDashboardSummary, assessmentType);
    const driverMetricsChartData = useDriverMetricsChartData(driverMetrics, userDriverMetrics);

    // Track which histories are expanded.
    const [isHistoryExpandedFor, toggleHistoryExpandedFor] = useToggleStateArray<string>([]);

    // Generate the display items for the passed in assignments.
    const generateItems = (myAssessments: Array<Assessment>) => {
        return myAssessments.map(assessment => {
            const latestCompleteSession = latestSessions.find(it => it.assessmentId === assessment.id);
            const latestSessionIncludingIncomplete = latestSessionsIncludingIncomplete.find(it => it.assessmentId === assessment.id);
            let assignment = expandedUserAssessmentAssignments.find(it => it.assessmentId === assessment.id && !it.subscriptionTeamId);
            if (!assignment) {
                assignment = expandedUserAssessmentAssignments.find(it => it.assessmentId === assessment.id && !!it.subscriptionTeamId);
            }

            // Get any historic completions.
            let allMyHistoricCompleteSessions = assessmentSessions
                .filter(it => it.assessmentId === assessment.id && it.isFinished)
                .filter(it => it.id !== latestSessionIncludingIncomplete?.id);
            // Flip the order.
            allMyHistoricCompleteSessions.sort((a, b) => {
                if (a.startDate === b.startDate) {
                    return 0;
                } else if (a.startDate < b.startDate) {
                    return 1;
                } else {
                    return -1;
                }
            });

            const launchBaseRoute = assessment.assessmentType === AssessmentType.TrainingModule ? '/training' : '/assessment';

            return (
                <ListGroupItem key={assessment.id} className="user-progress-dashboard-tab-item">
                    <Row>
                        <Col xs={12} md="">
                            <ListGroupItemHeading>
                                {assessment.name}
                            </ListGroupItemHeading>
                            <ListGroupItemText tag="div">
                                <HtmlDisplay html={assessment.tileText} textOnly={true} />
                            </ListGroupItemText>


                            <Row noGutters className="mt-2">
                                <Col>
                                    <ConditionalFragment showIf={!latestSessionIncludingIncomplete}>
                                        <ConditionalFragment showIf={isCurrentUser}>
                                            <LinkContainer to={`${launchBaseRoute}/start/${assessment.id}`}>
                                                <Button color="primary">
                                                    {
                                                        assessment.assessmentType === AssessmentType.TrainingModule ? t('dashboardAssessmentCard.start.trainingModule', 'Start training')
                                                            : t('dashboardAssessmentCard.start.default', 'Start assessment')
                                                    }
                                                </Button>
                                            </LinkContainer>
                                        </ConditionalFragment>
                                    </ConditionalFragment>
                                    <ConditionalFragment showIf={!!latestSessionIncludingIncomplete && !latestSessionIncludingIncomplete?.isFinished}>
                                        <ConditionalFragment showIf={isCurrentUser}>
                                            <ButtonGroup>
                                                <LinkContainer to={`${launchBaseRoute}/launch/${latestSessionIncludingIncomplete?.id ?? ''}`}>
                                                    <Button color="primary">
                                                        {
                                                            assessment.assessmentType === AssessmentType.TrainingModule ? t('dashboardAssessmentCard.continue.trainingModule', 'Continue training')
                                                                : t('dashboardAssessmentCard.continue.default', 'Continue assessment')
                                                        }
                                                    </Button>
                                                </LinkContainer>
                                                <LinkContainer to={`${launchBaseRoute}/start/${assessment.id}`}>
                                                    <Button color="primary" outline>
                                                        {
                                                            assessment.assessmentType === AssessmentType.TrainingModule ? t('dashboardAssessmentCard.restartInProgress.trainingModule', 'Restart')
                                                                : t('dashboardAssessmentCard.restartInProgress.default', 'Restart')
                                                        }
                                                    </Button>
                                                </LinkContainer>
                                            </ButtonGroup>
                                        </ConditionalFragment>
                                    </ConditionalFragment>
                                    <ConditionalFragment showIf={!!latestSessionIncludingIncomplete && !!latestSessionIncludingIncomplete?.isFinished}>
                                        <ButtonGroup>
                                            <LinkContainer to={`${launchBaseRoute}/results/${latestSessionIncludingIncomplete?.id ?? ''}`}>
                                                <Button color="primary" outline>
                                                    {
                                                        assessment.assessmentType === AssessmentType.TrainingModule ? t('dashboardAssessmentCard.view.trainingModule', 'View results')
                                                            : t('dashboardAssessmentCard.view.default', 'View results')
                                                    }
                                                </Button>
                                            </LinkContainer>
                                        </ButtonGroup>
                                    </ConditionalFragment>
                                </Col>
                            </Row>
                        </Col>

                        <Col xs={12} md="auto">
                            <div className="user-progress-dashboard-tab-item-right-side">
                                <div className="user-progress-dashboard-tab-item-right-side-score">
                                    {
                                        latestCompleteSession && latestCompleteSession?.id !== latestSessionIncludingIncomplete?.id ? (
                                            <IndividualScoreProgressBar
                                                assessmentType={assessmentType}
                                                value={questionScoreFromNumber(latestCompleteSession?.totalScore ?? 0, false, riskRules)}
                                                title={t('userProgressDashboardTab.previousScore.title', 'Previous score')}
                                                footer={t('userProgressDashboardTab.previousScore.footer', 'Previously completed on {{date, DD/MM/YYYY}}', { date: moment(latestCompleteSession?.endDate) })}
                                            />
                                        ) : latestCompleteSession ? (
                                            <IndividualScoreProgressBar
                                                assessmentType={assessmentType}
                                                value={questionScoreFromNumber(latestCompleteSession?.totalScore ?? 0, false, riskRules)}
                                            />
                                        ) : null
                                    }
                                </div>
                                <div className="user-progress-dashboard-tab-item-right-side-attempts">
                                    <span>
                                        {
                                            assessmentSessions.filter(item => !item.isFinished && item.assessmentId === assessment.id && item.id !== latestSessionIncludingIncomplete?.id).length !== 0 ? (
                                                <>
                                                    {t('userProgressDashboardTab.restartCount', `This user had ${assessmentSessions.filter(item => !item.isFinished && item.assessmentId === assessment.id && item.id !== latestSessionIncludingIncomplete?.id).length} unfinished attempts before their latest attempt.`)}
                                                </>
                                            ) : null
                                        }
                                    </span>
                                </div>
                                <div className="user-progress-dashboard-tab-item-right-side-date">
                                    <DueDateLabel dueDate={assignment?.targetDate} completedDate={latestSessionIncludingIncomplete?.endDate} />
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <ConditionalFragment showIf={!!allMyHistoricCompleteSessions.length}>
                        <Button color="link" onClick={() => toggleHistoryExpandedFor(assessment.id)}>
                            {
                                isHistoryExpandedFor(assessment.id) ? t('userProgressDashboardTab.hideHistoricalResults', 'Hide all previous results')
                                    : t('userProgressDashboardTab.viewHistoricalResults', 'View all previous results')
                            }
                            <> </>
                            <Badge>
                                {t('common.integer', '{{count, #,0}}', { count: allMyHistoricCompleteSessions.length })}
                            </Badge>
                        </Button>

                        <Collapse isOpen={isHistoryExpandedFor(assessment.id)}>
                            <ListGroup>
                                {
                                    allMyHistoricCompleteSessions.map(session => (
                                        <ListGroupItem key={session.id}>
                                            <Row>
                                                <Col>
                                                    <div className="mb-2">
                                                        <DueDateLabel dueDate={session.startDate} completedDate={session.endDate} />
                                                    </div>

                                                    <ButtonGroup>
                                                        <LinkContainer to={`${launchBaseRoute}/launch/${session.id ?? ''}`}>
                                                            <Button color="primary" outline>
                                                                {
                                                                    assessment.assessmentType === AssessmentType.TrainingModule ? t('dashboardAssessmentCard.viewHistoric.trainingModule', 'View historic results')
                                                                        : t('dashboardAssessmentCard.viewHistoric.default', 'View historic results')
                                                                }
                                                            </Button>
                                                        </LinkContainer>
                                                    </ButtonGroup>
                                                </Col>

                                                <Col xs={12} md="auto">
                                                    <div className="user-progress-dashboard-tab-item-right-side">
                                                        <div className="user-progress-dashboard-tab-item-right-side-score">
                                                            <IndividualScoreProgressBar
                                                                assessmentType={assessmentType}
                                                                value={questionScoreFromNumber(session.totalScore ?? 0, false, riskRules)}
                                                                title={t('userProgressDashboardTab.historicScore.title', 'Historic score')}
                                                            />
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </ListGroupItem>
                                    ))
                                }
                            </ListGroup>
                        </Collapse>
                    </ConditionalFragment>
                </ListGroupItem>
            );
        })
    };

    return (
        <div className="user-progress-dashboard-tab">
            <Row>
                <Col xs={12} lg="">
                    {/* Not started */}
                    <div className="mb-2">
                        <ListGroup>
                            <ListGroupItem color="danger">
                                <h5>
                                    {t('userProgressDashboardTab.notStarted.heading', 'Not started')}
                                    <> </>
                                    <Badge pill>
                                        {notStarted.length}
                                    </Badge>
                                </h5>
                            </ListGroupItem>
                            {
                                generateItems(notStarted)
                            }
                        </ListGroup>
                    </div>

                    {/* Started */}
                    <div className="mb-2">
                        <ListGroup>
                            <ListGroupItem color="warning">
                                <h5>
                                    {t('userProgressDashboardTab.started.heading', 'Started')}
                                    <> </>
                                    <Badge pill>
                                        {started.length}
                                    </Badge>
                                </h5>
                            </ListGroupItem>
                            {
                                generateItems(started)
                            }
                        </ListGroup>
                    </div>

                    {/* Complete */}
                    <div className="mb-2">
                        <ListGroup>
                            <ListGroupItem color="success">
                                <h5>
                                    {t('userProgressDashboardTab.complete.heading', 'Completed')}
                                    <> </>
                                    <Badge pill>
                                        {complete.length}
                                    </Badge>
                                </h5>
                            </ListGroupItem>
                            {
                                generateItems(complete)
                            }
                        </ListGroup>
                    </div>

                    {/* Marked Suspicious */}
                    <div className="mb-2">
                        <ListGroup>
                            <ListGroupItem color="info">
                                <h5>
                                    {t('userProgressDashboardTab.markedSuspicious.heading', 'Marked Suspicious')}
                                    <> </>
                                    <Badge pill>
                                        {markedSuspicious.length}
                                    </Badge>
                                </h5>
                            </ListGroupItem>
                            {
                                generateItems(markedSuspicious)
                            }
                        </ListGroup>
                    </div>
                </Col>
                <Col>
                    <ConditionalFragment showIf={assessmentType === AssessmentType.Assessment}>
                        <DriverScoreProgressBarAsChart userDriverMetrics={userDriverMetrics} driverMetrics={driverMetrics} riskRules={riskRules} />
                        <DriverMetricsRadarChart data={driverMetricsChartData} />
                    </ConditionalFragment>
                    <AverageScoreRadarChart data={averageScoreChartData} assessmentType={assessmentType} riskRules={riskRules} />
                </Col>
            </Row>
        </div>
    );
};