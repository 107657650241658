import * as React from 'react';
import { AssessmentFeedbackCreateInput, AssessmentFeedbackUpdateInput } from '../generated/globalTypes';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useCreateAssessmentFeedbackCallback } from './useCreateAssessmentFeedbackCallback';
import { useUpdateAssessmentFeedbackCallback } from './useUpdateAssessmentFeedbackCallback';
import { AssessmentFeedback } from '../models/AssessmentFeedback';

/**
 * Get a callback to save a AssessmentFeedback in the store using either a create or update.
 */
export function useSaveAssessmentFeedbackCallback(options: SaveInStoreOptions<AssessmentFeedback, string> = {}) {
    const [_create, createStatus] = useCreateAssessmentFeedbackCallback();
    const create = React.useCallback((model: Partial<AssessmentFeedback>) => _create(model as AssessmentFeedbackCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateAssessmentFeedbackCallback();
    const update = React.useCallback((id: string, changes: Partial<AssessmentFeedback>) => _update(id, changes as AssessmentFeedbackUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
