import { Button, Row, Col, Form, Label, FormGroup, Spinner } from 'reactstrap';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContainer } from '../shared/MainContainer';
import { useParams, useHistory } from 'react-router';
import { useSubscriptionTeam } from '../../api/main/subscriptionTeams/useSubscriptionTeam';
import { useChanges } from '../../shared/useChanges';
import { useSaveSubscriptionTeamCallback } from '../../api/main/subscriptionTeams/useSaveSubscriptionTeamCallback';
import { useValidatorCallback } from 'pojo-validator-react';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { FormButtons } from '../shared/FormButtons';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { useAsyncCallback } from 'react-use-async-callback';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Banner } from '../shared/Banner';
import { Background } from '../shared/background/Background';
import { subscriptionTeamDefaultValues } from '../../api/main/models/SubscriptionTeam';
import { useCurrentUserOrEmulatedSubscriptionId } from '../../globalState/subscriptions/useCurrentUserOrEmulatedSubscriptionId';
import { SubscriptionTeamNavigation } from './SubscriptionTeamNavigation';
import { PillsNavBar } from '../shared/pillsNavBar/PillsNavBar';

interface EditSubscriptionTeamProps {
    isCreate?: boolean,
}

/**
 * Create a new subscription team.
 */
export const CreateSubscriptionTeam = () => (<EditSubscriptionTeam isCreate={true} />);

/**
 * Edit a subscription team.
 */
export const EditSubscriptionTeam = (props: EditSubscriptionTeamProps) => {
    const { isCreate } = props;

    const { t } = useTranslation();
    const { id } = useParams<{ id: string | undefined }>();

    const subscriptionId = useCurrentUserOrEmulatedSubscriptionId();

    const { data: { model: storeModel }, isLoading: _isLoading, errors: loadErrors } = useSubscriptionTeam(id);
    const isLoading = _isLoading;
    const { model, change, changes } = useChanges(storeModel, isCreate ? { ...subscriptionTeamDefaultValues(), subscriptionId: subscriptionId ?? undefined } : undefined);
    const [save, { errors: saveErrors }] = useSaveSubscriptionTeamCallback();
    const history = useHistory();

    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            name: () => !model?.name ? t('editSubscriptionTeam.name.required', 'Name is required') : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, [model]);

    const [saveForm, { isExecuting: isSaving, errors: saveFormErrors }] = useAsyncCallback(async (options?: { navigateTo?: string, }) => {
        if (!validate()) {
            return;
        }

        await save(model.id, changes, !!isCreate);

        // Navigate to the next screen.
        if (options?.navigateTo) {
            history.push(options.navigateTo);
        } else {
            history.goBack();
        }
    }, [validate, save, model, changes, isCreate, history]);

    return (
        <Background>
            <Banner>
                <Row>
                    <Col xs={12} md="auto">
                        <h1>
                            {
                                isCreate ? (
                                    <>{t('editSubscriptionTeam.createHeading', 'Add team')}</>
                                ) : (
                                        <>{t('editSubscriptionTeam.editHeading', 'Edit team')}</>
                                    )
                            }
                        </h1>
                    </Col>
                    <Col>
                        <PillsNavBar>
                            <SubscriptionTeamNavigation
                                id={model?.id ?? ''}
                                editLink={history.location.pathname}
                                onNavigate={link => saveForm({ navigateTo: link })}
                            />
                        </PillsNavBar>
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
            </Banner>

            <MainContainer>
                <AlertOnErrors errors={[loadErrors, saveFormErrors, saveErrors]} />

                <Form onSubmit={e => { e.preventDefault(); saveForm(); }}>
                    <FormGroup>
                        <Label htmlFor="name">{t('editSubscriptionTeam.name.label', 'Name')}</Label>
                        <ValidatedInput name="name" type="text" value={model.name ?? ''} onChange={e => change({ name: e.currentTarget.value })} onBlur={e => validate('name')} validationErrors={validationErrors['name']} />
                    </FormGroup>
                    <FormButtons>
                        <ConditionalFragment showIf={!isLoading}>
                            <ButtonAsync color="primary" isExecuting={isSaving}
                                executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}>
                                <FontAwesomeIcon icon="save" />
                                <> {t('common.save', 'Save')}</>
                            </ButtonAsync>
                        </ConditionalFragment>
                        <Button type="button" color="primary" outline onClick={e => history.goBack()}>
                            {t('common.cancel', 'Cancel')}
                        </Button>
                    </FormButtons>
                </Form>
            </MainContainer>
        </Background>
    );
};
