import { QuestionType } from "../../../api/main/models/codeOnly/QuestionType";
import { CreateQuestionBase, EditQuestionBase } from "../edit/EditQuestionBase";

/**
 * Create a learning activity.
 */
export const CreateLearning = () => (
    <CreateQuestionBase
        defaultQuestionType={QuestionType.LearningVideo}
        onCreateDefaultValues={() => ({ /* No defaults needed */ })}
    />);

/**
 * Edit a learning activity.
 */
export const EditLearning = () => (<EditQuestionBase defaultQuestionType={QuestionType.LearningVideo} />);
