import * as React from 'react';
import { AssessmentItemDriverMetricQuantityCreateInput, AssessmentItemDriverMetricQuantityUpdateInput } from '../generated/globalTypes';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useCreateAssessmentItemDriverMetricQuantityCallback } from './useCreateAssessmentItemDriverMetricQuantityCallback';
import { useUpdateAssessmentItemDriverMetricQuantityCallback } from './useUpdateAssessmentItemDriverMetricQuantityCallback';
import { AssessmentItemDriverMetricQuantity } from '../models/AssessmentItemDriverMetricQuantity';

/**
 * Get a callback to save a AssessmentItem in the store using either a create or update.
 */
export function useSaveAssessmentItemDriverMetricQuantityCallback(options: SaveInStoreOptions<AssessmentItemDriverMetricQuantity, string> = {}) {
    const [_create, createStatus] = useCreateAssessmentItemDriverMetricQuantityCallback();
    const create = React.useCallback((model: Partial<AssessmentItemDriverMetricQuantity>) => _create(model as AssessmentItemDriverMetricQuantityCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateAssessmentItemDriverMetricQuantityCallback();
    const update = React.useCallback((id: string, changes: Partial<AssessmentItemDriverMetricQuantity>) => _update(id, changes as AssessmentItemDriverMetricQuantityUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
