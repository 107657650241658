import { QuestionResponseEvent } from "../../../../api/main/models/QuestionResponseEvent";
import { OverallRiskCategory, overallRiskService, RiskRules } from "../../../../services/OverallRiskService";

export interface QuestionScore {
    score: number,
    minScore: number,
    maxScore: number,
    riskCategory: OverallRiskCategory,
}

/**
 * Calculate the question score for a question.  Result will always be between 0 and 10.
 */
export function calculateQuestionScore(responses: Array<QuestionResponseEvent>, riskRules?: RiskRules | undefined | null): QuestionScore  {
    let score: number = 0;
    for (const response of responses) {
        score += response.score;
    }

    if (score < 0) {
        score = 0;
    }

    if (score > 100) {
        score = 100;
    }

    var notStarted = true
    if (responses.length > 0) {
        notStarted = false
    }

    const riskCategory = overallRiskService.getRiskCategory(score, notStarted, riskRules);

    return {
        score,
        minScore: 0,
        maxScore: 100,
        riskCategory,
    };
}