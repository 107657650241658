import { useCallback, useMemo, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Button, Col, Row } from "reactstrap";
import { QuestionAnswer } from "../../../../../api/main/models/QuestionAnswer";
import { HtmlDisplay } from "../../../../../shared/htmlEditor";
import { usePrefetchImages } from "../../../../../shared/prefetchImage";
import { ShowQuestionChildProps } from "../ShowQuestion";
import "./showQuestionMultipleChoiceImage.scss";

/**
 * Running of a MultipleChoiceImage question.
 * @param props
 */
export const ShowQuestionMultipleChoiceImage = (props: ShowQuestionChildProps) => {
    const {
        model,
        answers,
        blobReferences,

        selectedAnswerId,
        selectSingleAnswer,
        questionResponseEventsManager,

        onPageComplete,
    } = props;

    const { t } = useTranslation();

    const imageBlob = useMemo(() => blobReferences.find(it => it.id === model.imageBlobReferenceId), [blobReferences, model]);

    // Some calls to apis (such as saving clicks) will cause us to generate a new secure image URL.  To make sure this doesn't reset out
    // image we cache the poster and image urls as we original receive them into our state and always use them.
    const [imageUrl] = useState<string>(imageBlob?.url ?? '');

    // Prefetch the images we use.
    const resolveImageUrl = usePrefetchImages([
        imageUrl,
        ...answers.map(answer => {
            const blob = blobReferences.find(item => item.id === answer.answerTextImageBlobReferenceId);
            return blob?.url ?? '';
        }),
    ]);

    const onSelectSingleAnswer = useCallback((answer: QuestionAnswer | undefined) => {
        selectSingleAnswer(answer, model);

        // Let the navigation know this question has been completed.
        onPageComplete();
    }, [selectSingleAnswer, onPageComplete, model]);

    return (
        <div className="show-question-multiple-choice-image">

            <div className="embed-responsive embed-responsive-16by9 mb-4">
                <img className="embed-responsive-item" src={resolveImageUrl(imageUrl)} alt="Scene" />
            </div>

            <HtmlDisplay html={model.questionText} />

            <div className="show-question-multiple-choice-image-answer-overlay">
                <Row>
                    {
                        answers.map(answer => {
                            const answerBlob = blobReferences.find(it => it.id === answer.answerTextImageBlobReferenceId);

                            return (
                                <Col key={answer.id} xs={12} md={6}>
                                    <Button className="show-question-multiple-choice-image-answer-button"
                                        color={selectedAnswerId === answer.id? 'primary': 'secondary'}

                                        onClick={() => onSelectSingleAnswer(answer)}
                                    >
                                        <ConditionalFragment showIf={!!answerBlob}>
                                            <img className="fluid-img" src={resolveImageUrl(answerBlob?.url ?? '')} alt="" style={{ maxWidth: '100%'}} />
                                        </ConditionalFragment>

                                        <HtmlDisplay html={answer.answerText} />
                                    </Button>
                                </Col>
                            );
                        })
                    }
                </Row>
            </div>

            <ConditionalFragment showIf={!!questionResponseEventsManager.model.filter(item => item.questionId === model.id && !item.archived).length}>
                <p className="text-muted">
                    {t('showQuestionMultipleChoiceImage.endText', 'Press Next to confirm your answer and continue.')}
                </p>
            </ConditionalFragment>
        </div>
        );
};