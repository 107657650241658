import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useMemo, KeyboardEvent } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Col, Collapse, Row } from "reactstrap";
import { useToggleState } from "use-toggle-state";
import { WebhookEventTypeButton } from "./WebhookEventTypeButton";
import "./webhookEventTypeSelector.scss";

export interface WebhookEventTypeSelectorProps {
    eventTypes: Array<string>,
    isSelected: (id: string) => boolean,
    toggleSelected?: (id: string) => void,
}

/**
 * Selector for toggling a question tag on or off.
 */
export const WebhookEventTypeSelector = (props: WebhookEventTypeSelectorProps) => {
    const {
        eventTypes,
        isSelected,
        toggleSelected,
    } = props;

    const { t } = useTranslation();

    const [isOpen, toggleOpen] = useToggleState();

    const selectedEventTypes = useMemo(() => {
        return eventTypes.filter(item => isSelected(item));
    }, [eventTypes, isSelected]);

    // Handle keyboard keys in a way that let us act select like.
    const keyDown = useCallback((event: KeyboardEvent<HTMLDivElement>) => {
        switch (event.key) {
            case 'Down':
            case 'ArrowDown':
            case 'ArrowUp':
            case 'Esc':
            case 'Escape':
            case ' ':
            case 'Enter':
                toggleOpen();
                break;
            default:
                break;
        }
    }, [toggleOpen]);

    return (
        <div className="webhook-event-type-selector form-control" tabIndex={0} onKeyDown={keyDown}>
            <div className="webhook-event-type-selector-input" onClick={() => toggleOpen()}>
                <Row>
                    <Col>
                        {
                            selectedEventTypes
                                .map(item => (
                                    <WebhookEventTypeButton key={item}
                                        eventType={item}
                                        isSelected={true}
                                        readonly={true}
                                        gray={isOpen}
                                    />
                                ))
                        }
                        <ConditionalFragment showIf={!selectedEventTypes.length}>
                            <span className="text-muted" style={{ userSelect: 'none' }}>
                                {t('webhookEventTypeSelector.nothingSelected', 'No events have been selected.')}
                            </span>
                        </ConditionalFragment>
                    </Col>
                    <Col xs="auto">
                        <FontAwesomeIcon icon={isOpen ? 'caret-up' : 'caret-down'} />
                    </Col>
                </Row>
            </div>
            <Collapse isOpen={isOpen}>
                <div className="webhook-event-type-selector-dropdown">
                    {
                        eventTypes.map(item => (
                            <WebhookEventTypeButton key={item}
                                eventType={item}
                                isSelected={isSelected(item)}
                                toggleSelected={() => {
                                    if (toggleSelected) {
                                        toggleSelected(item);
                                    }
                                }}
                            />
                        ))
                    }
                </div>
            </Collapse>
        </div>
        );
};
