import * as React from 'react';
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { AsyncLoadResult, AsyncLoadOptions } from '../../../../shared/abstractStore';
import { editQuestionViewModelQuery, editQuestionViewModelQueryVariables } from '../../generated/editQuestionViewModelQuery';
import { mainApiConfig } from '../../../../configure/mainApiConfig';
import { questionFieldsFragment } from '../../models/Question';
import { blobReferenceFieldsFragment } from '../../models/BlobReference';
import { Guid } from 'guid-string';
import { questionAnswerFieldsFragment } from '../../models/QuestionAnswer';
import { questionFeedbackFieldsFragment } from '../../models/QuestionFeedback';
import { questionTagLinkFieldsFragment } from '../../models/QuestionTagLink';
import { driverMetricRecommendationFieldsFragment } from '../../models/DriverMetricRecommendation';
import { driverMetricAdjustmentFieldsFragment } from '../../models/DriverMetricAdjustment';
import { questionSequenceQuestionFieldsFragment } from '../../models/QuestionSequenceQuestion';

/**
 * View model for EditQuestion that replaces the call to useQuestion() and loads the right related data.
 * @param options
 */
export function useEditQuestionViewModel(id: string | undefined | null, options: AsyncLoadOptions = {}): AsyncLoadResult<editQuestionViewModelQuery> {
    // Query the data we need form the api.
    const [load, { data, loading, error, refetch }] = useLazyQuery<editQuestionViewModelQuery, editQuestionViewModelQueryVariables>(
        gql`
        query editQuestionViewModelQuery ($id: ID!) {
            model: question(id: $id) {
                ...questionFields

                videoBlobReference {
                    ...blobReferenceFields
                }

                imageBlobReference {
                    ...blobReferenceFields
                }

                questionTextImageBlobReference {
                    ...blobReferenceFields
                }
            }

            answers: questionAnswers(questionId: $id) {
                ...questionAnswerFields

                answerTextImageBlobReference {
                    ...blobReferenceFields
                }

                scaleImageBlobReference {
                    ...blobReferenceFields
                }
            }

            questionnaireQuestionAnswers: questionAnswers(questionnaireQuestionId: $id) {
                ...questionAnswerFields

                answerTextImageBlobReference {
                    ...blobReferenceFields
                }

                scaleImageBlobReference {
                    ...blobReferenceFields
                }
            }

            feedbacks: questionFeedbacks(questionId: $id) {
                ...questionFeedbackFields

                videoBlobReference {
                    ...blobReferenceFields
                }

                imageBlobReference {
                    ...blobReferenceFields
                }
            }

            questionTagLinks: questionTagLinks(targetId: $id) {
                ...questionTagLinkFields
            }

            driverMetricRecommendations: driverMetricRecommendations(targetId: $id) {
                ...driverMetricRecommendationFields
            }

            driverMetricAdjustments: driverMetricAdjustments(targetId: $id) {
                ...driverMetricAdjustmentFields
            }

            questionSequenceQuestions: questionSequenceQuestions(parentQuestionId: $id) {
                ...questionSequenceQuestionFields

                childQuestion {
                    ...questionFields
                }
            }
        }

        ${questionFieldsFragment}
        ${blobReferenceFieldsFragment}
        ${questionAnswerFieldsFragment}
        ${questionFeedbackFieldsFragment}
        ${questionTagLinkFieldsFragment}
        ${driverMetricRecommendationFieldsFragment}
        ${driverMetricAdjustmentFieldsFragment}
        ${questionSequenceQuestionFieldsFragment}
        `,
        {
            variables: {
                id: id ?? Guid.empty
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load])

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error
    }), [data, refresh, loading, error]);
}