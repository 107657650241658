import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonGroup, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Label, Spinner } from "reactstrap";
import { BlobReference } from "../../../../api/main/models/BlobReference";
import { Question } from "../../../../api/main/models/Question";
import { QuestionAnswer, questionAnswerDefaultValues } from "../../../../api/main/models/QuestionAnswer";
import { HtmlEditor } from "../../../../shared/htmlEditor";
import { ModelArrayChanges } from "../../../../shared/useChanges";
import { FileUploadButton } from "../../../shared/fileUploadButton/FileUploadButton";
import { UploadedImagePreview } from "../../../shared/uploadedImagePreview/UploadedImagePreview";
import { QuestionAnswerSlidingScaleVideo } from "./QuestionAnswerSlidingScaleVideo";
import { useDisplayOrder } from "../../../shared/useDisplayOrder/useDisplayOrder";
import { useToggleState } from "use-toggle-state";
import { ValidationErrors } from "pojo-validator";
import { SlidingScaleSlider } from "./SlidingScaleSlider";

export interface QuestionPromptSlidingScaleVideoTabProps {
    model: Question | undefined,
    change: (changes: Partial<Question>) => void,

    questionTextImageBlob: BlobReference | undefined | null,
    uploadQuestionTextImageBlob: (files: FileList | null) => void,
    isUploadingQuestionTextImageBlob: boolean,
    clearQuestionTextImageBlob: () => void,

    answersManager: ModelArrayChanges<QuestionAnswer, string>,

    childBlobs: Array<BlobReference>,
    uploadChildBlob: (files: FileList | null) => Promise<BlobReference | null>,

    validateQuestionAnswer: (model: QuestionAnswer) => boolean,
    questionAnswerValidationErrors: (id: string) => ValidationErrors,
}

/**
 * Tab for maintaining the prompt and scale answers for SlidingScaleVideo questions.
 * @param props
 */
export const QuestionPromptSlidingScaleVideoTab = (props: QuestionPromptSlidingScaleVideoTabProps) => {
    const {
        model,
        change,

        questionTextImageBlob, uploadQuestionTextImageBlob, isUploadingQuestionTextImageBlob, clearQuestionTextImageBlob,
        answersManager,
        childBlobs,
        uploadChildBlob,

        validateQuestionAnswer,
        questionAnswerValidationErrors,
    } = props;

    const { t } = useTranslation();

    // Order the answers so they show in and can be managed by displayOrder.
    const [orderedAnswers, {
        canMoveUp: canMoveAnswerUp,
        moveUp: moveAnswerUp,
        canMoveDown: canMoveAnswerDown,
        moveDown: moveAnswerDown,
    }] = useDisplayOrder(answersManager);
    

    // Adding of an answer.
    const addAnswer = useCallback(() => {
        answersManager.addFor({
            ...questionAnswerDefaultValues(),

            questionId: model?.id,
        });
    }, [answersManager, model?.id]);

    const [isQuestionTextImageDropdownOpen, toggleQuestionTextImageDropdown] = useToggleState();

    return (
        <>
            <FormGroup>
                <Label htmlFor="questionText">{t('questionPromptSlidingScaleVideoTab.preQuestionText', 'Prompt before video')}</Label>

                <HtmlEditor value={model?.preQuestionText} onChange={html => change({ preQuestionText: html })} />
            </FormGroup>
            <FormGroup>
                <Label htmlFor="questionText">{t('questionPromptSlidingScaleVideoTab.questionText', 'Prompt after video')}</Label>

                <HtmlEditor value={model?.questionText} onChange={html => change({ questionText: html })} />
            </FormGroup>
            <FormGroup>
                <Label htmlFor="questionTextImageBlobReferenceId">{t('questionPromptSlidingScaleVideoTab.questionTextImageBlobReferenceId', 'Image to show at the end of the video')}</Label>

                <UploadedImagePreview src={questionTextImageBlob?.url ?? ''} />

                <ButtonGroup>
                    <FileUploadButton onUpload={files => uploadQuestionTextImageBlob(files)} isExecuting={isUploadingQuestionTextImageBlob}
                        executingChildren={<><Spinner size="sm" /><> </>{t('common.uploading', 'Uploading...')}</>}
                    >
                        {t('questionPromptSlidingScaleVideoTab.uploadPromotionalImage', 'Upload image...')}
                    </FileUploadButton>
                    <Dropdown isOpen={isQuestionTextImageDropdownOpen} toggle={() => toggleQuestionTextImageDropdown()}>
                        <DropdownToggle color="primary" outline caret>
                            <span className="sr-only">
                                {t('common.more', 'More')}
                            </span>
                        </DropdownToggle>
                        <DropdownMenu right>
                            <DropdownItem className="text-danger" onClick={() => clearQuestionTextImageBlob()}>
                                {t('questionPromptSlidingScaleVideoTab.clearImage', 'Clear image')}
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </ButtonGroup>
            </FormGroup>

            <FormGroup>
                <Label htmlFor="answers">{t('questionPromptSlidingScaleVideoTab.answers', 'Slider')}</Label>

                <div>
                    <SlidingScaleSlider answers={orderedAnswers} />
                </div>

                <div>
                    {
                        orderedAnswers.map(item => (
                            <QuestionAnswerSlidingScaleVideo key={item.id}
                                model={item}
                                change={changes => answersManager.changeFor(item.id, changes)}
                                remove={() => answersManager.removeFor(item.id)}

                                isOpenInitially={!!answersManager.added.find(it => it.id === item.id)}

                                moveUp={() => moveAnswerUp(item.id)} canMoveUp={canMoveAnswerUp(item.id)}
                                moveDown={() => moveAnswerDown(item.id)} canMoveDown={canMoveAnswerDown(item.id)}

                                answerTextImageBlob={childBlobs.find(it => it.id === item.answerTextImageBlobReferenceId)}
                                uploadChildBlob={uploadChildBlob}

                                validate={() => validateQuestionAnswer(item)}
                                validationErrors={questionAnswerValidationErrors(item.id)}
                                />
                            ))
                    }
                </div>

                <Button color="primary" outline onClick={() => addAnswer()}>
                    {t('questionPromptSlidingScaleVideoTab.addAnswer', 'Add slider mark')}
                </Button>
            </FormGroup>
        </>
        );
};