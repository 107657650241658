import { useEffect } from "react";
import { HtmlDisplay } from "../../../../../shared/htmlEditor";
import { ShowQuestionChildProps } from "../ShowQuestion";

/**
 * Running of a LearningText question.
 * @param props
 */
export const ShowQuestionLearningText = (props: ShowQuestionChildProps) => {
    const {
        model,
        onPageComplete,
    } = props;

    // There is no end event for this type of learning so we are complete as soon as we're shown.
    useEffect(() => {
        onPageComplete();
    }, [onPageComplete]);

    return (
        <div>
            <div>
                <HtmlDisplay html={model.questionText} />
            </div>
        </div>
    );
};