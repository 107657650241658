import { useTranslation } from "react-i18next";
import { AssessmentType } from "../../../api/main/models/codeOnly/AssessmentType";
import { SubscriptionAssessmentsListBase } from "../SubscriptionAssessmentsListBase";

/**
 * List of actual assessments for a subscription
 */
export const SubscriptionTrainingModulesList = () => {
    const { t } = useTranslation();

    return (
        <SubscriptionAssessmentsListBase
            title={t('subscriptionTrainingModulesList.title', 'Licensed training modules')}
            mobileColumn1Name={t('subscriptionTrainingModulesList.mobileColumn1Name', 'Training module')}
            assessmentTypes={[AssessmentType.TrainingModule]}
            baseRoute={'/administration/training'}
        />
        );
};