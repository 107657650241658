import gql from "graphql-tag";
import { questionTagFields } from "../generated/questionTagFields";

export const questionTagFieldsFragment = gql`
    fragment questionTagFields on QuestionTag {
        id
        name
        archived
    }
`;


export type QuestionTag = Omit<questionTagFields, '__typename'>;
