import { Dispatch, SetStateAction, useCallback, useMemo, useState } from "react";
import { useAsyncCallback } from "react-use-async-callback";
import { useUploadBlobCallback } from "../../../api/main/blobReferences/useUploadBlobCallback";
import { BlobReference } from "../../../api/main/models/BlobReference";

export type BlobReferenceStateArrayReturnType = [
    Array<BlobReference>,
    Dispatch<SetStateAction<Array<BlobReference>>>,
    {
        upload: (files: FileList | null) => Promise<BlobReference | null>,
        isUploading: boolean,
        uploadErrors: any,
        clearUpload: (id: string) => void,
    }
];

/**
 * Custom hook to manage a BlobReference in state along with upload options.
 */
export function useBlobReferenceStateArray(initialValue?: Array<BlobReference>)
    : BlobReferenceStateArrayReturnType
{
    // State we store the blob in.
    const [blobReferences, setBlobReferences] = useState<Array<BlobReference>>(initialValue ?? []);

    // Uploading of a blob.
    const [uploadBlob] = useUploadBlobCallback();
    const [upload, { isExecuting: isUploading, errors: uploadErrors }] = useAsyncCallback(async (files: FileList | null) => {
        if (!files) {
            return null;
        }

        // Upload the blob.
        const blob = await uploadBlob(files);
        if (!blob) {
            return null;
        }

        setBlobReferences(prevState => [
            ...prevState,
            blob,
        ]);

        return blob;
    }, [uploadBlob, setBlobReferences]);

    // Clearning a blob.
    const clearUpload = useCallback((id: string) => {
        setBlobReferences(prevState => prevState.filter(it => it.id !== id));
    }, [setBlobReferences]);

    // Return everything in a format that is easy for people to grab the bits they want.
    const ret = useMemo((): BlobReferenceStateArrayReturnType => ([
        blobReferences,
        setBlobReferences,
        {
            upload,
            isUploading,
            uploadErrors,
            clearUpload,
        }
    ]), [blobReferences, setBlobReferences, upload, isUploading, uploadErrors, clearUpload]);

    return ret;
}