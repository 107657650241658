import './background.scss';

import * as React from 'react';
import { Helmet } from 'react-helmet-async';

export interface BackgroundProps {
    children?: React.ReactNode,
    className?: string,
    centerChildren?: 'none' | 'vertically' | 'horizontally',

    background?: 'default',
}

/**
 * Background for making the back of screens look nicer.
 * @param props
 */
export const Background = (props: BackgroundProps) => {
    const {
        children,
        className,
        centerChildren,
        background = 'default',
    } = props;

    return (
        <div className={`background ${centerChildren ? `background-center-children-${centerChildren}` : ''} ${className ?? ''}`}>
            <Helmet>
                <body data-background={background} />
            </Helmet>
            {children}
        </div>
        );
};