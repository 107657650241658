import { RouteEntry } from "../shared/routes";
import { apiAuthorizationRoutes } from "../components/api-authorization/routes";
import { accountRoutes } from "../components/account/routes";
import { profileRoutes } from "../components/profiles/routes";
import { NotFound } from "../components/shared/NotFound";
import { homeRoutes } from "../components/home/routes";
import { administrationRoutes, administrationSidebarRoutes } from "../components/administration/routes";
import { questionTagRoutes } from "../components/questionTags/routes";
import { driverMetricRoutes } from "../components/driverMetrics/routes";
import { subscriptionRoutes } from "../components/subscriptions/routes";
import { assessmentRoutes } from "../components/assessments/assessments/routes";
import { trainingModuleAssessmentRoutes } from "../components/assessments/trainingModules/routes";
import { questionRoutes } from "../components/questions/questions/routes";
import { questionnaireRoutes } from "../components/questions/questionnaires/routes";
import { learningRoutes } from "../components/questions/learning/routes";
import { subscriptionAssessmentRoutes } from "../components/subscriptionAssessments/assessments/routes";
import { subscriptionTrainingModuleAssessmentRoutes } from "../components/subscriptionAssessments/trainingModules/routes";
import { meRoutes } from "../components/me/routes";
import { administratorsRoutes } from "../components/users/administrators/routes";
import { manageRoutes } from "../components/manage/routes";
import { driversRoutes } from "../components/users/drivers/routes";
import { managersRoutes } from "../components/users/managers/routes";
import { subscriptionTeamRoutes } from "../components/subscriptionTeam/routes";
import { userAssessmentAssignmentRoutes } from "../components/userAssessmentAssignment/assessments/routes";
import { userTrainingModuleAssessmentAssignmentsRoutes } from "../components/userAssessmentAssignment/trainingModules/routes";
import { assessmentSessionAssessmentRoutes } from "../components/assessmentSessions/assessments/routes";
import { assessmentSessionTrainingRoutes } from "../components/assessmentSessions/trainingModules/routes";
import { dashboardRoutes } from "../components/dashboard/routes";
import { importUsersRoutes } from "../components/users/import/routes";
import { informationRoutes } from "../components/questions/information/routes";
import { distributorRoutes } from "../components/subscriptions/Distributors/routes"
import { sdkRoutes } from "../components/sdk/routes";
import { cmsPageRoutes } from "../components/cmsPages/routes";
import { studyRoutes } from "../components/subscriptions/Studies/routes";
import { studyAccountRoutes } from "../components/studyAccount/routes";

export const appRoutes: Array<RouteEntry> = [
    ...apiAuthorizationRoutes,
    ...accountRoutes,
    ...homeRoutes,
    ...profileRoutes,

    ...administrationRoutes,
    ...administratorsRoutes,
    ...assessmentRoutes,
    ...assessmentSessionAssessmentRoutes,
    ...assessmentSessionTrainingRoutes,
    ...cmsPageRoutes,
    ...dashboardRoutes,
    ...driverMetricRoutes,
    ...driversRoutes,
    ...importUsersRoutes,
    ...informationRoutes,
    ...learningRoutes,
    ...manageRoutes,
    ...managersRoutes,
    ...meRoutes,
    ...trainingModuleAssessmentRoutes,
    ...questionRoutes,
    ...questionnaireRoutes,
    ...questionTagRoutes,
    ...sdkRoutes,
    ...studyRoutes,
    ...studyAccountRoutes,
    ...subscriptionAssessmentRoutes,
    ...subscriptionRoutes,
    ...subscriptionTeamRoutes,
    ...subscriptionTrainingModuleAssessmentRoutes,
    ...userAssessmentAssignmentRoutes,
    ...userTrainingModuleAssessmentAssignmentsRoutes,
    ...distributorRoutes,

    { path: undefined, component: NotFound }
];

export const sidebarRoutes: Array<RouteEntry> = [
    ...administrationSidebarRoutes,
];