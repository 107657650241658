import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Button, FormGroup, Label } from "reactstrap";
import { BlobReference } from "../../../../api/main/models/BlobReference";
import { Question } from "../../../../api/main/models/Question";
import { QuestionAnswer, questionAnswerDefaultValues } from "../../../../api/main/models/QuestionAnswer";
import { HtmlEditor } from "../../../../shared/htmlEditor";
import { ModelArrayChanges } from "../../../../shared/useChanges";
import { QuestionAnswerMultipleChoiceImage } from "./QuestionAnswerMultipleChoiceImage";
import { useDisplayOrder } from "../../../shared/useDisplayOrder/useDisplayOrder";
import { ValidationErrors } from "pojo-validator";

export interface QuestionPromptMultipleChoiceImageTabProps {
    model: Question | undefined,
    change: (changes: Partial<Question>) => void,

    answersManager: ModelArrayChanges<QuestionAnswer, string>,

    childBlobs: Array<BlobReference>,
    uploadChildBlob: (files: FileList | null) => Promise<BlobReference | null>,

    validateQuestionAnswer: (model: QuestionAnswer) => boolean,
    questionAnswerValidationErrors: (id: string) => ValidationErrors,
}

/**
 * Tab for maintaining the prompt for the question and the available multiple choice answers.
 * @param props
 */
export const QuestionPromptMultipleChoiceImageTab = (props: QuestionPromptMultipleChoiceImageTabProps) => {
    const {
        model,
        change,

        answersManager,
        childBlobs,
        uploadChildBlob,

        validateQuestionAnswer,
        questionAnswerValidationErrors,
    } = props;

    const { t } = useTranslation();

    // Order the answers so they show in and can be managed by displayOrder.
    const [orderedAnswers, {
        canMoveUp: canMoveAnswerUp,
        moveUp: moveAnswerUp,
        canMoveDown: canMoveAnswerDown,
        moveDown: moveAnswerDown,
    }] = useDisplayOrder(answersManager);
    

    // Adding of an answer.
    const addAnswer = useCallback(() => {
        answersManager.addFor({
            ...questionAnswerDefaultValues(),

            questionId: model?.id,
        });
    }, [answersManager, model?.id]);

    return (
        <>
            <FormGroup>
                <Label htmlFor="questionText">{t('questionPromptMultipleChoiceImageTab.questionText', 'Question prompt')}</Label>

                <HtmlEditor value={model?.questionText} onChange={html => change({ questionText: html })} />
            </FormGroup>

            <FormGroup>
                <Label htmlFor="answers">{t('questionPromptMultipleChoiceImageTab.answers', 'Answers')}</Label>

                <div>
                    {
                        orderedAnswers.map(item => (
                            <QuestionAnswerMultipleChoiceImage key={item.id}
                                model={item}
                                change={changes => answersManager.changeFor(item.id, changes)}
                                remove={() => answersManager.removeFor(item.id)}

                                isOpenInitially={!!answersManager.added.find(it => it.id === item.id)}

                                moveUp={() => moveAnswerUp(item.id)} canMoveUp={canMoveAnswerUp(item.id)}
                                moveDown={() => moveAnswerDown(item.id)} canMoveDown={canMoveAnswerDown(item.id)}

                                answerTextImageBlob={childBlobs.find(it => it.id === item.answerTextImageBlobReferenceId)}
                                uploadChildBlob={uploadChildBlob}

                                validate={() => validateQuestionAnswer(item)}
                                validationErrors={questionAnswerValidationErrors(item.id)}
                                />
                            ))
                    }
                </div>

                <Button color="primary" outline onClick={() => addAnswer()}>
                    {t('questionPromptMultipleChoiceImageTab.addAnswer', 'Add answer')}
                </Button>
            </FormGroup>
        </>
        );
};