import { useCallback, useMemo } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { Link } from "react-router-dom";
import { Col, ListGroup, ListGroupItemHeading, ListGroupItemText, Row } from "reactstrap";
import { Assessment } from "../../../api/main/models/Assessment";
import { AssessmentSession } from "../../../api/main/models/AssessmentSession";
import { AssessmentType } from "../../../api/main/models/codeOnly/AssessmentType";
import { Profile } from "../../../api/main/models/Profile";
import { Subscription } from "../../../api/main/models/Subscription";
import { SubscriptionTeam } from "../../../api/main/models/SubscriptionTeam";
import { UserAssessmentAssignment } from "../../../api/main/models/UserAssessmentAssignment";
import { useActiveDashboardRiskCategoryFilter } from "../../../globalState/activeDashboardRiskCategoryFilter/useActiveDashboardRiskCategoryFilter";
import { RiskRules } from "../../../services/OverallRiskService";
import { useAverageDriverMetricRiskChartData } from "../chartData/useAverageDriverMetricRiskChartData";
import { useAverageScoreChartData } from "../chartData/useAverageScoreChartData";
import { useCompletionChartData } from "../chartData/useCompletionChartData";
import { AverageDriverMetricRiskDonutChart } from "../charts/AverageDriverMetricRiskDonutChart";
import { AverageScoreRadarChart } from "../charts/AverageScoreRadarChart";
import { CompletionBarChart } from "../charts/CompletionBarChart";
import { DashboardListGroupItem } from "../listGroups/DashboardListGroupItem";
import { OverallRiskFilterListGroupItem } from "../listGroups/OverallRiskFilterListGroupItem";
import { DashboardSummary } from "../utilities/generateDashboardSummary";
import { useCachedDashboardSummaries } from "../utilities/useCachedDashboardSummaries";
import { useDashboardBaseRoute } from "../utilities/useDashboardBaseRoute";

export interface SubscriptionTeamsDashboardTabProps {
    subscriptionTeams: Array<SubscriptionTeam>,
    profiles: Array<Profile>,
    generateDashboardSummary: (
        sessionFilter: (session: AssessmentSession) => boolean,
        assignmentFilter: (assignment: UserAssessmentAssignment) => boolean,
        assessmentType: AssessmentType
    ) => DashboardSummary,

    assessmentSessions: Array<AssessmentSession>,
    assessments: Array<Assessment>,
    activeAssessmentType: AssessmentType,

    showSubscriptionName?: boolean,
    subscriptions?: Array<Subscription>,

    riskRules: RiskRules | undefined | null,
}

/**
 * User based tab on the dashboard.
 * @param props
 */
export const SubscriptionTeamsDashboardTab = (props: SubscriptionTeamsDashboardTabProps) => {
    const {
        subscriptionTeams,
        profiles,
        generateDashboardSummary,
        activeAssessmentType,

        showSubscriptionName = false,
        subscriptions,

        riskRules,
    } = props;

    // Method we use to filter sessions by user wherever we need it.
    const filterByTeam = useCallback((subscriptionTeam: { id: string }, item: { userId: string | undefined | null }) => {
        const profile = profiles.find(it => it.userId === item.userId);
        if (!profile) {
            return false;
        }

        return profile.subscriptionTeamId === subscriptionTeam.id;
    }, [profiles]);

    // Cache the dashboard summaries so each chart/display doesn't need to recaclulate them and they can be looked up easily by id.
    const getCachedDashboardSummary = useCachedDashboardSummaries(
        subscriptionTeams,
        (group, assessmentType) => generateDashboardSummary(
            session => filterByTeam(group, session),
            assignment => filterByTeam(group, assignment),
            assessmentType
        )
    );

    // Get data for the various charts.
    const averageScoreChartData = useAverageScoreChartData(subscriptionTeams, getCachedDashboardSummary, activeAssessmentType);
    const averageScoreRiskChartData = useAverageDriverMetricRiskChartData(subscriptionTeams, getCachedDashboardSummary, activeAssessmentType);
    const completionChartData = useCompletionChartData(subscriptionTeams, getCachedDashboardSummary, activeAssessmentType);

    // Filter the visible results by overall risk category if required.
    const [riskCategoryFilter, setRiskCategoryFilter] = useActiveDashboardRiskCategoryFilter();
    const filteredSubscriptionTeams = useMemo(() => {
        if (!riskCategoryFilter) {
            return subscriptionTeams;
        }

        return subscriptionTeams.filter(user => {
            const summary = getCachedDashboardSummary(user.id, activeAssessmentType);
            return (summary.averageUserDriverMetricScore.riskCategory === riskCategoryFilter);
        })
    }, [subscriptionTeams, riskCategoryFilter, getCachedDashboardSummary, activeAssessmentType]);


    const baseRoute = useDashboardBaseRoute();

    return (
        <Row>
            <Col xs={12} lg="">
                <ListGroup className="mb-4">
                    <OverallRiskFilterListGroupItem riskCategoryFilter={riskCategoryFilter} setRiskCategoryFilter={setRiskCategoryFilter} />
                    {
                        filteredSubscriptionTeams.map(team => (
                            <DashboardListGroupItem
                                key={team.id}
                                getDashboardSummary={assessmentType => getCachedDashboardSummary(team.id, assessmentType)}
                                progressType="risk"
                            >
                                <ListGroupItemHeading>
                                    <Link to={
                                        showSubscriptionName ? `${baseRoute}/subscription/${team.subscriptionId}/team/${team.id}`
                                            : `${baseRoute}/team/${team.id}`
                                    }>
                                        {team.name}
                                    </Link>
                                </ListGroupItemHeading>

                                <ListGroupItemText tag="div">
                                    <ConditionalFragment showIf={showSubscriptionName}>
                                        {'(' /* Can/should this be made translatable? */}
                                        <Link to={`${baseRoute}/subscription/${team.subscriptionId}`}>
                                            {subscriptions?.find(it => it.id === team.subscriptionId)?.companyName}
                                        </Link>
                                        {')'}
                                    </ConditionalFragment>
                                </ListGroupItemText>
                            </DashboardListGroupItem>
                        ))
                    }
                </ListGroup>
            </Col>
            <Col>
                <ConditionalFragment showIf={activeAssessmentType === AssessmentType.Assessment}>
                    <AverageDriverMetricRiskDonutChart data={averageScoreRiskChartData} assessmentType={activeAssessmentType} />
                </ConditionalFragment>
                <AverageScoreRadarChart data={averageScoreChartData} assessmentType={activeAssessmentType} riskRules={riskRules} />
                <CompletionBarChart data={completionChartData} assessmentType={activeAssessmentType} />
            </Col>
        </Row>
    );
};