import * as React from 'react';
import { SubscriptionTeamCreateInput, SubscriptionTeamUpdateInput } from '../generated/globalTypes';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useCreateSubscriptionTeamCallback } from './useCreateSubscriptionTeamCallback';
import { useUpdateSubscriptionTeamCallback } from './useUpdateSubscriptionTeamCallback';
import { SubscriptionTeam } from '../models/SubscriptionTeam';

/**
 * Get a callback to save a SubscriptionTeam in the store using either a create or update.
 */
export function useSaveSubscriptionTeamCallback(options: SaveInStoreOptions<SubscriptionTeam, string> = {}) {
    const [_create, createStatus] = useCreateSubscriptionTeamCallback();
    const create = React.useCallback((model: Partial<SubscriptionTeam>) => _create(model as SubscriptionTeamCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateSubscriptionTeamCallback();
    const update = React.useCallback((id: string, changes: Partial<SubscriptionTeam>) => _update(id, changes as SubscriptionTeamUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
