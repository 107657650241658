import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { AssessmentItemUpdateInput } from '../generated/globalTypes';
import { updateAssessmentItemMutation, updateAssessmentItemMutationVariables } from '../generated/updateAssessmentItemMutation';
import { assessmentItemFieldsFragment } from '../models/AssessmentItem';

/**
 * Get a callback to update a AssessmentItem in the store.
 */
export function useUpdateAssessmentItemCallback(): [(id: string, model: AssessmentItemUpdateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<updateAssessmentItemMutation, updateAssessmentItemMutationVariables>(
        gql`
            mutation updateAssessmentItemMutation ($model: AssessmentItemUpdateInput!) {
                updateAssessmentItem(model: $model) {
                    ...assessmentItemFields
                }
            }

            ${assessmentItemFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (id: string, model: AssessmentItemUpdateInput) => {
        const result = await mutationAction({ variables: { model: { ...model, id: id } } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
