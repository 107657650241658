import { SubscriptionType } from "../../../api/main/models/codeOnly/SubscriptionType";
import { SubscriptionsListBase } from "../SubscriptionsListBase";
import { useTranslation } from "react-i18next";

/**
 * List of actual distributors.
 */
export const DistributorsList = () => {
    const { t } = useTranslation();

    return (
        <SubscriptionsListBase
            title={t('distributorsList.heading', 'Distributors')}
            subscriptionTypes={[SubscriptionType.Distributor]}
            baseRoute={'/administration/distributors'}
        />
    );
};