import { useCallback, useMemo } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Button, ButtonGroup, Col, Container, NavItem, NavLink, Row } from "reactstrap";
import { AssessmentType } from "../../../api/main/models/codeOnly/AssessmentType";
import { useSubscriptionDashboardViewModel } from "../../../api/main/dashboard/viewModels/useSubscriptionDashboardViewModel";
import { AlertOnErrors } from "../../../shared/alertOnErrors";
import { Background } from "../../shared/background/Background";
import { Banner } from "../../shared/Banner";
import { PillsNavBar } from "../../shared/pillsNavBar/PillsNavBar";
import { LoadingIndicator } from "../../shared/LoadingIndicator";
import { UserAssessmentAssignment } from "../../../api/main/models/UserAssessmentAssignment";
import { AssessmentSession } from "../../../api/main/models/AssessmentSession";
import { generateDashboardSummary as _generateDashboardSummary } from "../utilities/generateDashboardSummary";
import { SubscriptionTeamsDashboardTab } from "../tabs/SubscriptionTeamsDashboardTab";
import { SubscriptionUsersDashboardTab } from "../tabs/SubscriptionUsersDashboardTab";
import { AssessmentsDashboardTab } from "../tabs/AssessmentsDashboardTab";
import { TrainingDashboardTab } from "../tabs/TrainingDashboardTab";
import { useParams } from "react-router";
import { useCurrentUserOrEmulatedSubscriptionId } from "../../../globalState/subscriptions/useCurrentUserOrEmulatedSubscriptionId";
import { useExpandedUserAssessmentAssignments } from "../utilities/useExpandedUserAssessmentAssignments";
import { useActiveDashboardAssessmentType } from "../../../globalState/activeDashboardAssessmentType/useActiveDashboardAssessmentType";
import { useActiveDashboardTab } from "../../../globalState/activeDashboardTab/useActiveDashboardTab";
import { DashboardBackButton } from "../backButton/DashboardBackButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ExportAssessmentResults } from "../tabs/ExportAssessmentResults";
import { RiskRules } from "../../../services/OverallRiskService";
import { SubscriptionType } from "../../../api/main/models/codeOnly/SubscriptionType";

export type SubscriptionDashboardTabs = 'teams' | 'people' | 'assessments' | 'training' | 'export';

/**
 * Dashboard for a subscription.
 */
export const SubscriptionDashboard = () => {
    // Work out the subscription to show for.
    const { subscriptionId: subscriptionIdParam } = useParams<{ subscriptionId: string | undefined; }>();
    const mySubscriptionId = useCurrentUserOrEmulatedSubscriptionId();
    const subscriptionId = subscriptionIdParam ?? mySubscriptionId;

    const { t } = useTranslation();

    const {
        data: {
            model: subscription,
            subscriptionTeams,
            profiles,
            subscriptionAssessments,
            assessments: allAssessments,
            assessmentSessions,
            userAssessmentAssignments,
            userDriverMetrics: allUserDriverMetrics,
        },
        isLoading,
        errors: loadErrors
    } = useSubscriptionDashboardViewModel(subscriptionId);

    // The subscription is only licensed for some assessments, so remove any that we're not licensed for now.
    const assessments = useMemo(() =>
        allAssessments?.filter(assessment => !!subscriptionAssessments?.find(it => it.assessmentId === assessment.id))
        , [allAssessments, subscriptionAssessments]);

    // Expand the UserAssessmentAssignments so we can work with them easier.
    const expandedUserAssessmentAssignments = useExpandedUserAssessmentAssignments(userAssessmentAssignments, profiles);

    // Allow the generation of dashboard summary data.
    const generateDashboardSummary = useCallback((
        sessionFilter: (session: AssessmentSession) => boolean,
        assignmentFilter: (assignment: UserAssessmentAssignment) => boolean,
        assessmentType: AssessmentType,
        riskRule: RiskRules | undefined | null = subscription) => {
        // Filter by the passed in fitler.
        let mySessions = assessmentSessions?.filter(sessionFilter) ?? [];
        let myAssignments = expandedUserAssessmentAssignments?.filter(assignmentFilter) ?? [];

        // Filter by the passed in type.
        mySessions = mySessions.filter(session => {
            const assessment = assessments?.find(it => it.id === session.assessmentId);
            if (!assessment) {
                return false;
            }

            return assessment.assessmentType === assessmentType;
        });

        myAssignments = myAssignments.filter(session => {
            const assessment = assessments?.find(it => it.id === session.assessmentId);
            if (!assessment) {
                return false;
            }

            return assessment.assessmentType === assessmentType;
        });

        // Filter the driver metrics to contain only users that have assessments or assignments.
        const myUserDriverMetrics = allUserDriverMetrics?.filter(
            udm => !!mySessions.find(it => it.userId === udm.userId) || !!myAssignments.find(it => it.userId === udm.userId)
        ) ?? [];

        // Generate the summary data.
        const ret = _generateDashboardSummary(mySessions, assessments ?? [], profiles ?? [], myAssignments, myUserDriverMetrics ?? [], riskRule);
        return ret;
    }, [assessmentSessions, assessments, profiles, expandedUserAssessmentAssignments, subscription, allUserDriverMetrics]);


    // Track the active tab.
    const [activeTab, setActiveTab] = useActiveDashboardTab<SubscriptionDashboardTabs>('subscriptionDashboard', 'people');

    // Track the active assessment type.
    const [activeAssessmentType, setActiveAssessmentType] = useActiveDashboardAssessmentType();
    const hideAssessmentTypeToggle = activeTab === 'assessments' || activeTab === 'training' || activeTab === 'export';

    return (
        <Background>
            <Banner fluid>
                <Row>
                    <Col xs={12} md="auto">
                        <h1>
                            <DashboardBackButton />
                            {
                                // If we are showing for our own (or emulated) subscription just show "Dashboard" as the name.
                                !!subscriptionIdParam ? t('subscriptionDashboard.heading.withSubscriptionName', '{{name}} dashboard', { name: subscription?.companyName })
                                    : t('subscriptionDashboard.heading.default', 'Dashboard')
                            }
                        </h1>
                    </Col>
                    <Col>
                        <PillsNavBar textColor="white" className="mb-2">
                            <NavItem>
                                <NavLink active={activeTab === 'people'} onClick={() => setActiveTab('people')}>
                                    <FontAwesomeIcon icon="user" />
                                    <> </>
                                    {subscription?.subscriptionType === SubscriptionType.Study ? (
                                        t('subscriptionDashboard.tabs.people', 'Participants')
                                    ) : (
                                        t('subscriptionDashboard.tabs.people', 'People')
                                    )}
                                </NavLink>
                            </NavItem>
                            <ConditionalFragment showIf={subscription?.subscriptionType !== SubscriptionType.Study}>
                                <NavItem>
                                    <NavLink active={activeTab === 'teams'} onClick={() => setActiveTab('teams')}>
                                        <FontAwesomeIcon icon="users" />
                                        <> </>
                                        {t('subscriptionDashboard.tabs.teams', 'Teams')}
                                    </NavLink>
                                </NavItem>
                            </ConditionalFragment>
                            <NavItem>
                                <NavLink active={activeTab === 'assessments'} onClick={() => setActiveTab('assessments')}>
                                    <FontAwesomeIcon icon="clipboard-check" />
                                    <> </>
                                    {t('subscriptionDashboard.tabs.assessments', 'Assessments')}
                                </NavLink>
                            </NavItem>
                            <ConditionalFragment showIf={subscription?.subscriptionType !== SubscriptionType.Study}>
                                <NavItem>
                                    <NavLink active={activeTab === 'training'} onClick={() => setActiveTab('training')}>
                                        <FontAwesomeIcon icon="graduation-cap" />
                                        <> </>
                                        {t('subscriptionDashboard.tabs.training', 'Training')}
                                    </NavLink>
                                </NavItem>
                            </ConditionalFragment>
                            <NavItem>
                                <NavLink active={activeTab === 'export'} onClick={() => setActiveTab('export')}>
                                    <FontAwesomeIcon icon="download" />
                                    <> </>
                                    {t('subscriptionDashboard.export', 'Export results to Excel')}
                                </NavLink>
                            </NavItem>
                        </PillsNavBar>
                    </Col>
                    <ConditionalFragment showIf={!hideAssessmentTypeToggle && subscription?.subscriptionType !== SubscriptionType.Study}>
                        <Col xs="auto">
                            <Row>
                                <ButtonGroup>
                                    <Button outline={activeAssessmentType !== AssessmentType.Assessment} onClick={() => setActiveAssessmentType(AssessmentType.Assessment)}>
                                        {t('subscriptionDashboard.toggle.assessments', 'Assessments')}
                                    </Button>
                                    <Button outline={activeAssessmentType !== AssessmentType.TrainingModule} onClick={() => setActiveAssessmentType(AssessmentType.TrainingModule)}>
                                        {t('subscriptionDashboard.toggle.training', 'Training')}
                                    </Button>
                                </ButtonGroup>
                            </Row>

                            <Row style={{ marginTop: 10 }}>
                                <ConditionalFragment showIf={activeTab === "people" && subscription?.allowExport === true}>
                                    <a className=" btn btn-outline-primary" href={`/api/export/SubscriptionDashboard?subscriptionId=${encodeURIComponent(subscription?.id ?? '')}`} download>
                                        <FontAwesomeIcon icon="download" /> <> {t('usersListBase.export', 'Export to Excel')} </>
                                    </a>
                                </ConditionalFragment>
                            </Row>
                        </Col>
                    </ConditionalFragment>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>

                <Row>
                    <Col>
                    </Col>
                </Row>
            </Banner>
            <Container fluid>
                <AlertOnErrors errors={[loadErrors]} />


                <ConditionalFragment showIf={activeTab === 'people'}>
                    <SubscriptionUsersDashboardTab
                        profiles={profiles ?? []}
                        generateDashboardSummary={generateDashboardSummary}
                        activeAssessmentType={activeAssessmentType}

                        assessmentSessions={assessmentSessions ?? []}
                        assessments={assessments ?? []}

                        subscriptionTeams={subscriptionTeams ?? []}
                        riskRules={subscription}
                    />
                </ConditionalFragment>

                <ConditionalFragment showIf={activeTab === 'teams'}>
                    <SubscriptionTeamsDashboardTab
                        subscriptionTeams={subscriptionTeams ?? []}
                        profiles={profiles ?? []}
                        generateDashboardSummary={generateDashboardSummary}
                        activeAssessmentType={activeAssessmentType}

                        assessmentSessions={assessmentSessions ?? []}
                        assessments={assessments ?? []}
                        riskRules={subscription}
                    />
                </ConditionalFragment>

                <ConditionalFragment showIf={activeTab === 'assessments'}>
                    <AssessmentsDashboardTab
                        assessments={assessments ?? []}
                        generateDashboardSummary={generateDashboardSummary}
                        activeAssessmentType={activeAssessmentType}
                        subscriptionId={subscriptionId ?? ""}

                        assessmentSessions={assessmentSessions ?? []}
                        riskRules={subscription}
                    />
                </ConditionalFragment>

                <ConditionalFragment showIf={activeTab === 'training'}>
                    <TrainingDashboardTab
                        assessments={assessments ?? []}
                        generateDashboardSummary={generateDashboardSummary}
                        activeAssessmentType={activeAssessmentType}
                        subscriptionId={subscriptionId ?? ""}

                        assessmentSessions={assessmentSessions ?? []}
                        riskRules={subscription}
                    />
                </ConditionalFragment>

                <ConditionalFragment showIf={activeTab === 'export'}>
                    <ExportAssessmentResults
                        assessments={assessments ?? []}
                        assessmentSessions={assessmentSessions ?? []}
                        subscriptionId={subscriptionId!}
                        subscriptionTeams={subscriptionTeams}
                    />
                </ConditionalFragment>
            </Container>
        </Background>
    );
};
