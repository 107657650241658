import * as React from 'react';
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { AsyncLoadPagedResult, AsyncLoadPagedOptions } from '../../../../shared/abstractStore';
import { useFetchMoreCallback } from '../../../../shared/useFetchMoreCallback';
import { usersListViewBaseViewModelQuery, usersListViewBaseViewModelQueryVariables } from '../../generated/usersListViewBaseViewModelQuery';
import { mainApiConfig } from '../../../../configure/mainApiConfig';
import { profileFieldsFragment } from '../../models/Profile';
import { subscriptionFieldsFragment } from '../../models/Subscription';
import { subscriptionTeamFieldsFragment } from '../../models/SubscriptionTeam';
import { Guid } from 'guid-string';

export interface UsersListViewBaseViewModelAsyncLoadPagedOptions extends AsyncLoadPagedOptions {
    subscriptionId?: string | undefined | null,
    subscriptionTeamId?: string | undefined | null,
}

/**
 * Get a list of profiles from the store.
 * @param id
 * @param options
 */
export function useUsersListViewBaseViewModel(options: UsersListViewBaseViewModelAsyncLoadPagedOptions = {}): AsyncLoadPagedResult<usersListViewBaseViewModelQuery> {
    // Query data we need from the api.
    const [load, { data, loading, error, refetch, fetchMore: fetchMoreFromStore }] = useLazyQuery<usersListViewBaseViewModelQuery, usersListViewBaseViewModelQueryVariables>(
        gql`
        query usersListViewBaseViewModelQuery ($offset: Int, $first: Int, $subscriptionId: ID, $subscriptionTeamId: ID, $subscriptionIdOrEmptyGuid: ID!, $subscriptionTeamIdOrEmptyGuid: ID!) {
            items: profiles (offset: $offset, first: $first, subscriptionId: $subscriptionId, subscriptionTeamId: $subscriptionTeamId) {
                ...profileFields
            }

            subscription (id: $subscriptionIdOrEmptyGuid) {
                ...subscriptionFields
            }
            subscriptionTeam (id: $subscriptionTeamIdOrEmptyGuid){
                ...subscriptionTeamFields
            }
            subscriptionTeams (subscriptionId: $subscriptionIdOrEmptyGuid){
                ...subscriptionTeamFields
            }
        }

        ${profileFieldsFragment}
        ${subscriptionFieldsFragment}
        ${subscriptionTeamFieldsFragment}
        `,
        {

            variables: {
                offset: options.startOffset,
                first: options.pageSize,
                subscriptionId: options.subscriptionId,
                subscriptionTeamId: options.subscriptionTeamId,
                subscriptionIdOrEmptyGuid: options.subscriptionId ?? Guid.empty,
                subscriptionTeamIdOrEmptyGuid: options.subscriptionTeamId ?? Guid.empty,
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load])

    // Get the functions used to manage paging.
    const { fetchMore, hasMore: _hasMore } = useFetchMoreCallback<usersListViewBaseViewModelQuery>(
        fetchMoreFromStore,
        (options?.startOffset ?? 0) + (data?.items?.length ?? 0),
        (prevResults, newResults) => ({
            ...prevResults,
            ...newResults,

            items: [...(prevResults?.items ?? []), ...(newResults?.items ?? [])],
            subscription: newResults?.subscription ?? null,
            subscriptionTeam: newResults?.subscriptionTeam ?? null,
            subscriptionTeams: newResults?.subscriptionTeams ?? [],
        }),
        (newResults) => newResults?.items?.length
    );
    const hasMore = React.useCallback(() => !options.pageSize ? false : _hasMore(), [options.pageSize, _hasMore]);
    
    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error,
        fetchMore: fetchMore,
        hasMore: hasMore,
    }), [data, refresh, loading, error, fetchMore, hasMore]);
}
