import { useTranslation } from "react-i18next";
import { AssessmentType } from "../../../api/main/models/codeOnly/AssessmentType";
import { DashboardSummary } from "../utilities/generateDashboardSummary";
import { AverageScoreProgressBar } from "./AverageScoreProgressBar";

export interface TrainingAverageScoreProgressBarProps {
    summary: DashboardSummary,
}

/**
 * Show an average score for trainings as a progress bar, with supporting data around it.
 */
export const TrainingAverageScoreProgressBar = (props: TrainingAverageScoreProgressBarProps) => {
    const {
        summary,
    } = props;

    const { t } = useTranslation();

    return (
        <AverageScoreProgressBar
            assessmentType={AssessmentType.TrainingModule}
            summary={summary}
            title={t('trainingsAverageScoreProgressBar.title', 'Training')}
            footer={t('trainingsAverageScoreProgressBar.footer', 'Based on {{count}} training sessions', { count: summary.uniqueSessionsCount })}
        />
        );
};