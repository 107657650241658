import gql from "graphql-tag";
import { Guid } from "guid-string";
import { assessmentItemQuestionFields } from "../generated/assessmentItemQuestionFields";

export const assessmentItemQuestionFieldsFragment = gql`
    fragment assessmentItemQuestionFields on AssessmentItemQuestion {
        id
        assessmentId
        assessmentItemId
        questionId
        archived
    }
`;


export type AssessmentItemQuestion = Omit<assessmentItemQuestionFields, '__typename'>;

// Default values.
export const assessmentItemQuestionDefaultValues = (): Partial<AssessmentItemQuestion> => ({
    id: Guid.newGuid(),
    assessmentId: undefined,
    assessmentItemId: undefined,
    questionId: undefined,
    archived: false,
});