import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row, } from 'reactstrap';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { Background } from '../shared/background/Background';
import { Banner } from '../shared/Banner';
import { MainContainer } from '../shared/MainContainer';
import { StickyToolbar } from '../shared/StickyToolbar';
import './privacyPolicyPage.scss';

/**
 * Privacy Policy Page.
 */
export const PrivacyPolicyPage = () => {

    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // a formatted table with a lower case alpha in the first column and the item in the second column
    // presents a a pseudo ordered list
    const LowerAlphaList = useCallback((props: {
        listItems: object[],
    }) => {
        const { listItems } = props;
        const alphaCharacters: Array<string> = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'y'];

        return (

            <table className="without-borders">
                <tbody>
                    {
                        listItems.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td>{alphaCharacters[index]})</td>
                                    <td>{item}</td>
                                </tr>
                            );
                        })
                    }
                </tbody>
            </table>
        );

    }, []);

    return (
        <Background>
            <Banner fluid>
                <StickyToolbar>
                    <Row>
                        <Col xs={11} md="">
                            <h1>{t("privacyPolicyPage.title", "Privacy Policy")}</h1>
                        </Col>

                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer className="privacy-policy" fluid>
                <AlertOnErrors errors={null} />
                <div className="text-container">
                    <h2>{t("privacyPolicyPage.heading", "Privacy Notice")}</h2>

                    <h3>{t("privacyPolicyPage.background.header", "Introduction")}</h3>
                    <p>{t("privacyPolicyPage.background.paragraph.1", "Welcome to Esitu Solutions Limited privacy policy.")}</p>
                    <p>{t("privacyPolicyPage.background.paragraph.2", "The Esitu Solutions Limited respects your privacy and is committed to protecting your personal data. This privacy policy will inform you as to how we look after your personal data when you visit our website (regardless of where you visit it from) and tell you about your privacy rights and how the law protects you.")}</p>
                    <p>{t("privacyPolicyPage.background.paragraph.3", "This privacy policy is provided in a layered format so you can click through to the specific areas set out below. Please also use the Glossary to understand the meaning of some of the terms used in this privacy policy.")}</p>

                    { /*gave the contents a margin to seperate it from the start of the main page list, this may need to be done in a better way*/ }
                    <ol style={{marginLeft: "3%"}}>
                        <li><span>{t("privacyPolicyPage.content.link.1", "IMPORTANT INFORMATION AND WHO WE ARE")}</span></li>
                        <li><span>{t("privacyPolicyPage.content.link.2", "THE DATA WE COLLECT ABOUT YOU")}</span></li>
                        <li><span>{t("privacyPolicyPage.content.link.3", "HOW IS YOUR PERSONAL DATA COLLECTED?")}</span></li>
                        <li><span>{t("privacyPolicyPage.content.link.4", "HOW WE USE YOUR PERSONAL DATA")}</span></li>
                        <li><span>{t("privacyPolicyPage.content.link.5", "DISCLOSURES OF YOUR PERSONAL DATA")}</span></li>
                        <li><span>{t("privacyPolicyPage.content.link.6", "INTERNATIONAL TRANSFERS")}</span></li>
                        <li><span>{t("privacyPolicyPage.content.link.7", "DATA SECURITY")}</span></li>
                        <li><span>{t("privacyPolicyPage.content.link.8", "DATA RETENTION")}</span></li>
                        <li><span>{t("privacyPolicyPage.content.link.9", "YOUR LEGAL RIGHTS")}</span></li>
                        <li><span>{t("privacyPolicyPage.content.link.10", "GLOSSARY")}</span></li>
                    </ol>

                    <ol>
                        <li className="li-sub-heading"> <span>{t("privacyPolicyPage.subHeading.1", "Important information and who we are")}</span>
                            <h3>{t("privacyPolicyPage.paragraph1.1.header", "Purpose of this privacy policy")}</h3>
                            <p>{t("privacyPolicyPage.paragraph1.1.1", "This privacy policy aims to give you information on how Esitu Solutions Limited collects and processes your personal data through your use of this website, including any data you may provide through this website when you sign up to our newsletter or purchase a product or service.")}</p>
                            <p>{t("privacyPolicyPage.paragraph1.1.2", "This website is not intended for children and we do not knowingly collect data relating to children.")}</p>
                            <p>{t("privacyPolicyPage.paragraph1.1.3", "It is important that you read this privacy policy together with any other privacy policy or fair processing policy we may provide on specific occasions when we are collecting or processing personal data about you so that you are fully aware of how and why we are using your data. This privacy policy supplements other notices and privacy policies and is not intended to override them.")}</p>

                            <h3>{t("privacyPolicyPage.paragraph1.2.header", "Controller")}</h3>
                            <p>{t("privacyPolicyPage.paragraph1.2.1", "The Esitu Solutions Limited is the controller and is registered with the Information Commissioners Office (ICO) under reference number ZB097590 and is responsible for your personal data in this privacy policy.")}</p>
                            <p>{t("privacyPolicyPage.paragraph1.2.2", "We have appointed a data privacy manager who is responsible for overseeing questions in relation to this privacy policy. If you have any questions about this privacy policy, including any requests to exercise your legal rights, please contact the data privacy manager using the details set out below.")}</p>

                            <h3>{t("privacyPolicyPage.paragraph1.3.header", "Contact details")}</h3>
                            <p>{t("privacyPolicyPage.paragraph1.3.1", "If you have any questions about this privacy policy or our privacy practices, please contact our data privacy manager in the following ways:")}</p>
                            <p>{t("privacyPolicyPage.paragraph1.3.2", "Full name of legal entity: Esitu Solutions Limited")}</p>
                            <p>{t("privacyPolicyPage.paragraph1.3.3", "Email address: victoria@esitusolution.com")}</p>
                            <p>{t("privacyPolicyPage.paragraph1.3.4", "Postal address: Dryden Enterprise Centre, Dryden Street, Nottingham, England, NG1 4FQ")}</p>
                            <p>{t("privacyPolicyPage.paragraph1.3.5", "You have the right to make a complaint at any time to the Information Commissioner's Office (ICO), the UK supervisory authority for data protection issues (www.ico.org.uk). We would, however, appreciate the chance to deal with your concerns before you approach the ICO so please contact us in the first instance.")}</p>

                            <h3>{t("privacyPolicyPage.paragraph1.4.header", "Changes to the privacy policy and your duty to inform us of changes")}</h3>
                            <p>{t("privacyPolicyPage.paragraph1.4.1", "We keep our privacy policy under regular review. This version was last updated on August 2022.")}</p>
                            <p>{t("privacyPolicyPage.paragraph1.4.2", "It is important that the personal data we hold about you is accurate and current. Please keep us informed if your personal data changes during your relationship with us.")}</p>

                            <h3>{t("privacyPolicyPage.paragraph1.5.header", "Third-party links")}</h3>
                            <p>{t("privacyPolicyPage.paragraph1.5.1", "This website may include links to third-party websites, plug-ins and applications. Clicking on those links or enabling those connections may allow third parties to collect or share technical data about you. We do not control these third-party websites and are not responsible for their privacy statements. When you leave our website, we encourage you to read the privacy policy of every website you visit.")}</p>

                        </li>
                        <li className="li-sub-heading"><span>{t("privacyPolicyPage.subHeading.2", "The data we collect about you")}</span>
                            <p>{t("privacyPolicyPage.paragraph2.1", "Personal data, or personal information, means any information about an individual from which that person can be identified. It does not include data where the identity has been removed (anonymous data).")}</p>
                            <p>{t("privacyPolicyPage.paragraph2.2", "We may collect, use, store and transfer different kinds of personal data about you which we have grouped together as follows:")}
                            <div>
                                    <p>&#x2022; <b>Identity Data</b> {t("privacyPolicyPage.paragraph2.2.1", " includes first name, maiden name, last name, username or similar identifier, marital status, title, date of birth and gender.")}</p>
                                    <p>&#x2022; <b>Contact Data</b>{t("privacyPolicyPage.paragraph2.2.2", " includes billing address, delivery address, email address and telephone numbers.")}</p>
                                    <p>&#x2022; <b>Financial Data</b>{t("privacyPolicyPage.paragraph2.2.3", " includes bank account and payment card details.")}</p>
                                    <p>&#x2022; <b>Transaction Data</b>{t("privacyPolicyPage.paragraph2.2.4", " includes details about payments to and from you and other details of products and services you have purchased from us.")}</p>
                                    <p>&#x2022; <b>Tecnhical Data</b>{t("privacyPolicyPage.paragraph2.2.5", " includes internet protocol (IP) address, your login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform, and other technology on the devices you use to access this website.")}</p>
                                    <p>&#x2022; <b>Profile Data</b>{t("privacyPolicyPage.paragraph2.2.6", " includes your username and password, purchases or orders made by you, your interests, preferences, feedback and survey responses.")}</p>
                                    <p>&#x2022; <b>Usage Data</b>{t("privacyPolicyPage.paragraph2.2.7", " includes information about how you use our website, products and services.")}</p>
                                    <p>&#x2022; <b>Marketing and Communications Data</b>{t("privacyPolicyPage.paragraph2.2.8", " includes your preferences in receiving marketing from us and our third parties and your communication preferences.")}</p>

                            </div>
                            </p>

                            <p>We also collect, use and share <b>Aggregated Data</b>{t("privacyPolicyPage.paragraph2.3.1", " such as statistical or demographic data for any purpose. Aggregated Data could be derived from your personal data but is not considered personal data in law as this data will ")} <b>not</b>  {t("privacyPolicyPage.paragraph2.3.2", " directly or indirectly reveal your identity. For example, we may aggregate your Usage Data to calculate the percentage of users accessing a specific website feature. However, if we combine or connect Aggregated Data with your personal data so that it can directly or indirectly identify you, we treat the combined data as personal data which will be used in accordance with this privacy policy.")}</p>
                            <p>We do not collect any <b>Special Categories of Personal Data</b>{t("privacyPolicyPage.paragraph2.4", " about you (this includes details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, information about your health, and genetic and biometric data). Nor do we collect any information about criminal convictions and offences.")}</p>

                            <h3>{t("privacyPolicyPage.paragraph2.5.header", "If you fail to provide personal data")}</h3>
                            <p>{t("privacyPolicyPage.paragraph2.5.1", "Where we need to collect personal data by law, or under the terms of a contract we have with you, and you fail to provide that data when requested, we may not be able to perform the contract we have or are trying to enter into with you (for example, to provide you with goods or services). In this case, we may have to cancel a product or service you have with us but we will notify you if this is the case at the time.")}</p>

                        </li>
                        <li className="li-sub-heading"><span>{t("privacyPolicyPage.subHeading.3", "How is your personal data collected?")}</span>
                            <p>{t("privacyPolicyPage.paragraph3.1", "We use different methods to collect data from and about you including through:")}</p>
                            <p>&#x2022; <b>{t("privacyPolicyPage.paragraph3.2.directInteractions", "Direct interactions.")}</b>{t("privacyPolicyPage.paragraph3.2", " You may give us your Identity, Contact and Financial Data by filling in forms or by corresponding with us by post, phone, email or otherwise. This includes personal data you provide when you:")}
                                <div>
                                    <p>&#x2022; {t("privacyPolicyPage.paragraph3.2.1", "apply for our products or services;")}</p>
                                    <p>&#x2022; {t("privacyPolicyPage.paragraph3.2.2", "create an account on our website;")}</p>
                                    <p>&#x2022; {t("privacyPolicyPage.paragraph3.2.3", "subscribe to our service or publications;")}</p>
                                    <p>&#x2022; {t("privacyPolicyPage.paragraph3.2.4", "request marketing to be sent to you;")}</p>
                                    <p>&#x2022; {t("privacyPolicyPage.paragraph3.2.5", "enter a competition, promotion or survey; or")}</p>
                                    <p>&#x2022; {t("privacyPolicyPage.paragraph3.2.6", "give us feedback or contact us.")}</p>
                                </div>
                            </p>
                            <p>&#x2022; <b>{t("privacyPolicyPage.paragraph3.3.automatedTechnologiesOrInteractions", "Automated technologies or interactions.")}</b> {t("privacyPolicyPage.paragraph3.3", " As you interact with our website, we will automatically collect Technical Data about your equipment, browsing actions and patterns. We collect this personal data by using cookies and other similar technologies. We may also receive Technical Data about you if you visit other websites employing our cookies.")} { /*not included stuff on the right in the use translate as it contains html*/} <span style={{fontWeight: "normal"}}> Please see our <a href="/cookie-policy">cookie policy</a> for further details. </span></p>
                            <p>&#x2022; <b>{t("privacyPolicyPage.paragraph3.4.thirdPartiesOrPubliclyAvailableSources", "Third parties or publicly available sources.")}</b>{t("privacyPolicyPage.paragraph3.4", " We will receive personal data about you from various third parties and public sources as set out below: ")}</p>

                            <p>{t("privacyPolicyPage.paragraph3.5", "Technical Data from the following parties:")}
                                <LowerAlphaList listItems={[t("privacyPolicyPage.paragraph3.5.1.a", "analytics providers such as Google based outside the EU;")]} />
                            </p>
                            {/*below line is unfinished? seems to only have one payment provider listed, not in a way that joins the rest of the sentence and also a left in note for any other providers*/}
                            <p>&#x2022; {t("privacyPolicyPage.paragraph3.6", "Contact, Financial and Transaction Data from providers of technical, payment and delivery services such as PayPal or any other payment providers")}</p>
                            <p>&#x2022; {t("privacyPolicyPage.paragraph3.7", "Identity and Contact Data from publicly available sources such as Companies House and the Electoral Register based inside the UK and EU.")}</p>
                            <p>&#x2022; {t("privacyPolicyPage.paragraph3.8", "Facebook, LinkedIn, and Instagram lead generation tools")}</p>
                            <p>&#x2022; {t("privacyPolicyPage.paragraph3.9", "Mailing lists via Zymplify")}</p>

                        </li>
                        <li className="li-sub-heading"><span>{t("privacyPolicyPage.subHeading.4", "How we use your personal data")}</span>
                            <p>{t("privacyPolicyPage.paragraph4.1", "We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in the following circumstances:")}
                                <div>
                                    <p>&#x2022; {t("privacyPolicyPage.paragraph4.1.1", "Where we need to perform the contract, we are about to enter into or have entered into with you.")}</p>
                                    <p>&#x2022; {t("privacyPolicyPage.paragraph4.1.2", "Where it is necessary for our legitimate interests (or those of a third party) and your interests and fundamental rights do not override those interests.")}</p>
                                    <p>&#x2022; {t("privacyPolicyPage.paragraph4.1.3", "Where we need to comply with a legal obligation.")}</p>
                                </div>
                            </p>

                            <p>{t("privacyPolicyPage.paragraph4.2", "Generally, we do not rely on consent as a legal basis for processing your personal data although we will get your consent before sending third party direct marketing communications to you via email or text message. You have the right to withdraw consent to marketing at any time by contacting us.")}</p>

                            <h3>{t("privacyPolicyPage.paragraph4.3.header", "Purposes for which we will use your personal data")}</h3>

                            <p>{t("privacyPolicyPage.paragraph4.4", "We have set out below, in a table format, a description of all the ways we plan to use your personal data, and which of the legal bases we rely on to do so. We have also identified what our legitimate interests are where appropriate.")}</p>
                            <p>{t("privacyPolicyPage.paragraph4.5", "Note that we may process your personal data for more than one lawful ground depending on the specific purpose for which we are using your data. Please contact us if you need details about the specific legal ground we are relying on to process your personal data where more than one ground has been set out in the table below.")}</p>

                            <table className="with-borders">
                                <thead>
                                    <tr>
                                        <th>{t("privacyPolicyPage.paragraph4.tableHeader.1", "Purpose/Activity")}</th>
                                        <th>{t("privacyPolicyPage.paragraph4.tableHeader.2", "Type of data")}</th>
                                        <th>{t("privacyPolicyPage.paragraph4.tableHeader.3", "Lawful basis for processing including basis of legitimate interest")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{t("privacyPolicyPage.paragraph4.tableRow1.1", "To register you as a new customer")}</td>
                                        <td>
                                            <LowerAlphaList listItems={
                                                [t("privacyPolicyPage.paragraph4.tableRow1.2.a", "Identity"),
                                                t("privacyPolicyPage.paragraph4.tableRow1.2.b", "Contact")]
                                            } />
                                        </td>
                                        <td>{t("privacyPolicyPage.paragraph4.tableRow1.3", "Performance of a contract with you")}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>{t("privacyPolicyPage.paragraph4.tableRow2.1", "To process and deliver your order including:")}
                                                <LowerAlphaList listItems={
                                                    [t("privacyPolicyPage.paragraph4.tableRow2.1.a", "Manage payments, fees and charges"),
                                                    t("privacyPolicyPage.paragraph4.tableRow2.1.b", "Collect and recover money owed to us")]
                                                } />
                                            </p>
                                        </td>
                                        <td>
                                            <LowerAlphaList listItems={
                                                [t("privacyPolicyPage.paragraph4.tableRow2.2.a", "Identity"),
                                                    t("privacyPolicyPage.paragraph4.tableRow2.2.b", "Contact"),
                                                    t("privacyPolicyPage.paragraph4.tableRow2.2.c", "Financial"),
                                                    t("privacyPolicyPage.paragraph4.tableRow2.2.d", "Transaction"),
                                                    t("privacyPolicyPage.paragraph4.tableRow2.2.e", "Marketing and Communications")]
                                            } />
                                        </td>
                                        <td>
                                            <LowerAlphaList listItems={
                                                [t("privacyPolicyPage.paragraph4.tableRow2.3.a", "Performance of a contract with you"),
                                                    t("privacyPolicyPage.paragraph4.tableRow2.3.b", "Necessary for our legitimate interests (to recover debts due to us)")]
                                            } />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>{t("privacyPolicyPage.paragraph4.tableRow3.1", "To manage our relationship with you which will include:")}
                                                <LowerAlphaList listItems={
                                                    [t("privacyPolicyPage.paragraph4.tableRow3.1.a", "Notifying you about changes to our terms or privacy policy"),
                                                        t("privacyPolicyPage.paragraph4.tableRow3.1.b", "Asking you to leave a review or take a survey")]
                                                } />
                                            </p>
                                        </td>
                                        <td>
                                            <LowerAlphaList listItems={
                                                [t("privacyPolicyPage.paragraph4.tableRow3.2.a", "Identity"),
                                                    t("privacyPolicyPage.paragraph4.tableRow3.2.b", "Contact"),
                                                    t("privacyPolicyPage.paragraph4.tableRow3.2.c", "Profile"),
                                                    t("privacyPolicyPage.paragraph4.tableRow3.2.d", "Marketing and Communications")]
                                            } />
                                        </td>
                                        <td>
                                            <LowerAlphaList listItems={
                                                [t("privacyPolicyPage.paragraph4.tableRow3.3.a", "Performance of a contract with you"),
                                                    t("privacyPolicyPage.paragraph4.tableRow3.3.b", "Necessary to comply with a legal obligation"),
                                                    t("privacyPolicyPage.paragraph4.tableRow3.3.c", "Necessary for our legitimate interests (to keep our records updated and to study how customers use our products/services)")]
                                            } />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{t("privacyPolicyPage.paragraph4.tableRow4.1", "To enable you to partake in a prize draw, competition or complete a survey")}</td>
                                        <td>
                                            <LowerAlphaList listItems={
                                                [t("privacyPolicyPage.paragraph4.tableRow4.2.a", "Identity"),
                                                    t("privacyPolicyPage.paragraph4.tableRow4.2.b", "Contact"),
                                                    t("privacyPolicyPage.paragraph4.tableRow4.2.c", "Profile"),
                                                    t("privacyPolicyPage.paragraph4.tableRow4.2.d", "Usage"),
                                                    t("privacyPolicyPage.paragraph4.tableRow4.2.e", "Marketing and Communications")]
                                            } />
                                        </td>
                                        <td>
                                            <LowerAlphaList listItems={
                                                [t("privacyPolicyPage.paragraph4.tableRow4.3.a", "Performance of a contract with you "),
                                                    t("privacyPolicyPage.paragraph4.tableRow4.3.b", "Necessary for our legitimate interests (to study how customers use our products/services, to develop them and grow our business)")]
                                            } />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{t("privacyPolicyPage.paragraph4.tableRow5.1", "To administer and protect our business and this website (including troubleshooting, data analysis, testing, system maintenance, support, reporting and hosting of data)")}</td>
                                        <td>
                                            <LowerAlphaList listItems={
                                                [t("privacyPolicyPage.paragraph4.tableRow5.2.a", "Identity"),
                                                    t("privacyPolicyPage.paragraph4.tableRow5.2.b", "Contact"),
                                                    t("privacyPolicyPage.paragraph4.tableRow5.2.c", "Technical")]
                                            } />
                                        </td>
                                        <td>
                                            <LowerAlphaList listItems={
                                                [t("privacyPolicyPage.paragraph4.tableRow5.3.a", "Necessary for our legitimate interests (for running our business, provision of administration and IT services, network security, to prevent fraud and in the context of a business reorganisation or group restructuring exercise)"),
                                                    t("privacyPolicyPage.paragraph4.tableRow5.3.b", "Necessary to comply with a legal obligation")]
                                            } />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{t("privacyPolicyPage.paragraph4.tableRow6.1", "To deliver relevant website content and advertisements to you and measure or understand the effectiveness of the advertising we serve to you")}</td>
                                        <td>
                                            <LowerAlphaList listItems={
                                                [t("privacyPolicyPage.paragraph4.tableRow6.2.a", "Identity"),
                                                    t("privacyPolicyPage.paragraph4.tableRow6.2.b", "Contact"),
                                                    t("privacyPolicyPage.paragraph4.tableRow6.2.c", "Profile"),
                                                    t("privacyPolicyPage.paragraph4.tableRow6.2.d", "Usage"),
                                                    t("privacyPolicyPage.paragraph4.tableRow6.2.e", "Marketing and Communications"),
                                                    t("privacyPolicyPage.paragraph4.tableRow6.2.f", "Technical"),                                            ]
                                            } />
                                        </td>
                                        <td>{t("privacyPolicyPage.paragraph4.tableRow6.3", "Necessary for our legitimate interests (to study how customers use our products/services, to develop them, to grow our business and to inform our marketing strategy)")}</td>
                                    </tr>
                                    <tr>
                                        <td>{t("privacyPolicyPage.paragraph4.tableRow7.1", "To use data analytics to improve our website, products/services, marketing, customer relationships and experiences")}</td>
                                        <td><LowerAlphaList listItems={
                                                [t("privacyPolicyPage.paragraph4.tableRow7.2.a", "Technical"),
                                                    t("privacyPolicyPage.paragraph4.tableRow7.2.b", "Usage")]
                                            } />
                                        </td>
                                        <td>{t("privacyPolicyPage.paragraph4.tableRow7.3", "Necessary for our legitimate interests (to define types of customers for our products and services, to keep our website updated and relevant, to develop our business and to inform our marketing strategy)")}</td>
                                    </tr>
                                    <tr>
                                        <td>{t("privacyPolicyPage.paragraph4.tableRow8.1", "To make suggestions and recommendations to you about goods or services that may be of interest to you")}</td>
                                            <td><LowerAlphaList listItems={
                                                [t("privacyPolicyPage.paragraph4.tableRow8.2.a", "Identity"),
                                                    t("privacyPolicyPage.paragraph4.tableRow8.2.b", "Contact"),
                                                    t("privacyPolicyPage.paragraph4.tableRow8.2.c", "Technical"),
                                                    t("privacyPolicyPage.paragraph4.tableRow8.2.d", "Usage"),
                                                    t("privacyPolicyPage.paragraph4.tableRow8.2.e", "Profile"),
                                                    t("privacyPolicyPage.paragraph4.tableRow8.2.f", "Marketing and Communications"),]
                                            } />
                                        </td>
                                        <td>{t("privacyPolicyPage.paragraph4.tableRow8.3", "Necessary for our legitimate interests (to develop our products/services and grow our business)")}</td>
                                    </tr>
                                </tbody>
                            </table>

                            <h3>{t("privacyPolicyPage.paragraph4.6.header", "Marketing")}</h3>
                            <p>{t("privacyPolicyPage.paragraph4.6", "We strive to provide you with choices regarding certain personal data uses, particularly around marketing and advertising.")}</p>

                            <h3>{t("privacyPolicyPage.paragraph4.7.header", "Promotional offers from us")}</h3>
                            <p>{t("privacyPolicyPage.paragraph4.7", "We may use your Identity, Contact, Technical, Usage and Profile Data to form a view on what we think you may want or need, or what may be of interest to you. This is how we decide which products, services and offers may be relevant for you (we call this marketing).")}</p>
                            <p>{t("privacyPolicyPage.paragraph4.8", "You will receive marketing communications from us if you have requested information from us or purchased goods or services from us and you have not opted out of receiving that marketing.")}</p>

                            <h3>{t("privacyPolicyPage.paragraph4.9.header", "Third-party marketing")}</h3>
                            <p>{t("privacyPolicyPage.paragraph4.9", "We will get your express opt-in consent before we share your personal data with any third party for marketing purposes.")}</p>

                            <h3>{t("privacyPolicyPage.paragraph4.10.header", "Opting out")}</h3>
                            <p>{t("privacyPolicyPage.paragraph4.10", "You can ask us or third parties to stop sending you marketing messages at any time by following the opt-out links on any marketing message sent to you or by contacting us at any time.")}</p>
                            <p>{t("privacyPolicyPage.paragraph4.11", "Where you opt out of receiving these marketing messages, this will not apply to personal data provided to us as a result of a product/service purchase, warranty registration, product/service experience or other transactions.")}</p>

                            <h3>{t("privacyPolicyPage.paragraph4.12.header", "Cookies")}</h3>
                            <p>{t("privacyPolicyPage.paragraph4.12", "You can set your browser to refuse all or some browser cookies, or to alert you when websites set or access cookies. If you disable or refuse cookies, please note that some parts of this website may become inaccessible or not function properly.")}{ /*not included stuff on the right in the use translate as it contains html*/} <span style={{ fontWeight: "normal" }}> For more information about the cookies we use, please see our <a href="/cookie-policy">cookie policy</a>.</span></p>

                            <h3>{t("privacyPolicyPage.paragraph4.13.header", "Change of purpose")}</h3>
                            <p>{t("privacyPolicyPage.paragraph4.13", "We will only use your personal data for the purposes for which we collected it, unless we reasonably consider that we need to use it for another reason and that reason is compatible with the original purpose. If you wish to get an explanation as to how the processing for the new purpose is compatible with the original purpose, please contact us.")}</p>
                            <p>{t("privacyPolicyPage.paragraph4.14", "If we need to use your personal data for an unrelated purpose, we will notify you and we will explain the legal basis which allows us to do so.")}</p>
                            <p>{t("privacyPolicyPage.paragraph4.15", "Please note that we may process your personal data without your knowledge or consent, in compliance with the above rules, where this is required or permitted by law.")}</p>
                        </li>
                        <li className="li-sub-heading"><span>{t("privacyPolicyPage.subHeading.5", "Disclosures of your personal data")}</span>
                            <p>{t("privacyPolicyPage.paragraph5.1", "We may share your personal data with the parties set out below for the purposes set out in the table Purposes for which we will use your personal data above.")}
                                <p>&#x2022; {t("privacyPolicyPage.paragraph5.1.1", "External Third Parties as set out in the Glossary.")}</p>
                                <p>&#x2022; {t("privacyPolicyPage.paragraph5.1.2Third parties to whom we may choose to sell, transfer or merge parts of our business or our assets. Alternatively, we may seek to acquire other businesses or merge with them. If a change happens to our business, then the new owners may use your personal data in the same way as set out in this privacy policy.")}</p>
                            </p>

                            <p>{t("privacyPolicyPage.paragraph5.2", "We require all third parties to respect the security of your personal data and to treat it in accordance with the law. We do not allow our third-party service providers to use your personal data for their own purposes and only permit them to process your personal data for specified purposes and in accordance with our instructions.")}</p>

                            
                        </li>
                        <li className="li-sub-heading"><span>{t("privacyPolicyPage.subHeading.6", "International transfers")}</span>
                            <p>{t("privacyPolicyPage.paragraph6.1", "Many of our external third parties are based outside the EEA so their processing of your personal data will involve a transfer of data outside the EEA.")}</p>

                            <p>{t("privacyPolicyPage.paragraph6.2", "Whenever we transfer your personal data out of the EEA, we ensure a similar degree of protection is afforded to it by ensuring at least one of the following safeguards is implemented")}
                                <p>&#x2022; {t("privacyPolicyPage.paragraph6.2.1", "We will only transfer your personal data to countries that have been deemed to provide an adequate level of protection for personal data by the European Commission. For further details, see European Commission: Adequacy of the protection of personal data in non-EU countries.")}</p>
                                <p>&#x2022; {t("privacyPolicyPage.paragraph6.2.1", "Where we use certain service providers, we may use specific contracts approved by the European Commission which give personal data the same protection it has in Europe. For further details, see European Commission: Model contracts for the transfer of personal data to third countries.")}</p>
                                <p>&#x2022; {t("privacyPolicyPage.paragraph6.2.1", "Where we use providers based in the US, we may transfer data to them if they are part of the Privacy Shield which requires them to provide similar protection to personal data shared between Europe and the US. For further details, see European Commission: EU-US Privacy Shield.")}</p>
                            </p>
                            <p>{t("privacyPolicyPage.paragraph6.3", "Where we use providers based in the US, we may transfer data to them if they are part of the Privacy Shield which requires them to provide similar protection to personal data shared between Europe and the US. For further details, see European Commission: EU-US Privacy Shield.")}</p>

                        </li>
                        <li className="li-sub-heading"><span>{t("privacyPolicyPage.subHeading.7", "7.	Data security")}</span>
                            <p>{t("privacyPolicyPage.paragraph7.1", "We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorised way, altered or disclosed. In addition, we limit access to your personal data to those employees, agents, contractors and other third parties who have a business need to know. They will only process your personal data on our instructions, and they are subject to a duty of confidentiality.")}</p>
                            <p>{t("privacyPolicyPage.paragraph7.2", "We have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of a breach where we are legally required to do so.")}</p>
                        </li>
                        <li className="li-sub-heading"><span>{t("privacyPolicyPage.subHeading.8", "Data retention")}</span>
                            <h3>{t("privacyPolicyPage.paragraph8.1.header", "How long will you use my personal data for?")}</h3>
                            <p>{t("privacyPolicyPage.paragraph8.1", "We will only retain your personal data for as long as reasonably necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, regulatory, tax, accounting or reporting requirements. We may retain your personal data for a longer period in the event of a complaint or if we reasonably believe there is a prospect of litigation in respect to our relationship with you.")}</p>
                            <p>{t("privacyPolicyPage.paragraph8.2", "To determine the appropriate retention period for personal data, we consider the amount, nature and sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal, regulatory, tax, accounting or other requirements.")}</p>
                            <p>{t("privacyPolicyPage.paragraph8.3", "In some circumstances you can ask us to delete your data: see your legal rights below for further information.")}</p>
                            <p>{t("privacyPolicyPage.paragraph8.4", "In some circumstances we will anonymise your personal data (so that it can no longer be associated with you) for research or statistical purposes, in which case we may use this information indefinitely without further notice to you.")}</p>


                        </li>
                        <li className="li-sub-heading"><span>{t("privacyPolicyPage.subHeading.9", "Your legal rights")}</span>
                            <p>{t("privacyPolicyPage.paragraph9.1", "Under certain circumstances, you have rights under data protection laws in relation to your personal data. Please see Your Legal Rights section below to find out more about these rights:")}</p>
                            {/*Sent over document had a set of links here to further down the document. looping this in with the discussion I had with Scott about a glossary where it was decided we arent linking to specific points on pages*/}

                            <p>{t("privacyPolicyPage.paragraph9.2", "If you wish to exercise any of these rights, please contact us.")}</p>

                            <h3>{t("privacyPolicyPage.paragraph9.3.header", "No fee usually required")}</h3>
                            <p>{t("privacyPolicyPage.paragraph9.3", "You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we could refuse to comply with your request in these circumstances.")}</p>

                            <h3>{t("privacyPolicyPage.paragraph9.4.header", "What we may need from you")}</h3>
                            <p>{t("privacyPolicyPage.paragraph9.4", "We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal data (or to exercise any of your other rights). This is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response.")}</p>

                            <h3>{t("privacyPolicyPage.paragraph9.4.header", "Time limit to respond")}</h3>
                            <p>{t("privacyPolicyPage.paragraph9.4", "We try to respond to all legitimate requests within one month. Occasionally it could take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.")}</p>
                        </li>
                        <li className="li-sub-heading"><span>{t("privacyPolicyPage.subHeading.10", "Glossary")}</span>

                            <h3>{t("privacyPolicyPage.paragraph10.1.header", "LAWFUL BASIS")}</h3>
                            <p>{t("privacyPolicyPage.paragraph10.1", "Legitimate Interest means the interest of our business in conducting and managing our business to enable us to give you the best service/product and the best and most secure experience. We make sure we consider and balance any potential impact on you (both positive and negative) and your rights before we process your personal data for our legitimate interests. We do not use your personal data for activities where our interests are overridden by the impact on you (unless we have your consent or are otherwise required or permitted to by law). You can obtain further information about how we assess our legitimate interests against any potential impact on you in respect of specific activities by contacting us.")}</p>
                            <p>{t("privacyPolicyPage.paragraph10.2", "Performance of Contract means processing your data where it is necessary for the performance of a contract to which you are a party or to take steps at your request before entering into such a contract.")}</p>
                            <p>{t("privacyPolicyPage.paragraph10.3", "Comply with a legal obligation means processing your personal data where it is necessary for compliance with a legal obligation that we are subject to.")}</p>

                            <h3>{t("privacyPolicyPage.paragraph10.4.header", "THIRD PARTIES")}</h3>
                            <h3>{t("privacyPolicyPage.paragraph10.5.header", "Internal Third Parties")}</h3>
                            <p>{t("privacyPolicyPage.paragraph10.5", "No other companies act as joint controllers or processors internally.")}</p>
                            <h3>{t("privacyPolicyPage.paragraph10.6.header", "External Third Parties")}
                                <p style={{ fontWeight: "normal" }}> &#x2022; {t("privacyPolicyPage.paragraph10.6.1", "Service providers based outside the European Union who provide IT and system administration services.")}</p>
                                <p style={{ fontWeight: "normal" }}> &#x2022; {t("privacyPolicyPage.paragraph10.6.2", "Professional advisers including lawyers, bankers, auditors and insurers based within the United Kingdom, European Union who provide consultancy, banking, legal, insurance and accounting services")}</p>
                                <p style={{ fontWeight: "normal" }}> &#x2022; {t("privacyPolicyPage.paragraph10.6.3", "HM Revenue & Customs, regulators and other authorities based in the United Kingdom who require reporting of processing activities in certain circumstances.")}</p>
                            </h3>

                            <h3>{t("privacyPolicyPage.paragraph10.7.header", "YOUR LEGAL RIGHTS")}</h3>
                            <p>{t("privacyPolicyPage.paragraph10.7", "You have the right to:")}</p>
                            <p><b>Request access</b>{t("privacyPolicyPage.paragraph10.8", " to your personal data (commonly known as a \"data subject access request\"). This enables you to receive a copy of the personal data we hold about you and to check that we are lawfully processing it.")}</p>
                            <p><b>Request correction</b>{t("privacyPolicyPage.paragraph10.9", " of the personal data that we hold about you. This enables you to have any incomplete or inaccurate data we hold about you corrected, though we may need to verify the accuracy of the new data you provide to us.")}</p>
                            <p><b>Request erasure</b>{t("privacyPolicyPage.paragraph10.10", " of your personal data. This enables you to ask us to delete or remove personal data where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your personal data where you have successfully exercised your right to object to processing (see below), where we may have processed your information unlawfully or where we are required to erase your personal data to comply with local law. Note, however, that we may not always be able to comply with your request of erasure for specific legal reasons which will be notified to you, if applicable, at the time of your request. ")}</p>
                            <p><b>Object to processing</b>{t("privacyPolicyPage.paragraph10.11", " of your personal data where we are relying on a legitimate interest (or those of a third party) and there is something about your particular situation which makes you want to object to processing on this ground as you feel it impacts on your fundamental rights and freedoms. You also have the right to object where we are processing your personal data for direct marketing purposes. In some cases, we may demonstrate that we have compelling legitimate grounds to process your information which override your rights and freedoms.")}</p>
                            <p><b>Request restriction</b>{t("privacyPolicyPage.paragraph10.12", " of processing of your personal data. This enables you to ask us to suspend the processing of your personal data in the following scenarios: ")}
                                <p> &#x2022; {t("privacyPolicyPage.paragraph10.12.1", "If you want us to establish the data's accuracy.")}</p>
                                <p> &#x2022; {t("privacyPolicyPage.paragraph10.12.2", "Where our use of the data is unlawful but you do not want us to erase it.")}</p>
                                <p> &#x2022; {t("privacyPolicyPage.paragraph10.12.3", "Where you need us to hold the data even if we no longer require it as you need it to establish, exercise or defend legal claims.")}</p>
                                <p> &#x2022; {t("privacyPolicyPage.paragraph10.12.4", "You have objected to our use of your data but we need to verify whether we have overriding legitimate grounds to use it.")}</p>
                            </p>
                            <p><b>Request the transfer</b>{t("privacyPolicyPage.paragraph10.13", " of your personal data to you or to a third party. We will provide to you, or a third party you have chosen, your personal data in a structured, commonly used, machine-readable format. Note that this right only applies to automated information which you initially provided consent for us to use or where we used the information to perform a contract with you.")}</p>
                            <p><b>Withdraw consent at any time</b>{t("privacyPolicyPage.paragraph10.14", " where we are relying on consent to process your personal data. However, this will not affect the lawfulness of any processing carried out before you withdraw your consent. If you withdraw your consent, we may not be able to provide certain products or services to you. We will advise you if this is the case at the time you withdraw your consent.")}</p>

                        </li>
                    </ol>
                </div>
            </MainContainer>
        </Background>
    );
};


