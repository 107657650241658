import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useMemo, KeyboardEvent } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Col, Collapse, Row } from "reactstrap";
import { useToggleState } from "use-toggle-state";
import { QuestionTag } from "../../../api/main/models/QuestionTag";
import { QuestionTagButton } from "./QuestionTagButton";
import "./questionTagSelector.scss";

export interface QuestionTagSelectorProps {
    tags: Array<QuestionTag>,
    isSelected: (id: string) => boolean,
    toggleSelected?: (id: string) => void,
}

/**
 * Selector for toggling a question tag on or off.
 */
export const QuestionTagSelector = (props: QuestionTagSelectorProps) => {
    const {
        tags,
        isSelected,
        toggleSelected,
    } = props;

    const { t } = useTranslation();

    const [isOpen, toggleOpen] = useToggleState();

    const selectedTags = useMemo(() => {
        return tags.filter(item => isSelected(item.id));
    }, [tags, isSelected]);

    // Handle keyboard keys in a way that let us act select like.
    const keyDown = useCallback((event: KeyboardEvent<HTMLDivElement>) => {
        switch (event.key) {
            case 'Down':
            case 'ArrowDown':
            case 'ArrowUp':
            case 'Esc':
            case 'Escape':
            case ' ':
            case 'Enter':
                toggleOpen();
                break;
            default:
                break;
        }
    }, [toggleOpen]);

    return (
        <div className="question-tag-selector form-control" tabIndex={0} onKeyDown={keyDown}>
            <div className="question-tag-selector-input" onClick={() => toggleOpen()}>
                <Row>
                    <Col>
                        {
                            selectedTags
                                .map(item => (
                                    <QuestionTagButton key={item.id}
                                        tag={item}
                                        isSelected={true}
                                        readonly={true}
                                        gray={isOpen}
                                    />
                                ))
                        }
                        <ConditionalFragment showIf={!selectedTags.length}>
                            <span className="text-muted" style={{ userSelect: 'none' }}>
                                {t('questionTagSelector.nothingSelected', 'No tags have been selected.')}
                            </span>
                        </ConditionalFragment>
                    </Col>
                    <Col xs="auto">
                        <FontAwesomeIcon icon={isOpen ? 'caret-up' : 'caret-down'} />
                    </Col>
                </Row>
            </div>
            <Collapse isOpen={isOpen}>
                <div className="question-tag-selector-dropdown">
                    {
                        tags.map(item => (
                            <QuestionTagButton key={item.id}
                                tag={item}
                                isSelected={isSelected(item.id)}
                                toggleSelected={() => {
                                    if (toggleSelected) {
                                        toggleSelected(item.id);
                                    }
                                }}
                            />
                        ))
                    }
                </div>
            </Collapse>
        </div>
        );
};
