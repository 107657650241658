import * as React from 'react';
import { SdkClientCreateInput, SdkClientUpdateInput } from '../generated/globalTypes';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useCreateSdkClientCallback } from './useCreateSdkClientCallback';
import { useUpdateSdkClientCallback } from './useUpdateSdkClientCallback';
import { SdkClient } from '../models/SdkClient';

/**
 * Get a callback to save a SdkClient in the store using either a create or update.
 */
export function useSaveSdkClientCallback(options: SaveInStoreOptions<SdkClient, string> = {}) {
    const [_create, createStatus] = useCreateSdkClientCallback();
    const create = React.useCallback((model: Partial<SdkClient>) => _create(model as SdkClientCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateSdkClientCallback();
    const update = React.useCallback((id: string, changes: Partial<SdkClient>) => _update(id, changes as SdkClientUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
