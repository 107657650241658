import gql from "graphql-tag";
import { userFields } from "../generated/userFields";
import { roleGroupFieldsFragment } from "./RoleGroup";

export const userFieldsFragment = gql`
    fragment userFields on User {
        id
        userName
        email
        emailConfirmed
        phoneNumberConfirmed
        twoFactorEnabled
        lockoutEnd
        lockoutEnabled
        accessFailedCount
        roleGroup {
            ...roleGroupFields
        }
    }

    ${roleGroupFieldsFragment}
`;


export type User = Omit<userFields, '__typename' | 'roleGroup'>;
