import Slider from "rc-slider";
import { ReactNode, useCallback, useMemo } from "react";
import { QuestionAnswer } from "../../../../api/main/models/QuestionAnswer";
import { HtmlDisplay } from "../../../../shared/htmlEditor";
import "./slidingScaleSlider.scss";

export interface SlidingScaleSliderProps {
    answers: Array<QuestionAnswer>,

    selectedAnswerId?: string | undefined,
    onSelectedAnswerChanged?: (answer: QuestionAnswer | undefined) => void,
}

/**
 * Slider that shows the answers for SlidingScaleVideo questions.
 * @param props
 */
export const SlidingScaleSlider = (props: SlidingScaleSliderProps) => {
    const {
        answers,
        selectedAnswerId,
        onSelectedAnswerChanged,
    } = props;

    const { min, max, marks } = useMemo(() => {
        let marks: { [key: number]: ReactNode } = {};

        // Add an entry for each answer.
        for (let i = 0; i < answers.length; ++i) {
            const answer = answers[i];
            marks[i] = <HtmlDisplay html={answer.answerText} />;
        }

        // If we need to, add a fake starting answer to make sure we have one.
        if (answers.length <= 0) {
            marks[0] = (<></>);
        }

        // If we need to, add a fake ending answer to make sure we always have one.
        if (answers.length <= 1) {
            marks[1] = (<></>);
        }

        return {
            min: 0,
            max: answers.length < 2 ? 1 : answers.length - 1,
            marks: marks,
        };
    }, [answers]);

    const defaultValue = Math.floor((max - min) / 2);
    const selectedAnswerIndex = useMemo(() => {
        if (!selectedAnswerId) {
            return undefined;
        }

        const selected = answers.find(item => item.id === selectedAnswerId);
        if (!selected) {
            return undefined;
        }

        return answers.indexOf(selected);
    }, [answers, selectedAnswerId]);
    // Select an answer by index.
    const onSelectedAnswerIndexChanged = useCallback((index: number) => {
        // If we have no callback wanting to handle the event, do nothing.
        if (!onSelectedAnswerChanged) {
            return;
        }

        // If we are out of bounds, unselect an answer.
        if (index < 0 || index > answers.length) {
            onSelectedAnswerChanged(undefined);
        }

        // We have selected an actual answer.
        const selectedAnswer = answers[index];
        onSelectedAnswerChanged(selectedAnswer);
    }, [answers, onSelectedAnswerChanged]);

    return (
        <div className="sliding-scale-slider">
            <Slider min={min} max={max} marks={marks} defaultValue={defaultValue}
                value={selectedAnswerIndex}
                onChange={index => onSelectedAnswerIndexChanged(index)}
            />
        </div>
        );
};