import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { deleteDriverMetricRecommendationMutation, deleteDriverMetricRecommendationMutationVariables } from '../generated/deleteDriverMetricRecommendationMutation';
import { driverMetricRecommendationFieldsFragment } from '../models/DriverMetricRecommendation';

/**
 * Get a callback to delete a DriverMetricRecommendation in the store.
 */
export function useDeleteDriverMetricRecommendationCallback(): [(id: string) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<deleteDriverMetricRecommendationMutation, deleteDriverMetricRecommendationMutationVariables>(
        gql`
            mutation deleteDriverMetricRecommendationMutation ($id: ID!) {
                deleteDriverMetricRecommendation(id: $id) {
                    ...driverMetricRecommendationFields
                }
            }

            ${driverMetricRecommendationFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (id: string) => {
        const result = await mutationAction({ variables: { id: id } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
