import { useCallback, useMemo } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { Link } from "react-router-dom";
import { Button, Col, ListGroup, ListGroupItemHeading, Row } from "reactstrap";
import { Assessment } from "../../../api/main/models/Assessment";
import { AssessmentSession } from "../../../api/main/models/AssessmentSession";
import { AssessmentType } from "../../../api/main/models/codeOnly/AssessmentType";
import { Subscription } from "../../../api/main/models/Subscription";
import { UserAssessmentAssignment } from "../../../api/main/models/UserAssessmentAssignment";
import { useActiveDashboardRiskCategoryFilter } from "../../../globalState/activeDashboardRiskCategoryFilter/useActiveDashboardRiskCategoryFilter";
import { useAverageDriverMetricRiskChartData } from "../chartData/useAverageDriverMetricRiskChartData";
import { useAverageScoreChartData } from "../chartData/useAverageScoreChartData";
import { useCompletionChartData } from "../chartData/useCompletionChartData";
import { AverageDriverMetricRiskDonutChart } from "../charts/AverageDriverMetricRiskDonutChart";
import { AverageScoreRadarChart } from "../charts/AverageScoreRadarChart";
import { CompletionBarChart } from "../charts/CompletionBarChart";
import { DashboardListGroupItem } from "../listGroups/DashboardListGroupItem";
import { OverallRiskFilterListGroupItem } from "../listGroups/OverallRiskFilterListGroupItem";
import { DashboardSummary } from "../utilities/generateDashboardSummary";
import { useCachedDashboardSummaries } from "../utilities/useCachedDashboardSummaries";
import { useDashboardBaseRoute } from "../utilities/useDashboardBaseRoute";
import { useCurrentUserOrEmulatedSubscriptionId } from "../../../globalState/subscriptions/useCurrentUserOrEmulatedSubscriptionId";

export interface SubscriptionTabProps {
    subscriptions: Array<Subscription>,
    generateDashboardSummary: (
        sessionFilter: (session: AssessmentSession) => boolean,
        assignmentFilter: (assignment: UserAssessmentAssignment) => boolean,
        assessmentType: AssessmentType
    ) => DashboardSummary,

    assessmentSessions: Array<AssessmentSession>,
    assessments: Array<Assessment>,
    activeAssessmentType: AssessmentType,
}

/**
 * Subscriptions tab on the dashboard.
 * @param props
 */
export const DistributorSubscriptionsDashboardTab = (props: SubscriptionTabProps) => {
    const {
        subscriptions,
        generateDashboardSummary,
        activeAssessmentType,
    } = props;

    const subscriptionId = useCurrentUserOrEmulatedSubscriptionId();

    const filteredSubscriptions = subscriptions.filter(item => item.distributorSubscriptionId === subscriptionId);

    // As a special case for subscriptions, we add "Esitu staff" on to the subscription lists.  As we'll do this several times we store it here for reuse.
    const subscriptionsAndEsitu = useMemo(() => [...(filteredSubscriptions?.map(item => ({ id: item.id, name: item.companyName })) ?? [])], [filteredSubscriptions]);

    // Method we use to filter sessions by subscription wherever we need it.
    const filterBySubscription = useCallback((subscription: { id: string }, item: { subscriptionId: string | undefined | null }) => {
        if (!subscription.id) {
            return !item.subscriptionId;
        }

        return item.subscriptionId === subscription.id;
    }, []);

    // Cache the dashboard summaries so each chart/display doesn't need to recaclulate them and they can be looked up easily by id.
    const getCachedDashboardSummary = useCachedDashboardSummaries(
        subscriptionsAndEsitu,
        (group, assessmentType) => generateDashboardSummary(
            session => filterBySubscription(group, session),
            assignment => filterBySubscription(group, assignment),
            assessmentType
        )
    );

    // Get data for the various charts.
    const averageScoreChartData = useAverageScoreChartData(subscriptionsAndEsitu, getCachedDashboardSummary, activeAssessmentType);
    const averageDriverMetricRiskChartData = useAverageDriverMetricRiskChartData(subscriptionsAndEsitu, getCachedDashboardSummary, activeAssessmentType);
    const completionChartData = useCompletionChartData(subscriptionsAndEsitu, getCachedDashboardSummary, activeAssessmentType);

    // Filter the visible results by overall risk category if required.
    const [riskCategoryFilter, setRiskCategoryFilter] = useActiveDashboardRiskCategoryFilter();
    const filteredSubscriptionsAndEsitu = useMemo(() => {
        if (!riskCategoryFilter) {
            return subscriptionsAndEsitu;
        }

        return subscriptionsAndEsitu.filter(user => {
            const summary = getCachedDashboardSummary(user.id, activeAssessmentType);
            return (summary.averageUserDriverMetricScore.riskCategory === riskCategoryFilter);
        })
    }, [subscriptionsAndEsitu, riskCategoryFilter, getCachedDashboardSummary, activeAssessmentType]);
    
    const baseRoute = useDashboardBaseRoute();

    return (
        <Row>
            <Col xs={12} lg="">
                <ListGroup className="mb-4">
                    <OverallRiskFilterListGroupItem riskCategoryFilter={riskCategoryFilter} setRiskCategoryFilter={setRiskCategoryFilter} />
                    {
                        filteredSubscriptionsAndEsitu.map(subscription => (
                            <DashboardListGroupItem
                                key={subscription.id}
                                getDashboardSummary={assessmentType => getCachedDashboardSummary(subscription.id, assessmentType)}
                                progressType="risk"
                            >
                            {
                                    subscription.id ? (
                                        <Link to={`${baseRoute}/subscription/${subscription.id}`}>
                                            <ListGroupItemHeading>
                                                {subscription.name}
                                            </ListGroupItemHeading>
                                        </Link>
                                    ) : (
                                        <Button color="link" style={{ padding: '0px' }}>
                                            <ListGroupItemHeading>
                                                {subscription.name}
                                            </ListGroupItemHeading>
                                        </Button>
                                    )
                                }
                            </DashboardListGroupItem>
                        ))
                    }
                </ListGroup>
            </Col>
            <Col>
                <ConditionalFragment showIf={activeAssessmentType === AssessmentType.Assessment}>
                    <AverageDriverMetricRiskDonutChart data={averageDriverMetricRiskChartData} assessmentType={activeAssessmentType} />
                </ConditionalFragment>
                <AverageScoreRadarChart data={averageScoreChartData} assessmentType={activeAssessmentType} riskRules={null} />
                <CompletionBarChart data={completionChartData} assessmentType={activeAssessmentType} />
            </Col>
        </Row>
        );
};