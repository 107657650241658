import { useEffect, useMemo, useState } from "react";
import { HtmlDisplay } from "../../../../../shared/htmlEditor";
import { ShowQuestionChildProps } from "../ShowQuestion";

/**
 * Running of a LInformationImage question.
 * @param props
 */
export const ShowQuestionInformationImage = (props: ShowQuestionChildProps) => {
    const {
        model,
        blobReferences,

        onPageComplete,
    } = props;

    const imageBlob = useMemo(() => blobReferences.find(it => it.id === model.imageBlobReferenceId), [blobReferences, model]);

    // Some calls to apis (such as saving clicks) will cause us to generate a new secure video URL.  To make sure this doesn't reset out
    // video we cache the poster and video urls as we original receive them into our state and always use them.
    const [imageUrl] = useState<string>(imageBlob?.url ?? '');

    // There is no end event for this type of learning so we are complete as soon as we're shown.
    useEffect(() => {
        onPageComplete();
    }, [onPageComplete]);

    return (
        <div>
            <img className="img-fluid" src={imageUrl} alt="Feedback" />

            <div className="mt-4">
                <HtmlDisplay html={model.questionText} />
            </div>
        </div>
    );
};