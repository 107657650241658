import * as React from 'react';
import { AssessmentItemCreateInput, AssessmentItemUpdateInput } from '../generated/globalTypes';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useCreateAssessmentItemCallback } from './useCreateAssessmentItemCallback';
import { useUpdateAssessmentItemCallback } from './useUpdateAssessmentItemCallback';
import { AssessmentItem } from '../models/AssessmentItem';

/**
 * Get a callback to save a AssessmentItem in the store using either a create or update.
 */
export function useSaveAssessmentItemCallback(options: SaveInStoreOptions<AssessmentItem, string> = {}) {
    const [_create, createStatus] = useCreateAssessmentItemCallback();
    const create = React.useCallback((model: Partial<AssessmentItem>) => _create(model as AssessmentItemCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateAssessmentItemCallback();
    const update = React.useCallback((id: string, changes: Partial<AssessmentItem>) => _update(id, changes as AssessmentItemUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
