import { Button, Row, Col, Form, Label, FormGroup, Spinner, NavItem, NavLink, FormText } from 'reactstrap';
import { AlertOnErrors } from '../../../shared/alertOnErrors';
import { LoadingIndicator } from '../../shared/LoadingIndicator';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContainer } from '../../shared/MainContainer';
import { useParams, useHistory } from 'react-router';
import { useChanges, useChangesArray } from '../../../shared/useChanges';
import { useSaveAssessmentCallback } from '../../../api/main/assessments/useSaveAssessmentCallback';
import { useValidatorCallback } from 'pojo-validator-react';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { FormButtons } from '../../shared/FormButtons';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { useAsyncCallback } from 'react-use-async-callback';
import { Guid } from 'guid-string';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Banner } from '../../shared/Banner';
import { Background } from '../../shared/background/Background';
import { AssessmentType } from '../../../api/main/models/codeOnly/AssessmentType';
import { PillsNavBar } from '../../shared/pillsNavBar/PillsNavBar';
import { StickyToolbar } from '../../shared/StickyToolbar';
import { AssessmentFeedback } from '../../../api/main/models/AssessmentFeedback';
import { useSaveAssessmentFeedbackCallback } from '../../../api/main/assessmentFeedbacks/useSaveAssessmentFeedbackCallback';
import { useDeleteAssessmentFeedbackCallback } from '../../../api/main/assessmentFeedbacks/useDeleteAssessmentFeedbackCallback';
import { useEditAssessmentSupportingData } from '../../../api/main/assessments/viewModels/useEditAssessmentSupportingData';
import { QuestionTagLink } from '../../../api/main/models/QuestionTagLink';
import { useDeleteQuestionTagLinkCallback } from '../../../api/main/questionTagLinks/useDeleteQuestionTagLinkCallback';
import { DriverMetricAdjustment } from '../../../api/main/models/DriverMetricAdjustment';
import { useSaveDriverMetricAdjustmentCallback } from '../../../api/main/driverMetricAdjustments/useSaveDriverMetricAdjustmentCallback';
import { useDeleteDriverMetricAdjustmentCallback } from '../../../api/main/driverMetricAdjustments/useDeleteDriverMetricAdjustmentCallback';
import { DriverMetricRecommendation } from '../../../api/main/models/DriverMetricRecommendation';
import { useSaveDriverMetricRecommendationCallback } from '../../../api/main/driverMetricRecommendations/useSaveDriverMetricRecommendationCallback';
import { useDeleteDriverMetricRecommendationCallback } from '../../../api/main/driverMetricRecommendations/useDeleteDriverMetricRecommendationCallback';
import { useSaveQuestionTagLinkCallback } from '../../../api/main/questionTagLinks/useSaveQuestionTagLinkCallback';
import { useValidatorArrayCallback } from '../../../shared/validator-react-contrib/useValidatorArrayCallback';
import { hasAnyValidationErrors } from '../../../utilities/hasAnyValidationErrors';
import { AssessmentItem } from '../../../api/main/models/AssessmentItem';
import { useSaveAssessmentItemCallback } from '../../../api/main/assessmentItems/useSaveAssessmentItemCallback';
import { useDeleteAssessmentItemCallback } from '../../../api/main/assessmentItems/useDeleteAssessmentItemCallback';
import { FeedbackTab } from './feedback/FeedbackTab';
import { DriverMetricsTab } from './driverMetrics/DriverMetricsTab';
import { QuestionTagsTab } from './questionTags/QuestionTagsTab';
import { useBlobReferenceStateArray } from '../../shared/useBlobReferenceState/useBlobReferenceStateArray';
import { BlobReference } from '../../../api/main/models/BlobReference';
import { useEffect, useMemo, useState } from 'react';
import { useEditAssessmentViewModel } from '../../../api/main/assessments/viewModels/useEditAssessmentViewModel';
import { Assessment, assessmentDefaultValues } from '../../../api/main/models/Assessment';
import { AssessmentItemQuestion } from '../../../api/main/models/AssessmentItemQuestion';
import { useSaveAssessmentItemQuestionCallback } from '../../../api/main/assessmentItemQuestions/useSaveAssessmentItemQuestionCallback';
import { useDeleteAssessmentItemQuestionCallback } from '../../../api/main/assessmentItemQuestions/useDeleteAssessmentItemQuestionCallback';
import { AssessmentItemsTab } from './assessmentItems/AssessmentItemsTab';
import { HtmlEditor } from '../../../shared/htmlEditor';
import moment from "moment";
import { useSaveAssessmentItemDriverMetricQuantityCallback } from '../../../api/main/AssessmentItemDriverMetricQuantity/useSaveAssessmentItemDriverMetricQuantityCallback';
import { AssessmentItemDriverMetricQuantity } from '../../../api/main/models/AssessmentItemDriverMetricQuantity';
import { useDeleteAssessmentItemDriverMetricQuantityCallback } from '../../../api/main/AssessmentItemDriverMetricQuantity/useDeleteAssessmentItemDriverMetricQuantityCallback';
import { defaultRiskRules, OverallRiskCategory, overallRiskService } from '../../../services/OverallRiskService';

export interface EditAssessmentBaseProps {
    isCreate?: boolean,
    onCreateDefaultValues?: () => Partial<Assessment>,
    defaultAssessmentType: AssessmentType,
}

/**
 * Create an Assessment based model.
 * @param props
 */
export const CreateAssessmentBase = (props: EditAssessmentBaseProps) => (<EditAssessmentBase isCreate={true} {...props} />);

/**
 * Edit an Assessment based model.
 */
export const EditAssessmentBase = (props: EditAssessmentBaseProps) => {
    const { isCreate, onCreateDefaultValues, defaultAssessmentType } = props;

    const { t } = useTranslation();
    const { id } = useParams<{ id: string | undefined }>();
    const { data: {
        model: storeModel,
        feedbacks: storeFeedbacks,
        questionTagLinks: storeQuestionTagLinks,
        driverMetricRecommendations: storeDriverMetricRecommendations,
        driverMetricAdjustments: storeDriverMetricAdjustments,
        assessmentItems: storeAssessmentItems,
        assessmentItemDriverMetricQuantities: storeAssessmentItemDriverMetricQuantities,
        assessmentItemsQuestions: storeAssessmentItemQuestions,
    }, isLoading: _isLoading, errors: loadErrors } = useEditAssessmentViewModel(id);
    const { data: { questionTags, driverMetrics, questions: allQuestions }, isLoading: isLoadingSupportingData, errors: loadSupportingDataErrors } = useEditAssessmentSupportingData();
    const isLoading = _isLoading || isLoadingSupportingData;
    const { model, change, changes } = useChanges(storeModel, isCreate ? { ...assessmentDefaultValues(), ...(onCreateDefaultValues ? onCreateDefaultValues() : {}) } : undefined);
    const [save, { errors: saveErrors }] = useSaveAssessmentCallback();

    const history = useHistory();

    // This base component serves both Assessments and Learning Modules which are stored in the same models and share most of their
    // functionality.  It is sometimes required that we know which we are working with, even when model is still not loaded.
    // To make that easy we require a defaultAssessmentType to be passed in and use that and model to calculate an
    // activeAssessmentType which can be used conistantly to show the right customisations based on assessment type.
    const activeAssessmentType: AssessmentType = useMemo(() => model?.assessmentType as AssessmentType ?? defaultAssessmentType, [model, defaultAssessmentType]);


    // Feedbacks.
    const feedbacksManager = useChangesArray<AssessmentFeedback, string>(storeFeedbacks, item => item.id);
    const [saveAssessmentFeedback] = useSaveAssessmentFeedbackCallback();
    const [removeAssessmentFeedback] = useDeleteAssessmentFeedbackCallback();

    // QuestionTagLinks.
    const questionTagLinksManager = useChangesArray<QuestionTagLink, string>(storeQuestionTagLinks, item => item.id);
    const [saveQuestionTagLink] = useSaveQuestionTagLinkCallback();
    const [removeQuestionTagLink] = useDeleteQuestionTagLinkCallback();

    // DriverMetricAdjustments.
    const driverMetricAdjustmentsManager = useChangesArray<DriverMetricAdjustment, string>(storeDriverMetricAdjustments, item => item.id);
    const [saveAssessmentDriverMetricAdjustment] = useSaveDriverMetricAdjustmentCallback();
    const [removeAssessmentDriverMetricAdjustment] = useDeleteDriverMetricAdjustmentCallback();

    // DriverMetricRecommendations.
    const driverMetricRecommendationsManager = useChangesArray<DriverMetricRecommendation, string>(storeDriverMetricRecommendations, item => item.id);
    const [saveAssessmentDriverMetricRecommendation] = useSaveDriverMetricRecommendationCallback();
    const [removeAssessmentDriverMetricRecommendation] = useDeleteDriverMetricRecommendationCallback();

    // AssessmentItems.
    const assessmentItemsManager = useChangesArray<AssessmentItem, string>(storeAssessmentItems, item => item.id);
    const [saveAssessmentItem] = useSaveAssessmentItemCallback();
    const [removeAssessmentItem] = useDeleteAssessmentItemCallback();

    // assessmentItemDriverMetricQuantities.
    const assessmentItemDriverMetricQuantitiesManager = useChangesArray<AssessmentItemDriverMetricQuantity, string>(storeAssessmentItemDriverMetricQuantities, item => item.id);
    const [saveAssessmentItemDriverMetricQuantity] = useSaveAssessmentItemDriverMetricQuantityCallback();
    const [removeAssessmentItemDriverMetricQuantity] = useDeleteAssessmentItemDriverMetricQuantityCallback();


    // AssessmentItemQuestions.
    const assessmentItemQuestionsManager = useChangesArray<AssessmentItemQuestion, string>(storeAssessmentItemQuestions, item => item.id);
    const [saveAssessmentItemQuestion] = useSaveAssessmentItemQuestionCallback();
    const [removeAssessmentItemQuestion] = useDeleteAssessmentItemQuestionCallback();

    // Blobs for child objects need to be managed as a dictionary of id to blobs.
    const [childBlobs, setChildBlobs, { upload: uploadChildBlob, uploadErrors: uploadChildBlobErrors }] = useBlobReferenceStateArray();
    useEffect(() => {
        let ret: Array<BlobReference | null> = [];

        if (storeFeedbacks) {
            for (const feedback of storeFeedbacks) {
                ret.push(feedback?.videoBlobReference);
                ret.push(feedback?.imageBlobReference);
            }
        }

        setChildBlobs(ret.filter(item => !!item) as Array<BlobReference>);
    }, [setChildBlobs, storeFeedbacks]);

    // Validation
    //
    const [validateAssessmentFeedback, assessmentFeedbackValidationErrors] = useValidatorArrayCallback<AssessmentFeedback>((myModel, validation, fieldsToCheck) => {
        const rules = {
            // No rules required.
        };

        validation.checkRules(rules, fieldsToCheck);
    }, []);

    const [validateDriverMetricAdjustment, driverMetricAdjustmentValidationErrors] = useValidatorArrayCallback<DriverMetricAdjustment>((myModel, validation, fieldsToCheck) => {
        const rules = {
            driverMetricId: () => Guid.isEmpty(myModel.driverMetricId) ? t('editAssessmentBase.validateDriverMetricAdjustment.driveMetricRequired', 'Metric is required') : '',
            adjustmentValue: () => myModel.adjustmentValue === 0 ? t('editAssessmentBase.validateDriverMetricAdjustment.adjustmentValueRequired', 'Adjustment cannot be zero') : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, []);

    const [validateDriverMetricRecommendation, driverMetricRecommendationValidationErrors] = useValidatorArrayCallback<DriverMetricRecommendation>((myModel, validation, fieldsToCheck) => {
        const rules = {
            driverMetricId: () => Guid.isEmpty(myModel.driverMetricId) ? t('editAssessmentBase.validateDriverMetricRecommendation.driveMetricRequired', 'Metric is required') : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, []);

    const [validateAssessmentItem, assessmentItemValidationErrors] = useValidatorArrayCallback<AssessmentItem>((myModel, validation, fieldsToCheck) => {
        const rules = {
            //add_field_to_validate_here_if_needed: () => Guid.isEmpty(myModel.driverMetricId) ? t('editAssessmentBase.validateDriverMetricRecommendation.driveMetricRequired', 'Metric is required') : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, []);

    const [validateAssessmentItemDriverMetricQuantity, assessmentItemDriverMetricQuantityErrors] = useValidatorArrayCallback<AssessmentItemDriverMetricQuantity>((myModel, validation, fieldsToCheck) => {
        const rules = {
            quantity: () => myModel.quantity == null || Number.isNaN(myModel.quantity) ? t('editAssessmentBase.AssessmentItemDriverMetricDisplayQuantityRuleComponent.QuantityRequired', 'Quantity is required') : '',
        }
        validation.checkRules(rules, fieldsToCheck);
    }, []);

    // Main model validation.
    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            name: () => !model?.name ? t('editAssessmentBase.nameRequired', 'Name is required') : '',
            assessmentType: () => !model?.assessmentType ? t('editAssessmentBase.assessmentType.required', 'Type of assessment is required') : '',
            feedbackStyle: () => !model?.feedbackStyle ? t('editAssessmentBase.feedbackType.required', 'Style of feedback is required'): '',

            // Validate related objects
            feedback: () => feedbacksManager.model.filter(it => !validateAssessmentFeedback(it)).length ? t('editAssessmentBase.feedbacksInvalid', 'One or more of the feedbacks are invalid') : '',
            driverMetricRecommendations: () => driverMetricRecommendationsManager.model.filter(it => !validateDriverMetricRecommendation(it)).length ? t('editAssessmentBase.driverMetricRecommendationsInvalid', 'One or more of the drive metric recommendation rules are invalid') : '',
            driverMetricAdjustments: () => driverMetricAdjustmentsManager.model.filter(it => !validateDriverMetricAdjustment(it)).length ? t('editAssessmentBase.driverMetricAdjustmentsInvalid', 'One or more of the drive metric recommendation rules are invalid') : '',
            assessmentItems: () => assessmentItemsManager.model.filter(it => !validateAssessmentItem(it)).length ? t('editAssessmentBase.assessmentItems.invalid', 'One or more of the assessment items are invalid') : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, [
        model,
        feedbacksManager, validateAssessmentFeedback,
        driverMetricRecommendationsManager, validateDriverMetricRecommendation,
        driverMetricAdjustmentsManager, validateDriverMetricAdjustment,
        assessmentItemsManager, validateAssessmentItem,
    ]);

    // Saving.
    const [saveForm, { isExecuting: isSaving, errors: saveFormErrors }] = useAsyncCallback(async () => {
        if (!validate()) {
            return;
        }

        // Save the main model.
        change({ creationDate: moment().toISOString() })
        await save(model.id, changes, !!isCreate);

        // Save the feedbacks.
        for (const item of feedbacksManager.added) { await saveAssessmentFeedback(item.id, feedbacksManager.changesFor(item.id), true); }
        for (const item of feedbacksManager.updated) { await saveAssessmentFeedback(item.id, feedbacksManager.changesFor(item.id), false); }
        for (const item of feedbacksManager.removed) { await removeAssessmentFeedback(item.id); }
        feedbacksManager.markAsSaved();

        // Save the assessment tag links.
        for (const item of questionTagLinksManager.added) { await saveQuestionTagLink(item.id, questionTagLinksManager.changesFor(item.id), true); }
        for (const item of questionTagLinksManager.updated) { await saveQuestionTagLink(item.id, questionTagLinksManager.changesFor(item.id), false); }
        for (const item of questionTagLinksManager.removed) { await removeQuestionTagLink(item.id); }
        questionTagLinksManager.markAsSaved();

        // Save the driver metric recommendations
        for (const item of driverMetricRecommendationsManager.added) { await saveAssessmentDriverMetricRecommendation(item.id, driverMetricRecommendationsManager.changesFor(item.id), true); }
        for (const item of driverMetricRecommendationsManager.updated) { await saveAssessmentDriverMetricRecommendation(item.id, driverMetricRecommendationsManager.changesFor(item.id), false); }
        for (const item of driverMetricRecommendationsManager.removed) { await removeAssessmentDriverMetricRecommendation(item.id); }
        driverMetricRecommendationsManager.markAsSaved();

        // Save the driver metric adjustment.
        for (const item of driverMetricAdjustmentsManager.added) { await saveAssessmentDriverMetricAdjustment(item.id, driverMetricAdjustmentsManager.changesFor(item.id), true); }
        for (const item of driverMetricAdjustmentsManager.updated) { await saveAssessmentDriverMetricAdjustment(item.id, driverMetricAdjustmentsManager.changesFor(item.id), false); }
        for (const item of driverMetricAdjustmentsManager.removed) { await removeAssessmentDriverMetricAdjustment(item.id); }
        driverMetricAdjustmentsManager.markAsSaved();

        // Save the assessment items
        for (const item of assessmentItemsManager.added) { await saveAssessmentItem(item.id, assessmentItemsManager.changesFor(item.id), true); }
        for (const item of assessmentItemsManager.updated) { await saveAssessmentItem(item.id, assessmentItemsManager.changesFor(item.id), false); }
        for (const item of assessmentItemsManager.removed) { await removeAssessmentItem(item.id); }
        assessmentItemsManager.markAsSaved();

        // Save the Assessment item driver metric quantities
        for (const item of assessmentItemDriverMetricQuantitiesManager.added) { await saveAssessmentItemDriverMetricQuantity(item.id, assessmentItemDriverMetricQuantitiesManager.changesFor(item.id), true); }
        for (const item of assessmentItemDriverMetricQuantitiesManager.updated) { await saveAssessmentItemDriverMetricQuantity(item.id, assessmentItemDriverMetricQuantitiesManager.changesFor(item.id), false); }
        for (const item of assessmentItemDriverMetricQuantitiesManager.removed) { await removeAssessmentItemDriverMetricQuantity(item.id); }

        // Save the assessment item questions
        for (const item of assessmentItemQuestionsManager.added) { await saveAssessmentItemQuestion(item.id, assessmentItemQuestionsManager.changesFor(item.id), true); }
        for (const item of assessmentItemQuestionsManager.updated) { await saveAssessmentItemQuestion(item.id, assessmentItemQuestionsManager.changesFor(item.id), false); }
        for (const item of assessmentItemQuestionsManager.removed) { await removeAssessmentItemQuestion(item.id); }
        assessmentItemQuestionsManager.markAsSaved();

        history.goBack();
    }, [
        validate, save, model, changes, isCreate, history,
        feedbacksManager, saveAssessmentFeedback, removeAssessmentFeedback,
        questionTagLinksManager, saveQuestionTagLink, removeQuestionTagLink,
        driverMetricRecommendationsManager, saveAssessmentDriverMetricRecommendation, removeAssessmentDriverMetricRecommendation,
        driverMetricAdjustmentsManager, saveAssessmentDriverMetricAdjustment, removeAssessmentDriverMetricAdjustment,
        assessmentItemsManager, saveAssessmentItem, removeAssessmentItem,
        assessmentItemQuestionsManager, saveAssessmentItemQuestion, removeAssessmentItemQuestion,
    ]);

    // Tab tracking and UI matching to specific assessment types.
    const [activeTab, setActiveTab] = useState<'main' | 'introduction' | 'items' | 'feedback' | 'driverMetrics' | 'tags'>('main');

    // UI
    //
    return (
        <Background>
            <Banner>
                <Row>
                    <Col>
                        <Row>
                            <Col xs={12} md="auto">
                                <h1>
                                    {
                                        activeAssessmentType === AssessmentType.TrainingModule ? (
                                            isCreate ? (
                                                <>{t('editAssessmentBase.createHeading.trainingModule', 'Add training module')}</>
                                            ) : (
                                                    <>{t('editAssessmentBase.editHeading.trainingModule', 'Edit training module')}</>
                                            )
                                        ) : (
                                            isCreate ? (
                                                <>{t('editAssessmentBase.createHeading.assessment', 'Add assessment')}</>
                                            ) : (
                                                <>{t('editAssessmentBase.editHeading.assessment', 'Edit assessment')}</>
                                            )
                                        )
                                    }
                                </h1>
                            </Col>
                            <Col>
                                <StickyToolbar>
                                    <PillsNavBar>
                                        <NavItem>
                                            <NavLink active={activeTab === 'main'} onClick={() => setActiveTab('main')}>
                                                {

                                                    activeAssessmentType === AssessmentType.TrainingModule ? <><FontAwesomeIcon icon="graduation-cap" className="nav-icon" /><> </>{t('editAssessmentBase.tabs.trainingModule', 'Training')}</>
                                                        : <><FontAwesomeIcon icon="clipboard-check" className="nav-icon" /><> </>{t('editAssessmentBase.tabs.assessment', 'Assessment')}</>

                                                }

                                                <ConditionalFragment showIf={hasAnyValidationErrors([validationErrors['assessmentType'], validationErrors['tileText'],])}>
                                                    <> </><FontAwesomeIcon icon="exclamation-triangle" color="danger" />
                                                </ConditionalFragment>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink active={activeTab === 'introduction'} onClick={() => setActiveTab('introduction')}>
                                                <FontAwesomeIcon icon="chalkboard-teacher" className="nav-icon" />
                                                <> </>
                                                {t('editAssessmentBasetabs.tabs.introduction', 'Introduction')}

                                                <ConditionalFragment showIf={hasAnyValidationErrors([validationErrors['introduction'],])}>
                                                    <> </><FontAwesomeIcon icon="exclamation-triangle" color="danger" />
                                                </ConditionalFragment>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink active={activeTab === 'items'} onClick={() => setActiveTab('items')}>
                                                <FontAwesomeIcon
                                                    icon={activeAssessmentType === AssessmentType.TrainingModule ? 'chalkboard-teacher' : 'car-crash'}
                                                    className="nav-icon"
                                                />
                                                <> </>
                                                {t('editAssessmentBasetabs.tabs.activities', 'Questions and activities')}

                                                <ConditionalFragment showIf={hasAnyValidationErrors([validationErrors['assessmentItems']])}>
                                                    <> </><FontAwesomeIcon icon="exclamation-triangle" color="danger" />
                                                </ConditionalFragment>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink active={activeTab === 'feedback'} onClick={() => setActiveTab('feedback')}>
                                                <FontAwesomeIcon icon="comment-dots" className="nav-icon" />
                                                <> </>
                                                {t('editAssessmentBase.tabs.feedback', 'Feedback')}
                                                <ConditionalFragment showIf={hasAnyValidationErrors([validationErrors['feedback'], validationErrors['feedbackStyle'],])}>
                                                    <> </><FontAwesomeIcon icon="exclamation-triangle" color="danger" />
                                                </ConditionalFragment>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink active={activeTab === 'driverMetrics'} onClick={() => setActiveTab('driverMetrics')}>
                                                <FontAwesomeIcon icon="user-tag" className="nav-icon" />
                                                {t('editAssessmentBase.tabs.driverMetrics', ' Recommend')}
                                                <ConditionalFragment showIf={hasAnyValidationErrors([validationErrors['driverMetricRecommendations'], validationErrors['driverMetricAdjustments'], validationErrors['isSmartIndividuality'], ])}>
                                                    <> </><FontAwesomeIcon icon="exclamation-triangle" color="danger" />
                                                </ConditionalFragment>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink active={activeTab === 'tags'} onClick={() => setActiveTab('tags')}>
                                                <FontAwesomeIcon icon="tags" className="nav-icon" />
                                                {t('editAssessmentBase.tabs.tags', ' Tags')}
                                            </NavLink>
                                        </NavItem>
                                    </PillsNavBar>
                                </StickyToolbar>
                            </Col>
                        </Row>
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
            </Banner>

            <MainContainer>
                <AlertOnErrors errors={[
                    loadErrors, saveFormErrors, saveErrors, loadSupportingDataErrors,
                    uploadChildBlobErrors,
                ]} />

                <Form onSubmit={e => { e.preventDefault(); saveForm(); }}>
                    <FormGroup>
                        <Label htmlFor="name">{t('editAssessmentBase.name', 'Name')}</Label>
                        <ValidatedInput name="name" type="text" value={model.name ?? ''} onChange={e => change({ name: e.currentTarget.value })} onBlur={e => validate('name')} validationErrors={validationErrors['name']} />
                    </FormGroup>

                    <>
                        {/* Tab contents */}
                        <ConditionalFragment showIf={activeTab === 'main'}>
                            <FormGroup>
                                <Label htmlFor="text">{t('editAssessmentBase.tileText.label', 'Tile description')}</Label>

                                <HtmlEditor value={model?.tileText ?? ''} onChange={html => change({ tileText: html })} />

                                <FormText>
                                    {
                                        activeAssessmentType === AssessmentType.TrainingModule ? t('editAssessmentBase.tileText.formText.trainingModule', 'This will show on the tile on the user\'s Home screen before they launch the learning.')
                                            : t('editAssessmentBase.tileText.formText.assessment', 'This will show on the tile on the user\'s Home screen before they launch the assessment.')
                                    }
                                </FormText>
                            </FormGroup>

                            <FormGroup>
                                <Label htmlFor="subscriptionName">{t('editSubscription.riskRules.label', 'Risk calculation rules')}</Label>
                                <Row noGutters>
                                    <Col xs="auto">
                                        <span className="text-danger">
                                            {overallRiskService.getRiskName(OverallRiskCategory.High)}
                                        </span>
                                        <> </>
                                        <span>
                                            {t('editSubscription.lessThanSign', '<')}
                                        </span>
                                    </Col>
                                    <Col xs="auto" style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                                        <ValidatedInput name="highRiskBelow" type="number"
                                            min={0} max={100}
                                            value={model.highRiskBelow ?? 0}
                                            onChange={e => change({ highRiskBelow: e.currentTarget.valueAsNumber })}
                                            onBlur={e => validate('highRiskBelow')}
                                            validationErrors={validationErrors['highRiskBelow']}
                                        />
                                    </Col>
                                    <Col xs="auto">
                                        <span>
                                            {t('editSubscription.lessThanOrEqualsSign', '<=')}
                                        </span>
                                        <> </>
                                        <span className="text-warning">
                                            {overallRiskService.getRiskName(OverallRiskCategory.Medium)}
                                        </span>
                                        <> </>
                                        <span>
                                            {t('editSubscription.lessThanSign', '<')}
                                        </span>
                                    </Col>
                                    <Col xs="auto" style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                                        <ValidatedInput name="mediumRiskBelow" type="number"
                                            min={0} max={100}
                                            value={model.mediumRiskBelow ?? 0}
                                            onChange={e => change({ mediumRiskBelow: e.currentTarget.valueAsNumber })}
                                            onBlur={e => validate('mediumRiskBelow')}
                                            validationErrors={validationErrors['mediumRiskBelow']}
                                        />

                                    </Col>
                                    <Col xs="auto">
                                        <span>
                                            {t('editSubscription.lessThanOrEqualsSign', '<=')}
                                        </span>
                                        <> </>
                                        {overallRiskService.getRiskName(OverallRiskCategory.Low)}
                                    </Col>
                                    <Col></Col>
                                </Row>
                                <FormText>
                                    {t('editSubscription.riskRules.formText', 'Default rules for new assessments and the Esitu Drive dashboard are: High risk < {{highRiskBelow}} <= Medium risk < {{mediumRiskBelow}} <= Low risk.', defaultRiskRules)}
                                </FormText>
                            </FormGroup>
                        </ConditionalFragment>

                        <ConditionalFragment showIf={activeTab === 'introduction'}>
                            <FormGroup>
                                <Label htmlFor="text">{t('editAssessmentBase.text.label', 'Introduction')}</Label>

                                <HtmlEditor value={model?.text} onChange={html => change({ text: html })} />
                                <FormText>
                                    {
                                        activeAssessmentType === AssessmentType.TrainingModule ? t('editAssessmentBase.text.formText.trainingModule', 'When the user launches the training this is the first page they will see to introduce them to the training.')
                                            : t('editAssessmentBase.text.formText.assessment', 'When the user launches the assessment this is the first page they will see to introduce them to the assessment.')
                                    }
                                </FormText>
                            </FormGroup>
                        </ConditionalFragment>

                        <ConditionalFragment showIf={activeTab === 'items'}>
                            <AssessmentItemsTab
                                model={model}
                                change={change}
                                assessmentItemsManager={assessmentItemsManager}
                                assessmentItemDriverMetricQuantitiesManager={assessmentItemDriverMetricQuantitiesManager}
                                assessmentItemQuestionsManager={assessmentItemQuestionsManager}
                                allQuestions={allQuestions}
                                validateAssessmentItem={validateAssessmentItem}
                                assessmentItemValidationErrors={assessmentItemValidationErrors}
                                validateAssessmentItemDriverMetricQuantity={validateAssessmentItemDriverMetricQuantity}
                                assessmentItemDriverMetricQuantityErrors={assessmentItemDriverMetricQuantityErrors}
                                driverMetrics={driverMetrics}
                                />
                        </ConditionalFragment>

                        <ConditionalFragment showIf={activeTab === 'feedback'}>
                            <FeedbackTab
                                model={model}
                                change={change}
                                activeAssessmentType={activeAssessmentType}
                                validate={validate}
                                validationErrors={validationErrors}
                                feedbacksManager={feedbacksManager}
                                childBlobs={childBlobs} uploadChildBlob={uploadChildBlob}
                                validateAssessmentFeedback={validateAssessmentFeedback}
                                assessmentFeedbackValidationErrors={assessmentFeedbackValidationErrors}
                            />
                        </ConditionalFragment>

                        <ConditionalFragment showIf={activeTab === 'driverMetrics'}>
                            <DriverMetricsTab
                                model={model}
                                change={change}
                                activeAssessmentType={activeAssessmentType}
                                driverMetricAdjustmentsManager={driverMetricAdjustmentsManager}
                                driverMetricRecommendationsManager={driverMetricRecommendationsManager}
                                driverMetrics={driverMetrics}
                                validateDriverMetricRecommendation={validateDriverMetricRecommendation}
                                driverMetricRecommendationValidationErrors={driverMetricRecommendationValidationErrors}
                                validateDriverMetricAdjustment={validateDriverMetricAdjustment}
                                driverMetricAdjustmentValidationErrors={driverMetricAdjustmentValidationErrors}
                            />
                        </ConditionalFragment>

                        <ConditionalFragment showIf={activeTab === 'tags'}>
                            <QuestionTagsTab
                                model={model}
                                questionTags={questionTags}
                                linksManager={questionTagLinksManager}
                            />
                        </ConditionalFragment>
                    </>


                    <FormButtons>
                        <ConditionalFragment showIf={!isLoading}>
                            <ButtonAsync color="primary" isExecuting={isSaving}
                                executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}>
                                <FontAwesomeIcon icon="save" />
                                <> {t('common.save', 'Save')}</>
                            </ButtonAsync>
                        </ConditionalFragment>
                        <Button type="button" color="primary" outline onClick={e => history.goBack()}>
                            {t('common.cancel', 'Cancel')}
                        </Button>
                    </FormButtons>
                </Form>
            </MainContainer>
        </Background>
    );
};
