import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { SdkWebhookCreateInput } from '../generated/globalTypes';
import { createSdkWebhookMutation, createSdkWebhookMutationVariables } from '../generated/createSdkWebhookMutation';
import { sdkWebhookFieldsFragment } from '../models/SdkWebhook';

/**
 * Get a callback to create a SdkWebhook in the store.
 */
export function useCreateSdkWebhookCallback(): [(model: SdkWebhookCreateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<createSdkWebhookMutation, createSdkWebhookMutationVariables>(
        gql`
            mutation createSdkWebhookMutation ($model: SdkWebhookCreateInput!) {
                createSdkWebhook(model: $model) {
                    ...sdkWebhookFields
                }
            }

            ${sdkWebhookFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (model: SdkWebhookCreateInput) => {
        const result = await mutationAction({ variables: { model: model } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
