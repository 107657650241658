import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import "./questionTimer.scss";

export interface QuestionTimerProps {
    secondsRemaining: number,
}

/**
 * Component that shows a countdown from fromSeconds and calls countdownComplete when the countdown finishes.
 * @param props
 */
export const QuestionTimer = (props: QuestionTimerProps) => {
    const {
        secondsRemaining,
    } = props;

    const { t } = useTranslation();

    return (
        <div className={`question-timer ${secondsRemaining < 10? 'question-timer-danger': ''}`}>
            {t('questionTimer.timeReamining', 'Time remaining:')}
            <> </>
            <div className="question-timer-seconds">
                <FontAwesomeIcon icon="stopwatch" />
                <> </>
                {secondsRemaining}
            </div>
        </div>
        );
};