import * as React from 'react';
import { Button, Row, Col, ButtonGroup, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, CardTitle  } from 'reactstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { useDriverMetrics } from '../../api/main/driverMetrics/useDriverMetrics';
import { Waypoint } from 'react-waypoint';
import { useReplaceSearchParamsEffect, useSearchParams } from '../../shared/useURLSearchParams';
import { useTranslation } from 'react-i18next';
import { SearchInput } from '../shared/searchInput/SearchInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContainer } from '../shared/MainContainer';
import { NoResultsFound } from '../shared/NoResultsFound';
import { StickyToolbar } from '../shared/StickyToolbar';
import { useHistory } from 'react-router';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Banner } from '../shared/Banner';
import { useToggleStateArray } from 'use-toggle-state';
import { Background } from '../shared/background/Background';
import { CardsOrTable } from '../shared/cardsOrTable/CardsOrTable';
import { usePreferredListViewMode } from '../../globalState/preferredListViewMode/usePreferredListViewMode';



/**
 * List of DriverMetrics.
 */
export const DriverMetricsList = () => {
    const { t } = useTranslation();
    const { search: searchParam } = useSearchParams();
    const [search, setSearch] = React.useState<string>(searchParam ?? '');
    const { data: { items: allItems }, isLoading, errors: loadingErrors, fetchMore, hasMore } = useDriverMetrics({ pageSize: undefined });
    const [isMenuOpen, toggleMenuOpen] = useToggleStateArray();
    const history = useHistory();

    // Filter by the driverMetric's search client side so it can work when offline as well as online.
    const items = React.useMemo(() => {
        if (!allItems || !search) {
            return allItems;
        }

        let lowerSearch = search.toLocaleLowerCase();

        // Filter the items being displayed.
        return allItems.filter(item =>
            item.name.toLocaleLowerCase().indexOf(lowerSearch) >= 0
        );
    }, [allItems, search]);

    useReplaceSearchParamsEffect({ search: search });

    const [viewMode, setViewMode] = usePreferredListViewMode();


    return (
        <Background>
            <Banner fluid>
                <StickyToolbar>
                    <Row>
                        <Col>
                            <h1>{t('administratorsDriverMetricList.heading', 'Safety metrics')}</h1>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>

                        <Col>
                            <SearchInput value={search} onChange={e => setSearch(e.currentTarget.value)} />
                        </Col>
                        <Col xs="auto">
                            <LinkContainer to="/administration/safety-metrics/add">
                                <Button color="primary">
                                    <FontAwesomeIcon icon="plus" /><> {t('administratorsDriverMetricList.add', 'Add')}</>
                                </Button>
                            </LinkContainer>
                        </Col>
                        <Col xs={12} md="auto">
                            <ButtonGroup>
                                <Button color="secondary" outline={viewMode !== 'cards'} onClick={() => setViewMode('cards')}>
                                    <FontAwesomeIcon icon="th-large" />
                                    <span className="sr-only">{t('common.cards', 'Cards')}</span>
                                </Button>
                                <Button color="secondary" outline={viewMode !== 'table'} onClick={() => setViewMode('table')}>
                                    <FontAwesomeIcon icon="th-list" />
                                    <span className="sr-only">{t('common.list', 'List')}</span>
                                </Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer fluid>
                <AlertOnErrors errors={loadingErrors} />
                <CardsOrTable
                    viewMode={viewMode}
                    items={items}
                    onItemClick={item => history.push(`/administration/safety-metrics/edit/${item.id}`)}
                        tableHeadings={[
                            t('driverMetricBase.name', 'Name'),
                        ]}
                            columns={[
                                (item, view) => view === 'cards' ? (<CardTitle tag="h5">{item.name}</CardTitle>) : item.name,
                            ]}

                        buttons={(item) => (

                                            <ButtonGroup>
                                                <LinkContainer to={`/administration/safety-metrics/edit/${item.id}`}>
                                                    <Button color="primary" outline>
                                                        <FontAwesomeIcon icon="edit" />
                                                        <> {t('common.edit', 'Edit')}</>
                                                    </Button>
                                                </LinkContainer>
                                                <ButtonDropdown isOpen={isMenuOpen(item.id)} toggle={() => toggleMenuOpen(item.id)}>
                                                    <DropdownToggle color="primary" outline caret>
                                                        <span className="sr-only">{t('common.menuDropdown', 'More')}</span>
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <LinkContainer to={`/administration/safety-metrics/delete/${item.id}`}>
                                                            <DropdownItem className="text-danger">
                                                                <FontAwesomeIcon icon="trash" />
                                                                <> {t('common.delete', 'Delete')}</>
                                                            </DropdownItem>
                                                        </LinkContainer>
                                                    </DropdownMenu>
                                                </ButtonDropdown>
                                            </ButtonGroup>
                        )}
                    />
                        <ConditionalFragment showIf={isLoading && !items?.length}>
                            <LoadingIndicator fullWidth />
                        </ConditionalFragment>
                        <ConditionalFragment showIf={!isLoading && !items?.length}>

                                <NoResultsFound search={search} />

                        </ConditionalFragment>
                        <ConditionalFragment showIf={!isLoading && hasMore()}>

                                <Waypoint key={items?.length ?? 0} onEnter={fetchMore} />
                                <LoadingIndicator fullWidth />

                        </ConditionalFragment>

            </MainContainer>
        </Background>
    );
};
