import { Button, Form, Label, FormGroup, Spinner, Input, Row, Col } from 'reactstrap';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContainer } from '../shared/MainContainer';
import { useParams, useHistory } from 'react-router';
import { FormButtons } from '../shared/FormButtons';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { useAsyncCallback } from 'react-use-async-callback';
import { useAssessment } from '../../api/main/assessments/useAssessment';
import { useDeleteAssessmentCallback } from '../../api/main/assessments/useDeleteAssessmentCallback';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Banner } from '../shared/Banner';
import { Background } from '../shared/background/Background';
import { AssessmentType } from '../../api/main/models/codeOnly/AssessmentType';
import { useMemo } from 'react';

export interface DeleteAssessmentBaseProps {
    defaultAssessmentType: AssessmentType,
}

/**
 * Delete a record based on the Assessment model.
 */
export const DeleteAssessmentBase = (props: DeleteAssessmentBaseProps) => {
    const { defaultAssessmentType } = props;

    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();
    const { data: { model }, isLoading, errors: loadErrors } = useAssessment(id);
    const [remove, { errors: removeErrors }] = useDeleteAssessmentCallback();
    const history = useHistory();

    // This base component serves both Assessments and Learning Modules which are stored in the same models and share most of their
    // functionality.  It is sometimes required that we know which we are working with, even when model is still not loaded.
    // To make that easy we require a defaultAssessmentType to be passed in and use that and model to calculate an
    // activeAssessmentType which can be used conistantly to show the right customisations based on assessment type.
    const activeAssessmentType: AssessmentType = useMemo(() => model?.assessmentType as AssessmentType ?? defaultAssessmentType, [model, defaultAssessmentType]);

    const [onRemoveFormSubmit, { isExecuting: isRemoving, errors: removeFormErrors }] = useAsyncCallback(async () => {
        if (!model) {
            return;
        }

        await remove(model.id);

        history.goBack();
    }, [model, history]);

    return (
        <Background>
            <Banner>
                <Row>
                    <Col>
                        <h1>
                            {
                                activeAssessmentType === AssessmentType.TrainingModule ? t('deleteAssessmentBase.heading.learning', 'Are you sure you want to delete this training module?')
                                    : t('deleteAssessmentBase.heading.assessment', 'Are you sure you want to delete this assessment?')
                            }
                        </h1>
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
            </Banner>

            <MainContainer>
                <AlertOnErrors errors={[loadErrors, removeFormErrors, removeErrors]} />

                <Form onSubmit={e => { e.preventDefault(); onRemoveFormSubmit(); }}>
                    <FormGroup>
                        <Label htmlFor="name">{t('deleteAssessmentBase.name', 'Name')}</Label>
                        <Input name="name" type="text" readOnly plaintext value={model?.name ?? ''} />
                    </FormGroup>
                    
                    <FormButtons>
                        <ConditionalFragment showIf={!isLoading}>
                            <ButtonAsync color="danger" isExecuting={isRemoving}
                                executingChildren={<><Spinner size="sm" /> {t('common.deleting', 'Deleting...')}</>}>
                                <FontAwesomeIcon icon="trash" />
                                <> {t('common.delete', 'Delete')}</>
                            </ButtonAsync>
                        </ConditionalFragment>
                        <Button type="button" color="primary" outline onClick={e => history.goBack()}>
                            {t('common.cancel', 'Cancel')}
                        </Button>
                    </FormButtons>
                </Form>
            </MainContainer>
        </Background>
    );
};
