import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Button, FormGroup, Label } from "reactstrap";
import { BlobReference } from "../../../../api/main/models/BlobReference";
import { Question } from "../../../../api/main/models/Question";
import { ModelArrayChanges } from "../../../../shared/useChanges";
import { QuestionFeedback, questionFeedbackDefaultValues } from "../../../../api/main/models/QuestionFeedback";
import { Feedback } from "./Feedback";
import { ValidationErrors } from "pojo-validator";

export interface FeedbackTabProps {
    model: Question | undefined,

    feedbacksManager: ModelArrayChanges<QuestionFeedback, string>,

    childBlobs: Array<BlobReference>,
    uploadChildBlob: (files: FileList | null) => Promise<BlobReference | null>,

    validateQuestionFeedback: (model: QuestionFeedback) => boolean,
    questionFeedbackValidationErrors: (id: string) => ValidationErrors,
}

/**
 * Tab for maintaining the feedback based on the question scores.
 * @param props
 */
export const FeedbackTab = (props: FeedbackTabProps) => {
    const {
        model,

        feedbacksManager,
        childBlobs,
        uploadChildBlob,

        validateQuestionFeedback,
        questionFeedbackValidationErrors,
    } = props;

    const { t } = useTranslation();

    // Order the feedback so they are shown based on their minimum score.
    const orderedFeedbacks = useMemo(() => {
        let ret = [...feedbacksManager.model];

        // Sort by min score, then max score.
        ret.sort((a, b) => (a.minScore === b.minScore ? (a.maxScore === b.maxScore? 0: a.maxScore < b.maxScore? -1: 1) : a.minScore < b.minScore ? -1 : 1));

        return ret;
    }, [feedbacksManager.model]);
    

    // Adding of feedback.
    const addFeedback = useCallback(() => {
        feedbacksManager.addFor({
            ...questionFeedbackDefaultValues(),

            questionId: model?.id,
        });
    }, [feedbacksManager, model?.id]);

    return (
        <>
            <FormGroup>
                <Label htmlFor="feedbacks">{t('feedbackTab.questions.feedbacks', 'Feedback')}</Label>

                <div>
                    {
                        orderedFeedbacks.map(item => (
                            <Feedback key={item.id}
                                model={item}
                                change={changes => feedbacksManager.changeFor(item.id, changes)}
                                remove={() => feedbacksManager.removeFor(item.id)}

                                isOpenInitially={!!feedbacksManager.added.find(it => it.id === item.id)}

                                videoBlob={childBlobs.find(it => it.id === item.videoBlobReferenceId)}
                                imageBlob={childBlobs.find(it => it.id === item.imageBlobReferenceId)}
                                uploadChildBlob={uploadChildBlob}

                                validate={() => validateQuestionFeedback(item)}
                                validationErrors={questionFeedbackValidationErrors(item.id)}
                                />
                            ))
                    }
                </div>

                <Button color="primary" outline onClick={() => addFeedback()}>
                    {t('feedbackTab.addFeedback', 'Add feedback range')}
                </Button>
            </FormGroup>
        </>
        );
};