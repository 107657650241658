import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { AssessmentFeedbackUpdateInput } from '../generated/globalTypes';
import { updateAssessmentFeedbackMutation, updateAssessmentFeedbackMutationVariables } from '../generated/updateAssessmentFeedbackMutation';
import { assessmentFeedbackFieldsFragment } from '../models/AssessmentFeedback';

/**
 * Get a callback to update a AssessmentFeedback in the store.
 */
export function useUpdateAssessmentFeedbackCallback(): [(id: string, model: AssessmentFeedbackUpdateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<updateAssessmentFeedbackMutation, updateAssessmentFeedbackMutationVariables>(
        gql`
            mutation updateAssessmentFeedbackMutation ($model: AssessmentFeedbackUpdateInput!) {
                updateAssessmentFeedback(model: $model) {
                    ...assessmentFeedbackFields
                }
            }

            ${assessmentFeedbackFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (id: string, model: AssessmentFeedbackUpdateInput) => {
        const result = await mutationAction({ variables: { model: { ...model, id: id } } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
