import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { AssessmentType } from "../../../api/main/models/codeOnly/AssessmentType";
import { OverallRiskCategory, overallRiskService } from "../../../services/OverallRiskService";
import { ChartData, ChartDataSeries } from "../chartDataUtilities/ChartData";
import { DashboardSummary } from "../utilities/generateDashboardSummary";

/**
 * Data for a chart that shows risk category counts.
 */
export function useAverageDriverMetricRiskChartData(groups: Array<{ id: string, name: string }>, getUserMetricSummary: (id: string, assessmentType: AssessmentType) => DashboardSummary, assessmentType: AssessmentType): Array<ChartDataSeries> {
    const { t } = useTranslation();

    const generateSeries = useCallback((seriesName: string, assessmentType: AssessmentType) => {
        // Generate chart data for each group.

        const summaries = groups.map(group => getUserMetricSummary(group.id, assessmentType));

        const data = [
            {
                text: overallRiskService.getRiskName(OverallRiskCategory.High),
                value: summaries.filter(item => item.averageUserDriverMetricScore.riskCategory === OverallRiskCategory.High).length,
            } as ChartData,
            {
                text: overallRiskService.getRiskName(OverallRiskCategory.Medium),
                value: summaries.filter(item => item.averageUserDriverMetricScore.riskCategory === OverallRiskCategory.Medium).length,
            } as ChartData,
            {
                text: overallRiskService.getRiskName(OverallRiskCategory.Low),
                value: summaries.filter(item => item.averageUserDriverMetricScore.riskCategory === OverallRiskCategory.Low).length,
            } as ChartData,
            {
                text: overallRiskService.getRiskName(OverallRiskCategory.NotAssessed),
                value: summaries.filter(item => item.averageUserDriverMetricScore.riskCategory === OverallRiskCategory.NotAssessed).length,
            } as ChartData,
        ];

        return {
            name: seriesName,
            data: data,
        } as ChartDataSeries;
    }, [groups, getUserMetricSummary]);

    const ret = useMemo(() => {
        return [
            generateSeries(
                assessmentType === AssessmentType.TrainingModule ? t('useAverageScoreChartData.trainingModules.seriesName', 'Training')
                    : t('useAverageScoreChartData.assessments.seriesName', 'Assessments'),
                assessmentType
            ),
        ];
    }, [generateSeries, t, assessmentType]);

    return ret;
}
