import { IdentityRoles } from "../../../configure/security/IdentityRoles";
import { useCurrentUserOrEmulatedSubscriptionId } from "../../../globalState/subscriptions/useCurrentUserOrEmulatedSubscriptionId";
import { AuthorizeContainer } from "../../../shared/authorizeRoute";
import { MyDashboard } from "../myDashboard/MyDashboard";
import { SubscriptionDashboard } from "../subscriptionDashboard/SubscriptionDashboard";

/**
 * Top level dashboard that will present either the user's own dashboard or a subscription's dashboard.
 */
export const Dashboard = () => {
    const subscriptionId = useCurrentUserOrEmulatedSubscriptionId();
    
    return (
        <>
            {/* Logic for admins is based on if we are emulating or not. */}
            <AuthorizeContainer requireRole={IdentityRoles.Administration}>
                {
                    subscriptionId ? (
                        <SubscriptionDashboard />
                    ) : (
                        <MyDashboard />
                    )
                }
            </AuthorizeContainer>

            {/* Logic for non-admins is just based on permissions. */}
            <AuthorizeContainer showIfNotAuthorized={true} requireRole={IdentityRoles.Administration}>
                {/* Show the right dashboard link */}
                <AuthorizeContainer requireRole={[IdentityRoles.DriverManagement, IdentityRoles.SubscriptionAdministration]}>
                    <SubscriptionDashboard />
                </AuthorizeContainer>
                <AuthorizeContainer showIfNotAuthorized={true} requireRole={[IdentityRoles.DriverManagement, IdentityRoles.SubscriptionAdministration]}>
                    <MyDashboard />
                </AuthorizeContainer>
            </AuthorizeContainer>
        </>
        );
};