import { AssessmentType } from "../../../api/main/models/codeOnly/AssessmentType";
import { CreateAssessmentBase, EditAssessmentBase } from "../edit/EditAssessmentBase";

/**
 * Create a Training Module assessment.
 */
export const CreateTrainingModule = () => (
    <CreateAssessmentBase
        defaultAssessmentType={AssessmentType.TrainingModule}
        onCreateDefaultValues={() => ({ assessmentType: AssessmentType.TrainingModule })}
    />
);

/**
 * Edit a Training Module assessment.
 */
export const EditTrainingModule = () => (<EditAssessmentBase defaultAssessmentType={AssessmentType.TrainingModule} />);
