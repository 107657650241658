import './searchInput.scss';
import * as React from 'react';
import { Input, InputGroup, InputGroupAddon, Button, InputProps } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

export interface SearchInputProps extends InputProps {
    onSearchButtonClick?: () => void,
    children?: React.ReactNode,
}

/**
 * Input that is hooked up for searching as the user types, by introducing an appropriate delay before raising the onSearch event.
 */
export const SearchInput = (props: SearchInputProps) => {
    const { type, placeholder, className, style, onSearchButtonClick, children, ...rest } = props;

    const { t } = useTranslation();

    return (
        <InputGroup className={`search-input ${className ?? ''}`} style={style}>
            <Input className="search-input-input" type={type ?? 'search'}
                placeholder={placeholder ?? t('searchInput.placeholder', 'Search')}
                onKeyPress={e => {
                    // When the user presses enter, trigger a click on the search button.
                    if (e.charCode === 13) {
                        if (onSearchButtonClick) {
                            onSearchButtonClick();
                        }
                    }
                }}
                {...rest} />
            <InputGroupAddon addonType="append">
                <Button className="search-input-button" onClick={onSearchButtonClick}>
                    <FontAwesomeIcon icon="search" />
                    <span className="sr-only">{t('searchInput.searchButton', 'Search')}</span>
                </Button>
            </InputGroupAddon>
            {children}
        </InputGroup>
    );
};