import * as React from 'react';
import { useURLSearchParams } from "./useURLSearchParams";
import { useHistory, useLocation } from "react-router";

/**
 * Replace the Search params in the browser using history.replace().
 * @param name name of the Query param to return the value of.
 */
export function useReplaceSearchParamsEffect<T extends {[key: string]: string}>(changes: T): void {
    const location = useLocation();
    const params = useURLSearchParams();
    const history = useHistory();

    const url = React.useMemo(() => {
        for (let key in changes) {

            // Make sure we treat undefined, null, and empty strings the same to avoid potentially infinate rerenders
            // if an empty string is passed in to changes.
            let changesValue = changes[key] ?? '';
            let paramsValeue = params.get(key) ?? '';

            if (changesValue === paramsValeue) {
                continue;
            }

            if (!changesValue) {
                params.delete(key);
            } else {
                params.set(key, changesValue);
            }
        }

        return `${location.pathname}?${params}${location.hash}`;
    }, [changes, location, params]);

    // If we have changed the url because of a parameter, apply it with history.replace().
    React.useEffect(() => {
        if (!url) {
            return;
        }

        history.replace(url);
    }, [url, history]);
}