import { useCallback } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Button, ButtonGroup, Col, Container, NavItem, NavLink, Row } from "reactstrap";
import { AssessmentType } from "../../../api/main/models/codeOnly/AssessmentType";
import { useAdministrationDashboardViewModel } from "../../../api/main/dashboard/viewModels/useAdministrationDashboardViewModel";
import { AlertOnErrors } from "../../../shared/alertOnErrors";
import { Background } from "../../shared/background/Background";
import { Banner } from "../../shared/Banner";
import { PillsNavBar } from "../../shared/pillsNavBar/PillsNavBar";
import { LoadingIndicator } from "../../shared/LoadingIndicator";
import { SubscriptionsDashboardTab } from "../tabs/SubscriptionsDashboardTab";
import { UserAssessmentAssignment } from "../../../api/main/models/UserAssessmentAssignment";
import { AssessmentSession } from "../../../api/main/models/AssessmentSession";
import { generateDashboardSummary as _generateDashboardSummary } from "../utilities/generateDashboardSummary";
import { EsituStaffDashboardTab } from "../tabs/EsituStaffDashboardTab";
import { SubscriptionTeamsDashboardTab } from "../tabs/SubscriptionTeamsDashboardTab";
import { SubscriptionUsersDashboardTab } from "../tabs/SubscriptionUsersDashboardTab";
import { AssessmentsDashboardTab } from "../tabs/AssessmentsDashboardTab";
import { TrainingDashboardTab } from "../tabs/TrainingDashboardTab";
import { useExpandedUserAssessmentAssignments } from "../utilities/useExpandedUserAssessmentAssignments";
import { useActiveDashboardAssessmentType } from "../../../globalState/activeDashboardAssessmentType/useActiveDashboardAssessmentType";
import { useActiveDashboardTab } from "../../../globalState/activeDashboardTab/useActiveDashboardTab";
import { DashboardBackButton } from "../backButton/DashboardBackButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DistributorsDashboardTab } from "../tabs/DistributorsDashboardTab";
import { SubscriptionType } from "../../../api/main/models/codeOnly/SubscriptionType";
import { useMemo } from 'react';

export type AdministrationDashboardTabs = 'subscriptions' | 'distributors' | 'teams' | 'staff' | 'subscriptionUsers' | 'assessments' | 'training';

/**
 * Dashboard for a subscription.
 */
export const AdministrationDashboard = () => {
    const { t } = useTranslation();

    const {
        data: {
            subscriptions,
            subscriptionTeams,
            subscriptionAssessments,
            profiles,
            assessments,
            assessmentSessions,
            userAssessmentAssignments,
            userDriverMetrics: allUserDriverMetrics,
        },
        isLoading,
        errors: loadErrors
    } = useAdministrationDashboardViewModel();

    // Expand the UserAssessmentAssignments so we can work with them easier.
    const expandedUserAssessmentAssignments = useExpandedUserAssessmentAssignments(userAssessmentAssignments, profiles);
    const distributors = useMemo(() => {

        if (subscriptions) {
            return subscriptions.filter(item => item.subscriptionType === SubscriptionType.Distributor);
        }

    }, [subscriptions]);

    // Allow the generation of dashboard summary data.
    const generateDashboardSummary = useCallback((
        sessionFilter: (session: AssessmentSession) => boolean,
        assignmentFilter: (assignment: UserAssessmentAssignment) => boolean,
        assessmentType: AssessmentType) => {
        // Filter by the passed in fitler.
        let mySessions = assessmentSessions?.filter(sessionFilter) ?? [];
        let myAssignments = expandedUserAssessmentAssignments?.filter(assignmentFilter) ?? [];

        // Filter by the passed in type.
        mySessions = mySessions.filter(session => {
            const assessment = assessments?.find(it => it.id === session.assessmentId);
            if (!assessment) {
                return false;
            }
                return assessment.assessmentType === assessmentType;
        });

        myAssignments = myAssignments.filter(session => {
            const assessment = assessments?.find(it => it.id === session.assessmentId);
            if (!assessment) {
                return false;
            }
                return assessment.assessmentType === assessmentType;
        });

        // Filter the driver metrics to contain only users that have assessments or assignments.
        const myUserDriverMetrics = allUserDriverMetrics?.filter(
            udm => !!mySessions.find(it => it.userId === udm.userId) || !!myAssignments.find(it => it.userId === udm.userId)
        ) ?? [];

        // Generate the summary data.
        const ret = _generateDashboardSummary(mySessions, assessments ?? [], profiles ?? [], myAssignments, myUserDriverMetrics, null);
        return ret;
    }, [assessmentSessions, assessments, profiles, expandedUserAssessmentAssignments, allUserDriverMetrics]);


    // Track the active tab.
    const [activeTab, setActiveTab] = useActiveDashboardTab<AdministrationDashboardTabs>('administrationDashboard', 'subscriptions');

    // Track the active assessment type.
    const [activeAssessmentType, setActiveAssessmentType] = useActiveDashboardAssessmentType();
    const hideAssessmentTypeToggle = activeTab === 'assessments' || activeTab === 'training' || activeTab === 'distributors';

    return (
        <Background>
            <Banner fluid>
                <Row>
                    <Col xs={12} lg="auto">
                        <h1>
                            <DashboardBackButton />
                            {t('administrationDashboard.heading.default', 'Esitu dashboard')}
                        </h1>
                    </Col>
                    <Col xs={12} lg="">
                        <PillsNavBar textColor="white" className="mb-2">
                            <NavItem>
                                <NavLink active={activeTab === 'subscriptions'} onClick={() => setActiveTab('subscriptions')}>
                                    <FontAwesomeIcon icon="building" />
                                    <> </>
                                    {t('administrationDashboard.tabs.subscriptions', 'Subscriptions')}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink active={activeTab === 'distributors'} onClick={() => setActiveTab('distributors')}>
                                    <FontAwesomeIcon icon="shipping-fast" />
                                    <> </>
                                    {t('administrationDashboard.tabs.distributors', 'Distributors')}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink active={activeTab === 'staff'} onClick={() => setActiveTab('staff')}>
                                    <FontAwesomeIcon icon="user-shield" />
                                    <> </>
                                    {t('administrationDashboard.tabs.staff', 'Esitu staff')}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink active={activeTab === 'subscriptionUsers'} onClick={() => setActiveTab('subscriptionUsers')}>
                                    <FontAwesomeIcon icon="user" />
                                    <> </>
                                    {t('administrationDashboard.tabs.subscriptionUsers', 'Subscription users')}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink active={activeTab === 'teams'} onClick={() => setActiveTab('teams')}>
                                    <FontAwesomeIcon icon="users" />
                                    <> </>
                                    {t('administrationDashboard.tabs.teams', 'Subscription teams')}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink active={activeTab === 'assessments'} onClick={() => setActiveTab('assessments')}>
                                    <FontAwesomeIcon icon="clipboard-check" />
                                    <> </>
                                    {t('administrationDashboard.tabs.assessments', 'Assessments')}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink active={activeTab === 'training'} onClick={() => setActiveTab('training')}>
                                    <FontAwesomeIcon icon="graduation-cap" />
                                    <> </>
                                    {t('administrationDashboard.tabs.training', 'Training')}
                                </NavLink>
                            </NavItem>
                        </PillsNavBar>
                    </Col>
                </Row>
                <Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <ConditionalFragment showIf={!hideAssessmentTypeToggle}>
                        <Col xs="auto">
                            <ButtonGroup>
                                <Button outline={activeAssessmentType !== AssessmentType.Assessment} onClick={() => setActiveAssessmentType(AssessmentType.Assessment)}>
                                    {t('administrationDashboard.toggle.assessments', 'Assessments')}
                                </Button>
                                <Button outline={activeAssessmentType !== AssessmentType.TrainingModule} onClick={() => setActiveAssessmentType(AssessmentType.TrainingModule)}>
                                    {t('administrationDashboard.toggle.training', 'Training')}
                                </Button>
                            </ButtonGroup>
                        </Col>
                    </ConditionalFragment>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
            </Banner>
            <Container fluid>
                <AlertOnErrors errors={[loadErrors]} />

                <ConditionalFragment showIf={activeTab === 'subscriptions'}>
                    <SubscriptionsDashboardTab
                        subscriptions={subscriptions ?? []}
                        generateDashboardSummary={generateDashboardSummary}
                        changeTab={tabName => setActiveTab(tabName)}
                        activeAssessmentType={activeAssessmentType}

                        assessmentSessions={assessmentSessions ?? []}
                        assessments={assessments ?? []}
                    />
                </ConditionalFragment>

                <ConditionalFragment showIf={activeTab === 'distributors'}>
                    <DistributorsDashboardTab
                        distributors={distributors ?? []}
                        subscriptions={subscriptions ?? []}
                        subscriptionAssessments={subscriptionAssessments ?? []}
                        changeTab={tabName => setActiveTab(tabName)}
                        activeAssessmentType={activeAssessmentType}
                        userAssessmentAssignment={userAssessmentAssignments ?? []}
                        assessments={assessments ?? []}
                    />
                </ConditionalFragment>

                <ConditionalFragment showIf={activeTab === 'staff'}>
                    <EsituStaffDashboardTab
                        profiles={profiles ?? []}
                        generateDashboardSummary={generateDashboardSummary}
                        activeAssessmentType={activeAssessmentType}

                        assessmentSessions={assessmentSessions ?? []}
                        assessments={assessments ?? []}
                        riskRules={null}
                    />
                </ConditionalFragment>

                <ConditionalFragment showIf={activeTab === 'subscriptionUsers'}>
                    <SubscriptionUsersDashboardTab
                        profiles={profiles ?? []}
                        generateDashboardSummary={generateDashboardSummary}
                        activeAssessmentType={activeAssessmentType}

                        assessmentSessions={assessmentSessions ?? []}
                        assessments={assessments ?? []}

                        showSubscriptionName={true}
                        subscriptions={subscriptions ?? []}
                        subscriptionTeams={subscriptionTeams ?? []}
                        riskRules={null}
                    />
                </ConditionalFragment>

                <ConditionalFragment showIf={activeTab === 'teams'}>
                    <SubscriptionTeamsDashboardTab
                        subscriptionTeams={subscriptionTeams ?? []}
                        profiles={profiles ?? []}
                        generateDashboardSummary={generateDashboardSummary}
                        activeAssessmentType={activeAssessmentType}

                        assessmentSessions={assessmentSessions ?? []}
                        assessments={assessments ?? []}

                        showSubscriptionName={true}
                        subscriptions={subscriptions ?? []}
                        riskRules={null}
                    />
                </ConditionalFragment>

                <ConditionalFragment showIf={activeTab === 'assessments'}>
                    <AssessmentsDashboardTab
                        assessments={assessments ?? []}
                        generateDashboardSummary={generateDashboardSummary}
                        activeAssessmentType={activeAssessmentType}

                        assessmentSessions={assessmentSessions ?? []}
                        riskRules={null}
                    />
                </ConditionalFragment>

                <ConditionalFragment showIf={activeTab === 'training'}>
                    <TrainingDashboardTab
                        assessments={assessments ?? []}
                        generateDashboardSummary={generateDashboardSummary}
                        activeAssessmentType={activeAssessmentType}

                        assessmentSessions={assessmentSessions ?? []}
                        riskRules={null}
                    />
                </ConditionalFragment>
            </Container>
        </Background>
    );
};
