import { QuestionType } from "../../../../../api/main/models/codeOnly/QuestionType";
import { Question } from "../../../../../api/main/models/Question";
import { QuestionAnswer } from "../../../../../api/main/models/QuestionAnswer";
import { QuestionResponseEvent } from "../../../../../api/main/models/QuestionResponseEvent";
import { ModelArrayChanges } from "../../../../../shared/useChanges";
import { ShowQuestionnaireMultipleChoice } from "./ShowQuestionnaireMultipleChoice";
import { ShowQuestionnaireSlidingScale } from "./ShowQuestionnaireSlidingScale";
import { ShowQuestionnaireInput } from "./ShowQuestionnaireInput";
import { launchAssessmentSessionViewModelQuery_viewModel_questionSequenceQuestions } from "../../../../../api/main/generated/launchAssessmentSessionViewModelQuery"

export interface ShowQuestionnaireProps {

    currentQuestionnaire: Question,

    allCurrentQuestionSequence: Array<launchAssessmentSessionViewModelQuery_viewModel_questionSequenceQuestions> | undefined,
    answers: Array<QuestionAnswer>,
    questionResponseEventsManager: ModelArrayChanges<QuestionResponseEvent, string>,

    // Selecting an answer.
    selectedAnswerId: string | undefined,
    selectSingleAnswer: (answer: QuestionAnswer | undefined, currentQuestion: Question) => void,


    assessmentId: string,
    assessmentItemId: string,
    assessmentSessionId: string,

    userId: string | undefined,

}

export const ShowQuestionnaireQuestionListComponent = (props: ShowQuestionnaireProps) => {
    const {
        currentQuestionnaire,

        allCurrentQuestionSequence,
        answers,

        questionResponseEventsManager,
        selectSingleAnswer,

        assessmentId,
        assessmentItemId,
        assessmentSessionId,
        userId,
    } = props;

    const filteredQuestions = allCurrentQuestionSequence?.filter(item => item.parentQuestionId === currentQuestionnaire.id && item.childQuestion.archived === false)

    return (
        <>

            <h4 style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                {

                    currentQuestionnaire.questionText

                }
            </h4>

            <hr />

            {
                filteredQuestions?.map(item => {
                    //loop through all questions for this questionnaire


                    const filteredAnswers: Array<QuestionAnswer> = answers?.filter(it => it.questionId === item.childQuestionId && !item.archived) ?? []
                    //filter the answers we have to be for the current question

                    // Sort filtered answers into display order.
                    let orderedAnswers = [...filteredAnswers];
                    orderedAnswers.sort((a, b) => {
                        if (a.displayOrder === b.displayOrder) {
                            return 0;
                        } else if (a.displayOrder > b.displayOrder) {
                            return 1;
                        } else {
                            return -1;
                        }
                    });


                    const myQuestionResponseEvent = questionResponseEventsManager.model.find(it => it.questionId === item.childQuestionId && !it.archived);
                    //find any responses that we already have

                    const selectedQuestionAnswerId = myQuestionResponseEvent?.questionAnswerId ?? undefined;
                    //pass the response we found in for the components, if we didnt have one we use undefined

                    const questionType = item.childQuestion.questionType as QuestionType;

                    switch (questionType) {
                        case QuestionType.QuestionnaireMultipleChoice:
                            return (
                                <>
                                    <ShowQuestionnaireMultipleChoice
                                        model={item.childQuestion}
                                        answers={orderedAnswers}
                                        selectedAnswerId={selectedQuestionAnswerId}
                                        selectSingleAnswer={selectSingleAnswer}
                                    />
                                    <hr />
                                </>
                            );

                        case QuestionType.QuestionnaireScale:
                            return (
                                <>
                                    <ShowQuestionnaireSlidingScale
                                        model={item.childQuestion}
                                        answers={orderedAnswers}
                                        selectedAnswerId={selectedQuestionAnswerId}
                                        selectSingleAnswer={selectSingleAnswer}
                                        questionResponseEventsManager={questionResponseEventsManager}
                                        userId={userId}
                                    />
                                    <hr />
                                </>
                            );

                        case QuestionType.QuestionnaireInput:
                            return (
                                <>
                                    <ShowQuestionnaireInput
                                        model={item.childQuestion}
                                        questionResponseEventsManager={questionResponseEventsManager}
                                        assessmentId={assessmentId}
                                        assessmentItemId={assessmentItemId}
                                        assessmentSessionId={assessmentSessionId}
                                    />
                                    <hr />
                                </>
                            );
                        default:
                            //should never reach but something to fall back on if it theres no logic for the current question type (avoid errors)
                            return (
                                <>
                                    {item.childQuestion.name} [{item.childQuestion.questionType}]
                                </>
                            );

                    }
                })
            }
        </>
    );

}