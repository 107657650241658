import { useTranslation } from "react-i18next";
import { Banner } from "../shared/Banner";
import { Background } from "../shared/background/Background";
import { profileTabRoutes } from "./routes";
import { AppRoutes } from "../shared/AppRoutes";
import { PillsNavBar } from "../shared/pillsNavBar/PillsNavBar";
import { ProfileNavigation } from "./ProfileNavigation";
import { Col, Row } from "reactstrap";

/**
 * Allow the user to change their profile.
 */
export const Profile = () => {
    const { t } = useTranslation();

    return (
        <Background>
            <Banner>
                <Row>
                    <Col xs={12} md="auto">
                        <h1>{t('profile.heading', 'Profile')}</h1>
                    </Col>
                    <Col>
                        <PillsNavBar>
                            <ProfileNavigation />
                        </PillsNavBar>
                    </Col>
                </Row>
            </Banner>

            <AppRoutes routes={profileTabRoutes} />
        </Background>
    );
};
