import { AssessmentType } from "../../../api/main/models/codeOnly/AssessmentType";
import { CreateAssessmentBase, EditAssessmentBase } from "../edit/EditAssessmentBase";

/**
 * Create an actual assessment.
 */
export const CreateAssessment = () => (
    <CreateAssessmentBase
        defaultAssessmentType={AssessmentType.Assessment}
        onCreateDefaultValues={() => ({ assessmentType: AssessmentType.Assessment })}
    />);

/**
 * Edit an actual assessment.
 */
export const EditAssessment = () => (<EditAssessmentBase defaultAssessmentType={AssessmentType.Assessment} />);
