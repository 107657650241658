/**
 * Class that prefetches and manages images as blob URLs.
 */
export class ImagePrefetcher {
    private _blobs: { [key: string]: CachedImage | undefined } = {};

    /**
     * Prefetch an image and add it to the cache.
     * @param url
     */
    async cacheImage(url: string) {
        // If we already have an existing cache record for this url, do nothing.
        const existing = this._blobs[url];
        if (existing) {
            return;
        }

        // Create a cached record as a placeholder.
        let cacheRecord: CachedImage = {
            originalUrl: url,
            cachedBlobUrl: undefined,
            isLoading: true,
        };
        this._blobs[url] = cacheRecord;

        // Fetch the image.
        const result = await fetch(url);

        // Convert the fetched image into a blob.
        const blob = await result.blob();

        // Create a blob URL from the results.
        const blobUrl = URL.createObjectURL(blob);

        // Update the cached record.
        cacheRecord.cachedBlobUrl = blobUrl;
        this._blobs[url] = cacheRecord;
    }

    /**
     * Remove the cached image from the cache.
     * @param url
     */
    removeImage(url: string) {
        // Lookup the cached record.
        const cacheRecord = this._blobs[url];
        if (!cacheRecord) {
            return;
        }

        // Revoke the blob url object.
        if (cacheRecord.cachedBlobUrl) {
            URL.revokeObjectURL(cacheRecord?.cachedBlobUrl);
        }

        // Remove the cache record.
        this._blobs[url] = undefined;
    }

    /**
     * Returns true if this url has a ready cached item to use.
     * @param url
     */
    isCached(url: string): boolean {
        const cacheRecord = this._blobs[url];
        if (cacheRecord?.cachedBlobUrl) {
            return true;
        }

        return false;
    }

    /**
     * Returns true if this url is currently loading into the cache.
     * @param url
     */
    isLoading(url: string): boolean {
        const cacheRecord = this._blobs[url];
        return !!cacheRecord?.isLoading;
    }

    /**
     * Returns the best url to use with the following rules:
     * 1. If we have a cached blob URL for this item, we return that.
     * 2. Otherwise we return the url unchanged.
     * @param url
     */
    urlFor(url: string): string {
        const cacheRecord = this._blobs[url];
        return cacheRecord?.cachedBlobUrl ?? url;
    }
}

/**
 * An entry in the ImagePrefetcher cache.
 */
interface CachedImage {
    originalUrl: string,
    cachedBlobUrl: string | undefined,
    isLoading: boolean,
}
