import { Button, Row, Col, Form, Label, FormGroup, Spinner, NavItem, NavLink } from 'reactstrap';
import { AlertOnErrors } from '../../../shared/alertOnErrors';
import { LoadingIndicator } from '../../shared/LoadingIndicator';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContainer } from '../../shared/MainContainer';
import { useParams, useHistory } from 'react-router';
import { useChanges, useChangesArray } from '../../../shared/useChanges';
import { useSaveQuestionCallback } from '../../../api/main/questions/useSaveQuestionCallback';
import { useDeleteQuestionCallback } from '../../../api/main/questions/useDeleteQuestionCallback';
import { useValidatorCallback } from 'pojo-validator-react';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { FormButtons } from '../../shared/FormButtons';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { useAsyncCallback } from 'react-use-async-callback';
import { Guid } from 'guid-string';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Banner } from '../../shared/Banner';
import { Background } from '../../shared/background/Background';
import { useEditQuestionViewModel } from '../../../api/main/questions/viewModels/useEditQuestionViewModel';
import { getQuestionTypeCategory, getQuestionTypes, QuestionType, QuestionTypeCategory, questionTypeDisplayName } from '../../../api/main/models/codeOnly/QuestionType';
import { PillsNavBar } from '../../shared/pillsNavBar/PillsNavBar';
import { VideoTab } from './video/VideoTab';
import { useBlobReferenceState } from '../../shared/useBlobReferenceState/useBlobReferenceState';
import { QuestionPromptMultipleChoiceVideoTab } from './multipleChoiceVideo/QuestionPromptMultipleChoiceVideoTab';
import { useSaveQuestionAnswerCallback } from '../../../api/main/questionAnswers/useSaveQuestionAnswerCallback';
import { useDeleteQuestionAnswerCallback } from '../../../api/main/questionAnswers/useDeleteQuestionAnswerCallback';
import { QuestionAnswer } from '../../../api/main/models/QuestionAnswer';
import { BlobReference } from '../../../api/main/models/BlobReference';
import { useBlobReferenceStateArray } from '../../shared/useBlobReferenceState/useBlobReferenceStateArray';
import { StickyToolbar } from '../../shared/StickyToolbar';
import { FeedbackTab } from './feedback/FeedbackTab';
import { QuestionFeedback } from '../../../api/main/models/QuestionFeedback';
import { useSaveQuestionFeedbackCallback } from '../../../api/main/questionFeedbacks/useSaveQuestionFeedbackCallback';
import { useDeleteQuestionFeedbackCallback } from '../../../api/main/questionFeedbacks/useDeleteQuestionFeedbackCallback';
import { QuestionTagsTab } from './questionTags/QuestionTagsTab';
import { useEditQuestionSupportingData } from '../../../api/main/questions/viewModels/useEditQuestionSupportingData';
import { QuestionTagLink } from '../../../api/main/models/QuestionTagLink';
import { useDeleteQuestionTagLinkCallback } from '../../../api/main/questionTagLinks/useDeleteQuestionTagLinkCallback';
import { DriverMetricsTab } from './driverMetrics/DriverMetricsTab';
import { DriverMetricAdjustment } from '../../../api/main/models/DriverMetricAdjustment';
import { useSaveDriverMetricAdjustmentCallback } from '../../../api/main/driverMetricAdjustments/useSaveDriverMetricAdjustmentCallback';
import { useDeleteDriverMetricAdjustmentCallback } from '../../../api/main/driverMetricAdjustments/useDeleteDriverMetricAdjustmentCallback';
import { DriverMetricRecommendation } from '../../../api/main/models/DriverMetricRecommendation';
import { useSaveDriverMetricRecommendationCallback } from '../../../api/main/driverMetricRecommendations/useSaveDriverMetricRecommendationCallback';
import { useDeleteDriverMetricRecommendationCallback } from '../../../api/main/driverMetricRecommendations/useDeleteDriverMetricRecommendationCallback';
import { useSaveQuestionTagLinkCallback } from '../../../api/main/questionTagLinks/useSaveQuestionTagLinkCallback';
import { useValidatorArrayCallback } from '../../../shared/validator-react-contrib/useValidatorArrayCallback';
import { hasAnyValidationErrors } from '../../../utilities/hasAnyValidationErrors';
import { QuestionPromptSlidingScaleVideoTab } from './slidingScaleVideo/QuestionPromptSlidingScaleVideoTab';
import { QuestionPromptResponseTimeVideoTab } from './responseTimeVideo/QuestionPromptResponseTimeVideoTab';
import { ImageTab } from './image/ImageTab';
import { QuestionPromptLocationResponseImageTab } from './locationResponseImage/QuestionPromptLocationResponseImageTab';
import { QuestionPromptLocationDiscriminationImageTab } from './locationDiscriminationImage/QuestionPromptLocationDiscriminationImageTab';
import { SequenceOfQuestionsTab } from './sequenceOfQuestions/SequenceOfQuestionsTab';
import { QuestionSequenceQuestion } from '../../../api/main/models/QuestionSequenceQuestion';
import { useSaveQuestionSequenceQuestionCallback } from '../../../api/main/questionSequenceQuestions/useSaveQuestionSequenceQuestionCallback';
import { useDeleteQuestionSequenceQuestionCallback } from '../../../api/main/questionSequenceQuestions/useDeleteQuestionSequenceQuestionCallback';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuestions } from '../../../api/main/questions/useQuestions';
import { Question, questionDefaultValues } from '../../../api/main/models/Question';
import { LearningTextTab } from './learningText/LearningTextTab';
import { SequenceOfQuestionnaireQuestionsTab } from '../questionnaires/QuestionnaireQuestion/SequenceOfQuestionnaireQuestionsTab';
import { InformationTextTab } from './informationText/InformationTextTab';
import { QuestionPromptMultipleChoiceImageTab } from './multipleChoiceImage/QuestionPromptMultipleChoiceImageTab';


interface EditQuestionBaseProps {
    isCreate?: boolean,
    onCreateDefaultValues?: () => Partial<Question>,
    defaultQuestionType: QuestionType,
}


/**
 * Create and a new question.
 */
export const CreateQuestionBase = (props: EditQuestionBaseProps) => (<EditQuestionBase isCreate={true} {...props} />);

/**
 * Edit a question.
 */
export const EditQuestionBase = (props: EditQuestionBaseProps) => {
    const {
        isCreate,
        onCreateDefaultValues,
        defaultQuestionType,
    } = props;

    const { t } = useTranslation();
    const { id } = useParams<{ id: string | undefined }>();
    const { data: {
        model: storeModel,
        answers: storeAnswers,
        questionnaireQuestionAnswers: storeQuestionnaireQuestionAnswers,
        feedbacks: storeFeedbacks,
        questionTagLinks: storeQuestionTagLinks,
        driverMetricRecommendations: storeDriverMetricRecommendations,
        driverMetricAdjustments: storeDriverMetricAdjustments,
        questionSequenceQuestions: storeQuestionSequenceQuestions,
    }, isLoading: _isLoading, errors: loadErrors, refresh: refreshEdit } = useEditQuestionViewModel(id);
    const { data: { questionTags, driverMetrics, }, isLoading: isLoadingSupportingData, errors: loadSupportingDataErrors } = useEditQuestionSupportingData();
    const isLoading = _isLoading || isLoadingSupportingData;
    const { model, change, changes } = useChanges(storeModel, isCreate ? { ...questionDefaultValues(), ...(onCreateDefaultValues ? onCreateDefaultValues() : {}) } : undefined);
    const [save, { errors: saveErrors }] = useSaveQuestionCallback();
    const history = useHistory();

    // We want to customise the interface based on the category of question we are.
    const activeQuestionType = model?.questionType as QuestionType ?? defaultQuestionType;
    const activeQuestionTypeCategory: QuestionTypeCategory = useMemo(() => getQuestionTypeCategory(model?.questionType as QuestionType || defaultQuestionType), [model, defaultQuestionType]);

    // Video and image display and uploading
    const [videoBlob, setVideoBlob, { upload: uploadVideoBlob, isUploading: isUploadingVideoBlob, uploadErrors: uploadVideoBlobErrors, clearUpload: clearVideoBlob }] = useBlobReferenceState(blob => {
        change({ videoBlobReferenceId: blob?.id ?? null });
    });
    useEffect(() => setVideoBlob(storeModel?.videoBlobReference ?? null), [setVideoBlob, storeModel]);

    const [imageBlob, setImageBlob, { upload: uploadImageBlob, isUploading: isUploadingImageBlob, uploadErrors: uploadImageBlobErrors, clearUpload: clearImageBlob }] = useBlobReferenceState(blob => {
        change({ imageBlobReferenceId: blob?.id ?? null });
    });
    useEffect(() => setImageBlob(storeModel?.imageBlobReference ?? null), [setImageBlob, storeModel]);

    const [questionTextImageBlob, setQuestionTextImageBlob, { upload: uploadQuestionTextImageBlob, isUploading: isUploadingQuestionTextImageBlob, uploadErrors: uploadQuestionTextImageBlobErrors, clearUpload: clearQuestionTextImageBlob }] = useBlobReferenceState(blob => {
        change({ questionTextImageBlobReferenceId: blob?.id ?? null });
    });
    useEffect(() => setQuestionTextImageBlob(storeModel?.questionTextImageBlobReference ?? null), [setQuestionTextImageBlob, storeModel]);

    // Blobs for child objects need to be managed as a dictionary of id to blobs.
    const [childBlobs, setChildBlobs, { upload: uploadChildBlob, uploadErrors: uploadChildBlobErrors }] = useBlobReferenceStateArray();
    useEffect(() => {
        let ret: Array<BlobReference | null> = [];

        if (storeAnswers) {
            for (const answer of storeAnswers) {
                ret.push(answer?.answerTextImageBlobReference);
                ret.push(answer?.scaleImageBlobReference);
            }
        }

        if (storeFeedbacks) {
            for (const feedback of storeFeedbacks) {
                ret.push(feedback?.videoBlobReference);
                ret.push(feedback?.imageBlobReference);
            }
        }

        setChildBlobs(ret.filter(item => !!item) as Array<BlobReference>);
    }, [setChildBlobs, storeAnswers, storeFeedbacks]);

    // Answers.
    const answersManager = useChangesArray<QuestionAnswer, string>(storeAnswers, item => item.id);
    const [saveQuestionAnswer] = useSaveQuestionAnswerCallback();
    const [removeQuestionAnswer] = useDeleteQuestionAnswerCallback();

    // Feedbacks.
    const feedbacksManager = useChangesArray<QuestionFeedback, string>(storeFeedbacks, item => item.id);
    const [saveQuestionFeedback] = useSaveQuestionFeedbackCallback();
    const [removeQuestionFeedback] = useDeleteQuestionFeedbackCallback();

    // QuestionTagLinks.
    const questionTagLinksManager = useChangesArray<QuestionTagLink, string>(storeQuestionTagLinks, item => item.id);
    const [saveQuestionTagLink] = useSaveQuestionTagLinkCallback();
    const [removeQuestionTagLink] = useDeleteQuestionTagLinkCallback();

    // DriverMetricAdjustments.
    const driverMetricAdjustmentsManager = useChangesArray<DriverMetricAdjustment, string>(storeDriverMetricAdjustments, item => item.id);
    const [saveQuestionDriverMetricAdjustment] = useSaveDriverMetricAdjustmentCallback();
    const [removeQuestionDriverMetricAdjustment] = useDeleteDriverMetricAdjustmentCallback();

    // DriverMetricRecommendations.
    const driverMetricRecommendationsManager = useChangesArray<DriverMetricRecommendation, string>(storeDriverMetricRecommendations, item => item.id);
    const [saveQuestionDriverMetricRecommendation] = useSaveDriverMetricRecommendationCallback();
    const [removeQuestionDriverMetricRecommendation] = useDeleteDriverMetricRecommendationCallback();

    // QuestionSequenceQuestion.
    const questionSequenceQuestionsManager = useChangesArray<QuestionSequenceQuestion, string>(storeQuestionSequenceQuestions, item => item.id);
    const [saveQuestionQuestionSequenceQuestion] = useSaveQuestionSequenceQuestionCallback();
    const [removeQuestionQuestionSequenceQuestion] = useDeleteQuestionSequenceQuestionCallback();

    //turn the filtered answers into a manager for changes
    const questionnaireQuestionAnswersManager = useChangesArray<QuestionAnswer, string>(storeQuestionnaireQuestionAnswers, item => item.id);

    // For QuestionSequenceQuestions we need to allow a list of all (other) questions to be shown.  We don't want to load this list until we need it though
    // so when the question loads we load the child questions for each of the QuestionSequenceQuestions and we start by just having those loaded.  If we need
    // to show more questions than that, then we are able to really load all questions at that point.
    const { data: { items: _allQuestions }, isLoading: isLoadingAllQuestions, errors: loadAllQuestionsErrors, refresh: loadAllQuestions } = useQuestions({ lazy: true });
    const allQuestions = useMemo(() => {
        // If we haven't loaded all the questions yet, then fall back on just the child questions we know we've loaded.
        if (!_allQuestions) {
            return storeQuestionSequenceQuestions?.map(item => item.childQuestion)?.filter(item => !!item) ?? [];
        }

        // Otherwise return all questions, with our own question removed so we can't create a circular reference.
        return _allQuestions.filter(item => item.id !== model?.id);
    }, [storeQuestionSequenceQuestions, _allQuestions, model]);
    const ensureAllQuestionsLoaded = useCallback(() => {
        // If we've already loaded all questions, then we don't need to do anything.
        if (_allQuestions) {
            return;
        }

        // If we are already loading all questions, then we also don't need to do anything as we'll get there once it completes.
        if (isLoadingAllQuestions) {
            return;
        }

        // Otherwise lets trigger the load of all questions.
        loadAllQuestions();
    }, [_allQuestions, isLoadingAllQuestions, loadAllQuestions]);

    //QuestionnaireQuestions.
    //Save is not needed as it is already added as "save".
    const questionnaireQuestionsManager = useChangesArray<Question, string>(allQuestions, item => item.id)
    const [removeQuestionnaireQuestion] = useDeleteQuestionCallback();

    // Validation
    //
    const [validateQuestionFeedback, questionFeedbackValidationErrors] = useValidatorArrayCallback<QuestionFeedback>((myModel, validation, fieldsToCheck) => {
        const rules = {
            // No rules required.
        };

        validation.checkRules(rules, fieldsToCheck);
    }, []);

    const [validateQuestionAnswer, questionAnswerValidationErrors] = useValidatorArrayCallback<QuestionAnswer>((myModel, validation, fieldsToCheck) => {
        const rules = {
            score: () => myModel.score > 100 || myModel.score < 0 ? t('editQuestion.validateQuestionAnswer.score', 'Score must be between 0 and 100') : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, []);

    const [validateDriverMetricAdjustment, driverMetricAdjustmentValidationErrors] = useValidatorArrayCallback<DriverMetricAdjustment>((myModel, validation, fieldsToCheck) => {
        const rules = {
            driverMetricId: () => Guid.isEmpty(myModel.driverMetricId) ? t('editQuestion.validateDriverMetricAdjustment.driveMetricRequired', 'Metric is required') : '',
            adjustmentValue: () => myModel.adjustmentValue === 0 ? t('editQuestion.validateDriverMetricAdjustment.adjustmentValueRequired', 'Adjustment cannot be zero') : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, []);

    const [validateDriverMetricRecommendation, driverMetricRecommendationValidationErrors] = useValidatorArrayCallback<DriverMetricRecommendation>((myModel, validation, fieldsToCheck) => {
        const rules = {
            driverMetricId: () => Guid.isEmpty(myModel.driverMetricId) ? t('editQuestion.validateDriverMetricRecommendation.driveMetricRequired', 'Metric is required') : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, []);

    // Main model validation.
    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            name: () => !model?.name ? t('editQuestion.nameRequired', 'Name is required') : '',
            questionType: () => !model?.questionType ? (
                activeQuestionTypeCategory === QuestionTypeCategory.Learning ? t('editQuestion.questionType.required.learning', 'Type of learning is required')
                    : activeQuestionTypeCategory === QuestionTypeCategory.Questionnaire ? t('editQuestion.questionType.required.questionnaire', 'Type of questionnaire is required')
                        : t('editQuestion.questionType.required.default', 'Type of question is required')
            ): '',

            // Validate related objects
            feedback: () => feedbacksManager.model.filter(it => !validateQuestionFeedback(it)).length ? t('editCalendarEvent.feedbacksInvalid', 'One or more of the feedbacks are invalid') : '',
            answers: () => answersManager.model.filter(it => !validateQuestionAnswer(it)).length ? t('editCalendarEvent.answersInvalid', 'One or more of the answers are invalid') : '',
            driverMetricRecommendations: () => driverMetricRecommendationsManager.model.filter(it => !validateDriverMetricRecommendation(it)).length ? t('editCalendarEvent.driverMetricRecommendationsInvalid', 'One or more of the drive metric recommendation rules are invalid') : '',
            driverMetricAdjustments: () => driverMetricAdjustmentsManager.model.filter(it => !validateDriverMetricAdjustment(it)).length ? t('editCalendarEvent.driverMetricAdjustmentsInvalid', 'One or more of the drive metric recommendation rules are invalid') : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, [
        model,
        feedbacksManager, validateQuestionFeedback,
        answersManager, validateQuestionAnswer,
        driverMetricRecommendationsManager, validateDriverMetricRecommendation,
        driverMetricAdjustmentsManager, validateDriverMetricAdjustment,
        activeQuestionTypeCategory,
    ]);

    // Saving.
    const [saveForm, { isExecuting: isSaving, errors: saveFormErrors }] = useAsyncCallback(async () => {
        if (!validate()) {
            return;
        }
        if (model.questionType === QuestionType.Questionnaire) {
            changes.numberOfSubQuestions = questionnaireQuestionsManager.model.length - questionnaireQuestionsManager.removed.length;
        }
        // Save the main model.
        await save(model.id, changes, !!isCreate);

        //Save the questionnaire questions.
        //need to happen before the answers and sequence questions are saved so they have something to put as foreign key
        for (const item of questionnaireQuestionsManager.added) { await save(item.id, questionnaireQuestionsManager.changesFor(item.id), true); }
        for (const item of questionnaireQuestionsManager.updated) { await save(item.id, questionnaireQuestionsManager.changesFor(item.id), false); }
        for (const item of questionnaireQuestionsManager.removed) { await removeQuestionnaireQuestion(item.id); }
        questionnaireQuestionsManager.markAsSaved();
               
        // Change any new or updated answer texts to remove blank lines from the end
        for (const item of answersManager.added) { await answersManager.changeFor(item.id, ({ answerText: item.answerText.replace("<br>&nbsp;</p>", "</p>") })); }
        for (const item of answersManager.updated) { await answersManager.changeFor(item.id, ({ answerText: item.answerText.replace("<br>&nbsp;</p>", "</p>") })); }

        // Save the answers.
        for (const item of answersManager.added) { await saveQuestionAnswer(item.id, answersManager.changesFor(item.id), true); }
        for (const item of answersManager.updated) { await saveQuestionAnswer(item.id, answersManager.changesFor(item.id), false); }
        for (const item of answersManager.removed) { await removeQuestionAnswer(item.id); }
        answersManager.markAsSaved();

        // Questionnaire Question Answers
        // Save the questionnaire question answers
        for (const item of questionnaireQuestionAnswersManager.added) { await saveQuestionAnswer(item.id, questionnaireQuestionAnswersManager.changesFor(item.id), true); }
        for (const item of questionnaireQuestionAnswersManager.updated) { await saveQuestionAnswer(item.id, questionnaireQuestionAnswersManager.changesFor(item.id), false); }
        for (const item of questionnaireQuestionAnswersManager.removed) { await removeQuestionAnswer(item.id); }
        questionnaireQuestionAnswersManager.markAsSaved();

        // Save the feedbacks.
        for (const item of feedbacksManager.added) { await saveQuestionFeedback(item.id, feedbacksManager.changesFor(item.id), true); }
        for (const item of feedbacksManager.updated) { await saveQuestionFeedback(item.id, feedbacksManager.changesFor(item.id), false); }
        for (const item of feedbacksManager.removed) { await removeQuestionFeedback(item.id); }
        feedbacksManager.markAsSaved();

        // Save the question tag links.
        for (const item of questionTagLinksManager.added) { await saveQuestionTagLink(item.id, questionTagLinksManager.changesFor(item.id), true); }
        for (const item of questionTagLinksManager.updated) { await saveQuestionTagLink(item.id, questionTagLinksManager.changesFor(item.id), false); }
        for (const item of questionTagLinksManager.removed) { await removeQuestionTagLink(item.id); }
        questionTagLinksManager.markAsSaved();

        // Save the driver metric recommendations
        for (const item of driverMetricRecommendationsManager.added) { await saveQuestionDriverMetricRecommendation(item.id, driverMetricRecommendationsManager.changesFor(item.id), true); }
        for (const item of driverMetricRecommendationsManager.updated) { await saveQuestionDriverMetricRecommendation(item.id, driverMetricRecommendationsManager.changesFor(item.id), false); }
        for (const item of driverMetricRecommendationsManager.removed) { await removeQuestionDriverMetricRecommendation(item.id); }
        driverMetricRecommendationsManager.markAsSaved();

        // Save the driver metric adjustment.
        for (const item of driverMetricAdjustmentsManager.added) { await saveQuestionDriverMetricAdjustment(item.id, driverMetricAdjustmentsManager.changesFor(item.id), true); }
        for (const item of driverMetricAdjustmentsManager.updated) { await saveQuestionDriverMetricAdjustment(item.id, driverMetricAdjustmentsManager.changesFor(item.id), false); }
        for (const item of driverMetricAdjustmentsManager.removed) { await removeQuestionDriverMetricAdjustment(item.id); }
        driverMetricAdjustmentsManager.markAsSaved();

        // Save the sequences of questions.
        for (const item of questionSequenceQuestionsManager.added) { await saveQuestionQuestionSequenceQuestion(item.id, questionSequenceQuestionsManager.changesFor(item.id), true); }
        for (const item of questionSequenceQuestionsManager.updated) { await saveQuestionQuestionSequenceQuestion(item.id, questionSequenceQuestionsManager.changesFor(item.id), false); }
        for (const item of questionSequenceQuestionsManager.removed) { await removeQuestionQuestionSequenceQuestion(item.id); }
        questionSequenceQuestionsManager.markAsSaved();



        history.goBack();
    }, [
        validate, save, model, changes, isCreate, history,
        answersManager, saveQuestionAnswer, removeQuestionAnswer,
        feedbacksManager, saveQuestionFeedback, removeQuestionFeedback,
        questionTagLinksManager, saveQuestionTagLink, removeQuestionTagLink,
        driverMetricRecommendationsManager, saveQuestionDriverMetricRecommendation, removeQuestionDriverMetricRecommendation,
        driverMetricAdjustmentsManager, saveQuestionDriverMetricAdjustment, removeQuestionDriverMetricAdjustment,
        questionSequenceQuestionsManager, saveQuestionQuestionSequenceQuestion, removeQuestionQuestionSequenceQuestion,
    ]);

    // Tab tracking and UI matching to specific question types.
    const [activeTab, setActiveTab] = useState<'main' | 'questionPrompt' | 'feedback' | 'driverMetrics' | 'tags'>('main');
    const isVideoQuestion = useCallback(() => (
        model?.questionType === QuestionType.MultipleChoiceVideo
        || model?.questionType === QuestionType.SlidingScaleVideo
        || model?.questionType === QuestionType.ResponseTimeVideo
        || model?.questionType === QuestionType.LearningVideo
        || model?.questionType === QuestionType.InformationVideo
    ), [model]);
    const isImageQuestion = useCallback(() => (
        model?.questionType === QuestionType.LocationResponseImage
        || model?.questionType === QuestionType.LocationDiscriminationImage
        || model?.questionType === QuestionType.MultipleChoiceImage
        || model?.questionType === QuestionType.LearningImage
        || model?.questionType === QuestionType.InformationImage
    ), [model]);
    const canHaveFeedback = useCallback(() => (
        model?.questionType === QuestionType.MultipleChoiceVideo
        || model?.questionType === QuestionType.SlidingScaleVideo
        || model?.questionType === QuestionType.ResponseTimeVideo
        || model?.questionType === QuestionType.LocationResponseImage
        || model?.questionType === QuestionType.LocationDiscriminationImage
        || model?.questionType === QuestionType.MultipleChoiceImage
        || model?.questionType === QuestionType.Questionnaire
    ), [model]);
    const canHaveDriverMetrics = useCallback(() => (
        model?.questionType === QuestionType.MultipleChoiceVideo
        || model?.questionType === QuestionType.SlidingScaleVideo
        || model?.questionType === QuestionType.ResponseTimeVideo
        || model?.questionType === QuestionType.LocationResponseImage
        || model?.questionType === QuestionType.LocationDiscriminationImage
        || model?.questionType === QuestionType.MultipleChoiceImage
    ), [model]);
    const canHaveAnswers = useCallback(() => (
        model?.questionType === QuestionType.MultipleChoiceVideo
        || model?.questionType === QuestionType.SlidingScaleVideo
        || model?.questionType === QuestionType.ResponseTimeVideo
        || model?.questionType === QuestionType.LocationResponseImage
        || model?.questionType === QuestionType.LocationDiscriminationImage
        || model?.questionType === QuestionType.MultipleChoiceImage
        ), [model]);

    // UI
    //
    return (
        <Background>
            <Banner>
                <Row>
                    <Col>
                        <Row>
                            <Col xs={12} md="auto">
                                <h1>
                                    {
                                        activeQuestionTypeCategory === QuestionTypeCategory.Learning ? (
                                            isCreate ? (
                                                <>{t('editQuestion.createHeading.learning', 'Add learning activity')}</>
                                            ) : (
                                                <>{t('editQuestion.editHeading.learning', 'Edit learning activity')}</>
                                            )
                                        ) : activeQuestionTypeCategory === QuestionTypeCategory.Questionnaire ? (
                                            isCreate ? (
                                                <>{t('editQuestion.createHeading.questionnaire', 'Add Questionnaire')}</>
                                            ) : (
                                                        <>{t('editQuestion.editHeading.questionnaire', 'Edit Questionnaire')}</>
                                            )
                                        ) : (
                                                isCreate ? (
                                                    <>{t('editQuestion.createHeading.default', 'Add question')}</>
                                                ) : (
                                                    <>{t('editQuestion.editHeading.default', 'Edit question')}</>
                                                )
                                        )
                                    }
                                </h1>
                            </Col>
                            <Col>
                                <StickyToolbar>
                                    <PillsNavBar>
                                        <NavItem>
                                            <NavLink active={activeTab === 'main'} onClick={() => setActiveTab('main')}>
                                                {
                                                    isVideoQuestion() ? <> <FontAwesomeIcon icon="video" className="nav-icon" /> {t('editQuestion.main.video', ' Video')}</>
                                                        : isImageQuestion() && activeQuestionTypeCategory === QuestionTypeCategory.Learning ? <><FontAwesomeIcon icon="chalkboard-teacher" className="nav-icon" /> {t('editQuestion.main.learning', ' Learning')}</>
                                                        : isImageQuestion() ? <> <FontAwesomeIcon icon="ticket-alt" className="nav-icon" /> {t('editQuestion.main.image', ' Scene')} </>
                                                                : model?.questionType === QuestionType.SequenceOfQuestions ? <> <FontAwesomeIcon icon="car-crash" className="nav-icon" /> {t('editQuestion.main.questions', ' Questions')}</>
                                                                : activeQuestionTypeCategory === QuestionTypeCategory.Learning ? <> <FontAwesomeIcon icon="chalkboard-teacher" className="nav-icon" /> {t('editQuestion.main.learning', ' Learning')}</>
                                                                        : activeQuestionTypeCategory === QuestionTypeCategory.Questionnaire ? <> <FontAwesomeIcon icon="meh" className="nav-icon" /> {t('editQuestion.main.quesitonnaire', ' Questionnaire')}</>
                                                                            : activeQuestionTypeCategory === QuestionTypeCategory.Informtion ? <> <FontAwesomeIcon icon="book" className="nav-icon" /> {t('editQuestion.main.information', ' Information page')}</>
                                                                                :<> <FontAwesomeIcon icon="car-crash" className="nav-icon" /> {t('editQuestion.main.unknown', ' Question')}</>
                                                }
                                                <ConditionalFragment showIf={hasAnyValidationErrors(validationErrors['questionType'])}>
                                                    <> </><FontAwesomeIcon icon="exclamation-triangle" color="danger" />
                                                </ConditionalFragment>
                                            </NavLink>
                                        </NavItem>
                                        <ConditionalFragment showIf={canHaveAnswers()}>
                                            <ConditionalFragment showIf={isVideoQuestion()}>
                                                <NavItem>
                                                    <NavLink active={activeTab === 'questionPrompt'} onClick={() => setActiveTab('questionPrompt')}>
                                                        {
                                                            model?.questionType === QuestionType.ResponseTimeVideo ? <> <FontAwesomeIcon icon="window-restore" className="nav-icon" />
                                                                {t('editQuestion.responseWindows', ' Response windows')} </>
                                                                : <> <FontAwesomeIcon icon="car-crash" className="nav-icon" /> {t('editQuestion.questionPrompt', ' Question prompt')} </>
                                                        }
                                                        <ConditionalFragment showIf={hasAnyValidationErrors(validationErrors['answers'])}>
                                                            <> </><FontAwesomeIcon icon="exclamation-triangle" color="danger" />
                                                        </ConditionalFragment>
                                                    </NavLink>
                                                </NavItem>
                                            </ConditionalFragment>
                                            <ConditionalFragment showIf={isImageQuestion()}>
                                                <NavItem>
                                                    <NavLink active={activeTab === 'questionPrompt'} onClick={() => setActiveTab('questionPrompt')}>
                                                        {
                                                            model?.questionType === QuestionType.MultipleChoiceImage ?
                                                                <> <FontAwesomeIcon icon="car-crash" className="nav-icon" /> {t('editQuestion.questionPrompt', ' Question prompt')} </>
                                                                :<> <FontAwesomeIcon icon="window-restore" className="nav-icon" /> {t('editQuestion.responseWindows', ' Response windows')} </>
                                                        }
                                                        <ConditionalFragment showIf={hasAnyValidationErrors(validationErrors['answers'])}>
                                                            <> </><FontAwesomeIcon icon="exclamation-triangle" color="danger" />
                                                        </ConditionalFragment>
                                                    </NavLink>
                                                </NavItem>
                                            </ConditionalFragment>
                                        </ConditionalFragment>
                                        <ConditionalFragment showIf={canHaveFeedback()}>
                                            <NavItem>
                                                <NavLink active={activeTab === 'feedback'} onClick={() => setActiveTab('feedback')}>
                                                    <FontAwesomeIcon icon="comment-dots" className="nav-icon" />
                                                    {t('editQuestion.feedback', ' Feedback')}
                                                    <ConditionalFragment showIf={hasAnyValidationErrors(validationErrors['feedback'])}>
                                                        <> </><FontAwesomeIcon icon="exclamation-triangle" color="danger" />
                                                    </ConditionalFragment>
                                                </NavLink>
                                            </NavItem>
                                        </ConditionalFragment>
                                        <ConditionalFragment showIf={canHaveDriverMetrics()}>
                                            <NavItem>
                                                <NavLink active={activeTab === 'driverMetrics'} onClick={() => setActiveTab('driverMetrics')}>
                                                    <FontAwesomeIcon icon="user-tag" className="nav-icon" />
                                                    {t('editQuestion.driverMetrics', ' Recommend')}
                                                    <ConditionalFragment showIf={hasAnyValidationErrors([validationErrors['driverMetricRecommendations'], validationErrors['driverMetricAdjustments']])}>
                                                        <> </><FontAwesomeIcon icon="exclamation-triangle" color="danger" />
                                                    </ConditionalFragment>
                                                </NavLink>
                                            </NavItem>
                                        </ConditionalFragment>
                                        <NavItem>
                                            <NavLink active={activeTab === 'tags'} onClick={() => setActiveTab('tags')}>
                                                <FontAwesomeIcon icon="tags" className="nav-icon" />
                                                {t('editQuestion.tags', ' Tags')}
                                            </NavLink>
                                        </NavItem>
                                    </PillsNavBar>
                                </StickyToolbar>
                            </Col>
                        </Row>
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
            </Banner>

            <MainContainer>
                <AlertOnErrors errors={[
                    loadErrors, saveFormErrors, saveErrors, loadSupportingDataErrors,
                    uploadVideoBlobErrors, uploadImageBlobErrors, uploadQuestionTextImageBlobErrors,
                    uploadChildBlobErrors,
                    loadAllQuestionsErrors,
                ]} />

                <Form onSubmit={e => { e.preventDefault(); saveForm(); }}>
                    <FormGroup>
                        <Label htmlFor="name">{t('editQuestion.name', 'Name')}</Label>
                        <ValidatedInput name="name" type="text" value={model.name ?? ''} onChange={e => change({ name: e.currentTarget.value })} onBlur={e => validate('name')} validationErrors={validationErrors['name']} />
                    </FormGroup>

                    <>
                        {/* Tab contents */}
                        <ConditionalFragment showIf={activeTab === 'main'}>
                            {/* Quesiton type select is shown on the main tab for all question type categories that support the user changing it. */}
                            <ConditionalFragment showIf={activeQuestionTypeCategory === QuestionTypeCategory.Question}>
                                <FormGroup>
                                    <Label htmlFor="questionType">{t('editQuestion.questionType.question', 'Type of question')}</Label>
                                    <ValidatedInput name="questionType" type="select" value={model.questionType ?? ''} onChange={e => change({ questionType: e.currentTarget.value })} onBlur={e => validate('questionType')} validationErrors={validationErrors['questionType']}>
                                        <option value="">{t('editQuestion.questionType.pleaseSelect.quesiton', '(Please select a type for this question)')}</option>
                                        {
                                            getQuestionTypes(activeQuestionTypeCategory).map(item => (
                                                <option key={item} value={item}>
                                                    {questionTypeDisplayName(item, t)}
                                                </option>
                                            ))
                                        }
                                    </ValidatedInput>
                                </FormGroup>

                                <FormGroup>
                                    <Label htmlFor="questionType">{t('editQuestion.driverMetricId.label', 'Primary safety metric')}</Label>
                                    <ValidatedInput name="driverMetricId" type="select" value={model.driverMetricId ?? ''} onChange={e => change({ driverMetricId: e.currentTarget.value || null })} onBlur={e => validate('driverMetricId')} validationErrors={validationErrors['driverMetricId']}>
                                        <option value={""}>{t('editQuestion.driverMetricId.pleaseSelect.quesiton', '(No primary safety metric)')}</option>
                                        {
                                            driverMetrics?.map(item => (
                                                <option key={item.id} value={item.id}>
                                                    {item.name}
                                                </option>
                                            ))
                                        }
                                    </ValidatedInput>
                                </FormGroup>
                            </ConditionalFragment>

                             <ConditionalFragment showIf={model?.questionType === QuestionTypeCategory.Questionnaire}>
                                <FormGroup>
                                    <SequenceOfQuestionnaireQuestionsTab
                                        model={model}
                                        driverMetrics={driverMetrics}
                                        change={change}
                                        validate={validate}
                                        validationErrors={validationErrors}
                                        questionSequenceQuestionsManager={questionSequenceQuestionsManager}
                                        questionnaireQuestionAnswersManager={questionnaireQuestionAnswersManager}
                                        validateQuestionAnswer={validateQuestionAnswer}
                                        questionAnswerValidationErrors={questionAnswerValidationErrors}
                                        refresh={refreshEdit}
                                        questionnaireQuestionsManager={questionnaireQuestionsManager}
                                    />
                                </FormGroup>
                             </ConditionalFragment>

                            <ConditionalFragment showIf={activeQuestionTypeCategory === QuestionTypeCategory.Learning}>
                                <FormGroup>
                                    <Label htmlFor="questionType">{t('editQuestion.questionType.learning', 'Type of learning')}</Label>
                                    <ValidatedInput name="questionType" type="select" value={model.questionType ?? ''} onChange={e => change({ questionType: e.currentTarget.value })} onBlur={e => validate('questionType')} validationErrors={validationErrors['questionType']}>
                                        <option value="">{t('editQuestion.questionType.pleaseSelect.learning', '(Please select a type for this learning activity)')}</option>
                                        {
                                            getQuestionTypes(activeQuestionTypeCategory).map(item => (
                                                <option key={item} value={item}>
                                                    {questionTypeDisplayName(item, t)}
                                                </option>
                                            ))
                                        }
                                    </ValidatedInput>
                                </FormGroup>
                            </ConditionalFragment>

                            <ConditionalFragment showIf={activeQuestionTypeCategory === QuestionTypeCategory.Informtion}>
                                <FormGroup>
                                    <Label htmlFor="questionType">{t('editQuestion.questionType.information', 'Type of information page')}</Label>
                                    <ValidatedInput name="questionType" type="select" value={model.questionType ?? ''} onChange={e => change({ questionType: e.currentTarget.value })} onBlur={e => validate('questionType')} validationErrors={validationErrors['questionType']}>
                                        <option value="">{t('editQuestion.questionType.pleaseSelect.information', '(Please select a type for this information page)')}</option>
                                        {
                                            getQuestionTypes(activeQuestionTypeCategory).map(item => (
                                                <option key={item} value={item}>
                                                    {questionTypeDisplayName(item, t)}
                                                </option>
                                            ))
                                        }
                                    </ValidatedInput>
                                </FormGroup>
                            </ConditionalFragment>

                            {/* NOTE other QuestionTypeCategories do not support a dropdown to change their type. */}

                            {/* Everything else on the main tab is dependant on the question type  */}
                            <ConditionalFragment showIf={isVideoQuestion()}>
                                <VideoTab
                                    model={model}
                                    videoBlob={videoBlob} uploadVideoBlob={uploadVideoBlob} isUploadingVideoBlob={isUploadingVideoBlob} clearVideoBlob={clearVideoBlob}
                                    imageBlob={imageBlob} uploadImageBlob={uploadImageBlob} isUploadingImageBlob={isUploadingImageBlob} clearImageBlob={clearImageBlob}
                                />
                            </ConditionalFragment>

                            <ConditionalFragment showIf={isImageQuestion()}>
                                <ImageTab
                                    model={model} change={change} activeQuestionType={activeQuestionType}
                                    imageBlob={imageBlob} uploadImageBlob={uploadImageBlob} isUploadingImageBlob={isUploadingImageBlob} clearImageBlob={clearImageBlob}
                                />
                            </ConditionalFragment>

                            <ConditionalFragment showIf={activeQuestionTypeCategory === QuestionTypeCategory.Learning}>
                                <LearningTextTab
                                    model={model}
                                    change={change}
                                />
                            </ConditionalFragment>

                            <ConditionalFragment showIf={activeQuestionTypeCategory === QuestionTypeCategory.Informtion}>
                                <InformationTextTab
                                    model={model}
                                    change={change}
                                />
                            </ConditionalFragment>

                            <ConditionalFragment showIf={model?.questionType === QuestionType.SequenceOfQuestions}>
                                <SequenceOfQuestionsTab
                                    model={model}
                                    questionSequenceQuestionsManager={questionSequenceQuestionsManager}
                                    allQuestions={allQuestions}
                                    isLoadingAllQuestions={isLoadingAllQuestions}
                                    ensureAllQuestionsLoaded={ensureAllQuestionsLoaded}
                                    />
                            </ConditionalFragment>
                        </ConditionalFragment>

                        <ConditionalFragment showIf={activeTab === 'questionPrompt'}>
                            <ConditionalFragment showIf={model?.questionType === QuestionType.MultipleChoiceVideo}>
                                <QuestionPromptMultipleChoiceVideoTab
                                    model={model} change={change}
                                    questionTextImageBlob={questionTextImageBlob} uploadQuestionTextImageBlob={uploadQuestionTextImageBlob} isUploadingQuestionTextImageBlob={isUploadingQuestionTextImageBlob} clearQuestionTextImageBlob={clearQuestionTextImageBlob}
                                    answersManager={answersManager}
                                    childBlobs={childBlobs} uploadChildBlob={uploadChildBlob}
                                    validateQuestionAnswer={validateQuestionAnswer}
                                    questionAnswerValidationErrors={questionAnswerValidationErrors}
                                />
                            </ConditionalFragment>
                            <ConditionalFragment showIf={model?.questionType === QuestionType.SlidingScaleVideo}>
                                <QuestionPromptSlidingScaleVideoTab
                                    model={model} change={change}
                                    questionTextImageBlob={questionTextImageBlob} uploadQuestionTextImageBlob={uploadQuestionTextImageBlob} isUploadingQuestionTextImageBlob={isUploadingQuestionTextImageBlob} clearQuestionTextImageBlob={clearQuestionTextImageBlob}
                                    answersManager={answersManager}
                                    childBlobs={childBlobs} uploadChildBlob={uploadChildBlob}
                                    validateQuestionAnswer={validateQuestionAnswer}
                                    questionAnswerValidationErrors={questionAnswerValidationErrors}
                                />
                            </ConditionalFragment>
                            <ConditionalFragment showIf={model?.questionType === QuestionType.ResponseTimeVideo}>
                                <QuestionPromptResponseTimeVideoTab
                                    model={model} change={change}
                                    videoBlob={videoBlob} imageBlob={imageBlob}
                                    answersManager={answersManager}
                                    validateQuestionAnswer={validateQuestionAnswer}
                                    questionAnswerValidationErrors={questionAnswerValidationErrors}
                                />
                            </ConditionalFragment>
                            <ConditionalFragment showIf={model?.questionType === QuestionType.LocationResponseImage}>
                                <QuestionPromptLocationResponseImageTab
                                    model={model} change={change}
                                    imageBlob={imageBlob}
                                    answersManager={answersManager}
                                    validateQuestionAnswer={validateQuestionAnswer}
                                    questionAnswerValidationErrors={questionAnswerValidationErrors}
                                />
                            </ConditionalFragment>
                            <ConditionalFragment showIf={model?.questionType === QuestionType.LocationDiscriminationImage}>
                                <QuestionPromptLocationDiscriminationImageTab
                                    model={model} change={change}
                                    imageBlob={imageBlob}
                                    answersManager={answersManager}
                                    validateQuestionAnswer={validateQuestionAnswer}
                                    questionAnswerValidationErrors={questionAnswerValidationErrors}
                                />
                            </ConditionalFragment>
                            <ConditionalFragment showIf={model?.questionType === QuestionType.MultipleChoiceImage}>
                                <QuestionPromptMultipleChoiceImageTab
                                    model={model} change={change}
                                    answersManager={answersManager}
                                    childBlobs={childBlobs} uploadChildBlob={uploadChildBlob}
                                    validateQuestionAnswer={validateQuestionAnswer}
                                    questionAnswerValidationErrors={questionAnswerValidationErrors}
                                />
                            </ConditionalFragment>
                        </ConditionalFragment>

                        <ConditionalFragment showIf={activeTab === 'feedback'}>
                            <FeedbackTab
                                model={model}
                                feedbacksManager={feedbacksManager}
                                childBlobs={childBlobs} uploadChildBlob={uploadChildBlob}
                                validateQuestionFeedback={validateQuestionFeedback}
                                questionFeedbackValidationErrors={questionFeedbackValidationErrors}
                            />
                        </ConditionalFragment>

                        <ConditionalFragment showIf={activeTab === 'driverMetrics'}>
                            <DriverMetricsTab
                                model={model}
                                driverMetricAdjustmentsManager={driverMetricAdjustmentsManager}
                                driverMetricRecommendationsManager={driverMetricRecommendationsManager}
                                driverMetrics={driverMetrics}
                                validateDriverMetricRecommendation={validateDriverMetricRecommendation}
                                driverMetricRecommendationValidationErrors={driverMetricRecommendationValidationErrors}
                                validateDriverMetricAdjustment={validateDriverMetricAdjustment}
                                driverMetricAdjustmentValidationErrors={driverMetricAdjustmentValidationErrors}
                            />
                        </ConditionalFragment>

                        <ConditionalFragment showIf={activeTab === 'tags'}>
                            <QuestionTagsTab
                                model={model}
                                questionTags={questionTags}
                                linksManager={questionTagLinksManager}
                            />
                        </ConditionalFragment>
                    </>


                    <FormButtons>
                        <ConditionalFragment showIf={!isLoading}>
                            <ButtonAsync color="primary" isExecuting={isSaving}
                                executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}>
                                <FontAwesomeIcon icon="save" />
                                <> {t('common.save', 'Save')}</>
                            </ButtonAsync>
                        </ConditionalFragment>
                        <Button type="button" color="primary" outline onClick={e => history.goBack()}>
                            {t('common.cancel', 'Cancel')}
                        </Button>
                    </FormButtons>
                </Form>
            </MainContainer>
        </Background>
    );
};
