import { RouteEntry } from "../../shared/routes";
import { SubscriptionTeamsList } from './SubscriptionTeamsList';
import { EditSubscriptionTeam, CreateSubscriptionTeam } from "./EditSubscriptionTeam";
import { IdentityRoles } from "../../configure/security/IdentityRoles";
import { DeleteSubscriptionTeam } from "./DeleteSubscriptionTeam";

export const subscriptionTeamRoutes: Array<RouteEntry> = [
    { path: '/manage/teams', exact: true, component: SubscriptionTeamsList, authorize: true, requireRole: IdentityRoles.DriverManagement },
    { path: '/manage/teams/edit/:id', component: EditSubscriptionTeam, authorize: true, requireRole: IdentityRoles.DriverManagement },
    { path: '/manage/teams/add', component: CreateSubscriptionTeam, authorize: true, requireRole: IdentityRoles.DriverManagement },
    { path: '/manage/teams/delete/:id', component: DeleteSubscriptionTeam, authorize: true, requireRole: IdentityRoles.DriverManagement },
];
