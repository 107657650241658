import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useMemo, useRef, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Button, Col, Row } from "reactstrap";
import { QuestionAnswer } from "../../../../../api/main/models/QuestionAnswer";
import { HtmlDisplay } from "../../../../../shared/htmlEditor";
import { usePrefetchImages } from "../../../../../shared/prefetchImage";
import { ShowQuestionChildProps } from "../ShowQuestion";
import "./showQuestionMultipleChoiceVideo.scss";

/**
 * Running of a MultipleChoiceVideo question.
 * @param props
 */
export const ShowQuestionMultipleChoiceVideo = (props: ShowQuestionChildProps) => {
    const {
        model,
        answers,
        blobReferences,

        selectedAnswerId,
        selectSingleAnswer,
        questionResponseEventsManager,

        resetResponseTimeStart,

        onPageComplete,
    } = props;

    const { t } = useTranslation();

    const videoBlob = useMemo(() => blobReferences.find(it => it.id === model.videoBlobReferenceId), [blobReferences, model]);
    const imageBlob = useMemo(() => blobReferences.find(it => it.id === model.imageBlobReferenceId), [blobReferences, model]);
    const questionTextImageBlob = useMemo(() => blobReferences.find(it => it.id === model.questionTextImageBlobReferenceId), [blobReferences, model]);

    // At the end, with the question, we either want to show a specific image set to show with the question, or the original thumbnail again.
    const endImageBlob = questionTextImageBlob ?? imageBlob;

    // Keep track of if the video has started/finished or not.
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const [hasVideoStarted, setHasVideoStarted] = useState<boolean>(false);
    const [hasVideoFinished, setHasVideoFinished] = useState<boolean>(false);

    // Some calls to apis (such as saving clicks) will cause us to generate a new secure video URL.  To make sure this doesn't reset out
    // video we cache the poster and video urls as we original receive them into our state and always use them.
    const [imageUrl] = useState<string>(imageBlob?.url ?? '');
    const [videoUrl] = useState<string>(videoBlob?.url ?? '');
    const [endImageUrl] = useState<string>(endImageBlob?.url ?? '');

    // Prefetch the images we use.
    const resolveImageUrl = usePrefetchImages([
        imageUrl,
        endImageUrl,
        ...answers.map(answer => {
            const blob = blobReferences.find(item => item.id === answer.answerTextImageBlobReferenceId);
            return blob?.url ?? '';
        }),
    ]);

    // Start the video.
    const startVideo = useCallback(() => {
        setHasVideoStarted(true);
        videoRef.current?.play();
    }, [videoRef, setHasVideoStarted]);

    // Video has ended.
    const onVideoEnded = useCallback(() => {
        setHasVideoFinished(true);
        resetResponseTimeStart();
    }, [setHasVideoFinished, resetResponseTimeStart]);

    const onSelectSingleAnswer = useCallback((answer: QuestionAnswer | undefined) => {
        selectSingleAnswer(answer, model);

        // Let the navigation know this question has been completed.
        onPageComplete();
    }, [selectSingleAnswer, onPageComplete, model]);

    return (
        <div className="show-question-multiple-choice-video">

            {/* Video has not started */}
            <ConditionalFragment showIf={!hasVideoStarted}>
                <div className="show-question-multiple-choice-video-background" style={{ backgroundImage: `url("${resolveImageUrl(imageUrl) ?? ''}")` }}>
                    <div className="show-question-multiple-choice-video-start-overlay"
                        onClick={() => startVideo()}
                    >
                        <Row>
                            <Col>
                            </Col>
                            <Col xs="auto">
                                <HtmlDisplay html={model.preQuestionText} />

                                <FontAwesomeIcon icon="play-circle" className="show-question-multiple-choice-video-start-overlay-play-icon" />
                                <p className="text-muted">
                                    {t('showQuestionMultipleChoiceVideo.playText', 'Press play to start the video.')}
                                </p>
                            </Col>
                            <Col>
                            </Col>
                        </Row>
                    </div>
                </div>
            </ConditionalFragment>

            {/* Video has started but is not yet finished */}
            <ConditionalFragment showIf={!hasVideoFinished}>
                <div className="embed-responsive embed-responsive-16by9 mb-4" style={{ display: !hasVideoStarted? 'none': undefined }}>
                    <video
                        className={"show-question-multiple-choice-video-fullscreen"}
                        disablePictureInPicture
                        ref={videoRef}
                        src={videoUrl}
                        poster={resolveImageUrl(imageUrl)}
                        playsInline={true}
                        controls={false}
                        onEnded={() => onVideoEnded()}
                    >
                    </video>
                </div>
            </ConditionalFragment>

            {/* Video has finished */}
            <ConditionalFragment showIf={hasVideoFinished}>
                <div className="show-question-multiple-choice-video-background" style={{ backgroundImage: `url("${resolveImageUrl(endImageUrl) ?? ''}")` }}>
                    <div className="show-question-multiple-choice-video-answer-overlay">
                        <HtmlDisplay html={model.questionText} />

                        <Row>
                            {
                                answers.map(answer => {
                                    const answerBlob = blobReferences.find(it => it.id === answer.answerTextImageBlobReferenceId);

                                    return (
                                        <Col key={answer.id} xs={12} md={6}>
                                            <Button className="show-question-multiple-choice-video-answer-button"
                                                color={selectedAnswerId === answer.id? 'primary': 'secondary'}

                                                onClick={() => onSelectSingleAnswer(answer)}
                                            >
                                                <ConditionalFragment showIf={!!answerBlob || !!endImageUrl}>
                                                    <img className="fluid-img" src={resolveImageUrl(answerBlob?.url ?? endImageUrl ?? '')} alt="" style={{ maxWidth: '100%'}} />
                                                </ConditionalFragment>

                                                <HtmlDisplay html={answer.answerText} />
                                            </Button>
                                        </Col>
                                    );
                                })
                            }
                        </Row>

                        <ConditionalFragment showIf={!!questionResponseEventsManager.model.filter(item => item.questionId === model.id && !item.archived).length}>
                            <p className="text-muted">
                                {t('showQuestionMultipleChoiceVideo.endText', 'Press Next to confirm your answer and continue.')}
                            </p>
                        </ConditionalFragment>
                    </div>
                </div>
            </ConditionalFragment>
        </div>
        );
};