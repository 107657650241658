import { LoginResult } from "../../api/account";
import { AsyncActionStatus } from "../../shared/abstractStore";
import { useAsyncCallback } from "react-use-async-callback";
import { StudyLogoutRequest } from "./AccountService";
import { useAccountService } from "./useAccountService";

/**
 * Callback to log out of a study.
 */
export function useStudyLogoutCallback(): [(model: StudyLogoutRequest) => Promise<LoginResult>, AsyncActionStatus] {
    const accountService = useAccountService();

    const [action, { isExecuting, errors }] = useAsyncCallback(async (model: StudyLogoutRequest) => {
        let result = await accountService.studyLogout(model);

        // If we get here we have a successful logout.
        return result;
    }, [accountService]);

    return [action, { isExecuting, errors }];
}
