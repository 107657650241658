import { IdentityRoles } from "../../../configure/security/IdentityRoles";
import { RouteEntry } from "../../../shared/routes";
import { ImportDrivers } from "./ImportDrivers";
import { ImportManagers } from "./ImportManagers";
import { ImportUserAssessmentAssignmentsBase } from "./ImportUserAssessmentAssignmentBase";


export const importUsersRoutes: Array<RouteEntry> = [
    { path: '/manage/drivers/import', exact: true, component: ImportDrivers, authorize: true, requireRole: [IdentityRoles.DriverManagement, IdentityRoles.SubscriptionAdministration] },
    { path: '/manage/drivers/importUserAssessmentAssignments', exact: true, component: ImportUserAssessmentAssignmentsBase, authorize: true, requireRole: [IdentityRoles.DriverManagement, IdentityRoles.SubscriptionAdministration] },
    { path: '/manage/managers/import', exact: true, component: ImportManagers, authorize: true, requireRole: [IdentityRoles.DriverManagement, IdentityRoles.SubscriptionAdministration] },

    { path: '/manage/teams/details/:subscriptionTeamId/drivers/import', exact: true, component: ImportDrivers, authorize: true, requireRole: [IdentityRoles.DriverManagement, IdentityRoles.SubscriptionAdministration] },
    { path: '/manage/teams/details/:subscriptionTeamId/managers/import', exact: true, component: ImportManagers, authorize: true, requireRole: [IdentityRoles.DriverManagement, IdentityRoles.SubscriptionAdministration] },
];
