import { useParams } from "react-router";
import { useCurrentUserOrEmulatedSubscriptionId } from "../../../globalState/subscriptions/useCurrentUserOrEmulatedSubscriptionId";
import { CreateUserBase, EditUserBase } from "../EditUserBase";

/**
 * Create a driver.
 */
export const CreateDriver = () => {
    const { subscriptionTeamId } = useParams<{ subscriptionTeamId: string | undefined }>();

    const subscriptionId = useCurrentUserOrEmulatedSubscriptionId();

    return (
        <CreateUserBase
            onCreateDefaultValues={() => ({ subscriptionId: subscriptionId, subscriptionTeamId: subscriptionTeamId ?? undefined, })}
            defaultRoleGroup="Driver"
            filterRoleGroups={(groups) => groups.filter(item => item.id === 'Driver')}
        />);
};

/**
 * Edit a driver.
 */
export const EditDriver = () => (
    <EditUserBase
        defaultRoleGroup="Driver"
        filterRoleGroups={(groups) => groups.filter(item => item.id === 'Driver')}
    />);
