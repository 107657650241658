import { overallRiskService, RiskRules } from "../../../../services/OverallRiskService";
import { QuestionScore } from "./calculateQuestionScore";

/**
 * Calculate the question score for a question.  Result will always be between 0 and 10.
 */
export function combineQuestionScores(scores: Array<QuestionScore>, riskRules?: RiskRules | undefined | null): QuestionScore  {
    let totalScore: number = 0;
    if (scores) {
        for (const score of scores) {
            totalScore += score.score;
        }

        totalScore = Math.round(totalScore / scores.length)

        if (totalScore < 0) {
            totalScore = 0;
        }

        if (totalScore > 100) {
            totalScore = 100;
        }

        const riskCategory = overallRiskService.getRiskCategory(totalScore, false, riskRules);


        return {
            score: totalScore,
            minScore: 0,
            maxScore: 100,
            riskCategory,
        };
    }

    else {
        const riskCategory = overallRiskService.getRiskCategory(0, true, riskRules);
        return {
            score: 0,
            minScore: 0,
            maxScore: 100,
            riskCategory,
        };
    }
}