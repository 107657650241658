import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { deleteQuestionTagLinkMutation, deleteQuestionTagLinkMutationVariables } from '../generated/deleteQuestionTagLinkMutation';
import { questionTagLinkFieldsFragment } from '../models/QuestionTagLink';

/**
 * Get a callback to delete a QuestionTagLink in the store.
 */
export function useDeleteQuestionTagLinkCallback(): [(id: string) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<deleteQuestionTagLinkMutation, deleteQuestionTagLinkMutationVariables>(
        gql`
            mutation deleteQuestionTagLinkMutation ($id: ID!) {
                deleteQuestionTagLink(id: $id) {
                    ...questionTagLinkFields
                }
            }

            ${questionTagLinkFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (id: string) => {
        const result = await mutationAction({ variables: { id: id } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
