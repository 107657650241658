import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { initializeAssessmentSessionMutation, initializeAssessmentSessionMutationVariables } from '../generated/initializeAssessmentSessionMutation';
import { AssessmentSession, assessmentSessionFieldsFragment } from '../models/AssessmentSession';

/**
 * Get a callback to create and initialize a AssessmentSession in the store.
 */
export function useInitializeAssessmentSessionCallback(): [(assessmentId: string, userId: string) => Promise<AssessmentSession | undefined | null>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<initializeAssessmentSessionMutation, initializeAssessmentSessionMutationVariables>(
        gql`
            mutation initializeAssessmentSessionMutation ($assessmentId: ID!, $userId: ID!) {
                initializeAssessmentSession(assessmentId: $assessmentId, userId: $userId) {
                    ...assessmentSessionFields
                }
            }

            ${assessmentSessionFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (assessmentId: string, userId: string) => {
        const result = await mutationAction({ variables: { assessmentId: assessmentId, userId: userId } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
        return result.data?.initializeAssessmentSession;
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
