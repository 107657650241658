import { useTranslation } from "react-i18next";
import { AssessmentType } from "../../../api/main/models/codeOnly/AssessmentType";
import { SubscriptionAssessmentsListBase } from "../../subscriptionAssessments/SubscriptionAssessmentsListBase";
import { useParams } from "react-router";
import { StudyEditNavigation } from './StudyEditNavigation';

/**
 * List of actual assessments.
 */
export const StudyAssessmentsList = () => {
    const { t } = useTranslation();

    const { subscriptionId } = useParams<{ subscriptionId: string; }>();

    const baseRoute = '/administration/assessments';

    return (
        <SubscriptionAssessmentsListBase
            title={t('studyAssessmentsList.title', 'Licensed assessments')}
            mobileColumn1Name={t('studyAssessmentsList.mobileColumn1Name', 'Assessment')}
            assessmentTypes={[AssessmentType.Assessment]}
            baseRoute={baseRoute}
            navigationPills={<StudyEditNavigation id={subscriptionId ?? ''} />}
            isStudy={true}
        />
    );
};