import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChangeEvent, useCallback, useRef, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Button, Input, InputGroup, InputGroupAddon, InputProps } from "reactstrap";
import { useToggleState } from "use-toggle-state";

export interface InputSelectOrTextProps extends InputProps {
}

/**
 * Input component that knows how to toggle itself between being a select and being a text input to add new items.
 * @param props
 */
export const InputSelectOrText = (props: InputSelectOrTextProps) => {
    const {
        onChange,
        children,
        ...rest
    } = props;

    const { t } = useTranslation();

    const [isTextMode, toggleTextMode] = useToggleState();
    const [text, setText] = useState<string>('');
    const lastTextChangeEvent = useRef<ChangeEvent<HTMLInputElement>>();

    // Save the text entered in textMode (by raising the onChange event) and exist text mode.
    const saveText = useCallback(() => {
        // If we have an onChange event, call it now as if the text had been typed.
        if (onChange && lastTextChangeEvent.current) {
            onChange({
                ...lastTextChangeEvent.current,
                currentTarget: {
                    ...(lastTextChangeEvent.current.currentTarget ?? {}),
                    value: text,
                },
            });
        }

        // Toggle out of text mode.
        toggleTextMode();
    }, [toggleTextMode, onChange, lastTextChangeEvent, text]);

    return (
        <InputGroup>
            <ConditionalFragment showIf={!isTextMode}>
                <Input type="select" onChange={onChange} {...rest}>
                    {children}
                </Input>
                <InputGroupAddon addonType="append">
                    <Button onClick={() => toggleTextMode()}>
                        <FontAwesomeIcon icon="plus" />
                        <span className="sr-only">{t('inputSelectOrText.add', 'Add')}</span>
                    </Button>
                </InputGroupAddon>
            </ConditionalFragment>
            <ConditionalFragment showIf={isTextMode}>
                <Input type="text" {...rest}
                    value={text}
                    onChange={e => {
                        setText(e.currentTarget.value);
                        lastTextChangeEvent.current = { ...e };
                    }}
                />
                <InputGroupAddon addonType="append">
                    <Button color="success" onClick={() => saveText()}>
                        <FontAwesomeIcon icon="check" />
                        <span className="sr-only">{t('inputSelectOrText.save', 'Save')}</span>
                    </Button>
                    <Button color="danger" onClick={() => { toggleTextMode(); setText(''); }}>
                        <FontAwesomeIcon icon="times" />
                        <span className="sr-only">{t('inputSelectOrText.cancel', 'Cancel')}</span>
                    </Button>
                </InputGroupAddon>
            </ConditionalFragment>
        </InputGroup>
    );
};