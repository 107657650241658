import { useTranslation } from "react-i18next";
import { AssessmentType } from "../../../api/main/models/codeOnly/AssessmentType";
import { DashboardSummary } from "../utilities/generateDashboardSummary";
import { AverageScoreProgressBar } from "./AverageScoreProgressBar";

export interface AssessmentAverageScoreProgressBarProps {
    summary: DashboardSummary,
}

/**
 * Show an average score for assessments as a progress bar, with supporting data around it.
 */
export const AssessmentAverageScoreProgressBar = (props: AssessmentAverageScoreProgressBarProps) => {
    const {
        summary,
    } = props;

    const { t } = useTranslation();

    return (
        <AverageScoreProgressBar
            assessmentType={AssessmentType.Assessment}
            summary={summary}
            title={t('assessmentsAverageScoreProgressBar.title', 'Assessments')}
            footer={t('assessmentsAverageScoreProgressBar.footer', 'Based on {{count}} assessments', { count: summary.uniqueSessionsCount })}
        />
        );
};