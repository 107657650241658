import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Guid } from "guid-string";
import { useCallback, MouseEvent, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Button, Input, InputGroup, InputGroupAddon, InputProps, Tooltip } from "reactstrap";
import { useToggleState } from "use-toggle-state";
import "./secretInput.scss";

export interface SecretInputProps extends InputProps {
    valueWhenHidden?: string | number | readonly string[] | undefined,
}

/**
 * Input that hides it's contents as a secret unless revealed.
 */
export const SecretInput = (props: SecretInputProps) => {
    const {
        value,
        valueWhenHidden,
        readOnly = true, // Default is readOnly unless overriden.
        onClick: _onClick,
        ...rest
    } = props;

    const { t } = useTranslation();

    // Visiblity of the secret item.
    const [isVisible, toggleIsVisible] = useToggleState();

    // Need a uniqueId for the input so the tooltip can work.
    const uniqueId = useMemo(() => `secret-input-${Guid.newGuid()}`, []);

    // Toggle if we're showing the "copied" tooltip.
    const [copiedTooltipIsOpen, toggleCopiedTooltipOpen] = useToggleState();

    // Copy the secret value to the clipboard.
    const copyToClipboard = useCallback(() => {
        try {
            navigator.clipboard.writeText(value?.toString() ?? '');
            toggleCopiedTooltipOpen(true);
            setTimeout(() => toggleCopiedTooltipOpen(false), 2000);
        } catch (error) {
        }
    }, [value, toggleCopiedTooltipOpen]);

    // For the onClick event want to use the passed in event if we have one, otherwise we want to copy to the clipboard on click.
    const onClick = useCallback((event: MouseEvent<HTMLInputElement>) => {
        if (_onClick) {
            _onClick(event);
        } else {
            copyToClipboard();
        }
    }, [_onClick, copyToClipboard]);

    // Render the UI
    //
    return (
        <>
            <InputGroup className="secret-input">
                <Input id={uniqueId}
                    value={isVisible ? value : (valueWhenHidden ?? t('secretInput.valueWhenHidden.default', '[Hidden]') as string)}
                    readOnly={readOnly}
                    onClick={onClick}
                    {...rest}
                />
                <InputGroupAddon addonType="append">
                    <Button outline onClick={() => toggleIsVisible()}>
                        <FontAwesomeIcon icon={isVisible ? 'eye' : 'eye-slash'} />
                        <span className="sr-only">{t('secretInput.toggleVisibility', 'Toggle secret visibility')}</span>
                    </Button>
                </InputGroupAddon>
                <InputGroupAddon addonType="append">
                    <Button outline onClick={() => copyToClipboard()}>
                        <FontAwesomeIcon icon="copy" />
                        <span className="sr-only">{t('secretInput.copy', 'Copy secret')}</span>
                    </Button>
                    <Tooltip target={uniqueId} placement="bottom">
                        Hello
                    </Tooltip>
                </InputGroupAddon>
            </InputGroup>
            <Tooltip target={uniqueId} placement="bottom" isOpen={copiedTooltipIsOpen}>
                {t('secretInput.copiedToClipboard', 'Copied to clipboard')}
            </Tooltip>
        </>
        );
};