import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useMemo, useRef, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Button, Card, Col, Row } from "reactstrap";
import { HtmlDisplay } from "../../../../../shared/htmlEditor";
import { usePrefetchImages } from "../../../../../shared/prefetchImage";
import { ShowQuestionChildProps } from "../ShowQuestion";
import { useClickSound } from "../useClickSound/useClickSound";
import "./showQuestionResponseTimeVideo.scss";

/**
 * Running of a ResponseTimeVideo question.
 * @param props
 */
export const ShowQuestionResponseTimeVideo = (props: ShowQuestionChildProps) => {
    const {
        model,
        answers,
        blobReferences,

        addResponseEvent,
        questionResponseEventsManager,
        hasFailedMaximumClickRule,
        isMaximumClickRuleFailtureRecord,

        cancelAllButHighestResponse,

        resetResponseTimeStart,

        onPageComplete,
    } = props;

    const { t } = useTranslation();

    const videoBlob = useMemo(() => blobReferences.find(it => it.id === model.videoBlobReferenceId), [blobReferences, model]);
    const imageBlob = useMemo(() => blobReferences.find(it => it.id === model.imageBlobReferenceId), [blobReferences, model]);
    const questionTextImageBlob = useMemo(() => blobReferences.find(it => it.id === model.questionTextImageBlobReferenceId), [blobReferences, model]);

    // At the end, with the question, we either want to show a specific image set to show with the question, or the original thumbnail again.
    const endImageBlob = questionTextImageBlob ?? imageBlob;

    // Keep track of if the video has started/finished or not.
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const [hasVideoStarted, setHasVideoStarted] = useState<boolean>(false);
    const [hasVideoFinished, setHasVideoFinished] = useState<boolean>(false);

    // Some calls to apis (such as saving clicks) will cause us to generate a new secure video URL.  To make sure this doesn't reset out
    // video we cache the poster and video urls as we original receive them into our state and always use them.
    const [imageUrl] = useState<string>(imageBlob?.url ?? '');
    const [videoUrl] = useState<string>(videoBlob?.url ?? '');
    const [endImageUrl] = useState<string>(endImageBlob?.url ?? '');

    // Prefetch the images we use.
    const resolveImageUrl = usePrefetchImages([
        imageUrl,
        endImageUrl,
        ...answers.map(answer => {
            const blob = blobReferences.find(item => item.id === answer.answerTextImageBlobReferenceId);
            return blob?.url ?? '';
        }),
    ]);

    // Start the video.
    const startVideo = useCallback(() => {
        setHasVideoStarted(true);
        resetResponseTimeStart();
        videoRef.current?.play();
    }, [videoRef, setHasVideoStarted, resetResponseTimeStart]);

    // Video has ended.
    const onVideoEnded = useCallback(() => {
        setHasVideoFinished(true);
        resetResponseTimeStart();

        // Correct the scoring so it only takes the highest response (should never be undefinded here but we handle it anyway)
        if (!!cancelAllButHighestResponse && questionResponseEventsManager.added.length > 0) {
            cancelAllButHighestResponse();
        }

        // Let the navigation know this question has been completed.
        onPageComplete();
    }, [setHasVideoFinished, resetResponseTimeStart, onPageComplete, cancelAllButHighestResponse, questionResponseEventsManager]);


    // Video playback tracking.
    const currentVideoTime = useRef<number>(0);

    // Handle the user responding (a click).
    const [isShowingClickIndicator, setIsShowingClickIndicator] = useState<boolean>(false);
    const [playClickSound] = useClickSound();
    const onResponseClickEvent = useCallback(() => {
        const window = answers.find(item => item.startResponseTimeSeconds <= currentVideoTime.current && currentVideoTime.current < item.endResponseTimeSeconds);

        // If we are outside a window, we will be applying a default score of 0.
        const defaultScoreOutsideOfWindow = 0;

        addResponseEvent({
            questionAnswerId: window?.id,
            score: window?.score ?? defaultScoreOutsideOfWindow,
            isCorrect: window?.isCorrect ?? false,
            responseTimeSeconds: currentVideoTime.current,
            subQuestionNumber: window?.subQuestionNumber ?? 1,
        });

        playClickSound();
        setIsShowingClickIndicator(true);
        setTimeout(() => setIsShowingClickIndicator(false), 200);
    }, [addResponseEvent, answers, currentVideoTime, playClickSound, setIsShowingClickIndicator]);

    return (
        <div className="show-question-response-time-video">

            {/* Video has not started */}
            <ConditionalFragment showIf={!hasVideoStarted}>
                <div className="show-question-response-time-video-background" style={{ backgroundImage: `url("${resolveImageUrl(imageUrl ?? '')}")` }}>
                    <div className="show-question-response-time-video-start-overlay"
                        onClick={() => startVideo()}
                    >
                        <HtmlDisplay html={model.preQuestionText} />

                        <Row>
                            <Col>
                            </Col>
                            <Col xs="auto">
                                <FontAwesomeIcon icon="play-circle" className="show-question-response-time-video-start-overlay-play-icon" />
                                <p className="text-muted">
                                    {t('showQuestionResponseTimeVideo.playText', 'Press play to start the video.')}
                                </p>
                            </Col>
                            <Col>
                            </Col>
                        </Row>
                    </div>
                </div>
            </ConditionalFragment>

            {/* Video has started but is not yet finished */}
            <ConditionalFragment showIf={!hasVideoFinished}>
                <div className="embed-responsive embed-responsive-16by9 mb-4" style={{ display: !hasVideoStarted? 'none': undefined }}>
                    <video
                        className={`embed-responsive-item show-question-response-time-video ${isShowingClickIndicator ? 'show-question-response-time-video-show-click-indicator' : ''} ${'show-question-response-time-video-full-screen'}`}
                        ref={videoRef}
                        src={videoUrl}
                        poster={resolveImageUrl(imageUrl)}
                        disablePictureInPicture
                        controls={false}
                        playsInline={true}
                        onEnded={() => onVideoEnded()}
                        onTimeUpdate={e => { currentVideoTime.current = e.currentTarget.currentTime; }}
                        onClick={e => {
                            e.preventDefault();

                            onResponseClickEvent()
                        }}
                        onDoubleClick={e => {
                            e.preventDefault();

                            onResponseClickEvent()
                        }}
                    >
                    </video>
                </div>
            </ConditionalFragment>

            {/* Video has finished */}
            <ConditionalFragment showIf={hasVideoFinished}>
                <div className="show-question-response-time-video-background" style={{ backgroundImage: `url("${resolveImageUrl(endImageUrl ?? '')}")` }}>
                    <div className="show-question-response-time-video-answer-overlay">
                        <HtmlDisplay html={model.questionText} />

                        <p className="text-muted">
                            {t('showQuestionResponseTimeVideo.endText', 'Press Next to continue.')}
                        </p>
                    </div>
                </div>
            </ConditionalFragment>
        </div>
        );
};