import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { deleteAssessmentMutation, deleteAssessmentMutationVariables } from '../generated/deleteAssessmentMutation';
import { assessmentFieldsFragment } from '../models/Assessment';

/**
 * Get a callback to delete a Assessment in the store.
 */
export function useDeleteAssessmentCallback(): [(id: string) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<deleteAssessmentMutation, deleteAssessmentMutationVariables>(
        gql`
            mutation deleteAssessmentMutation ($id: ID!) {
                deleteAssessment(id: $id) {
                    ...assessmentFields
                }
            }

            ${assessmentFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (id: string) => {
        const result = await mutationAction({ variables: { id: id } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
