import { useCallback, useMemo } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Button, ButtonGroup, Col, Container, NavItem, NavLink, Row } from "reactstrap";
import { AssessmentType } from "../../../api/main/models/codeOnly/AssessmentType";
import { useSubscriptionDashboardViewModel } from "../../../api/main/dashboard/viewModels/useSubscriptionDashboardViewModel";
import { AlertOnErrors } from "../../../shared/alertOnErrors";
import { Background } from "../../shared/background/Background";
import { Banner } from "../../shared/Banner";
import { PillsNavBar } from "../../shared/pillsNavBar/PillsNavBar";
import { LoadingIndicator } from "../../shared/LoadingIndicator";
import { UserAssessmentAssignment } from "../../../api/main/models/UserAssessmentAssignment";
import { AssessmentSession } from "../../../api/main/models/AssessmentSession";
import { generateDashboardSummary as _generateDashboardSummary } from "../utilities/generateDashboardSummary";
import { SubscriptionUsersDashboardTab } from "../tabs/SubscriptionUsersDashboardTab";
import { AssessmentsDashboardTab } from "../tabs/AssessmentsDashboardTab";
import { TrainingDashboardTab } from "../tabs/TrainingDashboardTab";
import { useParams } from "react-router";
import { useCurrentUserOrEmulatedSubscriptionId } from "../../../globalState/subscriptions/useCurrentUserOrEmulatedSubscriptionId";
import { useExpandedUserAssessmentAssignments } from "../utilities/useExpandedUserAssessmentAssignments";
import { useActiveDashboardAssessmentType } from "../../../globalState/activeDashboardAssessmentType/useActiveDashboardAssessmentType";
import { useActiveDashboardTab } from "../../../globalState/activeDashboardTab/useActiveDashboardTab";
import { DashboardBackButton } from "../backButton/DashboardBackButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export type SubscriptionTeamDashboardTabs = 'people' | 'assessments' | 'training';

/**
 * Dashboard for a subscription.
 */
export const SubscriptionTeamDashboard = () => {
    // Work out the subscription to show for.
    const { subscriptionId: subscriptionIdParam, subscriptionTeamId } = useParams<{ subscriptionId: string | undefined, subscriptionTeamId: string }>();
    const mySubscriptionId = useCurrentUserOrEmulatedSubscriptionId();
    const subscriptionId = subscriptionIdParam ?? mySubscriptionId;

    const { t } = useTranslation();

    const {
        data: {
            subscriptionTeams: allSubscriptionTeams,
            profiles: allProfiles,
            subscriptionAssessments,
            assessments: allAssessments,
            assessmentSessions: allAssmentSessions,
            userAssessmentAssignments: allUserAssessmentAssignments,
            model: subscription,
            userDriverMetrics: allUserDriverMetrics,
        },
        isLoading,
        errors: loadErrors
    } = useSubscriptionDashboardViewModel(subscriptionId);

    const subscriptionTeam = useMemo(() => allSubscriptionTeams?.find(it => it.id === subscriptionTeamId), [allSubscriptionTeams, subscriptionTeamId]);

    // Filter the profiles down to only this team.
    const profiles = useMemo(() => allProfiles?.filter(item => item.subscriptionTeamId === subscriptionTeamId), [allProfiles, subscriptionTeamId]);
    const assessmentSessions = useMemo(() => allAssmentSessions?.filter(item => !!profiles?.find(it => it.userId === item.userId)), [allAssmentSessions, profiles]);
    const userAssessmentAssignments = useMemo(() =>
        allUserAssessmentAssignments
            ?.filter(item => {
                // If it is for the team keep it.
                if (item.subscriptionTeamId === subscriptionTeamId) {
                    return true;
                }

                // If it is for a member of the team keep it.
                if (!!profiles?.find(it => it.userId === item.userId)) {
                    return true;
                }

                // Otherwise we don't want it.
                return false;
            })
            , [allUserAssessmentAssignments, subscriptionTeamId, profiles]);

    // The subscription is only licensed for some assessments, so remove any that we're not licensed for now
    // and also remove any that have not either been used or assigned to this team.
    const assessments = useMemo(() =>
        allAssessments
            ?.filter(assessment => !!subscriptionAssessments?.find(it => it.assessmentId === assessment.id))
            ?.filter(assessment =>
                !!assessmentSessions?.find(it => it.assessmentId === assessment.id)
                || !!userAssessmentAssignments?.find(it => it.assessmentId === assessment.id)
        )
        , [allAssessments, subscriptionAssessments, userAssessmentAssignments, assessmentSessions]);

    // Expand the UserAssessmentAssignments so we can work with them easier.
    const expandedUserAssessmentAssignments = useExpandedUserAssessmentAssignments(userAssessmentAssignments, profiles);

    // Allow the generation of dashboard summary data.
    const generateDashboardSummary = useCallback((
        sessionFilter: (session: AssessmentSession) => boolean,
        assignmentFilter: (assignment: UserAssessmentAssignment) => boolean,
        assessmentType: AssessmentType) => {
        // Filter by the passed in fitler.
        let mySessions = assessmentSessions?.filter(sessionFilter) ?? [];
        let myAssignments = expandedUserAssessmentAssignments?.filter(assignmentFilter) ?? [];

        // Filter by the passed in type.
        mySessions = mySessions.filter(session => {
            const assessment = assessments?.find(it => it.id === session.assessmentId);
            if (!assessment) {
                return false;
            }

            return assessment.assessmentType === assessmentType;
        });

        myAssignments = myAssignments.filter(session => {
            const assessment = assessments?.find(it => it.id === session.assessmentId);
            if (!assessment) {
                return false;
            }

            return assessment.assessmentType === assessmentType;
        });

        // Filter the driver metrics to contain only users that have assessments or assignments.
        const myUserDriverMetrics = allUserDriverMetrics?.filter(
            udm => !!mySessions.find(it => it.userId === udm.userId) || !!myAssignments.find(it => it.userId === udm.userId)
        ) ?? [];

        // Generate the summary data.
        const ret = _generateDashboardSummary(mySessions, assessments ?? [], profiles ?? [], myAssignments, myUserDriverMetrics ?? [], subscription);
        return ret;
    }, [assessmentSessions, assessments, profiles, expandedUserAssessmentAssignments, subscription, allUserDriverMetrics]);


    // Track the active tab.
    const [activeTab, setActiveTab] = useActiveDashboardTab<SubscriptionTeamDashboardTabs>('subscriptionTeamDashboard', 'people');

    // Track the active assessment type.
    const [activeAssessmentType, setActiveAssessmentType] = useActiveDashboardAssessmentType();
    const hideAssessmentTypeToggle = activeTab === 'assessments' || activeTab === 'training';

    return (
        <Background>
            <Banner fluid>
                <Row>
                    <Col xs={12} md="auto">
                        <h1>
                            <DashboardBackButton />
                            {
                                t('subscriptionTeamDashboard.heading', '{{name}} dashboard', { name: subscriptionTeam?.name })
                            }
                        </h1>
                    </Col>
                    <Col>
                        <PillsNavBar textColor="white" className="mb-2">
                            <NavItem>
                                <NavLink active={activeTab === 'people'} onClick={() => setActiveTab('people')}>
                                    <FontAwesomeIcon icon="user" />
                                    <> </>
                                    {t('subscriptionTeamDashboard.tabs.people', 'People')}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink active={activeTab === 'assessments'} onClick={() => setActiveTab('assessments')}>
                                    <FontAwesomeIcon icon="clipboard-check" />
                                    <> </>
                                    {t('subscriptionTeamDashboard.tabs.assessments', 'Assessments')}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink active={activeTab === 'training'} onClick={() => setActiveTab('training')}>
                                    <FontAwesomeIcon icon="graduation-cap" />
                                    <> </>
                                    {t('subscriptionTeamDashboard.tabs.training', 'Training')}
                                </NavLink>
                            </NavItem>
                        </PillsNavBar>
                    </Col>
                    <ConditionalFragment showIf={!hideAssessmentTypeToggle}>
                        <Col xs="auto">
                            <ButtonGroup>
                                <Button outline={activeAssessmentType !== AssessmentType.Assessment} onClick={() => setActiveAssessmentType(AssessmentType.Assessment)}>
                                    {t('subscriptionTeamDashboard.toggle.assessments', 'Assessments')}
                                </Button>
                                <Button outline={activeAssessmentType !== AssessmentType.TrainingModule} onClick={() => setActiveAssessmentType(AssessmentType.TrainingModule)}>
                                    {t('subscriptionTeamDashboard.toggle.training', 'Training')}
                                </Button>
                            </ButtonGroup>
                        </Col>
                    </ConditionalFragment>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
            </Banner>
            <Container fluid>
                <AlertOnErrors errors={[loadErrors]} />

                <ConditionalFragment showIf={activeTab === 'people'}>
                    <SubscriptionUsersDashboardTab
                        profiles={profiles ?? []}
                        generateDashboardSummary={generateDashboardSummary}
                        activeAssessmentType={activeAssessmentType}

                        assessmentSessions={assessmentSessions ?? []}
                        assessments={assessments ?? []}
                        riskRules={subscription}
                    />
                </ConditionalFragment>

                <ConditionalFragment showIf={activeTab === 'assessments'}>
                    <AssessmentsDashboardTab
                        assessments={assessments ?? []}
                        generateDashboardSummary={generateDashboardSummary}
                        activeAssessmentType={activeAssessmentType}

                        assessmentSessions={assessmentSessions ?? []}
                        riskRules={subscription}
                    />
                </ConditionalFragment>

                <ConditionalFragment showIf={activeTab === 'training'}>
                    <TrainingDashboardTab
                        assessments={assessments ?? []}
                        generateDashboardSummary={generateDashboardSummary}
                        activeAssessmentType={activeAssessmentType}

                        assessmentSessions={assessmentSessions ?? []}
                        riskRules={subscription}
                    />
                </ConditionalFragment>
            </Container>
        </Background>
    );
};
