import { IdentityRoles } from "../../configure/security/IdentityRoles";
import { RouteEntry } from "../../shared/routes";
import { DeleteSdkClient } from "./sdkClients/DeleteSdkClient";
import { CreateSdkClient, EditSdkClient } from "./sdkClients/EditSdkClient";
import { SdkOverview } from "./SdkOverview";
import { DeleteSdkWebhook } from "./sdkWebhooks/DeleteSdkWebhook";
import { CreateSdkWebhook, EditSdkWebhook } from "./sdkWebhooks/EditSdkWebhook";

export const sdkRoutes: Array<RouteEntry> = [
    { path: '/manage/sdk', exact: true, component: SdkOverview, authorize: true, requireRole: IdentityRoles.SubscriptionAdministration },

    // SdkClient
    { path: '/manage/sdk/applications/add', exact: true, component: CreateSdkClient, authorize: true, requireRole: IdentityRoles.SubscriptionAdministration },
    { path: '/manage/sdk/applications/edit/:id', exact: true, component: EditSdkClient, authorize: true, requireRole: IdentityRoles.SubscriptionAdministration },
    { path: '/manage/sdk/applications/delete/:id', exact: true, component: DeleteSdkClient, authorize: true, requireRole: IdentityRoles.SubscriptionAdministration },

    // SdkWebhook
    { path: '/manage/sdk/webhooks/add', exact: true, component: CreateSdkWebhook, authorize: true, requireRole: IdentityRoles.SubscriptionAdministration },
    { path: '/manage/sdk/webhooks/edit/:id', exact: true, component: EditSdkWebhook, authorize: true, requireRole: IdentityRoles.SubscriptionAdministration },
    { path: '/manage/sdk/webhooks/delete/:id', exact: true, component: DeleteSdkWebhook, authorize: true, requireRole: IdentityRoles.SubscriptionAdministration },
];
