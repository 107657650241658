import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Guid } from "guid-string";
import { ValidationErrors } from "pojo-validator";
import { ValidateCallback } from "pojo-validator-react";
import { ValidatedInput } from "pojo-validator-reactstrap";
import { useCallback } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { useAsyncCallback } from "react-use-async-callback";
import { Button, ButtonGroup, Card, CardBody, Col, Collapse, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, FormText, Label, Row, Spinner} from "reactstrap";
import { useToggleState } from "use-toggle-state";
import { AssessmentFeedback } from "../../../../api/main/models/AssessmentFeedback";
import { BlobReference } from "../../../../api/main/models/BlobReference";
import { HtmlEditor } from "../../../../shared/htmlEditor";
import { CardHeaderCollapse } from "../../../shared/cardHeaderCollapse/CardHeaderCollapse";
import { FileUploadButton } from "../../../shared/fileUploadButton/FileUploadButton";
import { FormButtons } from "../../../shared/FormButtons";
import { UploadedImagePreview } from "../../../shared/uploadedImagePreview/UploadedImagePreview";
import { UploadedVideoPreview } from "../../../shared/uploadedVideoPreview/UploadedVideoPreview";

export interface FeedbackProps {
    model: AssessmentFeedback | undefined,
    change: (changes: Partial<AssessmentFeedback>) => void,
    remove: () => void,

    videoBlob: BlobReference | undefined | null,
    imageBlob: BlobReference | undefined | null,
    uploadChildBlob: (files: FileList | null) => Promise<BlobReference | null>,

    isOpenInitially?: boolean,

    validate: ValidateCallback,
    validationErrors: ValidationErrors,
}

/**
 * An item of feedback for a question.
 * @param props
 */
export const Feedback = (props: FeedbackProps) => {
    const {
        model,
        change,
        remove,

        videoBlob,
        imageBlob,
        uploadChildBlob,
        isOpenInitially = false,

        validate,
        validationErrors,
    } = props;

    const { t } = useTranslation();

    // Self management of if we are expanded or not.
    const [isOpen, toggleOpen] = useToggleState(isOpenInitially);

    // Uploading the video blob.
    const [uploadVideoBlob, { isExecuting: isUploadingVideoBlob }] = useAsyncCallback(async (files: FileList | null) => {
        const blob = await uploadChildBlob(files);
        if (!blob) {
            return;
        }

        change({ videoBlobReferenceId: blob.id });
    }, [uploadChildBlob, change]);
    const clearVideoBlob = useCallback(() => {
        change({ videoBlobReferenceId: null });
    }, [change]);

    // Uploading the image blob.
    const [uploadImageBlob, { isExecuting: isUploadingImageBlob }] = useAsyncCallback(async (files: FileList | null) => {
        const blob = await uploadChildBlob(files);
        if (!blob) {
            return;
        }

        change({ imageBlobReferenceId: blob.id });
    }, [uploadChildBlob, change]);
    const clearImageBlob = useCallback(() => {
        change({ imageBlobReferenceId: null });
    }, [change]);


    const [isBlobDropdownOpen, toggleBlobDropdown] = useToggleState();

    return (
        <Card>
            <CardHeaderCollapse isOpen={isOpen} toggle={toggleOpen}>
                <div style={{ maxHeight: '2rem', overflowY: 'hidden', }}>
                    {t('feedback.heading.assessment', 'Feedback when total score is between {{minTotalScore}}% and {{maxTotalScore}}%', { minTotalScore: model?.minTotalScore ?? 0, maxTotalScore: model?.maxTotalScore ?? 0})}
                </div>
            </CardHeaderCollapse>

            <Collapse isOpen={isOpen}>
                <CardBody>
                    <FormButtons noPadding>
                        <ButtonGroup>
                            <Button color="danger" outline onClick={() => remove()}>
                                <FontAwesomeIcon icon="trash-alt" />
                                <> </>
                                {t('feedback.delete', 'Delete feedback')}
                            </Button>
                        </ButtonGroup>
                    </FormButtons>

                    <FormGroup>
                        <Label htmlFor="feedbackText">{t('feedback.feedbackText.label', 'Feedback')}</Label>
                        <HtmlEditor value={model?.feedbackText ?? ''} onChange={html => change({ feedbackText: html })} />
                        <FormText>
                            <Label htmlFor="feedbackText">{t('feedback.feedbackText.formText', 'You can use the placeholders {score} and {maxScore} to subsitute the user\'s score into the above text, e.g. You scored {score}% out of {maxScore}%.')}</Label>
                        </FormText>
                    </FormGroup>

                    <Row>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="minScore">{t('feedback.minScore.label', 'Min total score')}</Label>

                                <ValidatedInput name="minScore" type="number" value={model?.minTotalScore ?? ''} onChange={e => change({ minTotalScore: e.currentTarget.valueAsNumber })} onBlur={() => validate('minScore')} validationErrors={validationErrors['minScore']} />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="maxScore">{t('feedback.maxScore.label', 'Max total score')}</Label>

                                <ValidatedInput name="maxScore" type="number" value={model?.maxTotalScore ?? ''} onChange={e => change({ maxTotalScore: e.currentTarget.valueAsNumber })} onBlur={() => validate('maxScore')} validationErrors={validationErrors['maxScore']} />
                            </FormGroup>
                        </Col>
                    </Row>


                    <FormGroup>
                        <Label htmlFor="imageBlobReferenceId">{t('feedback.answerTextImageId', 'Image or video to show with the answer')}</Label>

                        {/* If we have no video show the image upload area, but including an option to upload a video. */}
                        <ConditionalFragment showIf={Guid.isEmpty(model?.videoBlobReferenceId)}>
                            <UploadedImagePreview src={imageBlob?.url ?? ''} />

                            <ButtonGroup>
                                <FileUploadButton onUpload={files => uploadImageBlob(files)} isExecuting={isUploadingImageBlob}
                                    executingChildren={<><Spinner size="sm" /><> </>{t('common.uploading', 'Uploading...')}</>}
                                >
                                    {t('feedback.uploadImage', 'Upload image...')}
                                </FileUploadButton>
                                <FileUploadButton onUpload={files => uploadVideoBlob(files)} isExecuting={isUploadingVideoBlob}
                                    executingChildren={<><Spinner size="sm" /><> </>{t('common.uploading', 'Uploading...')}</>}
                                >
                                    {t('feedback.uploadVideo', 'Upload video...')}
                                </FileUploadButton>
                                <Dropdown isOpen={isBlobDropdownOpen} toggle={() => toggleBlobDropdown()}>
                                    <DropdownToggle color="primary" outline caret>
                                        <span className="sr-only">
                                            {t('common.more', 'More')}
                                        </span>
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                        <DropdownItem className="text-danger" onClick={() => clearImageBlob()}>
                                            {t('feedback.clearImage', 'Clear image')}
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </ButtonGroup>
                        </ConditionalFragment>

                        {/* If have a video show the video upload experience.  User can return to the image upload experience by clearning the video.. */}
                        <ConditionalFragment showIf={!Guid.isEmpty(model?.videoBlobReferenceId)}>
                            <UploadedVideoPreview src={videoBlob?.url ?? ''} poster={imageBlob?.url} />

                            <ButtonGroup>
                                <FileUploadButton onUpload={files => uploadVideoBlob(files)} isExecuting={isUploadingVideoBlob}
                                    executingChildren={<><Spinner size="sm" /><> </>{t('common.uploading', 'Uploading...')}</>}
                                >
                                    {t('feedback.uploadVideo', 'Upload video...')}
                                </FileUploadButton>
                                <FileUploadButton onUpload={files => uploadImageBlob(files)} isExecuting={isUploadingImageBlob}
                                    executingChildren={<><Spinner size="sm" /><> </>{t('common.uploading', 'Uploading...')}</>}
                                >
                                    {t('feedback.uploadThumnail', 'Upload thumbnail...')}
                                </FileUploadButton>
                                <Dropdown isOpen={isBlobDropdownOpen} toggle={() => toggleBlobDropdown()}>
                                    <DropdownToggle color="primary" outline caret>
                                        <span className="sr-only">
                                            {t('common.more', 'More')}
                                        </span>
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                        <DropdownItem className="text-danger" onClick={() => clearVideoBlob()}>
                                            <FontAwesomeIcon icon="folder-minus" />
                                            <> </>
                                            {t('feedback.clearVideo', 'Clear video')}
                                        </DropdownItem>
                                        <DropdownItem className="text-danger" onClick={() => clearImageBlob()}>
                                            <FontAwesomeIcon icon="folder-minus"/>
                                            <> </>
                                            {t('feedback.clearThumbnail', 'Clear thumbnail')}
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </ButtonGroup>
                        </ConditionalFragment>
                    </FormGroup>
                </CardBody>
            </Collapse>
        </Card>
        );
};