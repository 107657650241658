import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Button, FormGroup, FormText, Label } from "reactstrap";
import { BlobReference } from "../../../../api/main/models/BlobReference";
import { ModelArrayChanges } from "../../../../shared/useChanges";
import { Feedback } from "./Feedback";
import { ValidationErrors } from "pojo-validator";
import { Assessment } from "../../../../api/main/models/Assessment";
import { AssessmentFeedback, assessmentFeedbackDefaultValues } from "../../../../api/main/models/AssessmentFeedback";
import { ValidatedInput } from "pojo-validator-reactstrap";
import { FeedbackStyle, feedbackStyleDisplayName } from "../../../../api/main/models/codeOnly/FeedbackStyle";
import { AssessmentType } from "../../../../api/main/models/codeOnly/AssessmentType";
import { ValidateCallback } from "pojo-validator-react";

export interface FeedbackTabProps {
    model: Assessment | undefined,
    change: (changes: Partial<Assessment>) => void,
    activeAssessmentType: AssessmentType,

    validate: ValidateCallback,
    validationErrors: ValidationErrors,

    feedbacksManager: ModelArrayChanges<AssessmentFeedback, string>,

    childBlobs: Array<BlobReference>,
    uploadChildBlob: (files: FileList | null) => Promise<BlobReference | null>,

    validateAssessmentFeedback: (model: AssessmentFeedback) => boolean,
    assessmentFeedbackValidationErrors: (id: string) => ValidationErrors,
}

/**
 * Tab for maintaining the feedback based on the question scores.
 * @param props
 */
export const FeedbackTab = (props: FeedbackTabProps) => {
    const {
        model,
        change,
        activeAssessmentType,

        validate,
        validationErrors,

        feedbacksManager,
        childBlobs,
        uploadChildBlob,

        validateAssessmentFeedback,
        assessmentFeedbackValidationErrors,
    } = props;

    const { t } = useTranslation();

    // Order the feedback so they are shown based on their minimum score.
    const orderedFeedbacks = useMemo(() => {
        let ret = [...feedbacksManager.model];

        // Sort by min score, then max score.
        ret.sort((a, b) => (a.minTotalScore === b.minTotalScore ? (a.maxTotalScore === b.maxTotalScore? 0: a.maxTotalScore < b.maxTotalScore? -1: 1) : a.minTotalScore < b.minTotalScore ? -1 : 1));

        return ret;
    }, [feedbacksManager.model]);
    

    // Adding of feedback.
    const addFeedback = useCallback(() => {
        feedbacksManager.addFor({
            ...assessmentFeedbackDefaultValues(),

            assessmentId: model?.id,
        });
    }, [feedbacksManager, model?.id]);

    return (
        <>
            <FormGroup>
                <Label htmlFor="assessmentType">{t('editAssessmentBase.feedbackStyle.label', 'Feedback style')}</Label>
                <ValidatedInput name="feedbackStyle" type="select" value={model?.feedbackStyle ?? ''} onChange={e => change({ feedbackStyle: e.currentTarget.value })} onBlur={e => validate('feedbackStyle')} validationErrors={validationErrors['feedbackStyle']}>
                    {
                        Object.values(FeedbackStyle)
                            .map(item => (
                                <option key={item} value={item}>
                                    {feedbackStyleDisplayName(item, t)}
                                </option>
                            ))
                    }
                </ValidatedInput>
                <FormText>
                    {
                        activeAssessmentType === AssessmentType.TrainingModule ? t('editAssessmentBase.feedbackStyle.formText.learning', 'This controls how the user is given feedback on the Finish page and throughout the learning module.')
                            : t('editAssessmentBase.feedbackStyle.formText.assessment', 'This controls how the user is given feedback on the Finish page and throughout the assessment.')
                    }
                </FormText>
            </FormGroup>

            <FormGroup>
                <Label htmlFor="feedbacks">{t('feedbackTab.feedbacks', 'Overall feedback')}</Label>

                <div>
                    {
                        orderedFeedbacks.map(item => (
                            <Feedback key={item.id}
                                model={item}
                                change={changes => feedbacksManager.changeFor(item.id, changes)}
                                remove={() => feedbacksManager.removeFor(item.id)}

                                isOpenInitially={!!feedbacksManager.added.find(it => it.id === item.id)}

                                videoBlob={childBlobs.find(it => it.id === item.videoBlobReferenceId)}
                                imageBlob={childBlobs.find(it => it.id === item.imageBlobReferenceId)}
                                uploadChildBlob={uploadChildBlob}

                                validate={() => validateAssessmentFeedback(item)}
                                validationErrors={assessmentFeedbackValidationErrors(item.id)}
                                />
                            ))
                    }
                </div>

                <Button color="primary" outline onClick={() => addFeedback()}>
                    {t('feedbackTab.addFeedback', 'Add feedback range')}
                </Button>
            </FormGroup>
        </>
        );
};
