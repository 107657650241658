import { useCurrentUserProfile } from '../../api/main/profiles/useCurrentUserProfile';
import { useSubscriptionEmulation } from './useSubscriptionEmulation';


/**
 * Returns the current user's subscriptionId, or if the user is currently emulating a different subscription, the emulated subscription.
 */
export function useCurrentUserOrEmulatedSubscriptionId(): string | null | undefined {
    const [emualtedSubscriptionId] = useSubscriptionEmulation();
    const { data: { model: currentUserProfile } } = useCurrentUserProfile();

    return emualtedSubscriptionId ?? currentUserProfile?.subscriptionId;
}