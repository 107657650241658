import * as React from 'react';
import { AssessmentSessionCreateInput, AssessmentSessionUpdateInput } from '../generated/globalTypes';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useCreateAssessmentSessionCallback } from './useCreateAssessmentSessionCallback';
import { useUpdateAssessmentSessionCallback } from './useUpdateAssessmentSessionCallback';
import { AssessmentSession } from '../models/AssessmentSession';

/**
 * Get a callback to save a AssessmentSession in the store using either a create or update.
 */
export function useSaveAssessmentSessionCallback(options: SaveInStoreOptions<AssessmentSession, string> = {}) {
    const [_create, createStatus] = useCreateAssessmentSessionCallback();
    const create = React.useCallback((model: Partial<AssessmentSession>) => _create(model as AssessmentSessionCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateAssessmentSessionCallback();
    const update = React.useCallback((id: string, changes: Partial<AssessmentSession>) => _update(id, changes as AssessmentSessionUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
