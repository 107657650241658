import { AssessmentSession } from "../../../api/main/models/AssessmentSession";

/**
 * Returns the latest session for each user assignment combination.
 */
export function getLatestSessions(
    sessions: Array<AssessmentSession>,
) {
    // Get only the latest (complete) session for each user and assessment pair.
    let latestSessions: Array<AssessmentSession> = [];
    for (const session of sessions) {
        // If the session is not complete, ignore it.
        if (!session.isFinished) {
            continue;
        }

        // If we already have a session for this assesmsnet/user combination, then we only want to keep
        // the one finished most recently.
        const existingSession = latestSessions.find(item => item.assessmentId === session.assessmentId && item.userId === session.userId);
        if (existingSession) {
            if (existingSession.startDate < session.startDate) {
                const index = latestSessions.indexOf(existingSession);
                latestSessions[index] = session;
            }
        } else {
            latestSessions.push(session);
        }
    }

    let latestSessionsIncludingIncomplete: Array<AssessmentSession> = [];
    for (const session of sessions) {
        // If we already have a session for this assesmsnet/user combination, then we only want to keep
        // the one finished most recently.
        const existingSession = latestSessionsIncludingIncomplete.find(item => item.assessmentId === session.assessmentId && item.userId === session.userId);
        if (existingSession) {
            if (existingSession.startDate < session.startDate) {
                const index = latestSessionsIncludingIncomplete.indexOf(existingSession);
                latestSessionsIncludingIncomplete[index] = session;
            }
        } else {
            latestSessionsIncludingIncomplete.push(session);
        }
    }

    return {
        latestSessions,
        latestSessionsIncludingIncomplete,
    };
}
