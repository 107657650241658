import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { DriverMetric } from "../../../api/main/models/DriverMetric";
import { UserDriverMetric } from "../../../api/main/models/UserDriverMetric";
import { ChartData, ChartDataSeries } from "../chartDataUtilities/ChartData";

/**
 * Data for a chart that shows driver metrics values (totaled).
 */
export function useDriverMetricsChartData(driverMetrics: Array<DriverMetric>, userDriverMetrics: Array<UserDriverMetric>): Array<ChartDataSeries> {
    const { t } = useTranslation();

    const generateSeries = useCallback((seriesName: string) => {
        // Generate chart data for each group.
        const data = driverMetrics?.map(metric => {
            const myUserDriverMetrics = userDriverMetrics?.filter(item => item.driverMetricId === metric.id);
            let totalValue = 0; // NOTE metric.startValue is no longer used.
            if (myUserDriverMetrics?.length > 0) {
                totalValue = myUserDriverMetrics.map(item => item.currentValue).reduce((previousValue, currentValue) => previousValue + currentValue, 0) / myUserDriverMetrics.length;
            }
            
            return {
                text: metric.name,
                value: totalValue,
            } as ChartData;
        });

        return {
            name: seriesName,
            data: data ?? [],
        } as ChartDataSeries;
    }, [driverMetrics, userDriverMetrics]);

    const ret = useMemo(() => {
        return [
            generateSeries(
                t('useDriverMetricsChartData.seriesName', 'Safety metrics'), 
            ),
        ];
    }, [generateSeries, t]);

    return ret;
}
