import './header.scss';

import { Nav, Container } from 'reactstrap';
import { AppHeader, AppSidebarToggler } from '@coreui/react';
import { UserNav } from './UserNav';
import { Link } from 'react-router-dom';
import { Navigation } from '../home/Navigation';
import { AppNavbarBrand } from './appNavbarBrand/AppNavbarBrand';
import { SubscriptionType } from '../../api/main/models/codeOnly/SubscriptionType';
import { ConditionalFragment } from 'react-conditionalfragment';
import { useMemo } from 'react';
import { useSubscription } from '../../api/main/subscriptions/useSubscription';
import { useCurrentUserProfile } from '../../api/main/profiles/useCurrentUserProfile';
import { Guid } from 'guid-string';

export const Header = () => {
    // If the user is part of a study, we don't provide any menu options.
    // NOTE we don't want to loose menus when we are emulating a study subscription, so we always grab it directly from the profile not using the
    // useCurrentUserOrEmulatedSubscriptionId hook.
    const { data: { model: currentProfile } } = useCurrentUserProfile();
    const { data: { model } } = useSubscription(currentProfile?.subscriptionId ?? Guid.empty);
    const showUserNav = useMemo(() => !model || model.subscriptionType !== SubscriptionType.Study, [model]);
    
    return (
        <AppHeader fixed className="header">
            <Container fluid>
                <AppSidebarToggler className="d-lg-none" display="md" mobile />

                <Link to="/">
                    <AppNavbarBrand />
                </Link>
                <AppSidebarToggler className="d-md-down-none flex-shrink" display="lg" />

                <Nav className="menu-nav d-none d-md-flex flex-grow-1">
                    <Navigation />
                </Nav>
                <Nav className="menu-nav-xs d-flex d-md-none flex-grow-1">        
                </Nav>               

                <ConditionalFragment showIf={showUserNav}>
                    <Nav className="signin-nav">
                        <UserNav />
                    </Nav>
                </ConditionalFragment>
                

                {/*<Nav className="language-nav">*/}
                {/*    <NavItem>*/}
                {/*        <LanguageSelect />*/}
                {/*    </NavItem>*/}
                {/*</Nav>*/}
            </Container>
        </AppHeader>
    );
};
