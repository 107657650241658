import gql from "graphql-tag";
import { blobReferenceFields } from "../generated/blobReferenceFields";

export const blobReferenceFieldsFragment = gql`
    fragment blobReferenceFields on BlobReference {
        id
        filename
        mimeType
        storageReference
        archived
        url
    }
`;


export type BlobReference = Omit<blobReferenceFields, '__typename'>;
