import { useTranslation } from "react-i18next";
import { SubscriptionType } from "../../../api/main/models/codeOnly/SubscriptionType";
import { SubscriptionsListBase } from "../SubscriptionsListBase";

/**
 * List of actual studies
 */
export const StudiesList = () => {
    const { t } = useTranslation();

    return (
        <SubscriptionsListBase
            title={t('studiesList.heading', 'Studies')}
            subscriptionTypes={[SubscriptionType.Study]}
            baseRoute={'/administration/studies'}
        />
    );
};