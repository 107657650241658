import * as React from 'react';
import { SdkWebhookCreateInput, SdkWebhookUpdateInput } from '../generated/globalTypes';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useCreateSdkWebhookCallback } from './useCreateSdkWebhookCallback';
import { useUpdateSdkWebhookCallback } from './useUpdateSdkWebhookCallback';
import { SdkWebhook } from '../models/SdkWebhook';

/**
 * Get a callback to save a SdkWebhook in the store using either a create or update.
 */
export function useSaveSdkWebhookCallback(options: SaveInStoreOptions<SdkWebhook, string> = {}) {
    const [_create, createStatus] = useCreateSdkWebhookCallback();
    const create = React.useCallback((model: Partial<SdkWebhook>) => _create(model as SdkWebhookCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateSdkWebhookCallback();
    const update = React.useCallback((id: string, changes: Partial<SdkWebhook>) => _update(id, changes as SdkWebhookUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
