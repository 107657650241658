import { Redirect } from 'react-router';

/**
 * Default Manage home page.
 */
export const ManageHome = () => {
    return (
        <>
            <Redirect to="/manage/drivers" />
        </>
        );
};