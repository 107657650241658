import { RouteEntry } from "../../shared/routes";
import { AdministrationNavigation } from "./AdministrationNavigation";
import { AdministrationHome } from "./AdministrationHome";
import { SidebarRequired } from "../shared/SidebarRequired";
import { IdentityRoles } from "../../configure/security/IdentityRoles";

export const administrationRoutes: Array<RouteEntry> = [
    { path: '/administration', exact: true, component: AdministrationHome, authorize: true, requireRole: IdentityRoles.Administration },
];

export const administrationSidebarRoutes: Array<RouteEntry> = [
    { path: '/administration', component: () => <SidebarRequired><AdministrationNavigation /></SidebarRequired>, authorize: true, requireRole: IdentityRoles.Administration },
];
