import { SubscriptionType } from "../../../api/main/models/codeOnly/SubscriptionType";
import { SubscriptionsListBase } from "../SubscriptionsListBase";
import { useTranslation } from "react-i18next";

/**
 * List of actual subscriptions.
 */
export const SubscriptionsList = () => {
    const { t } = useTranslation();

    return (
        <SubscriptionsListBase
            title={t('subscriptionsList.heading', 'Subscriptions')}
            subscriptionTypes={[SubscriptionType.Subscription]}
            baseRoute={'/administration/subscriptions'}
        />
    );
};