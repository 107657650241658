import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { SubscriptionAssessmentCreateInput } from '../generated/globalTypes';
import { createSubscriptionAssessmentMutation, createSubscriptionAssessmentMutationVariables } from '../generated/createSubscriptionAssessmentMutation';
import { subscriptionAssessmentFieldsFragment } from '../models/SubscriptionAssessment';

/**
 * Get a callback to create a SubscriptionAssessment in the store.
 */
export function useCreateSubscriptionAssessmentCallback(): [(model: SubscriptionAssessmentCreateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<createSubscriptionAssessmentMutation, createSubscriptionAssessmentMutationVariables>(
        gql`
            mutation createSubscriptionAssessmentMutation ($model: SubscriptionAssessmentCreateInput!) {
                createSubscriptionAssessment(model: $model) {
                    ...subscriptionAssessmentFields
                }
            }

            ${subscriptionAssessmentFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (model: SubscriptionAssessmentCreateInput) => {
        const result = await mutationAction({ variables: { model: model } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
