/**
 * Returns a useful formatted time string from the milliseconds passed in.
 * @param milliseconds
 */
export function timeStringFromSeconds(seconds: number) {
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    minutes = minutes % 60;

    const wholeSeconds = Math.floor(seconds % 60);
    const milliseconds = ((seconds % 60) - wholeSeconds);

    const ret = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${wholeSeconds.toString().padStart(2, '0')}.${milliseconds.toString().substr(2, 4).padStart(4, '0')}`;
    return ret;
}