import * as React from 'react';
import { Button, Row, Col, ButtonGroup, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, CardTitle } from 'reactstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { Waypoint } from 'react-waypoint';
import { useReplaceSearchParamsEffect, useSearchParams } from '../../shared/useURLSearchParams';
import { useTranslation } from 'react-i18next';
import { SearchInput } from '../shared/searchInput/SearchInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContainer } from '../shared/MainContainer';
import { NoResultsFound } from '../shared/NoResultsFound';
import { StickyToolbar } from '../shared/StickyToolbar';
import { useHistory, useParams } from 'react-router';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Banner } from '../shared/Banner';
import { useToggleStateArray } from 'use-toggle-state';
import { Background } from '../shared/background/Background';
import { useSubscriptions } from '../../api/main/subscriptions/useSubscriptions';
import { useSubscriptionEmulation } from "../../globalState/subscriptions/useSubscriptionEmulation";
import { CardsOrTable} from '../shared/cardsOrTable/CardsOrTable';
import { usePreferredListViewMode } from '../../globalState/preferredListViewMode/usePreferredListViewMode';
import { SubscriptionType } from '../../api/main/models/codeOnly/SubscriptionType'
import { PillsNavBar } from '../shared/pillsNavBar/PillsNavBar';

export interface SubscriptionListBaseBaseProps {
    title: string,
    subscriptionTypes: Array<SubscriptionType>,
    baseRoute: string,
    distributorSubscriptionList?: boolean,
    navigationPills?: React.ReactNode,
}

/**
 * List of subscriptions.
 */
export const SubscriptionsListBase = (props: SubscriptionListBaseBaseProps) => {
    const {
        title,
        subscriptionTypes,
        baseRoute,
        navigationPills,
        distributorSubscriptionList = false,
    } = props

    const { t } = useTranslation();
    const { search: searchParam } = useSearchParams();
    const [search, setSearch] = React.useState<string>(searchParam ?? '');
    const { data: { items: allItems }, isLoading, errors: loadingErrors, fetchMore, hasMore } = useSubscriptions({ pageSize: undefined, subscriptionTypes: subscriptionTypes});
    const [isMenuOpen, toggleMenuOpen] = useToggleStateArray();
    const history = useHistory();

    const [currentEmulatedSubscriptionId, emulateSubscription] = useSubscriptionEmulation();
    const { subscriptionId } = useParams<{ subscriptionId: string | undefined }>();
    const [subscriptionIdToUse, setSubscriptionIdToUse] = React.useState<string>();

    React.useEffect(() => {

        if (currentEmulatedSubscriptionId === null) {
            setSubscriptionIdToUse(subscriptionId);
        }
        else {
            setSubscriptionIdToUse(currentEmulatedSubscriptionId)
        }


    }, [currentEmulatedSubscriptionId, subscriptionId, setSubscriptionIdToUse]);

    const onManage = React.useCallback((id) => {
        emulateSubscription(id);
    }, [emulateSubscription]);

    const onStopManaging = React.useCallback((id) => {
        emulateSubscription(null);
    }, [emulateSubscription]);

    // Filter by the user's search client side so it can work when offline as well as online.
    const items = React.useMemo(() => {
        let ret = (allItems ?? []);

        if (distributorSubscriptionList) {
            //If we are in a distributor admin area, we only want to see subscriptions relating to the current distributor
            ret = ret.filter(item => item.distributorSubscriptionId === subscriptionIdToUse);

        }

        if (!allItems || !search) {
            return ret;
        }

        let lowerSearch = search.toLocaleLowerCase();

        // Filter the items being displayed.
        return ret.filter(item =>
            item.companyName.toLocaleLowerCase().indexOf(lowerSearch) >= 0
        );
    }, [allItems, search, distributorSubscriptionList, subscriptionIdToUse]);

    useReplaceSearchParamsEffect({ search: search });

    const [viewMode, setViewMode] = usePreferredListViewMode();

    return (
        <Background>
            <Banner fluid>
                <StickyToolbar>
                            <ConditionalFragment showIf={!!navigationPills}>
                                <Row>
                                    <Col xs={12} md="auto">
                                        <h1>
                                            {title}
                                        </h1>
                                    </Col>
                                    <Col>
                                        <PillsNavBar>
                                            {navigationPills}
                                        </PillsNavBar>
                                    </Col>

                                </Row>
                    </ConditionalFragment>
                    <Row>
                        <Col>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs={1} md="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                        <Col>
                            <SearchInput value={search} onChange={e => setSearch(e.currentTarget.value)} />
                        </Col>
                        <Col xs="auto">
                            <LinkContainer to={`${baseRoute}/add`}>
                                <Button color="primary">
                                    <FontAwesomeIcon icon="plus" /><> {t('subscriptionsList.add', 'Add')}</>
                                </Button>
                            </LinkContainer>
                        </Col>
                        <Col xs={12} md="auto">
                            <ButtonGroup>
                                <Button color="secondary" outline={viewMode !== 'cards'} onClick={() => setViewMode('cards')}>
                                    <FontAwesomeIcon icon="th-large" />
                                    <span className="sr-only">{t('common.cards', 'Cards')}</span>
                                </Button>
                                <Button color="secondary" outline={viewMode !== 'table'} onClick={() => setViewMode('table')}>
                                    <FontAwesomeIcon icon="th-list" />
                                    <span className="sr-only">{t('common.list', 'List')}</span>
                                </Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer fluid>
                <AlertOnErrors errors={loadingErrors} />
                <CardsOrTable
                    viewMode={viewMode}
                    items={items}
                    onItemClick={item => history.push(`${baseRoute}/edit/${item.id}`)}
                    tableHeadings={[
                        t('subscriptionsListBase.name', 'Name'),                        
                        t('subscriptionsListBase.userCount', 'User count')
                    ]}
                    columns={[
                        (item, view) => view === 'cards' ? (<CardTitle tag="h5">{item.companyName}</CardTitle>) : item.companyName,
                        (item, view) => view === 'cards' ? (t('subscriptionsListBase.userCountCard', 'User count: ') + item.currentUsers) : item.currentUsers,
                    ]}
                    buttons={(item) => (
                        <ButtonGroup>
                            <LinkContainer to={`${baseRoute}/edit/${item.id}`}>
                                <Button color="primary" outline>
                                    <FontAwesomeIcon icon="edit" />
                                    <> {t('common.edit', 'Edit')}</>
                                </Button>
                            </LinkContainer>
                            <ConditionalFragment showIf={currentEmulatedSubscriptionId !== item.id}>
                                <Button color="primary" outline onClick={() => onManage(item.id)}>
                                    <FontAwesomeIcon icon="clone" />
                                    <> {t('subscriptionList.manage', 'Manage')}</>
                                </Button>
                            </ConditionalFragment>
                            <ConditionalFragment showIf={currentEmulatedSubscriptionId === item.id}>
                                <Button color="primary" onClick={() => onStopManaging(item.id)}>
                                    <FontAwesomeIcon icon="stop" />
                                    <> {t('subscriptionList.stopManaging', 'Stop managing')}</>
                                </Button>
                            </ConditionalFragment>
                            <ButtonDropdown isOpen={isMenuOpen(item.id)} toggle={() => toggleMenuOpen(item.id)}>
                                <DropdownToggle color="primary" outline caret>
                                    <span className="sr-only">{t('common.menuDropdown', 'More')}</span>
                                </DropdownToggle>
                                <DropdownMenu>
                                    <LinkContainer to={`/administration/subscriptions/delete/${item.id}`}>
                                        <DropdownItem className="text-danger">
                                            <FontAwesomeIcon icon="trash" />
                                            <> {t('common.delete', 'Delete')}</>
                                        </DropdownItem>
                                    </LinkContainer>
                                </DropdownMenu>
                            </ButtonDropdown>
                        </ButtonGroup>
                    )}
                />
                <ConditionalFragment showIf={isLoading && !items?.length}>
                    <LoadingIndicator fullWidth />
                </ConditionalFragment>

                <ConditionalFragment showIf={!isLoading && !items?.length}>
                    <NoResultsFound search={search} />
                </ConditionalFragment>

                <ConditionalFragment showIf={!isLoading && hasMore()}>
                    <Waypoint key={items?.length ?? 0} onEnter={fetchMore} />
                    <LoadingIndicator fullWidth />
                </ConditionalFragment>

            </MainContainer>
        </Background>
    );
};
