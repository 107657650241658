import { Button, Col, Row } from "reactstrap";
import { QuestionAnswer } from "../../../../../api/main/models/QuestionAnswer";
import { HtmlDisplay } from "../../../../../shared/htmlEditor";
import { Question } from "../../../../../api/main/models/Question";
import { QuestionResponseEvent } from "../../../../../api/main/models/QuestionResponseEvent";
import { ModelArrayChanges } from "../../../../../shared/useChanges";
import { useCallback } from "react";
import "./showQuestionnaireMultipleChoice.scss";
import { useTranslation } from "react-i18next";



/**
 * Running of a MultipleChoiceVideo question.
 * @param props
 */

export interface ShowQuestionnaireMultipleChoiceProps {

    model: Question
    answers: Array<QuestionAnswer>

    // Selecting an answer.
    selectedAnswerId: string | undefined,
    selectSingleAnswer: (answer: QuestionAnswer | undefined, currentQuestion: Question) => void,

}

export const ShowQuestionnaireMultipleChoice = (props: ShowQuestionnaireMultipleChoiceProps) => {
    const {
        model,
        answers,

        selectedAnswerId,
        selectSingleAnswer,

    } = props;

    const { t } = useTranslation();

    const onSelectSingleAnswer = useCallback((answer: QuestionAnswer | undefined) => {
        selectSingleAnswer(answer, model);

    }, [selectSingleAnswer, model]);

    return (
        <div className="show-questionnaire-multiple-choice">
            <HtmlDisplay html={t(model.questionText)} />

            <Row>
                {
                answers.map(answer => {
                    return (
                        <Col key={answer.id} xs={12} md={6} style={{ paddingTop: '5px', paddingBottom: '5px' }}>
                        <Button className="show-questionnaire-multiple-choice-answer-button"
                                color={selectedAnswerId === answer.id ? 'primary' : 'secondary'}
                            onClick={() => onSelectSingleAnswer(answer)}
                        >

                            <HtmlDisplay html={answer.answerText} />
                        </Button>
                    </Col>
                    );
                })
                }
            </Row>


        </div>
    );
}