import { useCallback, useMemo, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { Form, FormGroup, Label, Input } from "reactstrap";
import { Assessment } from "../../../api/main/models/Assessment";
import { AssessmentSession } from "../../../api/main/models/AssessmentSession";
import { Background } from "../../shared/background/Background";
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MainContainer } from "../../shared/MainContainer";
import { TwoValueSwitch } from "../../shared/TwoValueSwitch";
import { SubscriptionTeam } from "../../../api/main/models/SubscriptionTeam";

export interface ExportAssesmentResultsProps {
    assessments: Array<Assessment>,
    assessmentSessions: Array<AssessmentSession>,
    subscriptionId: string
    subscriptionTeams: Array<SubscriptionTeam>,
}

export const ExportAssessmentResults = (props: ExportAssesmentResultsProps) => {
    const {
        assessments,
        assessmentSessions,
        subscriptionId,
        subscriptionTeams = [],
    } = props

    const { t } = useTranslation();
    const assessmentsWithSessions = assessments.filter(item => assessmentSessions.find(it => it.assessmentId === item.id));

    const [assessmentToUse, setAssessmentToUse] = useState<Assessment>();
    const [filterByTeam, setFilterByTeam] = useState<boolean>();
    const [subscriptionTeamToUse, setSubscriptionTeamToUse] = useState<SubscriptionTeam>();

    const [isDetailed, setisDetailed] = useState<boolean>();
    const [includeDeviceMetrics, setIncludeDeviceMetrics] = useState<boolean>(false);

    const pickAssessment = useCallback((id: string) => {
        if (id !== "") {
            const pickedAssessment = assessmentsWithSessions.find(item => item.id === id);
            setAssessmentToUse(pickedAssessment);
        }
    }, [setAssessmentToUse, assessmentsWithSessions]);

    const exportToUse = useMemo(() => {
        if (isDetailed) {
            return `/api/export/DetailedAssessmentResults?subscriptionId=${encodeURIComponent(subscriptionId ?? '')}&assessmentId=${encodeURIComponent(assessmentToUse?.id ?? '')}&subscriptionTeamId=${encodeURIComponent(subscriptionTeamToUse?.id ?? '')}&includeDeviceMetrics=${encodeURIComponent(includeDeviceMetrics ?? false)}`
        } else {
            return `/api/export/AssessmentResults?subscriptionId=${encodeURIComponent(subscriptionId ?? '')}&assessmentId=${encodeURIComponent(assessmentToUse?.id ?? '')}&subscriptionTeamId=${encodeURIComponent(subscriptionTeamToUse?.id ?? '')}&includeDeviceMetrics=${encodeURIComponent(includeDeviceMetrics ?? false)}`
        }
    }, [isDetailed, subscriptionId, assessmentToUse, subscriptionTeamToUse, includeDeviceMetrics]);

    const pickSubscriptionTeam = useCallback((id:string) => {
        if (id !== "") {
            const pickedSubscriptionTeam = subscriptionTeams.find(item => item.id === id);
            setSubscriptionTeamToUse(pickedSubscriptionTeam)
        }
    }, [subscriptionTeams, setSubscriptionTeamToUse]);
    
    return (
        <Background>
            <MainContainer>
                <Form>
                    <FormGroup>
                            <Label htmlFor="assessment">{t('exportAssessmentResults.assessment', 'Assessment to export')}</Label>
                            <Input name="assessment" type="select" value={assessmentToUse?.id ?? ''} onChange={e => pickAssessment(e.currentTarget.value)}>
                                <option value="">{t('exportAssessmentResults.pleaseSelect.assessment', '(Please select an assessment)')}</option>
                                {
                                    assessmentsWithSessions.map(item => (
                                        <option key={item.name} value={item.id}>
                                            {item.name}
                                        </option>
                                    ))
                                }
                            </Input>
                    </FormGroup>
                    <ConditionalFragment showIf={!!assessmentToUse}>
                        <FormGroup>
                            <Label htmlFor="includeDeviceMetrics">{t('exportAssessmentResults.includeDeviceMetrics.label', 'Include device metrics?')}</Label>

                            <TwoValueSwitch
                                leftLabel={t('exportAssessmentResults.includeDeviceMetrics.false', 'No')}
                                rightLabel={t('exportAssessmentResults.includeDeviceMetrics.true', 'Yes')}
                                checked={includeDeviceMetrics ?? false} onChange={checked => {
                                    // Toggle the flag
                                    setIncludeDeviceMetrics(checked);
                                }}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="isDetailed">{t('exportAssessmentResults.isDetailed.label', 'Breakdown the results by individual answers?')}</Label>

                            <TwoValueSwitch
                                leftLabel={t('exportAssessmentResults.isDetailed.false', 'No')}
                                rightLabel={t('exportAssessmentResults.isDetailed.true', 'Yes')}
                                checked={isDetailed ?? false} onChange={checked => {
                                    // Toggle the flag
                                    setisDetailed(checked)
                                }}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="subscription Team Filter">{t('exportAssessmentResults.subscriptionTeam.yesNo.label', 'Limit export to just one team?')}</Label>
                            <TwoValueSwitch
                                leftLabel={t('exportAssessmentResults.isCorrect.false', 'No')}
                                rightLabel={t('exportAssessmentResults.isCorrect.true', 'Yes')}
                                checked={filterByTeam ?? false} onChange={checked => {
                                    // Toggle the flag
                                    setFilterByTeam(checked);
                                    if (!checked) {
                                        setSubscriptionTeamToUse(undefined);
                                    }
                                }}
                            />
                        </FormGroup>
                        <ConditionalFragment showIf={filterByTeam === true}>
                            <FormGroup>
                                <Label htmlFor="subscription Team">{t('exportAssessmentResults.subscriptionTeam.label', 'Team to export')}</Label>
                                <Input name="assessment" type="select" value={subscriptionTeamToUse?.id ?? ''} onChange={e => pickSubscriptionTeam(e.currentTarget.value)}>
                                    <option value="">{t('exportAssessmentResults.pleaseSelect.subscriptionTeam', '(Please select a team)')}</option>
                                    {
                                        subscriptionTeams.map(item => (
                                            <option key={item.name} value={item.id}>
                                                {item.name}
                                            </option>
                                        ))
                                    }
                                    </Input>
                            </FormGroup>
                            </ConditionalFragment>

                        <a className=" btn btn-outline-primary" href={exportToUse} download>
                                <FontAwesomeIcon icon="download" /> <> {t('exportAssessmentResults.export', 'Export to Excel')} </>
                        </a>

                        </ConditionalFragment>
                </Form>
            </MainContainer>
        </Background>
    );
};