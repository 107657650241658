import * as React from 'react';
import { Button, Row, Col, Form, Label, FormGroup, Spinner, FormText, Alert, Input } from 'reactstrap';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContainer } from '../shared/MainContainer';
import { useParams, useHistory } from 'react-router';
import { useProfile } from '../../api/main/profiles/useProfile';
import { useChanges, useChangesArray } from '../../shared/useChanges';
import { useSaveProfileCallback } from '../../api/main/profiles/useSaveProfileCallback';
import { useValidatorCallback } from 'pojo-validator-react';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { FormButtons } from '../shared/FormButtons';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { useInviteCallback, useChangeAccountEmailCallback, useResendInviteEmailCallback } from '../../api/account';
import { useAsyncCallback } from 'react-use-async-callback';
import { useChangeUserRoleGroupCallback } from '../../api/main/users/useChangeUserRoleGroupCallback';
import { ConditionalFragment } from 'react-conditionalfragment';
import { useProfileSupportingData } from '../../api/main/profiles/useProfileSupportingData';
import { Banner } from '../shared/Banner';
import { Background } from '../shared/background/Background';
import { Profile, profileDefaultValues } from '../../api/main/models/Profile';
import { SubscriptionTeam } from '../../api/main/models/SubscriptionTeam';
import { TwoValueSwitch } from '../shared/TwoValueSwitch';
import { useGenerateUniqueDriverPinCallback } from '../../api/main/profiles/useGenerateUniqueDriverPinCallback';
import { DriverPinService } from '../../services/DriverPinService';
import { PillsNavBar } from '../shared/pillsNavBar/PillsNavBar';
import { DriverNavigation } from './drivers/DriverNavigation';
import { ManagerNavigation } from './managers/ManagerNavigation';
import { AdministratorNavigation } from './administrators/AdministratorNavigation';
import { useSaveSubscriptionCallback } from '../../api/main/subscriptions/useSaveSubscriptionCallback';

export interface EditUserBaseProps {
    isCreate?: boolean,
    onCreateDefaultValues?: () => Partial<Profile>,
    defaultRoleGroup: string,
    filterRoleGroups?: (groups: Array<{ id: string, name: string }>) => Array<{ id: string, name: string }>,
}


/**
 * Create and invite a new user.
 */
export const CreateUserBase = (props: EditUserBaseProps) => (<EditUserBase isCreate={true} {...props} />);

/**
 * Edit a user (actually a profile).
 */
export const EditUserBase = (props: EditUserBaseProps) => {
    const {
        isCreate,
        onCreateDefaultValues,
        defaultRoleGroup,
        filterRoleGroups,
    } = props;

    const { t } = useTranslation();
    const { id } = useParams<{ id: string | undefined }>();
    const { data: { model: storeModel }, isLoading: _isLoading, errors: loadErrors } = useProfile(id);
    const { model, change, changes } = useChanges(storeModel, isCreate ? { ...profileDefaultValues(), ...(onCreateDefaultValues ? onCreateDefaultValues() : {}) } : undefined);
    const { model: userModel, change: changeUserModel } = useChanges(storeModel?.user);
    const { model: roleGroupModel, change: changeRoleGroupModel } = useChanges<{ id: string }>({ id: storeModel?.user?.roleGroup?.id ?? '' }, isCreate ? { id: defaultRoleGroup }: undefined);
    const [save, { errors: saveErrors }] = useSaveProfileCallback();
    const [invite, { errors: inviteErrors }] = useInviteCallback();
    const [resendInviteEmail, { isExecuting: isResendingInviteEmail, errors: resendInviteEmailErrors }] = useResendInviteEmailCallback();
    const [hasResentInviteEmail, setHasResentInviteEmail] = React.useState<boolean>(false);
    const [changeAccountEmail, { errors: changeEmailErrors }] = useChangeAccountEmailCallback();
    const [changeUserRoleGroupInStore, { errors: changeUserRoleGroupInStoreErrors }] = useChangeUserRoleGroupCallback();
    const history = useHistory();
    const [willExceedMaxUsers, setWillExceedMaxUsers] = React.useState<boolean>(false);

    // Supporting data (dependant on the current user's subscription).
    const { data: { roleGroups: storeRoleGroups, subscriptionTeams: storeSubscriptionTeams, subscription }, isLoading: isLoadingSupportingData, errors: loadSupportingDataErrors } = useProfileSupportingData({ subscriptionId: model?.subscriptionId ?? undefined });
    const isLoading = _isLoading || isLoadingSupportingData;

    // Used for updating subscription current users
    const [saveSubscription] = useSaveSubscriptionCallback();

    // Teams
    const subscriptionTeamsManager = useChangesArray<SubscriptionTeam, string>(storeSubscriptionTeams, item => item.id);
    const orderedSubscriptionTeams = React.useMemo(() => {
        let ret = [...subscriptionTeamsManager.model];
        ret.sort((a, b) => {
            if (a.name === b.name) {
                return 0;
            } else if (a.name > b.name) {
                return 1;
            } else {
                return -1;
            }
        });
        return ret;
    }, [subscriptionTeamsManager]);
    const roleGroups = React.useMemo(() => {
        if (!filterRoleGroups) {
            return storeRoleGroups;
        }

        return filterRoleGroups(storeRoleGroups ?? []);
    }, [storeRoleGroups, filterRoleGroups]);


    // Unique PINS as an alternative to traditional username and password logins.
    const isUsingUniqueDriverPin = !!model?.uniqueDriverPin;
    const [generateUniqueDriverPin, { isExecuting: isGeneratingUniqueDriverPin, errors: generateUniqueDriverPinErrors }] = useGenerateUniqueDriverPinCallback();
    const [regeneratePin] = useAsyncCallback(async () => {
        const newPin = await generateUniqueDriverPin();
        change({ uniqueDriverPin: newPin });
        const driverPinService = new DriverPinService();
        const newEmail = driverPinService.emailFromPin(newPin);
        changeUserModel({ email: newEmail });
    }, [generateUniqueDriverPin, change, changeUserModel]);


    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            firstName: () => !model?.firstName ? t('editUserBase.firstNameRequired', 'First name is required') : '',
            lastName: () => !model?.lastName ? t('editUserBase.lastNameRequired', 'Last name is required') : '',
            email: () => !userModel?.email ? t('editUserBase.emailRequired', 'Email is required') : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, [model, userModel]);

    const [saveForm, { isExecuting: isSaving, errors: saveFormErrors }] = useAsyncCallback(async (options?: { navigateTo?: string, }) => {
        if (!validate()) {
            return;
        }

        if (subscription && isCreate) {

            if (subscription.maxUsers === 0 || subscription.restrictMaxUsers === false) {

                setWillExceedMaxUsers(false);

            }
            else if (subscription.currentUsers + 1 > subscription.maxUsers && subscription.restrictMaxUsers === true) {

                setWillExceedMaxUsers(true);
                return;

            }


        }

        // If we are creating this user, make sure we save the user and get its id before continuing with saving the profile.
        let userId = model.userId;
        if (isCreate) {
            // Create an invited user but don't send the invite yet, we'll send it after the profile has been saved to so we can include their name
            // and make it more personal for higher engagement.
            const result = await invite({ ...userModel, sendEmail: false });
            userId = result.userId;
            changeUserModel({ id: userId });
            change({ userId: userId });

            if (subscription && result) {

            //update the subscriptions current user count
                await saveSubscription(subscription.id, { currentUsers: subscription.currentUsers + 1 }, false);

            }
        }

        await save(model.id, { ...changes, userId: userId }, !!isCreate);

        // If we created a user invite, send the email now the profile has been saved so it can include more personal information (e.g. their name).
        if (isCreate) {
            await resendInviteEmail(userModel.email);
        }

        // Handle changes to the email address of existing users.
        if (!isCreate && storeModel && storeModel.user.email !== userModel.email) {
            await changeAccountEmail(storeModel.user.email, userModel.email);
        }

        // Handle the role group change.
        if (isCreate || storeModel?.user.roleGroup?.id !== roleGroupModel.id) {
            await changeUserRoleGroupInStore({ userId: userId, roleGroupId: roleGroupModel.id });
        }

        // Navigate to the next screen.
        if (options?.navigateTo) {
            history.push(options.navigateTo);
        } else {
            history.goBack();
        }
    }, [validate, save, model, changes, isCreate, history, userModel, invite, changeUserModel, change, changeAccountEmail, resendInviteEmail, setWillExceedMaxUsers, subscription, saveSubscription ]);

    const isDriver = React.useMemo(() => (roleGroupModel?.id ?? defaultRoleGroup) === 'Driver', [roleGroupModel, defaultRoleGroup]);
    const isManager = React.useMemo(() => (roleGroupModel.id ?? defaultRoleGroup) === 'Driver manager' || (roleGroupModel.id ?? defaultRoleGroup) === 'Overview manager', [roleGroupModel, defaultRoleGroup]);
    const isDistributorAdministrator = React.useMemo(() => (roleGroupModel.id ?? defaultRoleGroup) === 'Distributor administrator', [roleGroupModel, defaultRoleGroup]);
    const isAdministrator = !isDriver && !isManager && !isDistributorAdministrator;


    return (
        <Background>
            <Banner>
                <Row>
                    <Col xs={12} md="auto">
                        <h1>
                            {
                                isCreate ? (
                                    <>{
                                        isDriver ? t('editUserBase.createHeading.driver', 'Add driver')
                                            : isManager ? t('editUserBase.createHeading.manager', 'Add manager')
                                            : isDistributorAdministrator ? t('editUserBase.createHeading.distributorAdministrator', 'Add distributor administator')
                                            : t('editUserBase.createHeading.default', 'Add user')
                                    }</>
                                ) : (
                                        <>{
                                            isDriver ? t('editUserBase.editHeading.driver', 'Add driver')
                                                : isManager ? t('editUserBase.editHeading.manager', 'Edit manager')
                                                : isDistributorAdministrator ? t('editUserBase.editHeading.distributorAdministrator', 'Edit distributor administrator')
                                                : t('editUserBase.editHeading.default', 'Edit user')
                                        }</>
                                    )
                            }
                        </h1>
                    </Col>
                    <Col>
                        <PillsNavBar>
                            <ConditionalFragment showIf={isDriver}>
                                <DriverNavigation
                                    id={model?.id ?? ''}
                                    editLink={history.location.pathname}
                                    onNavigate={link => saveForm({ navigateTo: link })}
                                />
                            </ConditionalFragment>
                            <ConditionalFragment showIf={isManager}>
                                <ManagerNavigation
                                    id={model?.id ?? ''}
                                    editLink={history.location.pathname}
                                    onNavigate={link => saveForm({ navigateTo: link })}
                                />
                            </ConditionalFragment>
                            <ConditionalFragment showIf={isAdministrator}>
                                <AdministratorNavigation
                                    id={model?.id ?? ''}
                                    editLink={history.location.pathname}
                                    onNavigate={link => saveForm({ navigateTo: link })}
                                />
                            </ConditionalFragment>
                        </PillsNavBar>
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
            </Banner>

            <MainContainer>
                <AlertOnErrors errors={[loadErrors, loadSupportingDataErrors, saveFormErrors, saveErrors, inviteErrors, resendInviteEmailErrors, changeEmailErrors, changeUserRoleGroupInStoreErrors, generateUniqueDriverPinErrors]} />

                {
                    hasResentInviteEmail ? (
                        <Alert color="success" >
                            <>{t('editUserBase.confirmationEmailHasBeenResent', 'Invite email for this user has been resent.  Please ask the user to check their email to confirm.')} </>
                            <ButtonAsync type="button" color="success" onClick={async e => { e.preventDefault(); await resendInviteEmail(storeModel?.user?.email ?? userModel.email); }}
                                isExecuting={isResendingInviteEmail}
                                executingChildren={<><Spinner size="sm" />{t('common.sending', 'Sending...')}</>}>
                                {t('common.resendEmail', 'Resend email')}
                            </ButtonAsync>
                        </Alert>
                    ) : null
                }
                {
                    willExceedMaxUsers ? (
                        <Alert color="danger">
                            <>{t('editUserBase.maxUsersReached', 'Cannot create a new user. Maximum amount of users for this subscription has been reached.')} </>
                        </Alert>
                    ) : null
                }

                <Form onSubmit={e => { e.preventDefault(); saveForm(); }}>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="firstName">{t('editUserBase.firstName', 'First name')}</Label>
                                <ValidatedInput name="firstName" type="text" value={model.firstName ?? ''} onChange={e => change({ firstName: e.currentTarget.value })} onBlur={e => validate('firstName')} validationErrors={validationErrors['firstName']} />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="lastName">{t('editUserBase.lastName', 'Last name')}</Label>
                                <ValidatedInput name="lastName" type="text" value={model.lastName ?? ''} onChange={e => change({ lastName: e.currentTarget.value })} onBlur={e => validate('lastName')} validationErrors={validationErrors['lastName']} />
                            </FormGroup>
                        </Col>
                    </Row>

                    <ConditionalFragment showIf={isDriver}>
                        <FormGroup>
                            <Label htmlFor="howWillTheyLogIn">{t('editUserBase.howWillTheyLogIn.label', 'How will the driver log in?')}</Label>
                            <TwoValueSwitch
                                leftLabel={t('editUserBase.howWillTheyLogIn.email', 'Email and password')}
                                rightLabel={t('editUserBase.howWillTheyLogIn.ex', 'Unique driver PIN')}
                                checked={isUsingUniqueDriverPin}
                                onChange={checked => {
                                    if (checked) {
                                        regeneratePin();
                                    } else {
                                        change({ uniqueDriverPin: '' });
                                        changeUserModel({ email: storeModel?.user?.email ?? '' });
                                    }
                                }}
                            />
                        </FormGroup>
                    </ConditionalFragment>
                    <ConditionalFragment showIf={isUsingUniqueDriverPin}>
                        <FormGroup>
                            <Label htmlFor="uniqueDriverPin">{t('editUserBase.uniqueDriverPin.label', 'Login PIN for driver')}</Label>
                            <Row>
                                <Col>
                                    <Input name="uniqueDriverPin" type="text" readOnly value={model.uniqueDriverPin ?? ''} />
                                </Col>
                                <Col xs="auto">
                                    <ButtonAsync type="button" color="primary" outline onClick={async e => regeneratePin()}
                                        isExecuting={isGeneratingUniqueDriverPin}
                                        executingChildren={<><Spinner size="sm" />{t('common.generatingPin', 'Generating unique PIN...')}</>}>
                                        <FontAwesomeIcon icon="dice" />
                                        <> </>
                                        {t('editUserBase.regeneratePin', 'Generate new PIN')}
                                    </ButtonAsync>
                                </Col>
                            </Row>
                        </FormGroup>
                    </ConditionalFragment>
                    <ConditionalFragment showIf={!isUsingUniqueDriverPin}>
                        <FormGroup>
                            <Label htmlFor="email">{t('editUserBase.email', 'Email')}</Label>
                            <Row>
                                <Col>
                                    <ValidatedInput name="email" type="email" value={userModel.email ?? ''} onChange={e => changeUserModel({ email: e.currentTarget.value })} onBlur={e => validate('email')} validationErrors={validationErrors['email']} />
                                </Col>
                                {
                                    storeModel && !storeModel.user.emailConfirmed && userModel.email === storeModel.user.email ? (
                                        <Col xs="auto">
                                            <ButtonAsync type="button" color="primary" outline onClick={async e => { e.preventDefault(); await resendInviteEmail(userModel.email); setHasResentInviteEmail(true); }}
                                                isExecuting={isResendingInviteEmail}
                                                executingChildren={<><Spinner size="sm" />{t('editUserBase.resendingInvite', 'Resending invite...')}</>}>
                                                <FontAwesomeIcon icon="envelope" />
                                                <> {t('editUserBase.resendInvite', 'Resend invite')}</>
                                            </ButtonAsync>
                                        </Col>
                                    ) : null
                                }
                            </Row>
                            {
                                storeModel && !storeModel.user.emailConfirmed && userModel.email === storeModel.user.email ? (
                                    <FormText>
                                        {t('editUserBase.userHasNotAcceptedInvite', 'This user has not accepted the invite.  You can change their email if required or resend the invite.')}
                                    </FormText>
                                ) : storeModel && !storeModel.user.emailConfirmed && userModel.email !== storeModel.user.email ? (
                                    <FormText>
                                        {t('editUserBase.userHasNotAcceptedInviteAndEmailChanged', 'This user has not yet accepted the invite, a new invite will be sent to the new email you have supplied.')}
                                    </FormText>
                                ) : storeModel && storeModel.user.emailConfirmed && userModel.email !== storeModel.user.email ? (
                                    <FormText color="warning">
                                        {t('editUserBase.userHasAcceptedInviteAndEmailChanged', 'This user will be asked to confirm the email change before it takes affect.  The old email address will be shown in the system until the change has been confirmed.')}
                                    </FormText>
                                ) : storeModel && storeModel.user.emailConfirmed && userModel.email === storeModel.user.email ? (
                                    <FormText>
                                        {t('editUserBase.userHasAcceptedInviteAndEmailNotChanged', 'If you change this user\'s email they will be sent an email to confirm the change before it takes affect.')}
                                    </FormText>
                                ) : null
                            }
                        </FormGroup>
                    </ConditionalFragment>

                    <ConditionalFragment showIf={(roleGroupModel?.id ?? defaultRoleGroup) !== 'Esitu staff'}>
                        <FormGroup>
                            <Label htmlFor="subscriptionTeamId">{t('editUserBase.subscriptionTeamId.label', 'Team')}</Label>
                            <ValidatedInput name="subscriptionTeamId" type="select" value={model?.subscriptionTeamId ?? ''} onChange={e => change({ subscriptionTeamId: e.currentTarget.value || null })} onBlur={e => validate('subscriptionTeamId')} validationErrors={validationErrors['subscriptionTeamId']}>
                                <option value="">{t('editUserBase.subscriptionTeamId.noTeam', '(No team)')}</option>
                                
                                {
                                    orderedSubscriptionTeams.map(item => (
                                        <option key={item.id} value={item.id}>{item.name}</option>
                                    ))
                                }
                            </ValidatedInput>
                        </FormGroup>
                    </ConditionalFragment>

                    <ConditionalFragment showIf={roleGroups.length > 1}>
                        <FormGroup>
                            <Label htmlFor="roleGroup">{t('editUserBase.roleGroup', 'Security group')}</Label>
                            <ValidatedInput name="roleGroup" type="select" value={roleGroupModel?.id ?? ''} onChange={e => changeRoleGroupModel({ id: e.currentTarget.value })} onBlur={e => validate('roleGroup')} validationErrors={validationErrors['roleGroup']}>
                                {
                                    roleGroups?.map(item => (
                                        <option key={item.id} value={item.id}>{item.name}</option>
                                    ))
                                }
                            </ValidatedInput>
                        </FormGroup>
                    </ConditionalFragment>

                    <FormButtons>
                        <ConditionalFragment showIf={!isLoading}>
                            <ButtonAsync color="primary" isExecuting={isSaving}
                                executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}>
                                <FontAwesomeIcon icon="save" />
                                <> {t('common.save', 'Save')}</>
                            </ButtonAsync>
                        </ConditionalFragment>
                        <Button type="button" color="primary" outline onClick={e => history.goBack()}>
                            {t('common.cancel', 'Cancel')}
                        </Button>
                    </FormButtons>
                </Form>
            </MainContainer>
        </Background>
    );
};
