import { NavItem, NavLink } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLinkTrackActive } from '../../../shared/NavLinkTrackActive';

/**
 * Administration navigation menu for studies.
 */
export const StudyAdministrationNavigation = () => {
    const { t } = useTranslation();

    return (
        <>
            <NavItem>
                <NavLink tag={NavLinkTrackActive} to={`/manage/study/participants`}>
                    <FontAwesomeIcon icon="users" className="nav-icon" />
                    {t('studyAdministrationNavigation.participants', ' Study participants')}
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink tag={NavLinkTrackActive} to={`/manage/study/export`}>
                    <FontAwesomeIcon icon="building" className="nav-icon" />
                    {t('studyAdministrationNavigation.export', ' Study export')}
                </NavLink>
            </NavItem>
        </>
    );
};