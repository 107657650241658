import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { useCallback } from "react";
import { ConditionalFragment } from 'react-conditionalfragment';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { LinkContainer } from 'react-router-bootstrap';
import { Waypoint } from 'react-waypoint';
import { Button, ButtonDropdown, ButtonGroup, Card, CardBody, CardTitle, Col, Collapse, DropdownItem, DropdownMenu, DropdownToggle, InputGroupAddon, Row } from 'reactstrap';
import { useToggleState, useToggleStateArray } from 'use-toggle-state';
import { QuestionType, questionTypeDisplayName } from '../../api/main/models/codeOnly/QuestionType';
import { useQuestionsListViewModel } from '../../api/main/questions/viewModels/useQuestionsListViewModel';
import { usePreferredListViewMode } from '../../globalState/preferredListViewMode/usePreferredListViewMode';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { useReplaceSearchParamsEffect, useSearchParams } from '../../shared/useURLSearchParams';
import { QuestionTagDisplay } from '../questionTags/questionTagSelector/QuestionTagDisplay';
import { Background } from '../shared/background/Background';
import { Banner } from '../shared/Banner';
import { CardsOrTable } from '../shared/cardsOrTable/CardsOrTable';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { MainContainer } from '../shared/MainContainer';
import { NoResultsFound } from '../shared/NoResultsFound';
import { SearchInput } from '../shared/searchInput/SearchInput';
import { StickyToolbar } from '../shared/StickyToolbar';
import { TagDisplay } from "../shared/tagFilter/TagDisplay";

export interface QuestionsListBaseProps {
    title: string,
    questionTypeColumnHidden?: boolean,
    questionTypeColumnName?: string,
    questionTypes: Array<QuestionType>,
    baseRoute: string,
}

/**
 * Base list of Questions or other entities that use the Question model.
 */
export const QuestionsListBase = (props: QuestionsListBaseProps) => {
    const {
        title,
        questionTypeColumnHidden = false,
        questionTypeColumnName,
        questionTypes,
        baseRoute,
    } = props;

    const { t } = useTranslation();
    const { search: searchParam } = useSearchParams();
    const [search, setSearch] = React.useState<string>(searchParam ?? '');
    const {
        data: { items: allItems, tags, links, driverMetrics },
        isLoading, errors: loadingErrors, fetchMore, hasMore
    } = useQuestionsListViewModel({ pageSize: undefined, questionTypes: questionTypes, });
    const [isMenuOpen, toggleMenuOpen] = useToggleStateArray();
    const history = useHistory();


    // Manage the toggling of tags on and off.
    const [selectedTags, setSelectedTags] = React.useState<Array<string>>([]);
    const toggleTagSelected = useCallback((tag: string) => {
        setSelectedTags(prevState => {
            const existing = prevState.find(it => it === tag);

            if (existing) {
                // If we are currently on, toggle to off.
                return prevState.filter(it => it !== tag);
            } else {
                // Add tag to the list of on toggles.
                return [...prevState, tag];
            }
        });
    }, [setSelectedTags]);
    const isTagSelected = React.useCallback((tag: string) => {
        // If no tags are selected, treat it as if all tags are selected.
        if (!selectedTags.length) {
            return true;
        }

        // Return true or false based on if this tag has been selected explictly.
        return selectedTags.find(it => it === tag);
    }, [selectedTags]);

    // Filter by the question's search client side so it can work when offline as well as online.
    const items = React.useMemo(() => {
        if (!allItems) {
            return allItems;
        }

        let ret = allItems;

        // Filter by selected tags if any tag filters have been selected.
        if (selectedTags.length) {
            ret = ret.filter(item => {
                // Get all questionFilterTags for this question.
                const myLinks = links.filter(link => link.targetId === item.id);

                for (const link of myLinks) {
                    if (isTagSelected(link.questionTagId)) {
                        return true;
                    }
                }

                return false;
            });
        }

        // Filter by user search input.
        let lowerSearch = search.toLocaleLowerCase();
        if (lowerSearch) {
            // Filter the items being displayed.
            ret = ret.filter(item => {
                // Get the primary driver metric.
                const driverMetricName = driverMetrics?.find(it => it.id === item.driverMetricId)?.name
                    ?? t('questionsListBase.driverMetric.none', '(No primary safety metric)');

                // Get the tags that are linked to this item.
                const myTags = tags?.filter(tag => !!links?.find(it => it.questionTagId === tag.id && it.targetId === item.id)) ?? [];

                return (
                    item.name.toLocaleLowerCase().indexOf(lowerSearch) >= 0
                    || questionTypeDisplayName(item.questionType as QuestionType, t).toLocaleLowerCase().indexOf(lowerSearch) >= 0
                    || !!myTags.find(tag => tag.name.toLocaleLowerCase().indexOf(lowerSearch) >= 0)
                    || (driverMetricName ?? '').toLocaleLowerCase().indexOf(lowerSearch) >= 0
                );
            });
        }

        return ret;
    }, [allItems, search, t, tags, links, isTagSelected, selectedTags.length, driverMetrics]);

    useReplaceSearchParamsEffect({ search: search });

    const [viewMode, setViewMode] = usePreferredListViewMode();

    //controls for filtering by tags
    const [isTagFilterOpen, toggleTagFilterOpen] = useToggleState(false);

    return (
        <Background>
            <Banner fluid>
                <StickyToolbar>
                    <Row>
                        <Col xs={11} md="">
                            <h1>{title}</h1>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs={1} md="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                
                        <Col>
                            <SearchInput value={search} onChange={e => setSearch(e.currentTarget.value)}>
                                <InputGroupAddon addonType="append">
                                    <Button className="search-input-button" onClick={() => toggleTagFilterOpen()}>
                                        <FontAwesomeIcon icon="tags" />
                                        <span className="sr-only">{t('questionsListBase.toggleTags', 'Toggle tags')}</span>
                                    </Button>
                                </InputGroupAddon>
                            </SearchInput>
                        </Col>
                        <Col xs="auto">
                            <LinkContainer to={`${baseRoute}/add`}>
                                <Button color="primary">
                                    <FontAwesomeIcon icon="plus" /><> {t('questionsListBase.add', 'Add')}</>
                                </Button>
                            </LinkContainer>
                        </Col>
                        <Col xs={12} md="auto">
                            <ButtonGroup>
                                <Button color="secondary" outline={viewMode !== 'cards'} onClick={() => setViewMode('cards')}>
                                    <FontAwesomeIcon icon="th-large" />
                                    <span className="sr-only">{t('common.cards', 'Cards')}</span>
                                </Button>
                                <Button color="secondary" outline={viewMode !== 'table'} onClick={() => setViewMode('table')}>
                                    <FontAwesomeIcon icon="th-list" />
                                    <span className="sr-only">{t('common.list', 'List')}</span>
                                </Button>
                            </ButtonGroup>
                        </Col>
                    </Row>

                    <Collapse isOpen={isTagFilterOpen}>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col xs={12} md="auto">
                                        <p className="text-muted">
                                            {t('questionsListBase.selectTagsToFilter', 'Select tags to filter by:')}
                                        </p>
                                    </Col>
                                    <Col>
                                        <TagDisplay noPadding>
                                            {
                                                tags?.map(item => (
                                                    <Button key={item.id} size="sm" color="secondary" outline={!isTagSelected(item.id)} onClick={() => toggleTagSelected(item.id)}>
                                                        {item.name}
                                                    </Button>
                                                ))
                                            }
                                        </TagDisplay>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Collapse>
                </StickyToolbar>
            </Banner>

            <MainContainer fluid>
                <AlertOnErrors errors={loadingErrors} />

                <CardsOrTable
                    viewMode={viewMode}
                    items={items}
                    onItemClick={item => history.push(`${baseRoute}/edit/${item.id}`)}
                    tableHeadings={[
                        t('questionsListBase.name', 'Name'),
                        questionTypeColumnHidden ? null : questionTypeColumnName,
                        t('questionsListBase.driverMetric.label', 'Safety metric'),
                        t('questionsListBase.tags', 'Tags'),
                    ]}
                    columns={[
                        (item, view) => view === 'cards' ? (<CardTitle tag="h5">{item.name}</CardTitle>) : item.name,
                        (item) => questionTypeColumnHidden ? null : questionTypeDisplayName(item.questionType as QuestionType, t),
                        (item) => driverMetrics?.find(it => it.id === item.driverMetricId)?.name ?? t('questionsListBase.driverMetric.none', '(No primary safety metric)'),
                        (item) => (
                            <QuestionTagDisplay
                                tags={tags?.filter(tag => !!links?.find(it => it.questionTagId === tag.id && it.targetId === item.id)) ?? []}
                            />
                        ),
                    ]}
                    buttons={(item) => (
                        <ButtonGroup>
                            <LinkContainer to={`${baseRoute}/edit/${item.id}`}>
                                <Button color="primary" outline>
                                    <FontAwesomeIcon icon="edit" />
                                    <> {t('common.edit', 'Edit')}</>
                                </Button>
                            </LinkContainer>
                            <ButtonDropdown isOpen={isMenuOpen(item.id)} toggle={() => toggleMenuOpen(item.id)}>
                                <DropdownToggle color="primary" outline caret>
                                    <span className="sr-only">{t('common.menuDropdown', 'More')}</span>
                                </DropdownToggle>
                                <DropdownMenu>
                                    <LinkContainer to={`${baseRoute}/delete/${item.id}`}>
                                        <DropdownItem className="text-danger">
                                            <FontAwesomeIcon icon="trash" />
                                            <> {t('common.delete', 'Delete')}</>
                                        </DropdownItem>
                                    </LinkContainer>
                                </DropdownMenu>
                            </ButtonDropdown>
                        </ButtonGroup>
                    )}
                />

                <ConditionalFragment showIf={isLoading && !items?.length}>
                    <LoadingIndicator fullWidth />
                </ConditionalFragment>
                <ConditionalFragment showIf={!isLoading && !items?.length}>
                    <NoResultsFound search={search} />
                </ConditionalFragment>
                <ConditionalFragment showIf={!isLoading && hasMore()}>
                    <Waypoint key={items?.length ?? 0} onEnter={fetchMore} />
                    <LoadingIndicator fullWidth />
                </ConditionalFragment>
            </MainContainer>
        </Background>
    );
};
