import { RouteEntry } from "../../../shared/routes";
import { AssessmentResults } from "./AssessmentResults";
import { LaunchAssessment } from "./LaunchAssessment";
import { StartAssessment } from "./StartAssessment";


export const assessmentSessionAssessmentRoutes: Array<RouteEntry> = [
    { path: '/assessment/start/:assessmentId', exact: true, component: StartAssessment, authorize: true, },
    { path: '/assessment/startFor/:userId/:assessmentId', exact: true, component: StartAssessment, authorize: true, },
    { path: '/assessment/launch/:id', component: LaunchAssessment, authorize: true, },
    { path: '/assessment/results/:id', component: AssessmentResults, authorize: true, },
];
