import { useTranslation } from "react-i18next";
import { AssessmentType } from "../../../api/main/models/codeOnly/AssessmentType";
import { UserAssessmentAssignmentsListBase } from "../UserAssessmentAssignmentsListBase";

/**
 * List of actual assessments assigned to a user/team.
 */
export const UserAssessmentAssignmentsList = () => {
    const { t } = useTranslation();

    return (
        <UserAssessmentAssignmentsListBase
            title={t('userAssessmentsList.title', 'Required assessments')}
            mobileColumn1Name={t('userAssessmentsList.mobileColumn1Name', 'Assessment')}
            assessmentTypes={[AssessmentType.Assessment]}
        />
        );
};