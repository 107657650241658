import { RouteEntry } from "../../shared/routes";
import { QuestionTagsList } from './QuestionTagsList';
import { EditQuestionTag, CreateQuestionTag } from "./EditQuestionTag";
import { IdentityRoles } from "../../configure/security/IdentityRoles";
import { DeleteQuestionTag } from "./DeleteQuestionTag";

export const questionTagRoutes: Array<RouteEntry> = [
    { path: '/administration/tags', exact: true, component: QuestionTagsList, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/tags/edit/:id', component: EditQuestionTag, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/tags/add', component: CreateQuestionTag, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/tags/delete/:id', component: DeleteQuestionTag, authorize: true, requireRole: IdentityRoles.Administration },
];
