import './uploadedImagePreview.scss';

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ConditionalFragment } from 'react-conditionalfragment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface UploadedImagePreviewProps {
    src: string,
    alt?: string,

    size?: 'sm' | 'lg',

    className?: string,
    style?: React.CSSProperties,
}

/**
 * Preview of an image uploaded by a user, suitable for use on an Edit form for example and made to look similar to other controls that would show in that context.
 */
export const UploadedImagePreview = (props: UploadedImagePreviewProps) => {
    const {
        src,
        alt,
        size = 'lg',
        className,
        style,
    } = props;

    const { t } = useTranslation();

    return (
        <div className={`uploaded-image-preview uploaded-image-preview-${size} ${className ?? ''}`} style={style}>
            <ConditionalFragment showIf={!!src}>
                <img className="uploaded-image-preview-img img-fluid" src={src} alt={alt ?? t('uploadedImagePreview.defaultAlt', 'Preview of image')} />
            </ConditionalFragment>
            <ConditionalFragment showIf={!src}>
                <div className="uploaded-image-preview-no-image">
                    <div className="uploaded-image-preview-no-image-icon">
                        <FontAwesomeIcon icon="photo-video" />
                    </div>
                    <p>
                        {
                            t('uploadedImagePreview.notImageText', 'No image has been uploaded yet')
                        }
                    </p>
                </div>
            </ConditionalFragment>
        </div>
        );
};
