import gql from "graphql-tag";
import { Guid } from "guid-string";
import { subscriptionTeamFields } from "../generated/subscriptionTeamFields";

export const subscriptionTeamFieldsFragment = gql`
    fragment subscriptionTeamFields on SubscriptionTeam {
        id
        subscriptionId
        name
        archived
    }
`;


export type SubscriptionTeam = Omit<subscriptionTeamFields, '__typename'>;

export const subscriptionTeamDefaultValues = (): Partial<SubscriptionTeam> => ({
    id: Guid.newGuid(),
    name: '',
    subscriptionId: undefined,
    archived: false,
});