import { RouteEntry } from "../../shared/routes";
import { SubscriptionsList } from './Subscriptions/SubscriptionsList';
import { IdentityRoles } from "../../configure/security/IdentityRoles";
import { EditSubscription, CreateSubscription } from "./Subscriptions/EditSubscription";
import { DeleteSubscription } from "./DeleteSubscription";

export const subscriptionRoutes: Array<RouteEntry> = [
    { path: '/administration/subscriptions', exact: true, component: SubscriptionsList, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/subscriptions/edit/:id', component: EditSubscription, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/subscriptions/add', component: CreateSubscription, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/subscriptions/delete/:id', component: DeleteSubscription, authorize: true, requireRole: IdentityRoles.Administration },
];
