import { useCallback, useEffect, useRef, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { Circle, Ellipse, Rect, Text, Transformer } from "react-konva";
import { OverlayShape } from "./OverlayShape";

export interface ResizableReactangleProps {
    shapeProps: OverlayShape,
    isSelected: boolean,
    onSelect: () => void,
    onChange: (newAttrs: any) => void,
    allowTransform: boolean,
}

export const ResizableOverlay = (props: ResizableReactangleProps) => {
    const {
        shapeProps,
        isSelected: _isSelected,
        onSelect,
        onChange,
        allowTransform,
    } = props;

    const isSelected = allowTransform && _isSelected;

    const shapeRef = useRef<any>();
    const trRef = useRef<any>();

    useEffect(() => {
        if (isSelected) {
            if (!trRef.current) {
                return;
            }

            // we need to attach transformer manually
            trRef.current.nodes([shapeRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [isSelected]);

    const onDragEnd = useCallback((e: { target: { x: () => number, y: () => number } }) => {
        onChange({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y(),
        });
    }, [onChange, shapeProps]);

    const onTransformEnd = useCallback(() => {
        // transformer is changing scale of the node
        // and NOT its width or height
        // but in the store we have only width and height
        // to match the data better we will reset scale on transform end
        const node = shapeRef.current;
        if (!node) {
            return;
        }

        const scaleX = node.scaleX();
        const scaleY = node.scaleY();

        // we will reset it back
        node.scaleX(1);
        node.scaleY(1);

        onChange({
            ...shapeProps,
            x: node.x(),
            y: node.y(),
            // set minimal value
            width: Math.max(5, node.width() * scaleX),
            height: Math.max(node.height() * scaleY),
        });
    }, [onChange, shapeProps]);

    // TODO support types other than Rect.
    const [textHiddenByAction, setTextHiddenByAction] = useState<boolean>(false);
    const [textHiddenByMouse, setTextHiddenByMouse] = useState<boolean>(false);
    const textHidden = textHiddenByAction || textHiddenByMouse;

    return (
        <>
            <ConditionalFragment showIf={!shapeProps.shape || shapeProps.shape === 'rect'}>
                <Rect
                    ref={shapeRef}
                    onClick={onSelect}
                    onTap={onSelect}
                    {...shapeProps}
                    draggable={allowTransform}
                    onDragStart={() => setTextHiddenByAction(true)}
                    onDragEnd={e => { setTextHiddenByAction(false); onDragEnd(e); }}
                    onMouseEnter={() => setTextHiddenByMouse(true)}
                    onMouseLeave={() => setTextHiddenByMouse(false)}
                    onTransformEnd={onTransformEnd}
                />
            </ConditionalFragment>
            <ConditionalFragment showIf={shapeProps.shape === 'circle'}>
                <Circle
                    ref={shapeRef}
                    {...shapeProps}
                    onClick={onSelect}
                    onTap={onSelect}
                    draggable={allowTransform}
                    onDragStart={() => setTextHiddenByAction(true)}
                    onDragEnd={e => { setTextHiddenByAction(false); onDragEnd(e); }}
                    onMouseEnter={() => setTextHiddenByMouse(true)}
                    onMouseLeave={() => setTextHiddenByMouse(false)}
                    onTransformEnd={onTransformEnd}
                />
            </ConditionalFragment>
            <ConditionalFragment showIf={shapeProps.shape === 'ellipse'}>
                <Ellipse
                    ref={shapeRef}
                    {...shapeProps}
                    radiusX={shapeProps.width / 2} radiusY={shapeProps.height / 2}
                    onClick={onSelect}
                    onTap={onSelect}
                    draggable={allowTransform}
                    onDragStart={() => setTextHiddenByAction(true)}
                    onDragEnd={e => { setTextHiddenByAction(false); onDragEnd(e); } }
                    onMouseEnter={() => setTextHiddenByMouse(true)}
                    onMouseLeave={() => setTextHiddenByMouse(false)}
                    onTransformEnd={onTransformEnd}                />
            </ConditionalFragment>
            <Text
                text={shapeProps.label} x={shapeProps.x} y={shapeProps.y + 10} width={shapeProps.width}
                align="center"
                onClick={onSelect}
                onTap={onSelect}
                onMouseEnter={() => setTextHiddenByMouse(true)}
                visible={!textHidden}

            />
            {isSelected && (
                <Transformer
                    ref={trRef}
                    rotateEnabled={false}
                    boundBoxFunc={(oldBox, newBox) => {
                        // limit resize
                        if (newBox.width < 5 || newBox.height < 5) {
                            return oldBox;
                        }
                        return newBox;
                    }}
                    onTransformStart={() => setTextHiddenByAction(true)}
                    onTransformEnd={() => setTextHiddenByAction(false)}
                />
            )}
        </>
    );
};
