import { Button, Form, Label, FormGroup, Spinner, Input, Row, Col } from 'reactstrap';
import { AlertOnErrors } from '../../../shared/alertOnErrors';
import { LoadingIndicator } from '../../shared/LoadingIndicator';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContainer } from '../../shared/MainContainer';
import { useParams, useHistory } from 'react-router';
import { FormButtons } from '../../shared/FormButtons';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { useAsyncCallback } from 'react-use-async-callback';
import { useSdkWebhook } from '../../../api/main/sdkWebhooks/useSdkWebhook';
import { useDeleteSdkWebhookCallback } from '../../../api/main/sdkWebhooks/useDeleteSdkWebhookCallback';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Banner } from '../../shared/Banner';
import { Background } from '../../shared/background/Background';
import { useEditSdkWebhookSupportingData } from '../../../api/main/sdkWebhooks/viewModels/useEditSdkWebhookSupportingData';
import { useMemo } from 'react';
import { useCurrentUserOrEmulatedSubscriptionId } from '../../../globalState/subscriptions/useCurrentUserOrEmulatedSubscriptionId';

/**
 * Delete a SdkWebhook and deactivate its integrations.
 */
export const DeleteSdkWebhook = () => {
    const { id } = useParams<{ id: string }>();

    const subscriptionId = useCurrentUserOrEmulatedSubscriptionId();

    const { t } = useTranslation();

    const { data: { model: model }, isLoading: _isLoading, errors: loadErrors } = useSdkWebhook(id);
    const { data: { sdkClients }, isLoading: isLoadingSupportingData, errors: supportingDataErrors } = useEditSdkWebhookSupportingData(subscriptionId);
    const isLoading = _isLoading || isLoadingSupportingData;

    const [remove, { errors: removeErrors }] = useDeleteSdkWebhookCallback();
    const history = useHistory();

    const [onRemoveFormSubmit, { isExecuting: isRemoving, errors: removeFormErrors }] = useAsyncCallback(async () => {
        if (!model) {
            return;
        }

        await remove(model.id);

        history.goBack();
    }, [model, history]);

    const sdkClient = useMemo(() => sdkClients?.find(it => it.id === model?.sdkClientId), [model?.sdkClientId, sdkClients]);

    return (
        <Background>
            <Banner>
                <Row>
                    <Col>
                        <h1>{t('deleteSdkWebhook.heading', 'Are you sure you want to delete this webhook connection?')}</h1>
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
            </Banner>

            <MainContainer>
                <AlertOnErrors errors={[loadErrors, supportingDataErrors, removeFormErrors, removeErrors]} />

                <Form onSubmit={e => { e.preventDefault(); onRemoveFormSubmit(); }}>
                    <FormGroup>
                        <Label htmlFor="sdkClientName">{t('deleteSdkWebhook.sdkClientName.label', 'SDK application')}</Label>
                        <Input name="sdkClientName" type="text" readOnly plaintext value={sdkClient?.name ?? ''} />
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="url">{t('deleteSdkWebhook.url.label', 'URL')}</Label>
                        <Input name="url" type="text" readOnly plaintext value={model?.url ?? ''} />
                    </FormGroup>
                    
                    <FormButtons>
                        <ConditionalFragment showIf={!isLoading}>
                            <ButtonAsync color="danger" isExecuting={isRemoving}
                                executingChildren={<><Spinner size="sm" /> {t('common.deleting', 'Deleting...')}</>}>
                                <FontAwesomeIcon icon="trash" />
                                <> {t('common.delete', 'Delete')}</>
                            </ButtonAsync>
                        </ConditionalFragment>
                        <Button type="button" color="primary" outline onClick={e => history.goBack()}>
                            {t('common.cancel', 'Cancel')}
                        </Button>
                    </FormButtons>
                </Form>
            </MainContainer>
        </Background>
    );
};
