import { useTranslation } from "react-i18next";
import { AssessmentType } from "../../../../api/main/models/codeOnly/AssessmentType";
import { SubscriptionAssessmentsListBase } from "../../../subscriptionAssessments/SubscriptionAssessmentsListBase";
import { useParams } from "react-router";
import { DistributorSubscriptionEditNavigation } from './DistributorSubscriptionEditNavigation';

/**
 * List of actual assessments for a subscription
 */
export const DistributorSubscriptionTrainingList = () => {
    const { t } = useTranslation();

    const { subscriptionId } = useParams<{ subscriptionId: string }>();

    const baseRoute = '/administration/training';

    return (
        <SubscriptionAssessmentsListBase
            title={t('distributorTrainingModulesList.title', 'Licensed training modules')}
            mobileColumn1Name={t('distributorTrainingModulesList.mobileColumn1Name', 'Training module')}
            assessmentTypes={[AssessmentType.TrainingModule]}
            baseRoute={baseRoute}
            navigationPills={<DistributorSubscriptionEditNavigation id={subscriptionId ?? ''} />}
            isDistributorSubscription={true}
        />
    );
};