import { useCallback, useEffect, useState } from "react";
import { ImagePrefetcher } from ".";

/**
 * Prefetches multiple image url and caches them for instant reuse as a blob URL.
 * @param url
 */
export function usePrefetchImages(urls: Array<string>): (url: string) => string
export function usePrefetchImages(urls: Array<string | undefined>): (url: string | undefined) => string
{
    const [imagePrefetcher] = useState(() => new ImagePrefetcher());

    // Prefetch and cache the image if we need to.
    useEffect(() => {
        for (const url of urls) {
            if (!url) {
                continue;
            }

            if (
                imagePrefetcher.isCached(url)
                || imagePrefetcher.isLoading(url)
            ) {
                continue;
            }

            imagePrefetcher.cacheImage(url);
        }
    }, [urls, imagePrefetcher]);

    // Return a method to lookup a url.
    const resolve = useCallback((url: string | undefined) => {
        const ret = url ? imagePrefetcher.urlFor(url) : '';
        return ret;
    }, [imagePrefetcher]);
    return resolve;
}