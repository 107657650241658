import * as React from 'react';
import { atom, useRecoilState } from 'recoil';
import { persistAtom } from '../../configure/recoilPersistConfig';
import { OverallRiskCategory } from '../../services/OverallRiskService';

export const activeDashboardRiskCategoryFilterState = atom<OverallRiskCategory | undefined>({
    key: 'activeDashboardRiskCategoryFilterState',
    default: undefined,
    effects_UNSTABLE: [persistAtom],
    
});

/**
 * Hook that returns the active OverallRiskCategory to use for Dashboards.
 */
export function useActiveDashboardRiskCategoryFilter(): [OverallRiskCategory | undefined, React.Dispatch<React.SetStateAction<OverallRiskCategory | undefined>>] {
    const [value, setValue] = useRecoilState(activeDashboardRiskCategoryFilterState);
    return [value, setValue];
}