import * as React from 'react';
import { QuestionSequenceQuestionCreateInput, QuestionSequenceQuestionUpdateInput } from '../generated/globalTypes';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useCreateQuestionSequenceQuestionCallback } from './useCreateQuestionSequenceQuestionCallback';
import { useUpdateQuestionSequenceQuestionCallback } from './useUpdateQuestionSequenceQuestionCallback';
import { QuestionSequenceQuestion } from '../models/QuestionSequenceQuestion';

/**
 * Get a callback to save a QuestionSequenceQuestion in the store using either a create or update.
 */
export function useSaveQuestionSequenceQuestionCallback(options: SaveInStoreOptions<QuestionSequenceQuestion, string> = {}) {
    const [_create, createStatus] = useCreateQuestionSequenceQuestionCallback();
    const create = React.useCallback((model: Partial<QuestionSequenceQuestion>) => _create(model as QuestionSequenceQuestionCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateQuestionSequenceQuestionCallback();
    const update = React.useCallback((id: string, changes: Partial<QuestionSequenceQuestion>) => _update(id, changes as QuestionSequenceQuestionUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
