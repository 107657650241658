import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { Background } from '../shared/background/Background';
import { Banner } from '../shared/Banner';
import { MainContainer } from '../shared/MainContainer';
import { StickyToolbar } from '../shared/StickyToolbar';
import './termsAndConditionsPage.scss';

/**
 * Terms & Conditions Page.
 */
export const TermsAndConditionsPage = () => {

    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Background>
            <Banner fluid>
                <StickyToolbar>
                    <Row>
                        <Col xs={11} md="">
                            <h1>{t("termsAndConditionsPage.title", "Terms & Conditions")}</h1>
                        </Col>

                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer className="terms-and-conditions" fluid>
                <AlertOnErrors errors={null} />

                <div className="text-container">
                    <h2>{t("termsAndConditionsPage.heading", "Terms and Conditions")}</h2>
                    <p>{t("termsAndConditionsPage.para.1", "This is a contract with Esitu Solutions Ltd, whose registered office is at 50 Shakespeare Street, Nottingham, NG1 4FQ, UK.")}</p>
                    <p>{t("termsAndConditionsPage.para.2", "You may contact us on info@esitusolutions.com")}</p>
                    <p>{t("termsAndConditionsPage.para.3", "Registration Number: 12783669")}</p>
                    <p>{t("termsAndConditionsPage.para.4", "VAT number: 380260809")}</p>
                    <p>{t("termsAndConditionsPage.para.5", "Esitu Solutions Limited is referred to in the Terms and Conditions as \"Esitu Solutions\", \"we\" or \"us\". ")}</p>
                    <p>{t("termsAndConditionsPage.para.6", "These terms and conditions apply to products and services provided by Esitu Solutions Ltd. Please carefully read the following terms before accessing any of our products. By accessing the products or services (which includes accessing an online assessments or training modules) you accept and agree to all the covenants and conditions imposed in this agreement. If you do not agree to these terms, you may not access our products. ")}</p>
                    <ol>
                        <li className="li-sub-heading"><span>{t("termsAndConditionsPage.subHeading.1", "Use of these Terms")}</span>
                            <ol>
                                <li>{t("termsAndConditionsPage.para1.1", "These terms and conditions, together with the documents referred to in it, ('Terms of Use') set out the terms on which You may make use of the App (as defined below), Your rights and obligations, and those of Esitu Solutions Limited in relation to the App and its Content (as defined below). Use of the App includes use as a guest or as a registered user and includes downloading, streaming, accessing, browsing, or registering to use the App.")}</li>
                                <li>{t("termsAndConditionsPage.para1.2", "Please read these Terms of Use carefully before You start to use the App, as these will apply to Your use of the App.")}</li>
                                <li>{t("termsAndConditionsPage.para1.3", "By using the App, You confirm that you agree to these Terms of Use and You agree to comply with them. If You do not agree to these Terms of Use, You must not use the App.")}</li>
                            </ol>

                        </li>
                        <li className="li-sub-heading"><span>{t("termsAndConditionsPage.subHeading.2", "Other Applicable Terms")}</span>
                            <ol>
                                <li>{t("termsAndConditionsPage.para2.1", "These Terms of Use refer to the following additional terms, which also apply to your use of our site:")}
                                    <ol>
                                        <li><span style={{ fontWeight: "normal" }}> Our <a href="/privacy-policy">Privacy Policy,</a> </span>{t("termsAndConditionsPage.para2.1.1", " which sets out the terms on which We process any personal data we collect from You, or that You provide to Us. By using our App, You consent to such processing and You warrant that all data provided by You is accurate.")}</li>
                                        <li><span style={{ fontWeight: "normal" }}> Our <a href="/cookie-policy">Cookie Policy,</a> </span>{t("termsAndConditionsPage.para2.1.2", " which sets out information about the cookies on the App.")}</li>
                                    </ol>
                                </li>
                            </ol>

                        </li>
                        <li className="li-sub-heading"><span >{t("termsAndConditionsPage.subHeading.3", "Interpretation")}</span>
                            <ol>
                                <li>{t("termsAndConditionsPage.para3.1", "The following definitions and rules of interpretation apply in these Terms of Use:")}
                                    <ol>
                                        <li>{t("termsAndConditionsPage.para3.1.1", "\"Content\" includes, but is not limited to, all text, graphics and images that appear on the App, the design, look and feel of the app itself and any other material published on the App.")}</li>
                                        <li>{t("termsAndConditionsPage.para3.1.2", "\"Intellectual Property Rights\" means patents, utility models, rights to inventions, copyright and neighbouring and related rights, trade marks and service marks, business names and domain names, rights in get-up and trade dress, goodwill and the right to sue for passing off or unfair competition, rights in designs, database rights, rights to use, and protect the confidentiality of, confidential information (including know-how and trade secrets), and all other intellectual property rights, in each case whether registered or unregistered and including all applications and rights to apply for and be granted, renewals or extensions of, and rights to claim priority from, such rights and all similar or equivalent rights or forms of protection which subsist or will subsist now or in the future in any part of the world.")}</li>
                                        <li>{t("termsAndConditionsPage.para3.1.3", "\"Personal Information\" means the information that You provide while using the mobile app, that We obtain from You. You may request a change to Your Personal Information by contacting Our customer support department on 07870406529")}</li>
                                        <li>{t("termsAndConditionsPage.para3.1.4", "\"App\" means Our web application.")}</li>
                                        <li>{t("termsAndConditionsPage.para3.1.5", "\"Third Party Content\" means the material on the App or accessed through the App that is controlled by parties other than Esitu Solutions Limited.")}</li>
                                        <li>{t("termsAndConditionsPage.para3.1.6", "\"Us\" means Esitu Solutions Limited.")}</li>
                                        <li>{t("termsAndConditionsPage.para3.1.7", "\"We\" means Esitu Solutions Limited.")}</li>
                                        <li>{t("termsAndConditionsPage.para3.1.8", "\"You\" and \"Your\" means the person entering the Agreement or accessing the App as the case may be.")}</li>
                                    </ol>
                                </li>
                            </ol>

                        </li>
                        <li className="li-sub-heading"><span>{t("termsAndConditionsPage.subHeading.4", "Changes to these Terms of Use")}</span>
                            <ol>
                                <li>{t("termsAndConditionsPage.para4.1", "We may change these Terms of Use at any time.")}</li>
                                <li>{t("termsAndConditionsPage.para4.2", "It is Your responsibility to check these Terms of Use in case there are any changes. If You access and use the App after We have made a change to these Terms You shall be treated as having accepted the change.")}</li>
                            </ol>

                        </li>
                        <li className="li-sub-heading"><span>{t("termsAndConditionsPage.subHeading.5", "Accuracy of Content")}</span>
                            <ol>
                                <li>{t("termsAndConditionsPage.para5.1", "The App and Content is provided for Your general information only. It is not intended to amount to advice on which you should rely. You must obtain professional or specialist advice before taking, or refraining from, any action on the basis of the Content on the App.")}</li>
                                <li>{t("termsAndConditionsPage.para5.2", "Although we make reasonable efforts to update the information on our App, we make no representations, warranties or guarantees, whether express or implied that the Content on our App is accurate, complete or up-to-date. For example, distances on our website are calculated as a straight line from point to point, not driving distances using public roads.")}</li>
                                <li>{t("termsAndConditionsPage.para5.3", "We advise that, You should not plan to do, or refrain from doing, anything in reliance upon Content without first checking the accuracy of the relevant Content by some other means. For example, if You propose to establish an itinerary based on number of vehicles, You must check information of that type You obtain on the App with the relevant properties themselves. All such information on this App is necessarily subject to change, sometimes at short notice.")}</li>
                            </ol>

                        </li>
                        <li className="li-sub-heading"><span>{t("termsAndConditionsPage.subHeading.6", " Third Party Content, \"Partners\" and Links from the App")}</span>
                            <ol>
                                <li>{t("termsAndConditionsPage.para6.1", "Where the App contains links to other sites, mobile applications and/ or resources provided by third parties (\"Third Party Sites\"), these links are provided for your information only.")}</li>
                                <li>{t("termsAndConditionsPage.para6.2", "We have no control over the content of those sites or resources.")}</li>
                                <li>{t("termsAndConditionsPage.para6.3", "We are not responsible for the content of Third Party Sites linked to the App or for Third Party content. They are independent of Us, and their content is not endorsed or approved by Us.")}</li>
                                <li>{t("termsAndConditionsPage.para6.4", "\"Partners\" are persons (corporate or otherwise) with whom we have developed affinity products specifically to support Esitu Solutions Limited.")}</li>
                            </ol>

                        </li>
                        <li className="li-sub-heading"><span>{t("termsAndConditionsPage.subHeading.7", "Contact Between Us")}</span>
                            <ol>
                                <li>{t("termsAndConditionsPage.para7.1", "You are responsible for ensuring that Your instructions (including all the details We may require to carry out Your instructions) and any information You give to Us are accurate and complete. We shall not be liable for any loss or damage You suffer if they are in any way inaccurate or incomplete and Our records will be conclusive evidence of Your instructions unless We agree otherwise. Except where required to amend personal data that we process about you, we may in Our absolute discretion refuse to carry out any instruction or reverse any action taken by Us in response to any instruction.")}</li>
                                <li>{t("termsAndConditionsPage.para7.2", "To contact Us with enquiries or complaints about our App, please contact us at:  info@esitusolutions.com or in writing to:")}
                                    <br /> Dryden Enterprise Centre
                                    <br /> Dryden Street
                                    <br /> Nottingham
                                    <br /> NG1 4FQ
                                </li>
                            </ol>

                        </li>
                        <li className="li-sub-heading"><span>{t("termsAndConditionsPage.subHeading.8", "Intellectual Property")}</span>
                            <ol>
                                <li>{t("termsAndConditionsPage.para8.1", "Our App and the Intellectual Property Rights in the Content on the App are owned by or licensed to Us.")}</li>
                                <li>{t("termsAndConditionsPage.para8.2", "Certain names, words, images or logos identifying Us and the products and services featured in the App are the trade marks of Esitu Solutions Limited.")}</li>
                                <li>{t("termsAndConditionsPage.para8.3", "The names and logos of third parties mentioned in the App (whether that appears in the App or in third party content) may be the trade marks, trade names or unregistered trade marks of those third parties and they are used with the permission of such organisations.")}</li>
                                <li>{t("termsAndConditionsPage.para8.4", "You may view, print, download or store temporarily extracts from the App for Your own personal reference or for the purpose of applying to Us to access or use the products and services featured on the App. No other use (including, without limitation, the alteration, deletion, utilisation or extraction) of the Content and materials featured on the App is permitted without Our written permission. Otherwise, than as provided, the App cannot, whether in whole or as to any part, be copied, reproduced, distributed or transmitted in any medium (including, without limitation, by the internet) without Our written permission. You must not modify the paper or digital copies of any Content that you have printed off or downloaded in any way, and you must not use any illustrations, photographs, video or audio sequences or any graphics separately from any accompanying text.")}</li>
                                <li>{t("termsAndConditionsPage.para8.5", "Where downloads of third party software are made available on the App, they are owned by the third party licensor in question, and will be subject to any terms and conditions applied by the relevant third party.")}</li>
                                <li>{t("termsAndConditionsPage.para8.6", "The App may contain proprietary notices (such as moral rights and trade mark notices) and copyright information, the terms of which must be observed. Our status (and that of any identified contributors) as the authors of Content on the App must always be acknowledged.")}</li>
                                <li>{t("termsAndConditionsPage.para8.7", "You must not use any part of the Content on our App for commercial purposes without obtaining a licence to do so from us or our licensors.")}</li>
                                <li>{t("termsAndConditionsPage.para8.8", "If you print off, copy, download or modify any part of the Content of our App in breach of these Terms of Use, your right to use the App will cease immediately and you must, at our option, return or destroy any copies of the materials you have made.")}</li>
                            </ol>

                        </li>
                        <li className="li-sub-heading"><span>{t("termsAndConditionsPage.subHeading.9", "Access to the App ")}</span>
                            <ol>
                                <li>{t("termsAndConditionsPage.para9.1", "Access to our App is chargeable.")}</li>
                                <li>{t("termsAndConditionsPage.para9.2", "In parts of the App You may be enabled to interact with other users or with Us, through (for example) message boards and email. You must ensure that any such interaction by You or through Your email address will not:")}
                                    <ol>
                                        <li>{t("termsAndConditionsPage.para9.2.1", "be capable of infringing the Intellectual Property Rights or other rights of any person;")}</li>
                                        <li>{t("termsAndConditionsPage.para9.2.2", "breach any applicable law or regulation;")}</li>
                                        <li>{t("termsAndConditionsPage.para9.2.3", "breach these Terms of Use;")}</li>
                                        <li>{t("termsAndConditionsPage.para9.2.4", "be used fraudulently or amount to fraudulent misrepresentation; or")}</li>
                                        <li>{t("termsAndConditionsPage.para9.2.5", "be perceived as inappropriate for the App, offensive or misleading.")}</li>
                                    </ol>
                                </li>
                                <li>{t("termsAndConditionsPage.para9.3", "We may remove any postings or other interaction at our entire discretion.")}</li>
                                <li>{t("termsAndConditionsPage.para9.4", "We have no obligation to monitor, censor or edit the content of any material transmitted or received by You or other users of the App. You are responsible for the content of any material You transmit.")}</li>
                                <li>{t("termsAndConditionsPage.para9.5", "\"Equipment\" means all such compatible equipment, software and communications lines (including any public communication lines) required by You to properly access the App.")}</li>
                                <li>{t("termsAndConditionsPage.para9.6", "You are responsible for making all arrangements necessary for you to have access to the App. You are responsible for obtaining and maintaining Your Equipment and for ensuring that it is compatible with the App. We have no responsibility or liability with respect to Your Equipment.")}</li>
                                <li>{t("termsAndConditionsPage.para9.7", "We may change the minimum specification required to access the App at any time. We give no guarantee that you will have access the App on your mobile device (or continue to have access to the App). We shall not be liable to You if any such change in specification results in Your Equipment becoming incompatible with the App or becoming unable to perform, within the App, all of the functions previously performed.")}</li>
                                <li>{t("termsAndConditionsPage.para9.8", "You will be responsible for the cost of all charges You incur in accessing and using the App. We shall have no liability to you in respect of any such charges, costs or expenses that you may incur.")}</li>
                                <li>{t("termsAndConditionsPage.para9.9", "If You access the App outside of a WIFI connection zone, You may incur data charges by using the App. By accessing the App outside of a WIFI connection zone You agree to allow the App access to use your 3G Data.")}</li>
                                <li>{t("termsAndConditionsPage.para9.10", "We do not guarantee that that App, or any Content on it, will always be available or be uninterrupted. Access to the App is permitted on a temporary basis. We may suspend, withdraw, discontinue or change all or any part of the App without notice. We will not be liable to You if for any reason our App is unavailable at any time or for any period. We do not guarantee the speed with which You will be able to access and use the App (as it will depend upon factors such as the specification of Your Equipment and the number of people using the App) or that You will have uninterrupted or continuous access to the App.")}</li>
                                <li>{t("termsAndConditionsPage.para9.11", "You must not:")}
                                    <ol>
                                        <li>{t("termsAndConditionsPage.para9.11.1", "introduce or attempt to introduce any virus or any other contaminant to the App or any of Our computer systems;")}</li>
                                        <li>{t("termsAndConditionsPage.para9.11.2", "in any way attempt to access, alter, de-compile, reverse engineer, destroy or otherwise tamper with any part of the App or any of Our computer systems;")}</li>
                                        <li>{t("termsAndConditionsPage.para9.11.3", "interfere with the use of another person's access to or use of the App;")}</li>
                                        <li>{t("termsAndConditionsPage.para9.11.4", "obtain access to information relating to another person which is on Our computer system;")}</li>
                                        <li>{t("termsAndConditionsPage.para9.11.5", "use or attempt to use the App or any of Our computer systems for any unlawful or immoral purpose.")}</li>
                                    </ol>
                                </li>
                                <li>{t("termsAndConditionsPage.para9.12", "We may suspend or terminate Your access and use (in whole or in part) of the App at any time with or without notice.")}</li>
                                <li>{t("termsAndConditionsPage.para9.13", "You are responsible for getting an appropriate connection with a telecommunications provider in order to access the App.")}</li>
                                <li>{t("termsAndConditionsPage.para9.14", "We shall control, direct and establish technical procedures for using the App (and We may vary them from time to time). You must follow Our instructions and adhere to Our procedures as given on the App when using any the App.")}</li>
                                <li>{t("termsAndConditionsPage.para9.15", "You must ensure that any material and / or information downloaded or otherwise obtained through the use of the App is at Your own discretion and that You will be responsible for any damage to Your Equipment or loss of data that results from the download of such material and / or data.")}</li>
                                <li>{t("termsAndConditionsPage.para9.16", "We shall use reasonable endeavours to keep the App free from viruses and corrupt files but We do not warrant or guarantee that the App is free from infection by viruses, bugs or anything else with contaminating or destructive properties. We recommend that You \"virus check\" all information sent to You by Us. We shall not be liable for any corrupt information sent to You by Us or for any corrupt information You send to Us. You are responsible for configuring your information technology, computer programmes and platform in order to access our site. You should use your own virus protection software.")}</li>
                                <li>{t("termsAndConditionsPage.para9.17", "You must not misuse Our App by knowingly introducing viruses, trojans, worms, logic bombs or other material which is malicious or technologically harmful. You must not attempt to gain unauthorised access to our App, the server on which our App is stored, or any server, computer or database connected to our App. You must not attack our App via a denial-of-service attack or a distributed denial-of service attack. By breaching this provision, you would commit a criminal offence under the Computer Misuse Act 1990. We will report any such breach to the relevant law enforcement authorities, and We will co-operate with those authorities by disclosing Your identity to them. In the event of such a breach, Your right to use our App will cease immediately.")}</li>
                                <li>{t("termsAndConditionsPage.para9.18", "You are also responsible for ensuring that all persons who access our App through your internet connection are aware of these Terms of Use and any other applicable terms and conditions, and that they comply with them.")}</li>
                                <li>{t("termsAndConditionsPage.para9.19", "We regularly update the App. However, We do not warrant that the content of the App or the products and services featured are available (either as featured or at all). The content of and the products and services featured in the App are subject to change at any time without notice.")}</li>
                            </ol>

                        </li>
                        <li className="li-sub-heading"><span>{t("termsAndConditionsPage.subHeading.10", "Acceptable Use and Content Standards")}</span>
                            <ol>
                                <li>{t("termsAndConditionsPage.para10.1", "You may use the App only for lawful purposes. You may not use the App:")}
                                    <ol>
                                        <li>{t("termsAndConditionsPage.para10.1.1", "In any way that breaches any applicable local, national or international law or regulation.")}</li>
                                        <li>{t("termsAndConditionsPage.para10.1.2", "In any way that is unlawful or fraudulent or has any unlawful or fraudulent purpose or effect.")}</li>
                                        <li>{t("termsAndConditionsPage.para10.1.3", "For the purpose of harming or attempting to harm minors in any way.")}</li>
                                        <li>{t("termsAndConditionsPage.para10.1.4", "To send, knowingly receive, upload, download, use or re-use any material which does not comply with our content standards in clause 10.3 and 10.4 below.")}</li>
                                        <li>{t("termsAndConditionsPage.para10.1.5", "To transmit, or procure the sending of, any unsolicited or unauthorised advertising or promotional material or any other form of similar solicitation (spam).")}</li>
                                        <li>{t("termsAndConditionsPage.para10.1.6", "To knowingly transmit any data, send or upload any material that contains viruses, Trojan horses, worms, time-bombs, keystroke loggers, spyware, adware or any other harmful programs or similar computer code designed to adversely affect the operation of any computer software or hardware.")}</li>
                                    </ol>
                                </li>
                                <li>{t("termsAndConditionsPage.para10.2", "You also agree:")}
                                    <ol>
                                        <li>{t("termsAndConditionsPage.para10.2.1", "Not to access without authority, interfere with, damage or disrupt:")}
                                            <ol>
                                                <li>{t("termsAndConditionsPage.para10.2.1.1", "any part of the App;")}</li>
                                                <li>{t("termsAndConditionsPage.para10.2.1.2", "any software used in the provision of the App; or")}</li>
                                                <li>{t("termsAndConditionsPage.para10.2.1.3", "any equipment or network or software owned or used by any third party.")}</li>
                                            </ol>
                                        </li>
                                    </ol>
                                </li>
                                <li>{t("termsAndConditionsPage.para10.3", "All material which you contribute to the App (contributions), and to social media which is linked to the App must:")}
                                    <ol>
                                        <li>{t("termsAndConditionsPage.para10.2.1.1", "Be accurate (where they state facts)")}</li>
                                        <li>{t("termsAndConditionsPage.para10.2.1.1", "Be genuinely held (where they state opinions).")}</li>
                                        <li>{t("termsAndConditionsPage.para10.2.1.1", "Comply with applicable law in the UK and in any country from which they are posted.")}</li>
                                    </ol>
                                </li>
                                <li>{t("termsAndConditionsPage.para10.4", "Contributions must not:")}
                                    <ol>
                                        <li>{t("termsAndConditionsPage.para10.4.1", "Contain any material which is defamatory of any person.")}</li>
                                        <li>{t("termsAndConditionsPage.para10.4.2", "Contain any material which is obscene, offensive, hateful or inflammatory.")}</li>
                                        <li>{t("termsAndConditionsPage.para10.4.2", "Promote sexually explicit material.")}</li>
                                        <li>{t("termsAndConditionsPage.para10.4.4", "Promote violence.")}</li>
                                        <li>{t("termsAndConditionsPage.para10.4.5", "Promote discrimination based on race, sex, religion, nationality, disability, sexual orientation or age.")}</li>
                                        <li>{t("termsAndConditionsPage.para10.4.6", "Infringe any copyright, database right or trade mark of any other person.")}</li>
                                        <li>{t("termsAndConditionsPage.para10.4.7", "Be likely to deceive any person.")}</li>
                                        <li>{t("termsAndConditionsPage.para10.4.8", "Be made in breach of any legal duty owed to a third party, such as a contractual duty or a duty of confidence.")}</li>
                                        <li>{t("termsAndConditionsPage.para10.4.9", "Promote any illegal activity.")}</li>
                                        <li>{t("termsAndConditionsPage.para10.4.10", "Be threatening, abuse or invade another's privacy, or cause annoyance, inconvenience or needless anxiety.")}</li>
                                        <li>{t("termsAndConditionsPage.para10.4.11", "Be likely to harass, upset, embarrass, alarm or annoy any other person.")}</li>
                                        <li>{t("termsAndConditionsPage.para10.4.12", "Be used to impersonate any person, or to misrepresent your identity or affiliation with any person.")}</li>
                                        <li>{t("termsAndConditionsPage.para10.4.13", "Give the impression that they emanate from us, if this is not the case.")}</li>
                                        <li>{t("termsAndConditionsPage.para10.4.14", "Advocate, promote or assist any unlawful act such as (by way of example only) copyright infringement or computer misuse.")}</li>
                                    </ol>
                                </li>
                            </ol>
                        </li>
                        <li className="li-sub-heading"><span>{t("termsAndConditionsPage.subHeading.11", "Liability")}</span>
                            <ol>
                                <li>{t("termsAndConditionsPage.para11.1", "Nothing in these Terms of Use excludes or limits either party's liability for death or personal injury arising from that party's negligence, fraud or fraudulent misrepresentation or any other liability that cannot be excluded or limited by English law.")}</li>
                                <li>{t("termsAndConditionsPage.para11.2", "To the extent permitted by law, we exclude all conditions, warranties, representations or other terms which may apply to our App or any Content on it, whether express or implied.")}</li>
                                <li>{t("termsAndConditionsPage.para11.3", "Subject to clause 11.1, We shall not be liable to You for any loss or damage, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, even if foreseeable, arising under or in connection with:")}
                                    <ol>
                                        <li>{t("termsAndConditionsPage.para11.3.1", "Use or, or inability to use, the App; or")}</li>
                                        <li>{t("termsAndConditionsPage.para11.3.2", "Use of, or reliance on any Content displayed on the App.")}</li>
                                    </ol>
                                </li>
                                <li>{t("termsAndConditionsPage.para11.4", "If you are a business user, please note that in particular, we will not be liable for:")}
                                    <ol>
                                        <li>{t("termsAndConditionsPage.para11.4.1", "Loss of profits, sales, business or revenue;")}</li>
                                        <li>{t("termsAndConditionsPage.para11.4.2", "Business interruption;")}</li>
                                        <li>{t("termsAndConditionsPage.para11.4.3", "Loss of anticipated savings;")}</li>
                                        <li>{t("termsAndConditionsPage.para11.4.4", "Loss of business opportunity, goodwill or reputation; or")}</li>
                                        <li>{t("termsAndConditionsPage.para11.4.5", "Any indirect or consequential loss or damage.")}</li>
                                    </ol>
                                </li>
                                <li>{t("termsAndConditionsPage.para11.5", "If you are a consumer user, please note that we only provide our App for domestic and private use. You agree not to use the App for any commercial or business purposes, and We have no liability to You for any loss of profit, loss of business, business interruption, or loss of business opportunity.")}</li>
                                <li>{t("termsAndConditionsPage.para11.6", "We will not be liable for any loss or damage caused by a virus, distributed denial-of-service attack, or other technologically harmful material that may infect Your computer equipment, computer programs, data or other proprietary material due to Your use of the App or to Your downloading of any content on it, or on any website linked to it.")}</li>
                                <li>{t("termsAndConditionsPage.para11.7", "We assume no responsibility for the content of websites linked on our site. Such links should not be interpreted as endorsement by us of those linked websites. We will not be liable for any loss or damage that may arise from Your use of them.")}</li>
                                <li>{t("termsAndConditionsPage.para11.8", "Different limitations and exclusions of liability will apply to liability arising as a result of the supply of any goods by use to you, which will be set out in our Terms and conditions of supply.")}</li>
                            </ol>

                        </li>
                        <li className="li-sub-heading"><span>{t("termsAndConditionsPage.subHeading.12", "Generally")}</span>
                            <ol>
                                <li>{t("termsAndConditionsPage.para12.1", "Each of these conditions is separate from all other conditions, so that if one condition is found to be void or otherwise unenforceable it will not affect the validity of any of the others.")}</li>
                                <li>{t("termsAndConditionsPage.para12.2", "If We do not enforce any of the rights We have under this Agreement, or if We delay in enforcing them, that does not prevent Us from taking any action to enforce Our rights in the future.")}</li>
                            </ol>
                        </li>
                        <li className="li-sub-heading"><span>{t("termsAndConditionsPage.subHeading.13", "Applicable Law")}</span>
                            <ol>
                                <li>{t("termsAndConditionsPage.para13.1", "If you are a consumer, please note that these Terms of Use, its subject matter and its formation, are governed by English law. You and We both agree to that the courts of England and Wales will have exclusive jurisdiction.")}</li>
                                <li>{t("termsAndConditionsPage.para13.2", "If You are a business, these Terms of Use, its subject matter and its formation (and any non-contractual disputes or claims) are governed by English law. We both agree to the exclusive jurisdiction of the courts of England and Wales.")}</li>
                            </ol>
                        </li>
                        <li className="li-sub-heading"><span>{t("termsAndConditionsPage.subHeading.14", "Queries or Complaints")}</span>
                            <ol>
                                <li>{t("termsAndConditionsPage.para14.1", "If you have any queries or complaints about the App or these Terms of Use, please contact Us via the feedback button in the App, by email (info@esitusolutions.com), by phone on [07870406529], or by writing to us at:")}
                                    <br /> Dryden Enterprise Centre
                                    <br /> Dryden Street
                                    <br /> Nottingham
                                    <br /> NG1 4FQ
                                </li>
                                <li>{t("termsAndConditionsPage.para14.2", "If you contact us then we will make every effort to respond to You directly, however, due to email volumes we may not always be able to respond directly to every user. We will take on board email feedback and ensure it feeds into future developments of the App.")}</li>
                            </ol>
                        </li>
                        <br/>
                        <p>Consent from end users in the E.U. for the transfer, storage, and use of their information in the the United States and other countries where Twitter and/or Crashlytics (as applicable) operate.</p>
                    </ol>

                </div>
            </MainContainer>
        </Background>
    );
};


 

 
