import gql from "graphql-tag";
import { Guid } from "guid-string";
import { questionFields } from "../generated/questionFields";

export const questionFieldsFragment = gql`
    fragment questionFields on Question {
        id
        questionType
        name
        imageBlobReferenceId
        videoBlobReferenceId
        archived
        questionText
        questionTextImageBlobReferenceId
        preQuestionText
        maximumClicks
        maxScore
        timeLimitSeconds
        numberOfSubQuestions
        driverMetricId
    }
`;


export type Question = Omit<questionFields, '__typename'>;

export const questionDefaultValues = (): Partial<Question> => ({
    id: Guid.newGuid(),
    name: '',
    questionType: '',
    questionText: '',
    imageBlobReferenceId: null,
    videoBlobReferenceId: null,
    questionTextImageBlobReferenceId: null,
    preQuestionText: '',
    maximumClicks: 10,
    maxScore: 100,
    timeLimitSeconds: 30,
    numberOfSubQuestions: 1,
    driverMetricId: null,
});