import gql from "graphql-tag";
import { Guid } from "guid-string";
import moment from "moment";
import { questionResponseEventFields } from "../generated/questionResponseEventFields";

export const questionResponseEventFieldsFragment = gql`
    fragment questionResponseEventFields on QuestionResponseEvent {
        id
        questionId
        userId
        assessmentId
        assessmentItemId
        assessmentSessionId
        eventDate
        cancelEventDate
        archived
        questionAnswerId
        score
        isCorrect
        responseTimeSeconds
        clickPositionX
        clickPositionY
        discriminationGroup
        textInput
        subQuestionNumber
    }
`;


export type QuestionResponseEvent = Omit<questionResponseEventFields, '__typename'>;

// Default values.
export const questionResponseEventDefaultValues = (): Partial<QuestionResponseEvent> => ({
    id: Guid.newGuid(),
    questionId: undefined,
    questionAnswerId: undefined,
    archived: false,
    assessmentId: undefined,
    assessmentItemId: undefined,
    assessmentSessionId: undefined,
    cancelEventDate: undefined,
    clickPositionX: 0,
    clickPositionY: 0,
    discriminationGroup: '',
    eventDate: moment().toISOString(),
    isCorrect: false,
    responseTimeSeconds: 0,
    score: 0,
    textInput: '',
    userId: '',
    subQuestionNumber: 1,
});