import { Dispatch, SetStateAction, useCallback } from 'react';
import { atom, useRecoilState } from 'recoil';
import { persistAtom } from '../../configure/recoilPersistConfig';

interface DashboardActiveTab {
    dashboard: string,
    activeTab: string,
}

export const activeDashboardTabState = atom<Array<DashboardActiveTab>>({
    key: 'activeDashboardTabState',
    default: [],
    effects_UNSTABLE: [persistAtom],
    
});

/**
 * Hook that returns the active tab for a specific dashboard so it can be preserved as we navigate around and between sessions.
 */
export function useActiveDashboardTab<T extends string>(dashboard: string, defaultValue: T): [T, Dispatch<SetStateAction<T>>] {
    const [array, setArray] = useRecoilState(activeDashboardTabState);

    const myDashboard = array.find(it => it.dashboard === dashboard);
    const activeTab = myDashboard?.activeTab as T ?? defaultValue;

    const setActiveTab = useCallback((value: SetStateAction<T>) => setArray(prevState => {
        let newValue = value;

        // If we have a function, execute it.
        if (value instanceof Function) {
            const myPrevState = prevState.find(it => it.dashboard === dashboard)?.activeTab as T ?? defaultValue;
            newValue = value(myPrevState);
        }

        // Update the array.
        return [
            ...prevState.filter(it => it.dashboard !== dashboard),
            { dashboard: dashboard, activeTab: newValue } as DashboardActiveTab,
        ];
    }), [setArray, dashboard, defaultValue])

    return [activeTab, setActiveTab];
}