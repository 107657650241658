import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { ImportUserAssessmentAssignmentsInput } from '../generated/globalTypes';
import { importUserAssessmentAssignmentsMutationVariables, importUserAssessmentAssignmentsMutation, importUserAssessmentAssignmentsMutation_importUserAssessmentAssignments } from "../generated/importUserAssessmentAssignmentsMutation";

///**
// * Get a callback to import a list of users and an assessment to assign them to (WILL NOT CREATE USERS).
// */
export function useImportUserAssessmentAssignmentsCallback(): [(model: ImportUserAssessmentAssignmentsInput) => Promise<Array<importUserAssessmentAssignmentsMutation_importUserAssessmentAssignments>>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<importUserAssessmentAssignmentsMutation, importUserAssessmentAssignmentsMutationVariables>(
        gql`
            mutation importUserAssessmentAssignmentsMutation ($model: ImportUserAssessmentAssignmentsInput!) {
                importUserAssessmentAssignments(model: $model) {
                    id
                    rowNumber
                    successful
                    errors
                    message
                }
            }
        `,
        {
        }
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (model: ImportUserAssessmentAssignmentsInput) => {
        const result = await mutationAction({ variables: { model: model } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }

        return result.data?.importUserAssessmentAssignments ?? [];
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
