import { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Button, FormGroup, FormText, Label, Input } from "reactstrap";
import { BlobReference } from "../../../../api/main/models/BlobReference";
import { Question } from "../../../../api/main/models/Question";
import { QuestionAnswer, questionAnswerDefaultValues } from "../../../../api/main/models/QuestionAnswer";
import { HtmlEditor } from "../../../../shared/htmlEditor";
import { ModelArrayChanges } from "../../../../shared/useChanges";
import { QuestionAnswerResponseTimeVideo } from "./QuestionAnswerResponseTimeVideo";
import { useDisplayOrder } from "../../../shared/useDisplayOrder/useDisplayOrder";
import { ValidationErrors } from "pojo-validator";
import { UploadedVideoPreview } from "../../../shared/uploadedVideoPreview/UploadedVideoPreview";

export interface QuestionPromptResponseTimeVideoTabProps {
    model: Question | undefined,
    change: (changes: Partial<Question>) => void,

    videoBlob: BlobReference | undefined | null,
    imageBlob: BlobReference | undefined | null,

    answersManager: ModelArrayChanges<QuestionAnswer, string>,

    validateQuestionAnswer: (model: QuestionAnswer) => boolean,
    questionAnswerValidationErrors: (id: string) => ValidationErrors,
}

/**
 * Tab for maintaining the prompt and response time windows for ResponseTimeVideo questions.
 * @param props
 */
export const QuestionPromptResponseTimeVideoTab = (props: QuestionPromptResponseTimeVideoTabProps) => {
    const {
        model,
        change,

        videoBlob,
        imageBlob,

        answersManager,

        validateQuestionAnswer,
        questionAnswerValidationErrors,
    } = props;

    const { t } = useTranslation();

    // Order the answers so they show in and can be managed by displayOrder.
    const [orderedAnswers, {
        canMoveUp: canMoveAnswerUp,
        moveUp: moveAnswerUp,
        canMoveDown: canMoveAnswerDown,
        moveDown: moveAnswerDown,
    }] = useDisplayOrder(answersManager);

    // Video playback tracking.
    const currentVideoTime = useRef<number>(0);

    // Adding of an answer.
    const addAnswer = useCallback(() => {
        answersManager.addFor({
            ...questionAnswerDefaultValues(),

            questionId: model?.id,
            startResponseTimeSeconds: currentVideoTime.current,
            endResponseTimeSeconds: currentVideoTime.current,
        });
    }, [answersManager, model?.id]);

    return (
        <>
            <FormGroup>
                <Label htmlFor="questionText">{t('questionPromptResponseTimeVideoTab.preQuestionText', 'Prompt before video')}</Label>

                <HtmlEditor value={model?.preQuestionText} onChange={html => change({ preQuestionText: html })} />
            </FormGroup>

            <FormGroup>
                <Label htmlFor="questionText">{t('questionPromptResponseTimeVideoTab.questionText', 'Prompt after video')}</Label>

                <HtmlEditor value={model?.questionText} onChange={html => change({ questionText: html })} />
            </FormGroup>

            <FormGroup>
                <Label htmlFor="answers">{t('questionPromptResponseTimeVideoTab.answers', 'Response windows (Temporal scoring windows)')}</Label>

                <UploadedVideoPreview src={videoBlob?.url ?? ''} poster={imageBlob?.url} onTimeUpdate={e => { currentVideoTime.current = e.currentTarget.currentTime; }} />
                <FormText>
                    {t('questionPromptResponseTimeVideoTab.videoPlaybackText', 'Playback or select a time within the video to add or update response windows.')}
                </FormText>

                <FormGroup>
                    <Label htmlFor="questionText">{t('questionPromptResponseTimeVideoTab.numberOfSubQuestions', 'Number of parts to this question')}</Label>

                    <Input name="numberOfSubQuestions" type="number" min={-100} max={100}
                        value={model?.numberOfSubQuestions ?? ''}
                        onChange={e => {
                            const value = e.currentTarget.valueAsNumber;
                            let maxScore = value * 100;
                            if (maxScore < 0) { maxScore = 0; }

                            change({
                                numberOfSubQuestions: value,
                                maxScore: maxScore,
                            });
                        }}
                    />
                </FormGroup>

                <div className="mt-2">
                    {
                        orderedAnswers.map(item => (
                            <QuestionAnswerResponseTimeVideo key={item.id}
                                model={item}
                                change={changes => answersManager.changeFor(item.id, changes)}
                                remove={() => answersManager.removeFor(item.id)}

                                isOpenInitially={!!answersManager.added.find(it => it.id === item.id)}

                                moveUp={() => moveAnswerUp(item.id)} canMoveUp={canMoveAnswerUp(item.id)}
                                moveDown={() => moveAnswerDown(item.id)} canMoveDown={canMoveAnswerDown(item.id)}

                                validate={() => validateQuestionAnswer(item)}
                                validationErrors={questionAnswerValidationErrors(item.id)}

                                currentVideoTime={currentVideoTime}
                                numberOfSubQuestions={model?.numberOfSubQuestions ?? 0}
                                />
                            ))
                    }
                </div>

                <Button color="primary" outline onClick={() => addAnswer()}>
                    {t('questionPromptResponseTimeVideoTab.addAnswer', 'Add response window')}
                </Button>
            </FormGroup>

            <FormGroup>
                <Label htmlFor="maximumClicks">{t('questionPromptLocationDiscriminationImageTab.maximumClicks', 'Maximum clicks before score deduction')}</Label>
                <Input name="maximumClicks" type="number" min={0} value={model?.maximumClicks ?? ''} onChange={e => change({ maximumClicks: e.currentTarget.valueAsNumber })} />
            </FormGroup>
        </>
        );
};