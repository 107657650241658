import * as React from "react";
import { Form, FormGroup, Label, Spinner, Card, CardHeader, CardBody } from "reactstrap";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { useParams } from "react-router-dom";
import { useChanges } from "../../shared/useChanges";
import { useValidatorCallback } from "pojo-validator-react";
import { ValidatedInput } from "pojo-validator-reactstrap";
import { ButtonAsync } from "reactstrap-buttonasync"
import { StudyLoginRequest } from "../../api/account";
import { useTranslation } from "react-i18next";
import { FormButtons } from "../shared/FormButtons";
import { MainContainer } from "../shared/MainContainer";
import { Background } from "../shared/background/Background";
import { Guid } from "guid-string";
import { useStudyLoginCallback } from "../../api/account/useStudyLoginCallback";

/**
 * Study login screen where users are able to log in to a study.
 */
export const StudyLogin = () => {
    const { t } = useTranslation();
    //const { data: { externalAuthenticationSchemes }, isLoading, errors: loadErrors } = useExternalAuthenticationSchemes();
    const [studyLogin, { isExecuting: isLoggingIn, errors: loginErrors }] = useStudyLoginCallback();
    
    // Get the study from the route parameters.
    const { studyUniqueCode: studyUniqueCodeParam } = useParams<{ studyUniqueCode: string }>();

    // Get the user identifier from the query string, we allow several different query string parameters to be used.
    const params = new URLSearchParams(window.location.search);
    const userIdentifierParam = params.get('user') || params.get('User')
        || params.get('participant') || params.get('Participant')
        || params.get('prolific_pid') || params.get('PROLIFIC_PID') || params.get('Prolific_PID')
        || params.get('session') || params.get('Session')
        || params.get('id') || params.get('Id');
    // The default if we don't have a parameter is to generate a random guid.
    const [uniqueIdentifierDefault] = React.useState<string>(() => Guid.newGuid());
    
    const { model, change } = useChanges<StudyLoginRequest>({ studyUniqueCode: studyUniqueCodeParam, userIdentifier: userIdentifierParam || uniqueIdentifierDefault, });

    /**
     * Validate the model before trying to use it.
     */
    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            studyUniqueCode: () => !model.studyUniqueCode ? t('studyLogin.studyUniqueCode.required', 'Study code is required.  You should have received this from the study organiser.') : '',
            userIdentifier: () => !model.userIdentifier ? t('studyLogin.userIdentifier.required', 'A unique identifier for you as a study participant is required.') : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, [model]);

    /**
     * Perform a study login and handle the result.
     */
    const [isDoingFullPageRedirect, setIsDoingFullPageRedirect] = React.useState<boolean>(false);
    const performStudyLogin = React.useCallback(async (): Promise<void> => {
        if (!validate()) {
            return;
        }

        let result = await studyLogin(model);

        if (result) {
            if (result.succeeded) {
                // Redirect the whole page (not just react) to the returnUrl to let the server handle as well as the client.
                setIsDoingFullPageRedirect(true);
                window.location.href = result.returnUrl;
            }
        }
    }, [studyLogin, model, validate]);

    // If we have a study id, we can auto login.
    const [autoLoginStarted, setAutoLoginStarted] = React.useState<boolean>(false);
    React.useEffect(() => {
        if (studyUniqueCodeParam && !autoLoginStarted) {
            setAutoLoginStarted(true);
            performStudyLogin();
        }
    }, [studyUniqueCodeParam, performStudyLogin, autoLoginStarted, setAutoLoginStarted]);

    // Render the UI.
    return (
        <Background centerChildren="vertically">
            <MainContainer color="transparent">
                <Card color="dark">
                    <CardHeader>
                        <h1>
                            {t('studyLogin.signInHeading', 'Participate in study')}
                        </h1>
                    </CardHeader>
                    <CardBody>
                        <Form onSubmit={async e => { e.preventDefault(); await performStudyLogin(); }}>
                            <AlertOnErrors simple errors={[loginErrors]} />
                            <FormGroup>
                                <Label htmlFor="studyUniqueCode">{t('studyLogin.studyUniqueCode.label', 'Study')}</Label>
                                <ValidatedInput type="text" name="studyUniqueCode" value={model.studyUniqueCode} onChange={e => change({ studyUniqueCode: e.currentTarget.value })} onBlur={e => validate('studyUniqueCode')} validationErrors={validationErrors['studyUniqueCode']} />
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor="userIdentifier">{t('studyLogin.userIdentifier.label', 'Participant identifier')}</Label>
                                <ValidatedInput type="text" name="userIdentifier" value={model.userIdentifier} onChange={e => change({ userIdentifier: e.currentTarget.value })} onBlur={e => validate('userIdentifier')} validationErrors={validationErrors['userIdentifier']} />
                            </FormGroup>

                            <FormButtons>
                                <ButtonAsync type="submit" color="primary" isExecuting={isLoggingIn || isDoingFullPageRedirect}
                                    executingChildren={<><Spinner size="sm" /> {t('studyLogin.loggingIn', 'Checking...')}</>}>
                                    {t('studyLogin.signIn', 'Start study')}
                                </ButtonAsync>
                            </FormButtons>
                        </Form>
                    </CardBody>
                </Card>
            </MainContainer>
        </Background>
    );
};
