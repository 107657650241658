import * as React from 'react';
import { ProfileCreateInput, ProfileUpdateInput } from '../generated/globalTypes';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useCreateProfileCallback } from './useCreateProfileCallback';
import { useUpdateProfileCallback } from './useUpdateProfileCallback';
import { Profile } from '../models/Profile';

/**
 * Get a callback to save a Profile in the store using either a create or update.
 */
export function useSaveProfileCallback(options: SaveInStoreOptions<Profile, string> = {}) {
    const [_create, createStatus] = useCreateProfileCallback();
    const create = React.useCallback((model: Partial<Profile>) => _create(model as ProfileCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateProfileCallback();
    const update = React.useCallback((id: string, changes: Partial<Profile>) => _update(id, changes as ProfileUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
