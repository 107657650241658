/**
 * Subsitute the special placeholders in the feedback HTML.
 * @param html
 */
export function subsitutePlaceholders(value: string, subsitutions: {[key: string]: any}): string {
    let ret = value;

    for (const key in subsitutions) {
        const keyValue = subsitutions[key];
        ret = ret.replace('{' + key + '}', keyValue);
    }

    return ret;
}