import gql from "graphql-tag";
import { Guid } from "guid-string";
import moment from "moment";
import { assessmentSessionFields } from "../generated/assessmentSessionFields";

export const assessmentSessionFieldsFragment = gql`
    fragment assessmentSessionFields on AssessmentSession {
        id
        assessmentId
        userId
        subscriptionId
        archived
        startDate
        endDate
        totalScore
        currentPosition
        isFinished
        sessionInitializationJson
        markedSuspicious
        browserDataJson
    }
`;


export type AssessmentSession = Omit<assessmentSessionFields, '__typename'>;

// Default values.
export const assessmentSessionDefaultValues = (): Partial<AssessmentSession> => ({
    id: Guid.newGuid(),
    assessmentId: undefined,
    userId: undefined,
    subscriptionId: undefined,
    archived: false,
    startDate: moment().toISOString(),
    endDate: null,
    totalScore: 0,
    currentPosition: 0,
    isFinished: false,
    sessionInitializationJson: '',
    markedSuspicious: false,
});