import * as React from 'react';
import { UserAssessmentAssignmentCreateInput, UserAssessmentAssignmentUpdateInput } from '../generated/globalTypes';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useCreateUserAssessmentAssignmentCallback } from './useCreateUserAssessmentAssignmentCallback';
import { useUpdateUserAssessmentAssignmentCallback } from './useUpdateUserAssessmentAssignmentCallback';
import { UserAssessmentAssignment } from '../models/UserAssessmentAssignment';

/**
 * Get a callback to save a UserAssessmentAssignment in the store using either a create or update.
 */
export function useSaveUserAssessmentAssignmentCallback(options: SaveInStoreOptions<UserAssessmentAssignment, string> = {}) {
    const [_create, createStatus] = useCreateUserAssessmentAssignmentCallback();
    const create = React.useCallback((model: Partial<UserAssessmentAssignment>) => _create(model as UserAssessmentAssignmentCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateUserAssessmentAssignmentCallback();
    const update = React.useCallback((id: string, changes: Partial<UserAssessmentAssignment>) => _update(id, changes as UserAssessmentAssignmentUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
