import * as React from 'react';
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { AsyncLoadResult, AsyncLoadOptions } from '../../../shared/abstractStore';
import { profileForInviteQuery, profileForInviteQueryVariables } from '../generated/profileForInviteQuery';
import { profileFieldsFragment } from '../models/Profile';
import { mainApiConfig } from '../../../configure/mainApiConfig';

/**
 * Get a Profile from the store using the information available when confirming an invite.
 * 
 * This exists because useProfile() is secured to only load information for authenticated users.  This method exists to use the details of an invite as
 * permission to load the details of the related profile.
 * 
 * This version gets its details from the query string invite code.
 * 
 * @param id
 * @param options
 */
export function useProfileForInvite(options: AsyncLoadOptions = {}): AsyncLoadResult<profileForInviteQuery> {
    // Get the details from the query string.
    const { userId, code } = React.useMemo(() => {
        const params = new URLSearchParams(window.location.search);
        const userId = params.get('userId') ?? params.get('UserId') ?? '';
        const code = params.get('code') ?? params.get('Code') ?? '';
        return { userId, code };
    }, []);

    // Query the data we need form the api.
    const [load, { data, loading, error, refetch }] = useLazyQuery<profileForInviteQuery, profileForInviteQueryVariables>(
        gql`
        query profileForInviteQuery ($userId: ID!, $code: String!) {
            model: profileForInvite(userId: $userId, code: $code) {
                ...profileFields
            }
        }

        ${profileFieldsFragment}
        `,
        {
            variables: {
                userId: userId,
                code: code
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load])

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error
    }), [data, refresh, loading, error]);
}
