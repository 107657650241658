import { mainApiConfig } from "./mainApiConfig";
import { CachePersistor } from 'apollo3-cache-persist';

import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";

const cache = new InMemoryCache();

// Load cache from the persisited storage.
export const apolloCachePersistor = new CachePersistor({
    cache,
    storage: (window.localStorage as any),
});
apolloCachePersistor.restore();

const link = new HttpLink({
    uri: mainApiConfig.endpoint,
    credentials: 'same-origin',
});

export const apolloClient = new ApolloClient({
    link: link,
    cache: cache,
});