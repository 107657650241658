import gql from "graphql-tag";
import { roleGroupFields } from "../generated/roleGroupFields";

export const roleGroupFieldsFragment = gql`
    fragment roleGroupFields on RoleGroup {
        id
        name
    }
`;


export type RoleGroup = Omit<roleGroupFields, '__typename'>;