import { ReactNode, useMemo } from "react";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { useTranslation } from "react-i18next";
import { AssessmentType } from "../../../../api/main/models/codeOnly/AssessmentType";
import { overallRiskService } from "../../../../services/OverallRiskService";
import { QuestionScore } from "../utilities/calculateQuestionScore";
import "./scoreProgressBar.scss";

export interface ScoreProgressBarProps {
    value: QuestionScore,
    assessmentType?: AssessmentType,
    title?: ReactNode,
    size?: 'sm' | 'md' | 'lg',
}

/**
 * ProgressBar showing a score.
 * @param props
 */
export const ScoreProgressBar = (props: ScoreProgressBarProps) => {
    const {
        value,
        assessmentType = AssessmentType.Assessment,
        title,
        size = 'md'
    } = props;

    const { t } = useTranslation();

    const riskName = useMemo(() => {
        return overallRiskService.getRiskName(value.riskCategory);
    }, [value]);
    

    return (
        <div className={`score-progress-bar score-progress-bar-${assessmentType} score-progress-bar-${assessmentType}-${value.riskCategory} score-progress-bar score-progress-bar-${size}`}>
            <CircularProgressbarWithChildren value={value.score}>
                <div className="score-progress-bar-heading">
                    {title ?? t('scoreProgressBar.heading', 'Score')}
                </div>
                <div className="score-progress-bar-value">
                    {t('common.percent', '{{value}}%', { value: value.score })}
                </div>
                <div className="score-progress-bar-risk-name">
                    {
                        assessmentType === AssessmentType.TrainingModule ? t('scoreProgressBar.riskName.training', 'Training')
                            : riskName
                    }
                </div>
            </CircularProgressbarWithChildren>
        </div>
        );
};