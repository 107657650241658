import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { LinkContainer } from "react-router-bootstrap";
import { Button, ButtonDropdown, ButtonGroup, DropdownItem, DropdownMenu, DropdownToggle, Table } from "reactstrap";
import { useToggleStateArray } from "use-toggle-state";
import { SdkClient } from "../../../api/main/models/SdkClient";
import { SdkWebhook } from "../../../api/main/models/SdkWebhook";
import { NoResultsFound } from "../../shared/NoResultsFound";
import { TableRowButtons } from "../../shared/TableRowButtons";

export interface SdkWebhooksTableProps {
    items: Array<SdkWebhook> | undefined,
    isLoading: boolean,
    sdkClients: Array<SdkClient> | undefined,
}

/**
 * Table that shows the loaded SdkWebhooks.
 */
export const SdkWebhooksTable = (props: SdkWebhooksTableProps) => {
    const {
        items,
        isLoading,
        sdkClients,
    } = props;

    const { t } = useTranslation();
    const history = useHistory();

    // Handle the dropdown of the menus..
    const [isMenuOpen, toggleMenuOpen] = useToggleStateArray<string>([]);


    // Render the UI.
    //
    return (
        <>
            <Table responsive striped>
                <thead>
                    <tr>
                        <th>
                            {t('sdkWebhooksTable.table.headings.sdkClient', 'Application')}
                        </th>
                        <th>
                            {t('sdkWebhooksTable.table.headings.events', 'Events')}
                        </th>
                        <th>
                            {t('sdkWebhooksTable.table.headings.url', 'Url')}
                        </th>
                        <th>
                            {t('sdkWebhooksTable.table.headings.headers', 'Headers')}
                        </th>
                        <th>&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        items?.map((item) => (
                            <tr key={item.id} onDoubleClick={() => { history.push(`/manage/sdk/webhooks/edit/${item.id}`); }}>
                                <td>
                                    {sdkClients?.find(client => client.id === item.sdkClientId)?.name}
                                </td>
                                <td>
                                    {
                                        (JSON.parse(item.eventsJson) as Array<string>).map((event, index) => (
                                            <div key={index}>
                                                {event}
                                            </div>
                                        ))
                                    }
                                </td>
                                <td>
                                    {item.url}
                                </td>
                                <td>
                                    {
                                        (JSON.parse(item.headersJson) as Array<{ name: string, value: string }>).map((header, index) => (
                                            <div key={index}>
                                                {header.name}: {header.value}
                                            </div>
                                        ))
                                    }
                                </td>

                                <td>
                                    <TableRowButtons>
                                        <ButtonGroup>
                                            <LinkContainer to={`/manage/sdk/webhooks/edit/${item.id}`}>
                                                <Button color="primary" outline>
                                                    <FontAwesomeIcon icon="edit" />
                                                    <> {t('common.edit', 'Edit')}</>
                                                </Button>
                                            </LinkContainer>
                                            <ButtonDropdown isOpen={isMenuOpen(item.id)} toggle={() => toggleMenuOpen(item.id)}>
                                                <DropdownToggle color="primary" outline caret>
                                                    <span className="sr-only">{t('common.menuDropdown', 'More')}</span>
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <LinkContainer to={`/manage/sdk/webhooks/delete/${item.id}`}>
                                                        <DropdownItem className="text-danger">
                                                            <FontAwesomeIcon icon="trash" />
                                                            <> {t('common.delete', 'Delete')}</>
                                                        </DropdownItem>
                                                    </LinkContainer>
                                                </DropdownMenu>
                                            </ButtonDropdown>
                                        </ButtonGroup>
                                    </TableRowButtons>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
            <ConditionalFragment showIf={items?.length === 0 && !isLoading}>
                <NoResultsFound>
                    {t('sdkWebhooksTable.noApiKeys', 'You have not connected any webhooks yet.')}
                </NoResultsFound>
            </ConditionalFragment>

            <div className="mt-2">
                <LinkContainer to="/manage/sdk/webhooks/add">
                    <Button color="primary" outline>
                        {t('sdkWebhooksTable.add', 'Add webhook')}
                    </Button>
                </LinkContainer>
            </div>
        </>
        );
};