import { useCallback, useMemo } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "reactstrap";
import { AssessmentType } from "../../../api/main/models/codeOnly/AssessmentType";
import { AlertOnErrors } from "../../../shared/alertOnErrors";
import { Background } from "../../shared/background/Background";
import { Banner } from "../../shared/Banner";
import { LoadingIndicator } from "../../shared/LoadingIndicator";
import { UserAssessmentAssignment } from "../../../api/main/models/UserAssessmentAssignment";
import { AssessmentSession } from "../../../api/main/models/AssessmentSession";
import { generateDashboardSummary as _generateDashboardSummary } from "../utilities/generateDashboardSummary";
import { useParams } from "react-router";
import { useAdministrationDashboardViewModel } from "../../../api/main/dashboard/viewModels/useAdministrationDashboardViewModel";
import { useExpandedUserAssessmentAssignments } from "../utilities/useExpandedUserAssessmentAssignments";
import { AssessmentProgressDashboardTab } from "../tabs/AssessmentProgressDashboardTab";
import { DashboardBackButton } from "../backButton/DashboardBackButton";

/**
 * Dashboard for assessment via the administration dashboard data.
 */
export const AdministrationAssessmentDashboard = () => {
    // Work out the subscription and user to show for.
    const { assessmentId } = useParams<{ assessmentId: string }>();


    const { t } = useTranslation();

    const {
        data: {
            subscriptions,
            subscriptionTeams,
            profiles: allProfiles,
            assessments: allAssessments,
            assessmentSessions: allAssmentSessions,
            userAssessmentAssignments: allUserAssessmentAssignments,
            userDriverMetrics: allUserDriverMetrics,
        },
        isLoading,
        errors: loadErrors
    } = useAdministrationDashboardViewModel();

    const assessment = useMemo(() => allAssessments?.find(it => it.id === assessmentId), [allAssessments, assessmentId]);

    // Filter the profiles down to only this user.
    const assessmentSessions = useMemo(() => allAssmentSessions?.filter(item => item.assessmentId === assessmentId), [allAssmentSessions, assessmentId]);
    const userAssessmentAssignments = useMemo(() =>
        allUserAssessmentAssignments
            ?.filter(item => item.assessmentId === assessmentId)
        , [allUserAssessmentAssignments, assessmentId]);

    // Eemove any profiles that have not either been used or assigned to this assessment.
    const profiles = useMemo(() =>
        allProfiles
            ?.filter(profile =>
                !!assessmentSessions?.find(it => it.userId === profile.userId)
                || !!userAssessmentAssignments?.find(it => it.userId === profile.userId)
                || !!userAssessmentAssignments?.find(it => !!it.subscriptionTeamId && it.subscriptionTeamId === profile.subscriptionTeamId)
        )
        , [allProfiles, userAssessmentAssignments, assessmentSessions]);

    // Expand the UserAssessmentAssignments so we can work with them easier.
    const expandedUserAssessmentAssignments = useExpandedUserAssessmentAssignments(userAssessmentAssignments, profiles);

    // Allow the generation of dashboard summary data.
    const generateDashboardSummary = useCallback((
        sessionFilter: (session: AssessmentSession) => boolean,
        assignmentFilter: (assignment: UserAssessmentAssignment) => boolean,
        assessmentType: AssessmentType) => {
        // Filter by the passed in fitler.
        let mySessions = assessmentSessions?.filter(sessionFilter) ?? [];
        let myAssignments = expandedUserAssessmentAssignments?.filter(assignmentFilter) ?? [];

        // Filter the driver metrics to contain only users that have assessments or assignments.
        const myUserDriverMetrics = allUserDriverMetrics?.filter(
            udm => !!mySessions.find(it => it.userId === udm.userId) || !!myAssignments.find(it => it.userId === udm.userId)
        ) ?? [];

        // Generate the summary data.
        const ret = _generateDashboardSummary(mySessions, assessment ? [assessment] : [], profiles ?? [], myAssignments, myUserDriverMetrics ?? [], null);
        return ret;
    }, [assessmentSessions, assessment, profiles, expandedUserAssessmentAssignments, allUserDriverMetrics]);


    // Track the active assessment type.
    const activeAssessmentType = assessment?.assessmentType as AssessmentType ?? AssessmentType.Assessment;

    return (
        <Background>
            <Banner fluid>
                <Row>
                    <Col xs={12} md="">
                        <h1>
                            <DashboardBackButton />
                            {
                                t('administrationAssessmentDashboard.heading', '{{name}} dashboard', { name: assessment?.name })
                            }
                        </h1>
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
            </Banner>
            <Container fluid>
                <AlertOnErrors errors={[loadErrors]} />

                <AssessmentProgressDashboardTab
                    assessment={assessment}
                    profiles={profiles ?? []}
                    generateDashboardSummary={generateDashboardSummary}
                    assessmentType={activeAssessmentType}
                    assessmentSessions={assessmentSessions ?? []}
                    expandedUserAssessmentAssignments={expandedUserAssessmentAssignments}
                    subscriptions={subscriptions ?? []}
                    subscriptionTeams={subscriptionTeams ?? []}
                    showSubscriptionName={true}
                    riskRules={null}
                />
            </Container>
        </Background>
    );
};
