import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useMemo, useRef, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Button, Col, Row } from "reactstrap";
import { QuestionAnswer } from "../../../../../api/main/models/QuestionAnswer";
import { HtmlDisplay } from "../../../../../shared/htmlEditor";
import { usePrefetchImages } from "../../../../../shared/prefetchImage";
import { SlidingScaleSlider } from "../../../../questions/edit/slidingScaleVideo/SlidingScaleSlider";
import { ShowQuestionChildProps } from "../ShowQuestion";
import "./showQuestionSlidingScaleVideo.scss";

/**
 * Running of a SlidingScaleVideo question.
 * @param props
 */
export const ShowQuestionSlidingScaleVideo = (props: ShowQuestionChildProps) => {
    const {
        model,
        answers,
        blobReferences,

        selectedAnswerId,
        selectSingleAnswer,
        questionResponseEventsManager,

        resetResponseTimeStart,

        onPageComplete,
    } = props;


    const { t } = useTranslation();

    const videoBlob = useMemo(() => blobReferences.find(it => it.id === model.videoBlobReferenceId), [blobReferences, model]);
    const imageBlob = useMemo(() => blobReferences.find(it => it.id === model.imageBlobReferenceId), [blobReferences, model]);
    const questionTextImageBlob = useMemo(() => blobReferences.find(it => it.id === model.questionTextImageBlobReferenceId), [blobReferences, model]);

    // At the end, with the question, we either want to show a specific image set to show with the question, or the original thumbnail again.
    const endImageBlob = questionTextImageBlob ?? imageBlob;

    // Keep track of if the video has started/finished or not.
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const [hasVideoStarted, setHasVideoStarted] = useState<boolean>(false);
    const [hasVideoFinished, setHasVideoFinished] = useState<boolean>(false);

    // Some calls to apis (such as saving clicks) will cause us to generate a new secure video URL.  To make sure this doesn't reset out
    // video we cache the poster and video urls as we original receive them into our state and always use them.
    const [imageUrl] = useState<string>(imageBlob?.url ?? '');
    const [videoUrl] = useState<string>(videoBlob?.url ?? '');
    const [endImageUrl] = useState<string>(endImageBlob?.url ?? '');

    // Prefetch the images we use.
    const resolveImageUrl = usePrefetchImages([
        imageUrl,
        endImageUrl,
        ...answers.map(answer => {
            const blob = blobReferences.find(item => item.id === answer.answerTextImageBlobReferenceId);
            return blob?.url ?? '';
        }),
    ]);

    // Start the video.
    const startVideo = useCallback(() => {
        setHasVideoStarted(true);
        videoRef.current?.play();
    }, [videoRef, setHasVideoStarted]);

    const onSelectSingleAnswer = useCallback((answer: QuestionAnswer | undefined) => {
        selectSingleAnswer(answer, model);

        // Let the navigation know this question has been completed.
        onPageComplete();
    }, [selectSingleAnswer, onPageComplete, model]);

    // Video has ended.
    const onVideoEnded = useCallback(() => {
        setHasVideoFinished(true);
        resetResponseTimeStart();

            const currentResponse = questionResponseEventsManager.model.filter(item => item.questionId === model.id && !item.archived);
            //check if we already have a response saved
            if (currentResponse.length === 0) {

                //same logic as in "<SlidingScaleSlider>" for deciding where to start the scale dot
                const min = 0;
                const max = answers.length < 2 ? 1 : answers.length - 1;

                const defaultValue = Math.floor((max - min) / 2);

                //set what was calculated to be the current response
                onSelectSingleAnswer(answers[defaultValue]);

            }

    }, [setHasVideoFinished, resetResponseTimeStart, onSelectSingleAnswer, answers, model.id, questionResponseEventsManager.model]);

    // If the selected answer has an image, get it.
    const selectedAnswerBlob = useMemo(() => {
        if (!selectedAnswerId || !blobReferences) {
            return null;
        }

        const selectedAnswer = answers.find(it => it.id === selectedAnswerId);
        if (!selectedAnswer) {
            return null;
        }

        const ret = blobReferences.find(it => it.id === selectedAnswer.answerTextImageBlobReferenceId);
        if (!ret) {
            return null;
        }

        return ret;
    }, [blobReferences, selectedAnswerId, answers]);

    return (
        <div className="show-question-sliding-scale-video">

            {/* Video has not started */}
            <ConditionalFragment showIf={!hasVideoStarted}>
                <div className="show-question-sliding-scale-video-background" style={{ backgroundImage: `url("${resolveImageUrl(imageUrl) ?? ''}")`}}>
                    <div className="show-question-sliding-scale-video-start-overlay"
                        onClick={() => startVideo()}
                    >
                        <Row>
                            <Col>
                            </Col>
                            <Col xs="auto">
                                <HtmlDisplay html={model.preQuestionText} />

                                <FontAwesomeIcon icon="play-circle" className="show-question-sliding-scale-video-start-overlay-play-icon" />
                                <p className="text-muted">
                                    {t('showQuestionSlidingScaleVideo.playText', 'Press play to start the video.')}
                                </p>
                            </Col>
                            <Col>
                            </Col>
                        </Row>
                    </div>
                </div>
            </ConditionalFragment>

            {/* Video has started but is not yet finished */}
            <ConditionalFragment showIf={!hasVideoFinished}>
                <div className="embed-responsive embed-responsive-16by9 mb-4" style={{ display: !hasVideoStarted? 'none': undefined }}>
                    <video
                        className={"show-question-sliding-scale-video-fullscreen"}
                        disablePictureInPicture
                        ref={videoRef}
                        src={videoUrl}
                        poster={resolveImageUrl(imageUrl)}
                        playsInline={true}
                        controls={false}
                        onEnded={() => onVideoEnded()}
                    >
                    </video>
                </div>
            </ConditionalFragment>

            {/* Video has finished */}
            <ConditionalFragment showIf={hasVideoFinished}>
                <div className="show-question-sliding-scale-video-background" style={{ backgroundImage: `url("${resolveImageUrl(endImageUrl) ?? ''}")` }}>
                    <div className="show-question-sliding-scale-video-answer-overlay">
                        <HtmlDisplay html={model.questionText} />

                        <ConditionalFragment showIf={!!selectedAnswerBlob || !!endImageUrl}>
                            <Row>
                                <Col>
                                </Col>
                                <Col xs={12} md={8}>
                                    <div className="mt-4 show-question-sliding-scale-video-answer-selected-answer-image-container">
                                        <div className="embed-responsive embed-responsive-16by9">
                                            <img className="img-fluid embed-responsive-item" src={resolveImageUrl(selectedAnswerBlob?.url ?? endImageUrl ?? '')}alt="" />
                                        </div>
                                    </div>
                                </Col>
                                <Col>
                                </Col>
                            </Row>
                        </ConditionalFragment>

                        <div className="mt-4">
                            <SlidingScaleSlider
                                answers={answers}
                                selectedAnswerId={selectedAnswerId}
                                onSelectedAnswerChanged={answer => onSelectSingleAnswer(answer)}
                            />
                        </div>


                        <ConditionalFragment showIf={!!questionResponseEventsManager.model.filter(item => item.questionId === model.id && !item.archived).length}>
                            <p className="text-muted">
                                {t('showQuestionSlidingScaleVideo.endText', 'Press Next to confirm your answer and continue.')}
                            </p>
                        </ConditionalFragment>
                    </div>
                </div>
            </ConditionalFragment>
        </div>
        );
};