import * as React from 'react';
import { Table, Button, Row, Col, ButtonGroup } from 'reactstrap';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { Waypoint } from 'react-waypoint';
import { useReplaceSearchParamsEffect, useSearchParams } from '../../shared/useURLSearchParams';
import { useTranslation } from 'react-i18next';
import { SearchInput } from '../shared/searchInput/SearchInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContainer } from '../shared/MainContainer';
import { NoResultsFound } from '../shared/NoResultsFound';
import { StickyToolbar } from '../shared/StickyToolbar';
import { useParams } from 'react-router';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Banner } from '../shared/Banner';
import { Background } from '../shared/background/Background';
import { AssessmentType } from '../../api/main/models/codeOnly/AssessmentType';
import { PillsNavBar } from '../shared/pillsNavBar/PillsNavBar';
import { useUserAssessmentAssignmentsListViewBaseViewModel } from '../../api/main/userAssessmentAssignments/viewModels/useUserAssessmentAssignmentsListViewBaseViewModel';
import { useChangesArray } from '../../shared/useChanges';
import { useSaveUserAssessmentAssignmentCallback } from '../../api/main/userAssessmentAssignments/useSaveUserAssessmentAssignmentCallback';
import { useDeleteUserAssessmentAssignmentCallback } from '../../api/main/userAssessmentAssignments/useDeleteUserAssessmentAssignmentCallback';
import { useAsyncCallback } from 'react-use-async-callback';
import { useDebouncedCallback } from 'use-debounce/lib';
import { useCallback, useState } from 'react';
import { UserAssessmentAssignment, userAssessmentAssignmentDefaultValues } from '../../api/main/models/UserAssessmentAssignment';
import { SubscriptionTeamNavigation } from '../subscriptionTeam/SubscriptionTeamNavigation';
import { useCurrentUserOrEmulatedSubscriptionId } from '../../globalState/subscriptions/useCurrentUserOrEmulatedSubscriptionId';
import { UserNavigation } from '../users/UserNavigation';
import { useProfile } from '../../api/main/profiles/useProfile';
import { ISODateTimeInput } from '../shared/ISODateTimeInput';
import { useSendAssessmentReminderCallback } from '../../api/main/assessments/useSendAssessmentReminderCallback';
import { useSendAllAssessmentReminderCallback } from '../../api/main/assessments/useSendAllAssessmentReminderCallback';
import { ValidatedInput } from "pojo-validator-reactstrap";
import { useValidatorArrayCallback } from '../../shared/validator-react-contrib/useValidatorArrayCallback';
import { LinkContainer } from "react-router-bootstrap";
import { Assessment } from "../../api/main/models/Assessment";


export interface UserAssessmentAssignmentsListBaseBaseProps {
    title: string,
    mobileColumn1Name: string,
    assessmentTypes: Array<AssessmentType>,
}

/**
 * Assessments (or training) that is assigned to individuals or teams.
 */
export const UserAssessmentAssignmentsListBase = (props: UserAssessmentAssignmentsListBaseBaseProps) => {
    const {
        title,
        mobileColumn1Name,
        assessmentTypes,
    } = props;

    const { subscriptionTeamId, profileId } = useParams<{ subscriptionTeamId: string | undefined, profileId: string | undefined }>();
    const subscriptionId = useCurrentUserOrEmulatedSubscriptionId();

    // Convert the profileId into a userId if we have one.
    const { data: { model: _profile } } = useProfile(profileId);
    const userId = React.useMemo(() => _profile?.userId, [_profile]);

    
    const { t } = useTranslation();
    const { search: searchParam } = useSearchParams();
    const [search, setSearch] = React.useState<string>(searchParam ?? '');
    const {
        data: {
            items: storeUserAssessmentAssignments,
            subscriptionAssessments,
            assessments: allItems,
            subscriptionTeam,
            profile,
            driverMetricRecommendations,
            driverMetrics,
            userDriverMetrics,
        },
        isLoading, errors: loadingErrors, fetchMore, hasMore
    } = useUserAssessmentAssignmentsListViewBaseViewModel(subscriptionId, { pageSize: undefined, assessmentTypes: assessmentTypes, subscriptionTeamId: subscriptionTeamId, userId: userId });

    const baseRouteSolver = useCallback((assessment: Assessment) => {
        if (assessment.assessmentType === AssessmentType.TrainingModule) {
            return `/training/start/${assessment.id}`;
        } else {
            return `/assessment/start/${assessment.id}`;
        }
    }, []);


    // Manage the addition and removal of items for this subscription.
    const userAssessmentAssignmentsManager = useChangesArray<UserAssessmentAssignment, string>(storeUserAssessmentAssignments, item => item.id);
    const [saveUserAssessmentAssignment] = useSaveUserAssessmentAssignmentCallback();
    const [removeUserAssessmentAssignment] = useDeleteUserAssessmentAssignmentCallback();

    // Save all changes to the userAssessmentAssignmentManager.
    const [saveAllUserAssessmentAssignments] = useAsyncCallback(async () => {
        // Save to the store.
        for (const item of userAssessmentAssignmentsManager.added) { await saveUserAssessmentAssignment(item.id, userAssessmentAssignmentsManager.changesFor(item.id), true); }
        for (const item of userAssessmentAssignmentsManager.updated) { await saveUserAssessmentAssignment(item.id, userAssessmentAssignmentsManager.changesFor(item.id), false); }
        for (const item of userAssessmentAssignmentsManager.removed) { await removeUserAssessmentAssignment(item.id); }
        userAssessmentAssignmentsManager.markAsSaved();
    }, [userAssessmentAssignmentsManager, saveUserAssessmentAssignment, removeUserAssessmentAssignment, subscriptionId]);
    // Debounced version to avoid going to the server too often.
    const saveAllUserAssessmentAssignmentsDebounced = useDebouncedCallback((userAssessmentAssignment?, assessmentId?,) => {

        var allowed
        var currentAssignment

        if (userAssessmentAssignment) {
            allowed = validateUserAssessmentAssignments(userAssessmentAssignment);
        }
        else {
            currentAssignment = userAssessmentAssignmentsManager.model.find(item => item.assessmentId === assessmentId && item.userId === userId);
            if (currentAssignment) {

                allowed = validateUserAssessmentAssignments(currentAssignment);

            }
        }

        if (allowed) {
            saveAllUserAssessmentAssignments();
        }
    });

    // Toggle if an assessment is selected for the subscription or not.
    const toggleUserAssessmentAssignment = useCallback((assessmentId: string) => {
        const existing = userAssessmentAssignmentsManager.model.find(item => item.assessmentId === assessmentId);

        if (existing) {
            userAssessmentAssignmentsManager.removeFor(existing.id);
        } else {

            const defaultRestarts = subscriptionAssessments.find(it => it.assessmentId === assessmentId)?.maxRestarts ?? 0

            userAssessmentAssignmentsManager.addFor({
                ...userAssessmentAssignmentDefaultValues(),

                subscriptionId: profile?.subscriptionId ?? subscriptionTeam?.subscriptionId ?? undefined, // This logic makes sure admins who are emulating a subscription don't end up with assignments in that subscription when setting for Esitu staff.
                subscriptionTeamId: subscriptionTeamId ?? undefined,
                userId: userId ?? '',
                maxRestarts: defaultRestarts,
                assessmentId: assessmentId,
            });

        }

        // Save to the store after a debounce to allow grouping of quick actions and the state itself to be updated.
        saveAllUserAssessmentAssignmentsDebounced(existing ?? undefined, assessmentId);
    }, [userAssessmentAssignmentsManager, saveAllUserAssessmentAssignmentsDebounced, subscriptionTeamId, userId, profile, subscriptionTeam, subscriptionAssessments]);

    // Returns true if the assessment is selected for this subscription.
    const isUserAssessmentAssignmentSelected = useCallback((assessmentId: string) => !!userAssessmentAssignmentsManager.model.find(item => item.assessmentId === assessmentId), [userAssessmentAssignmentsManager]);

    // Filtering the display between all and selected only.
    const [filter, setFilter] = useState<'all' | 'selected'>('all');

    //Assessment Reminder callbacks
    const [remind] = useSendAssessmentReminderCallback();
    const [remindAll] = useSendAllAssessmentReminderCallback();

    //Handle which reminder callback to use on the remind button depending on if there is a TeamId or a ProfileId.
    const [handleRemindButton] = useAsyncCallback(async (assessmentId: string) => {
        if (!!subscriptionTeamId) {
            //if we have a subscriptionTeamId use remindAll
            await remindAll(assessmentId, subscriptionTeamId);
            return;
        }
        //if not we must have a profileId so use remind
        if (!!profile) {
            await remind(profile.userId, assessmentId);
        }
    }, [subscriptionTeamId, remind, remindAll, profile])
    
    // Filter by the assessment's search client side so it can work when offline as well as online.
    const items = React.useMemo(() => {
        if (!allItems) {
            return allItems;
        }

        let ret = allItems;

        if (profile?.subscriptionId != null || subscriptionTeam?.subscriptionId != null) {
            // Filter down to only items that are licesnced for this subscription.
            ret = ret.filter(item => !!subscriptionAssessments?.find(link => link.assessmentId === item.id));
        }

        // Filter by the selected filter.
        switch (filter) {
            case 'all':
                break;
            case 'selected': {
                ret = ret.filter(item => isUserAssessmentAssignmentSelected(item.id));
            }
        }

        // Filter by the user's search text.
        if (search) {
            let lowerSearch = search.toLocaleLowerCase();

            ret = ret.filter(item =>
                item.name.toLocaleLowerCase().indexOf(lowerSearch) >= 0
            );
        }

        return ret;
    }, [allItems, search, filter, isUserAssessmentAssignmentSelected, subscriptionAssessments, subscriptionTeam, profile]);

    useReplaceSearchParamsEffect({ search: search });

    // Given an assessmentId, check to see if there are any recommendation rules (based off driver metrics) that mean we should recommend this for the user.
    const generateRecommendationReasons = useCallback((assessmentId: string) => {
        if (!userId) {
            return [];
        }

        let ret: Array<string> = [];
        const myRules = driverMetricRecommendations.filter(item => item.targetId === assessmentId);
        for (const rule of myRules) {
            const metric = driverMetrics.find(item => item.id === rule.driverMetricId);
            const userMetric = userDriverMetrics.find(item => item.userId === userId && item.driverMetricId === rule.driverMetricId);

            const currentValue = userMetric?.currentValue ?? metric?.startValue ?? 0;

            // Make sure we fall within the bounds of the rule.
            if (currentValue < rule.minScore) {
                continue;
            }

            if (currentValue > rule.maxScore) {
                continue;
            }

            // Load the metric so we can use its name.
            if (!metric) {
                continue;
            }

            // Prepare the reasoning text.
            const text = t('userAssessmentAssignmentsListBase.generateRecommendationReasons.text', 'Recommended for this user based on their "{{metric}}" score of {{score}}.', { metric: metric.name, score: currentValue, });
            ret.push(text);
        }

        return ret;
    }, [driverMetricRecommendations, userId, userDriverMetrics, driverMetrics, t]);

    const colCount = 6;

    const [validateUserAssessmentAssignments, userAssessmentAssignmentErrors] = useValidatorArrayCallback<UserAssessmentAssignment>((myModel, validation, fieldsToCheck) => {
        const rules = {

            maxRestarts: () => subscriptionAssessments.find(item => item.assessmentId === myModel.assessmentId)?.maxRestarts! < myModel.maxRestarts && myModel.maxRestarts !== 0 ? t('UserAssessmentAssignmentsListBase.MaxRestarts', 'Max restarts cannot exceed the limit set on subscription') : '',

        };

        validation.checkRules(rules, fieldsToCheck);

    }, [subscriptionAssessments]);

    return (
        <Background>
            <Banner fluid>
                <StickyToolbar>
                    <Row>
                        <Col xs={12} md="auto">
                            <h1>
                                {title}
                            </h1>
                        </Col>
                        <Col>
                            <PillsNavBar>
                                {
                                    profileId ? <UserNavigation id={profileId} />
                                        : subscriptionTeamId ? <SubscriptionTeamNavigation id={subscriptionTeamId} />
                                            : null
                                }
                            </PillsNavBar>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h3 className="text-muted">
                                {
                                    profile ? t('common.fullName', '{{firstName}} {{lastName}}', { firstName: profile.firstName, lastName: profile.lastName, })
                                        : subscriptionTeam ? subscriptionTeam.name
                                            : ''
                                }
                            </h3>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                        <Col>
                            <SearchInput value={search} onChange={e => setSearch(e.currentTarget.value)} />
                        </Col>
                        <Col xs="auto">
                            <ButtonGroup>
                                <Button color="secondary" outline={filter !== 'all'} onClick={() => setFilter('all')}>
                                    {t('userAssessmentAssignmentsListBase.filter.all', 'All')}
                                </Button>
                                <Button color="secondary" outline={filter !== 'selected'} onClick={() => setFilter('selected')}>
                                    {t('userAssessmentAssignmentsListBase.filter.selected', 'Selected')}
                                </Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer fluid>
                <AlertOnErrors errors={loadingErrors} />
                <Table responsive striped>
                    <thead>
                        <tr>
                            <th>&nbsp;</th>
                            <th className="d-table-cell d-lg-none">{mobileColumn1Name}</th>
                            <th className="d-none d-lg-table-cell">{t('userAssessmentAssignmentsListBase.name', 'Name')}</th>
                            <th className="d-none d-lg-table-cell">{t('userAssessmentAssignmentsListBase.dueDate', 'Due by date')}</th>
                            <ConditionalFragment showIf={profile?.user.roleGroup?.name !== "Esitu staff"}>
                            <th className="d-none d-lg-table-cell">{t('userAssessmentAssignmentsListBase.MaxRestarts', 'Maximum retakes')}</th>
                            </ConditionalFragment>
                            {/*<th className="d-none d-lg-table-cell">{t('userAssessmentAssignmentsListBase.feedbackStyle', 'Feedback style')}</th>*/}
                            {/*<th className="d-none d-lg-table-cell">{t('userAssessmentAssignmentsListBase.isSmartIndividuality.heading', 'Smart?')}</th>*/}
                            <th className="d-none d-lg-table-cell">&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            items?.map(item => {
                                const itemIsSelected = isUserAssessmentAssignmentSelected(item.id);
                                const userAssessmentAssignment = userAssessmentAssignmentsManager.model.find(it => it.assessmentId === item.id);

                                const recommendationReasons = generateRecommendationReasons(item.id);

                                return (
                                    <tr key={item.id}>
                                        <td style={{ width: '1px' /* Sizes to fit contents */}}>
                                            <ButtonGroup>
                                                <Button color="secondary" outline={!itemIsSelected} onClick={() => toggleUserAssessmentAssignment(item.id)}>
                                                    {
                                                        itemIsSelected ? t('userAssessmentAssignmentsListBase.selected', 'Selected')
                                                            : t('userAssessmentAssignmentsListBase.select', 'Select')
                                                    }
                                                </Button>
                                                <ConditionalFragment showIf={itemIsSelected}>
                                                    <Button color="secondary" outline={!itemIsSelected} onClick={() => toggleUserAssessmentAssignment(item.id)}>
                                                        <FontAwesomeIcon icon="times" />
                                                    </Button>
                                                </ConditionalFragment>
                                            </ButtonGroup>
                                        </td>
                                        <td className="d-table-cell d-lg-none">
                                            <div>
                                                {item.name}
                                            </div>
                                            <ConditionalFragment showIf={recommendationReasons.length > 0}>
                                                <div className="text-muted">
                                                    <small>
                                                        {
                                                            recommendationReasons.map(reason => (
                                                                <div key={reason}>
                                                                    {reason}
                                                                </div>
                                                                ))
                                                        }
                                                    </small>
                                                </div>
                                            </ConditionalFragment>
                                        </td>
                                        <td className="d-none d-lg-table-cell">
                                            {item.name}

                                            <ConditionalFragment showIf={recommendationReasons.length > 0}>
                                                <div className="text-muted">
                                                    <small>
                                                        {
                                                            recommendationReasons.map(reason => (
                                                                <div key={reason}>
                                                                    {reason}
                                                                </div>
                                                            ))
                                                        }
                                                    </small>
                                                </div>
                                            </ConditionalFragment>
                                        </td>
                                        <td className="d-none d-lg-table-cell">
                                            <ConditionalFragment showIf={!!userAssessmentAssignment}>
                                                <ISODateTimeInput type="date"
                                                    value={userAssessmentAssignment?.targetDate}
                                                    onChange={e => userAssessmentAssignmentsManager.changeFor(userAssessmentAssignment?.id ?? '', { targetDate: e.currentTarget.value })}
                                                    onBlur={() => saveAllUserAssessmentAssignmentsDebounced(userAssessmentAssignment)}
                                                />
                                            </ConditionalFragment>
                                        </td>
                                        <td className="d-none d-lg-table-cell">
                                            <ConditionalFragment showIf={itemIsSelected}>
                                                <ConditionalFragment showIf={profile?.user.roleGroup?.name !== "Esitu staff"}>
                                                    <ValidatedInput type="number"
                                                        value={userAssessmentAssignment?.maxRestarts}
                                                        min={0}
                                                        onChange={e => userAssessmentAssignmentsManager?.changeFor(userAssessmentAssignment?.id ?? '', { maxRestarts: e.currentTarget.valueAsNumber })}
                                                        onBlur={() => saveAllUserAssessmentAssignmentsDebounced(userAssessmentAssignment)}
                                                        validationErrors={userAssessmentAssignmentErrors(userAssessmentAssignment?.id!)["maxRestarts"]}
                                                />
                                                </ConditionalFragment>
                                            </ConditionalFragment>
                                        </td>
                                        <td className="d-none d-lg-table-cell text-right">
                                            <ConditionalFragment showIf={itemIsSelected}>
                                                <Button color="secondary" onClick={() => handleRemindButton(item.id)}>
                                                    {t('userAssessmentAssignmentsListBase.remind', 'Send reminder')}
                                                </Button>
                                            </ConditionalFragment>
                                            <ConditionalFragment showIf={!itemIsSelected}>
                                                <LinkContainer to={baseRouteSolver(item)}>
                                                <Button color="secondary" outline={true}>
                                                    {t('userAssessmentAssignemtsUserListBase.preview', 'Preview')}
                                                </Button>
                                            </LinkContainer>
                                            </ConditionalFragment>
                                        </td>
                                    </tr>
                                );
                            })
                        }
                        <ConditionalFragment showIf={isLoading && !items?.length}>
                            <tr><td colSpan={colCount}><LoadingIndicator fullWidth /></td></tr>
                        </ConditionalFragment>
                        <ConditionalFragment showIf={!isLoading && !items?.length}>
                            <tr><td colSpan={colCount}>
                                <NoResultsFound search={search} />
                            </td></tr>
                        </ConditionalFragment>
                        <ConditionalFragment showIf={!isLoading && hasMore()}>
                            <tr><td colSpan={colCount}>
                                <Waypoint key={items?.length ?? 0} onEnter={fetchMore} />
                                <LoadingIndicator fullWidth />
                            </td></tr>
                        </ConditionalFragment>
                    </tbody>
                </Table>
            </MainContainer>
        </Background>
    );
};
