import gql from "graphql-tag";
import { driverMetricFields } from "../generated/driverMetricFields";

export const driverMetricFieldsFragment = gql`
    fragment driverMetricFields on DriverMetric {
        id
        name
        archived
        startValue
    }
`;


export type DriverMetric = Omit<driverMetricFields, '__typename'>;
