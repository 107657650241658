import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ValidationErrors } from "pojo-validator";
import { ValidateCallback } from "pojo-validator-react";
import { ValidatedInput } from "pojo-validator-reactstrap";
import { useTranslation } from "react-i18next";
import { Button, ButtonGroup, Card, FormGroup } from "reactstrap";
import { AssessmentType } from "../../../../api/main/models/codeOnly/AssessmentType";
import { DriverMetric } from "../../../../api/main/models/DriverMetric";
import { DriverMetricRecommendation } from "../../../../api/main/models/DriverMetricRecommendation";
import { FormButtons } from "../../../shared/FormButtons";

export interface DriverMetricRecommendationComponentProps {
    model: DriverMetricRecommendation | undefined,
    change: (changes: Partial<DriverMetricRecommendation>) => void,
    remove: () => void,

    validate: ValidateCallback,
    validationErrors: ValidationErrors,

    driverMetrics: Array<DriverMetric>,

    assessmentType: AssessmentType | undefined | null,
}

/**
 * Component for driver metric recommendation rules for questions.
 * @param props
 */
export const DriverMetricRecommendationComponent = (props: DriverMetricRecommendationComponentProps) => {
    const {
        model,
        change,
        remove,
        validate,
        validationErrors,
        driverMetrics,
        assessmentType,
    } = props;

    const { t } = useTranslation();

    return (
        <Card body>
            <FormButtons noPadding>
                <ButtonGroup>
                    <Button color="danger" outline onClick={() => remove()}>
                        <FontAwesomeIcon icon="trash-alt" />
                        <> </>
                        {t('driverMetricRecommendation.delete', 'Delete rule')}
                    </Button>
                </ButtonGroup>
            </FormButtons>
            <FormGroup className="form-inline">
                <div className="mt-1">
                    {
                        assessmentType === AssessmentType.TrainingModule ? t('driverMetricRecommendationComponent.beforeMetric.learning', 'Recommend this training module when')
                            : t('driverMetricRecommendationComponent.beforeMetric.assessment', 'Recommend this assessment when')
                    }
                    <> </>
                    <ValidatedInput type="select" value={model?.driverMetricId ?? ''} onChange={e => change({ driverMetricId: e.currentTarget.value })} onBlur={() => validate('driverMetricId')} validationErrors={validationErrors['driverMetricId']}>
                        <option value="">{t('driverMetricRecommendationComponent.driverMetricId.pleaseSelect', '(Please select a metric)')}</option>
                        {
                            driverMetrics.map(item => (
                                <option key={item.id} value={item.id}>{item.name}</option>
                            ))
                        }
                    </ValidatedInput>
                    <> </>
                    {t('driverMetricRecommendationComponent.beforeMin', 'is between')}
                    <> </>
                    <ValidatedInput name="minScore" type="number" value={model?.minScore ?? ''} onChange={e => change({ minScore: e.currentTarget.valueAsNumber })} onBlur={() => validate('minScore')} validationErrors={validationErrors['minScore']} />
                    <> </>
                    {t('driverMetricRecommendationComponent.beforeMax', 'and')}
                    <> </>
                    <ValidatedInput name="maxScore" type="number" value={model?.maxScore ?? ''} onChange={e => change({ maxScore: e.currentTarget.valueAsNumber })} onBlur={() => validate('maxScore')} validationErrors={validationErrors['maxScore']} />
                    <> </>
                    {t('driverMetricRecommendationComponent.afterMax', '')}
                </div>
            </FormGroup>
        </Card>
        );
};