import { RouteEntry } from "../../shared/routes";
import { DriverMetricsList } from './DriverMetricsList';
import { EditDriverMetric, CreateDriverMetric } from "./EditDriverMetric";
import { IdentityRoles } from "../../configure/security/IdentityRoles";
import { DeleteDriverMetric } from "./DeleteDriverMetric";

export const driverMetricRoutes: Array<RouteEntry> = [
    { path: '/administration/safety-metrics', exact: true, component: DriverMetricsList, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/safety-metrics/edit/:id', component: EditDriverMetric, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/safety-metrics/add', component: CreateDriverMetric, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/safety-metrics/delete/:id', component: DeleteDriverMetric, authorize: true, requireRole: IdentityRoles.Administration },
];
