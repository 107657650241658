import { NavItem, NavLink } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLinkTrackActive } from '../../../shared/NavLinkTrackActive';




/**
 * Administration navigation menu for distributors.
 */
export const DistributorAdministrationNavigation = () => {
    const { t } = useTranslation();

    return (
        <>
            <NavItem>
                <NavLink tag={NavLinkTrackActive} to={`/manage/distributor/dashboard`}>
                    <FontAwesomeIcon icon="solar-panel" className="nav-icon" />
                    {t('distributorAdministrationNavigation.dashboard', ' Dashboard')}
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink tag={NavLinkTrackActive} to={`/manage/distributor/subscriptions`}>
                    <FontAwesomeIcon icon="building" className="nav-icon" />
                    {t('distributorAdministrationNavigation.subscriptions', ' Subscriptions')}
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink tag={NavLinkTrackActive} to={`/manage/distributor/admins`}>
                    <FontAwesomeIcon icon="user-shield" className="nav-icon" />
                    {t('distributorAdministrationNavigation.admins', ' Admins')}
                </NavLink>
            </NavItem>
        </>
    );
}