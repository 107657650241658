import { useTranslation } from "react-i18next";
import { getQuestionTypes, QuestionTypeCategory } from "../../../api/main/models/codeOnly/QuestionType";
import { QuestionsListBase } from "../QuestionsListBase";

/**
 * List of questionnaires.
 */
export const QuestionnairesList = () => {
    const { t } = useTranslation();

    return (
        <QuestionsListBase
            title={t('questionnairesList.title', 'Questionnaires')}
            questionTypeColumnHidden={true}
            questionTypes={getQuestionTypes(QuestionTypeCategory.Questionnaire)}
            baseRoute={'/administration/questionnaires'}
        />
    );
};