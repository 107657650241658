import { useTranslation } from "react-i18next";
import {  ButtonGroup, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Label, Spinner } from "reactstrap";
import { useToggleState } from "use-toggle-state";
import { BlobReference } from "../../../../api/main/models/BlobReference";
import { Question } from "../../../../api/main/models/Question";
import { FileUploadButton } from "../../../shared/fileUploadButton/FileUploadButton";
import { UploadedVideoPreview } from "../../../shared/uploadedVideoPreview/UploadedVideoPreview";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface VideoTabProps {
    model: Question | undefined,

    videoBlob: BlobReference | undefined | null,
    uploadVideoBlob: (files: FileList | null) => void,
    isUploadingVideoBlob: boolean,
    clearVideoBlob: () => void,

    imageBlob: BlobReference | undefined | null,
    uploadImageBlob: (files: FileList | null) => void,
    isUploadingImageBlob: boolean,
    clearImageBlob: () => void,

}

/**
 * Video upload tab for EditQuestion.
 * @param props
 */
export const VideoTab = (props: VideoTabProps) => {
    const {
        //model,
        videoBlob, uploadVideoBlob, isUploadingVideoBlob, clearVideoBlob,
        imageBlob, uploadImageBlob, isUploadingImageBlob, clearImageBlob,
    } = props;

    const { t } = useTranslation();

    const [isVideoDropdownOpen, toggleVideoDropdown] = useToggleState();

    return (
        <>
            <FormGroup>
                <Label htmlFor="videoBlobReferenceId">{t('videoTab.videoBlobReferenceId', 'Video')}</Label>

                <UploadedVideoPreview src={videoBlob?.url ?? ''} poster={imageBlob?.url} />

                <ButtonGroup>
                    <FileUploadButton onUpload={files => uploadVideoBlob(files)} isExecuting={isUploadingVideoBlob}
                        executingChildren={<><Spinner size="sm" /><> </>{t('common.uploading', 'Uploading...')}</>}
                    >
                        <FontAwesomeIcon icon="upload" className="nav-icon" />
                        {t('editCalendarEvent.uploadVideoImage', ' Upload video...')}
                    </FileUploadButton>
                    <FileUploadButton onUpload={files => uploadImageBlob(files)} isExecuting={isUploadingImageBlob}
                        executingChildren={<><Spinner size="sm" /><> </>{t('common.uploading', 'Uploading...')}</>}
                    >
                        <FontAwesomeIcon icon="upload" className="nav-icon" />
                        {t('editCalendarEvent.uploadThumbnailImage', ' Upload thumbnail...')}
                    </FileUploadButton>

                    <Dropdown isOpen={isVideoDropdownOpen} toggle={() => toggleVideoDropdown()}>
                        <DropdownToggle color="primary" outline caret>
                            <span className="sr-only">
                                {t('common.more', 'More')}
                            </span>
                        </DropdownToggle>
                        <DropdownMenu right>
                            <DropdownItem className="text-danger" onClick={clearVideoBlob}>
                                {t('editCalendarHeadline.clearVideo', 'Clear video')}
                            </DropdownItem>
                            <DropdownItem className="text-danger" onClick={clearImageBlob}>
                                {t('editCalendarHeadline.clearThumbnail', 'Clear thumbnail')}
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </ButtonGroup>
            </FormGroup>
        </>
        );
};