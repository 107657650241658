import * as React from 'react';
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { AsyncLoadPagedResult, AsyncLoadPagedOptions } from '../../../../shared/abstractStore';
import { useFetchMoreCallback } from '../../../../shared/useFetchMoreCallback';
import { assessmentsListViewModelQuery, assessmentsListViewModelQueryVariables } from '../../generated/assessmentsListViewModelQuery';
import { mainApiConfig } from '../../../../configure/mainApiConfig';
import { assessmentFieldsFragment } from '../../models/Assessment';
import { AssessmentType } from '../../models/codeOnly/AssessmentType';
import { questionTagFieldsFragment } from '../../models/QuestionTag';
import { questionTagLinkFieldsFragment } from '../../models/QuestionTagLink';

export interface QuestionsAsyncLoadPageOptions extends AsyncLoadPagedOptions {
    assessmentTypes?: Array<AssessmentType>,
}

/**
 * Get a list of assessments from the store with other details needed for the assessments list.
 * @param id
 * @param options
 */
export function useAssessmentsListViewModel(options: QuestionsAsyncLoadPageOptions = {}): AsyncLoadPagedResult<assessmentsListViewModelQuery> {
    // Query data we need from the api.
    const [load, { data, loading, error, refetch, fetchMore: fetchMoreFromStore }] = useLazyQuery<assessmentsListViewModelQuery, assessmentsListViewModelQueryVariables>(
        gql`
        query assessmentsListViewModelQuery ($offset: Int, $first: Int, $assessmentTypes: [String!]) {
            items: assessments (offset: $offset, first: $first, assessmentTypes: $assessmentTypes) {
                ...assessmentFields
            }

            tags: questionTags {
                ...questionTagFields
            }

            links: questionTagLinks (targetType: "Assessment") {
                ...questionTagLinkFields
            }
        }

        ${assessmentFieldsFragment}
        ${questionTagFieldsFragment}
        ${questionTagLinkFieldsFragment}
        `,
        {

            variables: {
                offset: options.startOffset,
                first: options.pageSize,
                assessmentTypes: options.assessmentTypes,
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load])

    // Get the functions used to manage paging.
    const { fetchMore, hasMore: _hasMore } = useFetchMoreCallback<assessmentsListViewModelQuery>(
        fetchMoreFromStore,
        (options?.startOffset ?? 0) + (data?.items?.length ?? 0),
        (prevResults, newResults) => ({
            ...prevResults,
            ...newResults,

            items: [...(prevResults?.items ?? []), ...(newResults?.items ?? [])],
            tags: newResults?.tags ?? [],
            links: newResults?.links ?? [],
        }),
        (newResults) => newResults?.items?.length
    );
    const hasMore = React.useCallback(() => !options.pageSize ? false : _hasMore(), [options.pageSize, _hasMore]);
    
    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error,
        fetchMore: fetchMore,
        hasMore: hasMore,
    }), [data, refresh, loading, error, fetchMore, hasMore]);
}
