import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { AssessmentItemCreateInput } from '../generated/globalTypes';
import { createAssessmentItemMutation, createAssessmentItemMutationVariables } from '../generated/createAssessmentItemMutation';
import { assessmentItemFieldsFragment } from '../models/AssessmentItem';

/**
 * Get a callback to create a AssessmentItem in the store.
 */
export function useCreateAssessmentItemCallback(): [(model: AssessmentItemCreateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<createAssessmentItemMutation, createAssessmentItemMutationVariables>(
        gql`
            mutation createAssessmentItemMutation ($model: AssessmentItemCreateInput!) {
                createAssessmentItem(model: $model) {
                    ...assessmentItemFields
                }
            }

            ${assessmentItemFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (model: AssessmentItemCreateInput) => {
        const result = await mutationAction({ variables: { model: model } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
