import { Button, Modal, ModalBody, ModalHeader, ModalProps, Label, Form, FormGroup, ModalFooter } from "reactstrap";
import { QuestionType, getQuestionTypes, QuestionTypeCategory, questionTypeDisplayName } from "../../../../../api/main/models/codeOnly/QuestionType";
import { useTranslation } from "react-i18next";
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { useChanges } from '../../../../../shared/useChanges';
import { Question, questionDefaultValues } from '../../../../../api/main/models/Question';
import { useCallback } from 'react';
import { useValidatorCallback } from 'pojo-validator-react';
import { ConditionalFragment } from "react-conditionalfragment";
import { QuestionSequenceQuestion, questionSequenceQuestionDefaultValues } from '../../../../../api/main/models/QuestionSequenceQuestion';
import { QuestionAnswer, questionAnswerDefaultValues } from "../../../../../api/main/models/QuestionAnswer";
import { useDisplayOrder } from "../../../../shared/useDisplayOrder/useDisplayOrder";
import { ValidationErrors } from "pojo-validator";
import { QuestionnaireQuestionAnswerMultipleChoice } from "../QuestionnaireQuestionAnswerMultipleChoice";
import { SlidingScaleQuestionnaireQuestion } from "../SlidingScaleQuestionnaireQuestion";
import { HtmlEditor } from "../../../../../shared/htmlEditor";
import { useAsyncCallback } from 'react-use-async-callback';
import { SlidingScaleSlider } from "../../../edit/slidingScaleVideo/SlidingScaleSlider";
import { ModelArrayChanges } from "../../../../../shared/useChanges";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DriverMetric } from "../../../../../api/main/models/DriverMetric"

export interface CreateQuestionnaireQuestionModalCloseEventArgs {

    selectedIds: Array<string>,

    /**
 * Added Ids (those in selectedIds that were not in initialSelectedIds).
 */
    addedIds: Array<string>,
    /**
     * Removed Ids (those in selectedIds that were not in initialSelectedIds).
     */
    removedIds: Array<string>,

    cancelled: boolean,
}



export interface CreateQuestionnaireQuestionModalProps extends ModalProps {
    questionnaire: Question,
    driverMetrics: Array<DriverMetric>,

    isOpen: boolean,
    toggle: () => void,

    questionTypeCategory?: QuestionTypeCategory,

    isCreate?: boolean,
    onCreateDefaultValues?: () => Partial<Question>,

    parentId: string,

    validateQuestionAnswer: (model: QuestionAnswer) => boolean,
    questionAnswerValidationErrors: (id: string) => ValidationErrors,

    questionnaireQuestionAnswersManager: ModelArrayChanges<QuestionAnswer, string>,
    questionSequenceQuestionsManager: ModelArrayChanges<QuestionSequenceQuestion, string>,
    questionnaireQuestionsManager: ModelArrayChanges<Question, string>,

    refresh: () => void,
}

export const CreateQuestionnaireQuestionModal = (props: CreateQuestionnaireQuestionModalProps) => {
    const {

        questionnaire,
        driverMetrics,
        isOpen,
        toggle,

        isCreate,
        onCreateDefaultValues,

        parentId,

        validateQuestionAnswer,
        questionAnswerValidationErrors,

        questionnaireQuestionAnswersManager,
        questionSequenceQuestionsManager,
        questionnaireQuestionsManager,
    } = props;


    const { t } = useTranslation();
    const {model, change, changes } = useChanges(questionnaire, isCreate ? { ...questionDefaultValues(), ...(onCreateDefaultValues ? onCreateDefaultValues() : {}) } : undefined);


    // Order the answers so they show in and can be managed by displayOrder.
    const [orderedAnswers, {
        canMoveUp: canMoveAnswerUp,
        moveUp: moveAnswerUp,
        canMoveDown: canMoveAnswerDown,
        moveDown: moveAnswerDown,
    }] = useDisplayOrder(
        questionnaireQuestionAnswersManager,
        // Filter to only the answers for this question.
        answer => answer.questionId === model.id
    );


    // Adding of an answer.
    const addAnswer = useCallback(() => {
        questionnaireQuestionAnswersManager?.addFor({
            ...questionAnswerDefaultValues(),

            questionId: model?.id,
        });
    }, [questionnaireQuestionAnswersManager, model?.id]);

    //when the edit modal is cancelled, we still need to save the questions, as the answers will still exist in the answers manager
    //if we dont save it they wont have a question to save against, so we just set it to archived
    const [cancelSave] = useAsyncCallback(async () => {

        await questionnaireQuestionsManager.addFor({
            ...questionDefaultValues(),
            id: model.id,
            questionType: model.questionType,
            name: "Questionnaire Question",
            questionText: model.questionText,

            archived: true,
        })

        //make sure the answers are all set to archived
        await questionnaireQuestionAnswersManager.added.forEach(item => {
            if (item.questionId === model.id)

                item.archived = true;

        })


        toggle()

    }, [toggle, questionnaireQuestionsManager, questionnaireQuestionAnswersManager, model, questionDefaultValues]);


    //save the Question and the QuestionSequenceQuestion
    const [saveForm] = useAsyncCallback(async () => {
        if (!validate()) {
            return;
        }




        //save the question
        if (isCreate) {
            await questionnaireQuestionsManager.addFor({
                ...questionDefaultValues(),
                id: model.id,
                name: "Questionnaire Question",
                questionType: model.questionType,
                questionText: model.questionText,
            });


            //load an empty sequenceQuestion to save if we dont have one (doesnt need to update as nothing would change)

            let newQuestionSequenceQuestion = {
                ...questionSequenceQuestionDefaultValues(),

                childQuestionId: model.id,
                parentQuestionId: parentId,
            } as QuestionSequenceQuestion;

            //save the created sequenceQuestion
            await questionSequenceQuestionsManager.addFor(newQuestionSequenceQuestion)

        }
        else if (changes) {
            //dont want to commit changes every time, we may not have any changes (e.g. an answer was changed not the question)

            //update the questionnaire question with the changes
            questionnaireQuestionsManager.changeFor(model.id, changes)

        }

        toggle()

    }, [model, questionnaireQuestionsManager, parentId, questionSequenceQuestionsManager, questionSequenceQuestionDefaultValues, toggle, changes, ]);


    const questionTypeCategory = QuestionTypeCategory.QuestionnaireQuestion;

    // Main model validation.
    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            name: () => !model.questionText ? t('CreateQuestionnaireQuestionModal.questionTextRequired', 'Question text is required') : '',
            questionType: () => !model.questionType ? t('CreateQuestionnaireQuestionModal.questionTypeRequired', 'Question type is required') : '',
        }

        validation.checkRules(rules, fieldsToCheck)
    }, [model]);

    return (
        <Modal isOpen={isOpen} toggle={() => toggle()} size="xl" className="create-questionnaire-question-modal">

            <ModalHeader toggle={() => toggle()}>
                {
                    t('createQuestiobbaireQuestionModal.heading.question', 'Create question answer')
                }
            </ModalHeader>

            <ModalBody>

                <Form onSubmit={e => { e.preventDefault(); saveForm(); }}>
                    <FormGroup>

                        <Label htmlFor="questionType">{t('editQuestion.questionType.question', 'Type of question')}</Label>
                        <ValidatedInput name="questionType" type="select" value={model.questionType ?? ''} onChange={e => change({ questionType: e.currentTarget.value })} onBlur={e => validate('questionType')} validationErrors={validationErrors['questionType']}>
                            <option value="">{t('editQuestion.questionType.pleaseSelect.quesiton', '(Please select a type for this question)')}</option>
                            {
                                getQuestionTypes(questionTypeCategory).map(item => (
                                    <option key={item} value={item}>
                                        {questionTypeDisplayName(item, t)}
                                    </option>
                                ))
                            }
                        </ValidatedInput>
                        <ConditionalFragment showIf={model.questionType !== QuestionType.QuestionnaireInput}>
                            <FormGroup>
                                <Label htmlFor="questionType">{t('editQuestion.driverMetricId.label', 'Primary safety metric')}</Label>
                                <ValidatedInput name="driverMetricId" type="select" value={model.driverMetricId ?? ''} onChange={e => change({ driverMetricId: e.currentTarget.value || null })} onBlur={e => validate('driverMetricId')} validationErrors={validationErrors['driverMetricId']}>
                                    <option value="">{t('editQuestion.driverMetricId.pleaseSelect.quesiton', '(No primary safety metric)')}</option>
                                    {
                                        driverMetrics?.map(item => (
                                            <option key={item.id} value={item.id}>
                                                {item.name}
                                            </option>
                                        ))
                                    }
                                </ValidatedInput>
                            </FormGroup>
                        </ConditionalFragment>
                        <ConditionalFragment showIf={model.questionType !== ''}>

                            <Label htmlFor="questionText">{t('SlidingScaleQuestionnaireQuestion.questionText', 'Question Text')}</Label>

                            <HtmlEditor value={model?.questionText} onChange={html => change({ questionText: html })} />

                            <div style={{ marginTop: '10px' }}>
                            </div>

                        </ConditionalFragment>

                        <ConditionalFragment showIf={model.questionType === QuestionType.QuestionnaireInput}>


                        </ConditionalFragment>

                        <ConditionalFragment showIf={model.questionType === QuestionType.QuestionnaireMultipleChoice}>
                            <div>
                                {
                                    orderedAnswers.map(item => (
                                        <QuestionnaireQuestionAnswerMultipleChoice key={item.id}
                                            model={item}
                                            change={changes => questionnaireQuestionAnswersManager!.changeFor(item.id, changes)}
                                            remove={() => questionnaireQuestionAnswersManager!.removeFor(item.id)}

                                            isOpenInitially={!!questionnaireQuestionAnswersManager!.added.find(it => it.id === item.id)}

                                            moveUp={() => moveAnswerUp(item.id)} canMoveUp={canMoveAnswerUp(item.id)}
                                            moveDown={() => moveAnswerDown(item.id)} canMoveDown={canMoveAnswerDown(item.id)}

                                            validate={() => validateQuestionAnswer(item)}
                                            validationErrors={questionAnswerValidationErrors(item.id)}
                                        />
                                    ))
                                }
                            </div>

                            <Button color="primary" outline onClick={() => addAnswer()} style={{}}>
                                {t('questionPromptMultipleChoiceVideoTab.addAnswer', 'Add answer')}
                            </Button>
                        </ConditionalFragment>

                        <ConditionalFragment showIf={model.questionType === QuestionType.QuestionnaireScale}>

                            <Label htmlFor="answers">{t('questionPromptSlidingScaleVideoTab.answers', 'Slider')}</Label>

                            <div>
                                <SlidingScaleSlider answers={orderedAnswers} />
                            </div>
                            <div>
                                {


                                    orderedAnswers.map(item => (
                                        <SlidingScaleQuestionnaireQuestion key={item.id}
                                            model={item}
                                            change={changes => questionnaireQuestionAnswersManager.changeFor(item.id, changes)}

                                            remove={() => questionnaireQuestionAnswersManager.removeFor(item.id)}

                                            isOpenInitially={!!questionnaireQuestionAnswersManager.added.find(it => it.id === item.id)}

                                            moveUp={() => moveAnswerUp(item.id)} canMoveUp={canMoveAnswerUp(item.id)}
                                            moveDown={() => moveAnswerDown(item.id)} canMoveDown={canMoveAnswerDown(item.id)}

                                            validate={() => validateQuestionAnswer(item)}
                                            validationErrors={questionAnswerValidationErrors(item.id)}
                                        />
                                    ))
                                }
                            </div>

                            <Button color="primary" outline onClick={() => addAnswer()}>
                                {t('questionPromptSlidingScaleVideoTab.addAnswer', 'Add slider mark')}
                            </Button>

                        </ConditionalFragment>


                    </FormGroup>
                </Form>
            </ModalBody>

            <ModalFooter>
                <Button color="primary" onClick={() => saveForm()}>
                    <FontAwesomeIcon icon="save" />
                    <> </>
                    {

                        t('createQuestionnaireQuestionModal.add', 'Save question')
                    }
                </Button>
                <> </>
                <Button color="primary" outline onClick={() => cancelSave()}>
                    {t('common.cancel', 'Cancel')}
                </Button>
            </ModalFooter>

        </Modal>
    )

}