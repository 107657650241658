import { Guid } from "guid-string";
import { useCallback, useMemo } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Card, CardGroup, Container } from "reactstrap";
import { isUndefined } from "util";
import { useMeDashboardViewModel } from "../../api/main/meDashboardViewModels/useMeDashboardViewModel";
import { AssessmentSession } from "../../api/main/models/AssessmentSession";
import { AssessmentType } from "../../api/main/models/codeOnly/AssessmentType";
import { SubscriptionType } from "../../api/main/models/codeOnly/SubscriptionType";
import { useCurrentUserProfile } from "../../api/main/profiles/useCurrentUserProfile";
import { useSubscription } from "../../api/main/subscriptions/useSubscription";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { Background } from "../shared/background/Background";
import { Banner } from "../shared/Banner";
import { NoResultsFound } from "../shared/NoResultsFound";
import { DashboardAssessmentCard } from "./DashboardAssessmentCard";

export interface MeDashboardProps {

}

export const MeDashboard = (props: MeDashboardProps) => {

    const { profileId: profileIdParam } = useParams<{ profileId: string }>();
    const { data: { model: currentUserProfile } } = useCurrentUserProfile();

    const profileId = profileIdParam ?? currentUserProfile?.id;

    const { t } = useTranslation();

    const { data: { viewModel, profile }, errors: loadErrors } = useMeDashboardViewModel(profileId);
    const { assessments, userAssessmentAssignments, assessmentSessions } = viewModel ?? ({ assessments: [], userAssessmentAssignments: [], assessmentSessions: [] });

    // If the user is part of a study, we want to customise their expeirence a little.
    const { data: { model: subscription } } = useSubscription(currentUserProfile?.subscriptionId ?? Guid.empty);
    const isStudyParticipant = useMemo(() => !!profile?.subscriptionId && subscription?.subscriptionType === SubscriptionType.Study, [subscription, profile]);

    // Going to need a few counts.
    const {
        assessmentsCount,
        trainingCount,
    } = useMemo(() => {
        let assessmentsCount = 0;
        let trainingCount = 0;

        for (const assignment of userAssessmentAssignments) {
            // Lookup the assessment model.
            const assessment = assessments.find(it => it.id === assignment.assessmentId);
            if (!assessment) {
                continue;
            }
            
            if (assessment.assessmentType === AssessmentType.Assessment) {
                ++assessmentsCount;
            } else if (assessment.assessmentType === AssessmentType.TrainingModule) {
                ++trainingCount;
            }
        }

        return {
            assessmentsCount,
            trainingCount,
        };
    }, [userAssessmentAssignments, assessments]);

    // Returns details of what the user can do with this assessment.
    const getAssessmentSummary = useCallback((assessmentId: string) => {
        const mySessions = assessmentSessions?.filter(item => item.assessmentId === assessmentId) ?? [];

        const completionCount = mySessions.filter(item => item.isFinished).length;
        const startedCount = mySessions.filter(item => !!item.startDate).length;

        //The first value we want to try is max restart limit on user assessments
        var maxRestartCount = userAssessmentAssignments.find(item => item.assessmentId === assessmentId && item.userId === profile?.userId)?.maxRestarts;

        return {
            isEverFinished: completionCount > 0,
            isLatestFinished: mySessions.length === 0 ? false : mySessions[0].isFinished,
            canRetake:
                //Esitu staff (those without subscriptionIds) can always retake assessments (mostly for testing and demo purposes).
                startedCount <= maxRestartCount! || !profile?.subscriptionId ? true : false, // Everyone else can only retake up to the specified number of times licensed to the subscription.
        };
    }, [assessmentSessions, profile, userAssessmentAssignments]);

    // Generate the card components to show for the requested assessmentType,.
    const generateCards = useCallback((options: { assessmentType?: AssessmentType, includeIfEverFinishedIs?: boolean, includeIfLatestFinishedIs?: boolean, displayInline?: boolean, }) => (
        <>
            {
                userAssessmentAssignments.map(assignment => {
                    // Lookup the assessment model.
                    const assessment = assessments.find(it => it.id === assignment.assessmentId);
                    if (!assessment) {
                        return null;
                    }

                    // Skip any of the wrong type that we don't want in this list.
                    if (options.assessmentType && assessment.assessmentType !== options.assessmentType) {
                        return null;
                    }

                    // Get some details about what we can and can't do with this assessment.
                    const { isEverFinished, isLatestFinished, canRetake } = getAssessmentSummary(assessment.id);

                    // If we don't match the completion filter then skip it.
                    if (!isUndefined(options?.includeIfEverFinishedIs) && isEverFinished !== options.includeIfEverFinishedIs) {
                        return null;
                    }

                    if (!isUndefined(options?.includeIfLatestFinishedIs) && isLatestFinished !== options.includeIfLatestFinishedIs) {
                        return null;
                    }

                    // Find the newest active session for the assessment.
                    const latestSession = assessmentSessions.find(item => item.assessmentId === assignment.assessmentId);
                    const latestFinishedSession = assessmentSessions.find(item => item.assessmentId === assignment.assessmentId && item.isFinished);

                    return (
                        <DashboardAssessmentCard key={assignment.id}
                            inline={!!options.displayInline}
                            assessment={assessment}
                            assignment={assignment}
                            session={
                                options.includeIfEverFinishedIs === true ? latestFinishedSession // If we're filtered by "if ever finished" we'll show the latest finished session.
                                    : latestSession // Otherwise we'll show the latest session, regardless of state.
                            }
                            canRetake={canRetake}
                        />
                    );
                })
            }
        </>
    ), [userAssessmentAssignments, assessments, assessmentSessions, getAssessmentSummary]);

    // Generate the card components to show for the requested assessmentType,.
    const generateHistoryCards = useCallback(() => {
        // Create a list of the latest time we've done each assessment.
        let latestSessions: Array<AssessmentSession> = [];
        for (const session of assessmentSessions) {
            // Only care about finished sessions.
            if (!session.isFinished) {
                continue;
            }

            // If this is the first time we've seen this assessment, add it to the list.
            const existing = latestSessions.find(it => it.assessmentId === session.assessmentId);
            if (!existing) {
                latestSessions.push(session);
                continue;
            }

            // If the new session finished after the old session, swap it in the list.
            if (existing.endDate < session.endDate) {
                latestSessions[latestSessions.indexOf(existing)] = session;
            }
        }

        // Order by the latest completion first.
        latestSessions.sort((a, b) => {
            if (a.endDate === b.endDate) {
                return 0;
            } else if (a.endDate < b.endDate) {
                return 1;
            } else {
                return -1;
            }
        });

        return (
            <>
                {
                    latestSessions.map(session => {
                        // Lookup the assessment model.
                        const assessment = assessments.find(it => it.id === session.assessmentId);
                        if (!assessment) {
                            return null;
                        }

                        // Lookup the assignment model (there may not be one.).
                        const assignment = userAssessmentAssignments.find(it => it.id === session.assessmentId);

                        // Get some details about what we can and can't do with this assessment.
                        const { canRetake } = getAssessmentSummary(assessment.id);

                        return (
                            <DashboardAssessmentCard key={session.id}
                                inline={true}
                                assessment={assessment}
                                assignment={assignment}
                                session={session}
                                canRetake={canRetake}
                            />
                        );
                    })
                }
            </>
        );
    }, [userAssessmentAssignments, assessments, assessmentSessions, getAssessmentSummary]);


    // Render the UI.
    return (
        <Background>
            <Banner fluid>
                <h1>{t('common.fullName', '{{firstName}} {{lastName}}', { firstName: profile?.firstName, lastName: profile?.lastName })}</h1>
            </Banner>
            <Container fluid>
                <AlertOnErrors errors={[loadErrors]} />

                <div className="mb-4">
                    <h2 style={{ color: 'white' }}>
                        {
                            isStudyParticipant ? t('driverDashboard.study.heading', 'Studies')
                                : t('driverDashboard.assessments.heading', 'Assessments')
                        }
                    </h2>

                    <CardGroup >
                        {
                            generateCards({ assessmentType: AssessmentType.Assessment, includeIfLatestFinishedIs: false, })
                        }
                    </CardGroup>

                    <ConditionalFragment showIf={!assessmentsCount}>
                        <Card body>
                            <NoResultsFound icon={['far', 'smile']}>
                                {
                                    isStudyParticipant ? t('meDashboard.study.noResults', 'You don\'t have any studies to complete right now.')
                                        : t('meDashboard.assessments.noResults', 'You don\'t have any assessments to complete right now.')
                                }
                            </NoResultsFound>
                        </Card>
                    </ConditionalFragment>
                </div>

                <ConditionalFragment showIf={!isStudyParticipant}>
                    <div className="mb-4">
                        <h2 style={{ color: 'white' }}>
                            {t('driverDashboard.training.heading', 'Training')}
                        </h2>
                        <CardGroup>
                            {
                                generateCards({ assessmentType: AssessmentType.TrainingModule, includeIfLatestFinishedIs: false, })
                            }
                        </CardGroup>

                        <ConditionalFragment showIf={!trainingCount}>
                            <Card body>
                                <NoResultsFound icon={['far', 'smile']}>
                                    {t('meDashboard.training.noResults', 'You don\'t have any training to complete right now.')}
                                </NoResultsFound>
                            </Card>
                        </ConditionalFragment>
                    </div>
                </ConditionalFragment>

                <div className="mb-4">
                    <h2 style={{ color: 'white' }}>
                        {t('driverDashboard.history.heading', 'History')}
                    </h2>
                    <Card body>
                        {
                            generateHistoryCards()
                        }

                        <ConditionalFragment showIf={!assessmentSessions.length}>
                            <NoResultsFound icon="history">
                                {t('meDashboard.history.noResults', 'When you complete assessments and training we\'ll keep a history of it here for you.')}
                            </NoResultsFound>
                        </ConditionalFragment>
                    </Card>
                </div>
            </Container>
        </Background>
        );
};
