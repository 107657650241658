import * as React from 'react';
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { AsyncLoadResult, AsyncLoadOptions } from '../../../../shared/abstractStore';
import { editAssessmentViewModelQuery, editAssessmentViewModelQueryVariables } from '../../generated/editAssessmentViewModelQuery';
import { mainApiConfig } from '../../../../configure/mainApiConfig';
import { assessmentFieldsFragment } from '../../models/Assessment';
import { blobReferenceFieldsFragment } from '../../models/BlobReference';
import { Guid } from 'guid-string';
import { assessmentFeedbackFieldsFragment } from '../../models/AssessmentFeedback';
import { driverMetricRecommendationFieldsFragment } from '../../models/DriverMetricRecommendation';
import { driverMetricAdjustmentFieldsFragment } from '../../models/DriverMetricAdjustment';
import { assessmentItemFieldsFragment } from '../../models/AssessmentItem';
import { assessmentItemQuestionFieldsFragment } from '../../models/AssessmentItemQuestion';
import { questionTagLinkFieldsFragment } from '../../models/QuestionTagLink';
import { assessmentItemDriverMetricQuantityFieldsFragment } from '../../models/AssessmentItemDriverMetricQuantity';

/**
 * View model for EditAssessment that replaces the call to useAssessment() and loads the right related data.
 * @param options
 */
export function useEditAssessmentViewModel(id: string | undefined | null, options: AsyncLoadOptions = {}): AsyncLoadResult<editAssessmentViewModelQuery> {
    // Query the data we need form the api.
    const [load, { data, loading, error, refetch }] = useLazyQuery<editAssessmentViewModelQuery, editAssessmentViewModelQueryVariables>(
        gql`
        query editAssessmentViewModelQuery ($id: ID!) {
            model: assessment(id: $id) {
                ...assessmentFields
            }

            assessmentItems: assessmentItems(assessmentId: $id) {
                ...assessmentItemFields
            }

            assessmentItemDriverMetricQuantities: assessmentItemDriverMetricQuantities(assessmentId: $id)  {
                ...assessmentItemDriverMetricQuantityFields
            }

            assessmentItemsQuestions: assessmentItemQuestions(assessmentId: $id) {
                ...assessmentItemQuestionFields
            }

            feedbacks: assessmentFeedbacks(assessmentId: $id) {
                ...assessmentFeedbackFields

                videoBlobReference {
                    ...blobReferenceFields
                }

                imageBlobReference {
                    ...blobReferenceFields
                }
            }

            questionTagLinks: questionTagLinks(targetId: $id) {
                ...questionTagLinkFields
            }

            driverMetricRecommendations: driverMetricRecommendations(targetId: $id) {
                ...driverMetricRecommendationFields
            }

            driverMetricAdjustments: driverMetricAdjustments(targetId: $id) {
                ...driverMetricAdjustmentFields
            }
        }

        ${assessmentFieldsFragment}
        ${assessmentItemFieldsFragment}
        ${assessmentItemDriverMetricQuantityFieldsFragment}
        ${assessmentItemQuestionFieldsFragment}
        ${blobReferenceFieldsFragment}
        ${assessmentFeedbackFieldsFragment}
        ${questionTagLinkFieldsFragment}
        ${driverMetricRecommendationFieldsFragment}
        ${driverMetricAdjustmentFieldsFragment}
        `,
        {
            variables: {
                id: id ?? Guid.empty
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load])

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error
    }), [data, refresh, loading, error]);
}