import { RouteEntry } from "../../../shared/routes";
import { QuestionsList } from './QuestionsList';
import { EditQuestion, CreateQuestion } from "./EditQuestion";
import { IdentityRoles } from "../../../configure/security/IdentityRoles";
import { DeleteQuestion } from "./DeleteQuestion";

export const questionRoutes: Array<RouteEntry> = [
    { path: '/administration/questions', exact: true, component: QuestionsList, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/questions/edit/:id', component: EditQuestion, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/questions/add', component: CreateQuestion, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/questions/delete/:id', component: DeleteQuestion, authorize: true, requireRole: IdentityRoles.Administration },
];
