import * as React from "react";
import { Row, Col, Form, FormGroup, Label, Spinner, Alert } from "reactstrap";
import { useChanges } from "../../shared/useChanges";
import { useValidatorCallback } from "pojo-validator-react";
import { ValidatedInput } from "pojo-validator-reactstrap";
import { ButtonAsync } from "reactstrap-buttonasync"
import { useAuthenticatedState } from "../../api/api-authorization/useAuthenticatedState";
import { LoadingIndicator } from "../shared/LoadingIndicator";
import { Guid } from "guid-string";
import { useProfile } from "../../api/main/profiles/useProfile";
import { useSaveProfileCallback } from "../../api/main/profiles/useSaveProfileCallback";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { useTranslation } from "react-i18next";
import { FormButtons } from "../shared/FormButtons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAsyncCallback } from "react-use-async-callback";
import { MainContainer } from "../shared/MainContainer";
import { ConditionalFragment } from "react-conditionalfragment";

/**
 * Allow the user to change their personal details on their profile.
 */
export const ProfileDetails = () => {
    const { t } = useTranslation();
    const [hasSaved, setHasSaved] = React.useState<boolean>(false);
    const [isCreate, setIsCreate] = React.useState<boolean>(false);
    const { user } = useAuthenticatedState({ includeUser: true});
    const { data: { model: storeModel, }, isLoading, errors: loadErrors, refresh: refreshProfile } = useProfile(user?.sub ?? '', {
        isUserId: true,
        lazy: true,
        onCompleted: (data) => {
            // If we don't find a record in the database, create one ready to save.
            if (!data) {
                setIsCreate(true);
                change({ id: Guid.newGuid(), userId: user?.sub ?? '' });
            }
        } });
    const { model, change, changes } = useChanges(storeModel);
    const [save, { isExecuting: isSaving, errors: saveErrors }] = useSaveProfileCallback({ afterSaving: () => setIsCreate(false) })

    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        if (!model) {
            return;
        }

        const rules = {
            firstName: () => !model.firstName ? t('profileDetails.firstNameRequired', 'First name is required') : '',
            lastName: () => !model.lastName ? t('profileDetails.lastNameRequired', 'Last name is required') : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, [model]);

    // Only load the profile once we know the user.
    React.useEffect(() => {
        if (!user) {
            return;
        }

        refreshProfile();
    }, [user, refreshProfile]);

    const [saveForm] = useAsyncCallback(async () => {
        if (!validate()) {
            return;
        }

        await save(model.id, changes, isCreate);

        setHasSaved(true);
    }, [save, model, setHasSaved, validate, isCreate, changes]);


    // Render the UI.
    //

    return (
        <MainContainer>
            <ConditionalFragment showIf={isLoading}>
                <LoadingIndicator fullWidth />
            </ConditionalFragment>

            <Form onSubmit={async e => { e.preventDefault(); await saveForm(); }}>
                <AlertOnErrors errors={[loadErrors, saveErrors]} />

                {
                    hasSaved ? (
                        <Alert color="success">
                            {t('profileDetails.hasSaved', 'Your details have been updated.')}
                        </Alert>
                        ): null
                }

                <Row>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="firstName">{t('profileDetails.firstName', 'First name')}</Label>
                            <ValidatedInput name="firstName" type="text" value={model.firstName ?? ''} onChange={e => change({ firstName: e.currentTarget.value })} onBlur={e => validate('firstName')} validationErrors={validationErrors['firstName']} />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="lastName">{t('profileDetails.lastName', 'Last name')}</Label>
                            <ValidatedInput name="lastName" type="text" value={model.lastName ?? ''} onChange={e => change({ lastName: e.currentTarget.value })} onBlur={e => validate('lastName')} validationErrors={validationErrors['lastName']} />
                        </FormGroup>
                    </Col>
                </Row>

                <FormButtons>
                    <ButtonAsync color="primary" isExecuting={isSaving}
                        executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}>
                        <FontAwesomeIcon icon="save" />
                        <> {t('common.save', 'Save')}</>
                    </ButtonAsync>
                </FormButtons>
            </Form>
        </MainContainer>
    );
};
