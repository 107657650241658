import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useMemo, useRef, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Button, Col, Row } from "reactstrap";
import { HtmlDisplay } from "../../../../../shared/htmlEditor";
import { ShowQuestionChildProps } from "../ShowQuestion";
import "./showQuestionLearningVideo.scss";

/**
 * Running of a LearningVideo question.
 * @param props
 */
export const ShowQuestionLearningVideo = (props: ShowQuestionChildProps) => {
    const {
        model,
        blobReferences,

        onPageComplete,
    } = props;

    const { t } = useTranslation();

    const videoBlob = useMemo(() => blobReferences.find(it => it.id === model.videoBlobReferenceId), [blobReferences, model]);
    const imageBlob = useMemo(() => blobReferences.find(it => it.id === model.imageBlobReferenceId), [blobReferences, model]);

    // Keep track of if the video has started/finished or not.
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const [hasVideoStarted, setHasVideoStarted] = useState<boolean>(false);
    const [hasVideoFinished, setHasVideoFinished] = useState<boolean>(false);

    // Some calls to apis (such as saving clicks) will cause us to generate a new secure video URL.  To make sure this doesn't reset out
    // video we cache the poster and video urls as we original receive them into our state and always use them.
    const [imageUrl] = useState<string>(imageBlob?.url ?? '');
    const [videoUrl] = useState<string>(videoBlob?.url ?? '');

    // Start the video.
    const startVideo = useCallback(() => {
        setHasVideoStarted(true);
        videoRef.current?.play();
    }, [videoRef, setHasVideoStarted]);

    // Video has ended.
    const onVideoEnded = useCallback(() => {
        setHasVideoFinished(true);

        // Page is complete once learning video has been watched.
        onPageComplete();
    }, [setHasVideoFinished, onPageComplete]);
    
    return (
        <div className="show-question-learning-video">

            {/* Video has not started */}
            <ConditionalFragment showIf={!hasVideoStarted}>
                <div className="show-question-learning-video-background" style={{ backgroundImage: `url("${imageUrl ?? ''}")` }}>
                    <div className="show-question-learning-video-start-overlay"
                        onClick={() => startVideo()}
                    >
                        <Row>
                            <Col>
                            </Col>
                            <Col xs="auto">
                                <div className="mt-4">
                                    <HtmlDisplay html={model.preQuestionText} />
                                </div>

                                <FontAwesomeIcon icon="play-circle" className="show-question-learning-video-start-overlay-play-icon" />
                                <p className="text-muted">
                                    {t('showQuestionLearningVideo.playText', 'Press play to start the video.')}
                                </p>
                            </Col>
                            <Col>
                            </Col>
                        </Row>
                    </div>
                </div>
            </ConditionalFragment>

            <div className="embed-responsive embed-responsive-16by9 mb-4" style={{ display: !hasVideoStarted || hasVideoFinished ? 'none' : undefined }}>
                <video
                    className="show-question-learning-video"
                    disablePictureInPicture
                    ref={videoRef}
                    src={videoUrl}
                    poster={imageUrl}
                    playsInline={true}
                    controls={true}
                    onEnded={() => onVideoEnded()}
                >
                </video>
            </div>

            {/* Video has finished */}
            <ConditionalFragment showIf={hasVideoFinished}>
                <div className="show-question-learning-video-background" style={{ backgroundImage: `url("${imageUrl ?? ''}")` }}>
                    <div className="show-question-learning-video-start-overlay">
                        <div className="mt-4">
                            <HtmlDisplay html={model.questionText} />
                        </div>

                        <div>
                            <Button onClick={() => { setHasVideoFinished(false); startVideo(); }}>
                                {t('showQuestionLearningVideo.watchAgain', 'Watch again')}
                            </Button>
                        </div>

                        <p className="text-muted">
                            {t('showQuestionLearningVideo.endText', 'Press Next to continue.')}
                        </p>
                    </div>
                </div>
            </ConditionalFragment>
        </div>
        );
};