import { NavItem, NavLink } from 'reactstrap';
import { AuthorizeContainer } from '../../shared/authorizeRoute';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLinkTrackActive } from '../shared/NavLinkTrackActive';
import { IdentityRoles } from '../../configure/security/IdentityRoles';
import { ConditionalFragment } from 'react-conditionalfragment';
import { useSubscription } from '../../api/main/subscriptions/useSubscription';
import { SubscriptionType } from '../../api/main/models/codeOnly/SubscriptionType';
import { useCurrentUserProfile } from '../../api/main/profiles/useCurrentUserProfile';
import { Guid } from 'guid-string';
import { useCurrentUserOrEmulatedSubscriptionId } from '../../globalState/subscriptions/useCurrentUserOrEmulatedSubscriptionId';
import { useMemo } from 'react';
import { useCurrentUserRoles } from '../../api/account';

/**
 * Primary navigation menu for the app. 
 */
export const Navigation = () => {
    const { t } = useTranslation();

    // If the user is part of a study, we don't provide any menu options.
    
    const { data: { model: currentProfile } } = useCurrentUserProfile();
    const { data: { roles } } = useCurrentUserRoles();
    const emulatedSubscriptionId = useCurrentUserOrEmulatedSubscriptionId();

    // NOTE we don't want to loose menus when we are emulating a study subscription, so for non-admins we always grab it directly from the profile not using the
    // useCurrentUserOrEmulatedSubscriptionId hook.
    const subscriptionId = useMemo(() => {
        // Admins can use the emulated subscription.
        if (roles?.includes(IdentityRoles.Administration)) {
            return emulatedSubscriptionId;
        }

        // Non-admins can't use the emulated subscription.
        return currentProfile?.subscriptionId ?? Guid.empty;
    }, [currentProfile, emulatedSubscriptionId, roles]);

    // Load the subscription
    const { data: { model } } = useSubscription(subscriptionId ?? Guid.empty);
    if (model?.subscriptionType === SubscriptionType.Study && !roles?.includes(IdentityRoles.SubscriptionAdministration)) {
        return (<></>);
    }


    // Return the general UI.
    return (
        <>
            <AuthorizeContainer>
                <NavItem>
                    <NavLink tag={NavLinkTrackActive} to="/me">
                        <FontAwesomeIcon icon="home" className="nav-icon"  />
                        <> {t('navigation.home', 'Home')}</>
                    </NavLink>
                </NavItem>

                <NavItem>
                    <NavLink tag={NavLinkTrackActive} to="/dashboard">
                        <FontAwesomeIcon icon="tachometer-alt" className="nav-icon" />
                        <> {t('navigation.dashboard', 'Dashboard')}</>
                    </NavLink>
                </NavItem>
            </AuthorizeContainer>

            <AuthorizeContainer requireRole={[IdentityRoles.DriverManagement, IdentityRoles.SubscriptionAdministration]}>
                <ConditionalFragment showIf={model?.subscriptionType === SubscriptionType.Subscription}>
                    <NavItem>
                        <NavLink tag={NavLinkTrackActive} to="/manage">
                            <FontAwesomeIcon icon="users-cog" className="nav-icon" />
                            <> {t('navigation.manageSubscription', 'Manage subscription')}</>
                        </NavLink>
                    </NavItem>
                </ConditionalFragment>
            </AuthorizeContainer>

            <AuthorizeContainer requireRole={[IdentityRoles.DistributionAdministration, IdentityRoles.Administration]}>
                <ConditionalFragment showIf={model?.subscriptionType === SubscriptionType.Distributor}>
                    <NavItem>
                        <NavLink tag={NavLinkTrackActive} to={`/manage/distributor`}>
                            <FontAwesomeIcon icon="users-cog" className="nav-icon" />
                            <> {t('navigation.manageDistributor', 'Manage distributor')}</>
                        </NavLink>
                    </NavItem>
                </ConditionalFragment>
            </AuthorizeContainer>

            <AuthorizeContainer requireRole={IdentityRoles.Administration}>
                <NavItem>
                    <NavLink tag={NavLinkTrackActive} to="/administration">
                        <FontAwesomeIcon icon="cogs" className="nav-icon" />
                        <> {t('navigation.administration', 'Administration')}</>
                    </NavLink>
                </NavItem>
            </AuthorizeContainer>
        </>
    );
};
