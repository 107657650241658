import { RouteEntry } from "../../../shared/routes";
import { IdentityRoles } from "../../../configure/security/IdentityRoles";
import { UserAssessmentTrainingModuleAssignmentsList } from "./UserAssessmentTrainingModuleAssignmentsList";

export const userTrainingModuleAssessmentAssignmentsRoutes: Array<RouteEntry> = [
    { path: '/manage/teams/details/:subscriptionTeamId/training', exact: true, component: UserAssessmentTrainingModuleAssignmentsList, authorize: true, requireRole: IdentityRoles.DriverManagement },

    { path: '/manage/drivers/details/:profileId/training', exact: true, component: UserAssessmentTrainingModuleAssignmentsList, authorize: true, requireRole: IdentityRoles.DriverManagement },
    { path: '/manage/managers/details/:profileId/training', exact: true, component: UserAssessmentTrainingModuleAssignmentsList, authorize: true, requireRole: IdentityRoles.DriverManagement },

    { path: '/administration/administrators/details/:profileId/training', exact: true, component: UserAssessmentTrainingModuleAssignmentsList, authorize: true, requireRole: IdentityRoles.DriverManagement },
];
