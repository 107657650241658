export interface ResponseWindowColor {
    stroke: string,
    fill: string,
}

/**
 * Colours to use for different discrimination groups of response windows.
 */
export const responseWindowColors: Array<ResponseWindowColor> = [
    { stroke: 'darkgreen', fill: 'green', },
    { stroke: 'darkred', fill: 'red', },
    { stroke: 'darkblue', fill: 'blue', },
    { stroke: 'darkyellow', fill: 'yellow', },
    { stroke: 'darkpurple', fill: 'purple', },
];