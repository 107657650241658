import * as React from "react";
import { Card, CardHeader, CardBody } from "reactstrap";
import { useTranslation } from "react-i18next";
import { MainContainer } from "../shared/MainContainer";
import { Background } from "../shared/background/Background";

/**
 * Default thanks screen after user has completed a study and been logged out.
 */
export const StudyThanks = () => {
    const { t } = useTranslation();

    // Render the UI.
    return (
        <Background centerChildren="vertically">
            <MainContainer color="transparent">
                <Card color="dark">
                    <CardHeader>
                        <h1>
                            {t('studyThanks.heading', 'Thank you for participating in this study')}
                        </h1>
                    </CardHeader>
                    <CardBody>
                        {t('studyThanks.message', 'Thank you for participating in this study.  You responses have been captured and you can now close this window.')}
                    </CardBody>
                </Card>
            </MainContainer>
        </Background>
    );
};
