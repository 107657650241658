import { QuestionType } from "../../../api/main/models/codeOnly/QuestionType";
import { CreateQuestionBase, EditQuestionBase } from "../edit/EditQuestionBase";

/**
 * Create a questionnaire.
 */
export const CreateQuestionnaire = () => (
    <CreateQuestionBase
        defaultQuestionType={QuestionType.Questionnaire}
        onCreateDefaultValues={() => ({ questionType: QuestionType.Questionnaire, })}
    />);

/**
 * Edit a questionnaire
 */
export const EditQuestionnaire = () => (<EditQuestionBase defaultQuestionType={QuestionType.Questionnaire} />);
