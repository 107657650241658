import { useTranslation } from "react-i18next";
import { NavItem, NavLink } from "reactstrap";
import { NavLinkTrackActive } from "../shared/NavLinkTrackActive";

/**
 * Navigation under the Profile area.
 */
export const ProfileNavigation = () => {
    const { t } = useTranslation();

    return (
        <>
            <NavItem>
                <NavLink tag={NavLinkTrackActive} to="/profile/details">
                    {t('profile.profile', 'Profile')}
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink tag={NavLinkTrackActive} to="/profile/changeEmail">
                    {t('profile.changeEmail', 'Email')}
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink tag={NavLinkTrackActive} to="/profile/changePassword">
                    {t('profile.changePassword', 'Change Password')}
                </NavLink>
            </NavItem>
        </>
    );
};
