import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ValidationErrors } from "pojo-validator";
import { ValidateCallback } from "pojo-validator-react";
import { ValidatedInput } from "pojo-validator-reactstrap";
import { MutableRefObject, useMemo } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Button, ButtonGroup, Card, CardBody, Col, Collapse, FormGroup, FormText, Input, InputGroup, InputGroupAddon, Label, Row } from "reactstrap";
import { useToggleState } from "use-toggle-state";
import { QuestionAnswer } from "../../../../api/main/models/QuestionAnswer";
import { HtmlDisplay, HtmlEditor } from "../../../../shared/htmlEditor";
import { timeStringFromSeconds } from "../../../../utilities/timeStringFromSeconds";
import { CardHeaderCollapse } from "../../../shared/cardHeaderCollapse/CardHeaderCollapse";
import { FormButtons } from "../../../shared/FormButtons";

export interface QuestionAnswerResponseTimeVideoProps {
    model: QuestionAnswer | undefined,
    change: (changes: Partial<QuestionAnswer>) => void,
    remove: () => void,
    moveUp: () => void,
    canMoveUp: boolean,
    moveDown: () => void,
    canMoveDown: boolean,

    isOpenInitially?: boolean,

    validate: ValidateCallback,
    validationErrors: ValidationErrors,

    currentVideoTime: MutableRefObject<number>,

    numberOfSubQuestions: number,
}

/**
 * Response time window for ResponseTimeVideo questions.
 * @param props
 */
export const QuestionAnswerResponseTimeVideo = (props: QuestionAnswerResponseTimeVideoProps) => {
    const {
        model,
        change,
        remove,
        moveUp,
        canMoveUp,
        moveDown,
        canMoveDown,

        isOpenInitially = false,

        validate,
        validationErrors,

        currentVideoTime,

        numberOfSubQuestions,
    } = props;

    const { t } = useTranslation();

    // Self management of if we are expanded or not.
    const [isOpen, toggleOpen] = useToggleState(isOpenInitially);

    const startTimeString = useMemo(() => timeStringFromSeconds(model?.startResponseTimeSeconds ?? 0), [model?.startResponseTimeSeconds]);
    const endTimeString = useMemo(() => timeStringFromSeconds(model?.endResponseTimeSeconds ?? 0), [model?.endResponseTimeSeconds]);

    return (
        <Card>
            <CardHeaderCollapse isOpen={isOpen} toggle={toggleOpen}>
                <Row>
                    <Col xs="auto">
                        {t('questionAnswerResponseTimeVideo.heading', '{{startTimeString}} to {{endTimeString}}', { startTimeString, endTimeString })}
                    </Col>
                    <Col>
                        <div style={{ maxHeight: '2rem', overflowY: 'hidden', }}>
                            <HtmlDisplay html={model?.answerText ?? ''} />
                        </div>
                    </Col>
                    <ConditionalFragment showIf={numberOfSubQuestions > 1 || (model?.subQuestionNumber ?? 1) > 1}>
                        <Col xs="auto">
                            {t('questionAnswerResponseTimeVideo.responseGroupHeading', 'Part {{subQuestionNumber}}', { subQuestionNumber: model?.subQuestionNumber ?? 1 })}
                        </Col>
                    </ConditionalFragment>
                </Row>
            </CardHeaderCollapse>

            <Collapse isOpen={isOpen}>
                <CardBody>
                    <FormButtons noPadding>
                        <ButtonGroup>
                            <Button color="primary" outline onClick={() => moveUp()} disabled={!canMoveUp}>
                                <FontAwesomeIcon icon="caret-up" />
                                <> </>
                                {t('questionAnswerResponseTimeVideo.up', 'Up')}
                            </Button>
                            <Button color="primary" outline onClick={() => moveDown()} disabled={!canMoveDown}>
                                <FontAwesomeIcon icon="caret-down" />
                                <> </>
                                {t('questionAnswerResponseTimeVideo.down', 'Down')}
                            </Button>
                            <Button color="danger" outline onClick={() => remove()}>
                                <FontAwesomeIcon icon="trash-alt" />
                                <> </>
                                {t('questionAnswerResponseTimeVideo.delete', 'Delete window')}
                            </Button>
                        </ButtonGroup>
                    </FormButtons>

                    <ConditionalFragment showIf={numberOfSubQuestions > 1 || (model?.subQuestionNumber ?? 1) > 1}>
                        <FormGroup>
                            <Label htmlFor="questionText">{t('questionAnswerResponseTimeVideo.subQuestionNumber', 'Question part')}</Label>

                            <Input name="subQuestionNumber" type="number" min={1} max={numberOfSubQuestions} value={model?.subQuestionNumber ?? ''} onChange={e => change({ subQuestionNumber: e.currentTarget.valueAsNumber })} />
                        </FormGroup>
                    </ConditionalFragment>

                    <Row>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="score">{t('questionAnswerResponseTimeVideo.responseTimeStart.label', 'Starting timestamp')}</Label>

                                <InputGroup>
                                    <Input type="number" value={(model?.startResponseTimeSeconds ?? 0) * 1000} onChange={e => change({ startResponseTimeSeconds: e.currentTarget.valueAsNumber / 1000 })} />
                                    <InputGroupAddon addonType="append">
                                        {t('questionAnswerResponseTimeVideo.ms', 'ms')}
                                    </InputGroupAddon>
                                    <InputGroupAddon addonType="append">
                                        <Button color="primary" onClick={() => change({ startResponseTimeSeconds: currentVideoTime.current })}>
                                            {t('questionAnswerResponseTimeVideo.useCurrentTime', 'Use current time')}
                                        </Button>
                                    </InputGroupAddon>
                                </InputGroup>
                                <FormText>
                                    {startTimeString}
                                </FormText>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="score">{t('questionAnswerResponseTimeVideo.responseTimeEnd.label', 'Ending timestamp')}</Label>

                                <InputGroup>
                                    <Input type="number" value={(model?.endResponseTimeSeconds ?? 0) * 1000} onChange={e => change({ endResponseTimeSeconds: e.currentTarget.valueAsNumber / 1000 })} />
                                    <InputGroupAddon addonType="append">
                                        {t('questionAnswerResponseTimeVideo.ms', 'ms')}
                                    </InputGroupAddon>
                                    <InputGroupAddon addonType="append">
                                        <Button color="primary" onClick={() => change({ endResponseTimeSeconds: currentVideoTime.current })}>
                                            {t('questionAnswerResponseTimeVideo.useCurrentTime', 'Use current time')}
                                        </Button>
                                    </InputGroupAddon>
                                </InputGroup>
                                <FormText>
                                    {endTimeString}
                                </FormText>
                            </FormGroup>
                        </Col>
                    </Row>    
                    

                    <FormGroup>
                        <Label htmlFor="answerText">{t('questionAnswerResponseTimeVideo.answerText', 'Window description')}</Label>
                        <HtmlEditor value={model?.answerText ?? ''} onChange={html => change({ answerText: html })} />
                    </FormGroup>

                    <Row>
                        {/*<Col>*/}
                        {/*    <FormGroup>*/}
                        {/*        <Label htmlFor="isCorrect">{t('questionAnswerResponseTimeVideo.isCorrect', 'Is this a correct answer?')}</Label>*/}

                        {/*        <TwoValueSwitch*/}
                        {/*            leftLabel={t('questionAnswerResponseTimeVideo.isCorrect.false', 'Incorrect')}*/}
                        {/*            rightLabel={t('questionAnswerResponseTimeVideo.isCorrect.true', 'Correct')}*/}
                        {/*            checked={model?.isCorrect ?? false} onChange={checked => {*/}
                        {/*                // Toggle the flag, and if we're using default scoring at the moment, also default the score.*/}
                        {/*                if (checked && model?.score === 0) {*/}
                        {/*                    change({ isCorrect: checked, score: 10 });*/}
                        {/*                } else if (!checked && model?.score === 10) {*/}
                        {/*                    change({ isCorrect: checked, score: 0 });*/}
                        {/*                } else {*/}
                        {/*                    change({ isCorrect: checked });*/}
                        {/*                }*/}
                        {/*            }}*/}
                        {/*            />*/}
                        {/*    </FormGroup>*/}
                        {/*</Col>*/}
                        <Col>
                            <FormGroup>
                                <Label htmlFor="score">{t('questionAnswerResponseTimeVideo.score.label', 'Score')}</Label>

                                <InputGroup>
                                    <ValidatedInput name="score" type="number" min={-100} max={100} value={model?.score ?? ''} onChange={e => change({ score: e.currentTarget.valueAsNumber })} onBlur={() => validate('score')} validationErrors={validationErrors['score']} />
                                    <InputGroupAddon addonType="append">
                                        {t('common.percentageSign', '%')}
                                    </InputGroupAddon>
                                </InputGroup>
                                <FormText>
                                    {t('questionAnswerResponseTimeVideo.score.help', 'Score will normally be between 0% (incorrect) and 100% (correct).')}
                                </FormText>
                            </FormGroup>
                        </Col>
                    </Row>
                </CardBody>
            </Collapse>
        </Card>
        );
};