import { TFunction } from "i18next";

/**
 * Subscription types
 */
export enum SubscriptionType {
    Subscription = "Subscription",
    Distributor = "Distributor",
    Study = "Study"
}

/**
 * Return translated display name if its needed.
 * @param t
 */
export function subscriptionTypeDisplayName(type: SubscriptionType, t: TFunction): string {
    switch (type) {
        case SubscriptionType.Subscription: return t('subscriptionTypeDisplayName.Subscription', 'Subscription');
        case SubscriptionType.Distributor: return t('subscriptionTypeDisplayName.Distributor', 'Distributor');
        case SubscriptionType.Study: return t('subscriptionTypeDisplayName.Study', 'Study');
    }
}
