import * as React from 'react';
import { DriverMetricAdjustmentCreateInput, DriverMetricAdjustmentUpdateInput } from '../generated/globalTypes';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useCreateDriverMetricAdjustmentCallback } from './useCreateDriverMetricAdjustmentCallback';
import { useUpdateDriverMetricAdjustmentCallback } from './useUpdateDriverMetricAdjustmentCallback';
import { DriverMetricAdjustment } from '../models/DriverMetricAdjustment';

/**
 * Get a callback to save a DriverMetricAdjustment in the store using either a create or update.
 */
export function useSaveDriverMetricAdjustmentCallback(options: SaveInStoreOptions<DriverMetricAdjustment, string> = {}) {
    const [_create, createStatus] = useCreateDriverMetricAdjustmentCallback();
    const create = React.useCallback((model: Partial<DriverMetricAdjustment>) => _create(model as DriverMetricAdjustmentCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateDriverMetricAdjustmentCallback();
    const update = React.useCallback((id: string, changes: Partial<DriverMetricAdjustment>) => _update(id, changes as DriverMetricAdjustmentUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
