import { RouteEntry } from "../../../shared/routes";
import { EditTrainingModule, CreateTrainingModule } from "./EditTrainingModule";
import { IdentityRoles } from "../../../configure/security/IdentityRoles";
import { TrainingModulesList } from "./TrainingModulesList";
import { DeleteTrainingModule } from "./DeleteTrainingModule";

export const trainingModuleAssessmentRoutes: Array<RouteEntry> = [
    { path: '/administration/training', exact: true, component: TrainingModulesList, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/training/edit/:id', component: EditTrainingModule, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/training/add', component: CreateTrainingModule, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/training/delete/:id', component: DeleteTrainingModule, authorize: true, requireRole: IdentityRoles.Administration },
];
