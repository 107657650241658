import * as React from 'react';
import { QuestionCreateInput, QuestionUpdateInput } from '../generated/globalTypes';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useCreateQuestionCallback } from './useCreateQuestionCallback';
import { useUpdateQuestionCallback } from './useUpdateQuestionCallback';
import { Question } from '../models/Question';

/**
 * Get a callback to save a Question in the store using either a create or update.
 */
export function useSaveQuestionCallback(options: SaveInStoreOptions<Question, string> = {}) {
    const [_create, createStatus] = useCreateQuestionCallback();
    const create = React.useCallback((model: Partial<Question>) => _create(model as QuestionCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateQuestionCallback();
    const update = React.useCallback((id: string, changes: Partial<Question>) => _update(id, changes as QuestionUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
