import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ValidationErrors } from "pojo-validator";
import { ValidateCallback } from "pojo-validator-react";
import { useTranslation } from "react-i18next";
import { Button, ButtonGroup, Card, CardBody, Collapse, FormGroup, Label, InputGroupAddon, InputGroup, FormText} from "reactstrap";
import { useToggleState } from "use-toggle-state";
import { QuestionAnswer } from "../../../../api/main/models/QuestionAnswer";
import { HtmlDisplay, HtmlEditor } from "../../../../shared/htmlEditor";
import { CardHeaderCollapse } from "../../../shared/cardHeaderCollapse/CardHeaderCollapse";
import { FormButtons } from "../../../shared/FormButtons";
import { TwoValueSwitch } from "../../../shared/TwoValueSwitch";
import { ConditionalFragment } from "react-conditionalfragment";
import { ValidatedInput } from "pojo-validator-reactstrap";

export interface SlidingScaleQuestionnaireQuestionProps {


    model: QuestionAnswer | undefined,
    change: (changes: Partial<QuestionAnswer>) => void,

    remove: () => void,
    moveUp: () => void,
    canMoveUp: boolean,
    moveDown: () => void,
    canMoveDown: boolean,

    isOpenInitially?: boolean,

    validate: ValidateCallback,
    validationErrors: ValidationErrors,
}

/**
 * Answer on the scale for SlidingScaleVideo questions.
 * @param props
 */
export const SlidingScaleQuestionnaireQuestion = (props: SlidingScaleQuestionnaireQuestionProps) => {
    const {

        model,
        change,


        remove,
        moveUp,
        canMoveUp,
        moveDown,
        canMoveDown,

        isOpenInitially = false,

        validate,
        validationErrors
    } = props;

    const { t } = useTranslation();

    // Self management of if we are expanded or not.
    const [isOpen, toggleOpen] = useToggleState(isOpenInitially);



    return (
    <>

        <Card>
            <CardHeaderCollapse isOpen={isOpen} toggle={toggleOpen}>
                <div style={{ maxHeight: '2rem', overflowY: 'hidden', }}>
                    <HtmlDisplay html={model?.answerText ?? ''} />
                </div>
            </CardHeaderCollapse>

            <Collapse isOpen={isOpen}>
                <CardBody>
                    <FormButtons noPadding>
                        <ButtonGroup>
                            <Button color="primary" outline onClick={() => moveUp()} disabled={!canMoveUp}>
                                <FontAwesomeIcon icon="caret-up" />
                                <> </>
                                {t('questionAnswerSlidingScaleVideo.up', 'Up')}
                            </Button>
                            <Button color="primary" outline onClick={() => moveDown()} disabled={!canMoveDown}>
                                <FontAwesomeIcon icon="caret-down" />
                                <> </>
                                {t('questionAnswerSlidingScaleVideo.down', 'Down')}
                            </Button>
                            <Button color="danger" outline onClick={() => remove()}>
                                <FontAwesomeIcon icon="trash-alt" />
                                <> </>
                                {t('questionAnswerSlidingScaleVideo.delete', 'Delete mark')}
                            </Button>
                        </ButtonGroup>
                    </FormButtons>

                    <FormGroup>
                        <Label htmlFor="answerText">{t('questionAnswerSlidingScaleVideo.answerText', 'Mark label')}</Label>
                        <HtmlEditor value={model?.answerText ?? ''} onChange={html => change({ answerText: html })} />
                        </FormGroup>

                        <Label htmlFor="isCorrect">{t('questionnaireAnswerMultipleChoice.isCorrect.label', 'Is this a correct answer?')}</Label>

                        <TwoValueSwitch
                            leftLabel={t('questionnaireAnswerMultipleChoice.isCorrect.false', 'Incorrect')}
                            rightLabel={t('questionnaireAnswerMultipleChoice.isCorrect.true', 'Correct')}
                            checked={model?.isCorrect ?? false} onChange={checked => {
                                // Toggle the flag, and if we're using default scoring at the moment, also default the score.
                                if (checked && model?.score === 0) {
                                    change({ isCorrect: checked, score: 100 });
                                } else if (!checked && model?.score === 100) {
                                    change({ isCorrect: checked, score: 0 });
                                } else {
                                    change({ isCorrect: checked });
                                }
                            }}
                        />
                        <ConditionalFragment showIf={model?.isCorrect ?? false}>
                            <FormGroup>
                            <InputGroup>
                            <ValidatedInput name={"score"}
                                type="number"
                                value={model?.score ?? ''}
                                min={-100} max={100}
                                onChange={e => change({ score: e.currentTarget.valueAsNumber })}
                                onBlur={() => validate('score')}
                                validationErrors={validationErrors['score']}
                            />
                            <InputGroupAddon addonType="append">
                                {t('common.percentageSign', '%')}
                            </InputGroupAddon>
                                </InputGroup>
                                <FormText>
                                    {t('questionAnswerMultipleChoiceVideo.score.help', 'Score will normally be between 0% (incorrect) and 100% (correct).')}
                                </FormText>
                            </FormGroup>
                        </ConditionalFragment>

                </CardBody>
            </Collapse>
        </Card>



    </>
    );
};
