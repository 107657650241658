import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Guid } from "guid-string";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Button, ButtonGroup, Card, Col, Row } from "reactstrap";
import { HtmlDisplay } from "../../../../../shared/htmlEditor";
import { usePrefetchImages } from "../../../../../shared/prefetchImage";
import { ResponseWindowColor, responseWindowColors } from "../../../../questions/edit/locationDiscriminationImage/reponseWindowColors";
import { InteractiveImage, PointConversion } from "../../../../shared/interactiveImage/InteractiveImage";
import { OverlayShape, OverlayShapeType } from "../../../../shared/interactiveImage/OverlayShape";
import { ShowQuestionChildProps } from "../ShowQuestion";
import { useClickSound } from "../useClickSound/useClickSound";
import { QuestionTimer } from "./QuestionTimer";
import "./showQuestionLocationDiscriminationImage.scss";

/**
 * Running of a LocationDiscriminationImage question.
 * @param props
 */
export const ShowQuestionLocationDiscriminationImage = (props: ShowQuestionChildProps) => {
    const {
        model,
        answers,
        blobReferences,

        addResponseEvent,
        cancelResponseEvent,
        questionResponseEventsManager,

        resetResponseTimeStart,
        calculateResponseTime,
        hasFailedMaximumClickRule,
        isMaximumClickRuleFailtureRecord,

        onPageComplete,
    } = props;

    const { t } = useTranslation();

    const imageBlob = useMemo(() => blobReferences.find(it => it.id === model.imageBlobReferenceId), [blobReferences, model]);
    const questionTextImageBlob = useMemo(() => blobReferences.find(it => it.id === model.questionTextImageBlobReferenceId), [blobReferences, model]);

    // At the end, with the question, we either want to show a specific image set to show with the question, or the original thumbnail again.
    const endImageBlob = questionTextImageBlob ?? imageBlob;

    // Keep track of if the video has started/finished or not.
    const [hasActivityStarted, setHasActivityStarted] = useState<boolean>(false);
    const [hasActivityFinished, setHasActivityFinished] = useState<boolean>(false);

    // Some calls to apis (such as saving clicks) will cause us to generate a new secure video URL.  To make sure this doesn't reset out
    // video we cache the poster and video urls as we original receive them into our state and always use them.
    const [imageUrl] = useState<string>(imageBlob?.url ?? '');
    const [endImageUrl] = useState<string>(endImageBlob?.url ?? '');

    // Prefetch the images we use.
    const resolveImageUrl = usePrefetchImages([
        imageUrl,
        endImageUrl,
    ]);


    // Start the video.
    const startActivity = useCallback(() => {
        setHasActivityStarted(true);
        resetResponseTimeStart();
    }, [setHasActivityStarted, resetResponseTimeStart]);

    // Activity has ended.
    const onActivityEnded = useCallback(() => {
        setHasActivityFinished(true);
        resetResponseTimeStart();

        // Let the navigation know this question has been completed.
        onPageComplete();
    }, [setHasActivityFinished, resetResponseTimeStart, onPageComplete]);

    // Work out the discrimination groups and their associated colours.
    const discriminationGroups = useMemo(() => {
        let ret = Array<{ name: string, color: ResponseWindowColor }>();

        let colorIndex = 0;
        for (const answer of answers) {
            const existing = ret.find(it => it.name === answer.discriminationGroup);
            if (existing) {
                continue;
            }

            // Add a new entry.
            ret.push({
                name: answer.discriminationGroup,
                color: responseWindowColors[colorIndex % responseWindowColors.length],
            });

            // Move on to the next colour.
            ++colorIndex;
        }

        return ret;
    }, [answers]);

    // Generate overlays for each answer.
    const displayShapeSize = useMemo(() => ({ x: 50, y: 50 }), []);
    const [{ displayToVirtual }, setVirtualConversions] = useState<{ displayToVirtual: PointConversion, virtualToDisplay: PointConversion }>({
        displayToVirtual: point => point,
        virtualToDisplay: point => point,
    });
    const onVirtualConversionChanged = useCallback((conversions: { displayToVirtual: PointConversion, virtualToDisplay: PointConversion }) => {
        setVirtualConversions(conversions)
    }, [setVirtualConversions]);
    const shapeType: OverlayShapeType = 'circle';
    const overlays: Array<OverlayShape> = useMemo(() =>
        questionResponseEventsManager.model
            .filter(item => item.questionId === model.id && !item.archived)
            .filter(item => !isMaximumClickRuleFailtureRecord(item))
            .map(item => {
                const virtualSize = displayToVirtual(displayShapeSize);

                return ({
                    id: item.id,
                    label: '', //textFromHtml(item.answerText),
                    x: item.clickPositionX,
                    y: item.clickPositionY,
                    width: virtualSize.x,
                    height: virtualSize.y,

                    shape: shapeType,

                    opacity: 0.5,
                    ...(
                        discriminationGroups.find(it => it.name === item.discriminationGroup)?.color
                        ?? responseWindowColors[0]
                    ),
                } as OverlayShape
                );
            })
        , [questionResponseEventsManager.model, displayToVirtual, displayShapeSize, discriminationGroups, model, isMaximumClickRuleFailtureRecord]);


    // Activity playback tracking.
    const [addingShapeForClick, setAddingShapeForClick] = useState<Partial<OverlayShape> | null>(null);
    const [discriminationMenuPosition, setDiscriminationMenuPosition] = useState<{ x: number, y: number }>({ x: 0, y: 0 });
    const discriminationMenuMaxWidth = 200;
    const onResponseClickEventStarted = useCallback((shapeProps: Partial<OverlayShape>): OverlayShape => {
        const newItemId = Guid.newGuid();
        const newShapeProps = {
            ...shapeProps,
            shape: shapeType,
            id: newItemId,
        } as OverlayShape;

        // Store the fact we are trying to add this shape.
        setAddingShapeForClick(newShapeProps);

        // Let something be rendered.
        return newShapeProps;
    }, [setAddingShapeForClick]);

    const [playClickSound] = useClickSound();

    const onResponseClickEventFinished = useCallback((shapeProps: Partial<OverlayShape>, discriminationGroup: string): OverlayShape => {
        const window = answers.find(item =>
            item.windowLeft <= (shapeProps.x ?? 0)
            && (shapeProps.x ?? 0) < item.windowRight
            && item.windowTop <= (shapeProps.y ?? 0)
            && (shapeProps.y ?? 0) < item.windowBottom
            );

        // If we are outside a window, we will be applying a default score of 0.
        const defaultScoreOutsideOfWindow = 0;
        
        const newItemId = Guid.newGuid();
        addResponseEvent({
            id: newItemId,

            questionAnswerId: window?.id,
            score: window?.score ?? defaultScoreOutsideOfWindow,
            isCorrect: (window?.discriminationGroup === discriminationGroup) ?? false,
            responseTimeSeconds: calculateResponseTime(),
            discriminationGroup: discriminationGroup ?? '',

            clickPositionX: shapeProps.x ?? 0,
            clickPositionY: shapeProps.y ?? 0,
        });

        // Clear the shape thats in the process of being added as we've now added it.
        setAddingShapeForClick(null);

        // Make a click sound.
        playClickSound();

        // Return information to the interactive image so the new item can be selected automatically.
        return {
            ...shapeProps,
            shape: shapeType,
            id: newItemId,
        } as OverlayShape;
    }, [addResponseEvent, setAddingShapeForClick, answers, calculateResponseTime, playClickSound]);

    // Shape has been clicked.
    const onShapeClicked = useCallback((shape: OverlayShape) => {
        // When a shape is clicked, delete it as a response.
        cancelResponseEvent(shape.id)

        playClickSound();
    }, [cancelResponseEvent, playClickSound]);

    // Manage the time limit countdown.
    const [secondsRemaining, setSecondsRemaining] = useState<number>(model?.timeLimitSeconds ?? 0);
    useEffect(() => {
        const timer = setInterval(() => {
            setSecondsRemaining(prevState => {
                const newState = prevState - 1;

                if (newState <= 0) {
                    clearInterval(timer);
                    onActivityEnded();
                }

                return newState;
            })
        }, 1000);

        // Cleanup timer if the component is unmounted.
        return () => clearInterval(timer);
    }, [setSecondsRemaining, onActivityEnded]);

    // Score card, used in lots of places.
    const ScoreCard = (props: { includeQuestionTimer?: boolean, }) => (
        <Card body className="mt-2 ">
            <Row>
                <Col></Col>
                {
                    discriminationGroups.map(item => (
                        <Col key={item.name} xs={12} sm="auto">
                            <span style={{ color: item.color.fill }}>
                                {item.name}
                            </span>
                            <>: </>
                            {questionResponseEventsManager.model.filter(response => response.questionId === model.id && !response.archived && response.discriminationGroup === item.name && !isMaximumClickRuleFailtureRecord(response)).length}
                            <br />
                        </Col>
                    ))
                }
                <Col className="text-right">
                    {
                        props.includeQuestionTimer ? (
                            <QuestionTimer secondsRemaining={secondsRemaining} />
                        ) : null
                    }
                </Col>
            </Row>
            <ConditionalFragment showIf={hasFailedMaximumClickRule}>
                <p className="text-danger text-center">
                    {t('showQuestionLocationDiscrimination.failedMaximumClickRule', 'You exceeded the maximum number of clicks for this question and will score 0% for this question.')}
                </p>
            </ConditionalFragment>
        </Card>
        );

    return (
        <div className="show-question-location-discrimination-image">

            {/* Activity has not started */}
            <ConditionalFragment showIf={!hasActivityStarted}>
                <div className="show-question-location-discrimination-image-background" style={{ backgroundImage: `url("${resolveImageUrl(imageUrl ?? '')}")` }}>
                    <div className="show-question-location-discrimination-image-start-overlay"
                        onClick={() => startActivity()}
                    >
                        <HtmlDisplay html={model.preQuestionText} />

                        <Row>
                            <Col>
                            </Col>
                            <Col xs="auto">
                                <FontAwesomeIcon icon="play-circle" className="show-question-location-discrimination-image-start-overlay-play-icon" />
                                <p className="text-muted">
                                    {t('showQuestionLocationDiscrimnationImage.playText', 'Press play to show the scene and start the activity.  You will have {{timeLimitSeconds}} seconds to complete the activity.', { timeLimitSeconds: model?.timeLimitSeconds ?? 0 })}
                                </p>
                            </Col>
                            <Col>
                            </Col>
                        </Row>
                    </div>
                </div>
            </ConditionalFragment>

            {/* Activity has started but is not yet finished */}
            <ConditionalFragment showIf={hasActivityStarted && !hasActivityFinished}>
                <div className="mb-4" onClick={e => setDiscriminationMenuPosition({ x: e.pageX, y: e.pageY, })}>
                    <InteractiveImage
                        src={resolveImageUrl(imageUrl)}
                        overlays={overlays}
                        allowAdd={true}
                        addOverlayShapeType={shapeType}
                        onAddOverlay={onResponseClickEventStarted}
                        onShapeClicked={onShapeClicked}
                        onVirtualConversionChanged={onVirtualConversionChanged}
                    />
                </div>

                <ConditionalFragment showIf={!!addingShapeForClick}>
                    <div
                        className="show-question-location-discrimination-image-group-choice-menu"
                        style={{
                            left: `${discriminationMenuPosition.x - (discriminationMenuMaxWidth / 2)}px`,
                            top: `${discriminationMenuPosition.y - 20 /* Just enough to put first buttons under cursor */}px`,
                            maxWidth: `${discriminationMenuMaxWidth}px`,
                        }}
                    >
                        <ButtonGroup>
                            {
                                discriminationGroups.map(item => (
                                    <Button key={item.name} style={{ backgroundColor: item.color.fill, borderColor: item.color.stroke, }}
                                        onClick={e => {
                                            if (!addingShapeForClick) {
                                                return;
                                            }

                                            onResponseClickEventFinished(addingShapeForClick, item.name);
                                        }}
                                    >
                                        {item.name}
                                    </Button>
                                    ))
                            }
                        </ButtonGroup>
                    </div>
                </ConditionalFragment>

                <ScoreCard includeQuestionTimer={true} />
            </ConditionalFragment>

            {/* Activity has finished */}
            <ConditionalFragment showIf={hasActivityFinished}>
                <div className="show-question-location-discrimination-image-background" style={{ backgroundImage: `url("${resolveImageUrl(endImageUrl) ?? ''}")` }}>
                    <div className="show-question-location-discrimination-image-answer-overlay">
                        <HtmlDisplay html={model.questionText} />

                        <div>
                            <ScoreCard />
                        </div>

                        <p className="text-muted">
                            {t('showQuestionLocationDiscrimnationImage.endText', 'Press Next to continue.')}
                        </p>
                    </div>
                </div>
            </ConditionalFragment>
        </div>
        );
};