import gql from "graphql-tag";
import { Guid } from "guid-string";
import { profileFields } from "../generated/profileFields";
import { userFieldsFragment } from "./User";

export const profileFieldsFragment = gql`
    fragment profileFields on Profile {
        id
        userId
        firstName
        lastName
        archived
        subscriptionId
        uniqueDriverPin
        subscriptionTeamId

        user {
            ...userFields,
        }
    }

    ${userFieldsFragment}
`;


export type Profile = Omit<profileFields, '__typename' | 'user'>;

/**
 * Default values.
 */
export const profileDefaultValues = (): Partial<Profile> => ({
    id: Guid.newGuid(),
    firstName: '',
    lastName: '',
    subscriptionId: null,
    subscriptionTeamId: null,
    uniqueDriverPin: '',
});