import { ApexOptions }from "apexcharts";
import Chart from "react-apexcharts";
import { useMemo } from "react";
import { Card } from "reactstrap";
import { ChartDataSeries } from "../chartDataUtilities/ChartData";
import { chartDataConverters } from "../chartDataUtilities/ChartDataConverter";
import { useTranslation } from "react-i18next";
import { AssessmentType } from "../../../api/main/models/codeOnly/AssessmentType";

export interface AverageScoreRiskDonutChartProps {
    data: Array<ChartDataSeries>,
    assessmentType: AssessmentType,

    width?: string | number | undefined,
    height?: string | number | undefined,
}

/**
 * Chart that shows average driver metric risks as a donut chart.
 */
export const AverageDriverMetricRiskDonutChart = (props: AverageScoreRiskDonutChartProps) => {
    const {
        data,
        width,
        height = 500,
    } = props;

    const { t } = useTranslation();

    // Convert data into format needed for this graph.
    const { series, options: seriesOptions } = useMemo(() => chartDataConverters.toDonut(data), [data]);

    // Compile all the options we want to use.
    const options = useMemo(() => (
        {
            chart: {
                toolbar: {
                    show: false,
                },
            },

            title: {
                text: t('averageScoreRiskDonutChart.title', 'Risk summary'),
                align: 'center',
            },

            dataLabels: {
                enabled: true,
                formatter: (val, options) => {
                    // Show value instead of percentage.
                    return `${options.w.config.series[options.seriesIndex]}`;
                },
            },

            legend: {
                position: 'bottom',
            },

            plotOptions: {
                pie: {
                    donut: {
                        labels: {
                            show: true,
                            total: {
                                show: true,
                            }
                        }
                    }
                }
            },

            colors: [
                '#f86c6b',
                '#ffc107',
                '#4dbd74',
                '#aeaeae'
            ],

            ...seriesOptions,
        } as ApexOptions
    ), [seriesOptions, t]);

    // We need to force the refresh of the chart sometimes by updating its key as its too optomistic with its internal caching and
    // so doesn't end up changes top options very well at all if we dont.
    const key = useMemo(() => JSON.stringify(options), [options]);

    return (
        <Card body tag="div">
            <Chart key={key} options={options} series={series} type="donut" width={width} height={height} />
        </Card>
    );
};