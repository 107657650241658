import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonGroup, FormGroup, Label, Input, FormText } from "reactstrap";
import { Question } from "../../../../api/main/models/Question";
import { ModelArrayChanges } from "../../../../shared/useChanges";
import { useDisplayOrder } from "../../../shared/useDisplayOrder/useDisplayOrder";
import { AssessmentItem, assessmentItemDefaultValues } from "../../../../api/main/models/AssessmentItem";
import { AssessmentItemComponent } from "./AssessmentItemComponent";
import { useToggleState } from "use-toggle-state";
import { SelectQuestionModal } from "../../../questions/selectQuestionModal/SelectQuestionModal";
import { AssessmentItemQuestion, assessmentItemQuestionDefaultValues } from "../../../../api/main/models/AssessmentItemQuestion";
import { Guid } from "guid-string";
import { Assessment } from "../../../../api/main/models/Assessment";
import { ValidationErrors } from "pojo-validator";
import { isNullOrUndefined } from "util";
import { ConditionalFragment } from "react-conditionalfragment";
import { AssessmentType } from "../../../../api/main/models/codeOnly/AssessmentType";
import { QuestionTypeCategory } from "../../../../api/main/models/codeOnly/QuestionType";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SelectQuestionnaireModal } from "../../../questions/questionnaires/SelectQuestionnaireModal/SelectQuestionnaireModal";
import { SelectLearningModal } from "../../../questions/learning/SelectLearningModal/SelectLearningModal";
import { SelectInformationModal } from "../../../questions/information/SelectInformationModal/SelectInformationModal";
import { AssessmentItemDriverMetricQuantity } from "../../../../api/main/models/AssessmentItemDriverMetricQuantity";
import { DriverMetric } from "../../../../api/main/models/DriverMetric";
import { HtmlEditor } from '../../../../shared/htmlEditor';

export interface AssessmentItemsTabProps {
    model: Assessment | undefined,
    change: (changes: Partial<Assessment>) => void,

    assessmentItemsManager: ModelArrayChanges<AssessmentItem, string>,
    assessmentItemQuestionsManager: ModelArrayChanges<AssessmentItemQuestion, string>,
    assessmentItemDriverMetricQuantitiesManager: ModelArrayChanges<AssessmentItemDriverMetricQuantity, string>,

    allQuestions: Array<Question>,

    validateAssessmentItem: (model: AssessmentItem) => boolean,
    assessmentItemValidationErrors: (id: string) => ValidationErrors,

    validateAssessmentItemDriverMetricQuantity: (model: AssessmentItemDriverMetricQuantity) => boolean,
    assessmentItemDriverMetricQuantityErrors: (id: string) => ValidationErrors,

    driverMetrics: Array<DriverMetric>,
}

/**
 * Tab for a maintaining a sequence of questions.
 * @param props
 */
export const AssessmentItemsTab = (props: AssessmentItemsTabProps) => {
    const {
        model,
        change,

        assessmentItemsManager,
        assessmentItemQuestionsManager,

        allQuestions,

        validateAssessmentItem,
        assessmentItemDriverMetricQuantitiesManager,
        assessmentItemValidationErrors,

        validateAssessmentItemDriverMetricQuantity,
        assessmentItemDriverMetricQuantityErrors,

        driverMetrics,
    } = props;

    const { t } = useTranslation();

    // Order the items so they show in and can be managed by displayOrder.
    const [orderedItems, {
        canMoveUp: canMoveAnswerUp,
        moveUp: moveAnswerUp,
        canMoveDown: canMoveAnswerDown,
        moveDown: moveAnswerDown,
    }] = useDisplayOrder(assessmentItemsManager);

    // Get a list of all unique discrimnation groups from the answers
    const counterbalanceGroups = useMemo(() => {
        let ret: Array<string> = [];
        for (const answer of orderedItems) {
            const existing = ret.find(it => it === answer.counterbalanceGroup);
            if (!isNullOrUndefined(existing)) {
                continue;
            }

            ret.push(answer.counterbalanceGroup);
        }

        // NOTE we don't sort this alphabetically as we want them to appear in the same sequence as their first use.
        //ret.sort();

        return ret;
    }, [orderedItems]);
    
    // Showing of the question modal.
    const [questionModalIsOpen, toggleQuestionModal] = useToggleState();
    const openQuestionModal = useCallback((questionTypeCategory?: QuestionTypeCategory) => {
        toggleQuestionModal();
    }, [toggleQuestionModal]);

    const [questionnaireModalIsOpen, toggleQuestionnaireModal] = useToggleState();
    const openQuestionnaireModal = useCallback((questionTypeCategory?: QuestionTypeCategory) => {
        toggleQuestionnaireModal();
    }, [toggleQuestionnaireModal]);

    const [learningModalIsOpen, toggleLearningModal] = useToggleState();
    const openLearningModal = useCallback((questionTypeCategory?: QuestionTypeCategory) => {
        toggleLearningModal();
    }, [toggleLearningModal]);

    const [informationModalIsOpen, toggleInformationModal] = useToggleState();
    const openInformationModal = useCallback((questionTypeCategory?: QuestionTypeCategory) => {
        toggleInformationModal();
    }, [toggleInformationModal]);

    // Adding of questions selected in the modal
    const onQuestionModalClosed = useCallback((event: { selectedIds: Array<string> }) => {
        if (!event.selectedIds.length) {
            return;
        }

        const newAssessmentItemId = Guid.newGuid();
        assessmentItemsManager.addFor({
            ...assessmentItemDefaultValues(),

            id: newAssessmentItemId,
            assessmentId: model?.id,
        });

        for (const selectedId of event.selectedIds) {
            assessmentItemQuestionsManager.addFor({
                ...assessmentItemQuestionDefaultValues(),

                assessmentId: model?.id,
                assessmentItemId: newAssessmentItemId,
                questionId: selectedId,
            });
        }
    }, [assessmentItemsManager, assessmentItemQuestionsManager, model?.id]);

    const onQuestionnaireModalClosed = useCallback((event: { selectedIds: Array<string> }) => {
        if (!event.selectedIds.length) {
            return;
        }

        const newAssessmentItemId = Guid.newGuid();
        assessmentItemsManager.addFor({
            ...assessmentItemDefaultValues(),

            id: newAssessmentItemId,
            assessmentId: model?.id,
        });

        for (const selectedId of event.selectedIds) {
            assessmentItemQuestionsManager.addFor({
                ...assessmentItemQuestionDefaultValues(),

                assessmentId: model?.id,
                assessmentItemId: newAssessmentItemId,
                questionId: selectedId,
            });
        }
    }, [assessmentItemsManager, assessmentItemQuestionsManager, model?.id]);

    const onLearningModalClosed = useCallback((event: { selectedIds: Array<string> }) => {
        if (!event.selectedIds.length) {
            return;
        }

        const newAssessmentItemId = Guid.newGuid();
        assessmentItemsManager.addFor({
            ...assessmentItemDefaultValues(),

            id: newAssessmentItemId,
            assessmentId: model?.id,
        });

        for (const selectedId of event.selectedIds) {
            assessmentItemQuestionsManager.addFor({
                ...assessmentItemQuestionDefaultValues(),

                assessmentId: model?.id,
                assessmentItemId: newAssessmentItemId,
                questionId: selectedId,
            });
        }
    }, [assessmentItemsManager, assessmentItemQuestionsManager, model?.id]);

    const onInformationModalClosed = useCallback((event: { selectedIds: Array<string> }) => {
        if (!event.selectedIds.length) {
            return;
        }

        const newAssessmentItemId = Guid.newGuid();
        assessmentItemsManager.addFor({
            ...assessmentItemDefaultValues(),

            id: newAssessmentItemId,
            assessmentId: model?.id,
        });

        for (const selectedId of event.selectedIds) {
            assessmentItemQuestionsManager.addFor({
                ...assessmentItemQuestionDefaultValues(),

                assessmentId: model?.id,
                assessmentItemId: newAssessmentItemId,
                questionId: selectedId,
            });
        }
    }, [assessmentItemsManager, assessmentItemQuestionsManager, model?.id]);

    const isLearning = model?.assessmentType === AssessmentType.TrainingModule;

    const safetyMetricText = useMemo(() => {
        var uniqueUsedSafetyMetrics: Array<string> = [];

        // Go through each assessment item
        orderedItems.forEach(assessmentItem => {

            // Go through each question for assessment item
            var currentAssessmentItemQuestions = assessmentItemQuestionsManager.model.filter(it => it.assessmentItemId === assessmentItem.id);
            currentAssessmentItemQuestions.forEach(item => {
                var currentQuestion = allQuestions.find(question => question.id === item.questionId);

                // Get the safety metric assigned to the question and add it to the temp array if we dont have it already
                var safetyMetric = driverMetrics.find(driverMetric => driverMetric.id === currentQuestion?.driverMetricId) ?? null;
                if (!!safetyMetric) {
                    if (!uniqueUsedSafetyMetrics.includes(safetyMetric.name)) {
                        uniqueUsedSafetyMetrics.push(safetyMetric.name);
                    }
                }
            })
        })

        // If we have a list of driver metrics to use, process them as text to show the user
        if (uniqueUsedSafetyMetrics.length > 0) {

            if (uniqueUsedSafetyMetrics.length === 1) {
                // If we only have one driver metric affected, then show only that one. also use "score" instead of "scores"
                return "Based on the selected questions this assessment may affect a drivers " + uniqueUsedSafetyMetrics[0] + " score.";

            } else {
                // If we have more than one driver metric affected, then show all of them. use "scores" instead of "score"

                // Create a string of all the safety metrics affected in a easy to read format
                var easierSafetyMetricList = " ";
                uniqueUsedSafetyMetrics.forEach(item => {
                    if (uniqueUsedSafetyMetrics.indexOf(item) === uniqueUsedSafetyMetrics.length - 2) {
                        // If we are on the second to last item, add "and" instead of a comma
                        easierSafetyMetricList = easierSafetyMetricList + item + " and ";
                    } else if (uniqueUsedSafetyMetrics.indexOf(item) === uniqueUsedSafetyMetrics.length - 1) {
                        // If we are on the last item, add nothing
                        easierSafetyMetricList = easierSafetyMetricList + item;
                    } else {
                        // add a comma seperator for each item
                        easierSafetyMetricList = easierSafetyMetricList + item + ", ";
                    }

                })

                // Return text explaining which safety metrics may be affected by the assessment.
                return "Based on the selected questions this assessment may affect a drivers " + easierSafetyMetricList + " scores.";
            }
        } else {
            // If we have no safety metrics affected, then return text explaining that.
            return "Based on the selected questions this assessment will not effect a drivers overall risk score.";
        }
    }, [orderedItems, assessmentItemQuestionsManager, allQuestions, driverMetrics])

    return (
        <>
            <FormGroup>
                <Label htmlFor="questions">
                    {
                        isLearning ? t('assessmentItemsTab.learningAndQuestions', 'Learning, questions, and activities')
                            : t('assessmentItemsTab.questions', 'Questions and activities')
                    }
                </Label>

                <FormText style={{ fontWeight: 'bolder', marginBottom: '5px'}} >{safetyMetricText}</FormText>

                <div>
                    {
                        orderedItems.map(item => {
                            return (
                                <AssessmentItemComponent key={item.id}
                                    model={item}
                                    change={changes => assessmentItemsManager.changeFor(item.id, changes)}
                                    remove={() => assessmentItemsManager.removeFor(item.id)}

                                    moveUp={() => moveAnswerUp(item.id)} canMoveUp={canMoveAnswerUp(item.id)}
                                    moveDown={() => moveAnswerDown(item.id)} canMoveDown={canMoveAnswerDown(item.id)}

                                    assessmentItemQuestions={assessmentItemQuestionsManager.model.filter(it => it.assessmentItemId === item.id)}
                                    allQuestions={allQuestions}
                                    assessmentItemQuestionsManager={assessmentItemQuestionsManager}
                                    assessmentItemDriverMetricQuantitiesManager={assessmentItemDriverMetricQuantitiesManager}
                                    counterbalanceGroups={counterbalanceGroups}

                                    validateAssessmentItemDriverMetricQuantity={validateAssessmentItemDriverMetricQuantity}
                                    assessmentItemDriverMetricQuantityErrors={assessmentItemDriverMetricQuantityErrors}

                                    validate={() => validateAssessmentItem(item)}
                                    validationErrors={assessmentItemValidationErrors(item.id)}

                                    isOpenInitially={!!assessmentItemsManager.added.find(it => it.id === item.id)}

                                    driverMetrics={driverMetrics}
                                />
                            );
                        })
                    }
                </div>
                    
                <ButtonGroup>
                    <ConditionalFragment showIf={isLearning}>
                        <Button color="primary" outline onClick={() => openLearningModal(QuestionTypeCategory.Learning)}>
                            <FontAwesomeIcon icon="graduation-cap" className="nav-icon" />
                            {t('assessmentItemsTab.addLearning', ' Add learning')}
                        </Button>
                    </ConditionalFragment>
                    <Button color="primary" outline onClick={() => openQuestionModal(QuestionTypeCategory.Question)}>
                        <FontAwesomeIcon icon="car-crash" className="nav-icon" />
                        {t('assessmentItemsTab.addQuestions', ' Add questions')}
                    </Button>
                    <Button color="primary" outline onClick={() => openInformationModal(QuestionTypeCategory.Informtion)}>
                        <FontAwesomeIcon icon="book" className="nav-icon" />
                        {t('assessmentItemsTab.addInformation', ' Add information page')}
                    </Button>
                    <Button color="primary" outline onClick={() => openQuestionnaireModal(QuestionTypeCategory.Questionnaire)}>
                        <FontAwesomeIcon icon="meh" className="nav-icon" />
                        {t('assessmentItemsTab.addQuestionnaires', ' Add questionnaires')}
                    </Button>
                </ButtonGroup>
            </FormGroup>

            <FormGroup>
                <Label htmlFor="maxSuspiciousAnswers">{t('assessmentItemComponent.maxSuspiciousAnswers.label', 'Maximum score for potentially suspicious answers')}</Label>

                <Input name="maxSuspiciousAnswers" type="number" value={model?.maxSuspiciousAnswers ?? ''} onChange={e => change({ maxSuspiciousAnswers: e.currentTarget.valueAsNumber })} />
                <FormText>
                    {t('assessmentItemsTab.maxSuspiciousAnswers.rule', 'Using 0 will be treated as not having a limit.')}
                </FormText>
            </FormGroup>

            <ConditionalFragment showIf={!!model?.maxSuspiciousAnswers && model.maxSuspiciousAnswers > 0}>
                <FormGroup>
                    <Label htmlFor="text">{t('assessmentItemsTab.maxSuspiciousAnswers.label', 'Suspicious answers text')}</Label>

                    <HtmlEditor value={model?.suspiciousAnswerText ?? ''} onChange={html => change({ suspiciousAnswerText: html })} />
                    <FormText>
                        {
                            t('assessmentItemsTab.maxSuspiciousAnswers.formText', 'This will show when the user completes an assessment and has exceeded the maximum potentially suspicious answers.')
                        }
                    </FormText>
                </FormGroup>
            </ConditionalFragment>

            <ConditionalFragment showIf={questionModalIsOpen}>
                <SelectQuestionModal
                    isOpen={questionModalIsOpen}
                    toggle={toggleQuestionModal}
                    onClose={onQuestionModalClosed}
                    questions={allQuestions}
                />
            </ConditionalFragment>
            
            <ConditionalFragment showIf={questionnaireModalIsOpen}>
            <SelectQuestionnaireModal
                isOpen={questionnaireModalIsOpen}
                toggle={toggleQuestionnaireModal}
                onClose={onQuestionnaireModalClosed}
                questionnaires={allQuestions}
                />
            </ConditionalFragment>

            <ConditionalFragment showIf={learningModalIsOpen}>
            <SelectLearningModal
                isOpen={learningModalIsOpen}
                toggle={toggleLearningModal}
                onClose={onLearningModalClosed}
                Learning={allQuestions}
                />
            </ConditionalFragment>

            <ConditionalFragment showIf={informationModalIsOpen}>
                <SelectInformationModal
                    isOpen={informationModalIsOpen}
                    toggle={toggleInformationModal}
                    onClose={onInformationModalClosed}
                    Learning={allQuestions}
                />
            </ConditionalFragment>

        </>
        );
};