import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Button, Card, CardBody, CardHeader, Col, ListGroup, ListGroupItem, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { Assessment } from "../../../api/main/models/Assessment";
import { AssessmentFeedback } from "../../../api/main/models/AssessmentFeedback";
import { AssessmentSession } from "../../../api/main/models/AssessmentSession";
import { BlobReference } from "../../../api/main/models/BlobReference";
import { AssessmentType } from "../../../api/main/models/codeOnly/AssessmentType";
import { FeedbackStyle } from "../../../api/main/models/codeOnly/FeedbackStyle";
import { getQuestionTypeCategory, QuestionType, QuestionTypeCategory, questionTypeDisplayName } from "../../../api/main/models/codeOnly/QuestionType";
import { DriverMetric } from "../../../api/main/models/DriverMetric";
import { Question } from "../../../api/main/models/Question";
import { QuestionFeedback } from "../../../api/main/models/QuestionFeedback";
import { QuestionResponseEvent } from "../../../api/main/models/QuestionResponseEvent";
import { Subscription } from "../../../api/main/models/Subscription";
import { RiskRules } from "../../../services/OverallRiskService";
import { HtmlDisplay } from "../../../shared/htmlEditor";
import { subsitutePlaceholders } from "../../../utilities/subsitutePlaceholders";
import { ShowQuestionFeedback } from "./feedback/ShowQuestionFeedback";
import { LaunchAssessmentPage } from "./LaunchAssessmentBase";
import { ScoreProgressBar } from "./progress/ScoreProgressBar";
import { AssessmentScore } from "./utilities/calculateAssessmentScore";
import { QuestionScore } from "./utilities/calculateQuestionScore";
import { questionScoreFromNumber } from "./utilities/questionScoreFromNumber";
import { launchAssessmentSessionViewModelQuery_viewModel_questionSequenceQuestions } from "../../../api/main/generated/launchAssessmentSessionViewModelQuery"

export interface FinishPageProps {
    model: AssessmentSession | undefined | null,
    assessment: Assessment | undefined | null,
    feedbacks: Array<AssessmentFeedback>,
    blobReferences: Array<BlobReference>,
    activeAssessmentType: AssessmentType,
    totalScore: AssessmentScore,
    calculateQuestionScore: (responses: Array<QuestionResponseEvent>, riskRules?: RiskRules) => QuestionScore,

    exceedsSuspiciousLimit: boolean,

    // Event raised when a page has been completed.
    onPageComplete: () => void,

    questions: Array<Question>,
    questionFeedbacks: Array<QuestionFeedback>,
    questionResponseEvents: Array<QuestionResponseEvent>,

    pages: Array<LaunchAssessmentPage>,
    driverMetrics: Array<DriverMetric>,
    subscription: Subscription | undefined | null,
    allCurrentQuestionSequence: Array<launchAssessmentSessionViewModelQuery_viewModel_questionSequenceQuestions> | undefined,

    finishPageFeedbackStyle: FeedbackStyle | undefined | null,
}


/**
 * Show a question for an executing assessments.
 * @param props
 */
export const FinishPage = (props: FinishPageProps) => {
    const {
        assessment,
        feedbacks,
        blobReferences,
        onPageComplete,
        totalScore,
        calculateQuestionScore,

        exceedsSuspiciousLimit,

        questions,
        questionFeedbacks,
        questionResponseEvents,

        pages,
        driverMetrics,
        subscription,
        allCurrentQuestionSequence,

        finishPageFeedbackStyle,
    } = props;

    const { t } = useTranslation();

    // Work out which feedback to show.
    const { activeFeedback } = useMemo(() => {
        // Find the best feedback for the total score.
        const activeFeedback = feedbacks.find(item => item.minTotalScore <= totalScore.score && totalScore.score <= item.maxTotalScore);
        return {
            activeFeedback,
        };
    }, [totalScore, feedbacks]);

    const videoBlob = useMemo(() => blobReferences.find(it => it.id === activeFeedback?.videoBlobReferenceId), [blobReferences, activeFeedback]);
    const imageBlob = useMemo(() => blobReferences.find(it => it.id === activeFeedback?.imageBlobReferenceId), [blobReferences, activeFeedback]);

    // Some calls to apis (such as saving clicks) will cause us to generate a new secure video URL.  To make sure this doesn't reset out
    // video we cache the poster and video urls as we original receive them into our state and always use them.
    const [imageUrl] = useState<string>(imageBlob?.url ?? '');
    const [videoUrl] = useState<string>(videoBlob?.url ?? '');

    // Replace placeholders in the feedback HTML.
    const feedbackHtml = useMemo(() => {
        let ret = activeFeedback?.feedbackText ?? '';

        ret = subsitutePlaceholders(
            ret,
            {
                ...totalScore,
            }
        );

        return ret;
    }, [activeFeedback, totalScore]);

    // We will show individual quesiton feedback in a popup modal.
    const [showingFeedbackForQuestion, setShowingFeedbackForQuestion] = useState<Question | undefined>(undefined);
    const questionFeedbackModalIsOpen = !!showingFeedbackForQuestion;
    const closeQuestionFeedbackModal = useCallback(() => setShowingFeedbackForQuestion(undefined), [setShowingFeedbackForQuestion]);
    const openQuestionFeedbackModal = useCallback((question: Question) => setShowingFeedbackForQuestion(question), [setShowingFeedbackForQuestion]);

    // Find all the used driver metrics, and page questions into them.
    // We want these to appear in the order of the first page within each group.
    const driverMetricPageGroups = useMemo(() => {
        let ret: Array<{
            driverMetric: DriverMetric | null,
            pages: Array<LaunchAssessmentPage>,
            groupScore: QuestionScore,
        }> = [];

        for (const page of pages) {
            // We only show results for question pages.
            if (page.pageType !== 'question') {
                continue;
            }

            // Get the question for the page.
            const question = questions.find(it => it.id === page.questionId);
            if (!question) {
                continue;
            }

            const questionTypeCategory = getQuestionTypeCategory(question.questionType as QuestionType);
            if (questionTypeCategory !== QuestionTypeCategory.Question && questionTypeCategory !== QuestionTypeCategory.Questionnaire) {
                continue;
            }

            //if we are checking a questionnaire we need to use the questionnaire questions
            if (question.questionType === "Questionnaire") {

                //filter the sequence questions for only questions from this questionnaire
                const questionnaireQuestions = allCurrentQuestionSequence?.filter(item => item.parentQuestionId === question.id && !item.archived);

                questionnaireQuestions?.forEach(item => {

                    if (item.childQuestion.questionType === "QuestionnaireInput") {
                        return;
                    }

                    const driverMetric = driverMetrics.find(it => it.id === item.childQuestion.driverMetricId) ?? null;

                        // Lookup if we already have an entry for this driver metric, if not create one.
                        let entry = ret.find(it => it.driverMetric === driverMetric);
                        if (!entry) {
                            const metricScore = totalScore.driverMetricBreakdown.find(it => it.driverMetricId === (driverMetric?.id ?? null))?.score
                                ?? questionScoreFromNumber(0, false, subscription);

                            entry = {
                                driverMetric: driverMetric,
                                pages: [],
                                groupScore: metricScore,
                            };
                            ret.push(entry);
                        }

                        // Add this page to the entry.
                        entry.pages.push(page);
                })
            }
            else {

                // Find the driver metric (which may be null).
                const driverMetric = driverMetrics.find(it => it.id === question.driverMetricId) ?? null;

                // Lookup if we already have an entry for this driver metric, if not create one.
                let entry = ret.find(it => it.driverMetric === driverMetric);
                if (!entry) {
                    const metricScore = totalScore.driverMetricBreakdown.find(item => item.driverMetricId === (driverMetric?.id ?? null))?.score
                        ?? questionScoreFromNumber(0, false, subscription);

                    entry = {
                        driverMetric: driverMetric,
                        pages: [],
                        groupScore: metricScore,
                    };
                    ret.push(entry);
                }

                // Add this page to the entry.
                entry.pages.push(page);
            }
        }

        return ret;
    }, [questions, driverMetrics, subscription, totalScore, pages, allCurrentQuestionSequence]);

    // Reading of feedback is optional so we are complete as soon as we're shown.
    useEffect(() => {
        onPageComplete();
    }, [onPageComplete]);

    // Show the feedback.
    return (
        <div>
            {/* Show video feedback if we have it. */}
            <ConditionalFragment showIf={!!videoUrl}>
                <div className="embed-responsive embed-responsive-16by9">
                    <video
                        className="embed-responsive-item"
                        src={videoUrl}
                        poster={imageUrl}
                        playsInline={true}
                        controls={true}
                    >
                    </video>
                </div>
            </ConditionalFragment>

            {/* Show image feedback if we have it (and don't have a video) */}
            <ConditionalFragment showIf={!!imageUrl && !videoUrl}>
                <img className="img-fluid" src={imageUrl} alt="Feedback" />
            </ConditionalFragment>

            <div className="mt-4">
                <HtmlDisplay html={feedbackHtml} />
            </div>

            {/* If the user has answered too many answers suspiciously, show the text from the assessment */}
            <ConditionalFragment showIf={exceedsSuspiciousLimit}>
                    <HtmlDisplay html={assessment?.suspiciousAnswerText ?? ''}/>
            </ConditionalFragment>

            {/* Let the user review feedback for each question. */}
            <ConditionalFragment showIf={(finishPageFeedbackStyle === FeedbackStyle.SummaryFeedback || finishPageFeedbackStyle === FeedbackStyle.EachQuestionFeedback)}>
                <h2>
                    {t('finishPage.questionScores.heading', 'Here\'s what you scored in each area')}
                </h2>
                <div>
                    {
                        driverMetricPageGroups.map(group => (
                            <Card key={group.driverMetric?.id ?? ''}>
                                <CardHeader>
                                    <Row>
                                        <Col>
                                            <h5>
                                                {group.driverMetric?.name ?? t('finishPage.groups.generalQuestions', 'General and practice questions')}
                                            </h5>
                                        </Col>
                                        {/* Show a subtotal, except on the genreal questions group. */}
                                        <ConditionalFragment showIf={!!group.driverMetric}>
                                            <Col xs="auto">
                                                <ScoreProgressBar value={group.groupScore} />
                                            </Col>
                                        </ConditionalFragment>
                                    </Row>
                                </CardHeader>
                                <CardBody tag="div">
                                    <ListGroup flush>
                                        {
                                            pages.map((page, index) => {
                                                // If page is not part of this group, skip it.
                                                // NOTE we do it this way rather than looping over group.pages because we want to
                                                // maintain consistant screen numbersa across the groups and this combined with index is an easy way to do that.
                                                if (!group.pages.find(it => it === page)) {
                                                    return null;
                                                }

                                                // Grab the related question.
                                                const question = questions.find(it => it.id === page.questionId);
                                                if (!question) {
                                                    return null;
                                                }

                                                // If the question type isn't scored, then skip it.
                                                const questionTypeCategory = getQuestionTypeCategory(question.questionType as QuestionType);
                                                if (questionTypeCategory !== QuestionTypeCategory.Question
                                                    && questionTypeCategory !== QuestionTypeCategory.Questionnaire
                                                ) {
                                                    return null;
                                                }

                                                // Generate all the result progress bars for this quesiton (as some have more than one subquestion).
                                                let subQuestionProgressBars: Array<ReactNode> = [];

                                                if (questionTypeCategory === QuestionTypeCategory.Questionnaire) {
                                                    // Subquestions in a questionnaire are questionnaire questions so loop through each one.
                                                    const myQuestionnaireQuestions = allCurrentQuestionSequence?.filter(item => item.parentQuestionId === question.id);
                                                    let questionnaireQuestionNumber = 1;
                                                    for (const questionnaireQuestion of myQuestionnaireQuestions!) {
                                                        // If this questionnaire question isn't for the current drive metric, skip it.
                                                        if (questionnaireQuestion.childQuestion.driverMetricId !== group.driverMetric?.id) {
                                                            ++questionnaireQuestionNumber;
                                                            continue;
                                                        }

                                                        // If we get here then questionnaire question is for this driver metric, so lets make a progress bar.
                                                        //

                                                        // Get the score for this questionnaire question.
                                                        const score = totalScore.questionBreakdown.find(item => item.questionId === questionnaireQuestion.childQuestionId)?.score
                                                            ?? questionScoreFromNumber(0, false, subscription);

                                                        // Create a bar.
                                                        subQuestionProgressBars.push(
                                                            <Col key={questionnaireQuestionNumber} xs="auto">
                                                                <ConditionalFragment showIf={question.numberOfSubQuestions > 1}>
                                                                    <div className="text-muted text-center" style={{ fontSize: '0.7rem' }}>
                                                                        {t('finishPage.questionProgressBars.questionHeading', 'Question {{value}}', { value: questionnaireQuestionNumber })}
                                                                    </div>
                                                                </ConditionalFragment>
                                                                <ScoreProgressBar value={score} size="sm" />
                                                            </Col>
                                                        );

                                                        ++questionnaireQuestionNumber;
                                                    }
                                                } else {
                                                    // Subquestions for questions are controlled by quesiton.numberOfSubQuestions so loop around for each of those.
                                                    for (let subQuestionNumber = 1; subQuestionNumber <= question.numberOfSubQuestions; ++subQuestionNumber) {
                                                        const score = totalScore.questionBreakdown.find(item => item.questionId === question.id && item.subQuestionNumber === subQuestionNumber)?.score
                                                            ?? questionScoreFromNumber(0, false, subscription);

                                                        subQuestionProgressBars.push(
                                                            <Col key={subQuestionNumber} xs="auto">
                                                                <ConditionalFragment showIf={question.numberOfSubQuestions > 1}>
                                                                    <div className="text-muted text-center" style={{ fontSize: '0.7rem' }}>
                                                                        {t('finishPage.questionProgressBars.partHeading', 'Part {{value}}', { value: subQuestionNumber })}
                                                                    </div>
                                                                </ConditionalFragment>
                                                                <ScoreProgressBar value={score} size="sm" />
                                                            </Col>
                                                        );
                                                    }
                                                }

                                                // Return the completed UI.
                                                return (
                                                    <ListGroupItem key={question.id}>
                                                        <Row>
                                                            <Col xs="auto">
                                                                {t('finishPage.screenNumber', 'Screen {{screenNumber}}', { screenNumber: index + 1 })}
                                                            </Col>
                                                            <Col>
                                                                <p>
                                                                    {questionTypeDisplayName(question.questionType as QuestionType, t)}

                                                                    { /*If we have an image or video related to the question, show the question name (aka video/image name)*/ }
                                                                    <ConditionalFragment showIf={!!question.videoBlobReferenceId}>
                                                                        : {question.name}
                                                                    </ConditionalFragment>
                                                                </p>
                                                                <Button color="primary" outline onClick={() => openQuestionFeedbackModal(question)}>
                                                                    {t('finishPage.questionScores.viewFeedback', 'View feedback for this question')}
                                                                </Button>
                                                            </Col>
                                                            {subQuestionProgressBars}
                                                        </Row>
                                                    </ListGroupItem>
                                                );
                                            })
                                        }
                                    </ListGroup>
                                </CardBody>
                            </Card>
                            ))
                    }
                </div>
            </ConditionalFragment>

            <ConditionalFragment showIf={questionFeedbackModalIsOpen}>
                <Modal isOpen={questionFeedbackModalIsOpen} toggle={() => closeQuestionFeedbackModal()} size="xl">
                    <ModalHeader toggle={() => closeQuestionFeedbackModal()}>
                        {
                            t('finishPage.questionFeedbackModal.heading', 'Feedback for the question')
                        }
                    </ModalHeader>
                    <ModalBody>
                        <ShowQuestionFeedback
                            model={showingFeedbackForQuestion!}
                            feedbacks={questionFeedbacks.filter(it => it.questionId === showingFeedbackForQuestion?.id)}
                            blobReferences={blobReferences}
                            questionScore={calculateQuestionScore(questionResponseEvents.filter(item => item.questionId === showingFeedbackForQuestion?.id && item.archived === false)!)}
                            onPageComplete={() => {/* Do nothing */}}
                            />
                    </ModalBody>
                </Modal>
            </ConditionalFragment>
        </div>
    );
};