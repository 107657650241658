import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Button } from "reactstrap";
import { useDashboardRequiresBackButton } from "../utilities/useDashboardRequiresBackButton";
import "./dashboardBackButton.scss";

/**
 * Back button that goes back in the history, and is formatted for display on the dashboard.
 * 
 * The button will automatically hide itself if it is not required.
 */
export const DashboardBackButton = () => {
    const { t } = useTranslation();
    const history = useHistory();

    const needBackButton = useDashboardRequiresBackButton();

    // If we don't need a back button, don't show one.
    if (!needBackButton) {
        return null;
    }

    return (
        <Button outline className="dashboard-back-button" onClick={() => history.goBack()}>
            <FontAwesomeIcon icon="caret-left" />
            <span className="sr-only">
                {t('common.back', 'Back')}
            </span>
        </Button>
        );
};