import * as React from 'react';
import { AssessmentItemQuestionCreateInput, AssessmentItemQuestionUpdateInput } from '../generated/globalTypes';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useCreateAssessmentItemQuestionCallback } from './useCreateAssessmentItemQuestionCallback';
import { useUpdateAssessmentItemQuestionCallback } from './useUpdateAssessmentItemQuestionCallback';
import { AssessmentItemQuestion } from '../models/AssessmentItemQuestion';

/**
 * Get a callback to save a AssessmentItemQuestion in the store using either a create or update.
 */
export function useSaveAssessmentItemQuestionCallback(options: SaveInStoreOptions<AssessmentItemQuestion, string> = {}) {
    const [_create, createStatus] = useCreateAssessmentItemQuestionCallback();
    const create = React.useCallback((model: Partial<AssessmentItemQuestion>) => _create(model as AssessmentItemQuestionCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateAssessmentItemQuestionCallback();
    const update = React.useCallback((id: string, changes: Partial<AssessmentItemQuestion>) => _update(id, changes as AssessmentItemQuestionUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
