import { NavItem, NavLink } from 'reactstrap';
import { AuthorizeContainer } from '../../shared/authorizeRoute';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLinkTrackActive } from '../shared/NavLinkTrackActive';
import { IdentityRoles } from '../../configure/security/IdentityRoles';

/**
 * Manage navigation menu for the app. 
 */
export const ManageNavigation = () => {
    const { t } = useTranslation();

    return (
        <>
            <AuthorizeContainer requireRole={IdentityRoles.DriverManagement}>
                <NavItem>
                    <NavLink tag={NavLinkTrackActive} to="/manage/drivers">
                        <FontAwesomeIcon icon="bus" className="nav-icon" />
                        <> {t('manageNavigtion.drivers', 'Drivers')}</>
                    </NavLink>
                </NavItem>
            </AuthorizeContainer>
            <AuthorizeContainer requireRole={IdentityRoles.SubscriptionAdministration}>
                <NavItem>
                    <NavLink tag={NavLinkTrackActive} to="/manage/teams">
                        <FontAwesomeIcon icon="users" className="nav-icon" />
                        <> {t('manageNavigtion.teams', 'Teams')}</>
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={NavLinkTrackActive} to="/manage/managers">
                        <FontAwesomeIcon icon="user" className="nav-icon" />
                        <> {t('manageNavigtion.managers', 'Managers')}</>
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={NavLinkTrackActive} to="/manage/sdk">
                        <FontAwesomeIcon icon="code" className="nav-icon" />
                        <> {t('manageNavigtion.api', 'API and SDK')}</>
                    </NavLink>
                </NavItem>
            </AuthorizeContainer>
        </>
    );
};
