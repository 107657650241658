import { Button, Form, FormGroup, Spinner, Input, Row, Col } from 'reactstrap';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContainer } from '../shared/MainContainer';
import { useParams, useHistory } from 'react-router';
import { FormButtons } from '../shared/FormButtons';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { useAsyncCallback } from 'react-use-async-callback';
import { useDeleteQuestionCallback } from '../../api/main/questions/useDeleteQuestionCallback';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Banner } from '../shared/Banner';
import { Background } from '../shared/background/Background';
import { getQuestionTypeCategory, QuestionType, QuestionTypeCategory } from '../../api/main/models/codeOnly/QuestionType';
import { useMemo } from 'react';
import { useDeleteQuestionAnswerCallback } from '../../api/main/questionAnswers/useDeleteQuestionAnswerCallback';
import { useDeleteQuestionFeedbackCallback } from '../../api/main/questionFeedbacks/useDeleteQuestionFeedbackCallback';
import { useDeleteQuestionTagLinkCallback } from '../../api/main/questionTagLinks/useDeleteQuestionTagLinkCallback';
import { useDeleteDriverMetricRecommendationCallback } from '../../api/main/driverMetricRecommendations/useDeleteDriverMetricRecommendationCallback';
import { useDeleteDriverMetricAdjustmentCallback } from '../../api/main/driverMetricAdjustments/useDeleteDriverMetricAdjustmentCallback';
import { useDeleteAssessmentItemQuestionCallback } from '../../api/main/assessmentItemQuestions/useDeleteAssessmentItemQuestionCallback';
import { useDeleteQuestionResponseEventCallback } from '../../api/main/questionResponseEvents/useDeleteQuestionResponseEventCallback';
import { useDeleteQuestionSequenceQuestionCallback } from '../../api/main/questionSequenceQuestions/useDeleteQuestionSequenceQuestionCallback';
import { useQuestionLinksViewModel } from '../../api/main/questions/viewModels/useQuestionLinksViewModel';

export interface DeleteQuestionBaseProps {
    defaultQuestionType: QuestionType,
}

/**
 * Delete a question.
 */
export const DeleteQuestionBase = (props: DeleteQuestionBaseProps) => {
    const { defaultQuestionType } = props;

    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();


    const { data: {
        model,
        answers,
        questionnaireQuestionAnswers,
        feedbacks,
        questionTagLinks,
        driverMetricRecommendations,
        driverMetricAdjustments,
        assessmentLinks,
        responseEvents,
        questionSequenceQuestions,
    }, isLoading, errors: loadErrors } = useQuestionLinksViewModel(id);

    const [remove, { errors: removeErrors }] = useDeleteQuestionCallback();

    const [removeQuestionAnswer, { errors: removeQuestionAnswerErrors }] = useDeleteQuestionAnswerCallback();
    const [removeQuestionFeedback, { errors: removeQuestionFeedbackErrors }] = useDeleteQuestionFeedbackCallback();
    const [removeQuestionTagLink, { errors: removeQuestionTagLinkErrors }] = useDeleteQuestionTagLinkCallback();
    const [removeDriverMetricRecommendation, { errors: removeDriverMetricRecommendationErrors }] = useDeleteDriverMetricRecommendationCallback();
    const [removeDriverMetricAdjustment, { errors: removeDriverMetricAdjustmentErrors }] = useDeleteDriverMetricAdjustmentCallback();
    const [removeAssessmentLink, { errors: removeAssessmentLinkErrors }] = useDeleteAssessmentItemQuestionCallback();
    const [removeQuestionResponseEvent, { errors: removeQuestionResponseEventErrors }] = useDeleteQuestionResponseEventCallback();
    const [removeQuestionSequenceQuestion, { errors: removeQuestionSequenceQuestionErrors }] = useDeleteQuestionSequenceQuestionCallback();

    const history = useHistory();

    // We want to customise the interface based on the category of question we are.
    const activeQuestionTypeCategory: QuestionTypeCategory = useMemo(() => getQuestionTypeCategory(model?.questionType as QuestionType || defaultQuestionType), [model, defaultQuestionType]);

    const [onRemoveFormSubmit, { isExecuting: isRemoving, errors: removeFormErrors }] = useAsyncCallback(async () => {
        if (!model) {
            return;
        }

        //Delete everything linked to the question first
        await answers.forEach(item => removeQuestionAnswer(item.id));
        await questionnaireQuestionAnswers.forEach(item => removeQuestionAnswer(item.id));
        await feedbacks.forEach(item => removeQuestionFeedback(item.id));
        await questionTagLinks.forEach(item => removeQuestionTagLink(item.id));
        await driverMetricRecommendations.forEach(item => removeDriverMetricRecommendation(item.id));
        await driverMetricAdjustments.forEach(item => removeDriverMetricAdjustment(item.id));
        await assessmentLinks.forEach(item => removeAssessmentLink(item.id));       
        await responseEvents.forEach(item => removeQuestionResponseEvent(item.id));        
        await questionSequenceQuestions.forEach(item => removeQuestionSequenceQuestion(item.id));
        

        await remove(model.id);

        history.goBack();
    }, [model, history]);

    return (
        <Background>
            <Banner>
                <Row>
                    <Col>
                        <h1>{
                            activeQuestionTypeCategory === QuestionTypeCategory.Learning ? t('deleteQuestion.heading.learning', 'Are you sure you want to delete this learning activity?')
                                : activeQuestionTypeCategory === QuestionTypeCategory.Questionnaire ? t('deleteQuestion.heading.questionnaire', 'Are you sure you want to delete this questionnaire?')
                                    : t('deleteQuestion.heading.default', 'Are you sure you want to delete this question?')
                        }</h1>
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
            </Banner>

            <MainContainer>
                <AlertOnErrors errors={[loadErrors, removeFormErrors, removeErrors, removeQuestionAnswerErrors, removeQuestionFeedbackErrors, removeQuestionTagLinkErrors,
                    removeDriverMetricRecommendationErrors, removeDriverMetricAdjustmentErrors, removeAssessmentLinkErrors, removeQuestionResponseEventErrors, removeQuestionSequenceQuestionErrors]} />

                <Form onSubmit={e => { e.preventDefault(); onRemoveFormSubmit(); }}>
                    <FormGroup>
                        <Input name="name" type="text" readOnly plaintext value={"Name: " + model?.name ?? ''} />
                        <Input name="answer count" type="text" readOnly plaintext value={"Answers: " + answers?.length ?? ''} />
                        <Input name="questionnaire answers count" type="text" readOnly plaintext value={"Questionnaire answers: " + questionnaireQuestionAnswers?.length ?? ''} />
                        <Input name="feedback count" type="text" readOnly plaintext value={"Feedbacks: " + feedbacks?.length ?? ''} />
                        <Input name="question tag links count" type="text" readOnly plaintext value={"Question tag links: " + questionTagLinks?.length ?? ''} />
                        <Input name="driver metric recommendations count" type="text" readOnly plaintext value={"Driver metric recommendations: " + driverMetricRecommendations?.length ?? ''} />
                        <Input name="driver metric adjustments count" type="text" readOnly plaintext value={"Driver metric adjustments: " + driverMetricAdjustments?.length ?? ''} />
                        <Input name="assessment links count" type="text" readOnly plaintext value={"Assessment links: " + assessmentLinks?.length ?? ''} />
                        <Input name="response events count" type="text" readOnly plaintext value={"Response events: " + responseEvents?.length ?? ''} />
                        <Input name="question sequence questions count" type="text" readOnly plaintext value={"Question sequence questions: " + questionSequenceQuestions?.length ?? ''} />
                    </FormGroup>
                    
                    <FormButtons>
                        <ConditionalFragment showIf={!isLoading}>
                            <ButtonAsync color="danger" isExecuting={isRemoving}
                                executingChildren={<><Spinner size="sm" /> {t('common.deleting', 'Deleting...')}</>}>
                                <FontAwesomeIcon icon="trash" />
                                <> {t('common.delete', 'Delete')}</>
                            </ButtonAsync>
                        </ConditionalFragment>
                        <Button type="button" color="primary" outline onClick={e => history.goBack()}>
                            {t('common.cancel', 'Cancel')}
                        </Button>
                    </FormButtons>
                </Form>
            </MainContainer>
        </Background>
    );
};
