import { useTranslation } from "react-i18next";
import { useCurrentUserOrEmulatedSubscriptionId } from "../../../globalState/subscriptions/useCurrentUserOrEmulatedSubscriptionId";
import { ManageNavigation } from "../../manage/ManageNavigation";
import { UsersListBase } from "../UsersListBase";
import { SubscriptionTeamNavigation } from "../../subscriptionTeam/SubscriptionTeamNavigation";
import { useParams } from "react-router";

/**
 * List of managers.
 */
export const ManagersList = () => {
    const { t } = useTranslation();

    const { subscriptionTeamId } = useParams<{ subscriptionTeamId: string | undefined }>();

    const subscriptionId = useCurrentUserOrEmulatedSubscriptionId();

    const baseRoute = subscriptionTeamId ? `/manage/teams/details/${subscriptionTeamId}/managers` : '/manage/managers';

    return (
        <UsersListBase
            title={t('managersList.title', 'Managers')}
            filterByRoleGroups={['Driver manager', 'Overview manager']}
            roleGroupColumnHidden={false}
            baseRoute={baseRoute}
            subscriptionId={subscriptionId}
            subscriptionTeamId={subscriptionTeamId}
            navigationPills={subscriptionTeamId ? <SubscriptionTeamNavigation id={subscriptionTeamId} />
                : <ManageNavigation />
            }
            teamColumnHidden={subscriptionTeamId ? true
                : false
            }
            
        />
        );
};