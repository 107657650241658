import { Dispatch, SetStateAction, useCallback, useMemo, useState } from "react";
import { useAsyncCallback } from "react-use-async-callback";
import { useUploadBlobCallback } from "../../../api/main/blobReferences/useUploadBlobCallback";
import { BlobReference } from "../../../api/main/models/BlobReference";

export type BlobReferenceUpdatedCallback = (blobReference: BlobReference | null) => void;
export type BlobReferenceStateReturnType = [
    BlobReference | null,
    Dispatch<SetStateAction<BlobReference | null>>,
    {
        upload: (files: FileList | null) => Promise<BlobReference | null>,
        isUploading: boolean,
        uploadErrors: any,
        clearUpload: () => void,
    }
];

/**
 * Custom hook to manage a BlobReference in state along with upload options.
 */
export function useBlobReferenceState(onUploaded?: BlobReferenceUpdatedCallback, initialValue?: BlobReference | null)
    : BlobReferenceStateReturnType
{
    // State we store the blob in.
    const [blobReference, setBlobReference] = useState<BlobReference | null>(initialValue ?? null);

    // Uploading of a blob.
    const [uploadBlob] = useUploadBlobCallback();
    const [upload, { isExecuting: isUploading, errors: uploadErrors }] = useAsyncCallback(async (files: FileList | null) => {
        if (!files) {
            return null;
        }

        // Upload the blob.
        const blob = await uploadBlob(files);
        if (!blob) {
            return null;
        }

        // Store the state.
        setBlobReference(blob);

        // Call the callback.
        if (onUploaded) {
            onUploaded(blob);
        }

        return blob;
    }, [uploadBlob, onUploaded, setBlobReference]);

    // Clearing a blob.
    const clearUpload = useCallback(() => {
        // Clear the state.
        setBlobReference(null);

        // Call the callback.
        if (onUploaded) {
            onUploaded(null);
        }
    }, [setBlobReference, onUploaded]);

    // Return everything in a format that is easy for people to grab the bits they want.
    const ret = useMemo((): BlobReferenceStateReturnType => ([
        blobReference,
        setBlobReference,
        {
            upload,
            isUploading,
            uploadErrors,
            clearUpload,
        }
    ]), [blobReference, setBlobReference, upload, isUploading, uploadErrors, clearUpload]);

    return ret;
}