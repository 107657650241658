import gql from "graphql-tag";
import { Guid } from "guid-string";
import { driverMetricAdjustmentFields } from "../generated/driverMetricAdjustmentFields";

export const driverMetricAdjustmentFieldsFragment = gql`
    fragment driverMetricAdjustmentFields on DriverMetricAdjustment {
        id
        targetId
        targetType
        driverMetricId
        minScore
        maxScore
        adjustmentValue
        archived
    }
`;


export type DriverMetricAdjustment = Omit<driverMetricAdjustmentFields, '__typename'>;

// Default values.
export const driverMetricAdjustmentDefaultValues = (): Partial<DriverMetricAdjustment> => ({
    id: Guid.newGuid(),
    targetId: undefined,
    targetType: '',
    minScore: 0,
    maxScore: 100,
    driverMetricId: undefined,
    adjustmentValue: 1,
});