import { TFunction } from "i18next";

/**
 * Assessment types
 */
export enum AssessmentType {
    Assessment =  "Assessment",
    TrainingModule =  "TrainingModule",
}

/**
 * Return translated display name for a feedback style.
 * @param t
 */
export function assessmentTypeDisplayName(type: AssessmentType, t: TFunction): string {
    switch (type) {
        case AssessmentType.Assessment: return t('assessmentTypeDisplayName.Assessment', 'Assessment');
        case AssessmentType.TrainingModule: return t('assessmentTypeDisplayName.TrainingModule', 'Training module');
    }
}
