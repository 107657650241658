import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { FormGroup, Label } from "reactstrap";
import { QuestionType } from "../../../../api/main/models/codeOnly/QuestionType";
import { Question } from "../../../../api/main/models/Question";
import { HtmlEditor } from "../../../../shared/htmlEditor";

export interface LearningTextTabProps {
    model: Question | undefined,
    change: (changes: Partial<Question>) => void,
}

/**
 * Tab for the text part of all Learning types.
 * @param props
 */
export const LearningTextTab = (props: LearningTextTabProps) => {
    const {
        model,
        change,
    } = props;

    const { t } = useTranslation();

    return (
        <>
            <ConditionalFragment showIf={model?.questionType === QuestionType.LearningVideo}>
                <FormGroup>
                    <Label htmlFor="questionText">{t('learningText.preQuestionText', 'Learning text before video')}</Label>

                    <HtmlEditor value={model?.preQuestionText} onChange={html => change({ preQuestionText: html })} />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="questionText">{t('learningText.postQustionText', 'Learning text after video')}</Label>

                    <HtmlEditor value={model?.questionText} onChange={html => change({ questionText: html })} />
                </FormGroup>
            </ConditionalFragment>
            <ConditionalFragment showIf={model?.questionType !== QuestionType.LearningVideo}>
                <FormGroup>
                    <Label htmlFor="questionText">{t('learningText.questionText', 'Learning text')}</Label>

                    <HtmlEditor value={model?.questionText} onChange={html => change({ questionText: html })} />
                </FormGroup>
            </ConditionalFragment>
        </>
        );
};