import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { AssessmentType } from "../../../api/main/models/codeOnly/AssessmentType";
import { ChartData, ChartDataSeries } from "../chartDataUtilities/ChartData";
import { DashboardSummary } from "../utilities/generateDashboardSummary";

/**
 * Data for a chart that shows average scores by groups.
 */
export function useAverageScoreChartData(groups: Array<{ id: string, name: string }>, getDashboardSummary: (id: string, assessmentType: AssessmentType) => DashboardSummary, assessmentType: AssessmentType): Array<ChartDataSeries> {
    const { t } = useTranslation();

    const generateSeries = useCallback((seriesName: string, assessmentType: AssessmentType) => {
        // Generate chart data for each group.
        const data = groups.map(group => {
            const summary = getDashboardSummary(group.id, assessmentType);

            return {
                text: group.name,
                value: summary.averageScore.score,
            } as ChartData;
        });

        return {
            name: seriesName,
            data: data,
        } as ChartDataSeries;
    }, [groups, getDashboardSummary]);

    const ret = useMemo(() => {
        return [
            generateSeries(
                assessmentType === AssessmentType.TrainingModule ? t('useAverageScoreChartData.trainingModules.seriesName', 'Training')
                    : t('useAverageScoreChartData.assessments.seriesName', 'Assessments'),
                assessmentType
            ),
            // We now let the user toggle rather than see both at once as its more consistant with the other graphs being displayed.
            //generateSeries(
            //    t('useAverageScoreChartData.trainingModules.seriesName', 'Training'),
            //    AssessmentType.TrainingModule
            //),
        ];
    }, [generateSeries, t, assessmentType]);

    return ret;
}
