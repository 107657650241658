import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { applyDriverMetricsForAssessmentSessionMutation, applyDriverMetricsForAssessmentSessionMutationVariables } from '../generated/applyDriverMetricsForAssessmentSessionMutation';
import { ApplyDriveMetricForAssessmentSessionInput } from '../generated/globalTypes';
import { userDriverMetricFieldsFragment } from '../models/UserDriverMetric';

/**
 * Get a callback to apply change to UserDriverMetrics in the store for an assessment.
 */
export function useApplyDriverMetricsForAssessmentSessionCallback(): [(model: ApplyDriveMetricForAssessmentSessionInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<applyDriverMetricsForAssessmentSessionMutation, applyDriverMetricsForAssessmentSessionMutationVariables>(
        gql`
            mutation applyDriverMetricsForAssessmentSessionMutation ($model: ApplyDriveMetricForAssessmentSessionInput!) {
                applyDriverMetricsForAssessmentSession(model: $model) {
                    ...userDriverMetricFields
                }
            }

            ${userDriverMetricFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (model: ApplyDriveMetricForAssessmentSessionInput) => {
        const result = await mutationAction({ variables: { model: model } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
