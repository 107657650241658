import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button, ButtonGroup, Col, ListGroupItem, Row,} from "reactstrap";
import { QuestionType, questionTypeDisplayName } from "../../../../api/main/models/codeOnly/QuestionType";
import { Question } from "../../../../api/main/models/Question";
import { QuestionSequenceQuestion } from "../../../../api/main/models/QuestionSequenceQuestion";
import { FormButtons } from "../../../shared/FormButtons";


export interface QuestionSequenceQuestionComponentProps {
    model: QuestionSequenceQuestion,
    change: (changes: Partial<QuestionSequenceQuestion>) => void,
    remove: () => void,
    moveUp: () => void,
    canMoveUp: boolean,
    moveDown: () => void,
    canMoveDown: boolean,


    childQuestion: Question | undefined,
}

/**
 * A question that forms part of the QuestionSequenceQuestion.
 * @param props
 */
export const QuestionSequenceQuestionComponent = (props: QuestionSequenceQuestionComponentProps) => {
    const {
        //model,
        //change,
        remove,
        moveUp,
        canMoveUp,
        moveDown,
        canMoveDown,


        childQuestion,
    } = props;

    const { t } = useTranslation();



    return (

        <ListGroupItem tag="div">
            <Row>
                <Col>
                    <div>

                        
                            <Link to={`/administration/questions/edit/${childQuestion?.id}`} target="_blank">
                            {childQuestion?.name}
                            </Link>



                    </div>
                    <div className="text-muted">
                        {questionTypeDisplayName(childQuestion?.questionType as QuestionType, t)}
                    </div>
                </Col>
                <Col xs={12} sm="">
                    <FormButtons noPadding>
                        <ButtonGroup>
                            <Button color="primary" outline onClick={() => moveUp()} disabled={!canMoveUp}>
                                <FontAwesomeIcon icon="caret-up" />
                                <> </>
                                {t('questionSequenceQuestionComponent.up', 'Up')}
                            </Button>
                            <Button color="primary" outline onClick={() => moveDown()} disabled={!canMoveDown}>
                                <FontAwesomeIcon icon="caret-down" />
                                <> </>
                                {t('questionSequenceQuestionComponent.down', 'Down')}
                            </Button>
                            <Button color="danger" outline onClick={() => remove()}>
                                <FontAwesomeIcon icon="trash-alt" />
                                <> </>
                                {t('questionSequenceQuestionComponent.delete', 'Remove question')}
                            </Button>
                        </ButtonGroup>
                    </FormButtons>
                </Col>
            </Row>
        </ListGroupItem>




    );


};