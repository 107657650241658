import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { generateUniqueDriverPinMutation } from '../generated/generateUniqueDriverPinMutation';
//import { generateUniqueDriverPinMutation } from '../generated/generateUniqueDriverPinMutation';

/**
 * Get a callback to generate a unique driver pin that can be used to log in a user.
 */
export function useGenerateUniqueDriverPinCallback(): [() => Promise<string>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<generateUniqueDriverPinMutation>(
        gql`
            mutation generateUniqueDriverPinMutation {
                generateUniqueDriverPin
            }
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async () => {
        const result = await mutationAction({});
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
        return result.data?.generateUniqueDriverPin ?? '';
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
