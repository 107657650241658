import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { useAsyncCallback } from "react-use-async-callback";
import { Button, Col, FormGroup, Input, Label, Row, Spinner, Alert, ButtonGroup } from "reactstrap";
import { useUploadBlobCallback } from "../../../api/main/blobReferences/useUploadBlobCallback";
import { SubscriptionTeam } from "../../../api/main/models/SubscriptionTeam";
import { useProfileSupportingData } from "../../../api/main/profiles/useProfileSupportingData";
import { useCurrentUserOrEmulatedSubscriptionId } from "../../../globalState/subscriptions/useCurrentUserOrEmulatedSubscriptionId";
import { AlertOnErrors } from "../../../shared/alertOnErrors";
import { useChangesArray } from "../../../shared/useChanges";
import { Background } from "../../shared/background/Background";
import { Banner } from "../../shared/Banner";
import { FileUploadButton } from "../../shared/fileUploadButton/FileUploadButton";
import { FormButtons } from "../../shared/FormButtons";
import { LoadingIndicator } from "../../shared/LoadingIndicator";
import { MainContainer } from "../../shared/MainContainer";
import { useImportUserAssessmentAssignmentsCallback } from "../../../api/main/userAssessmentAssignments/useImportUserAssessmentAssignmentsCallback";
import { importUserAssessmentAssignmentsMutation_importUserAssessmentAssignments } from "../../../api/main/generated/importUserAssessmentAssignmentsMutation";

export type importUsersTabs = 'users' | 'assessmentAssignments' | 'trainingAssignments';

export interface ImportUserAssessmentAssignmentsBaseProps {
}

/**
 * Import users.
 */
export const ImportUserAssessmentAssignmentsBase = (props: ImportUserAssessmentAssignmentsBaseProps) => {

    const { t } = useTranslation();

    const history = useHistory();

    const subscriptionId = useCurrentUserOrEmulatedSubscriptionId();
    const { subscriptionTeamId: subscriptionTeamIdParam, roleGroup: roleGroupParam } = useParams<{ subscriptionTeamId: string | undefined, roleGroup: string | undefined }>();

    // Supporting data (dependant on the current user's subscription).
    const { data: { roleGroups: storeRoleGroups, subscriptionTeams: storeSubscriptionTeams, subscription }, isLoading: isLoadingSupportingData, errors: loadSupportingDataErrors } = useProfileSupportingData({ subscriptionId: subscriptionId ?? undefined });
    const isLoading = isLoadingSupportingData;

    // Simple state tracking of field changes.
    const [subscriptionTeamId, setSubscriptionTeamId] = useState<string | undefined>(subscriptionTeamIdParam);

    // Teams
    const subscriptionTeamsManager = useChangesArray<SubscriptionTeam, string>(storeSubscriptionTeams, item => item.id);
    const orderedSubscriptionTeams = useMemo(() => {
        let ret = [...subscriptionTeamsManager.model];
        ret.sort((a, b) => {
            if (a.name === b.name) {
                return 0;
            } else if (a.name > b.name) {
                return 1;
            } else {
                return -1;
            }
        });
        return ret;
    }, [subscriptionTeamsManager]);

    // Role groups.
    const roleGroups = useMemo(() => {
        return (storeRoleGroups ?? [])
            .filter(item => item.id === 'Driver' || item.id === 'Driver manager' || item.id === 'Overview manager');
    }, [storeRoleGroups]);

    // Uploading blobs.
    const [uploadBlob, { errors: uploadBlobErrors }] = useUploadBlobCallback();
    
    const [importUserAssessmentAssignments, { errors: importUserAssessmentAssignmentsErrors }] = useImportUserAssessmentAssignmentsCallback();

    // Results from an import.
    const [importUserAssessmentAssignmentsResults, setImportUserAssessmentAssignmentsResults] = React.useState<Array<importUserAssessmentAssignmentsMutation_importUserAssessmentAssignments> | undefined>(undefined);

    // Perform the assessment assignment import.
    const [performUserAssessmentAssignmentImport, { isExecuting: isImportingUserAssessmentAssignments, errors: performUserAssessmentAssignmentImportErrors }] = useAsyncCallback(async (files: FileList | null) => {
        if (!files) {
            return;
        }

        // Upload the file.
        const blob = await uploadBlob(files);
        if (!blob) {
            return;
        }

        // Import from the upload.
        const results = await importUserAssessmentAssignments({ blobId: blob.id, subscriptionId: subscriptionId ?? '' });

        setImportUserAssessmentAssignmentsResults(results);
    }, [uploadBlob, subscriptionId, setImportUserAssessmentAssignmentsResults, subscriptionTeamId, subscription, ]);


    return (
        <Background>
            <Banner>
                <Row>
                    <Col>
                        <h1>{t('importUserAssessmentAssignmentsBase.createHeading', 'Import user assessment assignments')}</h1>
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                    <Col xs="auto">
                        <ButtonGroup>
                            <a className=" btn btn-outline-primary" href={`/api/export/BlankAssessmentImport?subscriptionId=${encodeURIComponent(subscription?.id ?? '')}&type=${"Assessment"}`} download>
                                <FontAwesomeIcon icon="download" />
                                <> </>
                                {t('subscriptionDashboard.tabs.assessmentAssignments', 'Export assessment assignments')}
                            </a>
                            <a className=" btn btn-outline-primary" href={`/api/export/BlankAssessmentImport?subscriptionId=${encodeURIComponent(subscription?.id ?? '')}&type=${"TrainingModule"}`} download>
                                <FontAwesomeIcon icon="download" />
                                <> </>
                                {t('subscriptionDashboard.tabs.assessmentAssignments', 'Export training assignments')}
                            </a>
                        </ButtonGroup>
                    </Col>
                </Row>
            </Banner>

            <MainContainer>
                <AlertOnErrors errors={[loadSupportingDataErrors, uploadBlobErrors, importUserAssessmentAssignmentsErrors, performUserAssessmentAssignmentImportErrors]} />

                <ConditionalFragment showIf={!importUserAssessmentAssignmentsResults?.length}>
                    <h5>
                        {t('importUserAssessmentAssignmentsBase.instructions.p1', 'User assessment assignments can be imported from a file instead of being set up manually within the app.  The file can be in Excel (.xlsx), CSV (.csv), or tab-delimited (.txt or .tsv) format and must contain the correct columns as outlined below.')}
                    </h5>
                    <p>
                        {t('importUserAssessmentAssignmentsBase.instructions.p2', 'The file must contain the following columns.  Other columns will be ignored:')}
                    </p>
                    <ol>
                        <li>{t('importUserAssessmentAssignmentsBase.columns.email', 'Email or PIN.')}</li>
                        <li>{t('importUserAssessmentAssignmentsBase.columns.assignment', 'The exact assessment or training name (any order).')}</li>
                    </ol>

                    <p>
                        {t('importUserAssessmentAssignmentsBase.instructions.p3', 'Underneath the assessment or training name, the following values are accepted:')}
                    </p>
                    <ul>
                        <li>{t('importUserAssessmentAssignmentsBase.columns.assignmentYes', '"Yes" : Assign the assessment or training to the user.')}</li>
                        <li>{t('importUserAssessmentAssignmentsBase.columns.assignmentYes', '"No" : Remove an assignment from the user.')}</li>
                        <li>{t('importUserAssessmentAssignmentsBase.columns.assignmentYes', 'It can also be left empty where no changes will occur.')}</li>
                    </ul>
                    <p>
                        {t('importUserAssessmentAssignmentsBase.instructions.p4', 'Unsure about the file contents? You can use the export assignment buttons in the top right to get the correct layout and make the changes you want to import.')}
                    </p>
                    <p style={{ fontWeight: 'bold' }}>
                        {t('importUserAssessmentAssignmentsBase.instructions.p5', 'Please note that these imports and exports relate to directly assigning users to assessments and training.  If you want to assign a full team to an assessment or training you can do this under the "Teams" area.')}
                    </p>
                </ConditionalFragment>

                <ConditionalFragment showIf={!!importUserAssessmentAssignmentsResults?.length}>
                    <h5>
                        {t('importUserAssessmentAssignmentsBase.userAssessmentAssignmentResults.summary', '{{count}} assignments have been changed for you.', { count: importUserAssessmentAssignmentsResults?.filter(it => it.successful)?.length ?? 0 })}
                    </h5>

                    {
                        importUserAssessmentAssignmentsResults?.map(item => (
                            <div key={item.rowNumber} className={item.successful ? 'text-success' : 'text-danger'}>
                                <Row>
                                    <ConditionalFragment showIf={item.rowNumber !== -1 /* Whole file errors. */}>
                                        <Col xs={12} md="auto">
                                            {t('importUserAssessmentAssignmentsBase.results.changeNumber', 'Change {{rowNumber}}', { rowNumber: item.rowNumber })}
                                        </Col>
                                    </ConditionalFragment>
                                    <Col>
                                        <ConditionalFragment showIf={item.successful}>
                                            {item.message}
                                        </ConditionalFragment>
                                        <ConditionalFragment showIf={!!item.errors.length}>
                                            <ConditionalFragment showIf={item.errors.length === 1}>
                                                {
                                                    item.errors.map((error, index) => (
                                                        <div key={index}>
                                                            {error}
                                                        </div>
                                                    ))
                                                }
                                            </ConditionalFragment>
                                            <ConditionalFragment showIf={item.errors.length > 1}>
                                                <ul>
                                                    {
                                                        item.errors.map((error, index) => (
                                                            <li key={index}>
                                                                {error}
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                            </ConditionalFragment>
                                        </ConditionalFragment>
                                    </Col>
                                </Row>
                                <hr />
                            </div>
                        ))
                    }
                </ConditionalFragment>

                <FormButtons>
                    <ConditionalFragment showIf={!isLoading && !importUserAssessmentAssignmentsResults}>
                        <FileUploadButton onUpload={files => performUserAssessmentAssignmentImport(files)} isExecuting={isImportingUserAssessmentAssignments}
                            executingChildren={<><Spinner size="sm" /><> </>{t('importUserAssessmentAssignmentsBase.importing', 'Importing...')}</>}
                        >
                            <FontAwesomeIcon icon="upload" className="nav-icon" />
                            <> </>
                            {t('importUserAssessmentAssignmentsBase.import', 'Import file...')}
                        </FileUploadButton>
                        <Button type="button" color="primary" outline onClick={e => history.goBack()}>
                            {t('common.cancel', 'Cancel')}
                        </Button>
                    </ConditionalFragment>
                    <ConditionalFragment showIf={!!importUserAssessmentAssignmentsResults}>
                        <Button type="button" color="primary" onClick={e => history.goBack()}>
                            {t('common.close', 'Close')}
                        </Button>
                    </ConditionalFragment>
                </FormButtons>
            </MainContainer>
        </Background>
    );
};
