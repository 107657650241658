import * as React from "react";
import { Form, Alert, FormGroup, Label, Spinner, Row, Col, CardHeader, Card, CardBody } from "reactstrap";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { useChanges } from "../../shared/useChanges";
import { useValidatorCallback } from "pojo-validator-react";
import { ValidatedInput } from "pojo-validator-reactstrap";
import { ButtonAsync } from "reactstrap-buttonasync";
import { useSendPasswordResetEmailCallback } from "../../api/account";
import { ForgotPassword as ForgotPasswordModel } from '../../api/account/models/ForgotPassword';
import { useTranslation } from "react-i18next";
import { FormButtons } from "../shared/FormButtons";
import { Background } from "../shared/background/Background";
import { MainContainer } from "../shared/MainContainer";
import { Link } from "react-router-dom";

/**
 * Request a password reset emai because the user has forgotten their password.
 */
export const ForgotPassword = () => {
    const { t } = useTranslation();
    const [emailSent, setEmailSent] = React.useState<boolean>(false);
    const [sendResetEmail, { isExecuting: isSendingResetEmail, errors: sendResetEmailErrors }] = useSendPasswordResetEmailCallback();

    const { model, change } = useChanges<ForgotPasswordModel>({ email: '' });

    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        if (!model) {
            return;
        }

        const rules = {
            email: () => !model.email ? t('forgotPassword.emailRequired', 'Email is required') : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, [model]);

    // Render the UI.
    // 
    return (
        <Background centerChildren="vertically">
            <MainContainer color="transparent">
                <Card color="dark">
                    <CardHeader>
                        <h1>{t('forgotPassword.forgotPasswordHeading', 'Forgot your password?')}</h1>
                    </CardHeader>
                    <CardBody>
                        <p>
                            {t('forgotPassword.helpText', 'Enter your email and we\'ll send you a link you can use to recover your account and set a new password.')}
                        </p>
                        <Form onSubmit={async e => {
                            e.preventDefault();
                            const ok = await sendResetEmail(model.email);
                            setEmailSent(ok);
                        }}>

                            <AlertOnErrors simple errors={sendResetEmailErrors} />
                            {
                                emailSent ? (
                                    <Alert color="success">
                                        <Row>
                                            <Col>
                                                {t('forgotPassword.emailHasBeenSent', 'Please check your email and follow the link to reset your password.')}
                                            </Col>
                                            <Col xs="auto">
                                                <ButtonAsync type="submit" color="success" isExecuting={isSendingResetEmail}
                                                    executingChildren={<><Spinner size="sm" />{t('common.sending', 'Sending...')}</>}>
                                                    {t('common.resendEmail', 'Resend email')}
                                                </ButtonAsync>
                                            </Col>
                                        </Row>
                                    </Alert>
                                ) : null
                            }
                            <FormGroup>
                                <Label htmlFor="email">{t('forgotPassword.email', 'Email')}</Label>
                                <ValidatedInput type="email" name="email" autoComplete="username" value={model.email} onChange={e => change({ email: e.currentTarget.value })} onBlur={e => validate('email')} validationErrors={validationErrors['email']} />
                            </FormGroup>

                            <FormButtons>
                                <Row>
                                    <Col xs="auto">
                                        <Link to={'/account/login'}>
                                            {t('forgottenPassord.forgotYourPassword', 'Remembered your password and want to login instead?')}
                                        </Link>
                                    </Col>
                                    <Col>
                                        <ButtonAsync type="submit" color="primary" isExecuting={isSendingResetEmail}
                                            executingChildren={<><Spinner size="sm" /> {t('common.sending', 'Sending...')}</>}>
                                            {t('forgottenPassword.send', 'Send password reset email')}
                                        </ButtonAsync>
                                    </Col>
                                </Row>
                            </FormButtons>
                        </Form>
                    </CardBody>
                </Card>
            </MainContainer>
        </Background>
    );
};
