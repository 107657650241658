import { useMemo } from "react";
import { useLocation } from "react-router";
import { DriverNavigation } from "./drivers/DriverNavigation";
import { ManagerNavigation } from "./managers/ManagerNavigation";
import { AdministratorNavigation } from './administrators/AdministratorNavigation';

export interface UserNavigationProps {
    id: string,
    onNavigate?: (link: string) => void,
    editLink?: string,
}

/**
 * Navigation under a user that will automatically select the correct navigation to use for the type of user
 * (using the route/URL).
 * 
 * If you know where you are, it is usually better to use the right navigation directly, e.g. DriverNavigation, but in situations where you don't want
 * to make the component overly aware of how we split users into Drivers/Managers etc. you can use this one and let the logic here decide which one to show instead.
 */
export const UserNavigation = (props: UserNavigationProps) => {
    const { pathname } = useLocation();

    const userType = useMemo((): 'driver' | 'manager' | 'administrator' => (
        pathname.indexOf('/drivers') >= 0 ? 'driver'
            : pathname.indexOf('/managers') >= 0 ? 'manager'
                : 'administrator'
    ), [pathname]);

    switch (userType) {
        case 'driver':
            return <DriverNavigation {...props} />;
        case 'manager':
            return <ManagerNavigation {...props} />;
        case 'administrator':
            return <AdministratorNavigation {...props} />;
        default:
            return null;
    }
};
