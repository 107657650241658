import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { Button, ButtonGroup, Card, CardBody, Col, Collapse, FormGroup, Label, ListGroup, ListGroupItem, Row,} from "reactstrap";
import { Question } from "../../../../api/main/models/Question";
import { AssessmentItem } from "../../../../api/main/models/AssessmentItem";
import { FormButtons } from "../../../shared/FormButtons";
import { AssessmentItemQuestion, assessmentItemQuestionDefaultValues } from "../../../../api/main/models/AssessmentItemQuestion";
import { getQuestionTypeCategory, QuestionType, QuestionTypeCategory, questionTypeDisplayName } from "../../../../api/main/models/codeOnly/QuestionType";
import { ValidatedInput } from "pojo-validator-reactstrap";
import { ValidateCallback } from "pojo-validator-react";
import { ValidationErrors } from "pojo-validator";
import { CardHeaderCollapse } from "../../../shared/cardHeaderCollapse/CardHeaderCollapse";
import { useToggleState } from "use-toggle-state";
import { useCallback, useMemo } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { InputSelectOrText } from "../../../shared/inputSelectOrText/InputSelectOrText";
import { SelectQuestionModal } from "../../../questions/selectQuestionModal/SelectQuestionModal";
import { ModelArrayChanges } from "../../../../shared/useChanges";
import { NoResultsFound } from "../../../shared/NoResultsFound";
import { Link } from "react-router-dom";
import { SelectQuestionnaireModal } from "../../../questions/questionnaires/SelectQuestionnaireModal/SelectQuestionnaireModal";
import { SelectLearningModal } from "../../../questions/learning/SelectLearningModal/SelectLearningModal";
import { AssessmentItemDriverMetricQuantity, assessmentItemDriverMetricQuantityDefaultValues } from "../../../../api/main/models/AssessmentItemDriverMetricQuantity";
import { AssessmentItemDriverMetricDisplayQuantityRuleComponent } from "./assessmentItemDriverMetricDisplayQuantityRuleComponent";
import { DriverMetric } from "../../../../api/main/models/DriverMetric";

export interface AssessmentItemComponentProps {
    model: AssessmentItem,
    change: (changes: Partial<AssessmentItem>) => void,
    remove: () => void,
    moveUp: () => void,
    canMoveUp: boolean,
    moveDown: () => void,
    canMoveDown: boolean,

    assessmentItemQuestions: Array<AssessmentItemQuestion>,
    allQuestions: Array<Question>,
    assessmentItemQuestionsManager: ModelArrayChanges<AssessmentItemQuestion, string>,
    assessmentItemDriverMetricQuantitiesManager: ModelArrayChanges<AssessmentItemDriverMetricQuantity, string>,

    validate: ValidateCallback,
    validationErrors: ValidationErrors,

    validateAssessmentItemDriverMetricQuantity: (model: AssessmentItemDriverMetricQuantity) => boolean,
    assessmentItemDriverMetricQuantityErrors: (id: string) => ValidationErrors,

    isOpenInitially?: boolean,

    counterbalanceGroups: Array<string>,

    driverMetrics: Array<DriverMetric>,
}

/**
 * A question that forms part of the AssessmentItem.
 * @param props
 */
export const AssessmentItemComponent = (props: AssessmentItemComponentProps) => {
    const {
        model,
        change,
        remove,
        moveUp,
        canMoveUp,
        moveDown,
        canMoveDown,

        assessmentItemQuestions,
        allQuestions,
        assessmentItemQuestionsManager,
        assessmentItemDriverMetricQuantitiesManager,

        validate,
        validationErrors,

        validateAssessmentItemDriverMetricQuantity,
        assessmentItemDriverMetricQuantityErrors,

        isOpenInitially = false,

        counterbalanceGroups,

        driverMetrics,
    } = props;

    const { t } = useTranslation();

    const currentItemDisplayRules = useMemo(() => {
        return assessmentItemDriverMetricQuantitiesManager.model.filter(item => item.assessmentItemId === model.id)
    }, [assessmentItemDriverMetricQuantitiesManager, model]);

    // Self management of if we are expanded or not.
    const [isOpen, toggleOpen] = useToggleState(isOpenInitially);

    // Work out what category of question we're working with, as we want to be conistant to that type.
    const activeQuestionTypeCategory = useMemo(() => {
        let ret: QuestionTypeCategory = QuestionTypeCategory.Question;

        // If we have no questions selected, return the default and give up.
        if (!assessmentItemQuestions.length) {
            return ret;
        }

        // If we have a question selected, lets load it and get its type.
        const assessmentItemQuestion = assessmentItemQuestions[0];
        const question = allQuestions.find(item => item.id === assessmentItemQuestion.questionId);
        if (!question) {
            return ret;
        }

        ret = getQuestionTypeCategory(question.questionType as QuestionType);

        return ret;
    }, [assessmentItemQuestions, allQuestions]);

    const addAssessmentItemDriverMetricQuantity = useCallback(() => {
        assessmentItemDriverMetricQuantitiesManager.addFor({
            ...assessmentItemDriverMetricQuantityDefaultValues(),
            assessmentItemId: model.id
        })

    }, [assessmentItemDriverMetricQuantitiesManager, model]);

    // Generate a heading for the assessment item.
    const { headingText, subheadingText } = useMemo(() => {
        let headingText = '';
        let subheadingText = '';

        let totalQuantity: number = 0;
        // Check if we need to count all the quantities
        if (currentItemDisplayRules.length >= 1) {

            // Total up all quantities for the current item to show the correct amount for total to ask in pool
            
            currentItemDisplayRules.forEach(item => {

                if (item.quantity == null || Number.isNaN(item.quantity)) {
                    // We dont want to add the quantity if its null
                    return;
                }
                // Add the quantity to total
                totalQuantity = totalQuantity + item.quantity
            });

            // Set numberOfQuestionsToPresent to be the total we calculated
            model.numberOfQuestionsToPresent = totalQuantity;
        }

        switch (assessmentItemQuestions.length) {
            case 0:
                headingText =
                    activeQuestionTypeCategory === QuestionTypeCategory.Learning ? t('assessmentItemComponent.headingText.none.headingText.learning', 'No learning activities selected.')
                        : activeQuestionTypeCategory === QuestionTypeCategory.Questionnaire ? t('assessmentItemComponent.headingText.none.headingText.questionnaire', 'No questionnaires activities selected.')
                            : t('assessmentItemComponent.headingText.none.headingText.default', 'No questions selected.');
                break;
            case 1:
                const question = allQuestions.find(it => it.id === assessmentItemQuestions[0].questionId);
                headingText = question?.name ?? '';
                subheadingText = questionTypeDisplayName(question?.questionType as QuestionType, t);
                break;
            default:
                headingText =
                    activeQuestionTypeCategory === QuestionTypeCategory.Learning ? t('assessmentItemComponent.headingText.moreThanOne.headingText.learning', 'Present {{count}} activities out of a pool of {{poolCount}} learning activities', { count: model?.numberOfQuestionsToPresent ?? 1, poolCount: assessmentItemQuestions.length})
                    : activeQuestionTypeCategory === QuestionTypeCategory.Questionnaire ? t('assessmentItemComponent.headingText.moreThanOne.headingText.questionnaires', 'Present {{count}} questionnaires out of a pool of {{poolCount}} questionnaires', { count: model?.numberOfQuestionsToPresent ?? 1, poolCount: assessmentItemQuestions.length})
                        : t('assessmentItemComponent.headingText.moreThanOne.headingText.default', 'Ask {{count}} questions out of a pool of {{poolCount}} questions', { count: (model?.numberOfQuestionsToPresent ?? totalQuantity) ?? 1, poolCount: assessmentItemQuestions.length });
                let questionNames = '';
                const maxQuestionNamesToShow = 3;
                for (let i = 0; i < maxQuestionNamesToShow && i < assessmentItemQuestions.length; ++i) {
                    const question = allQuestions.find(it => it.id === assessmentItemQuestions[i].questionId);
                    
                    if (!questionNames) {
                        questionNames = question?.name ?? '';
                    } else {
                        questionNames += `, ${question?.name ?? ''}`;
                    }
                }

                if (assessmentItemQuestions.length <= maxQuestionNamesToShow) {
                    subheadingText = questionNames;
                } else {
                    subheadingText =
                        activeQuestionTypeCategory === QuestionTypeCategory.Learning ? t('assessmentItemComponent.headingText.moreThanOne.subheadingText.someNames.learning', '{{questionNames}}, and {{count}} more learning activities.', { questionNames, count: (assessmentItemQuestions.length - maxQuestionNamesToShow) })
                        : activeQuestionTypeCategory === QuestionTypeCategory.Questionnaire ? t('assessmentItemComponent.headingText.moreThanOne.subheadingText.someNames.questionnaire', '{{questionNames}}, and {{count}} more questionnaires.', { questionNames, count: (assessmentItemQuestions.length - maxQuestionNamesToShow) })
                            : t('assessmentItemComponent.headingText.moreThanOne.subheadingText.someNames.default', '{{questionNames}}, and {{count}} more questions.', { questionNames, count: (assessmentItemQuestions.length - maxQuestionNamesToShow) });
                }
        }

        return {
            headingText,
            subheadingText,
        };
    }, [t, model, assessmentItemQuestions, allQuestions, activeQuestionTypeCategory, currentItemDisplayRules]);


    // Showing of the question modal.
    const [questionModalIsOpen, toggleQuestionModal] = useToggleState();
    const[questionnaireModalIsOpen, toggleQuestionnaireModal] = useToggleState();
    const[learningModalIsOpen, toggleLearningModal] = useToggleState();

    // Adding of questions selected in the modal
    const onQuestionModalClosed = useCallback((event: { addedIds: Array<string>, removedIds: Array<string> }) => {
        // Add any new questions that were selected.
        for (const selectedId of event.addedIds) {
            assessmentItemQuestionsManager.addFor({
                ...assessmentItemQuestionDefaultValues(),

                assessmentId: model?.assessmentId,
                assessmentItemId: model?.id,
                questionId: selectedId,
            });
        }


        // Remove any previously selected questions that have been unselected.
        for (const unselectedId of event.removedIds) {
            const existing = assessmentItemQuestionsManager.model.find(it => it.assessmentItemId === model?.id && it.questionId === unselectedId);
            if (!existing) {
                continue;
            }

            assessmentItemQuestionsManager.removeFor(existing.id);
        }
    }, [assessmentItemQuestionsManager, model]);

    const onQuestionnaireModalClosed = useCallback((event: { addedIds: Array<string>, removedIds: Array<string> }) => {
        // Add any new questions that were selected.
        for (const selectedId of event.addedIds) {
            assessmentItemQuestionsManager.addFor({
                ...assessmentItemQuestionDefaultValues(),

                assessmentId: model?.assessmentId,
                assessmentItemId: model?.id,
                questionId: selectedId,
            });
        }


        // Remove any previously selected questions that have been unselected.
        for (const unselectedId of event.removedIds) {
            const existing = assessmentItemQuestionsManager.model.find(it => it.assessmentItemId === model?.id && it.questionId === unselectedId);
            if (!existing) {
                continue;
            }

            assessmentItemQuestionsManager.removeFor(existing.id);
        }
    }, [assessmentItemQuestionsManager, model]);

    const onLearningModalClosed = useCallback((event: { addedIds: Array<string>, removedIds: Array<string> }) => {
        // Add any new questions that were selected.
        for (const selectedId of event.addedIds) {
            assessmentItemQuestionsManager.addFor({
                ...assessmentItemQuestionDefaultValues(),

                assessmentId: model?.assessmentId,
                assessmentItemId: model?.id,
                questionId: selectedId,
            });
        }


        // Remove any previously selected questions that have been unselected.
        for (const unselectedId of event.removedIds) {
            const existing = assessmentItemQuestionsManager.model.find(it => it.assessmentItemId === model?.id && it.questionId === unselectedId);
            if (!existing) {
                continue;
            }

            assessmentItemQuestionsManager.removeFor(existing.id);
        }
    }, [assessmentItemQuestionsManager, model]);

    const RemoveBoth = useCallback(() => {
        // We need to remove the itemQuestion as well as item as we cannot save an ItemQuestion for an item that isnt in the list.

        const existing = assessmentItemQuestionsManager.model.find(it => it.assessmentItemId === model.id);
        if (existing) {
            assessmentItemQuestionsManager.removeFor(existing.id)
        }
        remove();

    }, [assessmentItemQuestionsManager, model, remove]);



    return (
        <>
            <Card>
                <CardHeaderCollapse isOpen={isOpen} toggle={toggleOpen}>
                    <Row>
                        <Col>
                            {headingText}
                            <div className="text-muted">
                                {subheadingText}
                            </div>
                        </Col>
                        <Col xs="auto">
                            {model?.counterbalanceGroup}
                        </Col>
                    </Row>
                </CardHeaderCollapse>

                <Collapse isOpen={isOpen}>
                    <CardBody>
                        <FormButtons noPadding className="mb-1">
                            <ButtonGroup>
                                <Button color="primary" outline onClick={() => moveUp()} disabled={!canMoveUp}>
                                    <FontAwesomeIcon icon="caret-up" />
                                    <> </>
                                    {t('assessmentItemComponent.up', 'Up')}
                                </Button>
                                <Button color="primary" outline onClick={() => moveDown()} disabled={!canMoveDown}>
                                    <FontAwesomeIcon icon="caret-down" />
                                    <> </>
                                    {t('assessmentItemComponent.down', 'Down')}
                                </Button>
                                <Button color="danger" outline onClick={() => RemoveBoth()}>
                                    <FontAwesomeIcon icon="trash-alt" />
                                    <> </>
                                    {t('assessmentItemComponent.delete', 'Remove item')}
                                </Button>
                            </ButtonGroup>
                        </FormButtons>

                        <hr />

                        <ConditionalFragment showIf={assessmentItemQuestions.length > 1 && currentItemDisplayRules.length === 0}>
                            <FormGroup>
                                <Label htmlFor="numberOfQuestionsToPresent">{t('assessmentItemComponent.numberOfQuestionsToPresent.label', 'Number of questions from the pool to ask')}</Label>

                                <ValidatedInput name="numberOfQuestionsToPresent" type="number" value={model?.numberOfQuestionsToPresent ?? ''} onChange={e => change({ numberOfQuestionsToPresent: e.currentTarget.valueAsNumber })} onBlur={() => validate('numberOfQuestionsToPresent')} validationErrors={validationErrors['numberOfQuestionsToPresent']} />
                            </FormGroup>
                        </ConditionalFragment>

                        <ConditionalFragment showIf={assessmentItemQuestions.length > 1}>
                            <div style={{ marginBottom: 4}}>
                                <Row>
                                    <Col>
                                        <Label htmlFor="questions">{t('assessmentItemComponent.questions.label.default', 'Driver metric display rules')}</Label>
                                    </Col>
                                    <Col xs="auto">
                                        <Button color="primary" outline onClick={() => addAssessmentItemDriverMetricQuantity()}>
                                            {t('assessmentItemComponent.addDisplayRule', 'Add display rule')}
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        

                        <div>
                            {
                                    currentItemDisplayRules.map(item => (
                                    <AssessmentItemDriverMetricDisplayQuantityRuleComponent key={item.id}
                                        model={item}
                                        change={changes => assessmentItemDriverMetricQuantitiesManager.changeFor(item.id, changes)}
                                        remove={() => assessmentItemDriverMetricQuantitiesManager.removeFor(item.id)}
                                        driverMetrics={driverMetrics}
                                        validate={() => validateAssessmentItemDriverMetricQuantity(item)}
                                        validationErrors={assessmentItemDriverMetricQuantityErrors(item.id)}
                                    />
                                ))
                            }
                        </div>

                        <hr />
                        </ConditionalFragment>



                        <FormGroup>
                            <ConditionalFragment showIf={activeQuestionTypeCategory === QuestionTypeCategory.Learning}>
                                <Row>
                                    <Col>
                                        <Label htmlFor="questions">{t('assessmentItemComponent.questions.label.learning', 'Learning activity pool')}</Label>
                                    </Col>
                                    <Col xs="auto">
                                        <Button color="primary" outline onClick={() => toggleLearningModal()}>
                                            {t('assessmentItemComponent.editQuestionPool.learning', 'Edit learning activity pool')}
                                        </Button>
                                    </Col>
                                </Row>
                            </ConditionalFragment>
                            <ConditionalFragment showIf={activeQuestionTypeCategory === QuestionTypeCategory.Questionnaire}>
                                <Row>
                                    <Col>
                                        <Label htmlFor="questions">{t('assessmentItemComponent.questions.label.questionnaire', 'Questionnaire pool')}</Label>
                                    </Col>
                                    <Col xs="auto">
                                        <Button color="primary" outline onClick={() => toggleQuestionnaireModal()}>
                                            {t('assessmentItemComponent.editQuestionPool.questionnaire', 'Edit questionnaire pool')}
                                        </Button>
                                    </Col>
                                </Row>
                            </ConditionalFragment>
                            <ConditionalFragment showIf={activeQuestionTypeCategory === QuestionTypeCategory.Question}>
                                <Row>
                                    <Col>
                                        <Label htmlFor="questions">{t('assessmentItemComponent.questions.label.default', 'Question pool')}</Label>
                                    </Col>
                                    <Col xs="auto">
                                        <Button color="primary" outline onClick={() => toggleQuestionModal()}>
                                            {t('assessmentItemComponent.editQuestionPool.default', 'Edit question pool')}
                                        </Button>
                                    </Col>
                                </Row>
                            </ConditionalFragment>

                            <ListGroup className="mt-1">
                                {
                                    // To show the questions we loop over allQuesitons and find the ones within this assessment item
                                    // rather than looping over each assessment item.  We do it this way so the order of questions is
                                    // preserved (i.e. they will show alphabetically).
                                    allQuestions.map(question => {
                                        const assessmentItemQuestion = assessmentItemQuestions.find(it => it.questionId === question.id);
                                        if (!assessmentItemQuestion) {
                                            return null;
                                        }

                                        return (
                                            <ListGroupItem key={assessmentItemQuestion.id} tag="div">
                                                <div>
                                                    <Link
                                                        to={
                                                            activeQuestionTypeCategory === QuestionTypeCategory.Learning ? `/administration/learning-activities/edit/${question?.id}`
                                                                : activeQuestionTypeCategory === QuestionTypeCategory.Questionnaire ? `/administration/questionnaires/edit/${question?.id}`
                                                                    : `/administration/questions/edit/${question?.id}`
                                                        }
                                                        target="_blank"
                                                    >
                                                        {question?.name}
                                                    </Link>
                                                </div>
                                                <div className="text-muted">
                                                    {questionTypeDisplayName(question?.questionType as QuestionType, t)}
                                                </div>
                                            </ListGroupItem>
                                        );
                                    })
                                }

                                <ConditionalFragment showIf={!assessmentItemQuestions.length}>
                                    <ListGroupItem>
                                        <NoResultsFound />
                                    </ListGroupItem>
                                </ConditionalFragment>
                            </ListGroup>
                        </FormGroup>

                        <FormGroup>
                            <Label htmlFor="counterbalanceGroup">{t('assessmentItemComponent.counterbalanceGroup.label', 'Counterbalance group')}</Label>
                            <InputSelectOrText value={model?.counterbalanceGroup} onChange={e => change({ counterbalanceGroup: e.currentTarget.value })}>
                                <option value="">{t('assessmentItemComponent.counterbalanceGroup.none', '(No counterbalance group)')}</option>
                                {
                                    counterbalanceGroups
                                        .filter(item => !!item)
                                        .map(item => (
                                        <option key={item} value={item}>
                                            {item}
                                        </option>
                                    ))
                                }
                            </InputSelectOrText>
                        </FormGroup>
                    </CardBody>
                </Collapse>
            </Card>


                <SelectQuestionModal
                isOpen={questionModalIsOpen}
                toggle={toggleQuestionModal}
                onClose={onQuestionModalClosed}
                questions={allQuestions}
                initialSelectedIds={assessmentItemQuestions.map(item => item.questionId)}
            />

            <SelectQuestionnaireModal
                isOpen={questionnaireModalIsOpen}
                toggle={toggleQuestionnaireModal}
                onClose={onQuestionnaireModalClosed}
                questionnaires={allQuestions}
                initialSelectedIds={assessmentItemQuestions.map(item => item.questionId)}
            />

            <SelectLearningModal
                isOpen={learningModalIsOpen}
                toggle={toggleLearningModal}
                onClose={onLearningModalClosed}
                learning={allQuestions}
                initialSelectedIds={assessmentItemQuestions.map(item => item.questionId)}
            />

        </>
        );
};