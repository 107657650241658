import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useCurrentUserOrEmulatedSubscriptionId } from "../../../globalState/subscriptions/useCurrentUserOrEmulatedSubscriptionId";
import { ManageNavigation } from "../../manage/ManageNavigation";
import { SubscriptionTeamNavigation } from "../../subscriptionTeam/SubscriptionTeamNavigation";
import { UsersListBase } from "../UsersListBase";

/**
 * List of drivers.
 */
export const DriversList = () => {
    const { t } = useTranslation();

    const { subscriptionTeamId } = useParams<{ subscriptionTeamId: string | undefined}>();

    const subscriptionId = useCurrentUserOrEmulatedSubscriptionId();

    const baseRoute = subscriptionTeamId ? `/manage/teams/details/${subscriptionTeamId}/drivers` : '/manage/drivers';

    return (
        <UsersListBase
            title={t('driversList.title', 'Drivers')}
            filterByRoleGroups={['Driver']}
            roleGroupColumnHidden={true}
            baseRoute={baseRoute}
            subscriptionId={subscriptionId}
            subscriptionTeamId={subscriptionTeamId}
            navigationPills={
                subscriptionTeamId ? <SubscriptionTeamNavigation id={subscriptionTeamId} />
                    : <ManageNavigation />
            }
            teamColumnHidden={subscriptionTeamId ? true
                : false
                }
            
        />
        );
};