import { useTranslation } from "react-i18next";
import { HtmlDisplay } from "../../../../../shared/htmlEditor";
import { Question } from "../../../../../api/main/models/Question";
import { Input } from 'reactstrap';
import { ModelArrayChanges } from "../../../../../shared/useChanges";
import { QuestionResponseEvent, questionResponseEventDefaultValues } from "../../../../../api/main/models/QuestionResponseEvent";
import moment from "moment";
import { useCurrentUserId } from "../../../../../api/account";
import * as React from "react";

/**
 * Running of a MultipleChoiceVideo question.
 * @param props
 */

export interface ShowQuestionnaireInputProps {

    model: Question
    questionResponseEventsManager: ModelArrayChanges<QuestionResponseEvent, string>,


    //needed for adding to response manager
    assessmentId: string,
    assessmentItemId: string,
    assessmentSessionId: string,
}

export const ShowQuestionnaireInput = (props: ShowQuestionnaireInputProps) => {
    const {
        model,
        questionResponseEventsManager,


        assessmentId,
        assessmentItemId,
        assessmentSessionId,
    } = props;

    const { t } = useTranslation();

    // Current user.
    const currentUserId = useCurrentUserId();

    const now = moment().toISOString();

    //for use with the input box and setting its value
    const [textInput, setTextInput] = React.useState<string>();
    

    const addInput = React.useCallback(() => {

        if (textInput) {

            const existingAnswers = questionResponseEventsManager.model.filter(item => item.questionId === model.id && !item.archived);

            if (existingAnswers) {
                //if we already have added to the manager, we need to archive it

                for (const existingAnswer of existingAnswers) {
                    questionResponseEventsManager.changeFor(existingAnswer.id, { archived: true, cancelEventDate: now });
                }

            }

            //add what we have to the manager
            questionResponseEventsManager.addFor({
                ...questionResponseEventDefaultValues(),

                eventDate: now,
                assessmentId: assessmentId,
                assessmentItemId: assessmentItemId,
                assessmentSessionId: assessmentSessionId,
                questionId: model.id,
                userId: currentUserId,
                textInput: textInput,
            });

        }

    }, [questionResponseEventsManager, model, textInput, assessmentId, assessmentItemId, assessmentSessionId, currentUserId, now]);

    return (
        <div className="show-questionnaire-multiple-choice">
            <HtmlDisplay html={t(model.questionText)} />

            <Input type={'textarea'} value={textInput} onBlur={addInput} onChange={e => setTextInput(e.currentTarget.value) } />

        </div>
    );
}