import './footer.scss';
import * as React from 'react';
import { AppFooter } from '@coreui/react';
import { Nav } from 'reactstrap';
import { FooterNavigation } from './FooterNavigation';

export const Footer = () => {
    return (
        <AppFooter className="footer">
            <Nav className="footer-nav">
                <FooterNavigation />
            </Nav>
        </AppFooter>
    );
};

