import { Link } from "react-router-dom";
import { Button, Col, ListGroup, ListGroupItemHeading, Row } from "reactstrap";
import { Assessment } from "../../../api/main/models/Assessment";
import { AssessmentType } from "../../../api/main/models/codeOnly/AssessmentType";
import { Subscription } from "../../../api/main/models/Subscription";
import { SubscriptionAssessment } from "../../../api/main/models/SubscriptionAssessment";
import { UserAssessmentAssignment } from "../../../api/main/models/UserAssessmentAssignment";
import { AdministrationDashboardTabs } from "../administrationDashboard/AdministrationDashboard";
import { DistributorDashboardListGroupItem } from "../listGroups/DistributorDashboardListGroupItem";
import { useDashboardBaseRoute } from "../utilities/useDashboardBaseRoute";

export interface DistributorDashboardSummary {
    subscriptionId: string,
}

export interface DistributorTabProps {
    distributors: Array<Subscription>,
    subscriptions: Array<Subscription>,
    subscriptionAssessments: Array<SubscriptionAssessment>,
    userAssessmentAssignment: Array<UserAssessmentAssignment>,
    assessments: Array<Assessment>,
    activeAssessmentType: AssessmentType,

    changeTab: (tabName: AdministrationDashboardTabs) => void,
}

/**
 * Subscriptions tab on the dashboard.
 * @param props
 */
export const DistributorsDashboardTab = (props: DistributorTabProps) => {
    const {
        distributors,
        subscriptions,
        subscriptionAssessments,
        changeTab,
        assessments,
        userAssessmentAssignment,

    } = props;


    const baseRoute = useDashboardBaseRoute();

    return (
        <Row>
            <Col xs={12} lg="">
                <ListGroup className="mb-4">
                    {
                        distributors.map(subscription => (
                            <DistributorDashboardListGroupItem
                                key={subscription.id}
                                subscriptionId={subscription.id}
                                subscriptions={subscriptions}
                                subscriptionAssessments={subscriptionAssessments}
                                assessments={assessments}
                                userAssessmentAssignment={userAssessmentAssignment}
                            >
                                {
                                    subscription.id ? (
                                        <Link to={`${baseRoute}/subscription/${subscription.id}`}>
                                            <ListGroupItemHeading>
                                                {subscription.companyName}
                                            </ListGroupItemHeading>
                                        </Link>
                                    ) : (
                                        <Button color="link" onClick={() => changeTab('staff')} style={{ padding: '0px' }}>
                                            <ListGroupItemHeading>
                                                {subscription.companyName}
                                            </ListGroupItemHeading>
                                        </Button>
                                    )
                                }
                            </DistributorDashboardListGroupItem>
                        ))
                    }
                </ListGroup>
            </Col>
        </Row>
    );
};