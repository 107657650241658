import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactNode } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Badge, Col, ListGroupItem, ListGroupItemText, Row } from "reactstrap";
import { AssessmentType } from "../../../api/main/models/codeOnly/AssessmentType";
import { IdentityRoles } from "../../../configure/security/IdentityRoles";
import { AuthorizeContainer } from "../../../shared/authorizeRoute";
import { AssessmentAverageScoreProgressBar } from "../progress/AssessmentAverageScoreProgressBar";
import { IndividualScoreProgressBar } from "../progress/IndividualScoreProgressBar";
import { TrainingAverageScoreProgressBar } from "../progress/TrainingAverageScoreProgressBar";
import { DashboardSummary } from "../utilities/generateDashboardSummary";

export interface DashboardListGroupItemProps {
    children?: ReactNode,
    getDashboardSummary: (assessmentType: AssessmentType) => DashboardSummary,

    hideAssessments?: boolean,
    hideTraining?: boolean,
    hideSuspicious?: boolean,

    seatExportHref?: string,

    progressType: 'risk' | 'assessments' | 'training',
}

/**
 * List group with items for each group.
 * @param props
 */
export const DashboardListGroupItem = (props: DashboardListGroupItemProps) => {
    const {
        getDashboardSummary,
        children,

        hideAssessments = false,
        hideTraining = false,
        hideSuspicious = true, // We only want to show suspicious when in a user view, for example we dont want to show the assessment under itself in the assessment view if its been marked suspicious

        seatExportHref = undefined,
        progressType,
    } = props;

    const { t } = useTranslation();
    const assessmentsSummary = getDashboardSummary(AssessmentType.Assessment);
    const trainingSummary = getDashboardSummary(AssessmentType.TrainingModule);

    return (
        <ListGroupItem tag="div">
            <Row>
                <Col xs={12} md="">
                    <div>
                        {children}
                    </div>

                    <ListGroupItemText tag="div">
                        <ConditionalFragment showIf={!hideAssessments}>
                            <div>
                                <strong>
                                    {t('dashboardListGroupItem.assessments.title', 'Assessments')}
                                </strong>
                                <div>
                                    {assessmentsSummary.assessmentsText}
                                </div>
                                <Row>
                                    <Col>
                                        <Badge color="danger">
                                            {t('dashboardListGroup.notStartedCount', 'Not started')}
                                            <> </>
                                            <Badge pill style={{ fontSize: '100%' }}>
                                                {assessmentsSummary.notStarted}
                                            </Badge>
                                        </Badge>
                                    </Col>
                                    <Col>
                                        <Badge color="warning">
                                            {t('dashboardListGroup.startedCount', 'Started')}
                                            <> </>
                                            <Badge pill style={{ fontSize: '100%' }}>
                                                {assessmentsSummary.started}
                                            </Badge>
                                        </Badge>
                                    </Col>
                                    <Col>
                                        <Badge color="success">
                                            {t('dashboardListGroup.completedCount', 'Completed')}
                                            <> </>
                                            <Badge pill style={{ fontSize: '100%' }}>
                                                {assessmentsSummary.complete}
                                            </Badge>
                                        </Badge>
                                    </Col>
                                </Row>
                                <ConditionalFragment showIf={progressType === 'assessments'}> { /*Only want to show for assessments*/ }
                                {/*Styled to seperate it more from the badges above, also using margin left of 0 keeps it in line with the rest of the content (somehow without it it is more to the left)*/}
                                <Row style={{marginTop: "5px", marginLeft: "0px"}}>
                                    <p>This assessment has used {assessmentsSummary.totalFinishedSessionsCount}  {assessmentsSummary.totalFinishedSessionsCount === 1 ? "seat" : "seats"} </p>
                                        {/* Thought this was broken because the number didnt go up, realised I wasnt on an account inside this subscription so it wouldnt track on their dashboard*/}

                                    </Row>

                                    <ConditionalFragment showIf={seatExportHref !== undefined}>
                                        <AuthorizeContainer requireRole={IdentityRoles.Administration}>

                                            <a className=" btn btn-outline-primary" href={seatExportHref} download>
                                                <FontAwesomeIcon icon="download" /> <> {t('assessmentsSummary.export', 'Seat Breakdown')} </>
                                            </a>

                                        </AuthorizeContainer>
                                    </ConditionalFragment>
                                    </ConditionalFragment>
                            </div>
                        </ConditionalFragment>


                        <ConditionalFragment showIf={!hideAssessments && !hideTraining}>
                            <hr />
                        </ConditionalFragment>

                        <ConditionalFragment showIf={!hideTraining}>
                            <div>
                                <strong>
                                    {t('dashboardListGroupItem.training.title', 'Training')}
                                </strong>
                                <div>
                                    {trainingSummary.assessmentsText}
                                </div>
                                <Row>
                                    <Col>
                                        <Badge color="danger">
                                            {t('dashboardListGroup.notStartedCount', 'Not started')}
                                            <> </>
                                            <Badge pill style={{ fontSize: '100%' }}>
                                                {trainingSummary.notStarted}
                                            </Badge>
                                        </Badge>
                                    </Col>
                                    <Col>
                                        <Badge color="warning">
                                            {t('dashboardListGroup.startedCount', 'Started')}
                                            <> </>
                                            <Badge pill style={{ fontSize: '100%' }}>
                                                {trainingSummary.started}
                                            </Badge>
                                        </Badge>
                                    </Col>
                                    <Col>
                                        <Badge color="success">
                                            {t('dashboardListGroup.completedCount', 'Completed')}
                                            <> </>
                                            <Badge pill style={{ fontSize: '100%' }}>
                                                {trainingSummary.complete}
                                            </Badge>
                                        </Badge>
                                    </Col>
                                </Row>
                            </div>
                        </ConditionalFragment>

                        <ConditionalFragment showIf={!!assessmentsSummary.suspiciousAssessmentText && !hideSuspicious}> {/*Should only show marked suspicious section if we have relevant data to show.*/}
                            <hr />
                            <div>
                                <strong>
                                    {t('dashboardListGroupItem.assessments.title', 'Marked Suspicious')}
                                </strong>
                                <div>
                                    {assessmentsSummary.suspiciousAssessmentText}
                                </div>
                            </div>
                        </ConditionalFragment>

                    </ListGroupItemText>
                </Col>
                <ConditionalFragment showIf={progressType === 'risk'}>
                    <Col xs={12} md="auto">
                        <IndividualScoreProgressBar value={assessmentsSummary.averageUserDriverMetricScore} title={t('dashboardListGroupItem.overallRisk', 'Overall risk')} assessmentType={AssessmentType.Assessment} />
                    </Col>
                </ConditionalFragment>
                <ConditionalFragment showIf={progressType === 'assessments'}>
                    <Col xs={12} md="auto">
                        <AssessmentAverageScoreProgressBar summary={assessmentsSummary} />
                    </Col>
                </ConditionalFragment>
                <ConditionalFragment showIf={progressType === 'training'}>
                    <Col xs={12} md="auto text-center">
                        <TrainingAverageScoreProgressBar summary={trainingSummary} />
                    </Col>
                </ConditionalFragment>
            </Row>
        </ListGroupItem>
        );
};