import { RouteEntry } from "../../shared/routes";
import { TermsAndConditionsPage } from "./TermsAndConditionsPage";
import { PrivacyPolicyPage } from "./PrivacyPolicyPage";
import { CookiePolicyPage } from "./CookiePolicyPage";

export const cmsPageRoutes: Array<RouteEntry> = [
    { path: '/terms-and-conditions', exact: true, component: TermsAndConditionsPage, },
    { path: '/privacy-policy', exact: true, component: PrivacyPolicyPage, },
    { path: '/cookie-policy', exact: true, component: CookiePolicyPage, },
];
