import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { SubscriptionTeamCreateInput } from '../generated/globalTypes';
import { createSubscriptionTeamMutation, createSubscriptionTeamMutationVariables } from '../generated/createSubscriptionTeamMutation';
import { subscriptionTeamFieldsFragment } from '../models/SubscriptionTeam';

/**
 * Get a callback to create a SubscriptionTeam in the store.
 */
export function useCreateSubscriptionTeamCallback(): [(model: SubscriptionTeamCreateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<createSubscriptionTeamMutation, createSubscriptionTeamMutationVariables>(
        gql`
            mutation createSubscriptionTeamMutation ($model: SubscriptionTeamCreateInput!) {
                createSubscriptionTeam(model: $model) {
                    ...subscriptionTeamFields
                }
            }

            ${subscriptionTeamFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (model: SubscriptionTeamCreateInput) => {
        const result = await mutationAction({ variables: { model: model } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
