import { useTranslation } from "react-i18next";
import { AssessmentType } from "../../../api/main/models/codeOnly/AssessmentType";
import { AssessmentsListBase } from "../AssessmentsListBase";

/**
 * List of training modules (using the Assessment model).
 */
export const TrainingModulesList = () => {
    const { t } = useTranslation();

    return (
        <AssessmentsListBase
            title={t('trainingModulesList.title', 'Training modules')}
            assessmentTypes={[AssessmentType.TrainingModule]}
            baseRoute={'/administration/training'}
        />
        );
};