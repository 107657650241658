import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { AssessmentType } from "../../../api/main/models/codeOnly/AssessmentType";
import { ChartData, ChartDataSeries } from "../chartDataUtilities/ChartData";
import { DashboardSummary } from "../utilities/generateDashboardSummary";

/**
 * Data for a chart that shows completion data broken down by groups.
 */
export function useCompletionChartData(groups: Array<{ id: string, name: string }>, getDashboardSummary: (id: string, assessmentType: AssessmentType) => DashboardSummary, assessmentType: AssessmentType): Array<ChartDataSeries> {
    const { t } = useTranslation();


    const generateSeries = useCallback((seriesName: string, getValue: (summary: DashboardSummary) => number) => {
        // Generate chart data for each group.
        const data = groups.map(group => {
            const summary = getDashboardSummary(group.id ?? null, assessmentType);

            return {
                text: group.name,
                value: getValue(summary),
            } as ChartData;
        });

        return {
            name: seriesName,
            data: data,
        } as ChartDataSeries;
    }, [groups, getDashboardSummary, assessmentType]);

    const ret = useMemo(() => {
        return [
            generateSeries(
                t('useCompletioneChartData.complete.seriesName', 'Completed'),
                summary => summary.complete,
            ),
            generateSeries(
                t('useCompletioneChartData.started.seriesName', 'Started'),
                summary => summary.started,
            ),
            generateSeries(
                t('useCompletioneChartData.notStarted.seriesName', 'Not started'),
                summary => summary.notStarted,
            ),
        ];
    }, [generateSeries, t]);

    return ret;
}
