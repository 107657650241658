import { TFunction } from "i18next";

/**
 * HttpMethod
 */
export enum HttpMethod {
    Get = "GET",
    Post = "POST",
    Put = "PUT",
    Delete = "DELETE",
    Options = "OPTIONS",
}

/**
 * Return translated display name if its needed.
 * @param t
 */
export function httpMethodDisplayName(type: HttpMethod, t: TFunction): string {
    // We can just return the option unchanged.
    return type;
}
