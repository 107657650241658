import i18n from 'i18next';


export enum OverallRiskCategory {
    High = 'High',
    Medium = 'Medium',
    Low = 'Low',
    NotAssessed = 'Not Assessed',
}

export interface RiskRules {
    highRiskBelow: number,
    mediumRiskBelow: number,
    // lowBelow: number, // Always 100.
}

export const defaultRiskRules: RiskRules = {
    highRiskBelow: 30,
    mediumRiskBelow: 70,
};


/**
 * Service that pulls together all utilities needed to manage the overall risk calculations and display.
 */
export class OverallRiskService {
    /**
     * Returns the name of the risk based off number.
     * @param value
    */
    getRiskCategory(value: number, notStarted: boolean, riskRules?: RiskRules | undefined | null): OverallRiskCategory {

        if (notStarted) {
            return OverallRiskCategory.NotAssessed;
        }

        const riskRulesToUse = riskRules ?? defaultRiskRules;

        if (value < riskRulesToUse.highRiskBelow) {
            return OverallRiskCategory.High;
        } else if (value < riskRulesToUse.mediumRiskBelow) {
            return OverallRiskCategory.Medium;
        } else {
            return OverallRiskCategory.Low;
        }
    }

    /**
     * Returns the name of the risk based off the category (caluclating it first if needed).
     * @param value
    */
    getRiskName(category: OverallRiskCategory, riskRules?: RiskRules | undefined | null): string;
    getRiskName(value: number, riskRules?: RiskRules | undefined | null): string;
    getRiskName(categoryOrValue: OverallRiskCategory | number, riskRules?: RiskRules | undefined | null): string {
        const riskRulesToUse = riskRules ?? defaultRiskRules;

        // Get a risk category either by converting it from the number passed in, or by using the passed in category.
        let category: OverallRiskCategory;
        if (typeof categoryOrValue === 'number') {
            category = this.getRiskCategory(categoryOrValue, false, riskRulesToUse);
        } else {
            category = categoryOrValue as OverallRiskCategory;
        }

        switch (category) {
            case OverallRiskCategory.High:
                return i18n.t('getRiskName.high', 'High risk');
            case OverallRiskCategory.Medium:
                return i18n.t('getRiskName.medium', 'Medium risk');
            case OverallRiskCategory.Low:
                return i18n.t('getRiskName.low', 'Low risk');
            case OverallRiskCategory.NotAssessed:
                return i18n.t('getRiskName.notAssessed', 'Not assessed');
        }

        /* NOTREACHED */
    }


    getSpidergraphBackgroundColors(tickAmount: number, riskRules?: RiskRules | undefined | null): Array<string> {
        const riskRulesToUse = riskRules ?? defaultRiskRules;

        // Colors need to be built in reverse order so we can run red from the centre out to green.
        let colors: Array<string> = [];
        for (let i = 0; i < 100; i += tickAmount) {
            if (i < riskRulesToUse.highRiskBelow) {
                colors = [`rgba(248,108,107, 0.8)`, ...colors];
            } else if (i < riskRulesToUse.mediumRiskBelow) {
                colors = [`rgba(255,193,7, 0.7)`, ...colors];
            } else {
                colors = [`rgba(77,189,116, 0.6)`, ...colors];
            }
        }

        return colors;
    }
}

export const overallRiskService = new OverallRiskService();
