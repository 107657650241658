import { SubscriptionType } from "../../../../api/main/models/codeOnly/SubscriptionType";
import { SubscriptionsListBase } from "../../SubscriptionsListBase";
import { useTranslation } from "react-i18next";
import { DistributorAdministrationNavigation } from './DistributorAdministrationNavigation';

/**
 * List of actual subscriptions.
 */
export const DistributorSubscriptionsList = () => {
    const { t } = useTranslation();

    return (
        <SubscriptionsListBase
            title={t('subscriptionsList.heading', 'Subscriptions')}
            subscriptionTypes={[SubscriptionType.Subscription]}
            baseRoute={`/manage/distributor/subscriptions`}
            distributorSubscriptionList={true}
            navigationPills={<DistributorAdministrationNavigation/>}
        />
    );
};