import { LoadingIndicator } from '../shared/LoadingIndicator';
import { Background } from '../shared/background/Background';

export interface LoginWaitingProps {
    waiting: boolean,
    message: string
}

/**
 * Component that is shown during waiting of any login or logout options.
 * 
 * If you want to change the "waiting" screen between redirects for all login actions, this is where to do it.
 */
export const LoginWaiting = (props: LoginWaitingProps) => {
    const { waiting, message } = props;

    return (
        <Background>
            {/*<AlertOnErrors errors={errors} />*/}

            {
                waiting ? (
                    <>
                        <LoadingIndicator fullWidth />
                        <div className="sr-only">
                            {message}
                        </div>
                    </>
                ) : (
                        <>
                            {
                                !!message ? (<div>{message}</div>) : null
                            }
                        </>
                )
            }
        </Background>
        );
};