import gql from "graphql-tag";
import { userDriverMetricFields } from "../generated/userDriverMetricFields";

export const userDriverMetricFieldsFragment = gql`
    fragment userDriverMetricFields on UserDriverMetric {
        id
        subscriptionId
        userId
        driverMetricId
        currentValue
        archived
    }
`;


export type UserDriverMetric = Omit<userDriverMetricFields, '__typename'>;
