import { useMemo } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";
import { Button, ButtonGroup, Card, CardTitle, Col, Row } from "reactstrap";
import { Assessment } from "../../api/main/models/Assessment";
import { AssessmentSession } from "../../api/main/models/AssessmentSession";
import { AssessmentType } from "../../api/main/models/codeOnly/AssessmentType";
import { UserAssessmentAssignment } from "../../api/main/models/UserAssessmentAssignment";
import { IdentityRoles } from "../../configure/security/IdentityRoles";
import { AuthorizeContainer } from "../../shared/authorizeRoute";
import { HtmlDisplay } from "../../shared/htmlEditor";
import { ScoreProgressBar } from "../assessmentSessions/launch/progress/ScoreProgressBar";
import { questionScoreFromNumber } from "../assessmentSessions/launch/utilities/questionScoreFromNumber";
import "./dashboardAssessmentCard.scss";
import { DueDateLabel } from "./DueDateLabel";

export interface DashboardAssessmentCardProps {
    assessment: Assessment,
    assignment: UserAssessmentAssignment | undefined | null,
    session: AssessmentSession | undefined | null,
    canRetake?: boolean,

    inline?: boolean,
}

/**
 * Card for launching an assessment.
 * @param props
 */
export const DashboardAssessmentCard = (props: DashboardAssessmentCardProps) => {
    const {
        assessment,
        assignment,
        session,
        inline = false,
        canRetake = false,
    } = props;
    const { t } = useTranslation();

    const baseRoute = useMemo(() => {
        if (assessment.assessmentType === AssessmentType.TrainingModule) {
            return '/training';
        } else {
            return '/assessment';
        }
    }, [assessment.assessmentType]);

    // We position the date in a couple of places depending on if we are inline or not, so we generate it seperatly here for convienience.
    const DateLabelComponent = () => (
        <DueDateLabel dueDate={assignment?.targetDate} completedDate={session?.endDate} />
        );

    return (
        <Card body className={`dashboard-assessment-card ${inline ? 'dashboard-assessment-card-inline': ''}`}>
            <CardTitle tag="h5" className="dashboard-assessment-card-title">
                {assessment.name}
            </CardTitle>
            
            <div className="dashboard-assessment-card-description">
                <Row>
                    <Col xs={12} md="">
                        <HtmlDisplay html={assessment.tileText} textOnly />
                    </Col>
                    <ConditionalFragment showIf={!!session?.isFinished}>
                        <Col xs="auto">
                            <ScoreProgressBar value={questionScoreFromNumber(session?.totalScore ?? 0, false, assessment)} assessmentType={assessment.assessmentType as AssessmentType} />
                        </Col>
                    </ConditionalFragment>
                </Row>
            </div>

            <div className="dashboard-assessment-card-actions">
                <Row noGutters>
                    <ConditionalFragment showIf={!inline}>
                        <Col className="text-left mb-2">
                            <DateLabelComponent />
                        </Col>
                    </ConditionalFragment>
                    <Col className="text-right">
                        <ConditionalFragment showIf={!session}>
                            <LinkContainer to={`${baseRoute}/start/${assessment.id}`}>
                                <Button color="primary">
                                    {
                                        assessment.assessmentType === AssessmentType.TrainingModule ? t('dashboardAssessmentCard.start.trainingModule', 'Start training')
                                            : t('dashboardAssessmentCard.start.default', 'Start assessment')
                                    }
                                </Button>
                            </LinkContainer>
                        </ConditionalFragment>
                        <ConditionalFragment showIf={!!session && !session?.isFinished}>
                            <ButtonGroup>
                                <LinkContainer to={`${baseRoute}/launch/${session?.id ?? ''}`}>
                                    <Button color="primary">
                                        {
                                            assessment.assessmentType === AssessmentType.TrainingModule ? t('dashboardAssessmentCard.continue.trainingModule', 'Continue training')
                                                : t('dashboardAssessmentCard.continue.default', 'Continue assessment')
                                        }
                                    </Button>
                                </LinkContainer>
                                <AuthorizeContainer requireRole={IdentityRoles.Administration}>
                                    <LinkContainer to={`${baseRoute}/start/${assessment.id}`}>
                                        <Button color="primary" outline>
                                            {
                                                assessment.assessmentType === AssessmentType.TrainingModule ? t('dashboardAssessmentCard.restartInProgress.trainingModule', 'Restart')
                                                    : t('dashboardAssessmentCard.restartInProgress.default', 'Restart')
                                            }
                                        </Button>
                                    </LinkContainer>
                                </AuthorizeContainer>
                            </ButtonGroup>
                        </ConditionalFragment>
                        <ConditionalFragment showIf={!!session && !!session?.isFinished}>
                            <ButtonGroup>
                                <LinkContainer to={`${baseRoute}/results/${session?.id ?? ''}`}>
                                    <Button color="primary" outline>
                                        {
                                            assessment.assessmentType === AssessmentType.TrainingModule ? t('dashboardAssessmentCard.view.trainingModule', 'View results')
                                                : t('dashboardAssessmentCard.view.default', 'View results')
                                        }
                                    </Button>
                                </LinkContainer>
                                <ConditionalFragment showIf={canRetake}>
                                    <LinkContainer to={`${baseRoute}/start/${assessment.id}`}>
                                        <Button color="primary">
                                            {
                                                assessment.assessmentType === AssessmentType.TrainingModule ? t('dashboardAssessmentCard.restart.trainingModule', 'Restart training')
                                                    : t('dashboardAssessmentCard.restart.default', 'Retake assessment')
                                            }
                                        </Button>
                                    </LinkContainer>
                                </ConditionalFragment>
                            </ButtonGroup>
                        </ConditionalFragment>

                        {/* In inline mode we want to show the date under the buttons */}
                        <ConditionalFragment showIf={inline}>
                            <div className="text-center mt-4">
                                <DateLabelComponent />
                            </div>
                        </ConditionalFragment>
                    </Col>
                </Row>
            </div>
        </Card>
    );
};