import './mainContainer.scss'; 

import * as React from 'react';
import { Container, ContainerProps } from 'reactstrap';

export interface MainContainerProps extends ContainerProps {
    color?: 'main' | 'transparent',
}

/**
 * Main container that ensures 
 */
export const MainContainer = (props: MainContainerProps) => {
    const {
        className,
        children,
        color = 'main',
        ...rest } = props;

    return (
        <Container className={`main-container main-container-${color} ${className ?? ''}`} {...rest}>
            {children}
        </Container>
    );
};