import { getQuestionTypeCategory, QuestionType, QuestionTypeCategory } from "../../../../api/main/models/codeOnly/QuestionType";
import { Question } from "../../../../api/main/models/Question";
import { QuestionResponseEvent } from "../../../../api/main/models/QuestionResponseEvent";
import { overallRiskService, RiskRules } from "../../../../services/OverallRiskService";
import { calculateQuestionScore, QuestionScore } from "./calculateQuestionScore";
import { combineQuestionScores } from "./combineQuestionScores";
import { QuestionSequenceQuestion } from "../../../../api/main/models/QuestionSequenceQuestion";

export interface AssessmentScore extends QuestionScore {
    /**
     * Raw score calculation based on all questions across all metrics without doing any metric based grouping.
     * We may not use this anywhere but its useful for debugging.
     * NOTE score is based on combining the score per safety metric (excluding those that affect no safety metric).
     */
    rawScore: number,

    questionBreakdown: Array<AssessmentScoreQuestionBreakdown>,
    driverMetricBreakdown: Array<AssessmentScoreDriverMetricBreakdown>,
}

export interface AssessmentScoreQuestionBreakdown {
    questionId: string,
    subQuestionNumber: number,
    driverMetricId: string | null,
    score: QuestionScore,
}

export interface AssessmentScoreDriverMetricBreakdown {
    driverMetricId: string | null,
    score: QuestionScore,
}

/**
 * Calculate the question score for a question.  Result will always be between 0 and 100.
 */
export function calculateAssessmentScore(questions: Array<Question>, responses: Array<QuestionResponseEvent>, riskRules?: RiskRules | undefined | null, questionnaireSequenceQuestions?: Array<QuestionSequenceQuestion>,
    questionnaireQuestions?: Array<Question>): AssessmentScore
{
    // Work out the score for each question or question subpart.
    let questionScores: Array<AssessmentScoreQuestionBreakdown> = [];

    for (const question of questions) {
        // Skip non-scored questions.
        const questionTypeCategory = getQuestionTypeCategory(question.questionType as QuestionType);

        if (questionTypeCategory === QuestionTypeCategory.Question) {
            for (let subQuestionNumber = 1; subQuestionNumber <= question.numberOfSubQuestions; ++subQuestionNumber) {
                const myResponses = responses.filter(item => item.questionId === question.id && item.subQuestionNumber === subQuestionNumber && !item.archived);
                const score = calculateQuestionScore(myResponses, riskRules);
                questionScores.push({
                    questionId: question.id,
                    subQuestionNumber: subQuestionNumber,
                    driverMetricId: question.driverMetricId ?? null,
                    score,
                });
            }
        }
        else if (questionTypeCategory === QuestionTypeCategory.Questionnaire) {
            //needs to use the questionnaire question responses too so that when its calculating driver metrics it knows what scores to use.
            const currentSequenceQuestions = questionnaireSequenceQuestions?.filter(it => it.parentQuestionId === question.id && !it.archived);
            if (currentSequenceQuestions && questionnaireQuestions) {
                var subQuestionNumber = 1;
                for (const questionSequence of currentSequenceQuestions) {

                    const currentQuestion = questionnaireQuestions.find(it => it.id === questionSequence.childQuestionId);

                    if (currentQuestion) {

                        if (currentQuestion.questionType as QuestionType === QuestionType.QuestionnaireInput || subQuestionNumber > question.numberOfSubQuestions) {
                            subQuestionNumber = subQuestionNumber + 1;
                            continue;
                        }
                                const myResponses = responses.filter(item => item.questionId === currentQuestion.id && !item.archived);

                                const score = calculateQuestionScore(myResponses, riskRules);

                                questionScores.push({
                                    questionId: currentQuestion.id,
                                    subQuestionNumber: subQuestionNumber,
                                    driverMetricId: currentQuestion.driverMetricId ?? null,
                                    score,
                                });
                        subQuestionNumber = subQuestionNumber + 1;
                    }
                }

            }
        }
    }

    // Calculate the score per safety metric.
    let driverMetricScores: Array<AssessmentScoreDriverMetricBreakdown> = [];

    questions.forEach(item => {

        if (item.questionType === QuestionTypeCategory.Questionnaire) {

            const currentSequenceQuestions = questionnaireSequenceQuestions?.filter(it => it.parentQuestionId === item.id);

            if (currentSequenceQuestions && questionnaireQuestions) {

                for (const questionSequence of currentSequenceQuestions) {
                    const currentQuestion = questionnaireQuestions.find(it => it.id === questionSequence.childQuestionId);

                    if (currentQuestion?.driverMetricId) {
                        const myQuestionScores = questionScores.filter(it => it.driverMetricId === currentQuestion.driverMetricId).map(item => item.score);
                            const myScore = combineQuestionScores(myQuestionScores, riskRules);
                            driverMetricScores.push({
                                driverMetricId: currentQuestion.driverMetricId,
                                score: myScore,
                            });
                    }
                }
            }
        }

        else {
            // Use a set to get a distinct list of driver metrics used by the questions.
            const myQuestionScores = questionScores.filter(it => it.driverMetricId === item.driverMetricId).map(it => it.score);
                const myScore = combineQuestionScores(myQuestionScores, riskRules);
                driverMetricScores.push({
                    driverMetricId: item.driverMetricId,
                    score: myScore,
                });
        }
    });
    

    // Calculate the raw total score if we were treating all questions equally.
    let rawScore = 0;
    let questionPartCount = 0;
    for (const questionScore of questionScores) {
        rawScore += questionScore.score.score;
        ++questionPartCount;
    }

    let rawTotalScore = rawScore;
    if (questionPartCount > 0) {
        rawTotalScore = Math.round(rawScore / questionPartCount);
    }

    const rawRiskCategory = overallRiskService.getRiskCategory(rawTotalScore, false, riskRules);

    // Calculate the actual score, based on treating each safety metric equally (and ignoring questions not assigned to a safety metric).
    const metricTotalScore = combineQuestionScores(driverMetricScores.filter(it => it.driverMetricId !== null).map(it => it.score), riskRules);

    // Work out if we should be using the metric based total score or the raw total score.  We will always use the metric based score if the assessment
    // has one or more metrics in it, and the raw total score if it has no metrics in it.
    const isMetricBased = driverMetricScores.filter(it => it.driverMetricId !== null).length > 0;

    return {
        score: isMetricBased ? metricTotalScore.score : rawTotalScore,
        rawScore: rawTotalScore,
        minScore: 0,
        maxScore: 100,
        riskCategory: isMetricBased? metricTotalScore.riskCategory: rawRiskCategory,
        questionBreakdown: questionScores,
        driverMetricBreakdown: driverMetricScores,
    };
}