import { SubscriptionType } from "../../../api/main/models/codeOnly/SubscriptionType";
import { CreateSubscriptionBase, EditSubscriptionBase } from "../EditSubscriptionBase";

/**
 * Create an actual Distributor.
 */
export const CreateDistributor = () => (
    <CreateSubscriptionBase
        subscriptionType={SubscriptionType.Distributor}
        onCreateDefaultValues={() => ({ subscriptionType: SubscriptionType.Distributor })}
    />);

/**
 * Edit an actual Distributor.
 */
export const EditDistributor = () => (<EditSubscriptionBase subscriptionType={SubscriptionType.Distributor} />);