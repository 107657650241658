import { IdentityRoles } from "../../configure/security/IdentityRoles";
import { RouteEntry } from "../../shared/routes";
import { AdministrationAssessmentDashboard } from "./administrationDashboard/AdministrationAssessmentDashboard";
import { AdministrationDashboard } from "./administrationDashboard/AdministrationDashboard";
import { StaffUserDashboard } from "./administrationDashboard/StaffUserDashboard";
import { Dashboard } from "./dashboard/Dashboard";
import { SubscriptionAssessmentDashboard } from "./subscriptionDashboard/SubscriptionAssessmentDashboard";
import { SubscriptionDashboard } from "./subscriptionDashboard/SubscriptionDashboard";
import { SubscriptionTeamDashboard } from "./subscriptionDashboard/SubscriptionTeamDashboard";
import { SubscriptionUserDashboard } from "./subscriptionDashboard/SubscriptionUserDashboard";

export const dashboardRoutes: Array<RouteEntry> = [
    { path: '/dashboard', exact: true, component: Dashboard, authorize: true },
    { path: '/dashboard/team/:subscriptionTeamId', exact: true, component: SubscriptionTeamDashboard, authorize: true },
    { path: '/dashboard/user/:userId', exact: true, component: SubscriptionUserDashboard, authorize: true },
    { path: '/dashboard/assessment/:assessmentId', exact: true, component: SubscriptionAssessmentDashboard, authorize: true },
    { path: '/dashboard/training/:assessmentId', exact: true, component: SubscriptionAssessmentDashboard, authorize: true },

    { path: '/administration/dashboard', exact: true, component: AdministrationDashboard, authorize: true, requireRole: IdentityRoles.Administration, },
    { path: '/administration/dashboard/subscription/:subscriptionId', exact: true, component: SubscriptionDashboard, authorize: true, requireRole: IdentityRoles.Administration, },
    { path: '/administration/dashboard/subscription/:subscriptionId/team/:subscriptionTeamId', exact: true, component: SubscriptionTeamDashboard, authorize: true, requireRole: IdentityRoles.Administration, },
    { path: '/administration/dashboard/subscription/:subscriptionId/user/:userId', exact: true, component: SubscriptionUserDashboard, authorize: true, requireRole: IdentityRoles.Administration, },
    { path: '/administration/dashboard/staff/:userId', exact: true, component: StaffUserDashboard, authorize: true, requireRole: IdentityRoles.Administration, },
    { path: '/administration/dashboard/assessment/:assessmentId', exact: true, component: AdministrationAssessmentDashboard, authorize: true, requireRole: IdentityRoles.Administration, },
    { path: '/administration/dashboard/training/:assessmentId', exact: true, component: AdministrationAssessmentDashboard, authorize: true, requireRole: IdentityRoles.Administration, },
    { path: '/administration/dashboard/subscription/:subscriptionId/assessment/:assessmentId', exact: true, component: SubscriptionAssessmentDashboard, authorize: true, requireRole: IdentityRoles.Administration, },
    { path: '/administration/dashboard/subscription/:subscriptionId/training/:assessmentId', exact: true, component: SubscriptionAssessmentDashboard, authorize: true, requireRole: IdentityRoles.Administration, },
];
