import { useLocation } from "react-router";

/**
 * Hook that returns the right base route to use when navigating around the dashboard by working out if we're under the administrator dashboard
 * or not.
 */
export function useDashboardBaseRoute() {
    const location = useLocation();

    // Things under the admin dashboard subscription navigation will all be under locations starting with the same path including the subscriptionId
    // as that is needed to be able to load the data exactly as the subscription would see it.
    const administrationSubscriptionPrefix = '/administration/dashboard/subscription/';
    if (location.pathname.startsWith(administrationSubscriptionPrefix)) {
        const nextSlash = location.pathname.indexOf('/', administrationSubscriptionPrefix.length);
        if (nextSlash === -1) {
            return location.pathname;
        }

        return location.pathname.substring(0, nextSlash);
    }

    // Things under the admin dashboard will all be under locations starting with the same path.
    if (location.pathname.startsWith('/administration/dashboard')) {
        return '/administration/dashboard';
    }

    // Otherwise use the primary dashboard.
    return '/dashboard';
}