import { Redirect } from 'react-router';

/**
 * Default Administration home page.
 */
export const AdministrationHome = () => {
    return (
        <>
            <Redirect to="/administration/dashboard" />
        </>
        );
};