import * as React from 'react';
import { atom, useRecoilState } from 'recoil';
import { CardsOrTableViewMode } from '../../components/shared/cardsOrTable/CardsOrTable';
import { persistAtom } from '../../configure/recoilPersistConfig';

export const preferredListViewModeState = atom<CardsOrTableViewMode>({
    key: 'preferredListViewModeState',
    default: 'cards',
    effects_UNSTABLE: [persistAtom],
    
});

/**
 * Hook that returns the users preferred view mode for list views so that changing it once preserves it across all lists until changed back.
 */
export function usePreferredListViewMode(): [CardsOrTableViewMode, React.Dispatch<React.SetStateAction<CardsOrTableViewMode>>] {
    const [value, setValue] = useRecoilState(preferredListViewModeState);
    return [value, setValue];
}