import { Button, Row, Col, Form, Label, FormGroup, Spinner, Input } from 'reactstrap';
import { AlertOnErrors } from '../../../shared/alertOnErrors';
import { LoadingIndicator } from '../../shared/LoadingIndicator';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContainer } from '../../shared/MainContainer';
import { useParams, useHistory } from 'react-router';
import { useSdkClient } from '../../../api/main/sdkClients/useSdkClient';
import { useChanges } from '../../../shared/useChanges';
import { useSaveSdkClientCallback } from '../../../api/main/sdkClients/useSaveSdkClientCallback';
import { useValidatorCallback } from 'pojo-validator-react';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { FormButtons } from '../../shared/FormButtons';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { useAsyncCallback } from 'react-use-async-callback';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Banner } from '../../shared/Banner';
import { Background } from '../../shared/background/Background';
import { useEffect } from 'react';
import { useJsonObject } from '../../../shared/useJsonObject';
import { SecretInput } from '../../shared/secretInput/SecretInput';
import { SdkClient, sdkClientDefaultValues } from '../../../api/main/models/SdkClient';
import { useCurrentUserOrEmulatedSubscriptionId } from '../../../globalState/subscriptions/useCurrentUserOrEmulatedSubscriptionId';
import { ApiKeyGenerationService } from '../../../services/ApiKeyGenerationService';

interface EditSdkClientProps {
    isCreate?: boolean,
}

/**
 * Create a new sdkClient.
 */
export const CreateSdkClient = () => (<EditSdkClient isCreate={true} />);

/**
 * Edit a SdkClient.
 */
export const EditSdkClient = (props: EditSdkClientProps) => {
    const { isCreate } = props;

    const { t } = useTranslation();
    const { id } = useParams<{ id: string | undefined }>();
    const { data: { model: storeModel }, isLoading: _isLoading, errors: loadErrors } = useSdkClient(id);
    const isLoading = _isLoading;
    const { model, change, changes } = useChanges(storeModel, isCreate ? { ...sdkClientDefaultValues(), } : undefined);
    const [save, { errors: saveErrors }] = useSaveSdkClientCallback();
    const history = useHistory();

    // When we get the current subscription, set it in the SdkClient (if we are a create) and also at the same time generate a unique API key.
    const subscriptionId = useCurrentUserOrEmulatedSubscriptionId();
    useEffect(() => {
        if (!model) {
            return;
        }

        // Build a list of defaults we need to set.
        let changes: Partial<SdkClient> | undefined = undefined;

        // If we haven't set a target, set it to the subscription if we have one.
        if (!model.targetId && !!subscriptionId) {
            changes = {
                ...(changes ?? {}),
                targetId: subscriptionId,
                targetType: 'SubscriptionId',
            };
        }

        // If we don't have an API key secret, generate one.
        if (!model.apiSecret && !!model.id) {
            const service = new ApiKeyGenerationService();
            const secret = service.generateKey(model.id);

            changes = {
                ...(changes ?? {}),
                apiSecret: secret,
            };
        }

        // Apply the changes to the model.
        if (changes) {
            change(changes);
        }
    }, [subscriptionId, change, model]);

    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            name: () => !model?.name ? t('editSdkClient.nameRequired', 'Name is required') : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, [model]);

    const [saveForm, { isExecuting: isSaving, errors: saveFormErrors }] = useAsyncCallback(async () => {
        if (!validate()) {
            return;
        }

        await save(model.id, changes, !!isCreate);

        history.goBack();
    }, [validate, save, model, changes, isCreate, history]);

    // Maintenance of ip addresses (becomes JSON in the model.)
    const [ipAddresses, setIpAddresses] = useJsonObject<Array<string>>(model?.ipAllowListJson ?? '[]', json => change({ ipAllowListJson: json }))

    return (
        <Background>
            <Banner>
                <Row>
                    <Col>
                        <h1>
                            {
                                isCreate ? (
                                    <>{t('editSdkClient.createHeading', 'Add application API key')}</>
                                ) : (
                                        <>{t('editSdkClient.editHeading', 'Edit application API key')}</>
                                    )
                            }
                        </h1>
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
            </Banner>

            <MainContainer>
                <AlertOnErrors errors={[loadErrors, saveFormErrors, saveErrors]} />

                <Form onSubmit={e => { e.preventDefault(); saveForm(); }}>
                    <FormGroup>
                        <Label htmlFor="name">{t('editSdkClient.name.label', 'Name')}</Label>
                        <ValidatedInput name="name" type="text" value={model.name ?? ''} onChange={e => change({ name: e.currentTarget.value })} onBlur={e => validate('name')} validationErrors={validationErrors['name']} />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="apiSecret">{t('editSdkClient.apiSecret.label', 'API key')}</Label>
                        <SecretInput name="apiSecret" type="text" value={model.apiSecret ?? ''} />
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="apiSecret">{t('editSdkClient.ip.label', 'Allowed IP addresses')}</Label>

                        <div>
                            {
                                ipAddresses.map((item, index) => (
                                    <div key={index} className="mb-2">
                                        <Row>
                                            <Col>
                                                <Input type="text" value={item}
                                                    onChange={e => {
                                                        // Edit the right index in the array.
                                                        setIpAddresses(prevState => {
                                                            let newValue = [...prevState];
                                                            newValue[index] = e.currentTarget.value;
                                                            return newValue;
                                                        })
                                                    }}
                                                />
                                            </Col>
                                            <Col>
                                                <Button color="danger" outline onClick={() => {
                                                    // Remove the item at index from the array (will reindex everything else to fill the space).
                                                    setIpAddresses(prevState => prevState.filter((_, itemIndex) => itemIndex !== index));
                                                }}>
                                                    <FontAwesomeIcon icon="trash-alt" />
                                                    <span className="sr-only">
                                                        {t('editSdkClient.ip.remove', 'Remove IP address')}
                                                    </span>
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                    ))
                            }
                        </div>
                        <div className="mt-2">
                            <Button color="primary" outline onClick={() => {
                                // Add a blank entry to the end of the array.
                                setIpAddresses(prevState => [...prevState, '']);
                            }}>
                                <FontAwesomeIcon icon="plus" />
                                <> </>
                                {t('editSdkClient.ip.add', ' Add IP address')}
                            </Button>
                        </div>
                    </FormGroup>

                    <FormButtons>
                        <ConditionalFragment showIf={!isLoading}>
                            <ButtonAsync color="primary" isExecuting={isSaving}
                                executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}>
                                <FontAwesomeIcon icon="save" />
                                <> {t('common.save', 'Save')}</>
                            </ButtonAsync>
                        </ConditionalFragment>
                        <Button type="button" color="primary" outline onClick={e => history.goBack()}>
                            {t('common.cancel', 'Cancel')}
                        </Button>
                    </FormButtons>
                </Form>
            </MainContainer>
        </Background>
    );
};
