import { SubscriptionType } from "../../../../api/main/models/codeOnly/SubscriptionType";
import { CreateSubscriptionBase, EditSubscriptionBase } from "../../EditSubscriptionBase";

/**
 * Create an actual Distributor.
 */
export const CreateDistributorSubscription = () => (
    <CreateSubscriptionBase
        subscriptionType={SubscriptionType.Subscription}
        onCreateDefaultValues={() => ({ subscriptionType: SubscriptionType.Subscription })}
        isInDistributor={true}
    />);

/**
 * Edit an actual Distributor.
 */
export const EditDistributorSubscription = () => (<EditSubscriptionBase subscriptionType={SubscriptionType.Subscription} isInDistributor={true} />);