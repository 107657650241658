import { NavItem, NavLink } from 'reactstrap';
import { AuthorizeContainer } from '../../shared/authorizeRoute';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLinkTrackActive } from '../shared/NavLinkTrackActive';
import { IdentityRoles } from '../../configure/security/IdentityRoles';
import { SidebarNavTitle } from '../../shared/sidebarNav';

/**
 * Administration navigation menu for the app. 
 */
export const AdministrationNavigation = () => {
    const { t } = useTranslation();

    return (
        <>
            <AuthorizeContainer requireRole={IdentityRoles.Administration}>
                <SidebarNavTitle>
                    {t('administrationNavigation.dashboardTitle', 'Esitu Dashboard')}
                </SidebarNavTitle>
                <NavItem>
                    <NavLink tag={NavLinkTrackActive} to="/administration/dashboard">
                        <FontAwesomeIcon icon="solar-panel" className="nav-icon" />
                        <> {t('administrationNavigation.dashboard', 'Esitu dashboard')}</>
                    </NavLink>
                </NavItem>

                <SidebarNavTitle>
                    {t('administrationNavigation.subscriptionsTitle', 'Subscriptions')}
                </SidebarNavTitle>
                <NavItem>
                    <NavLink tag={NavLinkTrackActive} to="/administration/subscriptions">
                        <FontAwesomeIcon icon="building" className="nav-icon" />
                        <> {t('administrationNavigation.subscriptions', 'Subscriptions')}</>
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={NavLinkTrackActive} to="/administration/distributors">
                        <FontAwesomeIcon icon="shipping-fast" className="nav-icon" />
                        <> {t('administrationNavigation.distributors', 'Distributors')}</>
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={NavLinkTrackActive} to="/administration/studies">
                        <FontAwesomeIcon icon="pen" className="nav-icon" />
                        <> {t('administrationNavigation.studies', 'Studies')}</>
                    </NavLink>
                </NavItem>

                <SidebarNavTitle>
                    {t('administrationNavigation.assessmentsTitle', 'Assessments')}
                </SidebarNavTitle>
                <NavItem>
                    <NavLink tag={NavLinkTrackActive} to="/administration/assessments">
                        <FontAwesomeIcon icon="clipboard-check" className="nav-icon" />
                        <> {t('administrationNavigation.assessments', 'Assessments')}</>
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={NavLinkTrackActive} to="/administration/questions">
                        <FontAwesomeIcon icon="car-crash" className="nav-icon" />
                        <> {t('administrationNavigation.questions', 'Questions')}</>
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={NavLinkTrackActive} to="/administration/information-pages">
                        <FontAwesomeIcon icon="book" className="nav-icon" />
                        <> {t('administrationNavigation.informationPages', 'Information pages')}</>
                    </NavLink>
                </NavItem>

                <SidebarNavTitle>
                    {t('administrationNavigation.psycoMetricsTitle', 'Psychometrics')}
                </SidebarNavTitle>
                <NavItem>
                    <NavLink tag={NavLinkTrackActive} to="/administration/questionnaires">
                        <FontAwesomeIcon icon="meh" className="nav-icon" />
                        <> {t('administrationNavigation.questionnaires', 'Questionnaires')}</>
                    </NavLink>
                </NavItem>

                <SidebarNavTitle>
                    {t('administrationNavigation.learningTitle', 'Training')}
                </SidebarNavTitle>
                <NavItem>
                    <NavLink tag={NavLinkTrackActive} to="/administration/training">
                        <FontAwesomeIcon icon="graduation-cap" className="nav-icon" />
                        <> {t('administrationNavigation.trainingModules', 'Training modules')}</>
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={NavLinkTrackActive} to="/administration/learning-activities">
                        <FontAwesomeIcon icon="chalkboard-teacher" className="nav-icon" />
                        <> {t('administrationNavigation.learningActivities', 'Learning activities')}</>
                    </NavLink>
                </NavItem>

                <SidebarNavTitle>
                    {t('administrationNavigation.tagsTitle', 'Metrics and tag')}
                </SidebarNavTitle>
                <NavItem>
                    <NavLink tag={NavLinkTrackActive} to="/administration/safety-metrics">
                        <FontAwesomeIcon icon="user-tag" className="nav-icon" />
                        <> {t('administrationNavigation.driverMetrics', 'Safety metrics')}</>
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={NavLinkTrackActive} to="/administration/tags">
                        <FontAwesomeIcon icon="tags" className="nav-icon" />
                        <> {t('administrationNavigation.tags', 'Tags')}</>
                    </NavLink>
                </NavItem>

                <SidebarNavTitle>
                    {t('administrationNavigation.usersTitle', 'User management')}
                </SidebarNavTitle>

                <NavItem>
                    <NavLink to="/administration/administrators" tag={NavLinkTrackActive}>
                        <FontAwesomeIcon icon="user-shield" className="nav-icon" />
                        <> {t('administrationNavigation.administrators', 'Esitu staff')}</>
                    </NavLink>
                </NavItem>

                <SidebarNavTitle>
                    {t('administrationNavigation.exportsTitle', 'Exports and analysis')}
                </SidebarNavTitle>
                <NavItem>
                    <a className="nav-link" href="/api/export/responseRawData" download>
                        <FontAwesomeIcon icon="cloud-download-alt" className="nav-icon" />
                        <> {t('administrationNavigation.events', 'Export event data')}</>
                    </a>
                </NavItem>
            </AuthorizeContainer>
        </>
    );
};
