import './sidebarRequired.scss';

import * as React from 'react';
import { Helmet } from 'react-helmet-async';

export interface SidebarRequiredProps {
    children?: React.ReactNode,
}

/**
 * Component that shows the sidebr if it is required/recommended for the screensize.
 */
export const SidebarRequired = (props: SidebarRequiredProps) => {
    const { children } = props;

    return (
        <>
            <Helmet>
                <body data-sidebar-required="true" />
            </Helmet>
            {children}
        </>
    );
};
