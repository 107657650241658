import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { QuestionSequenceQuestionUpdateInput } from '../generated/globalTypes';
import { updateQuestionSequenceQuestionMutation, updateQuestionSequenceQuestionMutationVariables } from '../generated/updateQuestionSequenceQuestionMutation';
import { questionSequenceQuestionFieldsFragment } from '../models/QuestionSequenceQuestion';

/**
 * Get a callback to update a QuestionSequenceQuestion in the store.
 */
export function useUpdateQuestionSequenceQuestionCallback(): [(id: string, model: QuestionSequenceQuestionUpdateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<updateQuestionSequenceQuestionMutation, updateQuestionSequenceQuestionMutationVariables>(
        gql`
            mutation updateQuestionSequenceQuestionMutation ($model: QuestionSequenceQuestionUpdateInput!) {
                updateQuestionSequenceQuestion(model: $model) {
                    ...questionSequenceQuestionFields
                }
            }

            ${questionSequenceQuestionFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (id: string, model: QuestionSequenceQuestionUpdateInput) => {
        const result = await mutationAction({ variables: { model: { ...model, id: id } } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
