import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ValidationErrors } from "pojo-validator";
import { ValidateCallback } from "pojo-validator-react";
import { ValidatedInput } from "pojo-validator-reactstrap";
import { useCallback } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { useAsyncCallback } from "react-use-async-callback";
import { Button, ButtonGroup, Card, CardBody, Col, Collapse, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, FormText, InputGroup, InputGroupAddon, Label, Row, Spinner} from "reactstrap";
import { useToggleState } from "use-toggle-state";
import { BlobReference } from "../../../../api/main/models/BlobReference";
import { QuestionAnswer } from "../../../../api/main/models/QuestionAnswer";
import { HtmlDisplay, HtmlEditor } from "../../../../shared/htmlEditor";
import { CardHeaderCollapse } from "../../../shared/cardHeaderCollapse/CardHeaderCollapse";
import { FileUploadButton } from "../../../shared/fileUploadButton/FileUploadButton";
import { FormButtons } from "../../../shared/FormButtons";
import { TwoValueSwitch } from "../../../shared/TwoValueSwitch";
import { UploadedImagePreview } from "../../../shared/uploadedImagePreview/UploadedImagePreview";

export interface QuestionAnswerMultipleChoiceVideoProps {
    model: QuestionAnswer | undefined,
    change: (changes: Partial<QuestionAnswer>) => void,
    remove: () => void,
    moveUp: () => void,
    canMoveUp: boolean,
    moveDown: () => void,
    canMoveDown: boolean,

    answerTextImageBlob: BlobReference | undefined | null,
    uploadChildBlob: (files: FileList | null) => Promise<BlobReference | null>,

    isOpenInitially?: boolean,

    validate: ValidateCallback,
    validationErrors: ValidationErrors,
}

/**
 * Tab for maintaining the prompt for the question and the available multiple choice answers.
 * @param props
 */
export const QuestionAnswerMultipleChoiceVideo = (props: QuestionAnswerMultipleChoiceVideoProps) => {
    const {
        model,
        change,
        remove,
        moveUp,
        canMoveUp,
        moveDown,
        canMoveDown,

        answerTextImageBlob,
        uploadChildBlob,
        isOpenInitially = false,

        validate,
        validationErrors,
    } = props;

    const { t } = useTranslation();

    // Self management of if we are expanded or not.
    const [isOpen, toggleOpen] = useToggleState(isOpenInitially);

    // Uploading the answer text blob.
    const [uploadAnswerTextImageBlob, { isExecuting: isUploadingAnswerTextImageBlob }] = useAsyncCallback(async (files: FileList | null) => {
        const blob = await uploadChildBlob(files);
        if (!blob) {
            return;
        }

        change({ answerTextImageBlobReferenceId: blob.id });
    }, [uploadChildBlob, change]);
    const clearAnswerTextImageBlob = useCallback(() => {
        change({ answerTextImageBlobReferenceId: null });
    }, [change]);
    const [isAnswerImageDropdownOpen, toggleAnswerImageDropdown] = useToggleState();

    return (
        <Card>
            <CardHeaderCollapse isOpen={isOpen} toggle={toggleOpen}>
                <div style={{ maxHeight: '2rem', overflowY: 'hidden', }}>
                    <HtmlDisplay html={model?.answerText ?? ''} />
                </div>
            </CardHeaderCollapse>

            <Collapse isOpen={isOpen}>
                <CardBody>
                    <FormButtons noPadding>
                        <ButtonGroup>
                            <Button color="primary" outline onClick={() => moveUp()} disabled={!canMoveUp}>
                                <FontAwesomeIcon icon="caret-up" />
                                <> </>
                                {t('questionAnswerMultipleChoiceVideo.up', 'Up')}
                            </Button>
                            <Button color="primary" outline onClick={() => moveDown()} disabled={!canMoveDown}>
                                <FontAwesomeIcon icon="caret-down" />
                                <> </>
                                {t('questionAnswerMultipleChoiceVideo.down', 'Down')}
                            </Button>
                            <Button color="danger" outline onClick={() => remove()}>
                                <FontAwesomeIcon icon="trash-alt" />
                                <> </>
                                {t('questionAnswerMultipleChoiceVideo.delete', 'Delete answer')}
                            </Button>
                        </ButtonGroup>
                    </FormButtons>

                    <FormGroup>
                        <Label htmlFor="answerText">{t('questionAnswerMultipleChoiceVideo.answerText', 'Answer')}</Label>
                        <HtmlEditor value={model?.answerText ?? ''} onChange={html => change({ answerText: html })} />
                    </FormGroup>

                    <Row>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="isCorrect">{t('questionAnswerMultipleChoiceVideo.isCorrect.label', 'Is this a correct answer?')}</Label>

                                <TwoValueSwitch
                                    leftLabel={t('questionAnswerMultipleChoiceVideo.isCorrect.false', 'Incorrect')}
                                    rightLabel={t('questionAnswerMultipleChoiceVideo.isCorrect.true', 'Correct')}
                                    checked={model?.isCorrect ?? false} onChange={checked => {
                                        // Toggle the flag, and if we're using default scoring at the moment, also default the score.
                                        if (checked && model?.score === 0) {
                                            change({ isCorrect: checked, score: 100 });
                                        } else if (!checked && model?.score === 100) {
                                            change({ isCorrect: checked, score: 0 });
                                        } else {
                                            change({ isCorrect: checked });
                                        }
                                    }}
                                    />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="score">{t('questionAnswerMultipleChoiceVideo.score.label', 'Score')}</Label>

                                <InputGroup>
                                    <ValidatedInput name="score" type="number" min={-100} max={100} value={model?.score ?? ''} onChange={e => change({ score: e.currentTarget.valueAsNumber })} onBlur={() => validate('score')} validationErrors={validationErrors['score']} />
                                    <InputGroupAddon addonType="append">
                                        {t('common.percentageSign', '%')}
                                    </InputGroupAddon>
                                </InputGroup>
                                <FormText>
                                    {t('questionAnswerMultipleChoiceVideo.score.help', 'Score will normally be between 0% (incorrect) and 100% (correct).')}
                                </FormText>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="isSuspicious">{t('questionAnswerMultipleChoiceVideo.isSuspicious.label', 'Is this a potentially suspicious answer?')}</Label>

                                <TwoValueSwitch
                                    leftLabel={t('questionAnswerMultipleChoiceVideo.isSuspicious.false', 'No')}
                                    rightLabel={t('questionAnswerMultipleChoiceVideo.isSuspicious.true', 'Yes')}
                                    checked={model?.isSuspicious ?? false} onChange={checked => {
                                        // Toggle the flag
                                        change({ isSuspicious: checked });
                                        if (checked) {
                                            change({ suspicionScore: 1 }); // If we are setting the answer to suspicious, we want to have a suspicion score, so setting this to 1 as default.
                                        } else {
                                            change({ suspicionScore: undefined }); // If we are setting the answer to not suspicious, we no longer need a suspicion score, so we can set this to undefined.
                                        }
                                    
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <ConditionalFragment showIf={model?.isSuspicious ?? false}>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="score">{t('questionAnswerMultipleChoiceVideo.suspicionScore.label', 'Suspicion Score')}</Label>

                                <InputGroup>
                                    <ValidatedInput name="score" type="number" min={1} value={model?.suspicionScore ?? ''} onChange={e => change({ suspicionScore: e.currentTarget.valueAsNumber })} onBlur={() => validate('suspicionScore')} validationErrors={validationErrors['suspicionScore']} />
                                </InputGroup>
                                <FormText>
                                    {t('questionAnswerMultipleChoiceVideo.suspicionScore.help', 'At the end of an assessment, any selected suspicious answers will have their suspicion scores added together to see if they exceed the limit set on the assessment.')}
                                </FormText>
                            </FormGroup>
                        </Col>
                        </ConditionalFragment>
                    </Row>


                    <FormGroup>
                        <Label htmlFor="answerTextImageId">{t('questionAnswerMultipleChoiceVideo.answerTextImageId', 'Image to show with the answer')}</Label>

                        <UploadedImagePreview src={answerTextImageBlob?.url ?? ''} />

                        <ButtonGroup>
                            <FileUploadButton onUpload={files => uploadAnswerTextImageBlob(files)} isExecuting={isUploadingAnswerTextImageBlob}
                                executingChildren={<><Spinner size="sm" /><> </>{t('common.uploading', 'Uploading...')}</>}
                            >
                                {t('questionAnswerMultipleChoiceVideo.uploadImage', 'Upload image...')}
                            </FileUploadButton>
                            <Dropdown isOpen={isAnswerImageDropdownOpen} toggle={() => toggleAnswerImageDropdown()}>
                                <DropdownToggle color="primary" outline caret>
                                    <span className="sr-only">
                                        {t('common.more', 'More')}
                                    </span>
                                </DropdownToggle>
                                <DropdownMenu right>
                                    <DropdownItem className="text-danger" onClick={() => clearAnswerTextImageBlob()}>
                                        {t('questionAnswerMultipleChoiceVideo.clearImage', 'Clear image')}
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </ButtonGroup>
                    </FormGroup>
                </CardBody>
            </Collapse>
        </Card>
        );
};