import { ApexOptions }from "apexcharts";
import Chart from "react-apexcharts";
import { useMemo } from "react";
import { Card } from "reactstrap";
import { ChartDataSeries } from "../chartDataUtilities/ChartData";
import { chartDataConverters } from "../chartDataUtilities/ChartDataConverter";
import { useTranslation } from "react-i18next";
import { AssessmentType } from "../../../api/main/models/codeOnly/AssessmentType";
import { garChartColours } from "./dashboardChartColors";

export interface CompletionBarChartProps {
    data: Array<ChartDataSeries>,
    assessmentType: AssessmentType,

    width?: string | number | undefined,
    height?: string | number | undefined,
}

/**
 * Chart that shows completion status totals (not started/started/completed) as a bar chart.
 */
export const CompletionBarChart = (props: CompletionBarChartProps) => {
    const {
        data,
        assessmentType,
        width,
        height,
    } = props;

    const { t } = useTranslation();

    // Convert data into format needed for this graph.
    const { series, options: seriesOptions } = useMemo(() => chartDataConverters.toColumn(data, { maxTextLength: 20 }), [data]);

    // Compile all the options we want to use.
    const options = useMemo(() => (
        {
            chart: {
                toolbar: {
                    show: false,
                },

                stacked: true,
            },

            title: {
                text:
                    assessmentType === AssessmentType.TrainingModule ? t('completionBarChart.title.training', 'Training progress')
                        : t('completionBarChart.title.assessments', 'Assessment progress'),
                align: 'center',
            },

            //plotOptions: {
            //    bar: {
            //        borderRadius: 10,
            //    },
            //},

            colors: garChartColours,

            ...seriesOptions,
        } as ApexOptions
    ), [seriesOptions, t, assessmentType]);

    // We need to force the refresh of the chart sometimes by updating its key as its too optomistic with its internal caching and
    // so doesn't end up changes top options very well at all if we dont.
    const key = useMemo(() => JSON.stringify(options), [options]);

    return (
        <Card body tag="div">
            <Chart key={key} options={options} series={series} type="bar" width={width} height={height} />
        </Card>
    );
};