// Red, Amber, Green chart colours (Red first).
export const ragChartColours = [
    '#f86c6b',
    '#ffc107',
    '#4dbd74',
];

// Green, Amber, Red chart colours (Green first).
export const garChartColours = [
    '#4dbd74',
    '#ffc107',
    '#f86c6b',
];

// Consistant colouring for assessments and training,
export const assessmentsChartColor = '#ec0b62'; // Esitu pink
export const trainingChartColor = "#2f5597"; // Esitu dark blue