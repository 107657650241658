import { useServices } from "inject-typesafe-react";
import { useAsyncCallback } from "react-use-async-callback";
import { AppServices } from "../../../configure/configureServices";
import { AsyncActionStatus } from "../../../shared/abstractStore";

/*
 * Get a callback to send a reminder to all users on a team to complete a Assessment.
 */
export function useSendAllAssessmentReminderCallback(): [(assessmentId: string, subscriptionTeamId: string) => Promise<void>, AsyncActionStatus]
{
    var api = useServices((services: AppServices) => services.apiFetch());
    //callback to get the AssessmentID 
    const [callback, status] = useAsyncCallback(async (assessmentId: string, subscriptionTeamId: string) => {
        await api.post(`/api/reminders/sendAllReminder`, { assessmentId: assessmentId, subscriptionTeamId: subscriptionTeamId });
    }, [api]);

    return [callback, status];
}