import { useCallback, useMemo } from "react";
import { AssessmentType } from "../../../api/main/models/codeOnly/AssessmentType";
import { DashboardSummary } from "./generateDashboardSummary";

export type GetCachedDashboardSummary = (id: string, asessmentType: AssessmentType) => DashboardSummary;

/**
 * Hook that returns caches the dashboard summaries for easy lookup by group.
 */
export function useCachedDashboardSummaries(
    groups: Array<{ id: string }>,
    calculateDashboardSummary: (
        group: { id: string },
        assessmentType: AssessmentType
    ) => DashboardSummary
): GetCachedDashboardSummary
{
    // Generate the summary data for each subscription.
    const cachedDashboardSummaries = useMemo(() =>
        groups.map(group => {
            return ({
                id: group.id,
                assessmentSummaryData: calculateDashboardSummary(group, AssessmentType.Assessment),
                trainingSummaryData: calculateDashboardSummary(group, AssessmentType.TrainingModule),
            });
        })
        , [calculateDashboardSummary, groups]);

    // Returns the dashboard summary by id from our cached values.
    const getCachedDashboardSummary = useCallback((id: string, assessmentType: AssessmentType) => {
        const cachedData = cachedDashboardSummaries.find(it => it.id === id);
        if (assessmentType === AssessmentType.TrainingModule) {
            return cachedData?.trainingSummaryData as DashboardSummary;
        }

        return cachedData?.assessmentSummaryData as DashboardSummary;
    }, [cachedDashboardSummaries]);

    return getCachedDashboardSummary;
}