import './tagDisplay.scss';

import * as React from 'react';

export interface FormButtonsProps {
    className?: string,
    noPadding?: boolean,
    children: React.ReactNode
}

/**
 * Container for buttons (e.g. sumbit) at the bottom of a form. 
 */
export const TagDisplay = (props: FormButtonsProps) => {
    const { className, children, noPadding = false } = props;

    return (
        <div className={`form-buttons ${noPadding ? 'form-buttons-no-padding' : ''} ${className ?? ''}`}>
            {children}
        </div>
    );
};