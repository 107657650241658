import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { LinkContainer } from "react-router-bootstrap";
import { Button, ButtonDropdown, ButtonGroup, DropdownItem, DropdownMenu, DropdownToggle, Table } from "reactstrap";
import { useToggleStateArray } from "use-toggle-state";
import { SdkClient } from "../../../api/main/models/SdkClient";
import { NoResultsFound } from "../../shared/NoResultsFound";
import { SecretInput } from "../../shared/secretInput/SecretInput";
import { TableRowButtons } from "../../shared/TableRowButtons";

export interface SdkClientsTableProps {
    items: Array<SdkClient> | undefined,
    isLoading: boolean,
}

/**
 * Table that shows the loaded SdkClients.
 */
export const SdkClientsTable = (props: SdkClientsTableProps) => {
    const {
        items,
        isLoading,
    } = props;

    const { t } = useTranslation();
    const history = useHistory();

    // Handle the dropdown of the menus..
    const [isMenuOpen, toggleMenuOpen] = useToggleStateArray<string>([]);


    // Render the UI.
    //
    return (
        <>
            <Table responsive striped>
                <thead>
                    <tr>
                        <th>
                            {t('sdkClientsTable.table.headings.name', 'Application')}
                        </th>
                        <th>
                            {t('sdkClientsTable.table.headings.secret', 'API key')}
                        </th>
                        <th>
                            {t('sdkClientsTable.table.headings.ipAddresses', 'Allowed IP addresses')}
                        </th>
                        <th>&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        items?.map((item) => (
                            <tr key={item.id} onDoubleClick={() => { history.push(`/manage/sdk/applications/edit/${item.id}`); }}>
                                <td>
                                    {item.name}
                                </td>
                                <td>
                                    <SecretInput plaintext value={item.apiSecret} />
                                </td>
                                <td>
                                    {
                                        (JSON.parse(item.ipAllowListJson) as Array<string>).map((item, index) => (
                                            <div key={index}>
                                                {item}
                                            </div>
                                            ))
                                    }
                                </td>

                                <td>
                                    <TableRowButtons>
                                        <ButtonGroup>
                                            <LinkContainer to={`/manage/sdk/applications/edit/${item.id}`}>
                                                <Button color="primary" outline>
                                                    <FontAwesomeIcon icon="edit" />
                                                    <> {t('common.edit', 'Edit')}</>
                                                </Button>
                                            </LinkContainer>
                                            <ButtonDropdown isOpen={isMenuOpen(item.id)} toggle={() => toggleMenuOpen(item.id)}>
                                                <DropdownToggle color="primary" outline caret>
                                                    <span className="sr-only">{t('common.menuDropdown', 'More')}</span>
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <LinkContainer to={`/manage/sdk/applications/delete/${item.id}`}>
                                                        <DropdownItem className="text-danger">
                                                            <FontAwesomeIcon icon="trash" />
                                                            <> {t('common.delete', 'Delete')}</>
                                                        </DropdownItem>
                                                    </LinkContainer>
                                                </DropdownMenu>
                                            </ButtonDropdown>
                                        </ButtonGroup>
                                    </TableRowButtons>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
            <ConditionalFragment showIf={items?.length === 0 && !isLoading}>
                <NoResultsFound>
                    {t('sdkClientsTable.noApiKeys', 'You have not generated any API keys yet.')}
                </NoResultsFound>
            </ConditionalFragment>

            <div className="mt-2">
                <LinkContainer to="/manage/sdk/applications/add">
                    <Button color="primary" outline>
                        {t('sdkClientsTable.add', 'Add application')}
                    </Button>
                </LinkContainer>
            </div>
        </>
        );
};