import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { FormGroup, Label } from "reactstrap";
import { QuestionType } from "../../../../api/main/models/codeOnly/QuestionType";
import { Question } from "../../../../api/main/models/Question";
import { HtmlEditor } from "../../../../shared/htmlEditor";

export interface InformationTextTabProps {
    model: Question | undefined,
    change: (changes: Partial<Question>) => void,
}

/**
 * Tab for the text part of all Learning types.
 * @param props
 */
export const InformationTextTab = (props: InformationTextTabProps) => {
    const {
        model,
        change,
    } = props;

    const { t } = useTranslation();

    return (
        <>
            <ConditionalFragment showIf={model?.questionType === QuestionType.InformationVideo}>
                <FormGroup>
                    <Label htmlFor="questionText">{t('informationText.preQuestionText', 'Information text before video')}</Label>

                    <HtmlEditor value={model?.preQuestionText} onChange={html => change({ preQuestionText: html })} />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="questionText">{t('informationText.postQustionText', 'Information text after video')}</Label>

                    <HtmlEditor value={model?.questionText} onChange={html => change({ questionText: html })} />
                </FormGroup>
            </ConditionalFragment>
            <ConditionalFragment showIf={model?.questionType !== QuestionType.InformationVideo}>
                <FormGroup>
                    <Label htmlFor="questionText">{t('informationText.questionText', 'Information text')}</Label>

                    <HtmlEditor value={model?.questionText} onChange={html => change({ questionText: html })} />
                </FormGroup>
            </ConditionalFragment>
        </>
        );
};