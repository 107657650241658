import { TFunction } from "i18next";

/**
 * Question types
 */
export enum QuestionType {
    MultipleChoiceVideo =  "MultipleChoiceVideo",
    SlidingScaleVideo =  "SlidingScaleVideo",
    ResponseTimeVideo =  "ResponseTimeVideo",
    LocationResponseImage =  "LocationResponseImage",
    LocationDiscriminationImage = "LocationDiscriminationImage",
    MultipleChoiceImage = "MultipleChoiceImage",
    SequenceOfQuestions =  "SequenceOfQuestions",
    Questionnaire =  "Questionnaire",
    QuestionnaireInput =  "QuestionnaireInput",
    QuestionnaireMultipleChoice =  "QuestionnaireMultipleChoice",
    QuestionnaireScale =  "QuestionnaireScale",
    LearningVideo =  "LearningVideo",
    LearningImage =  "LearningImage",
    LearningText = "LearningText",
    InformationVideo = "InformationVideo",
    InformationImage = "InformationImage",
    InformationText = "InformationText",
}

/**
 * Return translated display name for a question type.
 * @param t
 */
export function questionTypeDisplayName(type: QuestionType, t: TFunction): string {
    switch (type) {
        case QuestionType.MultipleChoiceVideo: return t('questionTypeDisplayName.MutlipleChoiceVideo', 'Multiple choice video');
        case QuestionType.SlidingScaleVideo: return t('questionTypeDisplayName.SlidingScaleVideo', 'Sliding scale video');
        case QuestionType.ResponseTimeVideo: return t('questionTypeDisplayName.ResponseTimeVideo', 'Response time video');
        case QuestionType.LocationResponseImage: return t('questionTypeDisplayName.LocationResponseImage', 'Location response');
        case QuestionType.LocationDiscriminationImage: return t('questionTypeDisplayName.LocationDiscriminationImage', 'Location discrimination');
        case QuestionType.MultipleChoiceImage: return t('questionTypeDisplayName.MultipleChoiceImage', 'Multiple choice picture');
        case QuestionType.Questionnaire: return t('questionTypeDisplayName.Questionnaire', 'Questionnaire');
        case QuestionType.QuestionnaireInput: return t('questionTypeDisplayName.QuestionnaireInput', 'Input');
        case QuestionType.QuestionnaireScale: return t('questionTypeDisplayName.QuestionnaireScale', 'Scale');
        case QuestionType.QuestionnaireMultipleChoice: return t('questionTypeDisplayName.QuestionnaireMultipleChoice', 'Multiple choice');
        case QuestionType.LearningVideo: return t('questionTypeDisplayName.LearningVideo', 'Video learning');
        case QuestionType.LearningImage: return t('questionTypeDisplayName.LearningImage', 'Image learning');
        case QuestionType.LearningText: return t('questionTypeDisplayName.LearningText', 'Textual learning');
        case QuestionType.SequenceOfQuestions: return t('questionTypeDisplayName.SequenceOfQuestions', 'Sequence of questions');
        case QuestionType.InformationVideo: return t('questionTypeDisplayName.InformationVideo', 'Video information');
        case QuestionType.InformationImage: return t('questionTypeDisplayName.InformationImage', 'Image information');
        case QuestionType.InformationText: return t('questionTypeDisplayName.InformationText', 'Textual information');
    }
}

/**
 * Broad categories that the question types are grouped into that is often useful to display or filter groups of questions together.
 */
export enum QuestionTypeCategory {
    Question = 'Question',
    Questionnaire = 'Questionnaire',
    QuestionnaireQuestion = 'QuesitonnaireQuestion',
    Learning = 'Learning',
    Informtion = 'Information',
}

/**
 * Return the QuestionTypeCategory that type belongs to.
 * @param type
 */
export function getQuestionTypeCategory(type: QuestionType): QuestionTypeCategory {
    switch (type) {
        case QuestionType.MultipleChoiceVideo: return QuestionTypeCategory.Question;
        case QuestionType.SlidingScaleVideo: return QuestionTypeCategory.Question;
        case QuestionType.ResponseTimeVideo: return QuestionTypeCategory.Question;
        case QuestionType.LocationResponseImage: return QuestionTypeCategory.Question;
        case QuestionType.LocationDiscriminationImage: return QuestionTypeCategory.Question;
        case QuestionType.MultipleChoiceImage: return QuestionTypeCategory.Question;
        case QuestionType.Questionnaire: return QuestionTypeCategory.Questionnaire;
        case QuestionType.QuestionnaireInput: return QuestionTypeCategory.QuestionnaireQuestion;
        case QuestionType.QuestionnaireScale: return QuestionTypeCategory.QuestionnaireQuestion;
        case QuestionType.QuestionnaireMultipleChoice: return QuestionTypeCategory.QuestionnaireQuestion;
        case QuestionType.LearningVideo: return QuestionTypeCategory.Learning;
        case QuestionType.LearningImage: return QuestionTypeCategory.Learning;
        case QuestionType.LearningText: return QuestionTypeCategory.Learning;
        case QuestionType.SequenceOfQuestions: return QuestionTypeCategory.Question;
        case QuestionType.InformationVideo: return QuestionTypeCategory.Informtion;
        case QuestionType.InformationImage: return QuestionTypeCategory.Informtion;
        case QuestionType.InformationText: return QuestionTypeCategory.Informtion;
    }
}

/**
 * Returns all question types for the given category.
 * @param category
 */
export function getQuestionTypes(category: QuestionTypeCategory): Array<QuestionType> {
    let ret: Array<QuestionType> = [];
    for (const type of Object.keys(QuestionType).map(item => item as QuestionType)) {
        if (getQuestionTypeCategory(type) === category) {
            ret.push(type);
        }
    }
    return ret;
}