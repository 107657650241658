import { useEffect } from "react";
import { Assessment } from "../../../api/main/models/Assessment";
import { AssessmentSession } from "../../../api/main/models/AssessmentSession";
import { HtmlDisplay } from "../../../shared/htmlEditor";

export interface StartPageProps {
    model: AssessmentSession | undefined | null,
    assessment: Assessment | undefined | null,
    
    // Event raised when a question has been completed.
    onPageComplete: () => void,
}


/**
 * Show a question for an executing assessments.
 * @param props
 */
export const StartPage = (props: StartPageProps) => {
    const {
        assessment,
        onPageComplete,
    } = props;

    // Start page has no activity so we are complete as soon as we're shown.
    useEffect(() => {
        onPageComplete();
    }, [onPageComplete]);

    return (
        <div>
            <HtmlDisplay html={assessment?.text ?? ''} />
        </div>
        );
};