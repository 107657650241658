import { useTranslation } from "react-i18next";
import { AssessmentType } from "../../../api/main/models/codeOnly/AssessmentType";
import { AssessmentsListBase } from "../AssessmentsListBase";

/**
 * List of actual assessments.
 */
export const AssessmentsList = () => {
    const { t } = useTranslation();

    return (
        <AssessmentsListBase
            title={t('assessmentsList.title', 'Assessments')}
            assessmentTypes={[AssessmentType.Assessment]}
            baseRoute={'/administration/assessments'}
        />
        );
};