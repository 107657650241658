import * as React from "react";
import { Background } from "../shared/background/Background";
import { useParams } from "react-router";
import { LoadingIndicator } from "../shared/LoadingIndicator";
import { useStudyLogoutCallback } from "../../api/account/useStudyLogoutCallback";
import { AlertOnErrors } from "../../shared/alertOnErrors";

/**
 * Screen to direct users to when they have completed their study.
 */
export const StudyCompletes = () => {
    // Get the assessmentSessionId from the parameters.
    const { assessmentSessionId } = useParams<{ assessmentSessionId: string }>();

    // Get the study logout callback and invoke it.
    const [studyLogout, { errors: studyLogoutErrors }] = useStudyLogoutCallback();
    const [attemptedLogout, setAttemptedLogout] = React.useState(false);
    React.useEffect(() => {
        (async () => {
            if (attemptedLogout) {
                return;
            }

            setAttemptedLogout(true);
            const result = await studyLogout({ assessmentSessionId });
            const returnUrl = result?.returnUrl ?? '/study-thanks';
            window.location.href = returnUrl
        })();
    }, [studyLogout, assessmentSessionId, attemptedLogout]);

    // Render the UI as a loading indicator while we process the completion..
    return (
        <Background>
            <AlertOnErrors errors={[studyLogoutErrors]} />
            <LoadingIndicator fullWidth />
        </Background>
    );
};
