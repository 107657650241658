import { useCurrentUserProfile } from "../../../api/main/profiles/useCurrentUserProfile";
import { LoadingIndicator } from "../../shared/LoadingIndicator";
import { StaffUserDashboard } from "../administrationDashboard/StaffUserDashboard";
import { SubscriptionUserDashboard } from "../subscriptionDashboard/SubscriptionUserDashboard";

/**
 * Dashboard for the current user.
 */
export const MyDashboard = () => {
    const { data: { model: currentProfile } } = useCurrentUserProfile();

    if (!currentProfile) {
        return (<LoadingIndicator fullWidth />);
    }

    if (!currentProfile?.subscriptionId) {
        return (
            <StaffUserDashboard userId={currentProfile.userId} />
        );
    }

    return (
        <SubscriptionUserDashboard userId={currentProfile.userId} />
        );
};