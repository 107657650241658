import { CreateUserBase, EditUserBase } from "../EditUserBase";

/**
 * Create an administrator.
 */
export const CreateAdministrator = () => (
    <CreateUserBase
        onCreateDefaultValues={() => ({ /* No defaults needed */ })}
        defaultRoleGroup="Esitu staff"
        filterRoleGroups={(groups) => groups.filter(item => item.id === 'Esitu staff')}
    />);

/**
 * Edit an administrator.
 */
export const EditAdministrator = () => (
    <EditUserBase
        defaultRoleGroup="Esitu staff"
        filterRoleGroups={(groups) => groups.filter(item => item.id === 'Esitu staff')}
    />);
