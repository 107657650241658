import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMemo } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Waypoint } from "react-waypoint";
import { Button, ButtonGroup, CardTitle, Col, Row } from "reactstrap";
import { useStudyAdministrationDashboardViewModel } from "../../../api/main/dashboard/viewModels/useStudyAdministrationDashboardViewModel";
import { usePreferredListViewMode } from "../../../globalState/preferredListViewMode/usePreferredListViewMode";
import { useCurrentUserOrEmulatedSubscriptionId } from "../../../globalState/subscriptions/useCurrentUserOrEmulatedSubscriptionId";
import { AlertOnErrors } from "../../../shared/alertOnErrors";
import { Background } from "../../shared/background/Background";
import { Banner } from "../../shared/Banner";
import { CardsOrTable } from "../../shared/cardsOrTable/CardsOrTable";
import { LoadingIndicator } from "../../shared/LoadingIndicator";
import { MainContainer } from "../../shared/MainContainer";
import { PillsNavBar } from "../../shared/pillsNavBar/PillsNavBar";
import { StudyAdministrationNavigation } from "../../subscriptions/Studies/Administration/StudyAdministrationNavigation";
import { DashboardBackButton } from "../backButton/DashboardBackButton";

/**
 * Dashboard for a Study
 */
export const StudyAdministrationDashboard = () => {
    const { t } = useTranslation();

    const subscriptionId = useCurrentUserOrEmulatedSubscriptionId();

    const {
        data: {
            items: allItems,
            profiles
        }, isLoading, errors: loadErrors, fetchMore, hasMore
    } = useStudyAdministrationDashboardViewModel(subscriptionId);

    // Combine all the data into a single object
    const items = useMemo(() => allItems?.map(item => {
        const user = profiles?.find(it => it.userId === item.userId);

        return {
            ...item,
            user
        };
    }), [allItems, profiles]);

    const [viewMode, setViewMode] = usePreferredListViewMode();

    // Render the UI
    //
    return (
        <Background>
            <Banner fluid>
                <Row>
                    <Col xs={12} md="auto">
                        <h1>
                            <DashboardBackButton />
                            {t('studyDashboard.heading.default', 'Study dashboard')}
                        </h1>
                    </Col>
                    <Col>
                        <PillsNavBar>
                            <StudyAdministrationNavigation />
                        </PillsNavBar>
                    </Col>
                    <Col xs="auto">
                        <ButtonGroup>
                            <Button color="secondary" outline={viewMode !== 'cards'} onClick={() => setViewMode('cards')}>
                                <FontAwesomeIcon icon="th-large" />
                                <span className="sr-only">{t('common.cards', 'Cards')}</span>
                            </Button>
                            <Button color="secondary" outline={viewMode !== 'table'} onClick={() => setViewMode('table')}>
                                <FontAwesomeIcon icon="th-list" />
                                <span className="sr-only">{t('common.list', 'List')}</span>
                            </Button>
                        </ButtonGroup>
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
            </Banner>

            <MainContainer fluid>
                <AlertOnErrors errors={[loadErrors]} />

                <CardsOrTable
                    viewMode={viewMode}
                    items={items}
                    tableHeadings={[
                        t('subscriptionsListBase.name', 'Name'),
                        t('subscriptionsListBase.userCount', 'Is finished?')
                    ]}
                    columns={[
                        (item, view) => view === 'cards' ? (<CardTitle tag="h5">{t('common.fullNamw', '{{firstName}} {{lastName}}', { firstName: item.user?.firstName, lastName: item.user?.lastName })}</CardTitle>) : t('common.fullNamw', '{{firstName}} {{lastName}}', { firstName: item.user?.firstName, lastName: item.user?.lastName }),
                        (item, view) => view === 'cards' ? (<CardTitle tag="h5">{item.isFinished ? 'Complete' : 'Incomplete'}</CardTitle>) : item.isFinished ? 'Yes' : 'No',
                    ]}
                />

                <ConditionalFragment showIf={isLoading && !items?.length}>
                    <LoadingIndicator fullWidth />
                </ConditionalFragment>

                {/*<ConditionalFragment showIf={!isLoading && !items?.length}>*/}
                {/*    <NoResultsFound search={search} />*/}
                {/*</ConditionalFragment>*/}

                <ConditionalFragment showIf={!isLoading && hasMore()}>
                    <Waypoint key={items?.length ?? 0} onEnter={fetchMore} />
                    <LoadingIndicator fullWidth />
                </ConditionalFragment>
            </MainContainer>
        </Background>
    );
};