import gql from "graphql-tag";
import { Guid } from "guid-string";
import { sdkWebhookFields } from "../generated/sdkWebhookFields";

export const sdkWebhookFieldsFragment = gql`
    fragment sdkWebhookFields on SdkWebhook {
        id
        sdkClientId
        eventsJson
        url
        httpMethod
        headersJson
        archived
    }
`;


export type SdkWebhook = Omit<sdkWebhookFields, '__typename'>;

export const sdkWebhookDefaultValues = (): Partial<SdkWebhook> => ({
    id: Guid.newGuid(),
    sdkClientId: undefined,
    eventsJson: '[]',
    url: '',
    httpMethod: 'POST',
    headersJson: '[]',
    archived: false,
});