import { RouteEntry } from "../../../shared/routes";
import { QuestionnairesList } from './QuestionnairesList';
import { EditQuestionnaire, CreateQuestionnaire } from "./EditQuestionnaire";
import { IdentityRoles } from "../../../configure/security/IdentityRoles";
import { DeleteQuestionnaire } from "./DeleteQuestionnaire";

export const questionnaireRoutes: Array<RouteEntry> = [
    { path: '/administration/questionnaires', exact: true, component: QuestionnairesList, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/questionnaires/edit/:id', component: EditQuestionnaire, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/questionnaires/add', component: CreateQuestionnaire, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/questionnaires/delete/:id', component: DeleteQuestionnaire, authorize: true, requireRole: IdentityRoles.Administration },
];
