import { BlobReference } from '../models/BlobReference';
import { useAsyncCallback } from 'react-use-async-callback';
import { BlobUploadService } from './BlobUploadService';
import { mainApiConfig } from '../../../configure/mainApiConfig';
import { useServices } from 'inject-typesafe-react';
import { AppServices } from '../../../configure/configureServices';

/**
 * Get a callback to upload blobs in bulk.
 */
export function useBulkUploadBlobsCallback() {
    const apiFetch = useServices((services: AppServices) => services.apiFetch());

    return useAsyncCallback(async (files: FileList): Promise<Array<BlobReference | null>> => {
        const uploadService = new BlobUploadService(mainApiConfig.blobUploadEndpoint, apiFetch);
        const ret = await uploadService.uploadMultiple(files);
        return ret ?? [];
    }, [apiFetch]);
}

/**
 * Get a callback to upload a blob.
 */
export function useUploadBlobCallback() {
    const apiFetch = useServices((services: AppServices) => services.apiFetch());

    return useAsyncCallback(async (files: FileList): Promise<BlobReference | null> => {
        const uploadService = new BlobUploadService(mainApiConfig.blobUploadEndpoint, apiFetch);
        const ret = await uploadService.upload(files);
        return ret;
    }, [apiFetch]);
}
