import * as React from 'react';
import { Table, Button, Row, Col, ButtonGroup, Input } from 'reactstrap';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { Waypoint } from 'react-waypoint';
import { useReplaceSearchParamsEffect, useSearchParams } from '../../shared/useURLSearchParams';
import { useTranslation } from 'react-i18next';
import { SearchInput } from '../shared/searchInput/SearchInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContainer } from '../shared/MainContainer';
import { NoResultsFound } from '../shared/NoResultsFound';
import { StickyToolbar } from '../shared/StickyToolbar';
import { useParams } from 'react-router';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Banner } from '../shared/Banner';
import { Background } from '../shared/background/Background';
import { FeedbackStyle, feedbackStyleDisplayName } from '../../api/main/models/codeOnly/FeedbackStyle';
import { AssessmentType } from '../../api/main/models/codeOnly/AssessmentType';
import { PillsNavBar } from '../shared/pillsNavBar/PillsNavBar';
import { SubscriptionAdministrationNavigation } from '../subscriptions/SubscriptionAdministrationNavigation';
import { useSubscriptionAssessmentsListViewBaseViewModel } from '../../api/main/subscriptionAssessments/viewModels/useSubscriptionAssessmentsListBaseViewModel';
import { useChangesArray } from '../../shared/useChanges';
import { SubscriptionAssessment, subscriptionAssessmentDefaultValues } from '../../api/main/models/SubscriptionAssessment';
import { useSaveSubscriptionAssessmentCallback } from '../../api/main/subscriptionAssessments/useSaveSubscriptionAssessmentCallback';
import { useDeleteSubscriptionAssessmentCallback } from '../../api/main/subscriptionAssessments/useDeleteSubscriptionAssessmentCallback';
import { useAsyncCallback } from 'react-use-async-callback';
import { useDebouncedCallback } from 'use-debounce/lib';
import { Link } from 'react-router-dom';
import { useCallback, useState } from 'react';
import { useCurrentUserOrEmulatedSubscriptionId } from '../../globalState/subscriptions/useCurrentUserOrEmulatedSubscriptionId';

export interface SubscriptionAssessmentsListBaseBaseProps {
    title: string,
    mobileColumn1Name: string,
    assessmentTypes: Array<AssessmentType>,
    baseRoute: string,
    navigationPills?: React.ReactNode,
    isDistributorSubscription?: boolean,
    isStudy?: boolean,
}

/**
 * Base list functionality for Assessments (including Learning).
 * 
 * You most often want to use the components that wrap this one rather than this one directly.
 */
export const SubscriptionAssessmentsListBase = (props: SubscriptionAssessmentsListBaseBaseProps) => {
    const {
        title,
        mobileColumn1Name,
        assessmentTypes,
        baseRoute,
        navigationPills,
        isDistributorSubscription,
        isStudy = false,
    } = props;

    const { subscriptionId } = useParams<{ subscriptionId: string | undefined; }>();

    const currentSubscriptionId = useCurrentUserOrEmulatedSubscriptionId();
    const lowerSubscriptionId = subscriptionId ?? undefined;

    const subscriptionIdToUse = React.useMemo(() => {
        //values are passed in with different param names, need to determine which to search by
        if (isDistributorSubscription) {
            return currentSubscriptionId;
        } else {
            return (subscriptionId);
        }

    }, [currentSubscriptionId, subscriptionId, isDistributorSubscription]);

    const { t } = useTranslation();
    const { search: searchParam } = useSearchParams();
    const [search, setSearch] = React.useState<string>(searchParam ?? '');
    const {
        data: { items: storeSubscriptionAssessments, selectedItems, assessments: allItems, subscription, },
        isLoading, errors: loadingErrors, fetchMore, hasMore
    } = useSubscriptionAssessmentsListViewBaseViewModel(subscriptionIdToUse, { pageSize: undefined, assessmentTypes: assessmentTypes, lowerSubscriptionId: lowerSubscriptionId });

    const [currentAssessments, setCurrentAssessments] = React.useState<Array<SubscriptionAssessment>>();
    React.useEffect(() => {
        //do we need to filter items to just current distributor items
        if (isDistributorSubscription) {
            setCurrentAssessments(selectedItems);
        } else {
            setCurrentAssessments(storeSubscriptionAssessments);
        }


    }, [setCurrentAssessments, storeSubscriptionAssessments, selectedItems, isDistributorSubscription]);


    // Manage the addition and removal of items for this subscription.
    const subscriptionAsssessmentsManager = useChangesArray<SubscriptionAssessment, string>(currentAssessments, item => item.id);
    const [saveSubscriptionAssessment] = useSaveSubscriptionAssessmentCallback();
    const [removeSubscriptionAssessment] = useDeleteSubscriptionAssessmentCallback();

    // Save all changes to the subscriptionAssessmentManager.
    const [saveAllSubscriptionAssessments] = useAsyncCallback(async () => {
        // Save to the store.
        for (const item of subscriptionAsssessmentsManager.added) { await saveSubscriptionAssessment(item.id, subscriptionAsssessmentsManager.changesFor(item.id), true); }
        for (const item of subscriptionAsssessmentsManager.updated) { await saveSubscriptionAssessment(item.id, subscriptionAsssessmentsManager.changesFor(item.id), false); }
        for (const item of subscriptionAsssessmentsManager.removed) { await removeSubscriptionAssessment(item.id); }
        subscriptionAsssessmentsManager.markAsSaved();
    }, [subscriptionAsssessmentsManager, saveSubscriptionAssessment, removeSubscriptionAssessment]);
    // Debounced version to avoid going to the server too often.
    const saveAllSubscriptionAssessmentsDebounced = useDebouncedCallback(() => {
        saveAllSubscriptionAssessments();
    });

    //const [subscriptionToUse, setSubscriptionToUse] = React.useState<string>();

    // Toggle if an assessment is selected for the subscription or not.
    const toggleSubscriptionAssessment = useCallback((assessmentId: string) => {
        const existing = subscriptionAsssessmentsManager.model.find(item => item.assessmentId === assessmentId);

        const subscriptionToUse = subscriptionId ?? currentSubscriptionId;

        if (existing) {
            subscriptionAsssessmentsManager.removeFor(existing.id);

        } else {
            if (isStudy && subscriptionAsssessmentsManager.model.length > 0) {
                // Limit to one assessment for studies.
                return;
            }
            subscriptionAsssessmentsManager.addFor({
                ...subscriptionAssessmentDefaultValues(),

                subscriptionId: subscriptionToUse!,
                assessmentId: assessmentId,
            });
        }

        // Save to the store after a debounce to allow grouping of quick actions and the state itself to be updated.
        saveAllSubscriptionAssessmentsDebounced();
    }, [subscriptionAsssessmentsManager, currentSubscriptionId, saveAllSubscriptionAssessmentsDebounced, subscriptionId, isStudy]);

    // Returns true if the assessment is selected for this subscription.
    const isSubscriptionAssessmentSelected = useCallback((assessmentId: string) => !!subscriptionAsssessmentsManager.model.find(item => item.assessmentId === assessmentId), [subscriptionAsssessmentsManager]);

    const isSubscriptionAssessmentSelectedByDistributor = useCallback((assessmentId: string) =>
        !!storeSubscriptionAssessments.find(item => item.assessmentId === assessmentId), [storeSubscriptionAssessments]);

    // Filtering the display between all and selected only.
    const [filter, setFilter] = useState<'all' | 'selected'>('all');

    // Filter by the assessment's search client side so it can work when offline as well as online.
    const items = React.useMemo(() => {
        if (!allItems) {
            return allItems;
        }

        let ret = allItems;

        if (isDistributorSubscription) {

            ret = ret.filter(item => isSubscriptionAssessmentSelectedByDistributor(item.id));

        } else {

            // Filter by the selected filter.
            switch (filter) {
                case 'all':
                    break;
                case 'selected': {
                    ret = ret.filter(item => isSubscriptionAssessmentSelected(item.id));
                }
            }
        }

        // Filter by the user's search text.
        if (search) {
            let lowerSearch = search.toLocaleLowerCase();

            ret = ret.filter(item =>
                item.name.toLocaleLowerCase().indexOf(lowerSearch) >= 0
            );
        }

        return ret;
    }, [allItems, search, filter, isSubscriptionAssessmentSelected, isSubscriptionAssessmentSelectedByDistributor, isDistributorSubscription]);

    useReplaceSearchParamsEffect({ search: search });

    const colCount = 5;

    // Limit to one assessment for studies.
    const assessmentLimit = 1;

    // Count the number of assessments for this study.
    const currentAssessmentCount = React.useMemo(() => {
        if (!subscriptionAsssessmentsManager) return 0;

        return subscriptionAsssessmentsManager.model.length;
    }, [subscriptionAsssessmentsManager]);

    // Render the UI
    //
    return (
        <Background>
            <Banner fluid>
                <StickyToolbar>
                    <Row>
                        <Col xs={12} md="auto">
                            <h1>
                                {title}
                            </h1>
                        </Col>
                        <Col>
                            <PillsNavBar>
                                {navigationPills}
                                <ConditionalFragment showIf={!navigationPills}>
                                    <SubscriptionAdministrationNavigation id={subscriptionId ?? ''} />
                                </ConditionalFragment>
                            </PillsNavBar>
                        </Col>

                    </Row>
                    <Row>
                        <Col>
                            <h3 className="text-muted">
                                {subscription?.companyName}
                            </h3>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                        <Col>
                            <SearchInput value={search} onChange={e => setSearch(e.currentTarget.value)} />
                        </Col>
                        <Col xs="auto">
                            <ButtonGroup>
                                <Button color="secondary" outline={filter !== 'all'} onClick={() => setFilter('all')}>
                                    {t('subscriptionAssessmentsListBase.filter.all', 'All')}
                                </Button>
                                <Button color="secondary" outline={filter !== 'selected'} onClick={() => setFilter('selected')}>
                                    {t('subscriptionAssessmentsListBase.filter.selected', 'Selected')}
                                </Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>{t('', 'A study is limited to {{assessmentLimit}} assessment{{pluraliseOrNot}}. This study has {{currentAssessmentCount}} of {{assessmentLimit}} assessment{{pluraliseOrNot}}', { assessmentLimit, pluraliseOrNot: assessmentLimit > 1 ? 's' : '', currentAssessmentCount })}</p>
                        </Col>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer fluid>
                <AlertOnErrors errors={loadingErrors} />

                <Table responsive striped>
                    <thead>
                        <tr>
                            <th>&nbsp;</th>
                            <th className="d-table-cell d-lg-none">{mobileColumn1Name}</th>
                            <th className="d-none d-lg-table-cell">{t('subscriptionAssessmentsListBase.name', 'Name')}</th>
                            <th className="d-none d-lg-table-cell">{t('subscriptionAssessmentsListBase.maxRestarts', 'Maximum retakes per user')}</th>
                            <th className="d-none d-lg-table-cell">{t('subscriptionAssessmentsListBase.feedbackStyle', 'Feedback style')}</th>
                            <th className="d-none d-lg-table-cell">{t('subscriptionAssessmentsListBase.isSmartIndividuality.heading', 'Smart?')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            items?.map(item => {
                                const itemIsSelected = isSubscriptionAssessmentSelected(item.id);
                                const subscriptionAsssessment = subscriptionAsssessmentsManager.model.find(it => it.assessmentId === item.id);

                                return (
                                    <tr key={item.id}>
                                        <td style={{ width: '1px' /* Sizes to fit contents */ }}>
                                            <ButtonGroup>
                                                <Button color="secondary" outline={!itemIsSelected} onClick={() => toggleSubscriptionAssessment(item.id)}>
                                                    {
                                                        itemIsSelected ? t('subscriptionAssessmentsListBase.selected', 'Selected')
                                                            : t('subscriptionAssessmentsListBase.select', 'Select')
                                                    }
                                                </Button>
                                                <ConditionalFragment showIf={itemIsSelected}>
                                                    <Button color="secondary" outline={!itemIsSelected} onClick={() => toggleSubscriptionAssessment(item.id)}>
                                                        <FontAwesomeIcon icon="times" />
                                                    </Button>
                                                </ConditionalFragment>
                                            </ButtonGroup>
                                        </td>
                                        <td className="d-table-cell d-lg-none">
                                            <div>
                                                <Link to={`${baseRoute}/edit/${item.id}`}>
                                                    {item.name}
                                                </Link>
                                            </div>
                                        </td>
                                        <td className="d-none d-lg-table-cell">
                                            <Link to={`${baseRoute}/edit/${item.id}`}>
                                                {item.name}
                                            </Link>
                                        </td>
                                        <td className="d-none d-lg-table-cell">
                                            <ConditionalFragment showIf={!!subscriptionAsssessment}>
                                                <Input type="number"
                                                    value={subscriptionAsssessment?.maxRestarts ?? 0}
                                                    min={0}
                                                    onChange={e => subscriptionAsssessmentsManager.changeFor(subscriptionAsssessment?.id ?? '', { maxRestarts: e.currentTarget.valueAsNumber })}
                                                    onBlur={() => saveAllSubscriptionAssessmentsDebounced()}
                                                />
                                            </ConditionalFragment>
                                        </td>
                                        <td className="d-none d-lg-table-cell">{feedbackStyleDisplayName(item.feedbackStyle as FeedbackStyle, t)}</td>
                                        <td className="d-none d-lg-table-cell">{
                                            item.isSmartIndividuality ? t('subscriptionAssessmentsListBase.isSmartIndividuality.smart', 'Smart')
                                                : t('subscriptionAssessmentsListBase.isSmartIndividuality.standardised', 'Standardised')
                                        }</td>
                                    </tr>
                                );
                            })
                        }
                        <ConditionalFragment showIf={isLoading && !items?.length}>
                            <tr><td colSpan={colCount}><LoadingIndicator fullWidth /></td></tr>
                        </ConditionalFragment>
                        <ConditionalFragment showIf={!isLoading && !items?.length}>
                            <tr><td colSpan={colCount}>
                                <NoResultsFound search={search} />
                            </td></tr>
                        </ConditionalFragment>
                        <ConditionalFragment showIf={!isLoading && hasMore()}>
                            <tr><td colSpan={colCount}>
                                <Waypoint key={items?.length ?? 0} onEnter={fetchMore} />
                                <LoadingIndicator fullWidth />
                            </td></tr>
                        </ConditionalFragment>
                    </tbody>
                </Table>
            </MainContainer>
        </Background>
    );
};
