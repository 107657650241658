import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { UserAssessmentAssignmentUpdateInput } from '../generated/globalTypes';
import { updateUserAssessmentAssignmentMutation, updateUserAssessmentAssignmentMutationVariables } from '../generated/updateUserAssessmentAssignmentMutation';
import { userAssessmentAssignmentFieldsFragment } from '../models/UserAssessmentAssignment';

/**
 * Get a callback to update a UserAssessmentAssignment in the store.
 */
export function useUpdateUserAssessmentAssignmentCallback(): [(id: string, model: UserAssessmentAssignmentUpdateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<updateUserAssessmentAssignmentMutation, updateUserAssessmentAssignmentMutationVariables>(
        gql`
            mutation updateUserAssessmentAssignmentMutation ($model: UserAssessmentAssignmentUpdateInput!) {
                updateUserAssessmentAssignment(model: $model) {
                    ...userAssessmentAssignmentFields
                }
            }

            ${userAssessmentAssignmentFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (id: string, model: UserAssessmentAssignmentUpdateInput) => {
        const result = await mutationAction({ variables: { model: { ...model, id: id } } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
