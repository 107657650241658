import { useMemo } from "react";
import { UsersDashboardTab, UsersDashboardTabProps } from "./UsersDashboardTab";

/**
 * Esitu staff tab on the dashboard.
 * @param props
 */
export const EsituStaffDashboardTab = (props: UsersDashboardTabProps) => {
    const {
        profiles,
        ...rest
    } = props;

    const staffProfiles = useMemo(() => profiles.filter(item => !item.subscriptionId), [profiles]);

    return (
        <UsersDashboardTab
            profiles={staffProfiles}
            linkToFolder={'staff'}
            {...rest}
        />
    );
};