import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { deleteDriverMetricAdjustmentMutation, deleteDriverMetricAdjustmentMutationVariables } from '../generated/deleteDriverMetricAdjustmentMutation';
import { driverMetricAdjustmentFieldsFragment } from '../models/DriverMetricAdjustment';

/**
 * Get a callback to delete a DriverMetricAdjustment in the store.
 */
export function useDeleteDriverMetricAdjustmentCallback(): [(id: string) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<deleteDriverMetricAdjustmentMutation, deleteDriverMetricAdjustmentMutationVariables>(
        gql`
            mutation deleteDriverMetricAdjustmentMutation ($id: ID!) {
                deleteDriverMetricAdjustment(id: $id) {
                    ...driverMetricAdjustmentFields
                }
            }

            ${driverMetricAdjustmentFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (id: string) => {
        const result = await mutationAction({ variables: { id: id } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
