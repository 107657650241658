import { ReactNode } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Badge, Card, CardBody, CardHeader, Col, ListGroupItem, ListGroupItemText, Row } from "reactstrap";
import { AssessmentType } from "../../../api/main/models/codeOnly/AssessmentType";
import { Subscription } from "../../../api/main/models/Subscription";
import { generateDistributorDashboardSummary } from "../utilities/generateDashboardSummary";
import { Assessment } from "../../../api/main/models/Assessment";
import { UserAssessmentAssignment } from "../../../api/main/models/UserAssessmentAssignment";
import { SubscriptionAssessment } from "../../../api/main/models/SubscriptionAssessment";

export interface DistributorDashboardListGroupItemProps {
    children?: ReactNode,
    subscriptionId: string,
    subscriptions: Array<Subscription>,
    subscriptionAssessments: Array<SubscriptionAssessment>,
    userAssessmentAssignment: Array<UserAssessmentAssignment>,
    assessments: Array<Assessment>,

}

/**
 * List group with items for each group.
 * @param props
 */
export const DistributorDashboardListGroupItem = (props: DistributorDashboardListGroupItemProps) => {
    const {
        children,
        subscriptionId,
        subscriptions,
        subscriptionAssessments,
        assessments,
        userAssessmentAssignment,
    } = props;

    const { t } = useTranslation();
    const distributorSummary = generateDistributorDashboardSummary(subscriptions, subscriptionId, userAssessmentAssignment, subscriptionAssessments, assessments,);
    const distributorAssessments = distributorSummary.filteredAssessments.filter(item => item.assessmentType === AssessmentType.Assessment)
    const distributorTraining = distributorSummary.filteredAssessments.filter(item => item.assessmentType === AssessmentType.TrainingModule)

    return (
        <ListGroupItem tag="div">
            <Row>
                <Col xs={12} md="">
                    <div>
                        {children}
                    </div>
                    <ListGroupItemText tag="div">
                        <Row>
                            <Col>
                                <h6>
                                    {"Subscriptions"}
                                </h6>
                                <ConditionalFragment showIf={!distributorSummary.DistributorSubscriptionsText}>
                                    {t('dashboardListGroup.NoDataToShow','No data to show')}
                                </ConditionalFragment>
                                <div>
                                    {distributorSummary.DistributorSubscriptionsText}
                                </div>
                                <Badge color="primary">
                                    {t('dashboardListGroup.TotalSubscriptions', 'Total subscriptions')}
                                    <> </>
                                    <Badge pill style={{ fontSize: '100%' }}>
                                        {distributorSummary.totalSubscriptionsCount}
                                    </Badge>
                                </Badge>
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col>
                                <h6>
                                    {"Assessments"}
                                </h6>
                                <ConditionalFragment showIf={!distributorSummary.distributorUserAssessmentAssignmentCount}>
                                    {t('dashboardListGroup.NoDataToShow', 'No data to show')}
                                </ConditionalFragment>
                            </Col>
                        </Row>
                        <Row>
                            {
                                distributorAssessments.map(item =>
                                    <ConditionalFragment showIf={distributorSummary.assessmentAssignments.find(it => it.AssessmentId === item.id)?.count !== undefined}>
                                        <Col key={item.id} xs={12} sm={6} lg={3}>
                                            <Card>
                                                <CardHeader>{item.name}</CardHeader>
                                                <CardBody>
                                                    {t('dashboardListGroup.TimesAssigned', 'Times assigned: ')}
                                                    {distributorSummary.assessmentAssignments.find(it => it.AssessmentId === item.id)?.count}
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </ConditionalFragment>
                                )
                            }
                        </Row>
                        <Row>
                            <Col>
                                <Badge color="primary">
                                    {t('dashboardListGroup.totalAssessmentAssignments', 'Total assessment assignments')}
                                    <> </>
                                    <Badge pill style={{ fontSize: '100%' }}>
                                        {distributorSummary.distributorUserAssessmentAssignmentCount}
                                    </Badge>
                                </Badge>
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col>
                                <h6>
                                    {"Training"}
                                </h6>
                                <ConditionalFragment showIf={!distributorSummary.distributorUserTrainingAssignmentCount}>
                                    {t('dashboardListGroup.NoDataToShow', 'No data to show')}
                                </ConditionalFragment>
                            </Col>
                        </Row>
                        <Row>
                            {
                                distributorTraining.map(item =>
                                    <ConditionalFragment showIf={distributorSummary.assessmentAssignments.find(it => it.AssessmentId === item.id)?.count !== undefined}>
                                        <Col key={item.id} xs={12} sm={6} lg={3}>
                                            <Card>
                                                <CardHeader>{item.name}</CardHeader>
                                                <CardBody>
                                                    {t('dashboardListGroup.TimesAssigned', 'Times assigned: ')}
                                                    {distributorSummary.assessmentAssignments.find(it => it.AssessmentId === item.id)?.count}
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </ConditionalFragment>
                                )
                            }
                    </Row>
                    <Row>
                        <Col>
                            <Badge color="primary">
                                {t('dashboardListGroup.totalTrainingAssignments', 'Total training assignments')}
                                <> </>
                                <Badge pill style={{ fontSize: '100%' }}>
                                    {distributorSummary.distributorUserTrainingAssignmentCount}
                                </Badge>
                            </Badge>
                        </Col>
                    </Row>
                    </ListGroupItemText>
                </Col>
            </Row>
        </ListGroupItem>
    );
};