import { useLocation } from "react-router";

/**
 * Hook that returns if we should show a back button on a dashboard, based off the route.
 */
export function useDashboardRequiresBackButton() {
    const location = useLocation();

    // Don't show on the main /dashboard page
    if (location.pathname === '/dashboard') {
        return false;
    }

    // Don't show on the main administration dashboard page.
    if (location.pathname === '/administration/dashboard') {
        return false;
    }

    // Otherwise show the back button.
    return true;
}