import { useCurrentUserOrEmulatedSubscriptionId } from "../../../globalState/subscriptions/useCurrentUserOrEmulatedSubscriptionId";
import { CreateUserBase, EditUserBase } from "../EditUserBase";
import { useParams } from "react-router";

/**
 * Create a manager.
 */
export const CreateManager = () => {
    const { subscriptionTeamId } = useParams<{ subscriptionTeamId: string | undefined }>();

    const subscriptionId = useCurrentUserOrEmulatedSubscriptionId();

    return (
        <CreateUserBase
            onCreateDefaultValues={() => ({ subscriptionId: subscriptionId, subscriptionTeamId:subscriptionTeamId ?? undefined })}
            defaultRoleGroup="Driver manager"
            filterRoleGroups={(groups) => groups.filter(item => item.id === 'Driver manager' || item.id === 'Overview manager')}
        />);
};

/**
 * Edit a manager.
 */
export const EditManager = () => (
    <EditUserBase
        defaultRoleGroup="Driver manager"
        filterRoleGroups={(groups) => groups.filter(item => item.id === 'Driver manager' || item.id === 'Overview manager')}
    />);
