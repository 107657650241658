import { useTranslation } from "react-i18next";
import { UsersListBase } from "../../users/UsersListBase";
import { DistributorAdministrationNavigation } from './Administration/DistributorAdministrationNavigation';
import { useCurrentUserOrEmulatedSubscriptionId } from '../../../globalState/subscriptions/useCurrentUserOrEmulatedSubscriptionId';

/**
 * List of distributor administrators.
 */
export const DistributorAdminsList = () => {
    const { t } = useTranslation();

    const baseRoute = `/manage/distributor/admins`

    const currentSubscriptionId = useCurrentUserOrEmulatedSubscriptionId();

    return (
        <UsersListBase
            title={t('distributorAdminsList.title', 'Distributor Admins')}
            filterByRoleGroups={['Distributor administrator']}
            roleGroupColumnHidden={true}
            baseRoute={baseRoute}
            subscriptionId={currentSubscriptionId}
            navigationPills={<DistributorAdministrationNavigation/>}
            teamColumnHidden={true}
            allowImport={true}
        />
    );
};