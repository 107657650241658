import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ValidationErrors } from "pojo-validator";
import { ValidateCallback } from "pojo-validator-react";
import { ValidatedInput } from "pojo-validator-reactstrap";
import { useTranslation } from "react-i18next";
import { Button, ButtonGroup, Card, FormGroup } from "reactstrap";
import { DriverMetric } from "../../../../api/main/models/DriverMetric";
import { AssessmentItemDriverMetricQuantity } from "../../../../api/main/models/AssessmentItemDriverMetricQuantity";
import { FormButtons } from "../../../shared/FormButtons";

export interface AssessmentItemDriverMetricDisplayQuantityRuleComponentProps {
    model: AssessmentItemDriverMetricQuantity | undefined,
    change: (changes: Partial<AssessmentItemDriverMetricQuantity>) => void,
    remove: () => void,

    validate: ValidateCallback,
    validationErrors: ValidationErrors,

    driverMetrics: Array<DriverMetric>,
}

/**
 * Component for Assessment Item Safety Metric Display Quantity Rule for questions.
 * @param props
 */
export const AssessmentItemDriverMetricDisplayQuantityRuleComponent = (props: AssessmentItemDriverMetricDisplayQuantityRuleComponentProps) => {
    const {
        model,
        change,
        remove,
        validate,
        validationErrors,
        driverMetrics,
    } = props;

    const { t } = useTranslation();

    return (
        <Card body>
            <FormButtons noPadding>
                <ButtonGroup>
                    <Button color="danger" outline onClick={() => remove()}>
                        <FontAwesomeIcon icon="trash-alt" />
                        <> </>
                        {t('AssessmentItemDriverMetricDisplayQuantityRuleComponent.delete', 'Delete rule')}
                    </Button>
                </ButtonGroup>
            </FormButtons>
            <FormGroup className="form-inline">
                <div className="mt-1">
                    {t('AssessmentItemDriverMetricDisplayQuantityRuleComponent.beforeQuantity', 'Show')}
                    <> </>
                    <ValidatedInput name="quantity" type="number" value={model?.quantity ?? ''} onChange={e => change({ quantity: e.currentTarget.valueAsNumber })} onBlur={() => validate('quantity')} validationErrors={validationErrors['quantity']} />
                    <> </>
                    {t('AssessmentItemDriverMetricDisplayQuantityRuleComponent.beforeMetric', 'questions with the safety metric')}
                    <> </>
                    <ValidatedInput type="select" value={model?.driverMetricId ?? ''} onChange={e => change({ driverMetricId: e.currentTarget.value })} onBlur={() => validate('driverMetricId')} validationErrors={validationErrors['driverMetricId']}>
                        <option value="">{t('AssessmentItemDriverMetricDisplayQuantityRuleComponent.driverMetricId.pleaseSelect', 'No safety metric')}</option>
                        {
                            driverMetrics.map(item => (
                                <option key={item.id} value={item.id}>{item.name}</option>
                            ))
                        }
                    </ValidatedInput>

                </div>
            </FormGroup>
        </Card>
    );
};