import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Button, FormGroup, Label, ListGroup } from "reactstrap";
import { Question } from "../../../../api/main/models/Question";
import { ModelArrayChanges } from "../../../../shared/useChanges";
import { useDisplayOrder } from "../../../shared/useDisplayOrder/useDisplayOrder";
import { QuestionSequenceQuestion, questionSequenceQuestionDefaultValues } from "../../../../api/main/models/QuestionSequenceQuestion";
import { QuestionSequenceQuestionComponent } from "./QuestionSequenceQuestionComponent";
import { useToggleState } from "use-toggle-state";
import { SelectQuestionModal } from "../../selectQuestionModal/SelectQuestionModal";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ConditionalFragment } from "react-conditionalfragment";
import moment from "moment";

export interface QuestionPromptMultipleChoiceVideoTabProps {
    model: Question | undefined,

    questionSequenceQuestionsManager: ModelArrayChanges<QuestionSequenceQuestion, string>,
    allQuestions: Array<Question>,
    isLoadingAllQuestions: boolean,
    ensureAllQuestionsLoaded: () => void,
}

/**
 * Tab for a maintaining a sequence of questions.
 * @param props
 */
export const SequenceOfQuestionsTab = (props: QuestionPromptMultipleChoiceVideoTabProps) => {
    const {
        model,

        questionSequenceQuestionsManager,
        allQuestions,
        isLoadingAllQuestions,
        ensureAllQuestionsLoaded,
    } = props;

    const { t } = useTranslation();

    // Order the answers so they show in and can be managed by displayOrder.
    const [orderedQuestions, {
        canMoveUp: canMoveAnswerUp,
        moveUp: moveAnswerUp,
        canMoveDown: canMoveAnswerDown,
        moveDown: moveAnswerDown,
    }] = useDisplayOrder(questionSequenceQuestionsManager);
    
    // Showing of the question modal.
    const [questionModalIsOpen, _toggleQuestionModal] = useToggleState();
    const toggleQuestionModal = useCallback(() => {
        ensureAllQuestionsLoaded();
        _toggleQuestionModal();
    }, [ensureAllQuestionsLoaded, _toggleQuestionModal]);

    // Adding of questions selected in the modal
    const onQuestionModalClosed = useCallback((event: { selectedIds: Array<string> }) => {

        var i = 0; // Using i to add on to the display order. This is done so when questions are added in bulk they dont get the same display order.

        for (const selectedId of event.selectedIds) {
            questionSequenceQuestionsManager.addFor({
                ...questionSequenceQuestionDefaultValues(),
                displayOrder: moment().unix() + i,
                parentQuestionId: model?.id,
                childQuestionId: selectedId,
            });

            i++;
        }
    }, [questionSequenceQuestionsManager, model?.id]);

    return (
        <>
            <FormGroup>
                <Label htmlFor="questions">{t('questionPromptMultipleChoiceVideoTab.questions', 'Questions in the sequence')}</Label>

                <ListGroup className="mb-2">
                    {
                        orderedQuestions.map(item => {
                            const childQuestion = allQuestions.find(it => it.id === item.childQuestionId);

                            return (
                                <QuestionSequenceQuestionComponent key={item.id}
                                    model={item}
                                    change={changes => questionSequenceQuestionsManager.changeFor(item.id, changes)}
                                    remove={() => questionSequenceQuestionsManager.removeFor(item.id)}

                                    moveUp={() => moveAnswerUp(item.id)} canMoveUp={canMoveAnswerUp(item.id)}
                                    moveDown={() => moveAnswerDown(item.id)} canMoveDown={canMoveAnswerDown(item.id)}

                                    childQuestion={childQuestion}
                                />
                            );
                        })
                    }
                </ListGroup>

                <Button color="primary" outline onClick={() => toggleQuestionModal()}>
                    <><FontAwesomeIcon icon="car-crash" className="nav-icon" />
                    {t('questionPromptMultipleChoiceVideoTab.addQuestions', ' Add questions')}</>
                </Button>
            </FormGroup>

            <ConditionalFragment showIf={questionModalIsOpen}>
            <SelectQuestionModal
                    isOpen={questionModalIsOpen}
                    toggle={toggleQuestionModal}
                    onClose={onQuestionModalClosed}
                    questions={allQuestions}
                    isLoadingQuestions={isLoadingAllQuestions}
                />
                </ConditionalFragment>
        </>
        );
};