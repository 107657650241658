import { RouteEntry } from "../../../shared/routes";
import { InformationList } from "./InformationList";
import { EditInformation, CreateInformation } from "./EditInformation";
import { IdentityRoles } from "../../../configure/security/IdentityRoles";
import { DeleteInformation } from "./DeleteInformation";

export const informationRoutes: Array<RouteEntry> = [
    { path: '/administration/information-pages', exact: true, component: InformationList, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/information-pages/edit/:id', component: EditInformation, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/information-pages/add', component: CreateInformation, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/information-pages/delete/:id', component: DeleteInformation, authorize: true, requireRole: IdentityRoles.Administration },
];
