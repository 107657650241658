import { KonvaEventObject } from "konva/lib/Node";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Stage, Layer } from "react-konva";
import "./interactiveImage.scss";
import { OverlayShape, OverlayShapeType } from "./OverlayShape";
import { ResizableOverlay } from "./ResizableOverlay";

export type PointConversion = (point: { x: number, y: number }) => { x: number, y: number };

export interface InteractiveImageProps {
    src: string | undefined,
    overlays: Array<OverlayShape>,
    onOverlayTransformed?: (id: string, shapeProps: OverlayShape, conversions: { displayToVirtual: PointConversion, virtualToDisplay: PointConversion }) => void,
    onAddOverlay?: (shapeProps: Partial<OverlayShape>, conversions: { displayToVirtual: PointConversion, virtualToDisplay: PointConversion }) => OverlayShape,
    allowAdd?: boolean
    addOverlayShapeType?: OverlayShapeType,
    onShapeClicked?: (shapeProps: OverlayShape, conversions: { displayToVirtual: PointConversion, virtualToDisplay: PointConversion }) => void,

    virtualSize?: { width: number, height: number },
    allowTransform?: boolean,

    onVirtualConversionChanged?: (conversions: { displayToVirtual: PointConversion, virtualToDisplay: PointConversion }) => void,
}

/**
 * An image that can be interacted with by the user.
 * @param props
 */
export const InteractiveImage = (props: InteractiveImageProps) => {
    const {
        src,
        overlays: _overlays,
        onOverlayTransformed: _onOverlayTransformed,
        onAddOverlay: _onAddOverlay,
        allowAdd = false,
        addOverlayShapeType = 'rect',
        allowTransform = false,
        onShapeClicked,
        onVirtualConversionChanged,
    } = props;

    const divRef = useRef<HTMLDivElement | null>(null);
    const [selectedId, _selectShape] = useState<string | null>(null);

    const [_virtualSize, setVirtualSize] = useState<{ width: number, height: number } | undefined>();
    const virtualSize = useMemo(() => _virtualSize ?? { width: 1920, height: 1080 } /* Use 1080p HD as a default */, [_virtualSize]);
    const needToCalculateVirtualSize = !_virtualSize;

    // Convert a virtual display location/size to a screen location/size.
    const virtualToDisplay = useCallback((point: { x: number, y: number }) => {
        // If we don't have a current display area then do nothing.
        if (!divRef.current) {
            return point;
        }

        return {
            x: ((point?.x ?? 0) / virtualSize.width) * (divRef.current?.clientWidth ?? 1),
            y: ((point?.y ?? 0) / virtualSize.height) * (divRef.current?.clientHeight ?? 1),
        };
    }, [virtualSize, divRef]);

    // Convert a screen location/size to a virtual location/size.
    const displayToVirtual = useCallback((point: { x: number, y: number }) => {
        // If we don't have a current display area then do nothing.
        if (!divRef.current) {
            return point;
        }

        return {
            x: ((point?.x ?? 0) / (divRef.current?.clientWidth ?? 1)) * virtualSize.width,
            y: ((point?.y ?? 0) / (divRef.current?.clientHeight ?? 1)) * virtualSize.height,
        };
    }, [virtualSize, divRef]);

    // Give a chance for the parent component to know the conversions we're using.
    useEffect(() => {
        if (onVirtualConversionChanged) {
            onVirtualConversionChanged({
                displayToVirtual,
                virtualToDisplay,
            });
        }
    }, [virtualToDisplay, displayToVirtual, onVirtualConversionChanged]);

    const selectShape = useCallback((value: string | null) => {
        _selectShape(value);
        if (value != null) {
            if (onShapeClicked) {
                const shape = _overlays.find(item => item.id === value);
                if (shape) {
                    onShapeClicked(shape, { displayToVirtual, virtualToDisplay, });
                }
            }
        }
    }, [_selectShape, _overlays, onShapeClicked, displayToVirtual, virtualToDisplay]);


    // Get all overlays with their screen sizing for x/y.
    const overlays = /*useMemo(() =>*/
        _overlays.map(item => {
            const location = virtualToDisplay({ x: item.x, y: item.y });
            const size = virtualToDisplay({ x: item.width, y: item.height });

            return {
                ...item,

                x: location.x,
                y: location.y,
                width: size.x,
                height: size.y,
            };
        }
        )/*, [_overlays, virtualToDisplay])*/;

    // Handle conversion to virtual x/y during overlay transformation.
    const onOverlayTransformed = useCallback((id: string, shapeProps: OverlayShape) => {
        if (!_onOverlayTransformed) {
            return;
        }

        const location = displayToVirtual({ x: shapeProps.x, y: shapeProps.y });
        const size = displayToVirtual({ x: shapeProps.width, y: shapeProps.height });

        const virtualShapeProps = {
            ...shapeProps,

            x: location.x,
            y: location.y,
            width: size.x,
            height: size.y,
        };

        _onOverlayTransformed(id, virtualShapeProps, { displayToVirtual, virtualToDisplay, });
    }, [_onOverlayTransformed, displayToVirtual, virtualToDisplay]);

    // Handle conversion to virtual x/y during item addition.
    const onAddOverlay = useCallback((shapeProps: Partial<OverlayShape>): OverlayShape => {
        if (!_onAddOverlay) {
            return {
                ...shapeProps,
                shape: addOverlayShapeType,
            }as OverlayShape;
        }

        const newDisplaySize = { x: 80, y: 80 }; // Default is to make the size look conistant on screen regardless of underlying image size.
        let location = displayToVirtual({ x: shapeProps.x ?? 0, y: (shapeProps.y ?? 0) });
        if (addOverlayShapeType === 'rect') {
            // Position it so the click is in the middle of the new area rather than the top left as rects have special positioning.
            location = displayToVirtual({ x: (shapeProps.x ?? 0) - (newDisplaySize.x / 2), y: (shapeProps.y ?? 0) - (newDisplaySize.y / 2) }); 
        }
        const size = displayToVirtual(newDisplaySize);

        const virtualShapeProps = {
            ...shapeProps,
            shape: addOverlayShapeType,
            
            x: location.x,
            y: location.y,
            width: size.x,
            height: size.y,
        };

        const ret = _onAddOverlay(virtualShapeProps, { displayToVirtual, virtualToDisplay });

        const retLocation = virtualToDisplay({ x: ret.x, y: ret.y });
        const retSize = virtualToDisplay({ x: ret.width, y: ret.height });

        const screenRet = {
            ...ret,

            x: retLocation.x,
            y: retLocation.y,
            width: retSize.x,
            height: retSize.y,
        };

        return screenRet;
    }, [_onAddOverlay, displayToVirtual, virtualToDisplay, addOverlayShapeType]);

    // Handle what happens when we click on the overlay layer.
    const handleOverlayLayerClick = useCallback((e: KonvaEventObject<MouseEvent> | KonvaEventObject<TouchEvent>) => {
        // When we are clicked without a shape targetted then either clear the selection or add a new overlay.

        const clickedOnEmpty = e.target === e.target.getStage();
        if (!clickedOnEmpty) {
            return;
        }

        if (!allowAdd) {
            selectShape(null);
            return;
        }

        if (onAddOverlay) {
            const shapePosition = {
                x: (e as KonvaEventObject<MouseEvent>).evt.offsetX ?? (e as KonvaEventObject<TouchEvent>).evt.touches[0].clientX,
                y: (e as KonvaEventObject<MouseEvent>).evt.offsetY ?? (e as KonvaEventObject<TouchEvent>).evt.touches[0].clientY,
            };

            const newShape = onAddOverlay(shapePosition);
            // Automatically select the new overlay.
            // NOTE we don't trigger the onShapeClick event here so go straight to _selectShape.
            _selectShape(newShape.id);
        }
    }, [allowAdd, onAddOverlay, selectShape]);

    // Need to force a refresh once everything is displayed once to cope with bugs in react-konva's display.
    const [forceInitialRefresh, setForceRefreshCount] = useState<number>(0);
    useEffect(() => {
        if (forceInitialRefresh > 0) {
            return;
        }

        setTimeout(() => {
            setForceRefreshCount(prevState => prevState + 1);
        }, 1);
    }, [forceInitialRefresh, setForceRefreshCount]);

    // Calculate the height based on the width available on screen and the virtual dimensions we are working to
    // and make sure we trigger a refresh each time the window is resized.
    const [calculatedHeight, setCalcualtedHeight] = useState<number>(100);
    useEffect(() => {
        function handleResize() {
            if (!divRef.current) {
                return;
            }

            const height = (divRef.current.clientWidth / virtualSize.width) * virtualSize.height;
            setCalcualtedHeight(height);
        }

        // Do the sizeing on first render.
        handleResize();

        // Trigger the sizing calculation again when the window size changes.
        window.addEventListener('resize', handleResize);

        // Cleanup the resize event handler when the effect is cleaned up
        return () => window.removeEventListener('resize', handleResize);
    }, [setForceRefreshCount, virtualSize, divRef, setCalcualtedHeight]);

    return (
        <div key={forceInitialRefresh} ref={divRef} className="interactive-image"
            style={{
                backgroundImage: src ? `url("${src}")` : undefined,
                height: calculatedHeight,
            }}
            >
            {
                needToCalculateVirtualSize ? (
                    <img className="interactive-image-loading-img" src={src} onLoad={(e) => setVirtualSize({ width: e.currentTarget.naturalWidth, height: e.currentTarget.naturalHeight })} alt="" />
                ) : (
                        <Stage
                            width={divRef.current?.clientWidth ?? 500}
                            height={divRef.current?.clientHeight ?? 500}
                            onMouseDown={handleOverlayLayerClick}
                            onTouchStart={handleOverlayLayerClick}
                        >
                            <Layer>
                                {
                                    overlays.map(item => {
                                        return (
                                            <ResizableOverlay key={item.id}
                                                shapeProps={item}
                                                allowTransform={allowTransform}
                                                isSelected={item.id === selectedId}
                                                onSelect={() => {
                                                    selectShape(item.id);
                                                }}
                                                onChange={(newAttrs: any) => {
                                                    if (onOverlayTransformed) {
                                                        onOverlayTransformed(item.id, newAttrs);
                                                    }
                                                }}
                                            />
                                        );
                                    })
                                }
                            </Layer>
                        </Stage>
                        )
            }
        </div>
    );
};
