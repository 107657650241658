import * as React from 'react';
import { Row, Col, CustomInput } from 'reactstrap';
import { Guid } from 'guid-string';

export interface TwoValueSwitchProps {
    leftLabel: string,
    rightLabel: string,
    checked: boolean,
    onChange?: (checked: boolean) => void,
    disabled?: boolean,
}

/**
 * A switch component that lets the user select between two values leftLabel (false), rightLabel (true).
 */
export const TwoValueSwitch = (props: TwoValueSwitchProps) => {
    const { checked, onChange, leftLabel, rightLabel, disabled } = props;
    const [uniqueName] = React.useState<string>(Guid.newGuid());

    return (
        <Row noGutters>
            <Col xs="auto" className={`${checked ? "text-muted" : ""} pr-2`}>
                {leftLabel}
            </Col>
            <Col xs="auto">
                <CustomInput id={uniqueName} name={uniqueName} type="switch" checked={checked} onChange={e => { if (onChange) { onChange(e.currentTarget.checked); } }} disabled={disabled} />
            </Col>
            <Col className={checked ? "" : "text-muted"}>
                {rightLabel}
            </Col>
        </Row>
    );
};