import { Redirect } from 'react-router';


/**
 * Default Distributor Administration home page.
 */
export const DistributorAdministrationHome = () => {

    return (
        <>
            <Redirect to={`/manage/distributor/dashboard`} />
        </>
    );
};