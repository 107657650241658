import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { Button, ButtonGroup } from "reactstrap";
import "./webhookEventTypeButton.scss";

export interface WebhookEventTypeButtonProps {
    eventType: string,
    isSelected: boolean,
    toggleSelected?: () => void,
    gray?: boolean,
    readonly?: boolean,
}

/**
 * Button for toggling a question tag on or off.
 */
export const WebhookEventTypeButton = (props: WebhookEventTypeButtonProps) => {
    const {
        eventType,
        isSelected,
        toggleSelected,
        gray = false,
        readonly = false,
    } = props;

    const onToggleSelected = useCallback(() => {
        if (toggleSelected) {
            toggleSelected();
        }
    }, [toggleSelected]);

    return (
        <ButtonGroup className={`webhook-event-type-button ${gray ? 'webhook-event-type-button-gray' : ''} ${readonly ? 'webhook-event-type-button-readonly' : ''}`} >
            <Button color="secondary" outline={!isSelected} onClick={onToggleSelected}>
                {eventType}
            </Button>
            <ConditionalFragment showIf={isSelected && !readonly}>
                <Button color="secondary" outline={!isSelected} onClick={onToggleSelected}>
                    <FontAwesomeIcon icon="times" />
                </Button>
            </ConditionalFragment>
        </ButtonGroup>
        );
};
