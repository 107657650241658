import { Col, Row } from "reactstrap";
import { AssessmentType } from "../../../api/main/models/codeOnly/AssessmentType";
import { ScoreProgressBar } from "../../assessmentSessions/launch/progress/ScoreProgressBar";
import { QuestionScore } from "../../assessmentSessions/launch/utilities/calculateQuestionScore";

export interface IndividualScoreProgressBarProps {
    value: QuestionScore,
    title?: string,
    footer?: string,
    assessmentType: AssessmentType,
}

/**
 * Show an individual score as a progress bar, with supporting data around it.
 */
export const IndividualScoreProgressBar = (props: IndividualScoreProgressBarProps) => {
    const {
        value,
        title,
        footer,
        assessmentType,
    } = props;

    return (
        <div className="text-center" style={{ width: '100%' }}>
            <div>
                <strong>
                    {title}
                </strong>
            </div>
            <Row>
                <Col>
                </Col>
                <Col xs="auto">
                    <ScoreProgressBar
                        value={value}
                        assessmentType={assessmentType}
                    />
                </Col>
                <Col>
                </Col>
            </Row>
            <div className="text-muted">
                <small>
                    {footer}
                </small>
            </div>
        </div>
        );
};