import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { useSdkOverviewViewModel } from "../../api/main/sdkClients/viewModels/useSdkOverviewViewModel";
import { useCurrentUserOrEmulatedSubscriptionId } from "../../globalState/subscriptions/useCurrentUserOrEmulatedSubscriptionId";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { ManageNavigation } from "../manage/ManageNavigation";
import { Background } from "../shared/background/Background";
import { Banner } from "../shared/Banner";
import { LoadingIndicator } from "../shared/LoadingIndicator";
import { MainContainer } from "../shared/MainContainer";
import { PillsNavBar } from "../shared/pillsNavBar/PillsNavBar";
import { StickyToolbar } from "../shared/StickyToolbar";
import { SdkClientsTable } from "./sdkClients/SdkClientsTable";
import "./sdkOverview.scss";
import { SdkWebhooksTable } from "./sdkWebhooks/SdkWebhooksTable";

/**
 * SDK/API overview screen for a subscription.
 */
export const SdkOverview = () => {
    // We always show for the current subscription.
    const subscriptionId = useCurrentUserOrEmulatedSubscriptionId();

    const { t } = useTranslation();

    // Load the data.
    const {
        data: { subscription, sdkClients, sdkWebhooks },
        isLoading, errors: loadErrors
    } = useSdkOverviewViewModel(subscriptionId);

    // Render the UI.
    //
    return (
        <Background className="sdk-overview">
            <Banner fluid>
                <StickyToolbar>
                    <Row>
                        <Col xs={12} md="auto">
                            <h1>
                                {t('sdkOverview.heading', 'API and SDK')}
                            </h1>
                        </Col>
                        <Col>
                            <PillsNavBar>
                                <ManageNavigation />
                            </PillsNavBar>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h3 className="text-muted">
                                {subscription?.companyName}
                            </h3>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer>
                <AlertOnErrors errors={[loadErrors,]} />

                {/* Introduction text */}
                <div>
                    <h3>
                        {t('sdkOverview.documentation.heading', 'API and SDK documentation')}
                    </h3>
                    <div>
                        <p>
                            {t('sdkOverview.documentation.info.p1', 'Esitu Drive allows integration with other applications and SaaS services through standards based APIs and webhooks.')}
                        </p>
                        <p>
                            {t('sdkOverview.documentation.info.p2', 'You can use the available APIs to connect your own applications or SaaS services to Esitu Drive.  These APIs give you access to query or mutate information within Esitu Drive on demand, on a schedule, or when events happen within your own application.  To be notified of events happening within Esitu Drive, you can combine these APIs with webhooks to allow us to notify your application when events happen and let you react to those events in realtime.')}
                        </p>
                        <p>
                            {t('sdkOverview.documentation.info.p3', 'Our API is entirely HTTP/HTTPS based so it can be used from any programming language, operating system, and environment.  You just need to generate a unique API key for your application to get started.')}
                        </p>
                        <p>

                            {t('sdkOverview.documentation.info.p4', 'We recommend each application/integration should be given its own unique API key to keep management and expiration of them secure.')}
                        </p>
                        <p>
                            {t('sdkOverview.documentation.info.p5', 'API keys should be kept secret and passed in the HTTP header with every request in the format:')}
                            <br />
                            <code>
                                {t('sdkOverview.documentation.info.apiKeyHeaderFormat', 'x-api-key: YOURAPIKEY')}
                            </code>
                        </p>
                        <p>
                            {t('sdkOverview.documentation.info.p6', 'For additional security each API key has a restricted list of IP addresses that access is allowed from.  When developing be sure to allow your development IP address in the list as well as any IP addresses that may be used in your production environment.')}
                        </p>
                        <p>
                            {t('sdkOverview.documentation.info.p7', 'We provide both GraphQL and REST apis to allow you to use the technology that is most compatible with your own applications and technical teams when building integrations.  For full details of the APIs select the most suitable documentation link below.  Each link includes an interactive playground environment to allow you to expiriment with the API directly from your browser.')}
                        </p>
                    </div>
                </div>

                {/* Links to documentation */}
                <div className="mb-3">
                    <Row>
                        <Col md={6}>
                            <a href="/api/graphql-playground/index.html" target="_blank" className="btn btn-outline-primary sdk-overview-documentation-button">
                                <div className="sdk-overview-documentation-button-icon">
                                    <FontAwesomeIcon icon="project-diagram" />
                                </div>
                                <div className="sdk-overview-documentation-button-title">
                                    {t('sdkOverview.documentation.graphQL.title', 'GraphQL API documentation')}
                                </div>
                                <div className="sdk-overview-documentation-button-playground">
                                    {t('sdkOverview.documentation.graphQL.playground', 'Try it yourself with the built in interactive tools powered by GraphQL Playground')}
                                </div>
                            </a>
                        </Col>
                        <Col md={6}>
                            <a href="api/rest/v1.0/swagger/index.html" target="_blank" className="btn btn-outline-primary sdk-overview-documentation-button">
                                <div className="sdk-overview-documentation-button-icon">
                                    <FontAwesomeIcon icon="terminal" />
                                </div>
                                <div className="sdk-overview-documentation-button-title">
                                    {t('sdkOverview.documentation.rest.title', 'REST API documentation')}
                                </div>
                                <div className="sdk-overview-documentation-button-playground">
                                    {t('sdkOverview.documentation.rest.playground', 'Try it yourself with the built in interactive tools powered by Swagger UI')}
                                </div>
                            </a>
                        </Col>
                    </Row>
                </div>

                {/* SDK Clients */}
                <Card>
                    <CardHeader>
                        {t('sdkOverview.sdkClients.heading', 'Application API keys')}
                    </CardHeader>
                    <CardBody>
                        <SdkClientsTable items={sdkClients} isLoading={isLoading} />
                    </CardBody>
                </Card>

                {/* Webhooks */}
                <Card>
                    <CardHeader>
                        {t('sdkOverview.sdkWebhooks.heading', 'Webhooks')}
                    </CardHeader>
                    <CardBody>
                        <SdkWebhooksTable items={sdkWebhooks} sdkClients={sdkClients} isLoading={isLoading} />
                    </CardBody>
                </Card>
            </MainContainer>
        </Background>
        );
};