import { useEffect, useMemo, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { BlobReference } from "../../../../api/main/models/BlobReference";
import { Question } from "../../../../api/main/models/Question";
import { QuestionFeedback } from "../../../../api/main/models/QuestionFeedback";
import { HtmlDisplay } from "../../../../shared/htmlEditor";
import { subsitutePlaceholders } from "../../../../utilities/subsitutePlaceholders";
import { QuestionScore } from "../utilities/calculateQuestionScore";

export interface ShowQuestionFeedbackProps {
    model: Question,
    feedbacks: Array<QuestionFeedback>,
    blobReferences: Array<BlobReference>,

    questionScore: QuestionScore,

    // Event raised when a question has been completed.
    onPageComplete: () => void,
}

/**
 * Show feedback for an individual question for an executing assessments.
 * @param props
 */
export const ShowQuestionFeedback = (props: ShowQuestionFeedbackProps) => {
    const {
        questionScore,
        feedbacks,
        blobReferences,
        onPageComplete,
    } = props;

    const { t } = useTranslation();

    // Work out which feedback to show.
    const { activeFeedback } = useMemo(() => {
        // Calculate the question score.
        // Find the best feedback for the total score.
        const activeFeedback = feedbacks.find(item => item.minScore <= questionScore.score && questionScore.score <= item.maxScore);
        return {
            activeFeedback,
        };
    }, [feedbacks, questionScore]);


    const videoBlob = useMemo(() => blobReferences.find(it => it.id === activeFeedback?.videoBlobReferenceId), [blobReferences, activeFeedback]);
    const imageBlob = useMemo(() => blobReferences.find(it => it.id === activeFeedback?.imageBlobReferenceId), [blobReferences, activeFeedback]);

    // Some calls to apis (such as saving clicks) will cause us to generate a new secure video URL.  To make sure this doesn't reset out
    // video we cache the poster and video urls as we original receive them into our state and always use them.
    const [imageUrl] = useState<string>(imageBlob?.url ?? '');
    const [videoUrl] = useState<string>(videoBlob?.url ?? '');

    // Replace placeholders in the feedback HTML.
    const feedbackHtml = useMemo(() => {
        let ret = activeFeedback?.feedbackText ?? '';

        ret = subsitutePlaceholders(
            ret,
            {
                ...questionScore,
            }
        );

        return ret;
    }, [activeFeedback, questionScore]);

    // Reading of feedback is optional so we are complete as soon as we're shown.
    useEffect(() => {
        onPageComplete();
    }, [onPageComplete]);

    // If we have no feedback, show a default page that just tells the user their score.
    if (!activeFeedback) {
        return (
            <div>
                <h1>{t('showQuestionFeedback.defaultFeedback.heading', 'No feedback available for this question')}</h1>
                <p>
                    {t('showQuestionFeedback.defaultFeedback.text', 'Sorry there is no feedback available for you on this question')}
                </p>
            </div>
            );
    }

    // Otherwise show the real feedback.
    return (
        <div>
            {/* Show video feedback if we have it. */}
            <ConditionalFragment showIf={!!videoUrl}>
                <div className="embed-responsive embed-responsive-16by9">
                    <video
                        className="embed-responsive-item"
                        src={videoUrl}
                        poster={imageUrl}
                        playsInline={true}
                        controls={true}
                    >
                    </video>
                </div>
            </ConditionalFragment>

            {/* Show image feedback if we have it (and don't have a video) */}
            <ConditionalFragment showIf={!!imageUrl && !videoUrl}>
                <img className="img-fluid" src={imageUrl} alt="Feedback" />
            </ConditionalFragment>

            <div className="mt-4">
                <HtmlDisplay html={feedbackHtml} />
            </div>
        </div>
        );
};