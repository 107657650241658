import { overallRiskService, RiskRules } from "../../../../services/OverallRiskService";
import { QuestionScore } from "./calculateQuestionScore";

/**
 * Creates a QuestionScore from a number.
 */
export function questionScoreFromNumber(value: number, notStarted: boolean, riskRules?: RiskRules | undefined | null): QuestionScore  {
    let score: number = value;

    if (score < 0) {
        score = 0;
    }

    if (score > 100) {
        score = 100;
    }

    const riskCategory = overallRiskService.getRiskCategory(score, notStarted, riskRules);

    return {
        score,
        minScore: 0,
        maxScore: 100,
        riskCategory,
    };
}