import gql from "graphql-tag";
import { Guid } from "guid-string";
import moment from "moment";
import { assessmentFields } from "../generated/assessmentFields";
import { AssessmentType } from "./codeOnly/AssessmentType";
import { FeedbackStyle } from "./codeOnly/FeedbackStyle";

export const assessmentFieldsFragment = gql`
    fragment assessmentFields on Assessment {
        id
        name
        assessmentType
        isSmartIndividuality
        feedbackStyle
        archived
        text
        imageBlobReferenceId
        tileText
        creationDate
        maxSuspiciousAnswers
        suspiciousAnswerText
        highRiskBelow
        mediumRiskBelow
    }
`;


export type Assessment = Omit<assessmentFields, '__typename'>;

export const assessmentDefaultValues = (): Partial<Assessment> => ({
    id: Guid.newGuid(),
    name: '',
    assessmentType: AssessmentType.Assessment,
    isSmartIndividuality: false,
    feedbackStyle: FeedbackStyle.NoFeedback,
    archived: false,
    text: '',
    imageBlobReferenceId: null,
    tileText: '',
    creationDate: moment().toISOString(),
    maxSuspiciousAnswers: 0,
    suspiciousAnswerText: '',
    highRiskBelow: 30,
    mediumRiskBelow: 70,
});