import { IdentityRoles } from "../../../configure/security/IdentityRoles";
import { RouteEntry } from "../../../shared/routes";
import { DriversList } from "./DriversList";
import { DeleteDriver } from "./DeleteDriver";
import { CreateDriver, EditDriver } from "./EditDriver";


export const driversRoutes: Array<RouteEntry> = [
    { path: '/manage/drivers', exact: true, component: DriversList, authorize: true, requireRole: IdentityRoles.DriverManagement },
    { path: '/manage/drivers/edit/:id', component: EditDriver, authorize: true, requireRole: IdentityRoles.DriverManagement },
    { path: '/manage/drivers/add', component: CreateDriver, authorize: true, requireRole: IdentityRoles.DriverManagement },
    { path: '/manage/drivers/delete/:id', component: DeleteDriver, authorize: true, requireRole: IdentityRoles.DriverManagement },

    { path: '/manage/teams/details/:subscriptionTeamId/drivers', exact: true, component: DriversList, authorize: true, requireRole: IdentityRoles.DriverManagement },
    { path: '/manage/teams/details/:subscriptionTeamId/drivers/edit/:id', component: EditDriver, authorize: true, requireRole: IdentityRoles.DriverManagement },
    { path: '/manage/teams/details/:subscriptionTeamId/drivers/add', component: CreateDriver, authorize: true, requireRole: IdentityRoles.DriverManagement },
    { path: '/manage/teams/details/:subscriptionTeamId/drivers/delete/:id', component: DeleteDriver, authorize: true, requireRole: IdentityRoles.DriverManagement },
];
