import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { Button, ButtonGroup } from "reactstrap";
import { QuestionTag } from "../../../api/main/models/QuestionTag";
import "./questionTagButton.scss";

export interface QuestionTagButtonProps {
    tag: QuestionTag,
    isSelected: boolean,
    toggleSelected?: () => void,
    gray?: boolean,
    readonly?: boolean,
}

/**
 * Button for toggling a question tag on or off.
 */
export const QuestionTagButton = (props: QuestionTagButtonProps) => {
    const {
        tag,
        isSelected,
        toggleSelected,
        gray = false,
        readonly = false,
    } = props;

    const onToggleSelected = useCallback(() => {
        if (toggleSelected) {
            toggleSelected();
        }
    }, [toggleSelected]);

    return (
        <ButtonGroup className={`question-tag-button ${gray ? 'question-tag-button-gray' : ''} ${readonly ? 'question-tag-button-readonly' : ''}`} >
            <Button color="secondary" outline={!isSelected} onClick={onToggleSelected}>
                {tag.name}
            </Button>
            <ConditionalFragment showIf={isSelected && !readonly}>
                <Button color="secondary" outline={!isSelected} onClick={onToggleSelected}>
                    <FontAwesomeIcon icon="times" />
                </Button>
            </ConditionalFragment>
        </ButtonGroup>
        );
};
