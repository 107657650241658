import * as React from 'react';
import { SubscriptionAssessmentCreateInput, SubscriptionAssessmentUpdateInput } from '../generated/globalTypes';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useCreateSubscriptionAssessmentCallback } from './useCreateSubscriptionAssessmentCallback';
import { useUpdateSubscriptionAssessmentCallback } from './useUpdateSubscriptionAssessmentCallback';
import { SubscriptionAssessment } from '../models/SubscriptionAssessment';

/**
 * Get a callback to save a SubscriptionAssessment in the store using either a create or update.
 */
export function useSaveSubscriptionAssessmentCallback(options: SaveInStoreOptions<SubscriptionAssessment, string> = {}) {
    const [_create, createStatus] = useCreateSubscriptionAssessmentCallback();
    const create = React.useCallback((model: Partial<SubscriptionAssessment>) => _create(model as SubscriptionAssessmentCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateSubscriptionAssessmentCallback();
    const update = React.useCallback((id: string, changes: Partial<SubscriptionAssessment>) => _update(id, changes as SubscriptionAssessmentUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
