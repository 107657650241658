import gql from "graphql-tag";
import { Guid } from "guid-string";
import moment from "moment";
import { questionSequenceQuestionFields } from "../generated/questionSequenceQuestionFields";

export const questionSequenceQuestionFieldsFragment = gql`
    fragment questionSequenceQuestionFields on QuestionSequenceQuestion {
        id
        parentQuestionId
        displayOrder
        childQuestionId
        archived
    }
`;


export type QuestionSequenceQuestion = Omit<questionSequenceQuestionFields, '__typename'>;

// Default values.
export const questionSequenceQuestionDefaultValues = (): Partial<QuestionSequenceQuestion> => ({
    id: Guid.newGuid(),
    displayOrder: moment().unix(),
    parentQuestionId: undefined,
    childQuestionId: undefined,
});