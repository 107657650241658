import * as React from 'react';
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { AsyncLoadResult, AsyncLoadOptions } from '../../../../shared/abstractStore';
import { editAssessmentSupportingDataQuery } from '../../generated/editAssessmentSupportingDataQuery';
import { mainApiConfig } from '../../../../configure/mainApiConfig';
import { questionTagFieldsFragment } from '../../models/QuestionTag';
import { driverMetricFieldsFragment } from '../../models/DriverMetric';
import { questionFieldsFragment } from '../../models/Question';

/**
 * Supporting data for EditAssessment that loads static data (e.g. dropdowns) that are not changed by the model being used.
 * @param options
 */
export function useEditAssessmentSupportingData(options: AsyncLoadOptions = {}): AsyncLoadResult<editAssessmentSupportingDataQuery> {
    // Query the data we need form the api.
    const [load, { data, loading, error, refetch }] = useLazyQuery<editAssessmentSupportingDataQuery>(
        gql`
        query editAssessmentSupportingDataQuery {
            questionTags {
                ...questionTagFields
            }

            driverMetrics {
                ...driverMetricFields
            }

            questions {
                ...questionFields
            }

        }

        ${questionTagFieldsFragment}
        ${driverMetricFieldsFragment}
        ${questionFieldsFragment}
        `,
        {
            variables: {
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load])

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error
    }), [data, refresh, loading, error]);
}