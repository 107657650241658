import { Button, Form, Label, FormGroup, Spinner, Input, Row, Col } from 'reactstrap';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContainer } from '../shared/MainContainer';
import { useParams, useHistory } from 'react-router';
import { FormButtons } from '../shared/FormButtons';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { useAsyncCallback } from 'react-use-async-callback';
import { useUsersListViewBaseViewModel } from '../../api/main/profiles/viewModels/useUsersListViewBaseViewModel';
import { useDeleteSubscriptionCallback } from '../../api/main/subscriptions/useDeleteSubscriptionCallback';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Banner } from '../shared/Banner';
import { Background } from '../shared/background/Background';
import { useLockoutCallback } from '../../api/account';
import { useDeleteSubscriptionTeamCallback } from '../../api/main/subscriptionTeams/useDeleteSubscriptionTeamCallback';
import { useDeleteProfileCallback } from '../../api/main/profiles/useDeleteProfileCallback';

/**
 * Delete a subscription.
 */
export const DeleteSubscription = () => {
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();
    const { data: { subscription: model, items, subscriptionTeams }, isLoading, errors: loadErrors } = useUsersListViewBaseViewModel({ subscriptionId: id });
    const [remove, { errors: removeErrors }] = useDeleteSubscriptionCallback();
    const [removeProfile, { errors: removeProfileErrors }] = useDeleteProfileCallback();
    const [removeTeam, { errors: removeTeamErrors }] = useDeleteSubscriptionTeamCallback();
    const [lockout, { errors: lockoutErrors }] = useLockoutCallback();
    const history = useHistory();

    const [onRemoveFormSubmit, { isExecuting: isRemoving, errors: removeFormErrors }] = useAsyncCallback(async () => {
        if (!model) {
            return;
        }

        items.forEach(item => lockout({ email: item.user.email, archive: true }));
        items.forEach(item => removeProfile(item.id));
        subscriptionTeams.forEach(item => removeTeam(item.id));

        await remove(model.id);

        history.goBack();
    }, [model, history]);

    return (
        <Background>
            <Banner>
                <Row>
                    <Col>
                        <h1>{t('deleteSubscription.heading', 'Are you sure you want to delete this subscription?')}</h1>
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
            </Banner>

            <MainContainer>
                <AlertOnErrors errors={[loadErrors, removeFormErrors, removeErrors, removeProfileErrors, removeTeamErrors, lockoutErrors]} />

                <Form onSubmit={e => { e.preventDefault(); onRemoveFormSubmit(); }}>
                    <FormGroup>
                        <Label htmlFor="name">{t('deleteSubscription.companyName.label', 'Name')}</Label>
                        <Input name="name" type="text" readOnly plaintext value={model?.companyName ?? ''} />
                        <Input name="name" type="text" readOnly plaintext value={`This will remove ${model?.currentUsers} users and ${subscriptionTeams?.length} subscription teams. Do you wish to continue?`} />
                    </FormGroup>
                    
                    <FormButtons>
                        <ConditionalFragment showIf={!isLoading}>
                            <ButtonAsync color="danger" isExecuting={isRemoving}
                                executingChildren={<><Spinner size="sm" /> {t('common.deleting', 'Deleting...')}</>}>
                                <FontAwesomeIcon icon="trash" />
                                <> {t('common.delete', 'Delete')}</>
                            </ButtonAsync>
                        </ConditionalFragment>
                        <Button type="button" color="primary" outline onClick={e => history.goBack()}>
                            {t('common.cancel', 'Cancel')}
                        </Button>
                    </FormButtons>
                </Form>
            </MainContainer>
        </Background>
    );
};
