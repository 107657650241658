import { useMemo, SetStateAction, useCallback, Dispatch } from "react";

/**
 * Hook that parses json and provides useState style access to the parsed value and access to setting the value. 
 **/
export function useJsonObject<T>(json: string, onJsonChanged: (json: string) => void): [T, Dispatch<SetStateAction<T>>] {
    // Parse the json into an object and cast it as a T.
    const value = useMemo(() => JSON.parse(json), [json]) as T;

    // Return a setter that behaves like J
    const setValue = useCallback(newValue => {
        // Allow value to be a function so we have same API as useState
        let valueToStore = newValue;
        if (newValue instanceof Function) {
            valueToStore = newValue(value);
        }

        // Save state back to original source.
        const newJson = JSON.stringify(valueToStore);
        onJsonChanged(newJson);
    }, [value, onJsonChanged]);

    return [value, setValue];
}
