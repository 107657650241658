import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useMemo } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";

export interface DueDateLabelProps {
    dueDate: string | undefined | null,
    completedDate?: string | undefined | null,
}

/**
 * Component for showing a due date (that may optionally also be completed).
 * @param props
 */
export const DueDateLabel = (props: DueDateLabelProps) => {
    const { dueDate, completedDate, } = props;

    const { t } = useTranslation();

    const color = useMemo(() => {
        const now = moment().toISOString();
        const dueSoonDateIso = moment().add(7, 'day').toISOString();
        const dueDateIso = moment(dueDate).toISOString();

        if (completedDate) {
            return 'success';
        }

        if (dueDateIso < now) {
            return 'danger';
        }

        if (dueDateIso < now) {
            return 'danger';
        }

        if (dueDateIso < dueSoonDateIso) {
            return 'warning';
        }

        return 'muted';
    }, [dueDate, completedDate]);

    return (
        <span className={`text-${color}`}>
            {
                completedDate ? (
                    <>
                        <FontAwesomeIcon icon="flag-checkered" />
                        <> </>
                        {t('dashboardAssessmentCard.finishedOn', 'Completed on: {{date, DD/MM/YYYY}}', { date: moment(completedDate).local() })}
                    </>
                ) : dueDate ? (
                        <>
                            <ConditionalFragment showIf={color === 'danger'}>
                                <FontAwesomeIcon icon="exclamation-triangle" />
                                <> </>
                            </ConditionalFragment>
                            {t('dashboardAssessmentCard.dueBy', 'Due by: {{date, DD/MM/YYYY}}', { date: moment(dueDate).local() })}
                    </>
                ) : null
            }
        </span>
        );
};