import gql from "graphql-tag";
import { Guid } from "guid-string";
import { assessmentItemDriverMetricQuantityFields } from "../generated/assessmentItemDriverMetricQuantityFields";

export const assessmentItemDriverMetricQuantityFieldsFragment = gql`
    fragment assessmentItemDriverMetricQuantityFields on AssessmentItemDriverMetricQuantity {
        id
        assessmentItemId
        driverMetricId
        quantity
        archived
    }
`;


export type AssessmentItemDriverMetricQuantity = Omit<assessmentItemDriverMetricQuantityFields, '__typename'>;

// Default values.
export const assessmentItemDriverMetricQuantityDefaultValues = (): Partial<AssessmentItemDriverMetricQuantity> => ({
    id: Guid.newGuid(),
    assessmentItemId: undefined,
    driverMetricId: undefined,
    quantity: undefined,
    archived: false,
});