import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { deleteSdkWebhookMutation, deleteSdkWebhookMutationVariables } from '../generated/deleteSdkWebhookMutation';
import { sdkWebhookFieldsFragment } from '../models/SdkWebhook';

/**
 * Get a callback to delete a SdkWebhook in the store.
 */
export function useDeleteSdkWebhookCallback(): [(id: string) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<deleteSdkWebhookMutation, deleteSdkWebhookMutationVariables>(
        gql`
            mutation deleteSdkWebhookMutation ($id: ID!) {
                deleteSdkWebhook(id: $id) {
                    ...sdkWebhookFields
                }
            }

            ${sdkWebhookFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (id: string) => {
        const result = await mutationAction({ variables: { id: id } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
