import moment, { Moment } from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useCurrentUserId } from "../../../../api/account";
import { Assessment } from "../../../../api/main/models/Assessment";
import { AssessmentItem } from "../../../../api/main/models/AssessmentItem";
import { AssessmentSession } from "../../../../api/main/models/AssessmentSession";
import { BlobReference } from "../../../../api/main/models/BlobReference";
import { QuestionType } from "../../../../api/main/models/codeOnly/QuestionType";
import { Question } from "../../../../api/main/models/Question";
import { launchAssessmentSessionViewModelQuery_viewModel_questionSequenceQuestions } from "../../../../api/main/generated/launchAssessmentSessionViewModelQuery"
import { QuestionAnswer } from "../../../../api/main/models/QuestionAnswer";
import { QuestionResponseEvent, questionResponseEventDefaultValues } from "../../../../api/main/models/QuestionResponseEvent";
import { ModelArrayChanges } from "../../../../shared/useChanges";
import { ShowQuestionLearningImage } from "./learningImage/ShowQuestionLearningImage";
import { ShowQuestionLearningText } from "./learningText/ShowQuestionLearningText";
import { ShowQuestionLearningVideo } from "./learningVideo/ShowQuestionLearningVideo";
import { ShowQuestionLocationDiscriminationImage } from "./locationDiscriminationImage/ShowQuestionLocationDiscriminationImage";
import { ShowQuestionLocationResponseImage } from "./locationResponseImage/ShowQuestionLocationResponseImage";
import { ShowQuestionMultipleChoiceVideo } from "./multipleChoiceVideo/ShowQuestionMultipleChoiceVideo";
import { ShowQuestionResponseTimeVideo } from "./responseTimeVideo/ShowQuestionResponseTimeVideo";
import { ShowQuestionSlidingScaleVideo } from "./slidingScaleVideo/ShowQuestionSlidingScaleVideo";
import { ShowQuestionnaireQuestionListComponent } from "../questions/QuestionnaireQuestions/QuestionnaireQuestionListComponent";
import { ShowQuestionInformationVideo } from "./informationVideo/ShowQuestionInformationVideo";
import { ShowQuestionInformationImage } from "./informationImage/ShowQuestionInformationImage";
import { ShowQuestionInformationText } from "./informationText/ShowQuestionInformationText";
import { ShowQuestionMultipleChoiceImage } from "./multipleChoiceImage/ShowQuestionMultipleChoiceImage";

export interface ShowQuestionProps {
    model: Question,
    allCurrentQuestionSequence: Array<launchAssessmentSessionViewModelQuery_viewModel_questionSequenceQuestions> | undefined,
    answers: Array<QuestionAnswer>,
    questionnaireQuestionAnswers: Array<QuestionAnswer>,
    assessmentSession: AssessmentSession,
    blobReferences: Array<BlobReference>,
    questionResponseEventsManager: ModelArrayChanges<QuestionResponseEvent, string>,
    assessment: Assessment,
    assessmentItem: AssessmentItem,

    // Event raised when a question has been completed.
    onPageComplete: () => void,
}

export interface ShowQuestionChildProps extends ShowQuestionProps {
    // Selecting an answer.
    selectedAnswerId: string | undefined,
    selectSingleAnswer: (answer: QuestionAnswer | undefined, currentQuestion: Question) => void,

    // Response time calculation.
    resetResponseTimeStart: () => void,
    calculateResponseTime: (now?: string) => number,
    addResponseEvent: (event: Partial<QuestionResponseEvent>) => void,
    cancelResponseEvent: (id: string) => void,

    cancelAllButHighestResponse?: () => void,

    // Has the maximum click rule failed?
    hasFailedMaximumClickRule: boolean,
    isMaximumClickRuleFailtureRecord: (item: QuestionResponseEvent) => boolean,
}

/**
 * Show a question for an executing assessments.
 * @param props
 */
export const ShowQuestion = (props: ShowQuestionProps) => {
    const {
        model,
        allCurrentQuestionSequence,
        answers,
        questionnaireQuestionAnswers,
        assessmentSession,
        questionResponseEventsManager,
        assessment,
        assessmentItem,
        onPageComplete,
    } = props;

    // Current user.
    const currentUserId = useCurrentUserId();

    const filteredAnswers = answers.filter(it => it.questionId === model.id && !it.archived) ?? []

    // Response time tracking.
    const [responseTimeStart, setResponseTimeStart] = useState<string>(() => moment().toISOString());
    const [answerSelected, setAnswerSelected] = useState<boolean>(false);
    const resetResponseTimeStart = useCallback(() => setResponseTimeStart(moment().toISOString()), [setResponseTimeStart]);
    const calculateResponseTime = useCallback((now?: string) => {
        let nowMoment: Moment;
        if (now) {
            nowMoment = moment(now);
        } else {
            nowMoment = moment();
        }

        const startMoment = moment(responseTimeStart);
        const milliseconds = nowMoment.diff(startMoment, 'milliseconds');

        // Convert to decimal seconds.
        const ret = milliseconds / 1000.0;
        return ret;
    }, [responseTimeStart]);

    const [savedQuestionnaireForJson, setSavedQuestionnaireForJson] = useState<string>('');
    useEffect(() => {

        if (model.questionType === QuestionType.Questionnaire) {

            var filteredSequenceQuestions = allCurrentQuestionSequence?.filter(item => !item.archived && item.parentQuestionId === model.id);         

            // If a response isnt archived, then it must be a completed response
            const completedResponses = questionResponseEventsManager.model.filter(item => !item.archived && !!filteredSequenceQuestions?.find(it => it.childQuestionId === item.questionId))

            // If we have all questions completed, then we should be able to progress
            if (completedResponses.length === filteredSequenceQuestions?.length) {
                const questionnaireJson = JSON.stringify(completedResponses);

                // If nothing has changed since we last saved, then we dont need to save again
                if (savedQuestionnaireForJson !== questionnaireJson) {

                    const savedQuestionnaireResponse = questionResponseEventsManager.model.find(item => item.questionId === model.id && !item.archived);
                    const now = moment().toISOString();
                    if (savedQuestionnaireResponse) {
                        // Only want to save a new response for this questionnaire if we dont already have one (this will check with every screen update so needs to check this)

                        questionResponseEventsManager.changeFor(savedQuestionnaireResponse.id, { archived: true, cancelEventDate: now });

                    }

                    var CombinedQuestionScore: number = 0;
                    var textQuestions: number = 0;

                    // Collect all the question scores into one
                    questionResponseEventsManager.added.forEach(item => {
                        if (item.questionId !== model.id && !item.archived) { CombinedQuestionScore = CombinedQuestionScore + (item?.score ?? 0) }
                        if (item.textInput !== "") { textQuestions = textQuestions + 1 }
                    });



                    // Divide by how many questions there are (for how much you got correct) This will need to remove text questions from th list, as they dont have a correct answer so cannot give score
                    let totalScore = 0;
                    if (completedResponses.length - textQuestions === 0) {
                        // If there are no questions, then the score is 0 (this is to stop a divide by 0 error)
                        totalScore = 0
                    } else {
                        totalScore = Math.floor(CombinedQuestionScore / (completedResponses.length - textQuestions));
                        if (isNaN(totalScore)) {
                            totalScore = 0;
                        }
                    }


                    // This should be moved now, or atleast have a logic change, as we need the score to update when the user changes an answer, which we werent worried about before.
                    questionResponseEventsManager.addFor({
                        ...questionResponseEventDefaultValues(),

                        eventDate: now,
                        assessmentId: assessment.id,
                        assessmentItemId: assessmentItem.id,
                        assessmentSessionId: assessmentSession.id,
                        questionId: model.id,
                        isCorrect: true,
                        userId: currentUserId ?? '',
                        score: totalScore ?? 0
                    });

                    onPageComplete()
                    setAnswerSelected(false);
                    setSavedQuestionnaireForJson(questionnaireJson);
                }                
            }
        }
    }, [model.questionType, allCurrentQuestionSequence, assessment.id, assessmentItem.id, assessmentSession.id, currentUserId, model.id, onPageComplete, questionResponseEventsManager,
        answerSelected, setSavedQuestionnaireForJson, savedQuestionnaireForJson]);

    // Keep track of the selected answer.
    const selectedAnswerId = useMemo(() =>
        questionResponseEventsManager.model.find(item => item.questionId === model.id && !item.archived)?.questionAnswerId ?? undefined
        , [questionResponseEventsManager, model]);

    // Used with multiple choices and sliding scale to select one of the answers as current
    const selectSingleAnswer = useCallback((answer: QuestionAnswer | undefined, currentQuestion: Question) => {

        // Clear any current answers in the response events.
        const existingAnswers = questionResponseEventsManager.model.filter(item => item.questionId === currentQuestion.id && !item.archived);

        const now = moment().toISOString();

        for (const existingAnswer of existingAnswers) {
            // Remove an old response if we have any
            questionResponseEventsManager.changeFor(existingAnswer.id, { archived: true, cancelEventDate: now });
        }

        if (answer) {
            // Add an event to show this answer was selected.
            questionResponseEventsManager.addFor({
                ...questionResponseEventDefaultValues(),

                eventDate: now,
                assessmentId: assessment.id,
                assessmentItemId: assessmentItem.id,
                assessmentSessionId: assessmentSession.id,
                questionAnswerId: answer.id,
                questionId: currentQuestion.id,
                isCorrect: answer.isCorrect,
                userId: currentUserId,
                score: answer.score,
                responseTimeSeconds: calculateResponseTime(now),
                subQuestionNumber: answer.subQuestionNumber,
            });

            setAnswerSelected(true);

        }
    }, [questionResponseEventsManager, currentUserId, assessment, assessmentSession, assessmentItem, calculateResponseTime]);

    // Add a response event (not a single choice answer).
    const addResponseEvent = useCallback((event: Partial<QuestionResponseEvent>) => {
        // Add an event to show this answer was selected.
        const now = moment().toISOString();
        questionResponseEventsManager.addFor({
            ...questionResponseEventDefaultValues(),

            eventDate: now,
            assessmentId: assessment.id,
            assessmentItemId: assessmentItem.id,
            assessmentSessionId: assessmentSession.id,
            questionAnswerId: null,
            questionId: model.id,
            isCorrect: false,
            userId: currentUserId,
            score: 0,
            responseTimeSeconds: calculateResponseTime(now),

            ...event,
        });
    }, [questionResponseEventsManager, model, currentUserId, assessment, assessmentSession, assessmentItem, calculateResponseTime]);

    // Cancel a response event (not a single choice answer).
    const cancelResponseEvent = useCallback((id: string) => {
        const event = questionResponseEventsManager.model.find(item => item.id === id);
        if (!event) {
            return;
        }

        questionResponseEventsManager.changeFor(
            event.id,
            {
                cancelEventDate: moment().toISOString(),
                archived: true,
            }
        );
    }, [questionResponseEventsManager]);

    // Check if the maximum number of allowed clicks has expired, if it has, then apply an "auto fail" record.  If it hasn't then remove the auto fail
    // record if it exists.
    const maximumClickRuleFailScore = -100000;
    const isMaximumClickRuleFailtureRecord = useCallback((item: QuestionResponseEvent) => item.score === maximumClickRuleFailScore ? true : false, [maximumClickRuleFailScore]);
    const performMaximumClickRule = useCallback(() => {
        // This score is used to auto fail a question (effectivly gives a score of 0).

        // Lookup the active fail record (if any).
        const currentFailRecord = questionResponseEventsManager.model.find(it => it.questionId === model.id && isMaximumClickRuleFailtureRecord(it) && !it.archived);

        // Get the current live clicks (exclude any which have been archived/cancelled).
        const currentClicks = questionResponseEventsManager.model.filter(it => it.questionId === model.id && !it.archived && !isMaximumClickRuleFailtureRecord(it));

        // If we have more clicks than we allow, fail the record (unless it already has a fail record).
        if (currentClicks.length > model.maximumClicks) {
            if (currentFailRecord) {
                return;
            }

            const now = moment().toISOString();
            questionResponseEventsManager.addFor({
                ...questionResponseEventDefaultValues(),

                eventDate: now,
                assessmentId: assessment.id,
                assessmentItemId: assessmentItem.id,
                assessmentSessionId: assessmentSession.id,
                questionAnswerId: null,
                questionId: model.id,
                isCorrect: false,
                userId: currentUserId,
                score: maximumClickRuleFailScore,
                responseTimeSeconds: calculateResponseTime(now),
                textInput: 'FailedMaximumClicks', // Helps when browsing the database, not to be relied upon anywhere.
            });
        } else {
            if (!currentFailRecord) {
                return;
            }

            questionResponseEventsManager.changeFor(
                currentFailRecord?.id || '',
                {
                    cancelEventDate: moment().toISOString(),
                    archived: true,
                }
            );
        }
    }, [questionResponseEventsManager, isMaximumClickRuleFailtureRecord, model, calculateResponseTime, assessment, maximumClickRuleFailScore, assessmentItem, assessmentSession, currentUserId]);

    const [cancelAllButHighestResponseComplete, setCancelAllButHighestResponseComplete] = useState(false);
    const calculateHighestResponseTimeScore = useCallback(() => {

        // Get the responses for the current question.
        const currentResponses = questionResponseEventsManager.model.filter(item => item.questionId === model.id && !item.archived);

        // Find the highest score response Id.
        const highestScore = currentResponses.reduce((prev, current) => (prev.score > current.score) ? prev : current).id;

        // Archive all responses that are not the highest score.
        for (const currentResponse of currentResponses) {
            // Dont want to archive any max click scores.
            if (currentResponse.id !== highestScore && currentResponse.score !== maximumClickRuleFailScore) {
                questionResponseEventsManager.changeFor(
                    currentResponse.id,
                    {
                        cancelEventDate: moment().toISOString(),
                        archived: true,
                    }
                );
            }
        }

        setCancelAllButHighestResponseComplete(true);

    }, [questionResponseEventsManager, model, maximumClickRuleFailScore]);

    // Automatically apply the max click failure rules each time we have a change in questionResponseEventsManager (via performMaximumClickRule dependancies).
    useEffect(() => {
        // Dont want to retrigger this if we have completed the cancelAllButHighestResponse, as it leads to an archived max click response.
        if (!questionResponseEventsManager.model.length || cancelAllButHighestResponseComplete) {
            return;
        }

        performMaximumClickRule();
    }, [performMaximumClickRule, questionResponseEventsManager]);

    // Have we failed the maximum click rule?
    const hasFailedMaximumClickRule = useMemo(() => {
        const currentFailRecord = questionResponseEventsManager.model.find(it => it.questionId === model.id && isMaximumClickRuleFailtureRecord(it) && !it.archived);
        if (currentFailRecord) {
            return true;
        }

        return false;
    }, [questionResponseEventsManager, isMaximumClickRuleFailtureRecord, model]);

    // Show the right question component based on the question type.
    //
    const questionType = model.questionType as QuestionType;
    switch (questionType) {
        case QuestionType.MultipleChoiceVideo:
            return (
                <ShowQuestionMultipleChoiceVideo
                    {...props}
                    answers={filteredAnswers}
                    resetResponseTimeStart={resetResponseTimeStart}
                    calculateResponseTime={calculateResponseTime}
                    
                    selectedAnswerId={selectedAnswerId}
                    selectSingleAnswer={selectSingleAnswer}
                    addResponseEvent={addResponseEvent}
                    cancelResponseEvent={cancelResponseEvent}
                    hasFailedMaximumClickRule={hasFailedMaximumClickRule}
                    isMaximumClickRuleFailtureRecord={isMaximumClickRuleFailtureRecord}
                />
            );
        case QuestionType.SlidingScaleVideo:
            return (
                <ShowQuestionSlidingScaleVideo
                    {...props}
                    answers={filteredAnswers}
                    resetResponseTimeStart={resetResponseTimeStart}
                    calculateResponseTime={calculateResponseTime}

                    selectedAnswerId={selectedAnswerId}
                    selectSingleAnswer={selectSingleAnswer}
                    addResponseEvent={addResponseEvent}
                    cancelResponseEvent={cancelResponseEvent}
                    hasFailedMaximumClickRule={hasFailedMaximumClickRule}
                    isMaximumClickRuleFailtureRecord={isMaximumClickRuleFailtureRecord}
                />
            );
        case QuestionType.ResponseTimeVideo:
            return (
                <ShowQuestionResponseTimeVideo
                    {...props}
                    answers={filteredAnswers}
                    resetResponseTimeStart={resetResponseTimeStart}
                    calculateResponseTime={calculateResponseTime}

                    selectedAnswerId={selectedAnswerId}
                    selectSingleAnswer={selectSingleAnswer}
                    addResponseEvent={addResponseEvent}
                    cancelResponseEvent={cancelResponseEvent}
                    cancelAllButHighestResponse={calculateHighestResponseTimeScore}
                    hasFailedMaximumClickRule={hasFailedMaximumClickRule}
                    isMaximumClickRuleFailtureRecord={isMaximumClickRuleFailtureRecord}
                />
            );
        case QuestionType.LocationResponseImage:
            return (
                <ShowQuestionLocationResponseImage
                    {...props}
                    answers={filteredAnswers}
                    resetResponseTimeStart={resetResponseTimeStart}
                    calculateResponseTime={calculateResponseTime}

                    selectedAnswerId={selectedAnswerId}
                    selectSingleAnswer={selectSingleAnswer}
                    addResponseEvent={addResponseEvent}
                    cancelResponseEvent={cancelResponseEvent}
                    hasFailedMaximumClickRule={hasFailedMaximumClickRule}
                    isMaximumClickRuleFailtureRecord={isMaximumClickRuleFailtureRecord}
                />
            );
        case QuestionType.LocationDiscriminationImage:
            return (
                <ShowQuestionLocationDiscriminationImage
                    {...props}
                    answers={filteredAnswers}
                    resetResponseTimeStart={resetResponseTimeStart}
                    calculateResponseTime={calculateResponseTime}

                    selectedAnswerId={selectedAnswerId}
                    selectSingleAnswer={selectSingleAnswer}
                    addResponseEvent={addResponseEvent}
                    cancelResponseEvent={cancelResponseEvent}
                    hasFailedMaximumClickRule={hasFailedMaximumClickRule}
                    isMaximumClickRuleFailtureRecord={isMaximumClickRuleFailtureRecord}
                />
            );
        case QuestionType.MultipleChoiceImage:
            return (
                <ShowQuestionMultipleChoiceImage
                    {...props}
                    answers={filteredAnswers}
                    resetResponseTimeStart={resetResponseTimeStart}
                    calculateResponseTime={calculateResponseTime}

                    selectedAnswerId={selectedAnswerId}
                    selectSingleAnswer={selectSingleAnswer}
                    addResponseEvent={addResponseEvent}
                    cancelResponseEvent={cancelResponseEvent}
                    hasFailedMaximumClickRule={hasFailedMaximumClickRule}
                    isMaximumClickRuleFailtureRecord={isMaximumClickRuleFailtureRecord}
                />
            );
        case QuestionType.LearningVideo:
            return (
                <ShowQuestionLearningVideo
                    {...props}
                    answers={filteredAnswers}
                    resetResponseTimeStart={resetResponseTimeStart}
                    calculateResponseTime={calculateResponseTime}

                    selectedAnswerId={selectedAnswerId}
                    selectSingleAnswer={selectSingleAnswer}
                    addResponseEvent={addResponseEvent}
                    cancelResponseEvent={cancelResponseEvent}
                    hasFailedMaximumClickRule={hasFailedMaximumClickRule}
                    isMaximumClickRuleFailtureRecord={isMaximumClickRuleFailtureRecord}
                />
            );
        case QuestionType.LearningImage:
            return (
                <ShowQuestionLearningImage
                    {...props}
                    answers={filteredAnswers}
                    resetResponseTimeStart={resetResponseTimeStart}
                    calculateResponseTime={calculateResponseTime}

                    selectedAnswerId={selectedAnswerId}
                    selectSingleAnswer={selectSingleAnswer}
                    addResponseEvent={addResponseEvent}
                    cancelResponseEvent={cancelResponseEvent}
                    hasFailedMaximumClickRule={hasFailedMaximumClickRule}
                    isMaximumClickRuleFailtureRecord={isMaximumClickRuleFailtureRecord}
                />
            );
        case QuestionType.LearningText:
            return (
                <ShowQuestionLearningText
                    {...props}
                    answers={filteredAnswers}
                    resetResponseTimeStart={resetResponseTimeStart}
                    calculateResponseTime={calculateResponseTime}

                    selectedAnswerId={selectedAnswerId}
                    selectSingleAnswer={selectSingleAnswer}
                    addResponseEvent={addResponseEvent}
                    cancelResponseEvent={cancelResponseEvent}
                    hasFailedMaximumClickRule={hasFailedMaximumClickRule}
                    isMaximumClickRuleFailtureRecord={isMaximumClickRuleFailtureRecord}
                />
            );
        case QuestionType.Questionnaire:
            return (
                <>
                    <ShowQuestionnaireQuestionListComponent
                        currentQuestionnaire={model}
                        allCurrentQuestionSequence={allCurrentQuestionSequence}
                        answers={questionnaireQuestionAnswers}
                        selectedAnswerId={selectedAnswerId}
                        selectSingleAnswer={selectSingleAnswer}
                        questionResponseEventsManager={questionResponseEventsManager}

                        assessmentId={assessment.id}
                        assessmentItemId={assessmentItem.id}
                        assessmentSessionId={assessmentSession.id}

                        userId={currentUserId}
                    />
                </>
            );
        case QuestionType.InformationVideo:
            return (
                <ShowQuestionInformationVideo
                    {...props}
                    answers={filteredAnswers}
                    resetResponseTimeStart={resetResponseTimeStart}
                    calculateResponseTime={calculateResponseTime}

                    selectedAnswerId={selectedAnswerId}
                    selectSingleAnswer={selectSingleAnswer}
                    addResponseEvent={addResponseEvent}
                    cancelResponseEvent={cancelResponseEvent}
                    hasFailedMaximumClickRule={hasFailedMaximumClickRule}
                    isMaximumClickRuleFailtureRecord={isMaximumClickRuleFailtureRecord}
                />
            );
        case QuestionType.InformationImage:
            return (
                <ShowQuestionInformationImage
                    {...props}
                    answers={filteredAnswers}
                    resetResponseTimeStart={resetResponseTimeStart}
                    calculateResponseTime={calculateResponseTime}

                    selectedAnswerId={selectedAnswerId}
                    selectSingleAnswer={selectSingleAnswer}
                    addResponseEvent={addResponseEvent}
                    cancelResponseEvent={cancelResponseEvent}
                    hasFailedMaximumClickRule={hasFailedMaximumClickRule}
                    isMaximumClickRuleFailtureRecord={isMaximumClickRuleFailtureRecord}
                />
            );
        case QuestionType.InformationText:
            return (
                <ShowQuestionInformationText
                    {...props}
                    answers={filteredAnswers}
                    resetResponseTimeStart={resetResponseTimeStart}
                    calculateResponseTime={calculateResponseTime}

                    selectedAnswerId={selectedAnswerId}
                    selectSingleAnswer={selectSingleAnswer}
                    addResponseEvent={addResponseEvent}
                    cancelResponseEvent={cancelResponseEvent}
                    hasFailedMaximumClickRule={hasFailedMaximumClickRule}
                    isMaximumClickRuleFailtureRecord={isMaximumClickRuleFailtureRecord}
                />
            );


        default:
            return (<>{model.name} [{model.questionType}]</>);
    }
};