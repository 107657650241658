import * as React from 'react';
import { QuestionAnswerCreateInput, QuestionAnswerUpdateInput } from '../generated/globalTypes';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useCreateQuestionAnswerCallback } from './useCreateQuestionAnswerCallback';
import { useUpdateQuestionAnswerCallback } from './useUpdateQuestionAnswerCallback';
import { QuestionAnswer } from '../models/QuestionAnswer';

/**
 * Get a callback to save a QuestionAnswer in the store using either a create or update.
 */
export function useSaveQuestionAnswerCallback(options: SaveInStoreOptions<QuestionAnswer, string> = {}) {
    const [_create, createStatus] = useCreateQuestionAnswerCallback();
    const create = React.useCallback((model: Partial<QuestionAnswer>) => _create(model as QuestionAnswerCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateQuestionAnswerCallback();
    const update = React.useCallback((id: string, changes: Partial<QuestionAnswer>) => _update(id, changes as QuestionAnswerUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
