import { Button, Row, Col, Form, Label, FormGroup, Spinner, Input, Card } from 'reactstrap';
import { AlertOnErrors } from '../../../shared/alertOnErrors';
import { LoadingIndicator } from '../../shared/LoadingIndicator';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContainer } from '../../shared/MainContainer';
import { useParams, useHistory } from 'react-router';
import { useSdkWebhook } from '../../../api/main/sdkWebhooks/useSdkWebhook';
import { useChanges } from '../../../shared/useChanges';
import { useSaveSdkWebhookCallback } from '../../../api/main/sdkWebhooks/useSaveSdkWebhookCallback';
import { useValidatorCallback } from 'pojo-validator-react';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { FormButtons } from '../../shared/FormButtons';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { useAsyncCallback } from 'react-use-async-callback';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Banner } from '../../shared/Banner';
import { Background } from '../../shared/background/Background';
import { useJsonObject } from '../../../shared/useJsonObject';
import { sdkWebhookDefaultValues } from '../../../api/main/models/SdkWebhook';
import { useCurrentUserOrEmulatedSubscriptionId } from '../../../globalState/subscriptions/useCurrentUserOrEmulatedSubscriptionId';
import { useEditSdkWebhookSupportingData } from '../../../api/main/sdkWebhooks/viewModels/useEditSdkWebhookSupportingData';
import { HttpMethod, httpMethodDisplayName } from '../../../api/main/models/codeOnly/HttpMethod';
import { useMemo } from 'react';
import moment from 'moment';
import { WebhookEventTypeSelector } from './webhookEventTypeSelector/WebhookEventTypeSelector';
import { sdkWebhookEventTypes } from '../../../api/main/models/codeOnly/SdkWebhookEventType';
import { Guid } from 'guid-string';

interface EditSdkWebhookProps {
    isCreate?: boolean,
}

/**
 * Create a new sdkWebhook.
 */
export const CreateSdkWebhook = () => (<EditSdkWebhook isCreate={true} />);

/**
 * Edit a SdkWebhook.
 */
export const EditSdkWebhook = (props: EditSdkWebhookProps) => {
    const { isCreate } = props;

    const subscriptionId = useCurrentUserOrEmulatedSubscriptionId();

    const { t } = useTranslation();
    const { id } = useParams<{ id: string | undefined }>();
    const { data: { model: storeModel }, isLoading: _isLoading, errors: loadErrors } = useSdkWebhook(id);
    const { data: { sdkClients }, isLoading: isLoadingSupportingData, errors: supportingDataErrors } = useEditSdkWebhookSupportingData(subscriptionId);
    const isLoading = _isLoading || isLoadingSupportingData;
    const { model, change, changes } = useChanges(storeModel, isCreate ? { ...sdkWebhookDefaultValues(), } : undefined);
    const [save, { errors: saveErrors }] = useSaveSdkWebhookCallback();
    const history = useHistory();

    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            sdkClientId: () => !model?.sdkClientId ? t('editSdkWebhook.sdkClientId.required', 'SDK application is required') : '',
            url: () => !model?.url ? t('editSdkWebhook.url.required', 'URL is required') : '',
            httpMethod: () => !model?.httpMethod ? t('editSdkWebhook.httpMethod.required', 'HTTP method is required') : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, [model]);

    const [saveForm, { isExecuting: isSaving, errors: saveFormErrors }] = useAsyncCallback(async () => {
        if (!validate()) {
            return;
        }

        await save(model.id, changes, !!isCreate);

        history.goBack();
    }, [validate, save, model, changes, isCreate, history]);

    // Maintenance of header details (becomes JSON in the model.)
    const [headers, setHeaders] = useJsonObject<Array<{ name: string, value: string }>>(model?.headersJson ?? '[]', json => change({ headersJson: json }));

    // Maintenance of selected event types (becomes JSON in the model.)
    const [selectedEventTypes, setSelectedEventTypes] = useJsonObject<Array<string>>(model?.eventsJson ?? '[]', json => change({ eventsJson: json }));


    // Generate the sample JSON we will send.
    const { sampleJson, sampleQueryString } = useMemo(() => {
        // Use the selected events to generate the most overlapping detail we can from the event's combined data objects.
        let dataObject: any = null;
        for (const eventType of selectedEventTypes) {
            const myDataObject = sdkWebhookEventTypes.find(item => item.id === eventType)?.dataObject ?? {};

            // If we don't already have a data object, just use this one and move on.
            if (!dataObject) {
                dataObject = { ...myDataObject };
            } else {
                // We have a data object from another event type, so we want to create a new object with only keys that are in both.
                const myKeys = Object.keys(myDataObject);
                for (const key of Object.keys(dataObject)) {
                    if (!myKeys.find(it => it === key)) {
                        delete dataObject[key];
                    }
                }
            }

            
        }

        // Generate an object that contains all properties.
        const eventData = {
            event: selectedEventTypes.length === 1 ? selectedEventTypes[0] : 'EVENT_NAME',
            eventDate: moment().toISOString(),
            uniqueId: Guid.empty,
            data: dataObject ?? {},
        };

        // Generate the json.
        const json = JSON.stringify(eventData, null, 4);

        // Generate the query string.
        let queryString = '';
        for (const key of Object.keys(eventData).filter(it => it !== 'data')) {
            queryString += `&${key}=${encodeURIComponent((eventData as any)[key] ?? '')}`;
        }
        for (const key of Object.keys(eventData.data)) {
            queryString += `&${key}=${encodeURIComponent((eventData as any).data[key] ?? '')}`;
        }
        queryString = `?${queryString.substr(1)}`;

        return {
            sampleJson: json,
            sampleQueryString: queryString,
        }
    }, [selectedEventTypes]);

    return (
        <Background>
            <Banner>
                <Row>
                    <Col>
                        <h1>
                            {
                                isCreate ? (
                                    <>{t('editSdkWebhook.createHeading', 'Add webhook connection')}</>
                                ) : (
                                        <>{t('editSdkWebhook.editHeading', 'Edit webhook connection')}</>
                                    )
                            }
                        </h1>
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
            </Banner>

            <MainContainer>
                <AlertOnErrors errors={[loadErrors, supportingDataErrors, saveFormErrors, saveErrors]} />

                <Form onSubmit={e => { e.preventDefault(); saveForm(); }}>
                    <FormGroup>
                        <Label htmlFor="sdkClientId">{t('editSdkWebhook.sdkClientId.label', 'SDK application')}</Label>
                        <ValidatedInput name="sdkClientId" type="select" value={model.sdkClientId ?? ''} onChange={e => change({ sdkClientId: e.currentTarget.value })} onBlur={e => validate('sdkClientId')} validationErrors={validationErrors['sdkClientId']}>
                            <option value="">{t('editSdkWebhook.sdkClientId.pleaseSelect', '(Please select an SDK application for this webhook)')}</option>
                            {
                                sdkClients?.map(item => (
                                    <option key={item.id} value={item.id}>{item.name}</option>
                                    ))
                            }
                        </ValidatedInput>
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="url">{t('editSdkWebhook.url.label', 'URL')}</Label>
                        <Row>
                            <Col xs={12} md="auto">
                                <ValidatedInput name="httpMethod" type="select" value={model.httpMethod ?? ''} onChange={e => change({ httpMethod: e.currentTarget.value })} onBlur={e => validate('httpMethod')} validationErrors={validationErrors['httpMethod']}>
                                    {
                                        Object.values(HttpMethod)
                                            .map(item => (
                                                <option key={item} value={item}>
                                                    {httpMethodDisplayName(item, t)}
                                                </option>
                                            ))
                                    }
                                </ValidatedInput>
                            </Col>
                            <Col>
                                <ValidatedInput name="url" type="url" value={model.url ?? ''} onChange={e => change({ url: e.currentTarget.value })} onBlur={e => validate('url')} validationErrors={validationErrors['url']} />
                            </Col>
                        </Row>
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="headers">{t('editSdkWebhook.headers.label', 'HTTP headers to send with the request')}</Label>

                        <div>
                            {
                                headers.map((item, index) => (
                                    <div key={index} className="mb-2">
                                        <Row>
                                            <Col>
                                                <Input type="text" value={item.name}
                                                    onChange={e => {
                                                        // Edit the right index in the array.
                                                        setHeaders(prevState => {
                                                            let newValue = [...prevState];
                                                            newValue[index].name = e.currentTarget.value;
                                                            return newValue;
                                                        })
                                                    }}
                                                />
                                            </Col>
                                            <Col>
                                                <Input type="text" value={item.value}
                                                    onChange={e => {
                                                        // Edit the right index in the array.
                                                        setHeaders(prevState => {
                                                            let newValue = [...prevState];
                                                            newValue[index].value = e.currentTarget.value;
                                                            return newValue;
                                                        })
                                                    }}
                                                />
                                            </Col>
                                            <Col>
                                                <Button color="danger" outline onClick={() => {
                                                    // Remove the item at index from the array (will reindex everything else to fill the space).
                                                    setHeaders(prevState => prevState.filter((_, itemIndex) => itemIndex !== index));
                                                }}>
                                                    <FontAwesomeIcon icon="trash-alt" />
                                                    <span className="sr-only">
                                                        {t('editSdkWebhook.headers.remove', 'Remove header')}
                                                    </span>
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                    ))
                            }
                        </div>
                        <div className="mt-2">
                            <Button color="primary" outline onClick={() => {
                                // Add a blank entry to the end of the array.
                                setHeaders(prevState => [...prevState, { name: '', value: '', }]);
                            }}>
                                <FontAwesomeIcon icon="plus" />
                                <> </>
                                {t('editSdkWebhook.headers.add', ' Add header')}
                            </Button>
                        </div>
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="eventTypes">{t('editSdkWebhook.eventTypes.label', 'Events to trigger webhook')}</Label>

                        <WebhookEventTypeSelector
                            eventTypes={sdkWebhookEventTypes.map(item => item.id)}
                            isSelected={event => !!selectedEventTypes.find(it => it === event)}
                            toggleSelected={event => setSelectedEventTypes(prevState => {
                                if (!!prevState.find(it => it === event)) {
                                    return prevState.filter(it => it !== event);
                                } else {
                                    let ret = [
                                        ...prevState,
                                        event,
                                    ];
                                    ret.sort();
                                    return ret;
                                }
                            })}
                        />

                    </FormGroup>

                    {/* Show either the body as JSON or the URL query string that will be passed based on the HTTP method being used. */}
                    {
                        model?.httpMethod === HttpMethod.Post || model?.httpMethod === HttpMethod.Put ? (
                            <FormGroup>
                                <Label htmlFor="sampleBody">{t('editSdkWebhook.sampleBody.label', 'Sample format of the JSON body that will be sent')}</Label>

                                <Card body>
                                    <pre>
                                        <code lang="json">{sampleJson}</code>
                                    </pre>
                                </Card>
                            </FormGroup>
                        ) : (
                                <FormGroup>
                                    <Label htmlFor="sampleBody">{t('editSdkWebhook.sampleQuery.label', 'Sample query string that will be sent')}</Label>

                                    <Card body>
                                        <code>{sampleQueryString}</code>
                                    </Card>
                                </FormGroup>
                            )
                    }

                    <FormButtons>
                        <ConditionalFragment showIf={!isLoading}>
                            <ButtonAsync color="primary" isExecuting={isSaving}
                                executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}>
                                <FontAwesomeIcon icon="save" />
                                <> {t('common.save', 'Save')}</>
                            </ButtonAsync>
                        </ConditionalFragment>
                        <Button type="button" color="primary" outline onClick={e => history.goBack()}>
                            {t('common.cancel', 'Cancel')}
                        </Button>
                    </FormButtons>
                </Form>
            </MainContainer>
        </Background>
    );
};
