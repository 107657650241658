import gql from "graphql-tag";
import { Guid } from "guid-string";
import { defaultRiskRules } from "../../../services/OverallRiskService";
import { subscriptionFields } from "../generated/subscriptionFields";
import { SubscriptionType } from "./codeOnly/SubscriptionType";

export const subscriptionFieldsFragment = gql`
    fragment subscriptionFields on Subscription {
        id
        companyName
        archived
        brandColor
        brandImageBlobReferenceId
        maxUsers
        currentUsers
        restrictMaxUsers
        allowBranding
        allowExport
        highRiskBelow
        mediumRiskBelow
        subscriptionType
        distributorSubscriptionId
        studyUniqueCode
        studyReturnUrl
    }
`;


export type Subscription = Omit<subscriptionFields, '__typename'>;

export const subscriptionDefaultValues = (): Partial<Subscription> => ({
    id: Guid.newGuid(),
    companyName: '',
    archived: false,
    brandColor: '',
    brandImageBlobReferenceId: null,
    maxUsers: 1,
    currentUsers: 0,
    restrictMaxUsers: false,
    allowBranding: false,
    allowExport: false,
    highRiskBelow: defaultRiskRules.highRiskBelow,
    mediumRiskBelow: defaultRiskRules.mediumRiskBelow,
    subscriptionType: SubscriptionType.Subscription,
    distributorSubscriptionId: null,
    studyUniqueCode: '',
    studyReturnUrl: ''
});