import { ApexOptions }from "apexcharts";
import Chart from "react-apexcharts";
import { useMemo } from "react";
import { Card } from "reactstrap";
import { ChartDataSeries } from "../chartDataUtilities/ChartData";
import { chartDataConverters } from "../chartDataUtilities/ChartDataConverter";
import { useTranslation } from "react-i18next";
import { assessmentsChartColor, trainingChartColor } from "./dashboardChartColors";
import { AssessmentType } from "../../../api/main/models/codeOnly/AssessmentType";
import { overallRiskService, RiskRules } from "../../../services/OverallRiskService";

export interface AverageScoreRadarChartProps {
    data: Array<ChartDataSeries>,
    assessmentType: AssessmentType,
    riskRules: RiskRules | undefined | null,

    width?: string | number | undefined,
    height?: string | number | undefined,
}

/**
 * Chart that shows average scores as a radar.
 */
export const AverageScoreRadarChart = (props: AverageScoreRadarChartProps) => {
    const {
        data,
        assessmentType,
        riskRules,
        width,
        height,
    } = props;

    const { t } = useTranslation();

    // Convert data into format needed for this graph.
    const { series, options: seriesOptions } = useMemo(() => chartDataConverters.toRadar(data, { maxTextLength: 20 }), [data]);

    // Compile all the options we want to use.
    const options = useMemo(() => (
        {
            chart: {
                toolbar: {
                    show: false,
                },
            },

            title: {
                text: assessmentType === AssessmentType.TrainingModule ? t('averageScoreRadarChart.title.trainingModule', 'Training scores')
                    : t('averageScoreRadarChart.title.default', 'Assessment scores'),
                align: 'center',
            },

            colors:
                assessmentType === AssessmentType.TrainingModule ? [trainingChartColor]
                    : [assessmentsChartColor],

            yaxis: {
                min: 0,
                max: 100,
                tickAmount: 10,
            },

            plotOptions: {
                radar: {
                    polygons: {
                        fill: {
                            colors: assessmentType === AssessmentType.TrainingModule ? [] : overallRiskService.getSpidergraphBackgroundColors(10, riskRules),
                        }
                    }

                }
            },

            tooltip: {
                y: {
                    formatter: function (val: any) {
                        if (assessmentType === AssessmentType.TrainingModule) {
                            return val;
                        }

                        return `${val} (${overallRiskService.getRiskName(val, riskRules)})`;
                    }
                },
            },

            ...seriesOptions,
        } as ApexOptions
    ), [seriesOptions, t, assessmentType, riskRules]);

    // We need to force the refresh of the chart sometimes by updating its key as its too optomistic with its internal caching and
    // so doesn't end up changes top options very well at all if we dont.
    const key = useMemo(() => JSON.stringify(options), [options]);

    return (
        <Card body tag="div">
            <Chart key={key} options={options} series={series} type="radar" width={width} height={height} />
        </Card>
    );
};