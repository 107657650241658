import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMemo, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { useAsyncCallback } from "react-use-async-callback";
import { Button, Col, FormGroup, Input, Label, Row, Spinner, Alert } from "reactstrap";
import { useUploadBlobCallback } from "../../../api/main/blobReferences/useUploadBlobCallback";
import { SubscriptionTeam } from "../../../api/main/models/SubscriptionTeam";
import { useProfileSupportingData } from "../../../api/main/profiles/useProfileSupportingData";
import { useImportUsersCallback } from "../../../api/main/users/useImportUsersCallback";
import { useCurrentUserOrEmulatedSubscriptionId } from "../../../globalState/subscriptions/useCurrentUserOrEmulatedSubscriptionId";
import { AlertOnErrors } from "../../../shared/alertOnErrors";
import { useChangesArray } from "../../../shared/useChanges";
import { Background } from "../../shared/background/Background";
import { Banner } from "../../shared/Banner";
import { FileUploadButton } from "../../shared/fileUploadButton/FileUploadButton";
import { FormButtons } from "../../shared/FormButtons";
import { LoadingIndicator } from "../../shared/LoadingIndicator";
import { MainContainer } from "../../shared/MainContainer";
import { useSaveSubscriptionCallback } from '../../../api/main/subscriptions/useSaveSubscriptionCallback';
import { importUsersMutation_importUsers } from "../../../api/main/generated/importUsersMutation";

export type importUsersTabs = 'users' | 'assessmentAssignments' | 'trainingAssignments';

export interface ImportUsersBaseProps {
    defaultRoleGroup: string,
}

/**
 * Import users.
 */
export const ImportUsersBase = (props: ImportUsersBaseProps) => {
    const {
        defaultRoleGroup,
    } = props;

    const { t } = useTranslation();

    const history = useHistory();

    const subscriptionId = useCurrentUserOrEmulatedSubscriptionId();
    const { subscriptionTeamId: subscriptionTeamIdParam, roleGroup: roleGroupParam } = useParams<{ subscriptionTeamId: string | undefined, roleGroup: string | undefined }>();

    // Supporting data (dependant on the current user's subscription).
    const { data: { roleGroups: storeRoleGroups, subscriptionTeams: storeSubscriptionTeams, subscription }, isLoading: isLoadingSupportingData, errors: loadSupportingDataErrors } = useProfileSupportingData({ subscriptionId: subscriptionId ?? undefined });
    const isLoading = isLoadingSupportingData;

    // Used for updating subscription current users
    const [saveSubscription] = useSaveSubscriptionCallback();

    // Simple state tracking of field changes.
    const [subscriptionTeamId, setSubscriptionTeamId] = useState<string | undefined>(subscriptionTeamIdParam);
    const [_roleGroup, setRoleGroup] = useState<string | undefined>(roleGroupParam ?? undefined);
    const roleGroup = _roleGroup || defaultRoleGroup;

    // Teams
    const subscriptionTeamsManager = useChangesArray<SubscriptionTeam, string>(storeSubscriptionTeams, item => item.id);
    const orderedSubscriptionTeams = useMemo(() => {
        let ret = [...subscriptionTeamsManager.model];
        ret.sort((a, b) => {
            if (a.name === b.name) {
                return 0;
            } else if (a.name > b.name) {
                return 1;
            } else {
                return -1;
            }
        });
        return ret;
    }, [subscriptionTeamsManager]);

    // Role groups.
    const roleGroups = useMemo(() => {
        return (storeRoleGroups ?? [])
            .filter(item => item.id === 'Driver' || item.id === 'Driver manager' || item.id === 'Overview manager');
    }, [storeRoleGroups]);

    // Uploading blobs.
    const [uploadBlob, { errors: uploadBlobErrors }] = useUploadBlobCallback();

    // Import users from a blob.
    const [importUsers, { errors: importUsersErrors }] = useImportUsersCallback();

    // Results from an import.
    const [importUsersResults, setImportUsersResults] = useState<Array<importUsersMutation_importUsers> | undefined>(undefined);

    const [willExceedMaxUsers, setWillExceedMaxUsers] = useState<boolean>(false);

    // Perform the user import.
    const [performUserImport, { isExecuting: isImportingUsers, errors: performUserImportErrors }] = useAsyncCallback(async (files: FileList | null) => {
        if (!files) {
            return;
        }

        // Upload the file.
        const blob = await uploadBlob(files);
        if (!blob) {
            return;
        }

        // Import from the upload.
        const results = await importUsers({ blobId: blob.id, subscriptionId: subscriptionId ?? '', subscriptionTeamId: subscriptionTeamId ?? null, roleGroup: roleGroup ?? '' });

        if (results.length === 0) {

            setWillExceedMaxUsers(true);
            return;

        }
        if (subscription) {
            //update the subscriptions current user count
            await saveSubscription(subscription.id, { currentUsers: subscription.currentUsers + (results?.filter(it => it.successful)?.length ?? 0) }, false);
        }

        setImportUsersResults(results);
    }, [uploadBlob, subscriptionId, setImportUsersResults, roleGroup, subscriptionTeamId, subscription, setWillExceedMaxUsers]);


    return (
        <Background>
            <Banner>
                <Row>
                    <Col>
                        <h1>{t('importUsersBase.createHeading', 'Import users')}</h1>
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
            </Banner>

            <MainContainer>
                {
                    willExceedMaxUsers ? (
                        <Alert color="danger">
                            <>{t('importUsersBase.maxUsersReached', 'Cannot import new users. Maximum amount of users for this subscription has been reached or will be exceeded.')} </>
                        </Alert>
                    ) : null
                }
                <AlertOnErrors errors={[loadSupportingDataErrors, uploadBlobErrors, importUsersErrors, performUserImportErrors]} />

                <ConditionalFragment showIf={!importUsersResults?.length}>
                    <h5>
                        {t('importUsersBase.instructions.p1', 'Users can be imported from a file instead of being set up manually within the app.  The file can be in Excel (.xlsx), CSV (.csv), or tab-delimited (.txt or .tsv) format and must contain the correct columns as outlined below.')}
                    </h5>
                    <p>
                        {t('importUsersBase.instructions.p2', 'The file can contain the following columns (in any order).  Other columns will be ignored:')}
                    </p>
                    <ul>
                        <li>{t('importUsersBase.columns.email', 'Email (Used for login - if omitted or blank user will have a unique driver PIN generated for their login).')}</li>
                        <li>{t('importUsersBase.columns.firstName', 'First name (optional)')}</li>
                        <li>{t('importUsersBase.columns.lastName', 'Last name (optional)')}</li>
                        <li>{t('importUsersBase.columns.team', 'Team (optional - if omitted or blank user will be placed into the team selected below.)')}</li>
                        <li>{t('importUsersBase.columns.securityGroup', 'Security group (optional - if omitted or blank user will be placed into the security group selected below.)')}</li>
                        <li>{t('importUsersBase.columns.password', 'Password (optional - if omitted or blank user will be asked to their password when first accessing the system.)')}</li>
                    </ul>

                    <FormGroup>
                        <Label htmlFor="subscriptionTeamId">{t('importUsersBase.subscriptionTeamId.label', 'Team')}</Label>
                        <Input name="subscriptionTeamId" type="select" value={subscriptionTeamId ?? ''} onChange={e => setSubscriptionTeamId(e.currentTarget.value || undefined)}>
                            <option value="">{t('importUsersBase.subscriptionTeamId.noTeam', '(No team)')}</option>

                            {
                                orderedSubscriptionTeams.map(item => (
                                    <option key={item.id} value={item.id}>{item.name}</option>
                                ))
                            }
                        </Input>
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="roleGroup">{t('importUsersBase.roleGroup', 'Security group')}</Label>
                        <Input name="roleGroup" type="select" value={roleGroup ?? ''} onChange={e => setRoleGroup(e.currentTarget.value || undefined)}>
                            {
                                roleGroups?.map(item => (
                                    <option key={item.id} value={item.id}>{item.name}</option>
                                ))
                            }
                        </Input>
                    </FormGroup>
                </ConditionalFragment>

                <ConditionalFragment showIf={!!importUsersResults?.length}>
                    <h5>
                        {t('importUsersBase.results.summary', '{{count}} users have been imported for you.', { count: importUsersResults?.filter(it => it.successful)?.length ?? 0 })}
                    </h5>

                    {
                        importUsersResults?.map(item => (
                            <div key={item.rowNumber} className={item.successful ? 'text-success' : 'text-danger'}>
                                <Row>
                                    <ConditionalFragment showIf={item.rowNumber !== -1 /* Whole file errors. */}>
                                        <Col xs={12} md="auto">
                                            {t('importUsersBase.results.rowNumber', 'Row {{rowNumber}}', { rowNumber: item.rowNumber })}
                                        </Col>
                                    </ConditionalFragment>
                                    <Col>
                                        <ConditionalFragment showIf={item.successful}>
                                            {t('importUsersBase.results.successful', 'User created for {{email}}', { email: item.user?.email })}
                                        </ConditionalFragment>
                                        <ConditionalFragment showIf={!!item.errors.length}>
                                            <ConditionalFragment showIf={item.errors.length === 1}>
                                                {
                                                    item.errors.map((error, index) => (
                                                        <div key={index}>
                                                            {error}
                                                        </div>
                                                        ))
                                                }
                                            </ConditionalFragment>
                                            <ConditionalFragment showIf={item.errors.length > 1}>
                                                <ul>
                                                    {
                                                        item.errors.map((error, index) => (
                                                            <li key={index}>
                                                                {error}
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                            </ConditionalFragment>
                                        </ConditionalFragment>
                                    </Col>
                                </Row>
                                <hr />
                            </div>
                            ))
                    }
                </ConditionalFragment>

                <FormButtons>
                    <ConditionalFragment showIf={!isLoading && !importUsersResults}>
                        <FileUploadButton onUpload={files => performUserImport(files)} isExecuting={isImportingUsers}
                            executingChildren={<><Spinner size="sm" /><> </>{t('importUsersBase.importing', 'Importing...')}</>}
                        >
                            <FontAwesomeIcon icon="upload" className="nav-icon" />
                            <> </>
                            {t('importUsersBase.import', 'Import file...')}
                        </FileUploadButton>
                        <Button type="button" color="primary" outline onClick={e => history.goBack()}>
                            {t('common.cancel', 'Cancel')}
                        </Button>
                    </ConditionalFragment>
                    <ConditionalFragment showIf={!!importUsersResults}>
                        <Button type="button" color="primary" onClick={e => history.goBack()}>
                            {t('common.close', 'Close')}
                        </Button>
                    </ConditionalFragment>
                </FormButtons>
            </MainContainer>
        </Background>
    );
};
