import { useTranslation } from "react-i18next";
import { AssessmentType } from "../../../../api/main/models/codeOnly/AssessmentType";
import { SubscriptionAssessmentsListBase } from "../../../subscriptionAssessments/SubscriptionAssessmentsListBase";
import { useParams } from "react-router";
import { DistributorSubscriptionEditNavigation } from './DistributorSubscriptionEditNavigation';

/**
 * List of actual assessments.
 */
export const DistributorSubscriptionAssessmentsList = () => {
    const { t } = useTranslation();

    const { subscriptionId } = useParams<{ subscriptionId: string }>();

    const baseRoute = '/administration/assessments';

    return (
        <SubscriptionAssessmentsListBase
            title={t('distributorAssessmentsList.title', 'Licensed assessments')}
            mobileColumn1Name={t('distributorAssessmentsList.mobileColumn1Name', 'Assessment')}
            assessmentTypes={[AssessmentType.Assessment]}
            baseRoute={baseRoute}
            navigationPills={<DistributorSubscriptionEditNavigation id={subscriptionId ?? ''} />}
            isDistributorSubscription={true}
        />
    );
};