import './appNavbarBrand.scss';

import * as React from 'react';
//import { NavbarBrand } from 'reactstrap'; // Not used as it renders a / and we want to control our / elsewhere.

/**
 * Navbar brand image for the app. 
 */
export const AppNavbarBrand = () => {
    return (
        <div className="navbar-brand">
            {/*<div className="navbar-brand-full">
            </div>
            <div className="navbar-brand-minimized">
            </div>*/}
        </div>
        );
};