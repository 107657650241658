import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { DriverMetricCreateInput } from '../generated/globalTypes';
import { createDriverMetricMutation, createDriverMetricMutationVariables } from '../generated/createDriverMetricMutation';
import { driverMetricFieldsFragment } from '../models/DriverMetric';

/**
 * Get a callback to create a DriverMetric in the store.
 */
export function useCreateDriverMetricCallback(): [(model: DriverMetricCreateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<createDriverMetricMutation, createDriverMetricMutationVariables>(
        gql`
            mutation createDriverMetricMutation ($model: DriverMetricCreateInput!) {
                createDriverMetric(model: $model) {
                    ...driverMetricFields
                }
            }

            ${driverMetricFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (model: DriverMetricCreateInput) => {
        const result = await mutationAction({ variables: { model: model } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
