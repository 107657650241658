import * as React from 'react';
import { atom, useRecoilState } from 'recoil';
import { persistAtom } from '../../configure/recoilPersistConfig';

export const subscriptionEmulationState = atom<string | null | undefined>({
    key: 'subscriptionEmulationState',
    default: null,
    effects_UNSTABLE: [persistAtom],
});

/**
 * Hook that provides access to the subscription emulation functionality.
 * 
 * For code that just wants to get the current user's subscription (overriden by the emulated subscription if emulation is being used) you
 * should use useCurrentUserOrEmulatedSubscription().
 */
export function useSubscriptionEmulation(): [string | null | undefined, React.Dispatch<React.SetStateAction<string | null | undefined>>] {
    const [value, setValue] = useRecoilState(subscriptionEmulationState);
    return [value, setValue];
}