import { useEffect } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useHistory, useParams } from "react-router";
import { useCurrentUserId } from "../../api/account";
import { useInitializeAssessmentSessionCallback } from "../../api/main/assessmentSessions/useInitializeAssessmentSessionCallback";
import { AssessmentType } from "../../api/main/models/codeOnly/AssessmentType";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { Background } from "../shared/background/Background";
import { LoadingIndicator } from "../shared/LoadingIndicator";
import { useUserAssessmentAssignmentsListViewBaseViewModel } from '../../api/main/userAssessmentAssignments/viewModels/useUserAssessmentAssignmentsListViewBaseViewModel';
import { useCreateUserAssessmentAssignmentCallback } from '../../api/main/userAssessmentAssignments/useCreateUserAssessmentAssignmentCallback';
import { UserAssessmentAssignment, userAssessmentAssignmentDefaultValues } from '../../api/main/models/UserAssessmentAssignment';
import * as React from 'react';
import { UserAssessmentAssignmentCreateInput } from '../../api/main/generated/globalTypes';

export interface StartAssessmentBaseProps {
    defaultAssessmentType: AssessmentType,
}

/**
 * Start an assessment by creating and launching an assessment session.
 */
export const StartAssessmentBase = (props: StartAssessmentBaseProps) => {
    const {
        defaultAssessmentType
    } = props;

    const {
        userId: paramUserId,
        assessmentId, subscriptionTeamId
    } = useParams<{ userId?: string, assessmentId: string, subscriptionTeamId: string | undefined }>();

    const [_create] = useCreateUserAssessmentAssignmentCallback();
    const create = React.useCallback((model: Partial<UserAssessmentAssignment>) => _create(model as UserAssessmentAssignmentCreateInput), [_create]);


    const currentUserId = useCurrentUserId();
    const userId = paramUserId || currentUserId;

    const history = useHistory();

    const [initializeAssessment, { isExecuting: isInitializingAssessment, errors: initializeAssessmentErrors }] = useInitializeAssessmentSessionCallback();

    const {
        data: {
            items: storeUserAssessmentAssignments,
        }, } = useUserAssessmentAssignmentsListViewBaseViewModel(undefined, { pageSize: undefined, assessmentTypes: [defaultAssessmentType], subscriptionTeamId: subscriptionTeamId, userId: userId });

    // Create the assessment.
    const [hasInitializationStarted, setHasInitializationStarted] = React.useState<boolean>(false);
    useEffect(() => {
        // If we don't have a user yet, wait until we do.
        if (!userId) {
            return;
        }

        // If we're already in the process of launching, don't do it again.
        if (hasInitializationStarted) {
            return;
        }

        // Record in the state that we are in the process of launching, otherwise we can sometimes have a race condition where
        // we end up creating two sessions because we get called again before the first one is complete and redirected us to another screen.
        setHasInitializationStarted(true);

        var currentUserAssessmentAssignment;
        var checked = false;
        if (storeUserAssessmentAssignments) {
            //check if we have a user assessment assignment for the current assessment and user
            currentUserAssessmentAssignment = storeUserAssessmentAssignments.find(item => item.userId === userId && item.assessmentId === assessmentId) ?? undefined;
            checked = true;
        }

        if (!currentUserAssessmentAssignment && checked === true) {
            // If we dont have a pre-existing user assessment assingment,  it means we are previewing and want to create a new one so it shows corrrectly on the dashboard
            const newUserAssessmentAssignment = {
                ...userAssessmentAssignmentDefaultValues(),
                userId: userId,
                assessmentId: assessmentId,
            }

            create(newUserAssessmentAssignment);

        }


        // Now we have a user, lets one-time run a create method to create the assessment session and launch it.
        (async () => {
            const model = await initializeAssessment(assessmentId, userId);

            if (model) {
                history.replace(`/assessment/launch/${model.id}`);
            }
        })();
    }, [userId, assessmentId, initializeAssessment, history, storeUserAssessmentAssignments, create, hasInitializationStarted, setHasInitializationStarted]);

    return (
        <Background>
            <AlertOnErrors errors={[initializeAssessmentErrors]} />

            <ConditionalFragment showIf={isInitializingAssessment}>
                <LoadingIndicator fullWidth />
            </ConditionalFragment>
        </Background>
        );
};