/**
 * Service for generating API keys.
 */
export class ApiKeyGenerationService {
    private _applicationPrefix = 'ED'; // ED for Esitu Drive.
    private _secretLength = 42;

    /**
     * Generate an API key for targetId.
     * @param targetId
     */
    generateKey(id: string): string {
        const ret = `${this._applicationPrefix}.${id}.${this.generateSecret()}`;
        return ret;
    }

    /**
     * Generate a randomly generated secret for use in a API key.
     */
    private generateSecret(): string {
        // Get a list of all acceptable characters.
        const numberChars = "0123456789";
        const upperChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        const lowerChars = "abcdefghijklmnopqrstuvwxyz";

        const allChars = `${numberChars}${upperChars}${lowerChars}`;

        let ret = '';
        while (ret.length < this._secretLength) {
            const random = Math.floor(Math.random() * (allChars.length));
            ret += allChars[random];
        }

        return ret;
    }
}