import * as React from 'react';
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { AsyncLoadPagedOptions, AsyncLoadPagedResult } from '../../../../shared/abstractStore';
import { mainApiConfig } from '../../../../configure/mainApiConfig';
import { useFetchMoreCallback } from '../../../../shared/useFetchMoreCallback';
import { assessmentSessionFieldsFragment } from '../../models/AssessmentSession';
import { studyAdministrationDashboardViewModelQuery, studyAdministrationDashboardViewModelQueryVariables } from '../../generated/studyAdministrationDashboardViewModelQuery';
import { Guid } from 'guid-string';
import { profileFieldsFragment } from '../../models/Profile';

/**
 * Get a list of assessmentSessions from the store.
 * @param studyId
 * @param options
 */
export function useStudyAdministrationDashboardViewModel(studyId: string | undefined | null, options: AsyncLoadPagedOptions = {}): AsyncLoadPagedResult<studyAdministrationDashboardViewModelQuery> {
    // Query data we need from the api.
    const [load, { data, loading, error, refetch, fetchMore: fetchMoreFromStore }] = useLazyQuery<studyAdministrationDashboardViewModelQuery, studyAdministrationDashboardViewModelQueryVariables>(
        gql`
        query studyAdministrationDashboardViewModelQuery ($offset: Int, $first: Int, $studyId: ID!) {
            items: assessmentSessions (offset: $offset, first: $first, subscriptionId: $studyId) {
                ...assessmentSessionFields
            }

            profiles {
                ...profileFields
            }
        }

        ${assessmentSessionFieldsFragment}
        ${profileFieldsFragment}
        `,
        {

            variables: {
                offset: options.startOffset,
                first: options.pageSize,
                studyId: studyId ?? Guid.empty
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load]);

    // Get the functions used to manage paging.
    const { fetchMore, hasMore: _hasMore } = useFetchMoreCallback<studyAdministrationDashboardViewModelQuery>(
        fetchMoreFromStore,
        (options?.startOffset ?? 0) + (data?.items?.length ?? 0),
        (prevResults, newResults) => ({
            ...prevResults,
            ...newResults,

            items: [...(prevResults?.items ?? []), ...(newResults?.items ?? [])],
            profiles: [...(prevResults?.profiles ?? []), ...(newResults?.profiles ?? [])],
        }),
        (newResults) => newResults?.items?.length
    );
    const hasMore = React.useCallback(() => !options.pageSize ? false : _hasMore(), [options.pageSize, _hasMore]);

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error,
        fetchMore: fetchMore,
        hasMore: hasMore,
    }), [data, refresh, loading, error, fetchMore, hasMore]);
}
