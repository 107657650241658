import { useTranslation } from "react-i18next";
import { Progress } from "reactstrap";
import "./pageProgress.scss";

export interface PageProgressProps {
    currentPageIndex: number,
    pageCount: number,
}

/**
 * Progress bar showing progress through an assessment.
 * @param props
 */
export const PageProgress = (props: PageProgressProps) => {
    const {
        currentPageIndex,
        pageCount
    } = props;

    const { t } = useTranslation();

    return (
        <div className="page-progress">
            <Progress color="primary" value={currentPageIndex + 1} max={pageCount}>
                <span className="page-progress-text">
                    {t('pageProgress.progressText', 'Screen {{currentPage}} of {{pageCount}}', { currentPage: (currentPageIndex + 1), pageCount: pageCount })}
                </span>
            </Progress>
        </div>
        );
};