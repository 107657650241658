import * as React from 'react';
import { QuestionResponseEventCreateInput, QuestionResponseEventUpdateInput } from '../generated/globalTypes';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useCreateQuestionResponseEventCallback } from './useCreateQuestionResponseEventCallback';
import { useUpdateQuestionResponseEventCallback } from './useUpdateQuestionResponseEventCallback';
import { QuestionResponseEvent } from '../models/QuestionResponseEvent';

/**
 * Get a callback to save a QuestionResponseEvent in the store using either a create or update.
 */
export function useSaveQuestionResponseEventCallback(options: SaveInStoreOptions<QuestionResponseEvent, string> = {}) {
    const [_create, createStatus] = useCreateQuestionResponseEventCallback();
    const create = React.useCallback((model: Partial<QuestionResponseEvent>) => _create(model as QuestionResponseEventCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateQuestionResponseEventCallback();
    const update = React.useCallback((id: string, changes: Partial<QuestionResponseEvent>) => _update(id, changes as QuestionResponseEventUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
