import { AssessmentSession } from "../../../api/main/models/AssessmentSession";
import { overallRiskService, RiskRules } from "../../../services/OverallRiskService";
import { QuestionScore } from "../../assessmentSessions/launch/utilities/calculateQuestionScore";

/**
 * Calculate the average score from a set of assessment sessions.
 */
export function calculateAverageScore(sessions: Array<AssessmentSession>, riskRules?: RiskRules | undefined | null): QuestionScore {
    let minScore = -1;
    let maxScore = -1;
    let scoreSum = 0;
    let sessionCount = 0;
    for (const session of sessions) {
        scoreSum += session.totalScore;

        if (minScore === -1) {
            minScore = session.totalScore;
        } else if (session.totalScore < minScore) {
            minScore = session.totalScore;
        }

        if (maxScore === -1) {
            maxScore = session.totalScore;
        } else if (session.totalScore > maxScore) {
            maxScore = session.totalScore;
        }

        ++sessionCount;
    }

    let averageScore = 0;
    let notStarted = true;
    if (sessionCount > 0) {
        averageScore = Math.round(scoreSum / sessionCount);
        notStarted = false;
    }

    const riskCategory = overallRiskService.getRiskCategory(averageScore, notStarted, riskRules);

    return {
        score: averageScore,
        minScore: minScore === -1 ? 0 : minScore,
        maxScore: maxScore === -1 ? 0 : maxScore,
        riskCategory,
    };
}