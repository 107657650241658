import gql from "graphql-tag";
import { questionTagLinkFields } from "../generated/questionTagLinkFields";

export const questionTagLinkFieldsFragment = gql`
    fragment questionTagLinkFields on QuestionTagLink {
        id
        targetId
        targetType
        questionTagId
        archived
    }
`;


export type QuestionTagLink = Omit<questionTagLinkFields, '__typename'>;
