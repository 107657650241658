import { Redirect } from 'react-router';


/**
 * Default Study Administration home page.
 */
export const StudyAdministrationHome = () => {

    return (
        <>
            <Redirect to={`/manage/study/participants`} />
        </>
    );
};