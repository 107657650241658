import { useTranslation } from "react-i18next";
import { FormGroup, Label, ListGroup, Button } from "reactstrap";
import { Question } from "../../../../api/main/models/Question";
import { ModelArrayChanges } from "../../../../shared/useChanges";
import { useDisplayOrder } from "../../../shared/useDisplayOrder/useDisplayOrder";
import { QuestionSequenceQuestion } from "../../../../api/main/models/QuestionSequenceQuestion";
import { QuestionnaireQuestionSequenceQuestionComponent } from "./QuestionnaireQuestionSequenceQuestionComponent";
import { QuestionAnswer } from "../../../../api/main/models/QuestionAnswer";
import { useCallback } from "react";
import { useToggleState } from "use-toggle-state";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ConditionalFragment } from "react-conditionalfragment";
import { CreateQuestionnaireQuestionModal } from "../../questionnaires/QuestionnaireQuestion/CreateQuestionnaireQuestionModal/CreateQuestionnaireQuestionModal";
import { Guid } from "guid-string";
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { ValidationErrors } from "pojo-validator";
import { ValidateCallback } from '../../../../../node_modules/pojo-validator-react/dist/lib/esm/ValidateCallback';
import { DriverMetric } from "../../../../api/main/models/DriverMetric"

export interface QuestionnaireQuestionsTabProps {
    model: Question | undefined,
    driverMetrics: Array<DriverMetric>,

    change: (changes: Partial<Question>) => void,
    validate: ValidateCallback,
    validationErrors: ValidationErrors,

    questionSequenceQuestionsManager: ModelArrayChanges<QuestionSequenceQuestion, string>,

    questionnaireQuestionAnswersManager: ModelArrayChanges<QuestionAnswer, string>,
    validateQuestionAnswer: (model: QuestionAnswer) => boolean,
    questionAnswerValidationErrors: (id: string) => ValidationErrors,

    refresh: () => void,

    questionnaireQuestionsManager: ModelArrayChanges<Question, string>,
}

export const SequenceOfQuestionnaireQuestionsTab = (props: QuestionnaireQuestionsTabProps) => {
    const {
        model,
        driverMetrics,

        change,
        validate,
        validationErrors,

        questionSequenceQuestionsManager,

        questionnaireQuestionAnswersManager,
        validateQuestionAnswer,
        questionAnswerValidationErrors,

        refresh,

        questionnaireQuestionsManager,
    } = props;

    const { t } = useTranslation();

    // Showing of the question modal.
    const [questionModalIsOpen, _toggleQuestionModal] = useToggleState();
    const toggleQuestionModal = useCallback(() => {
        _toggleQuestionModal();
    }, [ _toggleQuestionModal]);

    // Order the answers so they show in and can be managed by displayOrder.
    const [orderedQuestions, {
        canMoveUp: canMoveAnswerUp,
        moveUp: moveAnswerUp,
        canMoveDown: canMoveAnswerDown,
        moveDown: moveAnswerDown,
    }] = useDisplayOrder(questionSequenceQuestionsManager);

    const newQuestion = useCallback(() => {

        toggleQuestionModal();

    }, [toggleQuestionModal]);

    const emptyQuestion = {
        id: Guid.newGuid(),
        name: '',
        questionType: '',
        questionText: '',
        imageBlobReferenceId: null,
        videoBlobReferenceId: null,
        questionTextImageBlobReferenceId: null,
        preQuestionText: '',
        maximumClicks: 10,
    } as Question;

    return (
      <>
            <FormGroup>
                <Label htmlFor="questionText">{t('editQuestion.questionText.question', 'Questionnaire Title')}</Label>
                <ValidatedInput name="Questionnaire Title" type="text" value={model?.questionText ?? ''} onChange={e => change({ questionText: e.currentTarget.value })} onBlur={e => validate('question Text')} validationErrors={validationErrors['question Text']} />

                <Label htmlFor="questions">{t('SequenceOfQuestionnaireQuestionsTab.questions', 'Questions')}</Label>

                <ListGroup className="mb-2">
                    {
                        orderedQuestions.map(item => {
                            const childQuestion = questionnaireQuestionsManager.model.find(it => it.id === item.childQuestionId);

                            return (
                                <QuestionnaireQuestionSequenceQuestionComponent key={item.id}
                                    model={item}
                                    driverMetrics={driverMetrics}
                                    parent={model}

                                    moveUp={() => moveAnswerUp(item.id)} canMoveUp={canMoveAnswerUp(item.id)}
                                    moveDown={() => moveAnswerDown(item.id)} canMoveDown={canMoveAnswerDown(item.id)}

                                    childQuestion={childQuestion!}

                                    validateQuestionAnswer={validateQuestionAnswer}
                                    questionAnswerValidationErrors={questionAnswerValidationErrors}
                                    refresh={refresh}

                                    questionnaireQuestionAnswersManager={questionnaireQuestionAnswersManager}
                                    questionSequenceQuestionsManager={questionSequenceQuestionsManager}
                                    questionnaireQuestionsManager={questionnaireQuestionsManager}
                                />
                            );
                        })
                    }
                </ListGroup>
            </FormGroup>

            <FormGroup>
                <Button color="primary" outline onClick={() => newQuestion()}>
                    <><FontAwesomeIcon icon="car-crash" className="nav-icon" />
                        {t('SequenceOfQuestionnaireQuestionsTab.addQuestion', ' Add question')}</>
                </Button>
            </FormGroup>

            <ConditionalFragment showIf={questionModalIsOpen}>
                <CreateQuestionnaireQuestionModal
                    questionnaire={emptyQuestion}
                    driverMetrics={driverMetrics}
                    isOpen={questionModalIsOpen}
                    toggle={toggleQuestionModal}
                    parentId={model!.id}
                    isCreate={true}
                    validateQuestionAnswer={validateQuestionAnswer}
                    questionAnswerValidationErrors={questionAnswerValidationErrors}
                    refresh={refresh}
                    questionnaireQuestionAnswersManager={questionnaireQuestionAnswersManager}
                    questionSequenceQuestionsManager={questionSequenceQuestionsManager}
                    questionnaireQuestionsManager={questionnaireQuestionsManager}
                />
            </ConditionalFragment>
      </>
           )
}