import * as React from 'react';
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { AsyncLoadResult, AsyncLoadOptions } from '../../../../shared/abstractStore';
import { subscriptionDashboardViewModelQuery, subscriptionDashboardViewModelQueryVariables } from '../../generated/subscriptionDashboardViewModelQuery';
import { mainApiConfig } from '../../../../configure/mainApiConfig';
import { Guid } from 'guid-string';
import { subscriptionFieldsFragment } from '../../models/Subscription';
import { subscriptionTeamFieldsFragment } from '../../models/SubscriptionTeam';
import { profileFieldsFragment } from '../../models/Profile';
import { subscriptionAssessmentFieldsFragment } from '../../models/SubscriptionAssessment';
import { assessmentFieldsFragment } from '../../models/Assessment';
import { assessmentSessionFieldsFragment } from '../../models/AssessmentSession';
import { userAssessmentAssignmentFieldsFragment } from '../../models/UserAssessmentAssignment';
import { userDriverMetricFieldsFragment } from '../../models/UserDriverMetric';
import { driverMetricFieldsFragment } from '../../models/DriverMetric';

/**
 * View model for SubscriptionDashboard to loads all the right related data.
 * @param options
 */
export function useSubscriptionDashboardViewModel(id: string | undefined | null, options: AsyncLoadOptions = {}): AsyncLoadResult<subscriptionDashboardViewModelQuery> {
    // Query the data we need form the api.
    const [load, { data, loading, error, refetch }] = useLazyQuery<subscriptionDashboardViewModelQuery, subscriptionDashboardViewModelQueryVariables>(
        gql`
        query subscriptionDashboardViewModelQuery ($id: ID!) {
            model: subscription(id: $id) {
                ...subscriptionFields
            }

            subscriptionTeams(subscriptionId: $id) {
                ...subscriptionTeamFields
            }

            profiles(subscriptionId: $id) {
                ...profileFields
            }

            subscriptionAssessments (subscriptionId: $id) {
                ...subscriptionAssessmentFields
            }

            assessments {
                ...assessmentFields
            }

            assessmentSessions (subscriptionId: $id) {
                ...assessmentSessionFields
            }

            userAssessmentAssignments (subscriptionId: $id) {
                ...userAssessmentAssignmentFields
            }

            userDriverMetrics (subscriptionId: $id) {
                ...userDriverMetricFields
            }

            driverMetrics {
                ...driverMetricFields
            }
        }

        ${subscriptionFieldsFragment}
        ${subscriptionTeamFieldsFragment}
        ${profileFieldsFragment}
        ${subscriptionAssessmentFieldsFragment}
        ${assessmentFieldsFragment}
        ${assessmentSessionFieldsFragment}
        ${userAssessmentAssignmentFieldsFragment}
        ${userDriverMetricFieldsFragment}
        ${driverMetricFieldsFragment}
        `,
        {
            variables: {
                id: id ?? Guid.empty
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load])

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error
    }), [data, refresh, loading, error]);
}