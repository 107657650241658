import * as React from 'react';
import { Button, Row, Col, ButtonGroup, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, CardTitle, CardSubtitle, FormText, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { Waypoint } from 'react-waypoint';
import { useReplaceSearchParamsEffect, useSearchParams } from '../../shared/useURLSearchParams';
import { useTranslation } from 'react-i18next';
import { SearchInput } from '../shared/searchInput/SearchInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContainer } from '../shared/MainContainer';
import { NoResultsFound } from '../shared/NoResultsFound';
import { StickyToolbar } from '../shared/StickyToolbar';
import { useHistory } from 'react-router';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Banner } from '../shared/Banner';
import { useToggleStateArray } from 'use-toggle-state';
import { Background } from '../shared/background/Background';
import { CardsOrTable } from '../shared/cardsOrTable/CardsOrTable';
import { usePreferredListViewMode } from '../../globalState/preferredListViewMode/usePreferredListViewMode';
import { PillsNavBar } from '../shared/pillsNavBar/PillsNavBar';
import { useUsersListViewBaseViewModel } from '../../api/main/profiles/viewModels/useUsersListViewBaseViewModel';
import { Guid } from 'guid-string';
import { FileUploadButton } from '../shared/fileUploadButton/FileUploadButton';

export interface UsersListBaseProps {
    title: string,
    filterByRoleGroups: Array<string>,
    roleGroupColumnHidden?: boolean,
    baseRoute: string,
    subscriptionId?: string | undefined | null,
    subscriptionTeamId?: string | undefined | null,
    navigationPills?: React.ReactNode,
    teamColumnHidden?: boolean,
    allowImport?: boolean,
}

/**
 * List of administrator users.
 */
export const UsersListBase = (props: UsersListBaseProps) => {
    const {
        title,
        filterByRoleGroups,
        roleGroupColumnHidden,
        baseRoute,
        subscriptionId,
        subscriptionTeamId,
        navigationPills,
        teamColumnHidden,
        allowImport = true,
    } = props;

    const { t } = useTranslation();
    const { search: searchParam } = useSearchParams();
    const [search, setSearch] = React.useState<string>(searchParam ?? '');
    const { data: { items: allItems, subscription, subscriptionTeam, subscriptionTeams }, isLoading, errors: loadingErrors, fetchMore, hasMore } = useUsersListViewBaseViewModel({ pageSize: undefined, subscriptionId: subscriptionId, subscriptionTeamId: subscriptionTeamId });
    const [isMenuOpen, toggleMenuOpen] = useToggleStateArray();
    const history = useHistory();

    // For showing info on max user counts
    const [subscriptionUserCount, setSubscriptionUserCount] = React.useState<number>();
    const [maxUserCount, setMaxUserCount] = React.useState<number>();

    // Filter by the user's search client side so it can work when offline as well as online.
    const items = React.useMemo(() => {
        let ret = (allItems ?? []);

        //For showing the user count for the current subscription
        setSubscriptionUserCount(subscription?.currentUsers);

        //For showing the current subscriptions user limit
            setMaxUserCount(subscription?.maxUsers);


        // Filter by the role groups allowed.
        ret = ret.filter(item => !!filterByRoleGroups.find(it => it === item.user?.roleGroup?.id));

            let lowerSearch = search.toLocaleLowerCase();

            // Filter the items being displayed.
            ret = ret.filter(item =>
                item.firstName.toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || item.lastName.toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || item.user.email.toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || item.uniqueDriverPin.toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || (item.user?.roleGroup?.name?.toLocaleLowerCase()?.indexOf(lowerSearch) ?? -1) >= 0
                || `${item.firstName} ${item.lastName}`.toLocaleLowerCase().indexOf(lowerSearch) >= 0 // Full name in languages "first last"
                || `${item.lastName} ${item.firstName}`.toLocaleLowerCase().indexOf(lowerSearch) >= 0 // Full name in languages "last first"
            );

        return ret;
    }, [allItems, search, filterByRoleGroups, subscription, setMaxUserCount, setSubscriptionUserCount]);

    useReplaceSearchParamsEffect({ search: search });

    const [viewMode, setViewMode] = usePreferredListViewMode();
        
    return (
        <Background>
            <Banner fluid>
                <StickyToolbar>
                    <ConditionalFragment showIf={!!navigationPills}>
                        <Row>
                            <Col xs={12} md="auto">
                                <h1>
                                    {title}
                                </h1>
                            </Col>
                            <Col>
                                <PillsNavBar>
                                    {navigationPills}
                                </PillsNavBar>
                            </Col>

                        </Row>
                    </ConditionalFragment>

                    <Row>
                        <Col>
                            {
                                // If we have navigation pills then the title is shown with that (above) so show the subscription name here.
                                !!navigationPills ? (
                                    <h3 className="text-muted">
                                        {
                                            subscriptionTeam? subscriptionTeam.name
                                                : subscription ? subscription.companyName
                                                    : ''
                                        }
                                    </h3>
                                ) : (
                                        <h1>{title}</h1>
                                    )
                            }
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>

                        <Col>
                            <SearchInput value={search} onChange={e => setSearch(e.currentTarget.value)} />
                        </Col>
                        <Col xs="auto">
                            <Row>
                                

                                <ButtonGroup>
                                    <LinkContainer to={`${baseRoute}/add`}>
                                        <Button color="primary">
                                            <FontAwesomeIcon icon="plus" /><> {t('usersListBase.add', 'Add')}</>
                                        </Button>
                                    </LinkContainer>
                                    <ConditionalFragment showIf={allowImport}>
                                        <LinkContainer to={`${baseRoute}/import`}>
                                            <Button color="primary" outline>
                                                <FontAwesomeIcon icon="upload" /><> {t('usersListBase.import', 'Import users')}</>
                                            </Button>
                                        </LinkContainer>
                                        <LinkContainer to={`${baseRoute}/importUserAssessmentAssignments`}>
                                            <Button color="primary" outline>
                                                <FontAwesomeIcon icon="upload" />
                                                <> </>
                                                {t('subscriptionDashboard.tabs.assessmentAssignments', 'Import assessment or training assignments')}
                                            </Button>
                                        </LinkContainer>
                                    </ConditionalFragment>
                                    <a className=" btn btn-outline-primary" href={`/api/export/Users?subscriptionId=${encodeURIComponent(subscription?.id ?? '')}&subscriptionTeamId=${encodeURIComponent(subscriptionTeam?.id ?? '')}&filterByRoleGroups=${filterByRoleGroups[0]}`} download>
                                        <FontAwesomeIcon icon="download" /> <> {t('usersListBase.export', 'Export users to Excel')} </>
                                    </a>
                                </ButtonGroup>
                            </Row>
                            <Row>
                                <ConditionalFragment showIf={!!subscription && maxUserCount !== 0 && subscription.restrictMaxUsers}>
                                <FormText color={"White"} >
                                    {t('usersListBase.maxUsers.help', `Current subscription users ${subscriptionUserCount} / ${maxUserCount ?? ''}` )}
                                </FormText>
                                </ConditionalFragment>

                            </Row>
                        </Col>
                        <Col xs={12} md="auto">
                            <ButtonGroup>
                                <Button color="secondary" outline={viewMode !== 'cards'} onClick={() => setViewMode('cards')}>
                                    <FontAwesomeIcon icon="th-large" />
                                    <span className="sr-only">{t('common.cards', 'Cards')}</span>
                                </Button>
                                <Button color="secondary" outline={viewMode !== 'table'} onClick={() => setViewMode('table')}>
                                    <FontAwesomeIcon icon="th-list" />
                                    <span className="sr-only">{t('common.list', 'List')}</span>
                                </Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer fluid>
                <AlertOnErrors errors={loadingErrors} />
                <CardsOrTable
                    viewMode={viewMode}
                    items={items}
                    tableHeadings={[
                        t('usersListBase.firstName', 'First name'),
                        t('usersListBase.lastName', 'Last name'),
                        t('usersListBase.email', 'Email or PIN'),
                        roleGroupColumnHidden ? null : t('usersListBase.roleGroup', 'Security'),
                        teamColumnHidden ? false : t('usersListBase.team', 'Team'),
                    ]}
                    columns={[
                        (item, view) => view === 'cards' ? (<CardTitle tag="h5">{item.firstName + " " + item.lastName}</CardTitle>) : null,
                        (item, view) => view === 'cards' ? null : item.firstName,
                        (item, view) => view === 'cards' ? null : item.lastName,
                        (item, view) =>
                            view === 'cards' ? (<CardSubtitle tag="h6" className="text-muted">{!!item.uniqueDriverPin ? `PIN: ${item.uniqueDriverPin}` : item.user?.email ?? ''}</CardSubtitle>)
                                : !!item.uniqueDriverPin ? `PIN: ${item.uniqueDriverPin}` : item.user?.email ?? '',
                        (item) => roleGroupColumnHidden ? null : item.user?.roleGroup?.name,
                        (item) => {
                            if (teamColumnHidden) {
                                return null;
                            }

                            var myTeam = subscriptionTeams.find(it => it.id === item.subscriptionTeamId);

                            if (Guid.isEmpty(item.subscriptionTeamId)) {
                                return t("(No team)");
                            }


                            return myTeam?.name;
                        },

                    ]}
                    buttons={(item) => (
                        <ButtonGroup>
                            <LinkContainer to={`${baseRoute}/edit/${item.id}`}>
                                <Button color="primary" outline>
                                    <FontAwesomeIcon icon="edit" />
                                    <> {t('common.edit', 'Edit')}</>
                                </Button>
                            </LinkContainer>
                            <ButtonDropdown isOpen={isMenuOpen(item.id)} toggle={() => toggleMenuOpen(item.id)}>
                                <DropdownToggle color="primary" outline caret>
                                    <span className="sr-only">{t('common.menuDropdown', 'More')}</span>
                                </DropdownToggle>
                                <DropdownMenu>
                                    <LinkContainer to={`${baseRoute}/delete/${item.id}`}>
                                        <DropdownItem className="text-danger">
                                            <FontAwesomeIcon icon="trash" />
                                            <> {t('common.delete', 'Delete')}</>
                                        </DropdownItem>
                                    </LinkContainer>
                                </DropdownMenu>
                            </ButtonDropdown>
                        </ButtonGroup>
                    )}
                    onItemClick={item => history.push(`${baseRoute}/edit/${item.id}`)}
                />
                <ConditionalFragment showIf={isLoading && !items?.length}>
                    <LoadingIndicator fullWidth />
                </ConditionalFragment>
                <ConditionalFragment showIf={!isLoading && !items?.length}>

                    <NoResultsFound search={search} />

                </ConditionalFragment>
                <ConditionalFragment showIf={!isLoading && hasMore()}>

                    <Waypoint key={items?.length ?? 0} onEnter={fetchMore} />
                    <LoadingIndicator fullWidth />

                </ConditionalFragment>
            </MainContainer>
        </Background>
    );
};
