import { CreateUserBase, EditUserBase } from "../../../users/EditUserBase";
import { useCurrentUserOrEmulatedSubscriptionId } from '../../../../globalState/subscriptions/useCurrentUserOrEmulatedSubscriptionId';

/**
 * Create a distributor admin.
 */
export const CreateDistributorAdmin = () => {


    const currentSubscriptionId = useCurrentUserOrEmulatedSubscriptionId();

    return (
        <CreateUserBase
            onCreateDefaultValues={() => ({ subscriptionId: currentSubscriptionId, undefined })}
            defaultRoleGroup="Distributor administrator"
            filterRoleGroups={(groups) => groups.filter(item => item.id === 'Distributor administrator')}
        />);
};

/**
 * Edit a distributor admin.
 */
export const EditDistributorAdmin = () => (
    <EditUserBase
        defaultRoleGroup="Distributor administrator"
        filterRoleGroups={(groups) => groups.filter(item => item.id === 'Distributor administrator')}
    />);