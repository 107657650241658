import { useCallback } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Button, ButtonGroup, Col, Container, Row } from "reactstrap";
import { AssessmentType } from "../../../api/main/models/codeOnly/AssessmentType";
import { useAdministrationDashboardViewModel } from "../../../api/main/dashboard/viewModels/useAdministrationDashboardViewModel";
import { AlertOnErrors } from "../../../shared/alertOnErrors";
import { Background } from "../../shared/background/Background";
import { Banner } from "../../shared/Banner";
import { PillsNavBar } from "../../shared/pillsNavBar/PillsNavBar";
import { LoadingIndicator } from "../../shared/LoadingIndicator";
import { DistributorSubscriptionsDashboardTab } from "../tabs/DistributorSubscriptionsDashboardTab";
import { UserAssessmentAssignment } from "../../../api/main/models/UserAssessmentAssignment";
import { AssessmentSession } from "../../../api/main/models/AssessmentSession";
import { generateDashboardSummary as _generateDashboardSummary } from "../utilities/generateDashboardSummary";
import { useExpandedUserAssessmentAssignments } from "../utilities/useExpandedUserAssessmentAssignments";
import { useActiveDashboardAssessmentType } from "../../../globalState/activeDashboardAssessmentType/useActiveDashboardAssessmentType";
import { DashboardBackButton } from "../backButton/DashboardBackButton";
import { useCurrentUserOrEmulatedSubscriptionId } from "../../../globalState/subscriptions/useCurrentUserOrEmulatedSubscriptionId";
import { DistributorAdministrationNavigation } from "../../subscriptions/Distributors/Administration/DistributorAdministrationNavigation";

/**
 * Dashboard for a subscription.
 */
export const DistributorAdministrationDashboard = () => {
    const { t } = useTranslation();

    const {
        data: {
            subscriptions,
            profiles,
            assessments,
            assessmentSessions,
            userAssessmentAssignments,
            userDriverMetrics: allUserDriverMetrics,
        },
        isLoading,
        errors: loadErrors
    } = useAdministrationDashboardViewModel();

    // Expand the UserAssessmentAssignments so we can work with them easier.
    const expandedUserAssessmentAssignments = useExpandedUserAssessmentAssignments(userAssessmentAssignments, profiles);

    const subscriptionId = useCurrentUserOrEmulatedSubscriptionId();


    // Allow the generation of dashboard summary data.
    const generateDashboardSummary = useCallback((
        sessionFilter: (session: AssessmentSession) => boolean,
        assignmentFilter: (assignment: UserAssessmentAssignment) => boolean,
        assessmentType: AssessmentType) => {
        // Filter by the passed in fitler.
        let mySessions = assessmentSessions?.filter(sessionFilter) ?? [];
        let myAssignments = expandedUserAssessmentAssignments?.filter(assignmentFilter) ?? [];

        // Filter by the passed in type.
        mySessions = mySessions.filter(session => {
            const assessment = assessments?.find(it => it.id === session.assessmentId);
            if (!assessment) {
                return false;
            }

            var isInDistributor: boolean = false;

                //want to filter to only subscriptions for the current distributor if we are managing one
            const currentDistributorsSubscriptions = subscriptions?.filter(item => item.distributorSubscriptionId === subscriptionId);
                const currentSubscription = subscriptions?.find(item => item.id === session.subscriptionId) ?? undefined;

                if (currentDistributorsSubscriptions?.find(item => item === currentSubscription) ?? undefined) {
                    isInDistributor = true;
                }
                return assessment.assessmentType === assessmentType && isInDistributor;
        });

        myAssignments = myAssignments.filter(session => {
            const assessment = assessments?.find(it => it.id === session.assessmentId);
            if (!assessment) {
                return false;
            }

            var isInDistributor: boolean = false;
                //want to filter to only subscriptions for the current distributor if we are managing one
            const currentDistributorsSubscriptions = subscriptions?.filter(item => item.distributorSubscriptionId === subscriptionId);
                const currentSubscription = subscriptions?.find(item => item.id === session.subscriptionId) ?? undefined;

                if (currentDistributorsSubscriptions?.find(item => item === currentSubscription) ?? undefined) {
                    isInDistributor = true;
                }
                return assessment.assessmentType === assessmentType && isInDistributor;
        });

        // Filter the driver metrics to contain only users that have assessments or assignments.
        const myUserDriverMetrics = allUserDriverMetrics?.filter(
            udm => !!mySessions.find(it => it.userId === udm.userId) || !!myAssignments.find(it => it.userId === udm.userId)
        ) ?? [];

        // Generate the summary data.
        const ret = _generateDashboardSummary(mySessions, assessments ?? [], profiles ?? [], myAssignments, myUserDriverMetrics, null);
        return ret;
    }, [assessmentSessions, assessments, profiles, expandedUserAssessmentAssignments, allUserDriverMetrics, subscriptionId, subscriptions]);

    // Track the active assessment type.
    const [activeAssessmentType, setActiveAssessmentType] = useActiveDashboardAssessmentType();
    return (
        <Background>
            <Banner fluid>
                <Row>
                    <Col xs={12} md="auto">
                        <h1>
                            <DashboardBackButton />
                            {t('distributorDashboard.heading.default', 'Distributor dashboard')}
                        </h1>
                    </Col>
                    <Col>
                        <PillsNavBar>
                            <DistributorAdministrationNavigation/>
                        </PillsNavBar>
                    </Col>
                        <Col xs="auto">
                            <ButtonGroup>
                                <Button outline={activeAssessmentType !== AssessmentType.Assessment} onClick={() => setActiveAssessmentType(AssessmentType.Assessment)}>
                                    {t('administrationDashboard.toggle.assessments', 'Assessments')}
                                </Button>
                                <Button outline={activeAssessmentType !== AssessmentType.TrainingModule} onClick={() => setActiveAssessmentType(AssessmentType.TrainingModule)}>
                                    {t('administrationDashboard.toggle.training', 'Training')}
                                </Button>
                            </ButtonGroup>
                        </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
            </Banner>
            <Container fluid>
                <AlertOnErrors errors={[loadErrors]} />

                    <DistributorSubscriptionsDashboardTab
                        subscriptions={subscriptions ?? []}
                        generateDashboardSummary={generateDashboardSummary}
                        activeAssessmentType={activeAssessmentType}

                        assessmentSessions={assessmentSessions ?? []}
                        assessments={assessments ?? []}
                    />
            </Container>
        </Background>
    );
};
