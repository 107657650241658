import { RouteEntry } from "../../../shared/routes";
import { EditAssessment, CreateAssessment } from "./EditAssessment";
import { IdentityRoles } from "../../../configure/security/IdentityRoles";
import { DeleteAssessment } from "./DeleteAssessment";
import { AssessmentsList } from "./AssessmentsList";

export const assessmentRoutes: Array<RouteEntry> = [
    { path: '/administration/assessments', exact: true, component: AssessmentsList, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/assessments/edit/:id', component: EditAssessment, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/assessments/add', component: CreateAssessment, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/assessments/delete/:id', component: DeleteAssessment, authorize: true, requireRole: IdentityRoles.Administration },
];
