import { useTranslation } from "react-i18next";
import { getQuestionTypes, QuestionTypeCategory } from "../../../api/main/models/codeOnly/QuestionType";
import { QuestionsListBase } from "../QuestionsListBase";

/**
 * List of actual questions.
 */
export const QuestionsList = () => {
    const { t } = useTranslation();

    return (
        <QuestionsListBase
            title={t('questionsList.title', 'Questions')}
            questionTypeColumnName={t('questionsList.questionTypeColumnName', 'Type of question')}
            questionTypes={getQuestionTypes(QuestionTypeCategory.Question)}
            baseRoute={'/administration/questions'}
        />
        );
};