import { QuestionTag } from "../../../api/main/models/QuestionTag";
import { QuestionTagButton } from "./QuestionTagButton";
import "./questionTagDisplay.scss";

export interface QuestionTagDisplayProps {
    tags: Array<QuestionTag>,
}

/**
 * Display for toggling a question tag on or off.
 */
export const QuestionTagDisplay = (props: QuestionTagDisplayProps) => {
    const {
        tags,
    } = props;

    return (
        <div className="question-tag-display">
            {
                tags
                    .map(item => (
                        <QuestionTagButton key={item.id}
                            tag={item}
                            isSelected={true}
                            readonly={true}
                        />
                    ))
            }
        </div>
        );
};
