import { useTranslation } from "react-i18next";
import { AssessmentType } from "../../../api/main/models/codeOnly/AssessmentType";
import { SubscriptionAssessmentsListBase } from "../SubscriptionAssessmentsListBase";

/**
 * List of actual assessments for a subscription
 */
export const SubscriptionAssessmentsList = () => {
    const { t } = useTranslation();

    return (
        <SubscriptionAssessmentsListBase
            title={t('subscriptionAssessmentsList.title', 'Licensed assessments')}
            mobileColumn1Name={t('subscriptionAssessmentsList.mobileColumn1Name', 'Assessment')}
            assessmentTypes={[AssessmentType.Assessment]}
            baseRoute={'/administration/assessments'}
        />
        );
};