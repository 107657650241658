import { Button, Row, Col, Form, Label, FormGroup, Spinner, Collapse, FormText, InputGroup, InputGroupAddon, Input } from 'reactstrap';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContainer } from '../shared/MainContainer';
import { useParams, useHistory } from 'react-router';
import { useChanges } from '../../shared/useChanges';
import { useSaveSubscriptionCallback } from '../../api/main/subscriptions/useSaveSubscriptionCallback';
import { useValidatorCallback } from 'pojo-validator-react';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { FormButtons } from '../shared/FormButtons';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { useAsyncCallback } from 'react-use-async-callback';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Banner } from '../shared/Banner';
import { Background } from '../shared/background/Background';
import { useEditSubscriptionViewModel } from '../../api/main/subscriptions/viewModels/useEditSubscriptionViewModel';
import React, { useEffect, useMemo } from 'react';
import { useBlobReferenceState } from '../shared/useBlobReferenceState/useBlobReferenceState';
import { ImageTab } from './image/ImageTab';
import { Subscription, subscriptionDefaultValues } from '../../api/main/models/Subscription';
import { PillsNavBar } from '../shared/pillsNavBar/PillsNavBar';
import { SubscriptionAdministrationNavigation } from './SubscriptionAdministrationNavigation';
import { TwoValueSwitch } from '../shared/TwoValueSwitch';
import { defaultRiskRules, OverallRiskCategory, overallRiskService } from '../../services/OverallRiskService';
import { SubscriptionType } from '../../api/main/models/codeOnly/SubscriptionType';
import { DistributorEditNavigation } from './Distributors/DistributorEditNavigation';
import { DistributorSubscriptionEditNavigation } from './Distributors/Administration/DistributorSubscriptionEditNavigation';
import { useCurrentUserOrEmulatedSubscriptionId } from '../../globalState/subscriptions/useCurrentUserOrEmulatedSubscriptionId';
import { StudyEditNavigation } from './Studies/StudyEditNavigation';
import { useGenerateUniqueStudyPinCallback } from '../../api/main/subscriptions/useGenerateUniqueStudyPinCallback';

interface EditSubscriptionProps {
    isCreate?: boolean,
    onCreateDefaultValues?: () => Partial<Subscription>,
    subscriptionType: SubscriptionType,
    isInDistributor?: boolean,
}

/**
 * Create a new subscription.
 * @param props
 */
export const CreateSubscriptionBase = (props: EditSubscriptionProps) => (<EditSubscriptionBase isCreate={true} {...props} />);

/**
 * Edit a subscription.
 */
export const EditSubscriptionBase = (props: EditSubscriptionProps) => {
    const {
        isCreate,
        onCreateDefaultValues,
        subscriptionType,
        isInDistributor = false,
    } = props;
    const { t } = useTranslation();
    const { id } = useParams<{ id: string | undefined; }>();
    const currentSubscriptionId = useCurrentUserOrEmulatedSubscriptionId();

    const { data: {
        model: storeModel,
    }, isLoading: _isLoading, errors: loadErrors } = useEditSubscriptionViewModel(id);
    const isLoading = _isLoading;
    const { model, change, changes } = useChanges(storeModel, isCreate ? { ...subscriptionDefaultValues(), ...(onCreateDefaultValues ? onCreateDefaultValues() : {}) } : undefined);
    const [save, { errors: saveErrors }] = useSaveSubscriptionCallback();
    const history = useHistory();

    //Allow subscription to work with a passed in subscription type or with the type of the pre existing subscription
    const activeSubscriptionType: SubscriptionType = useMemo(() => model?.subscriptionType as SubscriptionType ?? subscriptionType, [model, subscriptionType]);

    const [imageBlob, setImageBlob, { upload: uploadImageBlob, isUploading: isUploadingImageBlob, uploadErrors: uploadImageBlobErrors, clearUpload: clearImageBlob }] = useBlobReferenceState(blob => {
        change({ brandImageBlobReferenceId: blob?.id ?? null });
    });
    useEffect(() => setImageBlob(storeModel?.brandImageBlobReference ?? null), [setImageBlob, storeModel]);

    // Unique PINS as an alternative to traditional username and password logins.
    const [generateUniqueStudyPin, { isExecuting: isGeneratingUniqueStudyPin, errors: generateUniqueStudyPinErrors }] = useGenerateUniqueStudyPinCallback();
    const [regeneratePin] = useAsyncCallback(async () => {
        const newPin = await generateUniqueStudyPin();
        change({ studyUniqueCode: newPin });
    }, [generateUniqueStudyPin, change]);

    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            companyName: () => !model?.companyName ? t('editSubscription.companyName.required', 'Subscription name is required') : '',
            //brandColor: () => !!model.brandColor && (model?.brandColor.length > 7 || model.brandColor.indexOf('#') === -1) ? t('editSubscription.brandColorFormat', 'Brand color must be in the format #123456 representing your hex brand color code') : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, [model]);

    const [saveForm, { isExecuting: isSaving, errors: saveFormErrors }] = useAsyncCallback(async (options?: { navigateTo?: string, }) => {
        if (!validate()) {
            return;
        }
        changes.subscriptionType = activeSubscriptionType;

        if (isInDistributor) {
            changes.distributorSubscriptionId = currentSubscriptionId;
        }

        await save(model.id, changes, !!isCreate);

        // Navigate to the next screen.
        if (options?.navigateTo) {
            history.push(options.navigateTo);
        } else {
            history.goBack();
        }
    }, [validate, save, model, changes, isCreate, history]);

    // Track if the subscription has had a study access code generated yet or not.
    const hasStudyAccessCode = !!model?.studyUniqueCode;


    // Render the UI.
    //
    return (
        <Background>
            <Banner>
                <Row>
                    <Col xs={12} md="auto">
                        <h1>
                            {
                                activeSubscriptionType === SubscriptionType.Distributor ? (
                                    isCreate ? (
                                        <>{t('editSubscription.createHeading.distributor', 'Add distributor')}</>
                                    ) : (
                                        <>{t('editSubscription.editHeading.distributor', 'Edit distributor')}</>
                                    )
                                ) : (
                                    activeSubscriptionType === SubscriptionType.Study ? (
                                        isCreate ? (
                                            <>{t('editSubscription.createHeading.study', 'Add study')}</>
                                        ) : (
                                            <>{t('editSubscription.editHeading.study', 'Edit study')}</>
                                        )
                                    ) : isCreate ? (
                                        <>{t('editSubscription.createHeading.subscription', 'Add subscription')}</>
                                    ) : (
                                        <>{t('editSubscription.editHeading.subscription', 'Edit subscription')} </>
                                    )
                                )
                            }
                        </h1>
                    </Col>
                    <Col>
                        <ConditionalFragment showIf={activeSubscriptionType === SubscriptionType.Subscription}>
                            <ConditionalFragment showIf={isInDistributor}>
                                <PillsNavBar>
                                    <DistributorSubscriptionEditNavigation
                                        id={id ?? ''}
                                        editLink={history.location.pathname}
                                        onNavigate={link => saveForm({ navigateTo: link })}
                                    />
                                </PillsNavBar>
                            </ConditionalFragment>
                            <ConditionalFragment showIf={!isInDistributor}>
                                <PillsNavBar>
                                    <SubscriptionAdministrationNavigation
                                        id={model?.id ?? ''}
                                        editLink={history.location.pathname}
                                        onNavigate={link => saveForm({ navigateTo: link })}
                                    />
                                </PillsNavBar>
                            </ConditionalFragment>
                        </ConditionalFragment>
                        <ConditionalFragment showIf={activeSubscriptionType === SubscriptionType.Distributor}>
                            <PillsNavBar>
                                <DistributorEditNavigation
                                    id={model?.id ?? ''}
                                    editLink={history.location.pathname}
                                    onNavigate={link => saveForm({ navigateTo: link })}
                                />
                            </PillsNavBar>
                        </ConditionalFragment>
                        <ConditionalFragment showIf={activeSubscriptionType === SubscriptionType.Study}>
                            <PillsNavBar>
                                <StudyEditNavigation
                                    id={model?.id ?? ''}
                                    editLink={history.location.pathname}
                                    onNavigate={link => saveForm({ navigateTo: link })}
                                />
                            </PillsNavBar>
                        </ConditionalFragment>
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
            </Banner>

            <MainContainer>
                <AlertOnErrors errors={[
                    loadErrors,
                    saveFormErrors, saveErrors, uploadImageBlobErrors,
                    generateUniqueStudyPinErrors
                ]} />

                <Form onSubmit={e => { e.preventDefault(); saveForm(); }}>
                    <FormGroup>
                        <Label htmlFor="subscriptionName">{t('editSubscription.companyName.label', '{{subscriptionType}} name', { subscriptionType: subscriptionType === SubscriptionType.Study ? subscriptionType : 'Subscription' })}</Label>
                        <ValidatedInput name="companyName" type="text" value={model.companyName ?? ''}
                            onChange={e => change({ companyName: e.currentTarget.value })}
                            onBlur={e => validate('companyName')}
                            validationErrors={validationErrors['companyName']} />
                    </FormGroup>

                    <ConditionalFragment showIf={subscriptionType === SubscriptionType.Study}>
                        <FormGroup>
                            <Label htmlFor="studyUniqueCode">{t('editSubscription.studyUniqueCode.label', 'Study access code')}</Label>
                            <Row>
                                <Col>
                                    <Input name="studyUniqueCode" type="text" readOnly value={model.studyUniqueCode ?? ''} />
                                </Col>
                                <Col xs="auto">
                                    <ButtonAsync type="button" color={hasStudyAccessCode ? 'danger' : 'primary'} outline onClick={async e => regeneratePin()}
                                        isExecuting={isGeneratingUniqueStudyPin}
                                        executingChildren={<><Spinner size="sm" />{t('common.generatingCode', 'Generating unique code...')}</>}>
                                        <FontAwesomeIcon icon={hasStudyAccessCode ? 'exclamation-triangle' : 'dice'} />
                                        <> </>
                                        {
                                            hasStudyAccessCode ? t('editSubscription.regenerateCode', 'Reset code')
                                                : t('editSubscription.regenerateCode', 'Generate code')
                                        }
                                    </ButtonAsync>
                                </Col>
                            </Row>
                            <FormText>
                                {t('editSubscription.studyUniqueCode.hint.text', 'Each study needs its own unqiue study access code.')}
                                <ConditionalFragment showIf={hasStudyAccessCode}>
                                    <> </>
                                    <span className="text-danger">
                                        {t('editSubscription.studyUniqueCode.hint.danger', 'If you reset the study access code any existing links using the previous code will no longer work.')}
                                    </span>
                                </ConditionalFragment>
                            </FormText>
                        </FormGroup>

                        <FormGroup>
                            <Label htmlFor="sharableUrl">{t('editSubscription.sharableUrl.label', 'Study start URL')}</Label>
                            <Input name="sharableUrl" type="url" readOnly value={`${window.location.protocol}//${window.location.host}/study/${model?.studyUniqueCode ?? ''}`} />
                            <FormText>
                                {t('editSubscription.sharableUrl.label', 'This is the link you need to either provide directly to your study participants or connect to your study management software such as Prolific.  When this link is visited the participant will be taken to the assessment to start the study.  For the best experience using study management software, pass the participant identifier as a URL parameter or query string using ?user=[id], e.g. ?user=12345.  This will have to be configured in your study management software but will help you link the completed assessments to the study managements profiling data captured about the participant.')}
                            </FormText>
                        </FormGroup>

                        <FormGroup>
                            <Label htmlFor="studyReturnUrl">{t('editSubscription.studyReturnUrl.label', 'Study complete return URL')}</Label>
                            <Input name="studyReturnUrl" type="url" value={model?.studyReturnUrl} onChange={e => change({ studyReturnUrl: e.currentTarget.value })} />
                            <FormText>
                                {t('editSubscription.studyReturnUrl.label', 'This is the link the user will be redirected to after they complete their assessment and click close.  If you are using study management software such as Prolific, you should be given this URL when setting up the study.  If you do not supply a link here, we will redirect the user to our own built in thank you page.')}
                            </FormText>
                        </FormGroup>
                    </ConditionalFragment>

                    <ConditionalFragment showIf={subscriptionType === SubscriptionType.Subscription}>
                        <FormGroup>
                            <Label htmlFor="subscriptionName">{t('editSubscription.riskRules.label', 'Risk calculation rules')}</Label>
                            <Row noGutters>
                                <Col xs="auto">
                                    <span className="text-danger">
                                        {overallRiskService.getRiskName(OverallRiskCategory.High)}
                                    </span>
                                    <> </>
                                    <span>
                                        {t('editSubscription.lessThanSign', '<')}
                                    </span>
                                </Col>
                                <Col xs="auto" style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                                    <ValidatedInput name="highRiskBelow" type="number"
                                        min={0} max={100}
                                        value={model.highRiskBelow ?? 0}
                                        onChange={e => change({ highRiskBelow: e.currentTarget.valueAsNumber })}
                                        onBlur={e => validate('highRiskBelow')}
                                        validationErrors={validationErrors['highRiskBelow']}
                                    />
                                </Col>
                                <Col xs="auto">
                                    <span>
                                        {t('editSubscription.lessThanOrEqualsSign', '<=')}
                                    </span>
                                    <> </>
                                    <span className="text-warning">
                                        {overallRiskService.getRiskName(OverallRiskCategory.Medium)}
                                    </span>
                                    <> </>
                                    <span>
                                        {t('editSubscription.lessThanSign', '<')}
                                    </span>
                                </Col>
                                <Col xs="auto" style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                                    <ValidatedInput name="mediumRiskBelow" type="number"
                                        min={0} max={100}
                                        value={model.mediumRiskBelow ?? 0}
                                        onChange={e => change({ mediumRiskBelow: e.currentTarget.valueAsNumber })}
                                        onBlur={e => validate('mediumRiskBelow')}
                                        validationErrors={validationErrors['mediumRiskBelow']}
                                    />

                                </Col>
                                <Col xs="auto">
                                    <span>
                                        {t('editSubscription.lessThanOrEqualsSign', '<=')}
                                    </span>
                                    <> </>
                                    {overallRiskService.getRiskName(OverallRiskCategory.Low)}
                                </Col>
                                <Col></Col>
                            </Row>
                            <FormText>
                                {t('editSubscription.riskRules.formText', 'Default rules for new subscriptions and the Esitu Drive dashboard are: High risk < {{highRiskBelow}} <= Medium risk < {{mediumRiskBelow}} <= Low risk.', defaultRiskRules)}
                            </FormText>
                        </FormGroup>
                    </ConditionalFragment>

                    <ConditionalFragment showIf={subscriptionType !== SubscriptionType.Study}>
                        <FormGroup>
                            <Label htmlFor="restrictMaxUsers">{t('editSubscription.restrictMaxUsers.label', 'Restrict the number of users?')}</Label>
                            <TwoValueSwitch
                                leftLabel={t('common.no', 'No')}
                                rightLabel={t('common.yes', 'Yes')}
                                checked={model?.restrictMaxUsers ?? false}
                                onChange={checked => change({ restrictMaxUsers: checked })}
                            />
                        </FormGroup>
                        <Collapse isOpen={model?.restrictMaxUsers ?? false}>
                            <FormGroup>
                                <Label htmlFor="userLimit">{t('editSubscription.maxUsers.label', 'Maximum number of users (includes drivers and managers)')}</Label>
                                <ValidatedInput name="maxUsers" type="number" value={model.maxUsers} min="0"
                                    onChange={e => change({ maxUsers: parseInt(e.currentTarget.value) })}
                                    onBlur={e => validate('maxUsers')}
                                    validationErrors={validationErrors['maxUsers']} />
                                <FormText>
                                    {t('editSubscription.maxUsers.help', 'Setting to 0 gives the subscription unlimited users')}
                                </FormText>
                            </FormGroup>
                        </Collapse>
                    </ConditionalFragment>

                    <ConditionalFragment showIf={subscriptionType !== SubscriptionType.Study}>
                        <FormGroup>
                            <Label htmlFor="allowBranding">{t('editSubscription.allowBranding.label', 'Allow custom branding?')}</Label>
                            <TwoValueSwitch
                                leftLabel={t('common.no', 'No')}
                                rightLabel={t('common.yes', 'Yes')}
                                checked={model?.allowBranding ?? false}
                                onChange={checked => change({ allowBranding: checked })}
                            />
                        </FormGroup>
                        <Collapse isOpen={model?.allowBranding ?? false}>
                            <Row>
                                <Col>
                                    <ImageTab
                                        model={model}
                                        imageBlob={imageBlob} uploadImageBlob={uploadImageBlob} isUploadingImageBlob={isUploadingImageBlob} clearImageBlob={clearImageBlob}
                                    />
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label htmlFor="brandColor">{t('editSubscription.brandColor.label', 'Brand colour')}</Label>
                                        <InputGroup>
                                            <ValidatedInput name="brandColor" type="color" value={model.brandColor ?? ''}
                                                onChange={e => change({ brandColor: e.currentTarget.value })}
                                                onBlur={e => validate('brandColor')}
                                                validationErrors={validationErrors['brandColor']} />
                                            <InputGroupAddon addonType="append">
                                                <Button color="danger" onClick={() => change({ brandColor: '' })}>
                                                    {t('editSubscription.brandColor.reset', 'Reset')}
                                                </Button>
                                            </InputGroupAddon>
                                        </InputGroup>
                                        <FormText>
                                            {t('editSubscription.brandColor.formText', 'This will change the primary colour of buttons shown.  Other colours used by the app will maintain Esitu colours and branding.')}
                                        </FormText>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Collapse>
                    </ConditionalFragment>

                    <FormGroup>
                        <Label htmlFor="allowExport">{t('editSubscription.allowExport.label', 'Allow export of results to a spreadsheet?')}</Label>
                        <TwoValueSwitch
                            leftLabel={t('common.no', 'No')}
                            rightLabel={t('common.yes', 'Yes')}
                            checked={model?.allowExport ?? false}
                            onChange={checked => change({ allowExport: checked })}
                        />
                    </FormGroup>

                    <FormButtons>
                        <ConditionalFragment showIf={!isLoading}>
                            <ButtonAsync color="primary" isExecuting={isSaving}
                                executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}>
                                <FontAwesomeIcon icon="save" />
                                <> {t('common.save', 'Save')}</>
                            </ButtonAsync>
                        </ConditionalFragment>
                        <Button type="button" color="primary" outline onClick={e => history.goBack()}>
                            {t('common.cancel', 'Cancel')}
                        </Button>
                    </FormButtons>
                </Form>
            </MainContainer>
        </Background >
    );
};
