import { LoginResult } from "../../api/account";
import { AsyncActionStatus } from "../../shared/abstractStore";
import { useAsyncCallback } from "react-use-async-callback";
import { StudyLoginRequest } from "./AccountService";
import { useAccountService } from "./useAccountService";

/**
 * Callback to login to a study.
 */
export function useStudyLoginCallback(): [(model: StudyLoginRequest) => Promise<LoginResult>, AsyncActionStatus] {
    const accountService = useAccountService();

    const [action, { isExecuting, errors }] = useAsyncCallback(async (model: StudyLoginRequest) => {
        let result = await accountService.studyLogin(model);

        // If we get here we have a successful login
        return result;
    }, [accountService]);

    return [action, { isExecuting, errors }];
}
