import { IdentityRoles } from "../../../configure/security/IdentityRoles";
import { RouteEntry } from "../../../shared/routes";
import { AdministratorsList } from "./AdministratorsList";
import { DeleteAdministrator } from "./DeleteAdministrator";
import { CreateAdministrator, EditAdministrator } from "./EditAdministrator";


export const administratorsRoutes: Array<RouteEntry> = [
    { path: '/administration/administrators', exact: true, component: AdministratorsList, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/administrators/edit/:id', component: EditAdministrator, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/administrators/add', component: CreateAdministrator, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/administrators/delete/:id', component: DeleteAdministrator, authorize: true, requireRole: IdentityRoles.Administration },
];
