import * as React from 'react';
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { editSdkWebhookSupportingDataQuery, editSdkWebhookSupportingDataQueryVariables } from '../../generated/editSdkWebhookSupportingDataQuery';
import { mainApiConfig } from '../../../../configure/mainApiConfig';
import { AsyncLoadOptions, AsyncLoadResult } from '../../../../shared/abstractStore';
import { sdkClientFieldsFragment } from '../../models/SdkClient';
import { Guid } from 'guid-string';

/**
 * Get all the supporting data needed for EditSdkWebhook.
 * @param id
 * @param options
 */
export function useEditSdkWebhookSupportingData(subscriptionId: string | undefined | null, options: AsyncLoadOptions = {}): AsyncLoadResult<editSdkWebhookSupportingDataQuery> {
    // Query data we need from the api.
    const [load, { data, loading, error, refetch }] = useLazyQuery<editSdkWebhookSupportingDataQuery, editSdkWebhookSupportingDataQueryVariables>(
        gql`
        query editSdkWebhookSupportingDataQuery ($subscriptionId: ID!) {
            sdkClients (targetId: $subscriptionId) {
                ...sdkClientFields
            }
        }

        ${sdkClientFieldsFragment}
        `,
        {

            variables: {
                subscriptionId: subscriptionId ?? Guid.empty,
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load])

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error,
    }), [data, refresh, loading, error]);
}
