import gql from "graphql-tag";
import { Guid } from "guid-string";
import { sdkClientFields } from "../generated/sdkClientFields";

export const sdkClientFieldsFragment = gql`
    fragment sdkClientFields on SdkClient {
        id
        targetId
        targetType
        name
        apiSecret
        ipAllowListJson
        rolesJson
        archived
    }
`;


export type SdkClient = Omit<sdkClientFields, '__typename'>;

export const sdkClientDefaultValues = (): Partial<SdkClient> => ({
    id: Guid.newGuid(),
    targetId: undefined,
    targetType: '',
    name: '',
    apiSecret: '',
    ipAllowListJson: '[]',
    rolesJson: '["API"]',
    archived: false,
});