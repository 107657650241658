import { useTranslation } from "react-i18next";
import { getQuestionTypes, QuestionTypeCategory } from "../../../api/main/models/codeOnly/QuestionType";
import { QuestionsListBase } from "../QuestionsListBase";

/**
 * List of information pages.
 */
export const InformationList = () => {
    const { t } = useTranslation();

    return (
        <QuestionsListBase
            title={t('informationList.title', 'Information pages')}
            questionTypeColumnName={t('informationList.questionTypeColumnName', 'Type of information')}
            questionTypes={getQuestionTypes(QuestionTypeCategory.Informtion)}
            baseRoute={'/administration/information-pages'}
        />
    );
};