import { useCallback, useMemo } from "react";
import { ModelArrayChanges } from "../../../shared/useChanges";

export type DisplayOrderReturnType<T> = [
    Array<T>,
    {
        moveUp: (id: string) => void,
        canMoveUp: (id: string) => boolean,
        moveDown: (id: string) => void,
        canMoveDown: (id: string) => boolean,
    }
];

/**
 * Custom hook to provide an ordered result as well as methods to manage the order of models that use a displayOrder field.
 * 
 * If you only want to manage the display order of a filtered list of all items managed by the manager, pass a filterItems parameter to filter down to only the items you want.
 */
export function useDisplayOrder<T extends { id: string, displayOrder: number }>(managedItems: ModelArrayChanges<T, string>, filterItems?: (item: T) => boolean)
    : DisplayOrderReturnType<T>
{
    // Order the items so they show in display order.
    const orderedItems = useMemo(() => {
        let ret = [...managedItems.model];

        // If we are to filter the items we manage, do so now.
        if (filterItems) {
            ret = ret.filter(filterItems);
        }

        ret.sort((a, b) => {
            if (a.displayOrder === b.displayOrder) {
                return 0;
            } else if (a.displayOrder > b.displayOrder) {
                return 1;
            } else {
                return -1;
            }
        });
        return ret;
    }, [managedItems.model, filterItems]);

    // Can we move this item up?
    const canMoveUp = useCallback((id: string) => {
        const item = orderedItems.find(it => it.id === id);
        if (!item) {
            return false;
        }

        const index = orderedItems.indexOf(item);
        if (index <= 0) {
            return false;
        }

        return true;
    }, [orderedItems]);

    // Can we move this item down?
    const canMoveDown = useCallback((id: string) => {
        const item = orderedItems.find(it => it.id === id);
        if (!item) {
            return false;
        }

        const index = orderedItems.indexOf(item);
        if (index >= orderedItems.length - 1) {
            return false;
        }

        return true;
    }, [orderedItems]);

    // Move an item up
    const moveUp = useCallback((id: string) => {
        const item = orderedItems.find(it => it.id === id);
        if (!item) {
            return false;
        }

        const index = orderedItems.indexOf(item);
        if (index <= 0) {
            return false;
        }

        const otherModel = orderedItems[index - 1];

        // Cope with the fact that sometimes we end up with multiple items withthe same display order when added in bulk (thanks to moment.unix() often being used to generate a display order).
        // Without this the reordering wouldn't happen for items that share a displayOrder.
        const newDisplayOrderMe = item.displayOrder === otherModel.displayOrder ? otherModel.displayOrder - 1 : otherModel.displayOrder;

        // Swap/update the display orders.
        managedItems.changeFor(item.id, { displayOrder: newDisplayOrderMe } as Partial<T>);
        managedItems.changeFor(otherModel.id, { displayOrder: item.displayOrder } as Partial<T>);
    }, [orderedItems, managedItems]);

    // Move an answer down in the order.
    const moveDown = useCallback((id: string) => {
        const item = orderedItems.find(it => it.id === id);
        if (!item) {
            return false;
        }

        const index = orderedItems.indexOf(item);
        if (index >= orderedItems.length - 1) {
            return false;
        }

        const otherModel = orderedItems[index + 1];

        // Cope with the fact that sometimes we end up with multiple items withthe same display order when added in bulk (thanks to moment.unix() often being used to generate a display order).
        // Without this the reordering wouldn't happen for items that share a displayOrder.
        const newDisplayOrderMe = item.displayOrder === otherModel.displayOrder ? otherModel.displayOrder +1 : otherModel.displayOrder;

        // Swap/update the display orders.

        managedItems.changeFor(item.id, { displayOrder: newDisplayOrderMe } as Partial<T>);
        managedItems.changeFor(otherModel.id, { displayOrder: item.displayOrder } as Partial<T>);
    }, [orderedItems, managedItems]);

    // Return everything in a format that is easy for people to grab the bits they want.
    const ret = useMemo((): DisplayOrderReturnType<T> => ([
        orderedItems,
        {
            canMoveUp,
            moveUp,
            canMoveDown,
            moveDown,
        }
    ]), [orderedItems, canMoveUp, moveUp, canMoveDown, moveDown]);

    return ret;
}