import * as React from 'react';
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { AsyncLoadPagedResult, AsyncLoadPagedOptions } from '../../../../shared/abstractStore';
import { useFetchMoreCallback } from '../../../../shared/useFetchMoreCallback';
import { subscriptionTeamsListViewModelQuery, subscriptionTeamsListViewModelQueryVariables } from '../../generated/subscriptionTeamsListViewModelQuery';
import { mainApiConfig } from '../../../../configure/mainApiConfig';
import { subscriptionTeamFieldsFragment } from '../../models/SubscriptionTeam';
import { Guid } from 'guid-string';
import { subscriptionFieldsFragment } from '../../models/Subscription';

/**
 * Get a list of subscription teams for display as in SubscriptionTeamsList.
 * @param id
 * @param options
 */
export function useSubscriptionTeamsListViewModel(subscriptionId: string | undefined | null, options: AsyncLoadPagedOptions = {}): AsyncLoadPagedResult<subscriptionTeamsListViewModelQuery> {
    // Query data we need from the api.
    const [load, { data, loading, error, refetch, fetchMore: fetchMoreFromStore }] = useLazyQuery<subscriptionTeamsListViewModelQuery, subscriptionTeamsListViewModelQueryVariables>(
        gql`
        query subscriptionTeamsListViewModelQuery ($offset: Int, $first: Int, $subscriptionId: ID!) {
            items: subscriptionTeams (offset: $offset, first: $first, subscriptionId: $subscriptionId) {
                ...subscriptionTeamFields
            }

            subscription (id: $subscriptionId) {
                ...subscriptionFields
            }
        }

        ${subscriptionTeamFieldsFragment}
        ${subscriptionFieldsFragment}
        `,
        {

            variables: {
                offset: options.startOffset,
                first: options.pageSize,
                subscriptionId: subscriptionId ?? Guid.empty,
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load])

    // Get the functions used to manage paging.
    const { fetchMore, hasMore: _hasMore } = useFetchMoreCallback<subscriptionTeamsListViewModelQuery>(
        fetchMoreFromStore,
        (options?.startOffset ?? 0) + (data?.items?.length ?? 0),
        (prevResults, newResults) => ({
            ...prevResults,
            ...newResults,

            items: [...(prevResults?.items ?? []), ...(newResults?.items ?? [])],
            subscription: newResults?.subscription ?? null,
        }),
        (newResults) => newResults?.items?.length
    );
    const hasMore = React.useCallback(() => !options.pageSize ? false : _hasMore(), [options.pageSize, _hasMore]);
    
    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error,
        fetchMore: fetchMore,
        hasMore: hasMore,
    }), [data, refresh, loading, error, fetchMore, hasMore]);
}
