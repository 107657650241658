import { Guid } from "guid-string";

/**
 * One event type for a webhook.
 */
export interface SdkWebhookEventType {
    id: string,
    group: string,
    dataObject: any,
}

/**
 * All event types for a webhook.
 * 
 * These event id's should be match those used in Services/SdkEvents/SdkEventManager_Events.cs for the backend.
 */
export const sdkWebhookEventTypes: Array<SdkWebhookEventType> = [
    // Core activities.
    { id: 'assessments.assigned', group: 'assessments', dataObject: { assessmentId: Guid.empty, userId: Guid.empty, subscriptionTeamId: Guid.empty, subscriptionId: Guid.empty, }, },
    { id: 'assessments.started', group: 'assessments', dataObject: { assessmentId: Guid.empty, userId: Guid.empty, subscriptionId: Guid.empty, }, },
    { id: 'assessments.complete', group: 'assessments', dataObject: { assessmentId: Guid.empty, userId: Guid.empty, subscriptionId: Guid.empty, }, },

    { id: 'training.assigned', group: 'training', dataObject: { assessmentId: Guid.empty, userId: Guid.empty, subscriptionTeamId: Guid.empty, subscriptionId: Guid.empty, }, },
    { id: 'training.started', group: 'training', dataObject: { assessmentId: Guid.empty, userId: Guid.empty, subscriptionId: Guid.empty, }, },
    { id: 'training.complete', group: 'training', dataObject: { assessmentId: Guid.empty, userId: Guid.empty, subscriptionId: Guid.empty, }, },

    // Maintenance.
    { id: 'users.created', group: 'users', dataObject: { userId: Guid.empty, profileId: Guid.empty, subscriptionId: Guid.empty, }, },
    { id: 'users.updated', group: 'users', dataObject: { userId: Guid.empty, profileId: Guid.empty, subscriptionId: Guid.empty, }, },
    { id: 'users.deleted', group: 'users', dataObject: { userId: Guid.empty, profileId: Guid.empty, subscriptionId: Guid.empty, }, },

    { id: 'subscriptionTeams.created', group: 'subscriptionTeams', dataObject: { subscriptionTeamId: Guid.empty, subscriptionId: Guid.empty, }, },
    { id: 'subscriptionTeams.updated', group: 'subscriptionTeams', dataObject: { subscriptionTeamId: Guid.empty, subscriptionId: Guid.empty, }, },
    { id: 'subscriptionTeams.deleted', group: 'subscriptionTeams', dataObject: { subscriptionTeamId: Guid.empty, subscriptionId: Guid.empty, }, },
];
