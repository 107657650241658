import { Guid } from "guid-string";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { FormGroup, Label } from "reactstrap";
import { Assessment } from "../../../../api/main/models/Assessment";
import { QuestionTag } from "../../../../api/main/models/QuestionTag";
import { QuestionTagLink } from "../../../../api/main/models/QuestionTagLink";
import { ModelArrayChanges } from "../../../../shared/useChanges";
import { QuestionTagSelector } from "../../../questionTags/questionTagSelector/QuestionTagSelector";

export interface QuestionTagsTabProps {
    model: Assessment | undefined,

    questionTags: Array<QuestionTag>,
    linksManager: ModelArrayChanges<QuestionTagLink, string>,
}

/**
 * Tab for maintaining the tags for this question.
 * @param props
 */
export const QuestionTagsTab = (props: QuestionTagsTabProps) => {
    const {
        model,

        questionTags,
        linksManager,
    } = props;

    const { t } = useTranslation();

    // Returns if this tag is selected or not.
    const isSelected = useCallback((id: string) => {
        return !!linksManager.model.find(it => it.questionTagId === id);
    }, [linksManager]);

    // Toggle the selection of the tag.
    const toggleSelected = useCallback((id: string) => {
        const existing = linksManager.model.find(it => it.questionTagId === id);
        if (existing) {
            linksManager.removeFor(existing.id);
        } else {
            linksManager.addFor({ id: Guid.newGuid(), questionTagId: id, targetId: model?.id, targetType: 'Assessment', archived: false, });
        }
    }, [linksManager, model?.id]);
    
    return (
        <>
            <FormGroup>
                <Label htmlFor="questionTags">{t('questionTagsTab.questionTags', 'Tags')}</Label>

                <QuestionTagSelector tags={questionTags} isSelected={isSelected} toggleSelected={toggleSelected} />
            </FormGroup>
        </>
        );
};