import { useTranslation } from "react-i18next";
import { AssessmentType } from "../../../api/main/models/codeOnly/AssessmentType";
import { UserAssessmentAssignmentsListBase } from "../UserAssessmentAssignmentsListBase";

/**
 * List of training modules (using the assessments model) for a user/team.
 */
export const UserAssessmentTrainingModuleAssignmentsList = () => {
    const { t } = useTranslation();

    return (
        <UserAssessmentAssignmentsListBase
            title={t('userTrainingModulesList.title', 'Required training')}
            mobileColumn1Name={t('userTrainingModulesList.mobileColumn1Name', 'Training module')}
            assessmentTypes={[AssessmentType.TrainingModule]}
        />
        );
};