import * as React from 'react';
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { sdkOverviewViewModelQuery, sdkOverviewViewModelQueryVariables } from '../../generated/sdkOverviewViewModelQuery';
import { mainApiConfig } from '../../../../configure/mainApiConfig';
import { AsyncLoadOptions, AsyncLoadResult } from '../../../../shared/abstractStore';
import { sdkClientFieldsFragment } from '../../models/SdkClient';
import { sdkWebhookFieldsFragment } from '../../models/SdkWebhook';
import { subscriptionFieldsFragment } from '../../models/Subscription';
import { Guid } from 'guid-string';

/**
 * Get a list of questions from the store with other details needed for the questions list.
 * @param id
 * @param options
 */
export function useSdkOverviewViewModel(subscriptionId: string | undefined | null, options: AsyncLoadOptions = {}): AsyncLoadResult<sdkOverviewViewModelQuery> {
    // Query data we need from the api.
    const [load, { data, loading, error, refetch }] = useLazyQuery<sdkOverviewViewModelQuery, sdkOverviewViewModelQueryVariables>(
        gql`
        query sdkOverviewViewModelQuery ($subscriptionId: ID!) {
            sdkClients (targetId: $subscriptionId) {
                ...sdkClientFields
            }

            sdkWebhooks (targetId: $subscriptionId) {
                ...sdkWebhookFields
            }

            subscription (id: $subscriptionId) {
                ...subscriptionFields
            }
        }

        ${sdkClientFieldsFragment}
        ${sdkWebhookFieldsFragment}
        ${subscriptionFieldsFragment}
        `,
        {

            variables: {
                subscriptionId: subscriptionId ?? Guid.empty,
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load])

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error,
    }), [data, refresh, loading, error]);
}
