import gql from "graphql-tag";
import { Guid } from "guid-string";
import moment from "moment";
import { userAssessmentAssignmentFields } from "../generated/userAssessmentAssignmentFields";

export const userAssessmentAssignmentFieldsFragment = gql`
    fragment userAssessmentAssignmentFields on UserAssessmentAssignment {
        id
        subscriptionId
        userId
        subscriptionTeamId
        assessmentId
        targetDate
        archived
        maxRestarts
    }
`;


export type UserAssessmentAssignment = Omit<userAssessmentAssignmentFields, '__typename'>;

// Default values.
export const userAssessmentAssignmentDefaultValues = (): Partial<UserAssessmentAssignment> => ({
    id: Guid.newGuid(),
    subscriptionId: undefined,
    assessmentId: undefined,
    subscriptionTeamId: undefined,
    userId: undefined,
    targetDate: moment().add('month', 2).toISOString(),
    archived: false,
    maxRestarts: 0,
});