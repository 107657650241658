import { SubscriptionType } from "../../../api/main/models/codeOnly/SubscriptionType";
import { CreateSubscriptionBase, EditSubscriptionBase } from "../EditSubscriptionBase";

/**
 * Create an actual Study
 */
export const CreateStudy = () => (
    <CreateSubscriptionBase
        subscriptionType={SubscriptionType.Study}
        onCreateDefaultValues={() => ({ subscriptionType: SubscriptionType.Study })}
    />);

/**
 * Edit an actual Study
 */
export const EditStudy = () => (<EditSubscriptionBase subscriptionType={SubscriptionType.Study} />);