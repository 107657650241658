import { useCallback, useMemo } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Col, ListGroup, ListGroupItemHeading, Row } from "reactstrap";
import { isNullOrUndefined } from "util";
import { Assessment } from "../../../api/main/models/Assessment";
import { AssessmentSession } from "../../../api/main/models/AssessmentSession";
import { AssessmentType } from "../../../api/main/models/codeOnly/AssessmentType";
import { UserAssessmentAssignment } from "../../../api/main/models/UserAssessmentAssignment";
import { useActiveDashboardRiskCategoryFilter } from "../../../globalState/activeDashboardRiskCategoryFilter/useActiveDashboardRiskCategoryFilter";
import { useCurrentUserOrEmulatedSubscriptionId } from "../../../globalState/subscriptions/useCurrentUserOrEmulatedSubscriptionId";
import { RiskRules } from "../../../services/OverallRiskService";
import { useAverageScoreChartData } from "../chartData/useAverageScoreChartData";
import { useCompletionChartData } from "../chartData/useCompletionChartData";
import { AverageScoreRadarChart } from "../charts/AverageScoreRadarChart";
import { CompletionBarChart } from "../charts/CompletionBarChart";
import { DashboardListGroupItem } from "../listGroups/DashboardListGroupItem";
import { OverallRiskFilterListGroupItem } from "../listGroups/OverallRiskFilterListGroupItem";
import { DashboardSummary } from "../utilities/generateDashboardSummary";
import { useCachedDashboardSummaries } from "../utilities/useCachedDashboardSummaries";
import { useDashboardBaseRoute } from "../utilities/useDashboardBaseRoute";

export interface AssessmentsDashboardTabBaseProps {
    assessmentType?: AssessmentType | undefined,

    assessments: Array<Assessment>,
    generateDashboardSummary: (
        sessionFilter: (session: AssessmentSession) => boolean,
        assignmentFilter: (assignment: UserAssessmentAssignment) => boolean,
        assessmentType: AssessmentType,
        riskRule: RiskRules | undefined | null
    ) => DashboardSummary,

    assessmentSessions: Array<AssessmentSession>,
    activeAssessmentType: AssessmentType,

    linkToFolder?: string,

    riskRules: RiskRules | undefined | null,

    subscriptionId?: string,
}

/**
 * Base tab for assessment based tabs on the dashboard (e.g. assessments and training).
 * @param props
 */
export const AssessmentsDashboardTabBase = (props: AssessmentsDashboardTabBaseProps) => {
    const {
        assessments: allAssessments,
        assessmentType,

        generateDashboardSummary,
        activeAssessmentType: _activeAssessmentType,

        linkToFolder = 'assessment',

        riskRules,

        subscriptionId,
    } = props;

    // If we are forced into a single assesment type, set it as the active assessment type automatically.
    const activeAssessmentType = assessmentType ?? _activeAssessmentType;

    const mySubscriptionId = useCurrentUserOrEmulatedSubscriptionId();

    const { t } = useTranslation();
    const assessments = useMemo(() => {
        if (isNullOrUndefined(assessmentType)) {
            return allAssessments;
        }

        return allAssessments.filter(item => item.assessmentType === assessmentType);
    }, [allAssessments, assessmentType]);

    // Method we use to filter sessions by user wherever we need it.
    const filterByAssessment = useCallback((assessment: { id: string }, item: { assessmentId: string | undefined | null }) => {
        return item.assessmentId === assessment.id;
    }, []);

    // Cache the dashboard summaries so each chart/display doesn't need to recaclulate them and they can be looked up easily by id.
    const getCachedDashboardSummary = useCachedDashboardSummaries(
        assessments,
        (group, assessmentType) => generateDashboardSummary(
            session => filterByAssessment(group, session),
            assignment => filterByAssessment(group, assignment),
            assessmentType,
            assessments.find(item => item.id === group.id)
        )
    );

    // Get data for the various charts.
    const averageScoreChartData = useAverageScoreChartData(assessments, getCachedDashboardSummary, activeAssessmentType);
    const completionChartData = useCompletionChartData(assessments, getCachedDashboardSummary, activeAssessmentType);

    // Filter the visible results by overall risk category if required.
    const [riskCategoryFilter, setRiskCategoryFilter] = useActiveDashboardRiskCategoryFilter();
    const filterdAssessments = useMemo(() => {
        // Only AssessmentType.Assessment can be filtered by risk.
        if (activeAssessmentType !== AssessmentType.Assessment) {
            return assessments;
        }

        if (!riskCategoryFilter) {
            return assessments;
        }

        return assessments.filter(user => {
            const summary = getCachedDashboardSummary(user.id, activeAssessmentType);
            return (summary.averageScore.riskCategory === riskCategoryFilter); // For assessments we use averaged score rather than averateUserDriverMetricScore to match the presented progress bars.
        })
    }, [assessments, riskCategoryFilter, getCachedDashboardSummary, activeAssessmentType]);

    const baseRoute = useDashboardBaseRoute();

    return (
        <Row>
            <Col xs={12} lg="">
                <ListGroup className="mb-4">
                    <ConditionalFragment showIf={activeAssessmentType === AssessmentType.Assessment}>
                        <OverallRiskFilterListGroupItem riskCategoryFilter={riskCategoryFilter} setRiskCategoryFilter={setRiskCategoryFilter} />
                    </ConditionalFragment>
                    {
                        filterdAssessments.map(assessment => (
                            <DashboardListGroupItem
                                key={assessment.id}
                                getDashboardSummary={assessmentType => getCachedDashboardSummary(assessment.id, assessmentType)}
                                hideAssessments={assessmentType === AssessmentType.TrainingModule}
                                hideTraining={assessmentType === AssessmentType.Assessment}
                                progressType={assessmentType === AssessmentType.Assessment ? 'assessments' : 'training'}
                                seatExportHref={`/api/export/SeatsBreakdown?subscriptionId=${encodeURIComponent(mySubscriptionId ?? subscriptionId ?? "")}&assessmentId=${encodeURIComponent(assessment.id)}` }
                            >
                                <ListGroupItemHeading>
                                    <Link to={`${baseRoute}/${linkToFolder}/${assessment.id}`}>
                                        {assessment.name}
                                    </Link>
                                </ListGroupItemHeading>
                            </DashboardListGroupItem>
                        ))
                    }
                </ListGroup>
            </Col>
            <Col>
                <AverageScoreRadarChart data={averageScoreChartData} assessmentType={activeAssessmentType} riskRules={riskRules} />
                <CompletionBarChart data={completionChartData} assessmentType={activeAssessmentType} />
            </Col>
        </Row>
    );
};