import i18next from "i18next";
import { Assessment } from "../../../api/main/models/Assessment";
import { AssessmentSession } from "../../../api/main/models/AssessmentSession";
import { AssessmentType } from "../../../api/main/models/codeOnly/AssessmentType";
import { Profile } from "../../../api/main/models/Profile";
import { Subscription } from "../../../api/main/models/Subscription";
import { SubscriptionAssessment } from "../../../api/main/models/SubscriptionAssessment";
import { UserAssessmentAssignment } from "../../../api/main/models/UserAssessmentAssignment";
import { UserDriverMetric } from "../../../api/main/models/UserDriverMetric";
import { RiskRules } from "../../../services/OverallRiskService";
import { QuestionScore } from "../../assessmentSessions/launch/utilities/calculateQuestionScore";
import { questionScoreFromNumber } from "../../assessmentSessions/launch/utilities/questionScoreFromNumber";
import { calculateAverageScore } from "./calculateAverageScore";
import { getLatestSessions } from "./getLatestSessions";

/**
 * Create a summary for the dashboard from the passed in sessions.
 */
export function generateDashboardSummary(
    sessions: Array<AssessmentSession>,
    assessments: Array<Assessment>,
    profiles: Array<Profile>,
    expandedUserAssessmentAssignments: Array<UserAssessmentAssignment>,
    userDriverMetrics: Array<UserDriverMetric>,
    riskRules: RiskRules | undefined | null
): DashboardSummary {
    const {
        latestSessions,
        latestSessionsIncludingIncomplete,
    } = getLatestSessions(sessions);

    // Get a list of assessments abd profies actually used in the sessions. Changed to assessments to not include any marked suspicious.
    const usedAssessments = assessments.filter(item => !!latestSessions.find(it => it.assessmentId === item.id && !it.markedSuspicious));
    const usedProfiles = profiles.filter(item => !!latestSessions.find(it => it.userId === item.userId));

    // Get a list of assessments that are suspicious
    const suspiciousAssessments = assessments.filter(item => !!latestSessions.find(it => it.assessmentId === item.id && it.markedSuspicious));

    // Calculate the average scores using the users latest sessions only.
    const averageScore = calculateAverageScore(latestSessions, riskRules);

    // Find all the unqiue assignments for the user and count them up.
    let uniqueAssignedAssessmentUserCombinations: Array<{ userId: string, assessmentId: string | null, }> = [];
    for (const assignment of expandedUserAssessmentAssignments) {

        const existing = uniqueAssignedAssessmentUserCombinations.find(it => it.userId === assignment.userId && it.assessmentId === assignment.assessmentId);
        if (existing) {
            continue;
        }

        uniqueAssignedAssessmentUserCombinations.push({ userId: assignment.userId, assessmentId: assignment.assessmentId, });
    }

    // Work out the overall summary of not started/starts/complete.
    const notStarted = uniqueAssignedAssessmentUserCombinations.filter(assignment => !latestSessionsIncludingIncomplete.find(it => it.assessmentId === assignment.assessmentId && it.userId === assignment.userId) && !!profiles.find(profile => profile.userId === assignment.userId)).length;
    const started = latestSessionsIncludingIncomplete.filter(it => !it.isFinished && !!profiles.find(profile => profile.userId === it.userId)).length;
    const complete = latestSessionsIncludingIncomplete.filter(it => it.isFinished && !!profiles.find(profile => profile.userId === it.userId)).length;

    // Use the user metrics to work out the user driver metrics score.
    let profileTotalUserMetricScores: Array<{ userId: string, score: number, }> = [];
    for (const profile of profiles) {
        const myDriverMetrics = userDriverMetrics.filter(it => it.userId === profile.userId);
        if (myDriverMetrics.length === 0) {
            continue;
        }

        let myUserDriverMetricTotalScore = 0;
        for (const metric of myDriverMetrics) {
            myUserDriverMetricTotalScore += metric.currentValue;
        }

        if (myDriverMetrics.length > 0) {
            myUserDriverMetricTotalScore = Math.round(myUserDriverMetricTotalScore / myDriverMetrics.length);
        }

        profileTotalUserMetricScores.push({ userId: profile.userId, score: myUserDriverMetricTotalScore, })
    }

    // Calculate the overall average score across all the used profiles.
    let totalUserDriverMetricScore = 0;
    for (const profileMetricScore of profileTotalUserMetricScores) {
        totalUserDriverMetricScore += profileMetricScore.score;
    }

    if (profileTotalUserMetricScores.length > 0) {
        totalUserDriverMetricScore = Math.round(totalUserDriverMetricScore / profileTotalUserMetricScores.length);
    }
    
    const averageUserDriverMetricScore = questionScoreFromNumber(totalUserDriverMetricScore, profileTotalUserMetricScores.length === 0, riskRules);

    // Return it all in a userful format.
    return {
        averageScore: averageScore,
        averageUserDriverMetricScore: averageUserDriverMetricScore,

        uniqueSessionsCount: latestSessions.length,
        totalFinishedSessionsCount: sessions.filter(item => item.isFinished).length,
        duplicateSessionsCount: sessions.length - latestSessions.length,

        assessmentsText: getNameSummary(usedAssessments.map(item => item.name), 4),
        suspiciousAssessmentText: getNameSummary(suspiciousAssessments.map(item => item.name), 4),
        usersText: getNameSummary(usedProfiles.map(item => i18next.t('common.fullName', '{{firstName}} {{lastName}}', { firstName: item.firstName, lastName: item.lastName })), 4),

        notStarted: notStarted,
        started: started,
        complete: complete,
    };
}

export function generateDistributorDashboardSummary(
    subscriptions: Array<Subscription>,
    subscriptionId: string,
    userAssessmentAssignment: Array<UserAssessmentAssignment>,
    subscriptionAssessmentAssignment: Array<SubscriptionAssessment>,
    assessments: Array<Assessment>,
): DistributorDashboardSummary {

    const currentDistributorSubscriptions = subscriptions.filter(item => item.distributorSubscriptionId === subscriptionId);
    const distributorUserAssignments = userAssessmentAssignment.filter(item => currentDistributorSubscriptions.find(it => it.id === item.subscriptionId));
    const distributorAssignedAssessments = subscriptionAssessmentAssignment.filter(item => item.subscriptionId === subscriptionId);

    //attempt at counting individual assessments
    const assessmentAssignmentCounts: Array<{ AssessmentId: string, count: number }> = []
    distributorUserAssignments.forEach(item => {
        assessmentAssignmentCounts.push({ AssessmentId: item.assessmentId!,count: distributorUserAssignments.filter(it => it.assessmentId === item.assessmentId).length })
    })

    return {
        totalSubscriptionsCount: currentDistributorSubscriptions.length,
        distributorUserAssessmentAssignmentCount: distributorUserAssignments.filter(item => assessments.find(it => it.id === item.assessmentId)?.assessmentType === AssessmentType.Assessment).length,
        distributorUserTrainingAssignmentCount: distributorUserAssignments.filter(item => assessments.find(it => it.id === item.assessmentId)?.assessmentType === AssessmentType.TrainingModule).length,
        filteredAssessments: assessments.filter(item => distributorAssignedAssessments.find(it => it.assessmentId === item.id)),
        assessmentAssignments: assessmentAssignmentCounts,
        DistributorSubscriptionsText: getNameSummary(currentDistributorSubscriptions.map(item => item.companyName), 4),
    }

}

/**
 * Helpful function that lets us turn a list of names into a limited size list with "and X others".
 * @param names
 * @param maxNamesToShow
 */
function getNameSummary(names: Array<string>, maxNamesToShow: number) {
    let growingNames = '';
    for (let i = 0; i < maxNamesToShow && i < names.length; ++i) {
        const name = names[i];

        if (!growingNames) {
            growingNames = name;
        } else {
            growingNames += `, ${name ?? ''}`;
        }
    }

    let ret: string = growingNames;
    if (names.length > maxNamesToShow) {
        ret = i18next.t('createListOfNames.andSomeMore', '{{names}} and {{count}} others.', { names, count: names.length - maxNamesToShow });
    }
    
    return ret;
}

export interface DashboardSummary {
    averageScore: QuestionScore,
    averageUserDriverMetricScore: QuestionScore,

    uniqueSessionsCount: number,
    totalFinishedSessionsCount: number,
    duplicateSessionsCount: number,

    assessmentsText: string,
    suspiciousAssessmentText: string,
    usersText: string,

    notStarted: number,
    started: number,
    complete: number,
}

export interface DistributorDashboardSummary {
    totalSubscriptionsCount: number,
    distributorUserAssessmentAssignmentCount: number,
    distributorUserTrainingAssignmentCount: number,
    filteredAssessments: Array<Assessment>,
    assessmentAssignments: Array<{ AssessmentId: string, count: number }>,
    DistributorSubscriptionsText: string,

}
