/**
 * Service that lets us manage our special PINs used for logging in by some users.
 * 
 * There is a C# version of some of this classes functioality available in DriverPinService.cs.
 */
export class DriverPinService
{
    private pinEmailSuffix: string = '@pins.esitudrive.com';

    /**
     * Returns the email address to set in the user from the provided PIN.
     * @param pin
     */
    emailFromPin(pin: string): string {
        if (pin.endsWith(this.pinEmailSuffix)) {
            return pin;
        }

        return `${pin}${this.pinEmailSuffix}`;
    }

    /**
     * Returns true if email is a driver pin email.
     * @param email
     */
    isPinEmail(email: string): boolean {
        return email.endsWith(this.pinEmailSuffix);
    }
}