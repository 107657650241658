import { MouseEventHandler, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { NavItem, NavLink } from "reactstrap";
import { NavLinkTrackActive } from "../../../shared/NavLinkTrackActive";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface SubscriptionAdministrationNavigationProps {
    id: string,
    onNavigate?: (link: string) => void,
    editLink?: string,
}

/**
 * Navigation under editing of a Distributor in the administration area.
 */
export const DistributorSubscriptionEditNavigation = (props: SubscriptionAdministrationNavigationProps) => {
    const { id, onNavigate, editLink } = props;
    const { t } = useTranslation();

    // If we have an onNavigate event, we should call that instead of leting the links handle themselves.
    const onNavLinkClick = useCallback<MouseEventHandler>((e) => {
        if (onNavigate) {
            e.preventDefault();
            const link = e.currentTarget.getAttribute("href") ?? '';
            onNavigate(link);
        }
    }, [onNavigate]);

    return (
        <>
            <NavItem>
                <NavLink tag={NavLinkTrackActive} to={editLink ?? `/manage/distributor/subscriptions/edit/${id}`} onClick={onNavLinkClick}>
                    <FontAwesomeIcon icon="building" className="nav-icon" />
                    {t('distributorSubscriptionEditNavigation.subscription', ' Subscription')}
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink tag={NavLinkTrackActive} to={`/manage/distributor/subscriptions/details/${id}/assessments`} onClick={onNavLinkClick}>
                    <FontAwesomeIcon icon="clipboard-check" className="nav-icon" />
                    {t('distributorSubscriptionEditNavigation.assessments', ' Assessments')}
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink tag={NavLinkTrackActive} to={`/manage/distributor/subscriptions/details/${id}/training`} onClick={onNavLinkClick}>
                    <FontAwesomeIcon icon="graduation-cap" className="nav-icon" />
                    {t('distributorSubscriptionEditNavigation.training', ' Training Modules')}
                </NavLink>
            </NavItem>
        </>
    );
};