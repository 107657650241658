import * as React from 'react';
import { DriverMetricRecommendationCreateInput, DriverMetricRecommendationUpdateInput } from '../generated/globalTypes';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useCreateDriverMetricRecommendationCallback } from './useCreateDriverMetricRecommendationCallback';
import { useUpdateDriverMetricRecommendationCallback } from './useUpdateDriverMetricRecommendationCallback';
import { DriverMetricRecommendation } from '../models/DriverMetricRecommendation';

/**
 * Get a callback to save a DriverMetricRecommendation in the store using either a create or update.
 */
export function useSaveDriverMetricRecommendationCallback(options: SaveInStoreOptions<DriverMetricRecommendation, string> = {}) {
    const [_create, createStatus] = useCreateDriverMetricRecommendationCallback();
    const create = React.useCallback((model: Partial<DriverMetricRecommendation>) => _create(model as DriverMetricRecommendationCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateDriverMetricRecommendationCallback();
    const update = React.useCallback((id: string, changes: Partial<DriverMetricRecommendation>) => _update(id, changes as DriverMetricRecommendationUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
