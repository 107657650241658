import { RouteEntry } from "../../shared/routes";
import { StudyCompletes } from "./StudyComplete";
import { StudyLogin } from "./StudyLogin";
import { StudyThanks } from "./StudyThanks";

export const studyAccountRoutes: Array<RouteEntry> = [
    { path: '/study/:studyUniqueCode', component: StudyLogin },
    { path: '/study', component: StudyLogin },

    { path: '/study-complete/:assessmentSessionId', component: StudyCompletes },
    { path: '/study-thanks', component: StudyThanks },
];
