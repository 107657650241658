import { RouteEntry } from "../../../shared/routes";
import { LearningList } from "./LearningList";
import { EditLearning, CreateLearning } from "./EditLearning";
import { IdentityRoles } from "../../../configure/security/IdentityRoles";
import { DeleteLearning } from "./DeleteLearning";

export const learningRoutes: Array<RouteEntry> = [
    { path: '/administration/learning-activities', exact: true, component: LearningList, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/learning-activities/edit/:id', component: EditLearning, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/learning-activities/add', component: CreateLearning, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/learning-activities/delete/:id', component: DeleteLearning, authorize: true, requireRole: IdentityRoles.Administration },
];
