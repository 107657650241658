import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { Button, ButtonGroup, Col, ListGroupItem, Row, } from "reactstrap";
import { QuestionType, questionTypeDisplayName } from "../../../../api/main/models/codeOnly/QuestionType";
import { Question } from "../../../../api/main/models/Question";
import { QuestionSequenceQuestion } from "../../../../api/main/models/QuestionSequenceQuestion";
import { FormButtons } from "../../../shared/FormButtons";
import { ConditionalFragment } from "react-conditionalfragment";
import { CreateQuestionnaireQuestionModal } from "../../questionnaires/QuestionnaireQuestion/CreateQuestionnaireQuestionModal/CreateQuestionnaireQuestionModal";
import { useCallback } from "react";
import { useToggleState } from "use-toggle-state";
import { ValidationErrors } from "pojo-validator";
import { QuestionAnswer } from "../../../../api/main/models/QuestionAnswer";
import { useAsyncCallback } from 'react-use-async-callback';
import { ModelArrayChanges } from "../../../../shared/useChanges";
import { HtmlDisplay } from "../../../../shared/htmlEditor";
import { DriverMetric } from "../../../../api/main/models/DriverMetric"

export interface QuestionnaireQuestionSequenceQuestionComponentProps {
    model: QuestionSequenceQuestion,
    driverMetrics: Array<DriverMetric>,

    parent?: Question,
    moveUp: () => void,
    canMoveUp: boolean,
    moveDown: () => void,
    canMoveDown: boolean,


    childQuestion: Question,

    validateQuestionAnswer:(model: QuestionAnswer) => boolean,
    questionAnswerValidationErrors: ((id: string) => ValidationErrors)

    questionnaireQuestionAnswersManager: ModelArrayChanges<QuestionAnswer, string>,
    questionnaireQuestionsManager: ModelArrayChanges<Question, string>,
    questionSequenceQuestionsManager: ModelArrayChanges<QuestionSequenceQuestion, string>,

    refresh: () => void,
}

/**
 * A question that forms part of the QuestionSequenceQuestion.
 * @param props
 */
export const QuestionnaireQuestionSequenceQuestionComponent = (props: QuestionnaireQuestionSequenceQuestionComponentProps) => {
    const {
        model,
        driverMetrics,
        parent,
        moveUp,
        canMoveUp,
        moveDown,
        canMoveDown,

        childQuestion,

        validateQuestionAnswer,
        questionAnswerValidationErrors,

        questionnaireQuestionAnswersManager,
        questionnaireQuestionsManager,
        questionSequenceQuestionsManager,

        refresh,
    } = props;

    const { t } = useTranslation();

    const divClickedHandler = (event: React.MouseEvent<HTMLDivElement>) => {

        toggleQuestionModal();

    }

    // Showing of the question modal.
    const [questionModalIsOpen, _toggleQuestionModal] = useToggleState();
    const toggleQuestionModal = useCallback(() => {
        _toggleQuestionModal();
    }, [_toggleQuestionModal]);



    //deletes a question and all related objects
    const [deleteQuestion] = useAsyncCallback(async () => {

        //take what question needs to be deleted from model before model is deleted
        const questionToRemove = childQuestion.id;

        //deleting the relating objects first to prevent errors (if question is deleted first these items wont relate to anything)
        //delete the sequence question
        await questionSequenceQuestionsManager.removeFor(model.id);

        //delete all relating answers
        questionnaireQuestionAnswersManager?.model.forEach(async (item) => {

            if (item.questionId === questionToRemove) {

                await questionnaireQuestionAnswersManager.removeFor(item.id);

            }
        });

        //delete the question
        await questionnaireQuestionsManager.removeFor(questionToRemove);

    }, [questionSequenceQuestionsManager, questionnaireQuestionsManager, questionnaireQuestionAnswersManager, model]);


    return (

        <>

            <ListGroupItem tag="div">
                <Row>
                    <Col>
                        <div>

                                <div onClick={divClickedHandler}>

                                <HtmlDisplay html={childQuestion?.questionText} />

                                </div>


                        </div>
                        <div className="text-muted">
                            {questionTypeDisplayName(childQuestion?.questionType as QuestionType, t)}
                        </div>
                    </Col>
                    <Col xs={12} sm="">
                        <FormButtons noPadding>
                            <ButtonGroup>
                                <Button color="primary" outline onClick={() => moveUp()} disabled={!canMoveUp}>
                                    <FontAwesomeIcon icon="caret-up" />
                                    <> </>
                                    {t('questionSequenceQuestionComponent.up', 'Up')}
                                </Button>
                                <Button color="primary" outline onClick={() => moveDown()} disabled={!canMoveDown}>
                                    <FontAwesomeIcon icon="caret-down" />
                                    <> </>
                                    {t('questionSequenceQuestionComponent.down', 'Down')}
                                </Button>
                                <Button color="danger" outline onClick={() => deleteQuestion()}>
                                    <FontAwesomeIcon icon="trash-alt" />
                                    <> </>
                                    {t('questionSequenceQuestionComponent.delete', 'Remove question')}
                                </Button>
                            </ButtonGroup>
                        </FormButtons>
                    </Col>
                </Row>
            </ListGroupItem>



            <ConditionalFragment showIf={questionModalIsOpen}>
                <CreateQuestionnaireQuestionModal
                    questionnaire={childQuestion}
                    driverMetrics={driverMetrics}
                    isOpen={questionModalIsOpen}
                    toggle={toggleQuestionModal}
                    parentId={parent!.id}
                    isCreate={false}
                    validateQuestionAnswer={validateQuestionAnswer!}
                    questionAnswerValidationErrors={questionAnswerValidationErrors}
                    refresh={refresh}
                    questionnaireQuestionAnswersManager={questionnaireQuestionAnswersManager}
                    questionSequenceQuestionsManager={questionSequenceQuestionsManager}
                    questionnaireQuestionsManager={questionnaireQuestionsManager}
                />
            </ConditionalFragment>
        </>


    );


};