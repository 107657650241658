import { AssessmentType } from "../../../api/main/models/codeOnly/AssessmentType";
import { AssessmentsDashboardTabBase, AssessmentsDashboardTabBaseProps } from "./AssessmentsDashboardTabBase";

/**
 * Training tab on the dashboard.
 * @param props
 */
export const TrainingDashboardTab = (props: AssessmentsDashboardTabBaseProps) => {
    return (
        <AssessmentsDashboardTabBase
            assessmentType={AssessmentType.TrainingModule}
            linkToFolder="training"
            {...props}
        />
    );
};