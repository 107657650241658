import gql from "graphql-tag";
import { Guid } from "guid-string";
import { questionFeedbackFields } from "../generated/questionFeedbackFields";

export const questionFeedbackFieldsFragment = gql`
    fragment questionFeedbackFields on QuestionFeedback {
        id
        questionId
        minScore
        maxScore
        archived
        feedbackText
        imageBlobReferenceId
        videoBlobReferenceId
    }
`;


export type QuestionFeedback = Omit<questionFeedbackFields, '__typename'>;

// Default values.
export const questionFeedbackDefaultValues = (): Partial<QuestionFeedback> => ({
    id: Guid.newGuid(),
    questionId: undefined,
    minScore: 0,
    maxScore: 100,
    feedbackText: '',
    videoBlobReferenceId: null,
    imageBlobReferenceId: null,
});