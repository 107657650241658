import * as React from 'react';
import { atom, useRecoilState } from 'recoil';
import { AssessmentType } from '../../api/main/models/codeOnly/AssessmentType';
import { persistAtom } from '../../configure/recoilPersistConfig';

export const activeDashboardAssessmentTypeState = atom<AssessmentType>({
    key: 'activeDashboardAssessmentTypeState',
    default: AssessmentType.Assessment,
    effects_UNSTABLE: [persistAtom],
    
});

/**
 * Hook that returns the active AssessmentType to use for Dashboards.
 */
export function useActiveDashboardAssessmentType(): [AssessmentType, React.Dispatch<React.SetStateAction<AssessmentType>>] {
    const [value, setValue] = useRecoilState(activeDashboardAssessmentTypeState);
    return [value, setValue];
}