import gql from "graphql-tag";
import { Guid } from "guid-string";
import { assessmentFeedbackFields } from "../generated/assessmentFeedbackFields";

export const assessmentFeedbackFieldsFragment = gql`
    fragment assessmentFeedbackFields on AssessmentFeedback {
        id
        assessmentId
        minTotalScore
        maxTotalScore
        archived
        feedbackText
        imageBlobReferenceId
        videoBlobReferenceId
    }
`;


export type AssessmentFeedback = Omit<assessmentFeedbackFields, '__typename'>;

// Default values.
export const assessmentFeedbackDefaultValues = (): Partial<AssessmentFeedback> => ({
    id: Guid.newGuid(),
    assessmentId: undefined,
    minTotalScore: 0,
    maxTotalScore: 100,
    feedbackText: '',
    videoBlobReferenceId: null,
    imageBlobReferenceId: null,
});