import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { DriverMetricRecommendationCreateInput } from '../generated/globalTypes';
import { createDriverMetricRecommendationMutation, createDriverMetricRecommendationMutationVariables } from '../generated/createDriverMetricRecommendationMutation';
import { driverMetricRecommendationFieldsFragment } from '../models/DriverMetricRecommendation';

/**
 * Get a callback to create a DriverMetricRecommendation in the store.
 */
export function useCreateDriverMetricRecommendationCallback(): [(model: DriverMetricRecommendationCreateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<createDriverMetricRecommendationMutation, createDriverMetricRecommendationMutationVariables>(
        gql`
            mutation createDriverMetricRecommendationMutation ($model: DriverMetricRecommendationCreateInput!) {
                createDriverMetricRecommendation(model: $model) {
                    ...driverMetricRecommendationFields
                }
            }

            ${driverMetricRecommendationFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (model: DriverMetricRecommendationCreateInput) => {
        const result = await mutationAction({ variables: { model: model } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
