import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import {  ButtonGroup, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Input, Label, Spinner } from "reactstrap";
import { useToggleState } from "use-toggle-state";
import { BlobReference } from "../../../../api/main/models/BlobReference";
import { QuestionType } from "../../../../api/main/models/codeOnly/QuestionType";
import { Question } from "../../../../api/main/models/Question";
import { FileUploadButton } from "../../../shared/fileUploadButton/FileUploadButton";
import { UploadedImagePreview } from "../../../shared/uploadedImagePreview/UploadedImagePreview";

export interface ImageTabProps {
    model: Question | undefined,
    change: (changes: Partial<Question>) => void,
    activeQuestionType: QuestionType,

    imageBlob: BlobReference | undefined | null,
    uploadImageBlob: (files: FileList | null) => void,
    isUploadingImageBlob: boolean,
    clearImageBlob: () => void,

}

/**
 * Image upload tab for EditQuestion.
 * @param props
 */
export const ImageTab = (props: ImageTabProps) => {
    const {
        model,
        change,
        activeQuestionType,
        imageBlob, uploadImageBlob, isUploadingImageBlob, clearImageBlob,
    } = props;

    const { t } = useTranslation();

    const [isImageDropdownOpen, toggleImageDropdown] = useToggleState();

    return (
        <>
            <FormGroup>
                <Label htmlFor="imageBlobReferenceId">{t('imageTab.imageBlobReferenceId', 'Image')}</Label>

                <UploadedImagePreview src={imageBlob?.url ?? ''} />

                <ButtonGroup>
                    <FileUploadButton onUpload={files => uploadImageBlob(files)} isExecuting={isUploadingImageBlob}
                        executingChildren={<><Spinner size="sm" /><> </>{t('common.uploading', 'Uploading...')}</>}
                    >
                        {t('editCalendarEvent.uploadImageImage', 'Upload image...')}
                    </FileUploadButton>

                    <Dropdown isOpen={isImageDropdownOpen} toggle={() => toggleImageDropdown()}>
                        <DropdownToggle color="primary" outline caret>
                            <span className="sr-only">
                                {t('common.more', 'More')}
                            </span>
                        </DropdownToggle>
                        <DropdownMenu right>
                            <DropdownItem className="text-danger" onClick={clearImageBlob}>
                                {t('editCalendarHeadline.clearImage', 'Clear image')}
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </ButtonGroup>
            </FormGroup>

            <ConditionalFragment showIf={activeQuestionType === QuestionType.LocationResponseImage || activeQuestionType === QuestionType.LocationDiscriminationImage}>
                <FormGroup>
                    <Label htmlFor="score">{t('imageTab.timeLimitSeconds.label', 'Time limit in seconds to show the scene for')}</Label>

                    <Input type="number" min={0} value={model?.timeLimitSeconds ?? 0} onChange={e => change({ timeLimitSeconds: e.currentTarget.valueAsNumber })} />
                </FormGroup>
            </ConditionalFragment>
        </>
        );
};