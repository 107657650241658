import { TFunction } from "i18next";

/**
 * Feedback styles
 */
export enum FeedbackStyle {
    NoScore = "NoScore",
    NoFeedback =  "NoFeedback",
    SummaryFeedback = "SummaryFeedback",
    EachQuestionFeedback = "EachQuestionFeedback",
}

/**
 * Return translated display name for a feedback style.
 * @param t
 */
export function feedbackStyleDisplayName(type: FeedbackStyle, t: TFunction): string {
    switch (type) {
        case FeedbackStyle.NoScore: return t('feedbackStyleDisplayName.NoScore', 'On the Finish page the user will be shown the overall feedback text but given no score.');
        case FeedbackStyle.NoFeedback: return t('feedbackStyleDisplayName.NoFeedback', 'On the Finish page the user will be shown the overall feedback text and their total score, but cannot break that score down by question.');
        case FeedbackStyle.SummaryFeedback: return t('feedbackStyleDisplayName.SummaryFeedback', 'On the Finish page the user will be shown the overall feedback text, their total score, and have the ability to view their score for each question.');
        case FeedbackStyle.EachQuestionFeedback: return t('feedbackStyleDisplayName.EachQuestionFeedback', 'Feedback will be given to the user after each question.  On the Finish page the user will be shown the overall feedback text, their total score, and have the ability to review their score for each question again.');
    }
}
