import { ValidationErrors } from "pojo-validator";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Button, FormGroup, Label } from "reactstrap";
import { DriverMetric } from "../../../../api/main/models/DriverMetric";
import { DriverMetricAdjustment } from "../../../../api/main/models/DriverMetricAdjustment";
import { DriverMetricRecommendation, driverMetricRecommendationDefaultValues } from "../../../../api/main/models/DriverMetricRecommendation";
import { Question } from "../../../../api/main/models/Question";
import { ModelArrayChanges } from "../../../../shared/useChanges";
import { DriverMetricRecommendationComponent } from "./DriverMetricRecommendationComponent";

export interface DriverMetricsTabProps {
    model: Question | undefined,

    driverMetricRecommendationsManager: ModelArrayChanges<DriverMetricRecommendation, string>,
    driverMetricAdjustmentsManager: ModelArrayChanges<DriverMetricAdjustment, string>,

    driverMetrics: Array<DriverMetric>,

    validateDriverMetricRecommendation: (model: DriverMetricRecommendation) => boolean,
    driverMetricRecommendationValidationErrors: (id: string) => ValidationErrors,

    validateDriverMetricAdjustment: (model: DriverMetricAdjustment) => boolean,
    driverMetricAdjustmentValidationErrors: (id: string) => ValidationErrors,
}

/**
 * Tab for maintaining driver metrics for a question.
 * @param props
 */
export const DriverMetricsTab = (props: DriverMetricsTabProps) => {
    const {
        model,

        driverMetricRecommendationsManager,
        //driverMetricAdjustmentsManager,
        driverMetrics,

        validateDriverMetricRecommendation,
        driverMetricRecommendationValidationErrors,

        //validateDriverMetricAdjustment,
        //driverMetricAdjustmentValidationErrors,
    } = props;

    const { t } = useTranslation();

    // Order the recommendations so they are shown based on their minimum score.
    const orderedRecommendations = useMemo(() => {
        let ret = [...driverMetricRecommendationsManager.model];

        // Sort by min score, then max score.
        ret.sort((a, b) => (a.minScore === b.minScore ? (a.maxScore === b.maxScore ? 0 : a.maxScore < b.maxScore ? -1 : 1) : a.minScore < b.minScore ? -1 : 1));

        return ret;
    }, [driverMetricRecommendationsManager.model]);

    // Adding of a recommendation.
    const addRecommendation = useCallback(() => {
        driverMetricRecommendationsManager.addFor({
            ...driverMetricRecommendationDefaultValues(),

            targetId: model?.id,
            targetType: 'Question',
        });
    }, [driverMetricRecommendationsManager, model?.id]);

    //// Order the adjustments so they are shown based on their minimum score.
    //const orderedAdjustments = useMemo(() => {
    //    let ret = [...driverMetricAdjustmentsManager.model];

    //    // Sort by min score, then max score.
    //    ret.sort((a, b) => (a.minScore === b.minScore ? (a.maxScore === b.maxScore? 0: a.maxScore < b.maxScore? -1: 1) : a.minScore < b.minScore ? -1 : 1));

    //    return ret;
    //}, [driverMetricAdjustmentsManager.model]);

    //// Adding of an adjustment.
    //const addAdjustment = useCallback(() => {
    //    driverMetricAdjustmentsManager.addFor({
    //        ...driverMetricAdjustmentDefaultValues(),

    //        targetId: model?.id,
    //        targetType: 'Question',
    //    });
    //}, [driverMetricAdjustmentsManager, model?.id]);

    
    return (
        <>
            <FormGroup>
                <Label htmlFor="driverMetricRecommendations">{t('driverMetricsTab.driverMetricRecommendations.question', 'This question is recommended based on these rules:')}</Label>

                <div>
                    {
                        orderedRecommendations.map(item => (
                            <DriverMetricRecommendationComponent key={item.id}
                                model={item}
                                change={changes => driverMetricRecommendationsManager.changeFor(item.id, changes)}
                                remove={() => driverMetricRecommendationsManager.removeFor(item.id)}
                                driverMetrics={driverMetrics}
                                validate={() => validateDriverMetricRecommendation(item)}
                                validationErrors={driverMetricRecommendationValidationErrors(item.id)}
                                />
                            ))
                    }
                </div>

                <Button color="primary" outline onClick={() => addRecommendation()}>
                    {t('driverMetricsTab.addRecommendation', 'Add recommendation rule')}
                </Button>
            </FormGroup>

            {/* Driver metric adjustments have been removed form the UI in favour of using much simplier logic built around the primary driver metrics instead. */}
            {/*<FormGroup>
                <Label htmlFor="driverMetricAdjustments">{t('driverMetricsTab.driverMetricAdjustments.question', 'This question will change a driver\'s personal safety metrics based on these rules:')}</Label>

                <div>
                    {
                        orderedAdjustments.map(item => (
                            <DriverMetricAdjustmentComponent key={item.id}
                                model={item}
                                change={changes => driverMetricAdjustmentsManager.changeFor(item.id, changes)}
                                remove={() => driverMetricAdjustmentsManager.removeFor(item.id)}
                                driverMetrics={driverMetrics}
                                validate={() => validateDriverMetricAdjustment(item)}
                                validationErrors={driverMetricAdjustmentValidationErrors(item.id)}
                            />
                        ))
                    }
                </div>

                <Button color="primary" outline onClick={() => addAdjustment()}>
                    {t('driverMetricsTab.addAdjustment', 'Add adjustment rule')}
                </Button>
            </FormGroup>*/}
        </>
        );
};