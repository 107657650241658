import { useMemo } from "react";
import { Profile } from "../../../api/main/models/Profile";
import { UserAssessmentAssignment } from "../../../api/main/models/UserAssessmentAssignment";

/**
 * Expand out the UserAssessmentAssignments so that each team assignment has a record for each affected user.
 */
export function useExpandedUserAssessmentAssignments(userAssessmentAssignments: Array<UserAssessmentAssignment>, profiles: Array<Profile>) {
    const ret = useMemo(() => {
        if (!userAssessmentAssignments || !profiles) {
            return [];
        }

        const ret: Array<UserAssessmentAssignment> = [];

        // Add all the user specific assignments
        for (const assignment of userAssessmentAssignments) {
            // If this is for a user, add it to the return result directly.
            if (!assignment.userId) {
                continue;
            }

            ret.push(assignment);
        }

        // Expand the team assignments out for each member of the team (unless that member already has their own assignment).
        for (const assignment of userAssessmentAssignments) {
            // If this is for a user, add it to the return result directly.
            if (assignment.userId) {
                continue;
            }

            // This is for a team, so add an entry for each user in the team, taking care to skip users who already have their own
            // entry.
            const profilesInTeam = profiles.filter(item => item.subscriptionTeamId === assignment.subscriptionTeamId);
            for (const profile of profilesInTeam) {
                const usersOwnRecord = ret.find(it => it.assessmentId === assignment.assessmentId && it.userId === profile.userId);
                if (usersOwnRecord) {
                    continue;
                }

                ret.push({
                    ...assignment,
                    userId: profile.userId,
                });
            }
        }

        return ret;
    }, [userAssessmentAssignments, profiles]);

    return ret;
}