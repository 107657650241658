import { useCallback, useMemo } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Col, ListGroup, ListGroupItemHeading, ListGroupItemText, Row } from "reactstrap";
import { Assessment } from "../../../api/main/models/Assessment";
import { AssessmentSession } from "../../../api/main/models/AssessmentSession";
import { AssessmentType } from "../../../api/main/models/codeOnly/AssessmentType";
import { Profile } from "../../../api/main/models/Profile";
import { Subscription } from "../../../api/main/models/Subscription";
import { SubscriptionTeam } from "../../../api/main/models/SubscriptionTeam";
import { UserAssessmentAssignment } from "../../../api/main/models/UserAssessmentAssignment";
import { useActiveDashboardRiskCategoryFilter } from "../../../globalState/activeDashboardRiskCategoryFilter/useActiveDashboardRiskCategoryFilter";
import { RiskRules } from "../../../services/OverallRiskService";
import { useAverageDriverMetricRiskChartData } from "../chartData/useAverageDriverMetricRiskChartData";
import { useAverageScoreChartData } from "../chartData/useAverageScoreChartData";
import { useCompletionChartData } from "../chartData/useCompletionChartData";
import { AverageDriverMetricRiskDonutChart } from "../charts/AverageDriverMetricRiskDonutChart";
import { AverageScoreRadarChart } from "../charts/AverageScoreRadarChart";
import { CompletionBarChart } from "../charts/CompletionBarChart";
import { DashboardListGroupItem } from "../listGroups/DashboardListGroupItem";
import { OverallRiskFilterListGroupItem } from "../listGroups/OverallRiskFilterListGroupItem";
import { DashboardSummary } from "../utilities/generateDashboardSummary";
import { useCachedDashboardSummaries } from "../utilities/useCachedDashboardSummaries";
import { useDashboardBaseRoute } from "../utilities/useDashboardBaseRoute";

export interface UsersDashboardTabProps {
    profiles: Array<Profile>,
    generateDashboardSummary: (
        sessionFilter: (session: AssessmentSession) => boolean,
        assignmentFilter: (assignment: UserAssessmentAssignment) => boolean,
        assessmentType: AssessmentType,
    ) => DashboardSummary,

    assessmentSessions: Array<AssessmentSession>,
    assessments: Array<Assessment>,
    activeAssessmentType: AssessmentType,

    showSubscriptionName?: boolean,
    subscriptions?: Array<Subscription>,
    subscriptionTeams?: Array<SubscriptionTeam>,

    linkToFolder?: string,

    riskRules: RiskRules | undefined | null,
}

/**
 * User based tab on the dashboard.
 * @param props
 */
export const UsersDashboardTab = (props: UsersDashboardTabProps) => {
    const {
        profiles,
        generateDashboardSummary,
        activeAssessmentType,

        showSubscriptionName = false,
        subscriptions,
        subscriptionTeams,

        linkToFolder = 'user',
        riskRules,
    } = props;

    const { t } = useTranslation();

    // Method we use to filter sessions by user wherever we need it.
    const filterByUser = useCallback((user: { id: string }, item: { userId: string | undefined | null }) => {
        return item.userId === user.id;
    }, []);

    // Profiles simplified to have a name and id (for which we use the userId).
    const users = useMemo(() => profiles.map(profile => ({
        id: profile.userId,
        name: t('common.fullName', '{{firstName}} {{lastName}}', profile),
        subscriptionId: profile.subscriptionId,
        subscriptionTeamId: profile.subscriptionTeamId,
    })), [profiles, t]);

    // Cache the dashboard summaries so each chart/display doesn't need to recaclulate them and they can be looked up easily by id.
    const getCachedDashboardSummary = useCachedDashboardSummaries(
        users,
        (group, assessmentType) => generateDashboardSummary(
            session => filterByUser(group, session),
            assignment => filterByUser(group, assignment),
            assessmentType
        )
    );

    // Filter the visible results by overall risk category if required.
    const [riskCategoryFilter, setRiskCategoryFilter] = useActiveDashboardRiskCategoryFilter();
    const filterdUsers = useMemo(() => {
        if (!riskCategoryFilter) {
            return users;
        }

        return users.filter(user => {
            const summary = getCachedDashboardSummary(user.id, activeAssessmentType);
            return (summary.averageUserDriverMetricScore.riskCategory === riskCategoryFilter);
        })
    }, [users, riskCategoryFilter, getCachedDashboardSummary, activeAssessmentType]);

    // Get data for the various charts.
    const averageScoreChartData = useAverageScoreChartData(users, getCachedDashboardSummary, activeAssessmentType);
    const averageScoreRiskChartData = useAverageDriverMetricRiskChartData(users, getCachedDashboardSummary, activeAssessmentType);
    const completionChartData = useCompletionChartData(users, getCachedDashboardSummary, activeAssessmentType);
   

    const baseRoute = useDashboardBaseRoute();

    return (
        <Row>
            <Col xs={12} lg="">
                <ListGroup className="mb-4">
                    <OverallRiskFilterListGroupItem riskCategoryFilter={riskCategoryFilter} setRiskCategoryFilter={setRiskCategoryFilter} />
                    {
                        filterdUsers.map(user => {

                            // Also find the team and if available subscription for the profile.
                            const team = subscriptionTeams?.find(it => it.id === user.subscriptionTeamId);
                            const subscription = subscriptions?.find(it => it.id === user.subscriptionId);

                            return (
                                <DashboardListGroupItem
                                    key={user.id}
                                    hideSuspicious={false} // We want to show which assessments the user has completed that have been marked suspicious
                                    getDashboardSummary={assessmentType => getCachedDashboardSummary(user.id, assessmentType)}
                                    progressType="risk"
                                >
                                    <ListGroupItemHeading>
                                        <Link to={
                                            showSubscriptionName ? `${baseRoute}/subscription/${user.subscriptionId}/${linkToFolder}/${user.id}`
                                                : `${baseRoute}/${linkToFolder}/${user.id}`
                                        }>
                                            {user.name}
                                        </Link>
                                    </ListGroupItemHeading>
                                    <ListGroupItemText tag="div">
                                        <ConditionalFragment showIf={!!team}>
                                            <Link to={
                                                showSubscriptionName ? `${baseRoute}/subscription/${team?.subscriptionId}/team/${team?.id}`
                                                    : `${baseRoute}/team/${team?.id}`
                                            }>
                                                {team?.name}
                                            </Link>
                                        </ConditionalFragment>
                                        <ConditionalFragment showIf={showSubscriptionName && !!subscription}>
                                            <> </>
                                            {'('}
                                            <Link to={`${baseRoute}/subscription/${subscription?.id}`}>
                                                {subscription?.companyName}
                                            </Link>
                                            {')'}
                                        </ConditionalFragment>
                                    </ListGroupItemText>
                                </DashboardListGroupItem>
                            );
                        })
                    }
                </ListGroup>
            </Col>
            <Col>
                <ConditionalFragment showIf={activeAssessmentType === AssessmentType.Assessment}>
                    <AverageDriverMetricRiskDonutChart data={averageScoreRiskChartData} assessmentType={activeAssessmentType} />
                </ConditionalFragment>
                <AverageScoreRadarChart data={averageScoreChartData} assessmentType={activeAssessmentType} riskRules={riskRules} />
                <CompletionBarChart data={completionChartData} assessmentType={activeAssessmentType} />
            </Col>
        </Row>
    );
};