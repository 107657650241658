import * as React from 'react';
import { SubscriptionCreateInput, SubscriptionUpdateInput } from '../generated/globalTypes';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useCreateSubscriptionCallback } from './useCreateSubscriptionCallback';
import { useUpdateSubscriptionCallback } from './useUpdateSubscriptionCallback';
import { Subscription } from '../models/Subscription';

/**
 * Get a callback to save a Subscription in the store using either a create or update.
 */
export function useSaveSubscriptionCallback(options: SaveInStoreOptions<Subscription, string> = {}) {
    const [_create, createStatus] = useCreateSubscriptionCallback();
    const create = React.useCallback((model: Partial<Subscription>) => _create(model as SubscriptionCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateSubscriptionCallback();
    const update = React.useCallback((id: string, changes: Partial<Subscription>) => _update(id, changes as SubscriptionUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
