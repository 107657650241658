import gql from "graphql-tag";
import { Guid } from "guid-string";
import { driverMetricRecommendationFields } from "../generated/driverMetricRecommendationFields";

export const driverMetricRecommendationFieldsFragment = gql`
    fragment driverMetricRecommendationFields on DriverMetricRecommendation {
        id
        targetId
        targetType
        driverMetricId
        minScore
        maxScore
        archived
    }
`;


export type DriverMetricRecommendation = Omit<driverMetricRecommendationFields, '__typename'>;

// Default values.
export const driverMetricRecommendationDefaultValues = () : Partial<DriverMetricRecommendation> => ({
    id: Guid.newGuid(),
    targetId: undefined,
    targetType: '',
    minScore: 0,
    maxScore: 100,
    driverMetricId: undefined,
});