import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { QuestionTagLinkUpdateInput } from '../generated/globalTypes';
import { updateQuestionTagLinkMutation, updateQuestionTagLinkMutationVariables } from '../generated/updateQuestionTagLinkMutation';
import { questionTagLinkFieldsFragment } from '../models/QuestionTagLink';

/**
 * Get a callback to update a QuestionTagLink in the store.
 */
export function useUpdateQuestionTagLinkCallback(): [(id: string, model: QuestionTagLinkUpdateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<updateQuestionTagLinkMutation, updateQuestionTagLinkMutationVariables>(
        gql`
            mutation updateQuestionTagLinkMutation ($model: QuestionTagLinkUpdateInput!) {
                updateQuestionTagLink(model: $model) {
                    ...questionTagLinkFields
                }
            }

            ${questionTagLinkFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (id: string, model: QuestionTagLinkUpdateInput) => {
        const result = await mutationAction({ variables: { model: { ...model, id: id } } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
