import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { cloneAssessmentMutation, cloneAssessmentMutationVariables } from '../generated/cloneAssessmentMutation';
import { Assessment, assessmentFieldsFragment } from '../models/Assessment';

/**
 * Get a callback to clone an Assessment in the store.
 */
export function useCloneAssessmentCallback(): [(id: string) => Promise<Assessment | null | undefined>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<cloneAssessmentMutation, cloneAssessmentMutationVariables>(
        gql`
            mutation cloneAssessmentMutation ($copyFromAssessmentId: ID!) {
                cloneAssessment(copyFromAssessmentId: $copyFromAssessmentId) {
                    ...assessmentFields
                }
            }

            ${assessmentFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (id: string) => {
        const result = await mutationAction({ variables: { copyFromAssessmentId: id } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }

        return result?.data?.cloneAssessment;
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
