import { SubscriptionType } from "../../../api/main/models/codeOnly/SubscriptionType";
import { CreateSubscriptionBase, EditSubscriptionBase } from "../EditSubscriptionBase";

/**
 * Create an actual Subscription.
 */
export const CreateSubscription = () => (
    <CreateSubscriptionBase
        subscriptionType={SubscriptionType.Subscription}
        onCreateDefaultValues={() => ({ subscriptionType: SubscriptionType.Subscription })}
    />);

/**
 * Edit an actual Subscription.
 */
export const EditSubscription = () => (<EditSubscriptionBase subscriptionType={SubscriptionType.Subscription} />);