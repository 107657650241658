import { IdentityRoles } from "../../../configure/security/IdentityRoles";
import { RouteEntry } from "../../../shared/routes";
import { ManagersList } from "./ManagersList";
import { DeleteManager } from "./DeleteManager";
import { CreateManager, EditManager } from "./EditManager";


export const managersRoutes: Array<RouteEntry> = [
    { path: '/manage/managers', exact: true, component: ManagersList, authorize: true, requireRole: IdentityRoles.SubscriptionAdministration },
    { path: '/manage/managers/edit/:id', component: EditManager, authorize: true, requireRole: IdentityRoles.SubscriptionAdministration },
    { path: '/manage/managers/add', component: CreateManager, authorize: true, requireRole: IdentityRoles.SubscriptionAdministration },
    { path: '/manage/managers/delete/:id', component: DeleteManager, authorize: true, requireRole: IdentityRoles.SubscriptionAdministration },

    { path: '/manage/teams/details/:subscriptionTeamId/managers', exact: true, component: ManagersList, authorize: true, requireRole: IdentityRoles.SubscriptionAdministration },
    { path: '/manage/teams/details/:subscriptionTeamId/managers/edit/:id', component: EditManager, authorize: true, requireRole: IdentityRoles.SubscriptionAdministration },
    { path: '/manage/teams/details/:subscriptionTeamId/managers/add', component: CreateManager, authorize: true, requireRole: IdentityRoles.SubscriptionAdministration },
    { path: '/manage/teams/details/:subscriptionTeamId/managers/delete/:id', component: DeleteManager, authorize: true, requireRole: IdentityRoles.SubscriptionAdministration},
];
