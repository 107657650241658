/**
 * Constants for the available roles.
 */
export const IdentityRoles = {
    DriverManagement: 'DriverManagement',
    SubscriptionAdministration: 'SubscriptionAdministration',
    DistributionAdministration: 'DistributorAdministration',
    Administration: 'Administration',
};

///**
// * Constants (enum) for the available Roles.
// */
//export enum IdentityRoles {
//    Administration = 'Administration'
//}
