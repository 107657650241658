import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Card, Col, Row } from "reactstrap";
import { AssessmentType } from "../../../api/main/models/codeOnly/AssessmentType";
import { DriverMetric } from "../../../api/main/models/DriverMetric";
import { UserDriverMetric } from "../../../api/main/models/UserDriverMetric";
import { RiskRules } from "../../../services/OverallRiskService";
import { questionScoreFromNumber } from "../../assessmentSessions/launch/utilities/questionScoreFromNumber";
import { IndividualScoreProgressBar } from "../progress/IndividualScoreProgressBar";
import "./driverScoreProgressBarAsChart.scss";

export interface DriverScoreProgressBarAsChartProps {
    userDriverMetrics: Array<UserDriverMetric>,
    driverMetrics: Array<DriverMetric>,
    riskRules?: RiskRules | null | undefined,
}

/**
 * Show an AssessmentAverageScoreProgressBar formatted to look like the other charts do.
 * @param props
 */
export const DriverScoreProgressBarAsChart = (props: DriverScoreProgressBarAsChartProps) => {
    const {
        userDriverMetrics,
        driverMetrics,
        riskRules,
    } = props;

    const { t } = useTranslation();

    const totalScore = useMemo(() => {
        let score = 0;
        for (const metric of userDriverMetrics) {
            score += metric.currentValue;
        }

        if (userDriverMetrics.length > 0) {
            score = Math.round(score / userDriverMetrics.length);
        }

        return questionScoreFromNumber(score, userDriverMetrics.length === 0, riskRules);
    }, [userDriverMetrics, riskRules]);

    return (
        <Card body tag="div" className="driver-score-progress-bar-as-chart">
            <div className="driver-score-progress-bar-as-chart-main-progress-bar">
                <IndividualScoreProgressBar
                    assessmentType={AssessmentType.Assessment}
                    value={totalScore}
                    title={t('assessmentsAverageScoreProgressBarAsChart.title', 'Driver overview')}
                    footer={t('assessmentsAverageScoreProgressBarAsChart.footer', 'Based on {{count}} safety metrics', { count: userDriverMetrics.length })}
                />
            </div>

            <Row className="mt-3">
                <Col></Col>
                {
                    driverMetrics.map(metric => {
                        const userDriveMetric = userDriverMetrics.find(it => it.driverMetricId === metric.id);

                        // If we have no UserDriveMetric for this metric, don't show it.
                        if (!userDriveMetric) {
                            return null;
                        }

                        // Convert into a question score.
                        const score = questionScoreFromNumber(userDriveMetric.currentValue, false, riskRules);

                        return (
                            <Col xs="auto">
                                <IndividualScoreProgressBar
                                    assessmentType={AssessmentType.Assessment}
                                    value={score}
                                    title={metric.name}
                                />
                            </Col>
                            );
                    })
                }
                <Col></Col>
            </Row>
        </Card>
        );
};