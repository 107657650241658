import { useMemo } from "react";
import { useSubscriptionStyleOverridesViewModel } from "../../api/main/subscriptions/viewModels/useSubscriptionStyleOverridesViewModel";
import { useCurrentUserOrEmulatedSubscriptionId } from "../../globalState/subscriptions/useCurrentUserOrEmulatedSubscriptionId";

/**
 * Component that renders some style overrides based on the theming of specific components.
 */
export const SubscriptionStyleOverrides = () => {
    const subscriptionId = useCurrentUserOrEmulatedSubscriptionId();
    const { data: { model: subscription } } = useSubscriptionStyleOverridesViewModel(subscriptionId);

    // Generate the styles.
    const style = useMemo(() => {
        if (!subscription?.allowBranding) {
            return '';
        }

        const logoUrl = subscription?.brandImageBlobReference?.url;
        let brandColor = subscription?.brandColor;

        // Treat a fully black colour as a not being overrided colour.
        if (brandColor === '#000000') {
            brandColor = '';
        }

        let ret = '';

        // If a logo is uploaded against the subscription, swap our brand image for the logo.
        if (logoUrl) {
            ret += `
                .navbar-brand {
                    background-image: url("${logoUrl}") !important;
                }
            `;
        }

        // If a brand colour is specificed against the subscription, swap our primary colour for the colour.
        if (brandColor) {
            const borderColor = shadeBlend(-0.2, brandColor);
            const hoverColor = shadeBlend(-0.1, brandColor);

            ret += `
                /* Text and backgrounds that are primary coloured should be switched to the brand colour. */
                .text-primary {
                    color: ${brandColor} !important;
                }

                .bg-primary {
                    background-color: ${brandColor} !important;
                }

                /* Primary buttons should use the brand colour. */
                .btn-primary:not(:hover) {
                    background-color: ${brandColor} !important;
                    border-color: ${borderColor} !important;
                }

                .btn-primary:hover {
                    background-color: ${hoverColor} !important;
                    border-color: ${borderColor} !important;
                }

                .btn-outline-primary:not(:hover):not(.dropdown-toggle[aria-expanded="true"]) {
                    color: ${brandColor} !important;
                    border-color: ${borderColor} !important;
                }

                .btn-outline-primary:hover {
                    background-color: ${hoverColor} !important;
                    border-color: ${borderColor} !important;
                }

                .btn-outline-primary.dropdown-toggle[aria-expanded="true"]:not(:hover) {
                    background-color: ${brandColor} !important;
                    border-color: ${borderColor} !important;
                }

                .btn-primary.disabled:hover, .btn-outline-primary.disabled:hover {
                    background-color: transparent !important;
                    color: ${borderColor} !important;
                }

                /* Navigation pills should use the brand colour. */
                .pills-nav-bar .nav-item .nav-link:not(.active) {
                    color: ${brandColor} !important;
                    border-color: ${borderColor} !important;
                }

                .pills-nav-bar .nav-item .nav-link.active {
                    background-color: ${brandColor} !important;
                    border-color: ${borderColor} !important;
                }

                /* Sidebar links (mostly used on mobile by non-admins) need to use the brand colour */
                .sidebar .nav-link.active {
                    background-color: ${brandColor} !important;
                }

                .sidebar .nav-link:hover {
                    background-color: ${brandColor} !important;
                }
            `;
        }

        return ret;
    }, [subscription]);

    return (
        <style type="text/css">
            {style}
        </style>
        );
};

/**
 * Shade a color.
 * 
 * Taken from v2 universal B from: https://github.com/PimpTrizkit/PJs/wiki/12.-Shade,-Blend-and-Convert-a-Web-Color-(pSBC.js)#stackoverflow-archive-begin
 * @param p
 * @param c0
 * @param c1
 */
function shadeBlend(p: number, c0: string, c1?: string) {
    var n = p < 0 ? p * -1 : p, u = Math.round, w = parseInt;
    if (c0.length > 7) {
        // eslint-disable-next-line no-mixed-operators
        const f = c0.split(","), t = (c1 ? c1 : p < 0 ? "rgb(0,0,0)" : "rgb(255,255,255)").split(","), R = w(f[0].slice(4)), G = w(f[1]), B = w(f[2]);
        // eslint-disable-next-line no-mixed-operators
        return "rgb(" + (u((w(t[0].slice(4)) - R) * n) + R) + "," + (u((w(t[1]) - G) * n) + G) + "," + (u((w(t[2]) - B) * n) + B) + ")"
    } else {
        // eslint-disable-next-line no-mixed-operators
        const f = w(c0.slice(1), 16), t = w((c1 ? c1 : p < 0 ? "#000000" : "#FFFFFF").slice(1), 16), R1 = f >> 16, G1 = f >> 8 & 0x00FF, B1 = f & 0x0000FF;
        // eslint-disable-next-line no-mixed-operators
        return "#" + (0x1000000 + (u(((t >> 16) - R1) * n) + R1) * 0x10000 + (u(((t >> 8 & 0x00FF) - G1) * n) + G1) * 0x100 + (u(((t & 0x0000FF) - B1) * n) + B1)).toString(16).slice(1)
    }
}
