import * as React from 'react';
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { AsyncLoadPagedResult, AsyncLoadPagedOptions } from '../../../../shared/abstractStore';
import { useFetchMoreCallback } from '../../../../shared/useFetchMoreCallback';
import { subscriptionAssessmentsListViewBaseViewModelQuery, subscriptionAssessmentsListViewBaseViewModelQueryVariables } from '../../generated/subscriptionAssessmentsListViewBaseViewModelQuery';
import { mainApiConfig } from '../../../../configure/mainApiConfig';
import { subscriptionAssessmentFieldsFragment } from '../../models/SubscriptionAssessment';
import { AssessmentType } from '../../models/codeOnly/AssessmentType';
import { assessmentFieldsFragment } from '../../models/Assessment';
import { subscriptionFieldsFragment } from '../../models/Subscription';
import { Guid } from 'guid-string';


export interface SubscriptionAssessmentsListViewBaseViewModelAsyncLoadPageOptions extends AsyncLoadPagedOptions {
    assessmentTypes?: Array<AssessmentType>,
    lowerSubscriptionId?: string | undefined | null,
}

/**
 * Get a list of subscriptionAssessments from the store.
 * @param options
 */
export function useSubscriptionAssessmentsListViewBaseViewModel(subscriptionId: string | null | undefined, options: SubscriptionAssessmentsListViewBaseViewModelAsyncLoadPageOptions = {}): AsyncLoadPagedResult<subscriptionAssessmentsListViewBaseViewModelQuery> {
    // Query data we need from the api.
    const [load, { data, loading, error, refetch, fetchMore: fetchMoreFromStore }] = useLazyQuery<subscriptionAssessmentsListViewBaseViewModelQuery, subscriptionAssessmentsListViewBaseViewModelQueryVariables>(
        gql`
        query subscriptionAssessmentsListViewBaseViewModelQuery ($subscriptionId: ID!, $lowerSubscriptionId: ID, $offset: Int, $first: Int, $assessmentTypes: [String!]) {
            items: subscriptionAssessments (subscriptionId: $subscriptionId) {
                ...subscriptionAssessmentFields
            }

            selectedItems: subscriptionAssessments(subscriptionId: $lowerSubscriptionId) {
                ...subscriptionAssessmentFields
            }

            assessments (offset: $offset, first: $first, assessmentTypes: $assessmentTypes) {
                ...assessmentFields
            }

            subscription (id: $subscriptionId) {
                ...subscriptionFields
            }
        }

        ${subscriptionAssessmentFieldsFragment}
        ${assessmentFieldsFragment}
        ${subscriptionFieldsFragment}
        `,
        {

            variables: {
                offset: options.startOffset,
                first: options.pageSize,
                assessmentTypes: options.assessmentTypes,
                subscriptionId: subscriptionId ?? Guid.empty,
                lowerSubscriptionId: options.lowerSubscriptionId,
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load])

    // Get the functions used to manage paging.
    const { fetchMore, hasMore: _hasMore } = useFetchMoreCallback<subscriptionAssessmentsListViewBaseViewModelQuery>(
        fetchMoreFromStore,
        (options?.startOffset ?? 0) + (data?.items?.length ?? 0),
        (prevResults, newResults) => ({
            ...prevResults,
            ...newResults,

            items: newResults?.items ?? [],
            selectedItems: newResults?.selectedItems ?? [],
            subscription: newResults?.subscription ?? null,
            assessments: [...(prevResults?.assessments ?? []), ...(newResults?.assessments ?? [])]
        }),
        (newResults) => newResults?.items?.length
    );
    const hasMore = React.useCallback(() => !options.pageSize ? false : _hasMore(), [options.pageSize, _hasMore]);
    
    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error,
        fetchMore: fetchMore,
        hasMore: hasMore,
    }), [data, refresh, loading, error, fetchMore, hasMore]);
}
