import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { useAsyncCallback } from "react-use-async-callback";
import { Button, Col, Row } from "reactstrap";
import { useSaveAssessmentSessionCallback } from "../../../api/main/assessmentSessions/useSaveAssessmentSessionCallback";
import { useLaunchAssessmentSessionViewModel } from "../../../api/main/assessmentSessions/viewModels/useLaunchAssessmentSessionViewModel";
import { launchAssessmentSessionViewModelQuery_viewModel } from "../../../api/main/generated/launchAssessmentSessionViewModelQuery";
import { AssessmentItem } from "../../../api/main/models/AssessmentItem";
import { AssessmentSession } from "../../../api/main/models/AssessmentSession";
import { AssessmentType } from "../../../api/main/models/codeOnly/AssessmentType";
import { FeedbackStyle } from "../../../api/main/models/codeOnly/FeedbackStyle";
import { getQuestionTypeCategory, QuestionType, QuestionTypeCategory } from "../../../api/main/models/codeOnly/QuestionType";
import { Question } from "../../../api/main/models/Question";
import { QuestionResponseEvent } from "../../../api/main/models/QuestionResponseEvent";
import { useDeleteQuestionResponseEventCallback } from "../../../api/main/questionResponseEvents/useDeleteQuestionResponseEventCallback";
import { useSaveQuestionResponseEventCallback } from "../../../api/main/questionResponseEvents/useSaveQuestionResponseEventCallback";
import { AlertOnErrors } from "../../../shared/alertOnErrors";
import { useChanges, useChangesArray } from "../../../shared/useChanges";
import { Background } from "../../shared/background/Background";
import { Banner } from "../../shared/Banner";
import { FormButtons } from "../../shared/FormButtons";
import { LoadingIndicator } from "../../shared/LoadingIndicator";
import { MainContainer } from "../../shared/MainContainer";
import { ShowQuestionFeedback } from "./feedback/ShowQuestionFeedback";
import { FinishPage } from "./FinishPage";
import { ShowQuestion } from "./questions/ShowQuestion";
import { StartPage } from "./StartPage";
import { calculateAssessmentScore } from "./utilities/calculateAssessmentScore";
import { calculateQuestionScore } from "./utilities/calculateQuestionScore";
import "./launchAssessmentBase.scss";
import moment from "moment";
import { ScoreProgressBar } from "./progress/ScoreProgressBar";
import { PageProgress } from "./progress/PageProgress";
import { useApplyDriverMetricsForAssessmentSessionCallback } from "../../../api/main/userDriverMetrics/useApplyDriverMetricsForAssessmentSessionCallback";
import { questionScoreFromNumber } from "./utilities/questionScoreFromNumber";
import { useLaunchAssessmentSessionSupportingData } from "../../../api/main/assessmentSessions/viewModels/useLaunchAssessmentSessionSupportingData";
import { IdentityRoles } from "../../../configure/security/IdentityRoles";
import { useCurrentUserRoles } from "../../../api/account";
import { SubscriptionType } from "../../../api/main/models/codeOnly/SubscriptionType";

export interface LaunchAssessmentBaseProps {
    defaultAssessmentType: AssessmentType,
    finishPageOnly?: boolean,
}

export interface LaunchAssessmentPage {
    pageType: 'start' | 'question' | 'questionFeedback' | 'finish';
    assessmentItemId: string | undefined,
    questionId: string | undefined,
    fullScreen: boolean, // When true we'll use full screen when showing the page to avoid destractions.
}

/**
 * Launch an assessment so it can be completed.
 */
export const LaunchAssessmentBase = (props: LaunchAssessmentBaseProps) => {
    const {
        defaultAssessmentType,
        finishPageOnly = false,
    } = props;

    const { id } = useParams<{ id: string; }>();
    const history = useHistory();

    const { t } = useTranslation();

    // Load all the data.
    const { data: { viewModel }, isLoading: _isLoading, errors: loadErrors } = useLaunchAssessmentSessionViewModel(id);
    const {
        assessmentSession: storeModel,
        assessment,
        assessmentFeedbacks,
        questions,
        questionSequenceQuestions,
        questionAnswers,
        questionnaireQuestionAnswers,
        questionFeedbacks,
        blobReferences,
        questionResponseEvents,
        assessmentItems,
        assessmentItemSessionOrders,
        subscription,
    } = viewModel ?? {} as launchAssessmentSessionViewModelQuery_viewModel;
    const { data: { driverMetrics }, isLoading: isLoadingSupportingData, errors: supportingDataErrors } = useLaunchAssessmentSessionSupportingData();
    const isLoading = _isLoading || isLoadingSupportingData;

    // The session is our model.
    const { model, change, changes } = useChanges(storeModel);
    const [saveAssessmentSession, { errors: saveAssessmentSessionErrors }] = useSaveAssessmentSessionCallback();
    const [applyDriverMetricsForAssessmentSession, { errors: applyDriverMetricsForAssessmentSessionErrors }] = useApplyDriverMetricsForAssessmentSessionCallback();

    // Is it an assessment or is it training?
    const activeAssessmentType: AssessmentType = useMemo(() => assessment?.assessmentType as AssessmentType ?? defaultAssessmentType, [assessment, defaultAssessmentType]);

    // Want to be able to have a manager around all the events.
    const questionResponseEventsManager = useChangesArray<QuestionResponseEvent, string>(questionResponseEvents, item => item.id);
    const [saveQuestionResponseEvent, { errors: saveQuestionResponseEventErrors }] = useSaveQuestionResponseEventCallback();
    const [removeQuestionResponseEvent, { errors: removeQuestionResponseEventErrors }] = useDeleteQuestionResponseEventCallback();

    // Generate a list of pages.
    const pages = useMemo((): Array<LaunchAssessmentPage> => {
        let ret: Array<LaunchAssessmentPage> = [];

        // Always have a start page to get going.
        ret.push(
            { pageType: 'start', assessmentItemId: undefined, questionId: undefined, } as LaunchAssessmentPage
        );

        // Add questions, and if required the question by question feedback.
        if (assessmentItemSessionOrders) {
            for (const assessmentItem of assessmentItemSessionOrders) {
                for (const questionItem of assessmentItem.questionIds) {
                    // Add the question page.
                    ret.push(
                        { pageType: 'question', assessmentItemId: assessmentItem.id, questionId: questionItem.id, fullScreen: true, } as LaunchAssessmentPage,
                    );

                    // If we are showing question feedback as we go, create a page for feedback after each question.
                    if (assessment?.feedbackStyle === FeedbackStyle.EachQuestionFeedback) {
                        // Only add a feedback page for scored questions.
                        const question = questions.find(item => item.id === questionItem.id);
                        const questionTypeCategory = getQuestionTypeCategory(question?.questionType as QuestionType);
                        if (questionTypeCategory === QuestionTypeCategory.Question) {
                            ret.push(
                                { pageType: 'questionFeedback', assessmentItemId: assessmentItem.id, questionId: questionItem.id, fullScreen: true, } as LaunchAssessmentPage
                            );
                        }
                    }
                }
            }
        }

        // Add the finish/overal feedback page.
        ret.push(
            { pageType: 'finish', assessmentItemId: undefined, questionId: undefined, } as LaunchAssessmentPage,
        );

        return ret;
    }, [assessmentItemSessionOrders, assessment, questions]);

    // Want to know the finished page index as its treated as a special page.
    const finishPage = pages.find(item => item.pageType === 'finish');
    const finishPageIndex = !finishPage ? 9999999 : pages.indexOf(finishPage);

    const exceedsSuspiciousLimit = useMemo(() => {
        // Count up all the responses that relate to answers marked as suspicious.
        let suspicionScore = 0;
        if (!!questionResponseEvents || !!questionAnswers) {
            // We only want to start counting when we have stuff to count
            //suspiciousResponses = questionResponseEvents.filter(item => questionAnswers.find(it => it.id === item.questionAnswerId)?.isSuspicious).length ?? 0
            // Find all answers that relate to suspicious responses
            const pickedSuspiciousAnswers = questionAnswers.filter(item => questionResponseEvents.find(it => it.questionAnswerId === item.id) && item.isSuspicious);

            // Add up all their suspicionScores
            pickedSuspiciousAnswers.forEach(item => suspicionScore = suspicionScore + item.suspicionScore!);

        }

        // If the score we counted exceeds the limit set on the assessment we need to mark the session as being suspicious.
        if (!!assessment && suspicionScore > assessment.maxSuspiciousAnswers) {
            return true;
        } else {
            return false;
        }
    }, [questionResponseEvents, questionAnswers, assessment]);

    // Browser detection
    const detectBrowser = useCallback(() => {
        const userAgent = navigator?.userAgent?.toLowerCase() ?? '';
        let browserName;

        if (userAgent.match(/chrome|chromium|crios/i)) {
            browserName = 'Chrome';
            return browserName;
        }
        if (userAgent.match(/firefox|fxios/i)) {
            browserName = 'Firefox';
            return browserName;
        }
        if (userAgent.match(/safari/i)) {
            browserName = 'Safari';
            return browserName;
        }
        if (userAgent.match(/opr\//i)) {
            browserName = 'Opera';
            return browserName;
        }
        if (userAgent.match(/edg/i)) {
            browserName = 'Edge';
            return browserName;
        }

        return "No browser detection";

    }, []);

    // Detect Operating System
    const detectOS = useCallback(() => {
        const userAgent = navigator?.userAgent?.toLowerCase() ?? '';
        let osName;

        if (userAgent.indexOf('win') > -1) {
            osName = 'Windows';
            return osName;
        }
        if (userAgent.indexOf('iphone') > -1 || userAgent.indexOf('ipad') > -1) {
            osName = 'iOS';
            return osName;
        }
        if (userAgent.indexOf('mac') > -1) {
            osName = 'MacOS';
            return osName;
        }
        if (userAgent.indexOf('android') > -1) {
            osName = 'Android';
            return osName;
        }
        if (userAgent.indexOf('linux') > -1) {
            osName = 'Linux';
            return osName;
        }
        if (userAgent.indexOf('x11') > -1) {
            osName = 'UNIX';
            return osName;
        }
        return "No OS detection";

    }, []);

    // Get device metrics
    const browserData = useMemo(() => {
        return {
            userAgent: window.navigator.userAgent,
            browser: detectBrowser(),
            platform: detectOS(),
            screenResolution: `${window.screen.width}x${window.screen.height}`,
            screenOrientation: window.screen.orientation.type,
            windowResolution: `${window.innerWidth}x${window.innerHeight}`,
            colorDepth: window.screen.colorDepth,
            touch: window.navigator.maxTouchPoints > 0 ? 'Touch Device' : 'Non Touch Device',
        };
    }, [detectBrowser, detectOS]);
    
    // Save everything events to the database.
    const [saveEverything, { errors: saveEverythingErrors }] = useAsyncCallback(async (options?: { currentPosition?: number, }) => {
        // Save the question response events.
        for (const item of questionResponseEventsManager.added) { await saveQuestionResponseEvent(item.id, questionResponseEventsManager.changesFor(item.id), true); }
        for (const item of questionResponseEventsManager.updated) { await saveQuestionResponseEvent(item.id, questionResponseEventsManager.changesFor(item.id), false); }
        for (const item of questionResponseEventsManager.removed) { await removeQuestionResponseEvent(item.id); }
        questionResponseEventsManager.markAsSaved(questionResponseEventsManager.model);

        const filteredSequenceQuestions = questionSequenceQuestions.filter(item => item.archived === false);

        var questionnaireQuestions: Array<Question> = [];
        filteredSequenceQuestions.forEach(item => questionnaireQuestions.push(item.childQuestion));

        // Calculate the total score.
        const assessmentScore = calculateAssessmentScore(questions ?? [], questionResponseEventsManager.model, subscription, filteredSequenceQuestions, questionnaireQuestions);

        // Store the new score and current position.
        const newPosition = options?.currentPosition ?? 0;

        // Work out if we are finished or not.
        const isFinished = (newPosition >= finishPageIndex) ? true : false;

        const newChanges = {
            totalScore: assessmentScore.score,
            markedSuspicious: exceedsSuspiciousLimit,
            // Update the saved current position if we have progressed further than before.
            currentPosition: newPosition > model.currentPosition ? newPosition : model.currentPosition,

            // If we're newly finished, mark the dates.
            isFinished: !model.isFinished && isFinished ? true : model.isFinished,
            endDate: !model.endDate && isFinished ? moment().toISOString() : model.endDate,
            browserDataJson: model?.browserDataJson === '' ? JSON.stringify(browserData) : model?.browserDataJson,
        } as Partial<AssessmentSession>;

        // Update the new changes into the state.
        change(newChanges);

        // Save the session with the new changes.
        await saveAssessmentSession(model.id, { ...changes, ...newChanges, }, false);

        // If we are newly finished, then we also want to update all the driver metrics etc.
        if (isFinished && !model.isFinished) {
            const driverMetricScores = assessmentScore.driverMetricBreakdown
                .filter(item => !!item.driverMetricId)
                .map(item => ({ driverMetricId: item.driverMetricId ?? '', score: item.score.score, }));

            await applyDriverMetricsForAssessmentSession({
                assessmentSessionId: model.id,
                driverMetricScores: driverMetricScores,
            });
        }
    }, [questionResponseEventsManager, saveQuestionResponseEvent, removeQuestionResponseEvent, change, saveAssessmentSession, pages, applyDriverMetricsForAssessmentSession, finishPageIndex, exceedsSuspiciousLimit]);

    // Track the active question and assessment item.
    // NOTE we set the default to -1 as we don't want to start on any page until we have data ready, even the Start Page.  In particular not doing this
    // can cause funny behaviour for the Next button, which gets enabled by the start page when we load index 0 and then remains enabled when we jump to
    // the previous page once the state has been loaded.
    const [currentPageIndex, setCurrentPageIndex] = useState<number>(-1);
    const {
        currentPage,
        currentAssessmentItem,
        currentQuestion,
    } = useMemo(() => {
        // If we're at the start then return n
        if (currentPageIndex < 0 || currentPageIndex >= pages.length) {
            return {
                currentPage: undefined,
                currentAssessmentItem: undefined,
                currentQuestion: undefined,
            };
        }

        const page = pages[currentPageIndex];
        const assessmentItem = assessmentItems?.find(item => item.id === page.assessmentItemId);
        const question = questions?.find(item => item.id === page.questionId);

        return {
            currentPage: page,
            currentAssessmentItem: assessmentItem,
            currentQuestion: question,
        };
    }, [currentPageIndex, pages, assessmentItems, questions,]);

    // If we are continuing a session jump to the right page.
    const [hasJumpedToInitialPage, setHasJumpedToInitialPage] = useState<boolean>(false);
    useEffect(() => {
        if (!storeModel) {
            return;
        }

        if (hasJumpedToInitialPage) {
            return;
        }

        let initialPageIndex = storeModel.currentPosition ?? 0;
        if (finishPageOnly) {
            initialPageIndex = finishPageIndex;
        }

        if (initialPageIndex < 0) {
            initialPageIndex = 0;
        } else if (initialPageIndex >= pages.length) {
            initialPageIndex = pages.length - 1;
        } else if (initialPageIndex > finishPageIndex) {
            // If we are trying to land past the finish page (i.e. individual question feedback shown at the end),
            // land instead on the finish page.
            initialPageIndex = finishPageIndex;
        }

        setCanNavigateForwards(false);
        setHasJumpedToInitialPage(true);
        setCurrentPageIndex(initialPageIndex);
    }, [model, currentPageIndex, pages, hasJumpedToInitialPage, setHasJumpedToInitialPage, storeModel, finishPageIndex, finishPageOnly]);

    // Navigation backwards and forwards between questions.
    const [canNavigateBack, setCanNavigateBack] = useState<boolean>(false);
    const navigateBack = useCallback(() => {
        // Move on to the next question.
        setCurrentPageIndex(prevState => {
            if (prevState <= 0) {
                return prevState;
            }

            const newPageIndex = prevState - 1;

            // Save everything each time the page changes.
            saveEverything({ currentPosition: newPageIndex });
            return newPageIndex;
        });
    }, [setCurrentPageIndex, saveEverything]);

    // Backwards navigation should be available in training but not assessments.
    useEffect(() => {
        const expectedCanNavigateBack = activeAssessmentType === AssessmentType.TrainingModule ? true : false;
        if (canNavigateBack !== expectedCanNavigateBack) {
            setCanNavigateBack(expectedCanNavigateBack);
        }
    }, [activeAssessmentType, setCanNavigateBack, canNavigateBack]);

    const [canNavigateForwards, setCanNavigateForwards] = useState<boolean>(false);

    const navigateForwards = useCallback(() => {
        // Don't allow any further navigation until the question unlocks it again.
        setCanNavigateForwards(false);

        // Move on to the next question.
        setCurrentPageIndex(prevState => {
            if (prevState >= (pages.length - 1)) {
                return prevState;
            }

            const newPageIndex = prevState + 1;

            // Save everything each time the page changes.
            saveEverything({ currentPosition: newPageIndex });
            return newPageIndex;
        });
    }, [setCanNavigateForwards, setCurrentPageIndex, pages, saveEverything]);

    // Finish button handler.
    const navigateFinish = useCallback(() => {
        // Save everything each time the page changes.
        saveEverything();

        // If the subscription is a study, then instead of going back to the page we came from, redirect the user to the study completed page which
        // will log them out and return them to their study management website if one is configured.
        if (subscription?.subscriptionType === SubscriptionType.Study) {
            history.push(`/study-complete/${model.id}`);
            return;
        }

        // Otherwise go back to the page this was launched from.
        history.goBack();
    }, [saveEverything, history, subscription, model]);

    // When page is complete we unlock the ability to go forward.
    const onPageComplete = useCallback(() => {
        setCanNavigateForwards(true);
    }, [setCanNavigateForwards]);

    const [furthestTravelled, setFurthestTravelled] = useState<number>(0);

    useEffect(() => {

        if (currentPageIndex > furthestTravelled) {

            setFurthestTravelled(currentPageIndex);

        }
        if (currentPageIndex < furthestTravelled) {

            onPageComplete();

        }

    }, [currentPageIndex, furthestTravelled, setFurthestTravelled, onPageComplete]);

    // Admins can always see all feedback breakdowns, regardless of the assessments feedback when they launch in results mode (finsihPageOnly=true).
    // Handle that specialk case now.
    const { data: { roles: currentUserRoles } } = useCurrentUserRoles();
    const finishPageFeedbackStyle = useMemo(() => {
        const isAdministrator = !!currentUserRoles?.find(it => it === IdentityRoles.Administration || it === IdentityRoles.DriverManagement || it === IdentityRoles.SubscriptionAdministration);
        if (finishPageOnly && isAdministrator) {
            if (
                assessment?.feedbackStyle !== FeedbackStyle.SummaryFeedback
                && assessment?.feedbackStyle !== FeedbackStyle.EachQuestionFeedback
            ) {
                return FeedbackStyle.SummaryFeedback;
            }
        }

        return assessment?.feedbackStyle as FeedbackStyle | undefined;
    }, [finishPageOnly, currentUserRoles, assessment]);

    return (
        <Background className="launch-assessment-base">
            <ConditionalFragment showIf={!currentPage?.fullScreen}>
                <Banner>
                    <Row>
                        <Col>
                            <h1>
                                {assessment?.name}
                            </h1>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                        <ConditionalFragment showIf={!!model?.isFinished}>
                            <ConditionalFragment showIf={finishPageFeedbackStyle !== FeedbackStyle.NoScore}>
                                <Col xs={12} md="auto" className="text-center">
                                    <ScoreProgressBar value={questionScoreFromNumber(model?.totalScore ?? 0, false, assessment)} assessmentType={assessment?.assessmentType as AssessmentType | undefined} />
                                </Col>
                            </ConditionalFragment>
                        </ConditionalFragment>
                    </Row>
                </Banner>
            </ConditionalFragment>

            <MainContainer className={`launch-assessment-base-main-container ${currentPage?.fullScreen ? 'launch-assessment-base-main-container-full-screen' : ''}`}>
                <AlertOnErrors errors={[
                    loadErrors,
                    supportingDataErrors,
                    saveEverythingErrors,
                    saveAssessmentSessionErrors,
                    saveQuestionResponseEventErrors, removeQuestionResponseEventErrors,
                    applyDriverMetricsForAssessmentSessionErrors,
                ]} />

                <div className="launch-assessment-base-content">
                    {
                        !currentPage ? null
                            : currentPage.pageType === 'start' ? (
                                <StartPage
                                    model={model}
                                    assessment={assessment}
                                    onPageComplete={onPageComplete}
                                />
                            )
                                : currentPage.pageType === 'finish' ? (
                                    <FinishPage
                                        model={model}
                                        assessment={assessment}
                                        activeAssessmentType={activeAssessmentType}
                                        finishPageFeedbackStyle={finishPageFeedbackStyle}
                                        feedbacks={assessmentFeedbacks}
                                        blobReferences={blobReferences}
                                        totalScore={calculateAssessmentScore(questions ?? [], questionResponseEventsManager.model, assessment,
                                            questionSequenceQuestions, questionSequenceQuestions.map(item => item.childQuestion))}
                                        onPageComplete={onPageComplete}
                                        questions={questions}
                                        questionFeedbacks={questionFeedbacks}
                                        questionResponseEvents={questionResponseEvents}
                                        calculateQuestionScore={calculateQuestionScore}
                                        pages={pages}
                                        driverMetrics={driverMetrics ?? []}
                                        subscription={subscription}
                                        allCurrentQuestionSequence={questionSequenceQuestions}
                                        exceedsSuspiciousLimit={exceedsSuspiciousLimit}
                                    />
                                )
                                    : currentPage.pageType === 'question' ? (
                                        <>
                                            <ShowQuestion
                                                key={currentQuestion?.id ?? '' /* Make sure we get a fresh component for each question */}
                                                model={currentQuestion as Question}
                                                allCurrentQuestionSequence={questionSequenceQuestions}
                                                answers={questionAnswers}
                                                questionnaireQuestionAnswers={questionnaireQuestionAnswers}
                                                blobReferences={blobReferences}
                                                questionResponseEventsManager={questionResponseEventsManager}
                                                assessment={assessment}
                                                assessmentSession={model}
                                                assessmentItem={currentAssessmentItem as AssessmentItem}
                                                onPageComplete={onPageComplete}
                                            />
                                        </>

                                    )
                                        : currentPage.pageType === 'questionFeedback' ? (
                                            <ShowQuestionFeedback
                                                key={currentQuestion?.id ?? '' /* Make sure we get a fresh component for each question */}
                                                model={currentQuestion as Question}
                                                feedbacks={questionFeedbacks.filter(item => item.questionId === currentQuestion?.id)}
                                                blobReferences={blobReferences}
                                                questionScore={calculateQuestionScore(questionResponseEventsManager.model.filter(item => item.questionId === currentQuestion?.id && item.archived === false) ?? [], subscription)}
                                                onPageComplete={onPageComplete}
                                            />
                                        )
                                            : null
                    }
                </div>

                <FormButtons className="launch-assessment-base-form-buttons">
                    <Row>
                        <ConditionalFragment showIf={!finishPageOnly && canNavigateBack && currentPageIndex > 0}>
                            <Col xs="auto" className="text-left">
                                <Button color="primary" outline onClick={() => navigateBack()}>
                                    <FontAwesomeIcon icon="caret-left" />
                                    <> </>
                                    {t('launchAssessmentBase.previous', 'Previous')}
                                </Button>
                            </Col>
                        </ConditionalFragment>
                        <Col>
                            <ConditionalFragment showIf={!finishPageOnly && pages.length > 2 /* Only show if we have real activities to do, not while loading */}>
                                <PageProgress currentPageIndex={currentPageIndex} pageCount={pages.length} />
                            </ConditionalFragment>
                        </Col>
                        <Col xs="auto" className="text-right">
                            {
                                currentPageIndex >= (pages.length - 1) ?
                                    (
                                        <Button color="primary" outline={!canNavigateForwards} disabled={!canNavigateForwards} onClick={() => navigateFinish()}>
                                            {
                                                finishPageOnly ? (
                                                    <>
                                                        <FontAwesomeIcon icon="times" />
                                                        <> </>
                                                        {t('launchAssessmentBase.close', 'Close')}
                                                    </>
                                                ) : (
                                                    <>
                                                        <FontAwesomeIcon icon="flag-checkered" />
                                                        <> </>
                                                        {t('launchAssessmentBase.finish', 'Finish')}
                                                    </>
                                                )
                                            }
                                        </Button>
                                    ) : (
                                        <ConditionalFragment showIf={!finishPageOnly}>
                                            <Button color="primary" outline={!canNavigateForwards} disabled={!canNavigateForwards} onClick={() => navigateForwards()}>
                                                {t('launchAssessmentBase.next', 'Next')}
                                                <> </>
                                                <FontAwesomeIcon icon="caret-right" />
                                            </Button>
                                        </ConditionalFragment>
                                    )
                            }
                        </Col>
                    </Row>
                </FormButtons>
            </MainContainer>
        </Background>
    );
};