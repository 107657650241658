import { useCallback, useMemo } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Button, ButtonGroup, Col, Container, Row } from "reactstrap";
import { AssessmentType } from "../../../api/main/models/codeOnly/AssessmentType";
import { AlertOnErrors } from "../../../shared/alertOnErrors";
import { Background } from "../../shared/background/Background";
import { Banner } from "../../shared/Banner";
import { LoadingIndicator } from "../../shared/LoadingIndicator";
import { UserAssessmentAssignment } from "../../../api/main/models/UserAssessmentAssignment";
import { AssessmentSession } from "../../../api/main/models/AssessmentSession";
import { generateDashboardSummary as _generateDashboardSummary } from "../utilities/generateDashboardSummary";
import { useParams } from "react-router";
import { UserProgressDashboardTab } from "../tabs/UserProgressDashboardTab";
import { useAdministrationDashboardViewModel } from "../../../api/main/dashboard/viewModels/useAdministrationDashboardViewModel";
import { useExpandedUserAssessmentAssignments } from "../utilities/useExpandedUserAssessmentAssignments";
import { useActiveDashboardAssessmentType } from "../../../globalState/activeDashboardAssessmentType/useActiveDashboardAssessmentType";
import { DashboardBackButton } from "../backButton/DashboardBackButton";
import { useCurrentUserId } from "../../../api/account";

export interface StaffUserDashboardProps {
    userId?: string,
}


/**
 * Dashboard for a staff member/administrator.
 */
export const StaffUserDashboard = (props: StaffUserDashboardProps) => {
    const {
        userId: userIdProp,
    } = props;

    // Work out the subscription and user to show for.
    const { userId: userIdParam } = useParams<{ userId: string | undefined }>();
    const userId = userIdProp ?? userIdParam;

    // Get the current user, as some extra features are available on people's own dashboard.
    const currentUserId = useCurrentUserId();

    const { t } = useTranslation();

    const {
        data: {
            profiles: allProfiles,
            assessments: allAssessments,
            assessmentSessions: allAssmentSessions,
            userAssessmentAssignments: allUserAssessmentAssignments,
            driverMetrics,
            userDriverMetrics: allUserDriverMetrics,
        },
        isLoading,
        errors: loadErrors
    } = useAdministrationDashboardViewModel();

    const profile = useMemo(() => allProfiles?.find(it => it.userId === userId), [allProfiles, userId]);

    // Filter the sessions down to only this user.
    const assessmentSessions = useMemo(() => allAssmentSessions?.filter(item => item.userId === userId), [allAssmentSessions, userId]);
    const userAssessmentAssignments = useMemo(() =>
        allUserAssessmentAssignments
            ?.filter(item => {
                // If it is for the user's team keep it.
                if (!!item.subscriptionTeamId && item.subscriptionTeamId === profile?.userId) {
                    return true;
                }

                // If it is for this user keep it.
                if (item.userId === userId) {
                    return true;
                }

                // Otherwise we don't want it.
                return false;
            })
            , [allUserAssessmentAssignments, userId, profile]);

    // Filter asessments to match those used by this user in some way.
    const assessments = useMemo(() =>
        allAssessments
            ?.filter(assessment =>
                !!assessmentSessions?.find(it => it.assessmentId === assessment.id)
                || !!userAssessmentAssignments?.find(it => it.assessmentId === assessment.id)
        )
        , [allAssessments, userAssessmentAssignments, assessmentSessions]);

    // Expand the UserAssessmentAssignments so we can work with them easier.
    const expandedUserAssessmentAssignments = useExpandedUserAssessmentAssignments(userAssessmentAssignments ?? [], profile ? [profile] : []);

    // Filter the userDriverMetrics down to only this user.
    const userDriverMetrics = useMemo(() => allUserDriverMetrics?.filter(item => item.userId === profile?.userId), [allUserDriverMetrics, profile]);

    // Allow the generation of dashboard summary data.
    const generateDashboardSummary = useCallback((
        sessionFilter: (session: AssessmentSession) => boolean,
        assignmentFilter: (assignment: UserAssessmentAssignment) => boolean,
        assessmentType: AssessmentType) => {
        // Filter by the passed in fitler.
        let mySessions = assessmentSessions?.filter(sessionFilter) ?? [];
        let myAssignments = expandedUserAssessmentAssignments?.filter(assignmentFilter) ?? [];

        // Filter the driver metrics to contain only users that have assessments or assignments.
        const myUserDriverMetrics = allUserDriverMetrics?.filter(
            udm => !!mySessions.find(it => it.userId === udm.userId) || !!myAssignments.find(it => it.userId === udm.userId)
        ) ?? [];

        // Generate the summary data.
        const ret = _generateDashboardSummary(mySessions, assessments ?? [], profile ? [profile] : [], myAssignments, myUserDriverMetrics ?? [], null);
        return ret;
    }, [assessmentSessions, assessments, profile, expandedUserAssessmentAssignments, allUserDriverMetrics]);


    // Track the active assessment type.
    const [activeAssessmentType, setActiveAssessmentType] = useActiveDashboardAssessmentType();

    return (
        <Background>
            <Banner fluid>
                <Row>
                    <Col xs={12} md="">
                        <h1>
                            <DashboardBackButton />
                            {
                                t('staffUserDashboard.heading', '{{firstName}} {{lastName}} dashboard', profile)
                            }
                        </h1>
                    </Col>
                    <Col xs="auto">
                        <ButtonGroup>
                            <Button outline={activeAssessmentType !== AssessmentType.Assessment} onClick={() => setActiveAssessmentType(AssessmentType.Assessment)}>
                                {t('staffUserDashboard.toggle.assessments', 'Assessments')}
                            </Button>
                            <Button outline={activeAssessmentType !== AssessmentType.TrainingModule} onClick={() => setActiveAssessmentType(AssessmentType.TrainingModule)}>
                                {t('staffUserDashboard.toggle.training', 'Training')}
                            </Button>
                        </ButtonGroup>
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
            </Banner>
            <Container fluid>
                <AlertOnErrors errors={[loadErrors]} />

                <UserProgressDashboardTab
                    assessments={assessments ?? []}
                    generateDashboardSummary={generateDashboardSummary}
                    assessmentType={activeAssessmentType}
                    assessmentSessions={assessmentSessions ?? []}
                    expandedUserAssessmentAssignments={expandedUserAssessmentAssignments}
                    driverMetrics={driverMetrics ?? []}
                    userDriverMetrics={userDriverMetrics ?? []}
                    riskRules={null}
                    isCurrentUser={userId === currentUserId}
                />
            </Container>
        </Background>
    );
};
