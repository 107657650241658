import { QuestionType } from "../../../api/main/models/codeOnly/QuestionType";
import { CreateQuestionBase, EditQuestionBase } from "../edit/EditQuestionBase";

/**
 * Create an information page.
 */
export const CreateInformation = () => (
    <CreateQuestionBase
        defaultQuestionType={QuestionType.InformationVideo}
        onCreateDefaultValues={() => ({ /* No defaults needed */ })}
    />);

/**
 * Edit an information page.
 */
export const EditInformation = () => (<EditQuestionBase defaultQuestionType={QuestionType.InformationVideo} />);
