import gql from "graphql-tag";
import { Guid } from "guid-string";
import { subscriptionAssessmentFields } from "../generated/subscriptionAssessmentFields";

export const subscriptionAssessmentFieldsFragment = gql`
    fragment subscriptionAssessmentFields on SubscriptionAssessment {
        id
        subscriptionId
        assessmentId
        archived
        maxRestarts
    }
`;


export type SubscriptionAssessment = Omit<subscriptionAssessmentFields, '__typename'>;

// Default values.
export const subscriptionAssessmentDefaultValues = (): Partial<SubscriptionAssessment> => ({
    id: Guid.newGuid(),
    subscriptionId: undefined,
    assessmentId: undefined,
    archived: false,
    maxRestarts: 0,
});