import { RouteEntry } from "../../../shared/routes";
import { IdentityRoles } from "../../../configure/security/IdentityRoles";
import { UserAssessmentAssignmentsList } from "./UserAssessmentAssignmentsList";

export const userAssessmentAssignmentRoutes: Array<RouteEntry> = [
    { path: '/manage/teams/details/:subscriptionTeamId/assessments', exact: true, component: UserAssessmentAssignmentsList, authorize: true, requireRole: IdentityRoles.DriverManagement },

    { path: '/manage/drivers/details/:profileId/assessments', exact: true, component: UserAssessmentAssignmentsList, authorize: true, requireRole: IdentityRoles.DriverManagement },
    { path: '/manage/managers/details/:profileId/assessments', exact: true, component: UserAssessmentAssignmentsList, authorize: true, requireRole: IdentityRoles.DriverManagement },

    { path: '/administration/administrators/details/:profileId/assessments', exact: true, component: UserAssessmentAssignmentsList, authorize: true, requireRole: IdentityRoles.DriverManagement },
];
