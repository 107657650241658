import gql from "graphql-tag";
import { Guid } from "guid-string";
import moment from "moment";
import { questionAnswerFields } from "../generated/questionAnswerFields";

export const questionAnswerFieldsFragment = gql`
    fragment questionAnswerFields on QuestionAnswer {
        id
        questionId
        displayOrder
        archived
        answerText
        answerTextImageBlobReferenceId
        score
        isCorrect
        startResponseTimeSeconds
        endResponseTimeSeconds
        scaleImageBlobReferenceId
        windowTop
        windowLeft
        windowBottom
        windowRight
        discriminationGroup
        subQuestionNumber
        isSuspicious
        suspicionScore
    }
`;


export type QuestionAnswer = Omit<questionAnswerFields, '__typename'>;

// Default values.
export const questionAnswerDefaultValues = (): Partial<QuestionAnswer> => ({
    id: Guid.newGuid(),
    questionId: undefined,
    displayOrder: moment().unix(),
    answerText: '',
    answerTextImageBlobReferenceId: null,
    score: 0,
    isCorrect: false,
    startResponseTimeSeconds: 0,
    endResponseTimeSeconds: 0,
    scaleImageBlobReferenceId: null,
    windowTop: 0,
    windowLeft: 0,
    windowBottom: 0,
    windowRight: 0,
    discriminationGroup: '',
    subQuestionNumber: 1,
    isSuspicious: false,
    suspicionScore: undefined,
});