import { RouteEntry } from "../../../shared/routes";
import { IdentityRoles } from "../../../configure/security/IdentityRoles";
import { StudiesList } from "./StudiesList";
import { CreateStudy, EditStudy } from "./EditStudy";
import { StudyAssessmentsList } from "./StudyAssesmentsList";
import { StudyAdministrationDashboard } from "../../dashboard/administrationDashboard/StudyAdministrationDashboard";
import { StudyAdministrationHome } from "./Administration/StudyAdministrationHome";

export const studyRoutes: Array<RouteEntry> = [
    { path: '/administration/studies', exact: true, component: StudiesList, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/studies/add', component: CreateStudy, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/studies/edit/:id', component: EditStudy, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/studies/details/:subscriptionId/assessments', exact: true, component: StudyAssessmentsList, authorize: true, requireRole: IdentityRoles.Administration },

    // manage
    { path: '/manage/study', exact: true, component: StudyAdministrationHome, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/manage/study/participants', exact: true, component: StudyAdministrationDashboard, authorize: true, requireRole: IdentityRoles.Administration },
];
