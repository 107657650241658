import { ValidationErrors } from "pojo-validator";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Button, FormGroup, Label } from "reactstrap";
import { Assessment } from "../../../../api/main/models/Assessment";
import { AssessmentType } from "../../../../api/main/models/codeOnly/AssessmentType";
import { DriverMetric } from "../../../../api/main/models/DriverMetric";
import { DriverMetricAdjustment } from "../../../../api/main/models/DriverMetricAdjustment";
import { DriverMetricRecommendation, driverMetricRecommendationDefaultValues } from "../../../../api/main/models/DriverMetricRecommendation";
import { ModelArrayChanges } from "../../../../shared/useChanges";
import { DriverMetricRecommendationComponent } from "./DriverMetricRecommendationComponent";

export interface DriverMetricsTabProps {
    model: Assessment | undefined,
    change: (changes: Partial<Assessment>) => void,
    activeAssessmentType: AssessmentType,

    driverMetricRecommendationsManager: ModelArrayChanges<DriverMetricRecommendation, string>,
    driverMetricAdjustmentsManager: ModelArrayChanges<DriverMetricAdjustment, string>,

    driverMetrics: Array<DriverMetric>,

    validateDriverMetricRecommendation: (model: DriverMetricRecommendation) => boolean,
    driverMetricRecommendationValidationErrors: (id: string) => ValidationErrors,

    validateDriverMetricAdjustment: (model: DriverMetricAdjustment) => boolean,
    driverMetricAdjustmentValidationErrors: (id: string) => ValidationErrors,
}

/**
 * Tab for maintaining driver metrics for a question.
 * @param props
 */
export const DriverMetricsTab = (props: DriverMetricsTabProps) => {
    const {
        model,
        //change,
        //activeAssessmentType,

        driverMetricRecommendationsManager,
        //driverMetricAdjustmentsManager,
        driverMetrics,

        validateDriverMetricRecommendation,
        driverMetricRecommendationValidationErrors,

        //validateDriverMetricAdjustment,
        //driverMetricAdjustmentValidationErrors,
    } = props;

    const { t } = useTranslation();

    // Order the recommendations so they are shown based on their minimum score.
    const orderedRecommendations = useMemo(() => {
        let ret = [...driverMetricRecommendationsManager.model];

        // Sort by min score, then max score.
        ret.sort((a, b) => (a.minScore === b.minScore ? (a.maxScore === b.maxScore ? 0 : a.maxScore < b.maxScore ? -1 : 1) : a.minScore < b.minScore ? -1 : 1));

        return ret;
    }, [driverMetricRecommendationsManager.model]);

    // Adding of a recommendation.
    const addRecommendation = useCallback(() => {
        driverMetricRecommendationsManager.addFor({
            ...driverMetricRecommendationDefaultValues(),

            targetId: model?.id,
            targetType: 'Assessment',
        });
    }, [driverMetricRecommendationsManager, model?.id]);

    //// Order the adjustments so they are shown based on their minimum score.
    //const orderedAdjustments = useMemo(() => {
    //    let ret = [...driverMetricAdjustmentsManager.model];

    //    // Sort by min score, then max score.
    //    ret.sort((a, b) => (a.minScore === b.minScore ? (a.maxScore === b.maxScore? 0: a.maxScore < b.maxScore? -1: 1) : a.minScore < b.minScore ? -1 : 1));

    //    return ret;
    //}, [driverMetricAdjustmentsManager.model]);

    //// Adding of an adjustment.
    //const addAdjustment = useCallback(() => {
    //    driverMetricAdjustmentsManager.addFor({
    //        ...driverMetricAdjustmentDefaultValues(),

    //        targetId: model?.id,
    //        targetType: 'Assessment',
    //    });
    //}, [driverMetricAdjustmentsManager, model?.id]);

    
    return (
        <>
            {/*<FormGroup>
                <Label htmlFor="isSmartIndividuality">{t('editAssessmentBase.isSmartIndividuality.label', 'Standard or smart customised?')}</Label>
                <TwoValueSwitch
                    leftLabel={t('editAssessmentBase.isSmartIndividuality.standardised', 'Standardised')}
                    rightLabel={t('editAssessmentBase.isSmartIndividuality.smart', 'Smart customised')}
                    checked={model?.isSmartIndividuality ?? false}
                    onChange={checked => change({ isSmartIndividuality: checked })}
                />
                <FormText>
                    {
                        activeAssessmentType === AssessmentType.TrainingModule ? t('editAssessmentBase.isSmartIndividuality.formText.standardised.trainingModule', 'Standardised training modules are always fair and are not customised for the user taking the training, except to exclude questions they have seen previously.')
                            : t('editAssessmentBase.isSmartIndividuality.formText.standardised.assessment', 'Standardised assessments are always fair and are not customised for the user taking the assessment, except to exclude questions they have seen previously.')
                    }
                    <br/>
                    {
                        activeAssessmentType === AssessmentType.TrainingModule ? t('editAssessmentBase.isSmartIndividuality.formText.smart.trainingModule', 'Smart customised training modules look at the safety metrics of the user taking the training and check them against safety metric recommendations for indivudal questions and activities to customise the presented questions and activities to the needs of the individual user.')
                            : t('editAssessmentBase.isSmartIndividuality.formText.smart.assessment', 'Smart customised assessments look at the safety metrics of the user taking the assessment and check them against safety metric recommendations for indivudal questions and activities to customise the presented questions and activities to the needs of the individual user.')
                    }
                </FormText>
            </FormGroup>*/}

            <FormGroup>
                <Label htmlFor="driverMetricRecommendations">{
                    model?.assessmentType === AssessmentType.TrainingModule ? t('driverMetricsTab.driverMetricRecommendations.learning', 'This training module is recommended based on these rules:')
                    : t('driverMetricsTab.driverMetricRecommendations.assessment', 'This assessment is recommended based on these rules:')
                }</Label>

                <div>
                    {
                        orderedRecommendations.map(item => (
                            <DriverMetricRecommendationComponent key={item.id}
                                model={item}
                                change={changes => driverMetricRecommendationsManager.changeFor(item.id, changes)}
                                remove={() => driverMetricRecommendationsManager.removeFor(item.id)}
                                driverMetrics={driverMetrics}
                                validate={() => validateDriverMetricRecommendation(item)}
                                validationErrors={driverMetricRecommendationValidationErrors(item.id)}
                                assessmentType={model?.assessmentType as AssessmentType}
                                />
                            ))
                    }
                </div>

                <Button color="primary" outline onClick={() => addRecommendation()}>
                    {t('driverMetricsTab.addRecommendation', 'Add recommendation rule')}
                </Button>
            </FormGroup>

            {/* Driver metric adjustments have been removed form the UI in favour of using much simplier logic built around the primary driver metrics instead. */}
            {/*}<FormGroup>
                <Label htmlFor="driverMetricAdjustments">{
                    model?.assessmentType === AssessmentType.TrainingModule ? t('driverMetricsTab.driverMetricAdjustments.learning', 'This training module will change a driver\'s personal metrics based on these rules:')
                        : t('driverMetricsTab.driverMetricAdjustments.assessment', 'This assessment will change a driver\'s personal safety metrics based on these rules:')
                }</Label>

                <div>
                    {
                        orderedAdjustments.map(item => (
                            <DriverMetricAdjustmentComponent key={item.id}
                                model={item}
                                change={changes => driverMetricAdjustmentsManager.changeFor(item.id, changes)}
                                remove={() => driverMetricAdjustmentsManager.removeFor(item.id)}
                                driverMetrics={driverMetrics}
                                validate={() => validateDriverMetricAdjustment(item)}
                                validationErrors={driverMetricAdjustmentValidationErrors(item.id)}
                                assessmentType={model?.assessmentType as AssessmentType}
                            />
                        ))
                    }
                </div>

                <Button color="primary" outline onClick={() => addAdjustment()}>
                    {t('driverMetricsTab.addAdjustment', 'Add adjustment rule')}
                </Button>
            </FormGroup>*/}
        </>
        );
};
