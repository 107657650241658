import { Redirect } from "react-router";
import { RouteEntry } from "../../shared/routes";
import { ChangeEmail } from "./ChangeEmail";
import { ChangePassword } from "./ChangePassword";
import { Profile } from "./Profile";
import { ProfileDetails } from "./ProfileDetails";

export const profileRoutes: Array<RouteEntry> = [
    { path: '/profile', component: Profile, authorize: true },
];

export const profileTabRoutes: Array<RouteEntry> = [
    { path: '/profile', exact: true, component: () => <Redirect to="/profile/details" />, authorize: true },
    { path: '/profile/details', exact: true, component: ProfileDetails, authorize: true },
    { path: '/profile/changeEmail', exact: true, component: ChangeEmail, authorize: true },
    { path: '/profile/changePassword', exact: true, component: ChangePassword, authorize: true },
];

