import moment from "moment";
import { useCallback, useMemo } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router-dom";
import { Badge, Button, ButtonGroup, Col, Collapse, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText, Row } from "reactstrap";
import { useToggleStateArray } from "use-toggle-state";
import { useCurrentUserId } from "../../../api/account";
import { Assessment } from "../../../api/main/models/Assessment";
import { AssessmentSession } from "../../../api/main/models/AssessmentSession";
import { AssessmentType } from "../../../api/main/models/codeOnly/AssessmentType";
import { Profile } from "../../../api/main/models/Profile";
import { Subscription } from "../../../api/main/models/Subscription";
import { SubscriptionTeam } from "../../../api/main/models/SubscriptionTeam";
import { UserAssessmentAssignment } from "../../../api/main/models/UserAssessmentAssignment";
import { RiskRules } from "../../../services/OverallRiskService";
import { questionScoreFromNumber } from "../../assessmentSessions/launch/utilities/questionScoreFromNumber";
import { DueDateLabel } from "../../me/DueDateLabel";
import { useAverageScoreChartData } from "../chartData/useAverageScoreChartData";
import { AverageScoreRadarChart } from "../charts/AverageScoreRadarChart";
import { IndividualScoreProgressBar } from "../progress/IndividualScoreProgressBar";
import { DashboardSummary } from "../utilities/generateDashboardSummary";
import { getLatestSessions } from "../utilities/getLatestSessions";
import { useCachedDashboardSummaries } from "../utilities/useCachedDashboardSummaries";
import { useDashboardBaseRoute } from "../utilities/useDashboardBaseRoute";
import "./assessmentProgressDashboardTab.scss";

export interface AssessmentProgressDashboardTabProps {
    assessment: Assessment | undefined | null,
    profiles: Array<Profile>,
    subscriptions?: Array<Subscription>,
    subscriptionTeams: Array<SubscriptionTeam>,

    assessmentType: AssessmentType,
    generateDashboardSummary: (
        sessionFilter: (session: AssessmentSession) => boolean,
        assignmentFilter: (assignment: UserAssessmentAssignment) => boolean,
        assessmentType: AssessmentType
    ) => DashboardSummary,

    assessmentSessions: Array<AssessmentSession>,
    expandedUserAssessmentAssignments: Array<UserAssessmentAssignment>,

    showSubscriptionName?: boolean,

    riskRules: RiskRules | undefined | null,
}

/**
 * Tab for the dasboard that shows under a user to show their assigned assessments/training by status.
 * @param props
 */
export const AssessmentProgressDashboardTab = (props: AssessmentProgressDashboardTabProps) => {
    const {
        assessment,
        profiles,
        subscriptions,
        subscriptionTeams,

        assessmentType,

        generateDashboardSummary,

        assessmentSessions,
        expandedUserAssessmentAssignments,

        showSubscriptionName = false,

        riskRules,
    } = props;

    const { t } = useTranslation();

    const currentUserId = useCurrentUserId();

    // Method we use to filter sessions by user wherever we need it.
    const filterByUser = useCallback((user: { id: string }, item: { userId: string | undefined | null }) => {
        return item.userId === user.id;
    }, []);

    // Profiles simplified to have a name and id (for which we use the userId).
    const users = useMemo(() => profiles.map(profile => ({
        id: profile.userId,
        name: t('common.fullName', '{{firstName}} {{lastName}}', profile),
        subscriptionId: profile.subscriptionId,
        subscriptionTeamId: profile.subscriptionTeamId,
    })), [profiles, t]);

    // Cache the dashboard summaries so each chart/display doesn't need to recaclulate them and they can be looked up easily by id.
    const getCachedDashboardSummary = useCachedDashboardSummaries(
        users,
        (group, assessmentType) => generateDashboardSummary(
            session => filterByUser(group, session),
            assignment => filterByUser(group, assignment),
            assessmentType
        )
    );

    // Get the latest session for each assessment.
    const { latestSessions, latestSessionsIncludingIncomplete } = useMemo(() => getLatestSessions(assessmentSessions ?? []), [assessmentSessions]);

    // Split the assessments up into groups based on the user's progress.
    const { notStarted, started, complete } = useMemo(() => {
        let notStarted: Array<Profile> = [];
        let started: Array<Profile> = [];
        let complete: Array<Profile> = [];


        for (const profile of profiles) {
            const assignment = expandedUserAssessmentAssignments.find(it => it.userId === profile.userId);
            const session = latestSessionsIncludingIncomplete.find(it => it.userId === profile.userId);

            // If we have no session, then check if its assigned or not.
            if (!session) {
                if (!assignment) {
                    // User has nothing to do with this assessment, just skip it.
                    continue;
                }

                // Add to the notStarted.
                notStarted.push(profile);
            } else {
                // If its complete add it to the complete list.
                if (session.isFinished) {
                    complete.push(profile);
                } else {
                    // Otherwise add it to the started list.
                    started.push(profile);
                }
            }
        };

        return {
            notStarted,
            started,
            complete,
        };
    }, [profiles, latestSessionsIncludingIncomplete, expandedUserAssessmentAssignments]);

    // Get data for the various charts.
    const averageScoreChartData = useAverageScoreChartData(users, getCachedDashboardSummary, assessmentType);

    const baseRoute = useDashboardBaseRoute();

    // Track which histories are expanded.
    const [isHistoryExpandedFor, toggleHistoryExpandedFor] = useToggleStateArray<string>([]);

    // Generate the display items for the passed in assignments.
    const generateItems = (myProfiles: Array<Profile>) => {
        return myProfiles.map(profile => {
            const latestCompleteSession = latestSessions.find(it => it.userId === profile.userId);
            const latestSessionIncludingIncomplete = latestSessionsIncludingIncomplete.find(it => it.userId === profile.userId);
            let assignment = expandedUserAssessmentAssignments.find(it => it.userId === profile.userId && !it.subscriptionTeamId);
            if (!assignment) {
                assignment = expandedUserAssessmentAssignments.find(it => it.subscriptionTeamId === profile.subscriptionTeamId && !!it.subscriptionTeamId);
            }

            // The user format alreay has the name how we want it, so look it up.
            const user = users.find(it => it.id === profile.userId);

            // Also find the team and if available subscription for the profile.
            const team = subscriptionTeams.find(it => it.id === profile.subscriptionTeamId);
            const subscription = subscriptions?.find(it => it.id === profile.subscriptionId);

            const isCurrentUser = user?.id === currentUserId;
            const launchBaseRoute = assessment?.assessmentType === AssessmentType.TrainingModule ? '/training' : '/assessment';

            // Get any historic completions.
            let allMyHistoricCompleteSessions = assessmentSessions
                .filter(it => it.userId === profile.userId && it.isFinished)
                .filter(it => it.id !== latestSessionIncludingIncomplete?.id);
            // Flip the order.
            allMyHistoricCompleteSessions.sort((a, b) => {
                if (a.startDate === b.startDate) {
                    return 0;
                } else if (a.startDate < b.startDate) {
                    return 1;
                } else {
                    return -1;
                }
            });

            return (
                <ListGroupItem key={profile.userId} className="assessment-progress-dashboard-tab-item">
                    <Row>
                        <Col xs={12} md="">
                            <ListGroupItemHeading>
                                <Link to={
                                    showSubscriptionName && user?.subscriptionId ? `${launchBaseRoute}/subscription/${user?.subscriptionId}/user/${user?.id}`
                                        : `${baseRoute}/${user?.subscriptionId? 'user': 'staff'}/${user?.id}`
                                        }>
                                    {user?.name}
                                </Link>
                            </ListGroupItemHeading>
                            <ListGroupItemText tag="div">
                                <ConditionalFragment showIf={!!team}>
                                    <Link to={
                                        showSubscriptionName ? `${baseRoute}/subscription/${team?.subscriptionId}/team/${team?.id}`
                                            : `${baseRoute}/team/${team?.id}`
                                    }>
                                        {team?.name}
                                    </Link>
                                </ConditionalFragment>
                                <ConditionalFragment showIf={showSubscriptionName && !!subscription}>
                                    <> </>
                                    {'(' /* Can/should this be made translatable? */}
                                    <Link to={`${baseRoute}/subscription/${subscription?.id}`}>
                                        {subscription?.companyName}
                                    </Link>
                                    {')'}
                                </ConditionalFragment>
                            </ListGroupItemText>

                            <Row noGutters className="mt-2">
                                <Col>
                                    <ConditionalFragment showIf={!latestSessionIncludingIncomplete}>
                                        <ConditionalFragment showIf={isCurrentUser}>
                                            <LinkContainer to={`${launchBaseRoute}/start/${assessment?.id ?? ''}`}>
                                                <Button color="primary">
                                                    {
                                                        assessment?.assessmentType === AssessmentType.TrainingModule ? t('dashboardAssessmentCard.start.trainingModule', 'Start training')
                                                            : t('dashboardAssessmentCard.start.default', 'Start assessment')
                                                    }
                                                </Button>
                                            </LinkContainer>
                                        </ConditionalFragment>
                                    </ConditionalFragment>
                                    <ConditionalFragment showIf={!!latestSessionIncludingIncomplete && !latestSessionIncludingIncomplete?.isFinished}>
                                        <ConditionalFragment showIf={isCurrentUser}>
                                            <ButtonGroup>
                                                <LinkContainer to={`${launchBaseRoute}/launch/${latestSessionIncludingIncomplete?.id ?? ''}`}>
                                                    <Button color="primary">
                                                        {
                                                            assessment?.assessmentType === AssessmentType.TrainingModule ? t('dashboardAssessmentCard.continue.trainingModule', 'Continue training')
                                                                : t('dashboardAssessmentCard.continue.default', 'Continue assessment')
                                                        }
                                                    </Button>
                                                </LinkContainer>
                                                <LinkContainer to={`${launchBaseRoute}/start/${assessment?.id ?? ''}`}>
                                                    <Button color="primary" outline>
                                                        {
                                                            assessment?.assessmentType === AssessmentType.TrainingModule ? t('dashboardAssessmentCard.restartInProgress.trainingModule', 'Restart')
                                                                : t('dashboardAssessmentCard.restartInProgress.default', 'Restart')
                                                        }
                                                    </Button>
                                                </LinkContainer>
                                            </ButtonGroup>
                                        </ConditionalFragment>
                                    </ConditionalFragment>
                                    <ConditionalFragment showIf={!!latestSessionIncludingIncomplete && !!latestSessionIncludingIncomplete?.isFinished}>
                                        <ButtonGroup>
                                            <LinkContainer to={`${launchBaseRoute}/results/${latestSessionIncludingIncomplete?.id ?? ''}`}>
                                                <Button color="primary" outline>
                                                    {
                                                        assessment?.assessmentType === AssessmentType.TrainingModule ? t('dashboardAssessmentCard.view.trainingModule', 'View results')
                                                            : t('dashboardAssessmentCard.view.default', 'View results')
                                                    }
                                                </Button>
                                            </LinkContainer>
                                        </ButtonGroup>
                                    </ConditionalFragment>
                                </Col>
                            </Row>
                        </Col>

                        <Col xs={12} md="auto">
                            <div className="assessment-progress-dashboard-tab-item-right-side">
                                <div className="assessment-progress-dashboard-tab-item-right-side-score">
                                    {
                                        latestCompleteSession && latestCompleteSession?.id !== latestSessionIncludingIncomplete?.id ? (
                                            <IndividualScoreProgressBar
                                                assessmentType={assessmentType}
                                                value={questionScoreFromNumber(latestCompleteSession?.totalScore ?? 0, false, subscription)}
                                                title={t('assessmentProgressDashboardTab.previousScore.title', 'Previous score')}
                                                footer={t('assessmentProgressDashboardTab.previousScore.footer', 'Previously completed on {{date, DD/MM/YYYY}}', { date: moment(latestCompleteSession?.endDate) })}
                                            />
                                        ) : latestCompleteSession ? (
                                                <IndividualScoreProgressBar
                                                    assessmentType={assessmentType}
                                                    value={questionScoreFromNumber(latestCompleteSession?.totalScore ?? 0, false, subscription)}
                                                />
                                        ): null
                                    }
                                </div>
                                <div className="assessment-progress-dashboard-tab-item-right-side-attempts">
                                    <span>
                                        {
                                            assessmentSessions.filter(item => item.userId === user?.id && !item.isFinished && item.assessmentId === assessment?.id && item.id !== latestSessionIncludingIncomplete?.id).length !== 0 ? (
                                                <>
                                                    {t('assessmentProgressDashboardTab.restartCount', `This user had ${assessmentSessions.filter(item => item.userId === user?.id && !item.isFinished && item.assessmentId === assessment?.id && item.id !== latestSessionIncludingIncomplete?.id).length} unfinished attempts before their latest attempt.`)}
                                                </>
                                            ) : null
                                        }
                                    </span>
                                </div>
                                <div className="assessment-progress-dashboard-tab-item-right-side-date">
                                    <DueDateLabel dueDate={assignment?.targetDate} completedDate={latestSessionIncludingIncomplete?.endDate} />
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <ConditionalFragment showIf={!!allMyHistoricCompleteSessions.length}>
                        <Button color="link" onClick={() => toggleHistoryExpandedFor(profile.userId)}>
                            {
                                isHistoryExpandedFor(profile.userId) ? t('userProgressDashboardTab.hideHistoricalResults', 'Hide all previous results')
                                    : t('userProgressDashboardTab.viewHistoricalResults', 'View all previous results')
                            }
                            <> </>
                            <Badge>
                                {t('common.integer', '{{count, #,0}}', { count: allMyHistoricCompleteSessions.length })}
                            </Badge>
                        </Button>

                        <Collapse isOpen={isHistoryExpandedFor(profile.userId)}>
                            <ListGroup>
                                {
                                    allMyHistoricCompleteSessions.map(session => (
                                        <ListGroupItem key={session.id}>
                                            <Row>
                                                <Col>
                                                    <div className="mb-2">
                                                        <DueDateLabel dueDate={session.startDate} completedDate={session.endDate} />
                                                    </div>

                                                    <ButtonGroup>
                                                        <LinkContainer to={`${launchBaseRoute}/results/${session.id ?? ''}`}>
                                                            <Button color="primary" outline>
                                                                {
                                                                    assessment?.assessmentType === AssessmentType.TrainingModule ? t('dashboardAssessmentCard.viewHistoric.trainingModule', 'View historic results')
                                                                        : t('dashboardAssessmentCard.viewHistoric.default', 'View historic results')
                                                                }
                                                            </Button>
                                                        </LinkContainer>
                                                    </ButtonGroup>
                                                </Col>

                                                <Col xs={12} md="auto">
                                                    <div className="user-progress-dashboard-tab-item-right-side">
                                                        <div className="user-progress-dashboard-tab-item-right-side-score">
                                                            <IndividualScoreProgressBar
                                                                assessmentType={assessmentType}
                                                                value={questionScoreFromNumber(session.totalScore ?? 0, false, riskRules)}
                                                                title={t('userProgressDashboardTab.historicScore.title', 'Historic score')}
                                                            />
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </ListGroupItem>
                                    ))
                                }
                            </ListGroup>
                        </Collapse>
                    </ConditionalFragment>
                </ListGroupItem>
            );
        })
    };

    return (
        <div className="assessment-progress-dashboard-tab">
            <Row>
                <Col xs={12} lg="">
                    {/* Not started */}
                    <div className="mb-2">
                        <ListGroup>
                            <ListGroupItem color="danger">
                                <h5>
                                    {t('assessmentProgressDashboardTab.notStarted.heading', 'Not started')}
                                    <> </>
                                    <Badge pill>
                                        {notStarted.length}
                                    </Badge>
                                </h5>
                            </ListGroupItem>
                            {
                                generateItems(notStarted)
                            }
                        </ListGroup>
                    </div>

                    {/* Started */}
                    <div className="mb-2">
                        <ListGroup>
                            <ListGroupItem color="warning">
                                <h5>
                                    {t('assessmentProgressDashboardTab.started.heading', 'Started')}
                                    <> </>
                                    <Badge pill>
                                        {started.length}
                                    </Badge>
                                </h5>
                            </ListGroupItem>
                            {
                                generateItems(started)
                            }
                        </ListGroup>
                    </div>

                    {/* Complete */}
                    <div className="mb-2">
                        <ListGroup>
                            <ListGroupItem color="success">
                                <h5>
                                    {t('assessmentProgressDashboardTab.complete.heading', 'Completed')}
                                    <> </>
                                    <Badge pill>
                                        {complete.length}
                                    </Badge>
                                </h5>
                            </ListGroupItem>
                            {
                                generateItems(complete)
                            }
                        </ListGroup>
                    </div>
                </Col>
                <Col>
                    <AverageScoreRadarChart data={averageScoreChartData} assessmentType={assessmentType} riskRules={riskRules} />
                </Col>
            </Row>
        </div>
    );
};